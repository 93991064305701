import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { IApplication } from '~Api/Application';
import { IDeal } from '~Api/Deal';
import { IGlobalState } from '~reducer';
import Checkbox from '~UI/Checkbox';
import {
    applicationCompleteAction,
    dealGetAction,
} from './actions';
import Layout from './Layout';
import {
    applicationSelector,
    currentDealSelector,
    savingSelector,
} from './selectors';

interface IState {
    agree: boolean;
}

interface IPropsSelector {
    application: IApplication;
    deal: IDeal;
    saving: boolean;
}

interface IPropsDispatch {
    complete: () => void;
    dealGet: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Submit extends React.Component<Props, IState> {
    public state: IState = {
        agree: false,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAgree = this.onChangeAgree.bind(this);
    }

    public componentDidMount() {
        const { application, deal } = this.props;

        if (!application || !deal) {
            this.props.dealGet();
        }
    }

    public render(): JSX.Element {
        const { application, deal, saving } = this.props;
        const { agree } = this.state;

        if (!application || !deal) {
            return (
                <Layout containerClass='submit' stepNumber={3}>
                    <h3>Submit your application</h3>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        return (
            <Layout containerClass='submit' stepNumber={4}>
                <h3>Submit your application</h3>
                <p className='intro'>You are almost done.</p>
                <p className='intro'>
                    By checking the box you agree you have received and are bound by funding.com.au's
                    {' '}<a href='https://www.funding.com.au/electronic-consent-and-nomination/' target='_blank'>Electronic Consent and Nomination</a>,
                    {' '}<a href='https://www.funding.com.au/privacy-policy/' target='_blank'>Privacy Policy</a>,
                    {' '}<a href='https://www.funding.com.au/credit-guide/' target='_blank'>Credit Guide</a>,
                    {' '}<a href='https://www.funding.com.au/terms-of-use/' target='_blank'>Terms of Use</a>
                    {' '}(you can print or save a copy for your records).
                </p>
                <Form.Group>
                    <Checkbox
                        checked={agree}
                        label={<React.Fragment>I/We agree</React.Fragment>}
                        onChange={this.onChangeAgree}
                    />
                </Form.Group>
                <div className='actions'>
                    <Link className='previous' to='/application/borrowers'><Button disabled={saving} variant='primary'>Previous</Button></Link>
                    <Button className='continue' disabled={saving || !agree} onClick={this.props.complete} variant='primary'>
                        {!saving && 'Submit'}
                        {saving && <Spinner animation='border' as='span' role='status' size='sm' />}
                    </Button>
                </div>
            </Layout>
        );
    }

    private onChangeAgree(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            agree: event.currentTarget.checked,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        application: applicationSelector(state),
        deal: currentDealSelector(state),
        saving: savingSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        complete: () => dispatch(applicationCompleteAction()),
        dealGet: () => dispatch(dealGetAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Submit);
