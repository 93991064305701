import Cookies from 'js-cookie';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { Button, Form, Spinner, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import ITransaction from '~Api/Investor/ITransaction';
import TransactionTypeEnum from '~Api/Investor/TransactionTypeEnum';
import { currentInvestorGetAction, transactionsListAction } from '~Investor/actions';
import { currentInvestorAccountSelector, currentInvestorSelector, transactionsSelector } from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import Pagination from '~UI/Pagination';
import SortableHeader, { SortOrderEnum } from '~UI/SortableHeader';
import Layout from './Layout';
import './transactions.less';

enum TypeFilterEnum {
    All = 'All',
    LoanIssued = 'LoanIssued',
    DepositWithdrawal = 'DepositWithdrawal',
    Payment = 'Payment',
    Deposit = 'Deposit',
    Withdrawal = 'Withdrawal',
}

enum SortFieldEnum {
    Amount = 'Amount',
    Balance = 'Balance',
    Date = 'Date',
    Description = 'Description',
}

interface IState {
    currentPage: number;
    endDate: string;
    perPage: number;
    sortField: SortFieldEnum;
    sortOrder: SortOrderEnum;
    startDate: string;
    typeFilter: TypeFilterEnum;
}

interface IPropsSelector {
    currentInvestor: IInvestor;
    marketplaceAccount: IAccount;
    transactions: ITransaction[];
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
    transactionsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Transactions extends React.Component<Props, IState> {
    public state: IState = {
        currentPage: 1,
        endDate: null,
        perPage: 10,
        sortField: SortFieldEnum.Date,
        sortOrder: SortOrderEnum.Descending,
        startDate: null,
        typeFilter: TypeFilterEnum.All,
    };

    constructor(props: Props) {
        super(props);

        this.onClickPage = this.onClickPage.bind(this);
        this.onChangeTypeFilter = this.onChangeTypeFilter.bind(this);
        this.onChangeStartDate = this.onChangeStartDate.bind(this);
        this.onChangeEndDate = this.onChangeEndDate.bind(this);
        this.onClickReset = this.onClickReset.bind(this);
        this.onChangePerPage = this.onChangePerPage.bind(this);
        this.onClickSort = this.onClickSort.bind(this);
    }

    public componentDidMount(): void {
        const { currentInvestor, transactions } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }

        if (!transactions) {
            this.props.transactionsList();
        }
    }

    public render(): JSX.Element {
        const { currentInvestor, marketplaceAccount, transactions } = this.props;
        const { currentPage, endDate, perPage, sortField, sortOrder, startDate, typeFilter } = this.state;

        if (!currentInvestor || !transactions) {
            return (
                <Layout section='get-statement'>
                    <h1>Transactions</h1>
                    <div className='content-box'>
                        <Spinner animation='border' />
                    </div>
                </Layout>
            );
        }

        let filteredTransactions: ITransaction[] = _.filter(transactions, (transaction: ITransaction) => {
            if (startDate && transaction.activityDate < startDate) {
                return false;
            }

            if (endDate && transaction.activityDate > endDate) {
                return false;
            }

            switch (typeFilter) {
                case TypeFilterEnum.All:
                    return true;
                case TypeFilterEnum.Deposit:
                    return transaction.type === TransactionTypeEnum.Deposit;
                case TypeFilterEnum.DepositWithdrawal:
                    return [TransactionTypeEnum.Deposit, TransactionTypeEnum.Withdrawal].includes(transaction.type);
                case TypeFilterEnum.LoanIssued:
                    return transaction.type === TransactionTypeEnum.LoanIssued;
                case TypeFilterEnum.Payment:
                    return transaction.type === TransactionTypeEnum.Payment;
                case TypeFilterEnum.Withdrawal:
                    return transaction.type === TransactionTypeEnum.Withdrawal;
            }
        });

        filteredTransactions = _.sortBy(filteredTransactions, [(transaction: ITransaction) => {
            switch (sortField) {
                case SortFieldEnum.Amount:
                    return transaction.amount;
                case SortFieldEnum.Balance:
                    return transaction.balance;
                case SortFieldEnum.Date:
                    return transaction.activityDate;
                case SortFieldEnum.Description:
                    return transaction.description;
            }
        }]);

        if (sortOrder === SortOrderEnum.Descending) {
            filteredTransactions = _.reverse(filteredTransactions);
        }

        const offset: number = (currentPage - 1) * perPage;
        const pageTransactions: ITransaction[] = _.slice(filteredTransactions, offset, offset + perPage);

        const transactionsBlock: JSX.Element[] = pageTransactions.map((transaction: ITransaction) => {
            return (
                <tr key={transaction.uuid}>
                    <td className='date'>{moment(transaction.activityDate).format('D MMM YYYY')}</td>
                    <td className='description'>{transaction.description}</td>
                    <td className='amount'>{numeral(transaction.amount).format('$0,0[.]00')}</td>
                    <td className='balance'>{numeral(transaction.balance).format('$0,0[.]00')}</td>
                </tr>
            );
        });

        const onClickSortByAmount: () => void = () => this.onClickSort(SortFieldEnum.Amount);
        const onClickSortByBalance: () => void = () => this.onClickSort(SortFieldEnum.Balance);
        const onClickSortByDate: () => void = () => this.onClickSort(SortFieldEnum.Date);
        const onClickSortByDescription: () => void = () => this.onClickSort(SortFieldEnum.Description);

        const cookie: any = Cookies.getJSON('currentUser');

        const params: any = {
            token: cookie.token,
        };

        if (startDate) {
            params.startDate = startDate;
        }

        if (endDate) {
            params.endDate = endDate;
        }

        if (typeFilter && typeFilter !== TypeFilterEnum.All) {
            params.types = [];
            switch (typeFilter) {
                case TypeFilterEnum.Deposit:
                    params.types.push(TransactionTypeEnum.Deposit);
                    break;
                case TypeFilterEnum.DepositWithdrawal:
                    params.types.push(TransactionTypeEnum.Deposit);
                    params.types.push(TransactionTypeEnum.Withdrawal);
                    break;
                case TypeFilterEnum.LoanIssued:
                    params.types.push(TransactionTypeEnum.LoanIssued);
                    break;
                case TypeFilterEnum.Payment:
                    params.types.push(TransactionTypeEnum.Payment);
                    break;
                case TypeFilterEnum.Withdrawal:
                    params.types.push(TransactionTypeEnum.Withdrawal);
                    break;
            }
        }

        const urlParams: string = _.keys(params).map((key: string) => `${key}=${encodeURIComponent(params[key])}`).join('&');

        return (
            <Layout section='get-statement'>
                <h1>Transactions</h1>
                <div className='content-box'>
                    <Form.Group className='start-date'>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control onChange={this.onChangeStartDate} type='date' value={startDate || ''} />
                    </Form.Group>
                    <Form.Group className='end-date'>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control onChange={this.onChangeEndDate} type='date' value={endDate || ''} />
                    </Form.Group>
                    <Form.Group className='type'>
                        <Form.Label>Type</Form.Label>
                        <Form.Control as='select' onChange={this.onChangeTypeFilter} value={typeFilter}>
                            <option value={TypeFilterEnum.All}>All</option>
                            <option value={TypeFilterEnum.LoanIssued}>Loan Issued</option>
                            <option value={TypeFilterEnum.DepositWithdrawal}>Deposit / Withdrawal</option>
                            <option value={TypeFilterEnum.Payment}>Payment</option>
                            <option value={TypeFilterEnum.Deposit}>Deposit</option>
                            <option value={TypeFilterEnum.Withdrawal}>Withdrawal</option>
                        </Form.Control>
                    </Form.Group>
                    <Button className='reset' onClick={this.onClickReset}>Clear</Button>

                    <Table
                        className='statements'
                        bordered={true}
                    >
                        <thead>
                            <tr>
                                <SortableHeader
                                    className='date'
                                    field={SortFieldEnum.Date}
                                    label='Date'
                                    onClick={onClickSortByDate}
                                    sortField={sortField}
                                    sortOrder={sortOrder}
                                />
                                <SortableHeader
                                    className='description'
                                    field={SortFieldEnum.Description}
                                    label='Description'
                                    onClick={onClickSortByDescription}
                                    sortField={sortField}
                                    sortOrder={sortOrder}
                                />
                                <SortableHeader
                                    className='amount'
                                    field={SortFieldEnum.Amount}
                                    label='Amount'
                                    onClick={onClickSortByAmount}
                                    sortField={sortField}
                                    sortOrder={sortOrder}
                                />
                                <SortableHeader
                                    className='balance'
                                    field={SortFieldEnum.Balance}
                                    label='Balance'
                                    onClick={onClickSortByBalance}
                                    sortField={sortField}
                                    sortOrder={sortOrder}
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {transactionsBlock}
                        </tbody>
                    </Table>
                    <div className='pagination-block'>
                        <Pagination
                            currentPage={currentPage}
                            itemCount={filteredTransactions.length}
                            onChangePerPage={this.onChangePerPage}
                            onClickPage={this.onClickPage}
                            perPage={perPage}
                        />
                    </div>
                    <Button
                        className='export'
                        href={`${process.env.API_HOST}/investor-accounts/${marketplaceAccount.uuid}/transactions/download?${urlParams}`}
                        target='_blank'
                    >
                        Export
                    </Button>
                </div>
            </Layout>
        );
    }

    private onClickPage(page: number): void {
        this.setState({
            currentPage: page,
        });
    }

    private onChangeTypeFilter(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            typeFilter: event.target.value as TypeFilterEnum,
        });
    }

    private onChangeStartDate(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            startDate: event.target.value && moment(event.target.value).format('YYYY-MM-DD'),
        });
    }

    private onChangeEndDate(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            endDate: event.target.value && moment(event.target.value).format('YYYY-MM-DD'),
        });
    }

    private onClickReset(): void {
        this.setState({
            endDate: null,
            startDate: null,
            typeFilter: TypeFilterEnum.All,
        });
    }

    private onChangePerPage(perPage: number): void {
        this.setState({
            currentPage: 1,
            perPage,
        });
    }

    private onClickSort(field: SortFieldEnum): void {
        const { sortField, sortOrder } = this.state;

        if (sortField === field) {
            this.setState({
                sortOrder: sortOrder === SortOrderEnum.Ascending ? SortOrderEnum.Descending : SortOrderEnum.Ascending,
            });
        } else {
            this.setState({
                sortField: field,
                sortOrder: SortOrderEnum.Ascending,
            });
        }
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        marketplaceAccount: currentInvestorAccountSelector(state, AccountTypeEnum.Marketplace),
        transactions: transactionsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
        transactionsList: () => dispatch(transactionsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Transactions);
