import _ from 'lodash';
import { IGlobalState } from '~reducer';
import IUser from './IUser';

export function currentUserSelector(state: IGlobalState): IUser {
    return state.user.currentUser;
}

export function emailChangeErrorsSelector(state: IGlobalState): any {
    return state.user.emailChangeErrors;
}

export function emailChangeInProgressSelector(state: IGlobalState): boolean {
    return state.user.emailChangeInProgress;
}

export function emailChangeModalShowSelector(state: IGlobalState): boolean {
    return state.user.emailChangeModalShow;
}

export function passwordChangeErrorsSelector(state: IGlobalState): any {
    return state.user.passwordChangeErrors;
}

export function passwordChangeInProgressSelector(state: IGlobalState): boolean {
    return state.user.passwordChangeInProgress;
}

export function passwordChangeModalShowSelector(state: IGlobalState): boolean {
    return state.user.passwordChangeModalShow;
}
