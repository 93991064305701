import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { Button, Form, Spinner, Table } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { AiOutlineFacebook, AiOutlineLinkedin } from 'react-icons/ai';
import { BiEnvelope, BiFile } from 'react-icons/bi';
import { FiTwitter } from 'react-icons/fi';
import { connect } from 'react-redux';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from 'react-share';
import { Dispatch } from 'redux';
import IInvestor from '~Api/Investor/IInvestor';
import IReferral from '~Api/Investor/IReferral';
import {
    currentInvestorGetAction,
    referralAddAction,
    referralAddErrorSetAction,
    referralEmailSetAction,
    referralsListAction,
} from '~Investor/actions';
import { IReferralAddErrors } from '~Investor/reducer';
import {
    currentInvestorSelector,
    referralAddErrorsSelector,
    referralAddInProgressSelector,
    referralEmailSelector,
    referralsSelector,
} from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import { validateEmail } from '~validators';
import Layout from './Layout';
import './refer-friend.less';

interface IState {
    copied: boolean;
}

interface IPropsSelector {
    addInProgress: boolean;
    currentInvestor: IInvestor;
    email: string;
    errors: IReferralAddErrors;
    inProgress: boolean;
    referrals: IReferral[];
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
    referralsList: () => void;
    sendInvite: () => void;
    setEmail: (email: string) => void;
    setError: (key: keyof IReferralAddErrors, value: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class ReferFriend extends React.Component<Props> {
    public state: IState = {
        copied: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickCopy = this.onClickCopy.bind(this);
        this.onClickOpenDetails = this.onClickOpenDetails.bind(this);
        this.onCloseDetails = this.onCloseDetails.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onClickSendInvite = this.onClickSendInvite.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
    }

    public componentDidMount(): void {
        const { currentInvestor, referrals } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }

        if (!referrals) {
            this.props.referralsList();
        }
    }

    public render(): JSX.Element {
        const { currentInvestor, email, errors, inProgress, referrals } = this.props;
        const { copied } = this.state;

        if (!currentInvestor || !referrals) {
            return (
                <Layout section='refer-friend'>
                    <h1>Refer a Friend</h1>
                    <div className='content-box'>
                        <Spinner animation='border' />
                    </div>
                </Layout>
            );
        }

        const url: string = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/investor/signup/${currentInvestor.referralCode}`;

        const referralBlock: JSX.Element[] = referrals.map((referral: IReferral) => (
            <tr key={referral.uuid}>
                <td className='date'>{moment(referral.createdTime).format('D MMM YYYY')}</td>
                <td className='email'>{referral.referralEmailAddress}</td>
                <td className='status'>{referral.isInvestorProcessed ? 'Paid' : 'Pending'}</td>
                <td className='amount'>{numeral(referral.investorRewardAmount).format('$0,0[.]00')}</td>
            </tr>
        ));

        const referralStatusBlock: JSX.Element = referrals.length > 0 && (
            <div className='content-box referral-status'>
                <h2>Your Referral Status</h2>
                <Table
                    className='referrals'
                    bordered={true}
                >
                    <tbody>
                        {referralBlock}
                    </tbody>
                </Table>
            </div>
        );

        return (
            <Layout section='refer-friend'>
                <h1>Refer a Friend</h1>
                <div className='content-box your-referrals'>
                    <h2>Your Referrals</h2>
                    <img src={'https://www.funding.com.au/wp-content/uploads/2023/02/Refer_a_Friend.png'} />
                    <p>
                        You can now receive a <strong>$150</strong> investor bonus by referring funding.com.au to your friends.
                        For every successful referral, you and your friend both receive a <strong>$150</strong> investor bonus.
                    </p>
                    <p><strong>How do I refer someone?</strong></p>
                    <ol>
                        <li>You have a unique referral link you can share with your friends via email or social media.</li>
                        <li>When someone signs up using your referral link, you will both receive a <strong>$150</strong> investor bonus after they make their first investment.</li>
                        <li>The referral reward is credited directly to your investment account&apos;s &apos;available balance&apos;.</li>
                    </ol>
                    <p>
                        *<a href='https://www.funding.com.au/terms-and-conditions-for-funding-refer-a-friend-promotion/' target='_blank' rel='noreferrer'>Terms and Conditions</a> apply.
                        Please refer to the PDS for complete fund details before proceeding.
                        The promotion is open anyone with an existing Funding online account and who has invested <strong>$5,000</strong> into available investments.
                        The new customer must invest a minimum of <strong>$5,000</strong> in an available investment/s.
                    </p>
                </div>
                <div className='content-box email-friend'>
                    <h2>Email a Friend</h2>
                    <Button className='invite' disabled={inProgress} onClick={this.onClickSendInvite}>
                        {inProgress && <Spinner animation='border' size='sm' />}
                        {!inProgress && <BiEnvelope className='icon' />}
                        <div className='text'>Invite</div>
                    </Button>
                    <Form.Group>
                        <Form.Control isInvalid={!!errors.email} name='email' onBlur={this.validateEmail} onChange={this.onChangeEmail} placeholder='Email address' value={email || ''} />
                        {errors.email && <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>}
                    </Form.Group>
                </div>
                <div className='content-box invite-link'>
                    <h2>Share Your Invite Link</h2>
                    <CopyToClipboard onCopy={this.onClickCopy} text={url}>
                        <Button className={`copy ${copied ? 'copied' : ''}`}>
                            <BiFile className='icon' />
                            <div className='text'>{`${copied ? 'Copied' : 'Copy'}`}</div>
                        </Button>
                    </CopyToClipboard>
                    <Form.Group>
                        <Form.Control defaultValue={url} disabled={true} />
                    </Form.Group>
                </div>
                <div className='content-box share-social'>
                    <h2>Share on Social</h2>
                    <FacebookShareButton className='facebook' url={url}><AiOutlineFacebook /> Facebook</FacebookShareButton>
                    <TwitterShareButton className='twitter' url={url}><FiTwitter /> Twitter</TwitterShareButton>
                    <LinkedinShareButton className='linkedin' url={url}><AiOutlineLinkedin /> LinkedIn</LinkedinShareButton>
                </div>
                {referralStatusBlock}
            </Layout>
        );
    }

    private onClickCopy(): void {
        this.setState({
            copied: true,
        });
    }

    private onClickOpenDetails(): void {
        this.setState({
            isDetailsOpen: true,
        });
    }

    private onCloseDetails(): void {
        this.setState({
            isDetailsOpen: false,
        });
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setEmail(event.target.value);
    }

    private onClickSendInvite(): void {
        let valid: boolean = true;
        valid = this.validateEmail() && valid;

        if (!valid) {
            return;
        }

        this.props.sendInvite();
    }

    private validateEmail(): boolean {
        const { email } = this.props;

        let error: string;

        if (!email || email.length === 0) {
            error = 'Please enter the email address';
        } else if (!validateEmail(email)) {
            error = 'Please enter a valid email address';
        }

        this.props.setError('email', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        addInProgress: referralAddInProgressSelector(state),
        currentInvestor: currentInvestorSelector(state),
        email: referralEmailSelector(state),
        errors: referralAddErrorsSelector(state),
        inProgress: referralAddInProgressSelector(state),
        referrals: referralsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
        referralsList: () => dispatch(referralsListAction()),
        sendInvite: () => dispatch(referralAddAction()),
        setEmail: (email: string) => dispatch(referralEmailSetAction(email)),
        setError: (key: keyof IReferralAddErrors, value: string) => dispatch(referralAddErrorSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReferFriend);
