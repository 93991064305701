import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import Lottie, { Options } from 'react-lottie';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
    investmentInvestAction,
    investmentInvestCompleteModalShowSetAction,
    investmentInvestModalShowSetAction,
} from '~Investor/actions';
import {
    investAmountSelector,
    investCompleteModalShowSelector,
    investInProgressSelector,
    investModalShowSelector,
} from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import success from '~UI/success.json';

interface IProps {
    uuid: string;
}

interface IPropsSelector {
    amount: number;
    completeModalShow: boolean;
    investInProgress: boolean;
    modalShow: boolean;
}

interface IPropsDispatch {
    hideCompleteModal: () => void;
    hideModal: () => void;
    invest: (amount: number) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class InvestModal extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickSubmit = this.onClickSubmit.bind(this);
    }

    public render(): JSX.Element {
        const { amount, completeModalShow, investInProgress, modalShow } = this.props;

        if (modalShow) {
            const currencyFormatter = new Intl.NumberFormat('en-AU', {
                currency: 'AUD',
                style: 'currency',
            });

            return (
                <Modal
                    backdropClassName='investor-invest-modal-backdrop'
                    dialogClassName='investor-invest-modal'
                    onHide={this.props.hideModal}
                    show={modalShow}
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title>Confirm Investment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Confirm the below amount is correct.</p>
                        <div className='amount'>
                            <div className='label'>Amount in AUD $</div>
                            <div className='value'>{currencyFormatter.format(amount)}</div>
                        </div>
                        <Button disabled={investInProgress} onClick={this.onClickSubmit}>
                            {investInProgress && <Spinner animation='border' size='sm' />} Submit Investment
                        </Button>
                    </Modal.Body>
                </Modal>
            );
        }

        const options: Options = {
            animationData: success,
            autoplay: true,
            loop: false,
        };

        return (
            <Modal
                backdropClassName='investor-invest-modal-backdrop'
                dialogClassName='investor-invest-modal'
                onHide={this.props.hideCompleteModal}
                show={completeModalShow}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Success!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You have made an investment.</p>
                    <Lottie height={112} options={options} width={112} />
                    <Button className='done' onClick={this.props.hideCompleteModal}>Done</Button>
                </Modal.Body>
            </Modal>
        );
    }

    private onClickSubmit(): void {
        const { amount } = this.props;

        this.props.invest(amount);
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        amount: investAmountSelector(state),
        completeModalShow: investCompleteModalShowSelector(state),
        investInProgress: investInProgressSelector(state),
        modalShow: investModalShowSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        hideCompleteModal: () => dispatch(investmentInvestCompleteModalShowSetAction(false)),
        hideModal: () => dispatch(investmentInvestModalShowSetAction(false)),
        invest: (amount: number) => dispatch(investmentInvestAction(ownProps.uuid, amount)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InvestModal);
