import React from 'react';
import { Button, Container } from 'react-bootstrap';
import Layout from './Layout';

function RetailInvestor(): JSX.Element {
    return (
        <Layout>
            <Container className='register-retail-investor'>
                <h3>Retail Investor</h3>
                <p>Sorry only Wholesale and Sophisticated investors are eligible to participate in the Income Trust.</p>
                <p>Our retail fund is open to all investors who select and manage investments online starting from $5,000.</p>
                <p>Target returns from <strong>5 to 9%</strong> pa*.</p>
                <p>Be sure to read the PDS and consider all risks while investing. Target returns and repayment of capital are not guaranteed.</p>
                <Button href='/invest/marketplace' variant='primary' className='continue'>Continue to Retail Fund</Button>
            </Container>
        </Layout>
    );
}

export default RetailInvestor;
