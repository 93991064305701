import React from 'react';

class BpayLogo extends React.Component {
    public render(): JSX.Element {
        return (
            <svg width='131' height='192' version='1.1' viewBox='0 0 131 192'>
                <defs>
                    <clipPath id='clipPath4584'>
                        <path d='m0 173h127v-173h-127z'/>
                    </clipPath>
                </defs>
                <g transform='matrix(1.33 0 0 -1.33 -18.5 210)'>
                    <g clip-path='url(#clipPath4584)'>
                        <g transform='translate(112,20)'>
                            <path d='m0 0c0-2.59-2.1-4.69-4.68-4.69h-87.1c-2.58 0-4.68 2.1-4.68 4.69v133c0 2.58 2.1 4.68 4.68 4.68h87.1c2.59 0 4.68-2.1 4.68-4.68z' fill='#142c61'/>
                        </g>
                        <g transform='translate(106,23.2)'>
                            <path d='m0 0c0-1.2-0.606-2.34-2.37-2.38h-80.8c-0.047 0-0.102 0-0.16 0.012-1.15 0.066-2.07 1.02-2.07 2.19 0 0 7e-3 62.1 7e-3 99.2h24.6l-9.92-3.62v-54.4c0-1.52 0.844-2.32 2.13-2.44h33.4c11.4 0.367 20.6 9.72 20.6 21.2 0 7.55-3.93 14.2-9.85 17.9 3.7 3.28 6.49 8.64 6.49 13.9 0 14.1-9.98 22-24.2 22h-13.9v14.7c23.5 0 53.6-8e-3 53.6-8e-3 1.83 0 2.37-1.2 2.37-2.38zm-85.4 126v7e-3c0 1.17 0.907 2.11 2.04 2.2h12.7v-14.7h-14.7zm29.2-26.8h16c4.2 0 7.58-3.59 7.58-7.75 0-4.18-3.38-7.57-7.58-7.57l-2.76-0.145c-1.48-0.058-2.25-0.929-2.3-2.29v-9.3c0.106-1.48 1.01-2.25 2.43-2.25h6.85c4.55 0 8.24-3.69 8.24-8.22 0-4.3-3.32-7.8-7.51-8.16l-18.6-0.016c-1.38 0.035-2.28 0.832-2.34 2.34zm58.2 35.7h-89.4c-2.58 0-4.68-2.1-4.68-4.68v-135c0-2.59 2.1-4.69 4.68-4.69h89.4c2.59 0 4.68 2.1 4.68 4.69v135c0 2.58-2.1 4.68-4.68 4.68m-49.4-119 3.96 11.2 4.2-11.2zm6.98 14.1c-0.301 0.609-0.516 1.01-1.6 1.01h-2.84c-1.08 0-1.3-0.399-1.6-1.01-0.274-0.602-8.23-22.2-8.23-22.2s-0.468-0.93 0.731-0.93c1.22 0 1.49-0.016 2.01-0.016 0.547 0 1.18 0.102 1.48 0.961 0.261 0.75 1.45 4.06 1.45 4.06h11.2s1.19-3.3 1.45-4.06c0.296-0.859 0.929-0.961 1.48-0.961 0.52 0 0.785 0.016 2.01 0.016 1.2 0 0.73 0.93 0.73 0.93s-7.96 21.6-8.23 22.2m-20.7-10.6h-5.63v7.62l5.63 3e-3c2.11 0 3.81-1.71 3.81-3.81 0-2.11-1.71-3.81-3.81-3.81m0.066 11.6h-8.77c-0.762 0-0.871-0.629-0.871-1.02 0-0.394 4e-3 -22.2 4e-3 -22.2 0-0.914 0.84-0.875 0.933-0.875h2.24c0.367 0 0.773 0.297 0.773 0.719l-4e-3 7.96h5.69c4.26 0 7.71 3.45 7.71 7.71s-3.45 7.71-7.71 7.71m44.9 0.021c-1.22 0-1.77 0.016-2.29 0.016-0.547 0-1.18-0.102-1.48-0.961l-1.09-1.96-4.38-7.74-4.38 7.74-1.09 1.96c-0.297 0.859-0.93 0.961-1.48 0.961-0.519 0-1.07-0.016-2.29-0.016-1.2 0-0.73-0.93-0.73-0.93s4.44-7.88 8.02-14.2c0 0-0.061-7.56-0.061-8.09 0-0.527 0.281-0.859 0.852-0.859h2.09c0.375 0 1.04 0.031 1.04 0.836l-0.018 8.12c3.57 6.36 8.02 14.2 8.02 14.2s0.468 0.93-0.731 0.93' fill='#fff'/>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

export default BpayLogo;
