import _ from 'lodash';
import React from 'react';
import { Button, Container, Nav, NavDropdown, Spinner } from 'react-bootstrap';
import { BiUser } from 'react-icons/bi';
import { IoIosMenu } from 'react-icons/io';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestor from '~Api/Investor/IInvestor';
import { authLogoutAction } from '~Auth/actions';
import Toasts from '~Global/Toasts';
import {
    completeSignupModalShowSetAction,
    currentInvestorGetAction,
} from '~Investor/actions';
import {
    completeSignupModalShowSelector,
    currentInvestorSelector,
} from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import Logo from '~UI/Logo';
import EmailChangeModal from '~User/EmailChangeModal';
import PasswordChangeModal from '~User/PasswordChangeModal';
import CompleteSignupModal from './CompleteSignupModal';
import './investor.less';
import { Helmet } from 'react-helmet';

interface IState {
    isMenuCollapsed: boolean;
}

interface IProps {
    authNavMenuOptions?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    footer?: JSX.Element;
    leftNav?: JSX.Element;
    section: string;
}

interface IPropsSelector {
    completeSignupModalShow: boolean;
    currentInvestor: IInvestor;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
    hideSignupModal: () => void;
    logout: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Layout extends React.Component<Props, IState> {
    public state: IState = {
        isMenuCollapsed: true,
    };

    private currentInvestorInterval: any;

    constructor(props: Props) {
        super(props);

        this.onCancelCompleteSignup = this.onCancelCompleteSignup.bind(this);
        this.onClickCollapse = this.onClickCollapse.bind(this);
    }

    public componentDidMount(): void {
        const { currentInvestor } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }

        this.currentInvestorInterval = setInterval(() => {
            this.props.currentInvestorGet();
        }, 5 * 60 * 1000);
    }

    public componentWillUnmount(): void {
        clearInterval(this.currentInvestorInterval);
    }

    public render(): JSX.Element {
        const { authNavMenuOptions, completeSignupModalShow, currentInvestor, footer, leftNav, section } = this.props;
        const { isMenuCollapsed } = this.state;

        if (!currentInvestor) {
            return (
                <div className='investor'>
                    <Spinner animation='border' />
                </div>
            );
        }

        const leftSection: JSX.Element = leftNav && (
            <div className={`left-nav ${isMenuCollapsed ? 'collapsed' : 'open'}`}>
                {leftNav}
            </div>
        );

        return (
            <div className='investor'>
                <Toasts />
                <Helmet>
                    <title>Funding.com.au</title>
                </Helmet>
                <div className='d-flex flex-column flex-md-row align-items-center p-3 px-md-4 top-bar'>
                    <Container fluid={true}>
                        <Nav className='auth'>
                            <NavDropdown id='auth-menu' title={<BiUser />}>
                                <NavDropdown.Item as={NavLink} to='/my-details'>My Details</NavDropdown.Item>
                                <NavDropdown.Item onClick={this.props.logout}>Logout</NavDropdown.Item>
                                {authNavMenuOptions}
                            </NavDropdown>
                        </Nav>
                        <Link to='/'><Logo className='logo' /></Link>
                        <Button className='left-nav-collapse' onClick={this.onClickCollapse}><IoIosMenu /></Button>
                    </Container>
                </div>

                <main role='main'>
                    {leftSection}
                    <div className='right-section'>
                        <div className={`body ${section}`}>
                            {this.props.children}
                        </div>
                    </div>
                </main>

                {footer}
                <CompleteSignupModal
                    isOpen={completeSignupModalShow}
                    onCancel={this.onCancelCompleteSignup}
                />

                <EmailChangeModal/>

                <PasswordChangeModal/>
            </div>
        );
    }

    private onCancelCompleteSignup(): void {
        this.props.hideSignupModal();
    }

    private onClickCollapse(): void {
        const { isMenuCollapsed } = this.state;

        this.setState({
            isMenuCollapsed: !isMenuCollapsed,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        completeSignupModalShow: completeSignupModalShowSelector(state),
        currentInvestor: currentInvestorSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
        hideSignupModal: () => dispatch(completeSignupModalShowSetAction(false)),
        logout: () => dispatch(authLogoutAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Layout);
