import _ from 'lodash';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import {
    completeSignupModalShowSetAction,
    currentInvestorGetAction,
    topUpModalShowAction,
    withdrawModalShowAction,
} from '~Investor/actions';
import { currentInvestorAccountSelector, currentInvestorSelector } from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import TopUp from '~UI/TopUp';
import Withdraw from '~UI/Withdraw';
import Layout from './Layout';
import './top-up-withdraw.less';
import TopUpModal from './TopUpModal';
import WithdrawModal from './WithdrawModal';

interface IPropsSelector {
    currentInvestor: IInvestor;
    marketplaceAccount: IAccount;
}

interface IPropsDispatch {
    completeSignupModalShow: () => void;
    topUpModalShow: () => void;
    withdrawModalShow: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class TopUpWithdraw extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickTopUp = this.onClickTopUp.bind(this);
        this.onClickWithdraw = this.onClickWithdraw.bind(this);
    }

    public render(): JSX.Element {
        const { currentInvestor, marketplaceAccount } = this.props;

        if (!currentInvestor) {
            return (
                <Layout section='marketplace-top-up-withdraw'>
                    <h1>Top Up &amp; Withdraw</h1>
                    <div className='content-box'>
                        <Spinner animation='border' />
                    </div>
                </Layout>
            );
        }

        return (
            <Layout section='marketplace-top-up-withdraw'>
                <h1>Top Up &amp; Withdraw</h1>
                <div className='content-box top-up'>
                    <TopUp />
                    <div className='title'>Top Up Funds</div>
                    <p>Add money to your Funding investment account</p>
                    <Button disabled={marketplaceAccount.depositAmountPending > 0} onClick={this.onClickTopUp}>{marketplaceAccount.depositAmountPending > 0 ? 'Pending Top Up' : 'Top Up Funds'}</Button>
                </div>
                <div className='content-box withdraw'>
                    <Withdraw />
                    <div className='title'>Withdraw Funds</div>
                    <p>Take money out of your Funding investment account</p>
                    <Button disabled={marketplaceAccount.withdrawalAmountPending > 0} onClick={this.onClickWithdraw}>{marketplaceAccount.withdrawalAmountPending > 0 ? 'Pending Withdrawal' : 'Withdraw Funds'}</Button>
                </div>
                <TopUpModal />
                <WithdrawModal />
            </Layout>
        );
    }

    private onClickTopUp(): void {
        const { marketplaceAccount } = this.props;

        if (!marketplaceAccount.isCompleted) {
            this.props.completeSignupModalShow();
        } else {
            this.props.topUpModalShow();
        }
    }

    private onClickWithdraw(): void {
        const { marketplaceAccount } = this.props;

        if (!marketplaceAccount.isCompleted) {
            this.props.completeSignupModalShow();
        } else {
            this.props.withdrawModalShow();
        }
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        marketplaceAccount: currentInvestorAccountSelector(state, AccountTypeEnum.Marketplace),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        completeSignupModalShow: () => dispatch(completeSignupModalShowSetAction(true)),
        topUpModalShow: () => dispatch(topUpModalShowAction()),
        withdrawModalShow: () => dispatch(withdrawModalShowAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopUpWithdraw);
