import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import IAuthUser from '~Auth/IAuthUser';
import RoleEnum from '~Auth/RoleEnum';
import { authCurrentUserSelector } from '~Auth/selectors';
import history from '~history';
import IncomeTrustRegister from '~Investor/IncomeTrust/Register';
import { IGlobalState } from '~reducer';
import ScrollToTop from '~ScrollToTop';
import { currentInvestorGetAction } from '../actions';
import { currentInvestorAccountSelector, currentInvestorSelector } from '../selectors';
import Dashboard from './Dashboard';
import HelpCentre from './HelpCentre';
import TopUp from './Invest';
import Portfolio from './Portfolio';
import Statements from './Statements';

interface IPropsSelector {
    currentInvestor: IInvestor;
    currentUser: IAuthUser;
    incomeTrustAccount: IAccount;
    marketplaceAccount: IAccount;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class IncomeTrustRouter extends React.Component<Props> {
    public componentDidMount(): void {
        const { currentInvestor } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }
    }

    public render(): JSX.Element {
        const { currentInvestor, currentUser, incomeTrustAccount, marketplaceAccount } = this.props;

        if (currentUser.role !== RoleEnum.Investor || !currentInvestor) {
            return null;
        }

        let defaultRedirect: string;
        let incomeTrustRoutes: JSX.Element[] = null;

        if (!marketplaceAccount && incomeTrustAccount && !incomeTrustAccount.isCompleted) {
            // Registration was started through the public form and must be completed there
            defaultRedirect = '/invest/income-trust/details';
        } else if (incomeTrustAccount && incomeTrustAccount.isCompleted) {
            defaultRedirect = '/income-trust';
            incomeTrustRoutes = [
                <Route key='dashboard' exact={true} path='/income-trust' component={Dashboard} />,
                <Route key='help-centre' exact={true} path='/income-trust/help-centre' component={HelpCentre} />,
                <Route key='invest' exact={true} path='/income-trust/invest' component={TopUp} />,
                <Route key='portfolio' exact={true} path='/income-trust/portfolio' component={Portfolio} />,
                <Route key='statements' exact={true} path='/income-trust/statements' component={Statements} />,
            ];
        } else {
            defaultRedirect = '/income-trust/register';
            incomeTrustRoutes = [
                <Route key='register' path='/income-trust/register' component={IncomeTrustRegister} />,
            ];
        }

        return (
            <Router history={history}>
                <ScrollToTop />
                <Switch>
                    {incomeTrustRoutes}
                    <Redirect to={defaultRedirect} />
                </Switch>
            </Router>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        currentUser: authCurrentUserSelector(state),
        incomeTrustAccount: currentInvestorAccountSelector(state, AccountTypeEnum.IncomeTrust),
        marketplaceAccount: currentInvestorAccountSelector(state, AccountTypeEnum.Marketplace),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IncomeTrustRouter);
