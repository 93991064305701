import React from 'react';
import { Container } from 'react-bootstrap';

class Footer extends React.Component {
    public render(): JSX.Element {
        return (
            <div className='footer-wrapper'>
                <Container className='footer' fluid={true}>
                    <p>
                        <strong>DISCLAIMER</strong>
                        {' '}* Any investment rate of return and terms displayed are specific to the pool of mortgages and therefore subject to the performance of those mortgage investments.
                        {' '}Any rate of return and income is targeted, are not guaranteed and are shown net of any fees.
                        {' '}Investors: The following disclaimers apply to investors in the Funding Income Trust (“the Trust”).
                        {' '}Funding.com.au Pty Ltd ACN 603 756 547 is the manager of the Trust and authorised representative (no. 1239776) of Funding Capital Pty Ltd ACN 639 230 345 (AFSL 523247).
                        {' '}Funding Capital Pty Ltd ACN 639 230 345 (AFSL 523247) is the trustee of the Trust.
                        {' '}It is important for you to read the Information Memorandum (IM) for the Trust before you make any investment decision.
                        {' '}The IM is available on our website or by calling 1300 44 33 19.
                        {' '}You should consider carefully whether or not investing in the Trust is appropriate for you.
                        {' '}The rates of return from the Trust are targeted and not guaranteed and are determined by the future revenue of the Trust and may achieve lower than expected returns.
                        {' '}Past performance is not a reliable indicator of future performance.
                        {' '}Investors risk losing some or all of their principal investment.
                        {' '}The investment is not a bank deposit.
                        {' '}IMPORTANT: Information provided is general information only and should not be taken as legal or financial advice.
                        {' '}It does not consider the specific needs, investment objectives or financial situation of any particular investor and you should seek advice from a professional financial adviser.
                        {' '}Please refer to the <a href='https://www.funding.com.au/fsg/' target='_blank'>Financial Services Guide</a> for more information.
                        {' '}See <a href='https://www.funding.com.au/privacy-policy/' target='_blank'>Privacy</a>, <a href='https://www.funding.com.au/terms-of-use/' target='_blank'>Terms</a> and <a href='https://www.funding.com.au/credit-guide/' target='_blank'>Credit Guide</a> for more details.
                    </p>
                </Container>
            </div>
        );
    }
}

export default Footer;
