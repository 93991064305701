import React from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { brokerVerifyEmailAction } from '~Broker/actions';
import Layout from './Layout';

interface IMatch {
    code: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsDispatch {
    brokerEmailVerify: () => void;
}

type Props = IProps & IPropsDispatch;

class VerifyEmail extends React.Component<Props> {
    public componentDidMount() {
        this.props.brokerEmailVerify();
    }

    public render(): JSX.Element {
        return (
            <Layout>
                <div className='broker'>
                    <Container className='email-verification'>
                        <h3>Verifying Email...</h3>
                        <Spinner animation='border'/>
                    </Container>
                </div>
            </Layout>
        );
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        brokerEmailVerify: () => dispatch(brokerVerifyEmailAction(ownProps.match.params.code)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(VerifyEmail);
