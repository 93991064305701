import React from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IGlobalState } from '~reducer';
import { currentUserGetAction } from '~User/actions';
import IUser from '~User/IUser';
import { currentUserSelector } from '~User/selectors';
import './dashboard.less';
import Layout from './Layout';

interface IPropsSelector {
    currentUser: IUser;
}

interface IPropsDispatch {
    currentUserGet: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Dashboard extends React.Component<Props> {
    public componentDidMount() {
        const { currentUser } = this.props;

        if (!currentUser) {
            this.props.currentUserGet();
        }
    }

    public render(): JSX.Element {
        const { currentUser } = this.props;

        if (!currentUser) {
            return (
                <Layout section='dashboard'>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        return (
            <Layout section='dashboard'>
                <h1>Hello {currentUser.firstName}!</h1>
                <div className='main-box'>
                    Sorry, the borrower's area is not available yet.
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentUser: currentUserSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentUserGet: () => dispatch(currentUserGetAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Dashboard);
