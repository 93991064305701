import React from 'react';
import { Redirect, Route, Router as ReactRouter, Switch } from 'react-router-dom';
import history from '~history';
import Referral from './Referral';
import Thanks from './Thanks';

class Router extends React.Component {
    public render(): JSX.Element {
        return (
            <ReactRouter history={history}>
                <Switch>
                    <Route exact={true} path='/partner-referral/' component={Referral} />
                    <Route exact={true} path='/partner-referral/thanks' component={Thanks} />
                    <Redirect to='/partner-referral/' />
                </Switch>
            </ReactRouter>
        );
    }
}

export default Router;
