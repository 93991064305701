import React from 'react';
import { Form } from 'react-bootstrap';
import { AiOutlineFileAdd } from 'react-icons/ai';

interface IProps {
    error?: string;
    file: File;
    placeholder?: string | JSX.Element;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

class Upload extends React.Component<IProps> {
    public render(): JSX.Element {
        const {
            error,
            file,
            placeholder = <React.Fragment>Upload documents <AiOutlineFileAdd/></React.Fragment>,
        } = this.props;

        return (
            <div className='file-upload-wrapper'>
                <div className='label'>
                    {file && file.name}
                    {!file && placeholder}
                </div>
                <Form.Control isInvalid={!!error} onChange={this.props.onChange} type='file' />
                {error && <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>}
            </div>
        );
    }
}

export default Upload;
