import IAggregator from '~Api/Broker/IAggregator';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import { IGlobalState } from '~reducer';
import ICountry from './ICountry';
import { IDictionary } from '~utilities/IDictionary';

export function publicAdviserRegisterErrorsSelector(state: IGlobalState): IDictionary<string> {
    return state.public.adviserRegisterErrors;
}

export function publicAdviserRegisterInProgressSelector(state: IGlobalState): boolean {
    return state.public.adviserRegisterInProgress;
}

export function publicAggregatorsSelector(state: IGlobalState): IAggregator[] {
    return state.public.aggregators;
}

export function publicCurrentInvestorSelector(state: IGlobalState): IInvestor {
    return state.public.currentInvestor;
}

export function publicCurrentInvestorAccountSelector(state: IGlobalState, accountType: AccountTypeEnum): IAccount {
    const currentInvestor: IInvestor = publicCurrentInvestorSelector(state);

    if (!currentInvestor) {
        return null;
    }

    const investorAccount: IAccount = currentInvestor.accounts[accountType];
    if (!investorAccount) {
        return null;
    }

    return investorAccount;
}

export function publicCountriesSelector(state: IGlobalState): ICountry[] {
    return state.public.countries;
}
