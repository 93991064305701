import PropertyStreetTypeEnum from '~Api/Deal/PropertyStreetTypeEnum';
import BrokerStatusEnum from './BrokerStatusEnum';
import CreationMethodEnum from './CreationMethodEnum';
import EstablishmentFeeFormatEnum from './EstablishmentFeeFormatEnum';
import IDeal from './IDeal';
import IDealProperty from './IDealProperty';
import IDealQuote from './IDealQuote';
import IDealQuoteProperty from './IDealQuoteProperty';
import LoanPurposeEnum from './LoanPurposeEnum';
import MortgageTypeEnum from './MortgageTypeEnum';
import PropertyStateEnum from './PropertyStateEnum';
import ZoneTypeEnum from './ZoneTypeEnum';

export function parseDeal(raw: any): IDeal {
    return {
        applicationUuid: raw.applicationUuid,
        brokerStatus: raw.brokerStatus as BrokerStatusEnum,
        closedTime: raw.closedTime,
        code: raw.code,
        comments: raw.comments,
        createdTime: raw.createdTime,
        email: raw.email,
        firstName: raw.firstName,
        isBroker: raw.isBroker,
        isReferralPartner: raw.isReferralPartner,
        lastName: raw.lastName,
        loanAmount: raw.loanAmount,
        loanPurpose: raw.loanPurpose as LoanPurposeEnum,
        phone: raw.phone,
        properties: raw.properties && raw.properties.map(parseProperty),
        referralPartnerCompanyName: raw.referralPartnerCompanyName,
        referralPartnerEmail: raw.referralPartnerEmail,
        referralPartnerFirstName: raw.referralPartnerFirstName,
        referralPartnerLastName: raw.referralPartnerLastName,
        referralPartnerPhone: raw.referralPartnerPhone,
        termMonths: raw.termMonths,
        uuid: raw.uuid,
    };
}

export function parseDealQuote(raw: any): IDealQuote {
    return {
        code: raw.code,
        commitmentFee: raw.commitmentFee,
        createdTime: raw.createdTime,
        creationMethod: raw.creationMethod as CreationMethodEnum,
        currentDebt: raw.currentDebt,
        dealUuid: raw.dealUuid,
        establishmentFee: raw.establishmentFee,
        establishmentFeeFormat: raw.establishmentFeeFormat as EstablishmentFeeFormatEnum,
        establishmentFeePercentage: raw.establishmentFeePercentage,
        estimatedOutlays: raw.estimatedOutlays,
        interestRate: raw.interestRate,
        legalFees: raw.legalFees,
        linkCode: raw.linkCode,
        loanAmount: raw.loanAmount,
        lvr: raw.lvr,
        mortgageType: raw.mortgageType as MortgageTypeEnum,
        netBalance: raw.netBalance,
        properties: raw.properties && raw.properties.map(parseQuoteProperty),
        propertyValue: raw.propertyValue,
        termMonths: raw.termMonths,
        uuid: raw.uuid,
    };
}

export function parseQuoteProperty(raw: any): IDealQuoteProperty {
    return {
        name: raw.name,
        uuid: raw.uuid,
    };
}

export function parseProperty(raw: any): IDealProperty {
    return {
        addressVersion: raw.addressVersion,
        currentDebt: raw.currentDebt ? Number(raw.currentDebt) : null,
        errors: {},
        estimatedValue: raw.estimatedValue ? Number(raw.estimatedValue) : null,
        postcode: raw.postcode,
        state: raw.state as PropertyStateEnum,
        streetAddress: raw.streetAddress,
        streetName: raw.streetName,
        streetNumber: raw.streetNumber,
        streetType: raw.streetType as PropertyStreetTypeEnum,
        suburb: raw.suburb,
        unitNumber: raw.unitNumber,
        uuid: raw.uuid,
        zoneType: raw.zoneType as ZoneTypeEnum,
    };
}
