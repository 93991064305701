import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Rocket from '~UI/Rocket';
import Layout from './Layout';

class Welcome extends React.Component {
    public render(): JSX.Element {
        return (
            <Layout containerClass='welcome' stepNumber={0}>
                <h3>Let's kick start your loan <Rocket/></h3>
                <p className='intro'>Here at Funding we want to save you time and money by building the fastest mortgage application on the planet.</p>
                <Link to='/application/purpose'><Button className='continue' variant='primary'>Let's begin</Button></Link>
            </Layout>
        );
    }
}

export default Welcome;
