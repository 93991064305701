import { ParsedGroup, ParsedMailbox, parseOneAddress } from 'email-addresses';

export function validateEmail(email: string): boolean {
    // Returns null for invalid formats
    const parsedAddress: ParsedMailbox | ParsedGroup = parseOneAddress(email);
    // The name condition prevents any addresses with the format: '"fname lname" <email@domain.com>'
    if (parsedAddress === null || parsedAddress.name !== null) {
        return false;
    }
    return true;
}
