import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import InterestPaymentStrategyEnum from '~Api/Investor/InterestPaymentStrategyEnum';
import {
    currentInvestorAccountIncomeTrustInterestPaymentStrategySetAction,
    currentInvestorGetAction,
} from '~Investor/actions';
import {
    currentInvestorAccountIncomeTrustInterestPaymentStrategySelector,
    currentInvestorAccountSelector,
    currentInvestorSelector,
} from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import Radio from '~UI/Radio';
import Layout from './Layout';

interface IPropsSelector {
    currentInvestor: IInvestor;
    interestPaymentStrategy: InterestPaymentStrategyEnum;
    marketplaceAccount: IAccount;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
    currentInvestorUpdateInterestPaymentStrategy: (interestPaymentStrategy: InterestPaymentStrategyEnum) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class DistributionPreference extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeInterestPaymentStrategy = this.onChangeInterestPaymentStrategy.bind(this);
    }

    public componentDidMount(): void {
        const { currentInvestor } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }
    }

    public render(): JSX.Element {
        const { currentInvestor, interestPaymentStrategy, marketplaceAccount } = this.props;

        if (!currentInvestor) {
            return (
                <Layout section='distribution-preference'>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        return (
            <Layout section='distribution-preference'>
                <h1>Registration</h1>
                <div className='content-box'>
                    <h3>Distribution Preference</h3>
                    <p className='intro'>How would you like us to treat your monthly income?</p>
                    <Form>
                        <Form.Group className='distribution-preference'>
                            <div className='form-cards'>
                                <div className={`form-card ${interestPaymentStrategy === InterestPaymentStrategyEnum.Withdraw ? 'form-card-selected' : ''}`}>
                                    <Radio
                                        checked={interestPaymentStrategy === InterestPaymentStrategyEnum.Withdraw}
                                        label='Automatic Withdraw'
                                        value={InterestPaymentStrategyEnum.Withdraw}
                                        onChange={this.onChangeInterestPaymentStrategy}
                                    />
                                    <div className='card-description'>Automatically withdraw my interest to my nominated bank account</div>
                                </div>
                                <div className={`form-card ${interestPaymentStrategy === InterestPaymentStrategyEnum.Reinvest ? 'form-card-selected' : ''}`}>
                                    <Radio
                                        checked={interestPaymentStrategy === InterestPaymentStrategyEnum.Reinvest}
                                        label='Automatic Reinvest'
                                        value={InterestPaymentStrategyEnum.Reinvest}
                                        onChange={this.onChangeInterestPaymentStrategy}
                                    />
                                    <div className='card-description'>Automatically reinvest my interest back into the Income Trust</div>
                                </div>
                            </div>
                        </Form.Group>
                        <Link to={!marketplaceAccount.isCompleted ? '/income-trust/register/bank-account' : '/income-trust/register/qualifier'} className='previous'>
                            <Button variant='primary'>Previous</Button>
                        </Link>
                        <Link to='/income-trust/register/information-memorandum' className='continue'>
                            <Button variant='primary' disabled={!interestPaymentStrategy}>Continue</Button>
                        </Link>
                    </Form>
                </div>
            </Layout>
        );
    }

    private onChangeInterestPaymentStrategy(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.currentInvestorUpdateInterestPaymentStrategy(event.target.value as InterestPaymentStrategyEnum);
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        interestPaymentStrategy: currentInvestorAccountIncomeTrustInterestPaymentStrategySelector(state),
        marketplaceAccount: currentInvestorAccountSelector(state, AccountTypeEnum.Marketplace),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
        currentInvestorUpdateInterestPaymentStrategy: (interestPaymentStrategy: InterestPaymentStrategyEnum) => dispatch(currentInvestorAccountIncomeTrustInterestPaymentStrategySetAction(interestPaymentStrategy)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DistributionPreference);
