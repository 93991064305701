import React from 'react';

class ProductGuides extends React.Component {
    public render(): JSX.Element {
        return (
            <svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'>
                <circle cx='50' cy='51.2058' r='45' fill='#E9F6F6' />
                <path d='M32.1867 20.0969C32.1867 18.949 31.7306 17.8481 30.9187 17.0363C30.1068 16.2246 29.0056 15.7686 27.8574 15.7686C27.3401 15.7742 26.8285 15.8765 26.3489 16.0702C26.078 14.3772 25.2124 12.8361 23.9075 11.7236C22.6026 10.6111 20.9438 10 19.2288 10C17.5139 10 15.8551 10.6111 14.5502 11.7236C13.2452 12.8361 12.3797 14.3772 12.1088 16.0702C11.6293 15.8762 11.1176 15.7739 10.6003 15.7686C10.0093 15.7337 9.41734 15.82 8.86086 16.0222C8.30438 16.2243 7.7951 16.538 7.3643 16.9441C6.93349 17.3501 6.59024 17.8399 6.35563 18.3834C6.12102 18.9268 6 19.5125 6 20.1045C6 20.6964 6.12102 21.2821 6.35563 21.8255C6.59024 22.369 6.93349 22.8588 7.3643 23.2649C7.7951 23.6709 8.30438 23.9846 8.86086 24.1868C9.41734 24.3889 10.0093 24.4752 10.6003 24.4403H27.8875C29.0331 24.4323 30.1289 23.9707 30.9347 23.1566C31.7405 22.3425 32.1907 21.2423 32.1867 20.0969Z' fill='#D2EDED' stroke='#16254C' stroke-width='3' stroke-linejoin='round' />
                <path d='M17.7393 80.8848L80.9486 80.8848' stroke='#16254C' stroke-width='2.7' stroke-linecap='round' stroke-linejoin='round' />
                <path d='M73.8385 36.7898V22.5305C73.8385 21.9516 73.6086 21.3965 73.1993 20.9872C72.79 20.5779 72.2348 20.3479 71.656 20.3479H65.5449C64.966 20.3479 64.4109 20.5779 64.0016 20.9872C63.5922 21.3965 63.3623 21.9516 63.3623 22.5305V27.7322L73.8385 36.7898Z' fill='#D2EDED' stroke='#16254C' stroke-width='2.70897' stroke-linejoin='round' />
                <path d='M76.5305 80.5336H24.1855V49.5049L50.358 26.8064L76.5305 49.5049V80.5336Z' fill='white' />
                <path d='M50.358 26.8064L24.1855 49.5049V54.7431L47.8299 34.2816C48.5488 33.6644 49.465 33.3251 50.4126 33.3251C51.3601 33.3251 52.2763 33.6644 52.9952 34.2816L76.6396 54.7431V49.5049L50.358 26.8064Z' fill='#F0F0F0' />
                <path d='M76.5305 80.5336H24.1855V49.5049L50.358 26.8064L76.5305 49.5049V80.5336Z' stroke='#16254C' stroke-width='3' stroke-linejoin='round' />
                <path d='M21.6415 50.4317C21.0734 50.4341 20.5117 50.3116 19.9962 50.0727C19.4808 49.8338 19.0241 49.4845 18.6587 49.0494C17.9889 48.255 17.656 47.2303 17.7311 46.1939C17.7745 45.6793 17.9189 45.1783 18.1561 44.7195C18.3933 44.2607 18.7186 43.8532 19.1134 43.5203L47.814 18.6574C48.5277 18.0594 49.4292 17.7317 50.3603 17.7317C51.2914 17.7317 52.1929 18.0594 52.9066 18.6574L81.6072 43.5203C82.002 43.8532 82.3273 44.2607 82.5645 44.7195C82.8017 45.1783 82.9461 45.6793 82.9895 46.1939C83.0646 47.2303 82.7317 48.255 82.0619 49.0494C81.6965 49.4845 81.2398 49.8338 80.7244 50.0727C80.2089 50.3116 79.6472 50.4341 79.0791 50.4317C78.1469 50.4359 77.2438 50.107 76.5327 49.5041L50.3603 26.8056L24.1878 49.5041C23.4768 50.107 22.5737 50.4359 21.6415 50.4317Z' fill='#81D2D4' />
                <path d='M19.1137 47.9036L47.8143 23.0225C48.5215 22.4107 49.4254 22.074 50.3606 22.074C51.2957 22.074 52.1996 22.4107 52.9069 23.0225L81.6075 47.9036C81.8821 48.1339 82.1211 48.4036 82.3168 48.7038C82.818 47.974 83.0554 47.095 82.9898 46.2121C82.9464 45.6975 82.802 45.1964 82.5648 44.7377C82.3276 44.2789 82.0023 43.8714 81.6075 43.5385L52.9069 18.6574C52.1932 18.0594 51.2917 17.7317 50.3606 17.7317C49.4295 17.7317 48.528 18.0594 47.8143 18.6574L19.1137 43.5203C18.7189 43.8532 18.3935 44.2607 18.1564 44.7195C17.9192 45.1782 17.7748 45.6793 17.7314 46.1939C17.6658 47.0769 17.9032 47.9558 18.4044 48.6857C18.6015 48.3919 18.8405 48.1284 19.1137 47.9036Z' fill='#D2EDED' />
                <path d='M21.6415 50.4317C21.0734 50.4341 20.5117 50.3116 19.9962 50.0727C19.4808 49.8338 19.0241 49.4845 18.6587 49.0494C17.9889 48.255 17.656 47.2303 17.7311 46.1939C17.7745 45.6793 17.9189 45.1783 18.1561 44.7195C18.3933 44.2607 18.7186 43.8532 19.1134 43.5203L47.814 18.6574C48.5277 18.0594 49.4292 17.7317 50.3603 17.7317C51.2914 17.7317 52.1929 18.0594 52.9066 18.6574L81.6072 43.5203C82.002 43.8532 82.3273 44.2607 82.5645 44.7195C82.8017 45.1783 82.9461 45.6793 82.9895 46.1939C83.0646 47.2303 82.7317 48.255 82.0619 49.0494C81.6965 49.4845 81.2398 49.8338 80.7244 50.0727C80.2089 50.3116 79.6472 50.4341 79.0791 50.4317C78.1469 50.4359 77.2438 50.107 76.5327 49.5041L50.3603 26.8056L24.1878 49.5041C23.4768 50.107 22.5737 50.4359 21.6415 50.4317V50.4317Z' stroke='#16254C' stroke-width='3' stroke-linejoin='round' />
                <path d='M65.9814 20.3481H71.2196V17.2925C71.2053 16.7232 70.9691 16.182 70.5613 15.7844C70.1536 15.3868 69.6065 15.1644 69.037 15.1646H68.164C67.5852 15.1646 67.03 15.3945 66.6207 15.8038C66.2114 16.2131 65.9814 16.7683 65.9814 17.3471V20.3481Z' fill='#81D2D4' stroke='#16254C' stroke-width='2' stroke-linejoin='round' />
                <path d='M31.1694 75.2932H48.4661C48.9485 75.2932 49.4111 75.4848 49.7522 75.8259C50.0933 76.167 50.2849 76.6296 50.2849 77.112V80.5313H29.3506V77.112C29.3506 76.6296 29.5422 76.167 29.8833 75.8259C30.2244 75.4848 30.687 75.2932 31.1694 75.2932Z' fill='white' stroke='#16254C' stroke-width='2.86146' stroke-linejoin='round' />
                <path d='M47.6652 75.2942H31.9691V63.5266C31.9179 62.4642 32.083 61.4025 32.4543 60.4057C32.8256 59.409 33.3954 58.4981 34.1292 57.7281C34.863 56.9581 35.7455 56.3451 36.7232 55.9263C37.7009 55.5075 38.7535 55.2915 39.8172 55.2915C40.8808 55.2915 41.9334 55.5075 42.9111 55.9263C43.8888 56.3451 44.7713 56.9581 45.5051 57.7281C46.2389 58.4981 46.8087 59.409 47.18 60.4057C47.5513 61.4025 47.7164 62.4642 47.6652 63.5266V75.2942Z' fill='#D2EDED' stroke='#16254C' stroke-width='2.86146' stroke-linejoin='round' />
                <path d='M42.4297 68.7464V66.1455' stroke='#16254C' stroke-width='1.92571' stroke-linecap='round' stroke-linejoin='round' />
                <path d='M50.285 47.831C53.1779 47.831 55.5231 45.4858 55.5231 42.5929C55.5231 39.6999 53.1779 37.3547 50.285 37.3547C47.3921 37.3547 45.0469 39.6999 45.0469 42.5929C45.0469 45.4858 47.3921 47.831 50.285 47.831Z' fill='#D2EDED' />
                <path d='M53.9771 38.8097C53.248 38.0849 52.3217 37.5908 51.3137 37.3887C50.3057 37.1867 49.2605 37.2858 48.3084 37.6736C47.3563 38.0615 46.5394 38.7209 45.9594 39.5697C45.3794 40.4186 45.0621 41.4193 45.0469 42.4473C45.0522 43.1256 45.1915 43.7961 45.4568 44.4204C45.7221 45.0446 46.1082 45.6103 46.5928 46.0849L53.9771 38.8097Z' fill='#59C5C7' />
                <path d='M50.285 47.831C53.1779 47.831 55.5231 45.4858 55.5231 42.5929C55.5231 39.6999 53.1779 37.3547 50.285 37.3547C47.3921 37.3547 45.0469 39.6999 45.0469 42.5929C45.0469 45.4858 47.3921 47.831 50.285 47.831Z' stroke='#16254C' stroke-width='2.86146' stroke-linecap='round' stroke-linejoin='round' />
                <path d='M66.8001 56.9792H59.9614C58.9569 56.9792 58.1426 57.7935 58.1426 58.798V70.8566C58.1426 71.8611 58.9569 72.6754 59.9614 72.6754H66.8001C67.8046 72.6754 68.6189 71.8611 68.6189 70.8566V58.798C68.6189 57.7935 67.8046 56.9792 66.8001 56.9792Z' fill='#D2EDED' />
                <path d='M68.5643 58.9977C68.5232 58.4557 68.2796 57.9489 67.8819 57.5782C67.4843 57.2076 66.9617 57.0001 66.4181 56.9971H60.3251C59.7463 56.9971 59.1911 57.227 58.7818 57.6363C58.3725 58.0456 58.1426 58.6008 58.1426 59.1796V69.4558L68.5643 58.9977Z' fill='#59C5C7' />
                <path d='M66.8001 56.9792H59.9614C58.9569 56.9792 58.1426 57.7935 58.1426 58.798V70.8566C58.1426 71.8611 58.9569 72.6754 59.9614 72.6754H66.8001C67.8046 72.6754 68.6189 71.8611 68.6189 70.8566V58.798C68.6189 57.7935 67.8046 56.9792 66.8001 56.9792Z' stroke='#16254C' stroke-width='2.86146' stroke-linecap='round' stroke-linejoin='round' />
            </svg>
        );
    }
}

export default ProductGuides;
