import React from 'react';
import Lottie, { Options } from 'react-lottie';
import success from '~UI/success.json';
import Layout from './Layout';

class Thanks extends React.Component {
    public render(): JSX.Element {
        const options: Options = {
            animationData: success,
            autoplay: true,
            loop: false,
        };

        return (
            <Layout finePrint={null} showProgressBar={false} currentStep={7}>
                <div className='thanks'>
                    <h3>That&apos;s it. All done. Virtual high fives all round.</h3>
                    <p>To complete your Quote we need a little more information from you.</p>
                    <p>Our team will be in touch soon.</p>
                    <Lottie height={180} options={options} width={180} />
                </div>
            </Layout>
        );
    }
}

export default Thanks;
