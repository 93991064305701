import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IInvestor from '~Api/Investor/IInvestor';
import { marketplaceAccountCompleteAction } from '~Investor/actions';
import { IGlobalState } from '~reducer';
import Checkbox from '~UI/Checkbox';
import { currentInvestorGetAction } from '~Investor/actions';
import {
    currentInvestorSelector,
    registerInProgressSelector,
} from '~Investor/selectors';
import Layout from './Layout';
import { productDisclosureStatement } from '~utilities/ProductDisclosureStatement';

interface IState {
    agreePds: boolean;
}

interface IPropsSelector {
    currentInvestor: IInvestor;
    registerInProgress: boolean;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
    marketplaceAccountComplete: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class ProductDisclosure extends React.Component<Props, IState> {
    public state: IState = {
        agreePds: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickComplete = this.onClickComplete.bind(this);

        this.onChangeAgreePds = this.onChangeAgreePds.bind(this);
    }

    public componentDidMount(): void {
        const { currentInvestor } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }
    }

    public render(): JSX.Element {
        const { registerInProgress } = this.props;
        const { agreePds } = this.state;

        return (
            <Layout section='product-disclosure'>
                <h1>Registration</h1>
                <div className='content-box'>
                    <h3>Product Disclosure Statement</h3>
                    <p className='intro'>Welcome, you will now be able to see our investments.</p>
                    <p>
                        {productDisclosureStatement }
                    </p>
                    <Form>
                        <Form.Group className='bank-name'>
                            <Checkbox
                                checked={agreePds}
                                label={<React.Fragment>I/We have read and agree to the <a href='https://www.funding.com.au/pds/' target='_blank' rel="noreferrer">PDS</a>.</React.Fragment>}
                                onChange={this.onChangeAgreePds}
                            />
                        </Form.Group>
                        <Button className='continue' disabled={!agreePds || registerInProgress} onClick={this.onClickComplete} variant='primary'>
                            {registerInProgress && <Spinner animation='border' as='span' role='status' size='sm' />}
                            {!registerInProgress && 'Done'}
                        </Button>
                    </Form>
                </div>
            </Layout>
        );
    }

    private onClickComplete(): void {
        this.props.marketplaceAccountComplete();
    }

    private onChangeAgreePds(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            agreePds: event.currentTarget.checked,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        registerInProgress: registerInProgressSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
        marketplaceAccountComplete: () => dispatch(marketplaceAccountCompleteAction(productDisclosureStatement)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductDisclosure);
