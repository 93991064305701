import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { Spinner, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IIncomeTrust from '~Api/Investor/IIncomeTrust';
import IInvestor from '~Api/Investor/IInvestor';
import IncomeTrustClassTypeEnum from '~Api/Investor/IncomeTrustClassTypeEnum';
import {
    currentInvestorGetAction,
    incomeTrustsListAction,
} from '~Investor/actions';
import {
    currentInvestorAccountSelector,
    currentInvestorSelector,
    incomeTrustsSelector,
} from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import './portfolio.less';
import IncomeTrustClassTypeLabels from '~Api/Investor/IncomeTrustClassTypeLabels';

interface IPropsSelector {
    currentInvestor: IInvestor;
    incomeTrustAccount: IAccount;
    incomeTrusts: IDictionary<IIncomeTrust>;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
    incomeTrustsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Portfolio extends React.Component<Props> {
    public componentDidMount(): void {
        const { currentInvestor, incomeTrusts } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }

        if (!incomeTrusts) {
            this.props.incomeTrustsList();
        }
    }

    public render(): JSX.Element {
        const { currentInvestor, incomeTrustAccount, incomeTrusts } = this.props;

        if (!currentInvestor || !incomeTrusts) {
            return (
                <Layout section='income-trust-portfolio'>
                    <h1>My Portfolio</h1>
                    <div className='content-box'>
                        <Spinner animation='border' />
                    </div>
                </Layout>
            );
        }

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const incomeTrustsTableRows: JSX.Element[] = _.map(incomeTrusts, (incomeTrust: IIncomeTrust) => {
            if (![IncomeTrustClassTypeEnum.ClassC, IncomeTrustClassTypeEnum.ClassD].includes(incomeTrust.classType)) {
                return;
            }

            return (
                <tr key={incomeTrust.uuid}>
                    <td className='investment' data-label='Investment'>
                        {IncomeTrustClassTypeLabels[incomeTrust.classType]}
                    </td>
                    <td className='invested' data-label='Invested'>
                        {numeral(incomeTrust.principalBalance).format('$0,0[.]00')}
                    </td>
                    <td className='target-return' data-label='Target Return'>
                        {percentageFormatter.format(incomeTrust.interestRate)}
                    </td>
                    <td className='interest-earned' data-label='Interest Earned'>
                        {numeral(incomeTrust.interestBalance).format('$0,0[.]00')}
                    </td>
                </tr>
            );
        });

        return (
            <Layout section='income-trust-portfolio'>
                <h1>My Portfolio</h1>
                <div className='content-box'>
                    <div className='boxes'>
                        <div className='box'>
                            <div className='primary'>
                                <div className='label'>Total Portfolio</div>
                                <div className='value'>{numeral(incomeTrustAccount.balance + incomeTrustAccount.investedAmountCurrent).format('$0,0[.]00')}</div>
                            </div>
                        </div>
                        <div className='box'>
                            <div className='primary'>
                                <div className='label'>Total Income</div>
                                <div className='value'>{numeral(incomeTrustAccount.interestAmountEarned).format('$0,0[.]00')}</div>
                            </div>
                        </div>
                    </div>
                    <Table className='income-trusts' bordered={true}>
                        <thead>
                            <tr>
                                <th className='investment'>Investment</th>
                                <th className='invested'>Invested</th>
                                <th className='target-return'>Target Return</th>
                                <th className='interest-earned'>Interest Earned</th>
                            </tr>
                        </thead>
                        <tbody>
                            {incomeTrustsTableRows}
                        </tbody>
                    </Table>
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        incomeTrustAccount: currentInvestorAccountSelector(state, AccountTypeEnum.IncomeTrust),
        incomeTrusts: incomeTrustsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
        incomeTrustsList: () => dispatch(incomeTrustsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Portfolio);
