import React from 'react';

class Radio extends React.Component {
    public render(): JSX.Element {
        return (
            <div className='badges'>
                <img src='https://www.funding.com.au/wp-content/uploads/2024/04/funding-logos-v2-3.png' />
                <img src='https://www.funding.com.au/wp-content/uploads/2024/04/funding-logos-v2-8.png' />
                <img src='https://www.funding.com.au/wp-content/uploads/2024/04/funding-logos-v2-2.png' />
                <img src='https://www.funding.com.au/wp-content/uploads/2024/04/funding-logos-v2-1.png' />
                <img src='https://www.funding.com.au/wp-content/uploads/2024/04/funding-logos-v2-10.png' />
                <img src='https://www.funding.com.au/wp-content/uploads/2024/04/funding-logos-v2-9.png' />
                <img src='https://www.funding.com.au/wp-content/uploads/2024/04/funding-logos-v2-5.png' />
                <img src='https://www.funding.com.au/wp-content/uploads/2024/04/funding-logos-v2-7.png' />
                <img src='https://www.funding.com.au/wp-content/uploads/2024/04/funding-logos-v2-6.png' />
                <img src='https://www.funding.com.au/wp-content/uploads/2024/04/funding-logos-v2-4.png' />
            </div>
        );
    }
}

export default Radio;
