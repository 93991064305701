import React from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { publicInvestReferredAction } from '~Public/actions';
import Layout from './Layout';

interface IMatch {
    referrerCode: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsDispatch {
    getToken: (referrerCode: string) => void;
}

type Props = IProps & IPropsDispatch;

class Referred extends React.Component<Props> {
    public componentDidMount() {
        const { match: { params: { referrerCode } } } = this.props;

        this.props.getToken(referrerCode);
    }

    public render(): JSX.Element {
        return (
            <Layout>
                <Container className='register'>
                    <Spinner animation='border' />
                </Container>
            </Layout>
        );
    }
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        getToken: (referrerCode: string) => dispatch(publicInvestReferredAction(referrerCode)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(Referred);
