import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import roundTo from 'round-to';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import {
    currentInvestorGetAction,
    topUpAction,
    topUpCompleteModalHideAction,
    topUpConfirmModalShowAction,
    topUpErrorSetAction,
    topUpModalHideAction,
    topUpModalShowAction,
} from '~Investor/actions';
import {
    currentInvestorAccountSelector,
    currentInvestorSelector,
    topUpCompleteModalShowSelector,
    topUpConfirmModalShowSelector,
    topUpErrorsSelector,
    topUpInProgressSelector,
    topUpModalShowSelector,
} from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import BpayLogo from '~UI/BpayLogo';
import './top-up-modal.less';
import { IDictionary } from '~utilities/IDictionary';

interface IState {
    amount: number;
}

interface IPropsSelector {
    currentInvestor: IInvestor;
    isTopUpCompleteModalOpen: boolean;
    isTopUpConfirmModalOpen: boolean;
    marketplaceAccount: IAccount;
    show: boolean;
    topUpErrors: IDictionary<string>;
    topUpInProgress: boolean;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
    hide: () => void;
    setError: (key: string, value: string) => void;
    topUp: (amount: number) => void;
    topUpCompleteModalHide: () => void;
    topUpConfirmModalShow: () => void;
    topUpModalShow: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class TopUpModal extends React.Component<Props, IState> {
    public state: IState = {
        amount: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
        this.validateAmount = this.validateAmount.bind(this);
    }

    public render(): JSX.Element {
        const { isTopUpCompleteModalOpen, marketplaceAccount, show, topUpErrors, topUpInProgress } = this.props;
        const { amount } = this.state;

        const minimumAmount: number = marketplaceAccount.transferCountTotal > 0 ? 1000 : 5000;

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        if (isTopUpCompleteModalOpen) {
            return (
                <Modal
                    backdropClassName='investor-marketplace-top-up-modal-backdrop'
                    dialogClassName='investor-marketplace-top-up-modal complete'
                    onHide={this.props.topUpCompleteModalHide}
                    show={isTopUpCompleteModalOpen}
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title>Thankyou for submitting a top up request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='title'>Pay via BPAY</p>
                        <p className='intro'>
                            Use BPAY to transfer below amount from your account and send remittance advice to <a href='mailto:invest@funding.com.au'>invest@funding.com.au</a>.
                        </p>
                        <div className='bpay-box'>
                            <div className='logo'><BpayLogo /></div>
                            <div className='details'>
                                <div>
                                    <strong>Biller Code:</strong> 358275
                                </div>
                                <div>
                                    <strong>Ref:</strong> {marketplaceAccount.code}
                                </div>
                            </div>
                            <div className='notes'>
                                <strong>Telephone &amp; Internet Banking – BPAY<sup>&reg;</sup></strong><br />
                                Contact your bank or financial institution to make this<br />
                                payment from your cheque, savings, debit, credit card<br />
                                or transaction account. More info:<a href='https://www.bpay.com.au' target='_blank' rel='noreferrer'>www.bpay.com.au</a><br />
                            </div>
                        </div>
                        <hr className='spacing' />
                        <p className='title'>Pay via Bank Transfer</p>
                        <p className='intro'>
                            Please transfer below amount to given bank details and send remittance advice to <a href='mailto:invest@funding.com.au'>invest@funding.com.au</a>.
                        </p>
                        <div>
                            <div className='label'>Account Name:</div>
                            <div className='value'>Sandhurst Trustees Limited ACF Funding Investment Trust – Applications Account</div>
                        </div>
                        <div>
                            <div className='label'>BSB:</div>
                            <div className='value'>084 917</div>
                        </div>
                        <div>
                            <div className='label'>Account Number:</div>
                            <div className='value'>71 878 9047</div>
                        </div>
                        <div>
                            <div className='label'>Bank:</div>
                            <div className='value'>National Australia Bank</div>
                        </div>
                        <div>
                            <div className='label'>Amount:</div>
                            <div className='value'>{currencyFormatter.format(amount)}</div>
                        </div>
                        <div>
                            <div className='label'>Payment Reference:</div>
                            <div className='value'>{marketplaceAccount.code}</div>
                        </div>
                        <br />
                        <Button onClick={this.props.topUpCompleteModalHide}>OK</Button>
                    </Modal.Body>
                </Modal>
            );
        }

        const firstUnhandledError: string = _.head(_.values(_.omit(topUpErrors, ['amount'])));

        return (
            <Modal
                backdropClassName='investor-marketplace-top-up-modal-backdrop'
                dialogClassName='investor-marketplace-top-up-modal'
                onHide={this.props.hide}
                show={show}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Top Up Funds</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {firstUnhandledError && <Alert variant='danger'>{firstUnhandledError}</Alert>}
                    <p className='instructions-1'>Enter the dollar amount you wish to add to your account.</p>
                    <Form.Group className='amount'>
                        <Form.Label>Amount in AUD $</Form.Label>
                        <Form.Control isInvalid={!!topUpErrors.amount} onBlur={this.validateAmount} onChange={this.onChangeAmount} placeholder={`e.g. ${minimumAmount}`} type='number' value={amount || ''} />
                        {!!topUpErrors.amount && <Form.Control.Feedback type='invalid'>{topUpErrors.amount}</Form.Control.Feedback>}
                    </Form.Group>
                    <p className='instructions-2'>Once you click submit you will be advised of the bank account details where you are to transfer the funds to.</p>
                    <Button className='top-up' disabled={topUpInProgress} onClick={this.onClickOk} variant='primary'>
                        {topUpInProgress && <Spinner animation='border' size='sm' />} Submit
                    </Button>
                </Modal.Body>
            </Modal>
        );
    }

    private onChangeAmount(event: React.ChangeEvent<HTMLInputElement>): void {
        if (/[^0-9.]/.test(event.target.value)) {
            return;
        }

        this.setState({
            amount: roundTo(Number(event.target.value), 2),
        });
    }

    private onClickOk(): void {
        const { amount } = this.state;

        let valid: boolean = true;
        valid = this.validateAmount() && valid;

        if (!valid) {
            return;
        }

        this.props.topUp(amount);
    }

    private validateAmount(): boolean {
        const { marketplaceAccount } = this.props;
        const { amount } = this.state;

        let error: string;

        if (!amount) {
            error = 'Please enter the amount';
        } else {
            const minimumAmount: number = marketplaceAccount.transferCountTotal > 0 ? 1000 : 5000;
            if (amount < minimumAmount) {
                error = `The minimum amount is ${numeral(minimumAmount).format('$0,0[.]00')}`;
            }
        }

        this.props.setError('amount', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        isTopUpCompleteModalOpen: topUpCompleteModalShowSelector(state),
        isTopUpConfirmModalOpen: topUpConfirmModalShowSelector(state),
        marketplaceAccount: currentInvestorAccountSelector(state, AccountTypeEnum.Marketplace),
        show: topUpModalShowSelector(state),
        topUpErrors: topUpErrorsSelector(state),
        topUpInProgress: topUpInProgressSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
        hide: () => dispatch(topUpModalHideAction()),
        setError: (key: string, value: string) => dispatch(topUpErrorSetAction(key, value)),
        topUp: (amount: number) => dispatch(topUpAction(amount, AccountTypeEnum.Marketplace)),
        topUpCompleteModalHide: () => dispatch(topUpCompleteModalHideAction()),
        topUpConfirmModalShow: () => dispatch(topUpConfirmModalShowAction()),
        topUpModalShow: () => dispatch(topUpModalShowAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopUpModal);
