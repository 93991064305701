import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { Provider } from 'react-redux';
import store from '~store';
import './global.less';
import Router from './router';

dayjs.extend(timezone);
dayjs.extend(utc);

class App extends React.Component {
    public render(): JSX.Element {
        return (
            <Provider store={store}>
                <Router/>
            </Provider>
        );
    }
}

export default App;
