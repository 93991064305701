import { Action } from 'redux';
import AuthActionsEnum from './ActionsEnum';

export interface IAuthLoginAction extends Action {
    email: string;
    password: string;
}

export function authLoginAction(email: string, password: string): IAuthLoginAction {
    return {
        email,
        password,
        type: AuthActionsEnum.Login,
    };
}

export interface IAuthLoginErrorSetAction extends Action {
    key: string;
    value: string;
}

export function authLoginErrorSetAction(key: string, value: string): IAuthLoginErrorSetAction {
    return {
        key,
        type: AuthActionsEnum.LoginErrorSet,
        value,
    };
}

export interface IAuthLoginErrorsSetAction extends Action {
    errors: any;
}

export function authLoginErrorsSetAction(errors: any): IAuthLoginErrorsSetAction {
    return {
        errors,
        type: AuthActionsEnum.LoginErrorsSet,
    };
}

export interface IAuthLoginInProgressSetAction extends Action {
    inProgress: boolean;
}

export function authLoginInProgressSetAction(inProgress: boolean): IAuthLoginInProgressSetAction {
    return {
        inProgress,
        type: AuthActionsEnum.LoginInProgressSet,
    };
}

export interface IAuthLogoutAction extends Action {
    redirect?: string;
}

export function authLogoutAction(redirect?: string): IAuthLogoutAction {
    return {
        redirect,
        type: AuthActionsEnum.Logout,
    };
}

export interface IAuthMagicLinkCreateAction extends Action {
    email: string;
}

export function authMagicLinkCreateAction(email: string): IAuthMagicLinkCreateAction {
    return {
        email,
        type: AuthActionsEnum.MagicLinkCreate,
    };
}

export interface IAuthMagicLinkCreateErrorSetAction extends Action {
    key: string;
    value: string;
}

export function authMagicLinkCreateErrorSetAction(key: string, value: string): IAuthMagicLinkCreateErrorSetAction {
    return {
        key,
        type: AuthActionsEnum.MagicLinkCreateErrorSet,
        value,
    };
}

export interface IAuthMagicLinkCreateErrorsSetAction extends Action {
    errors: any;
}

export function authMagicLinkCreateErrorsSetAction(errors: any): IAuthMagicLinkCreateErrorsSetAction {
    return {
        errors,
        type: AuthActionsEnum.MagicLinkCreateErrorsSet,
    };
}

export interface IAuthMagicLinkCreateInProgressSetAction extends Action {
    inProgress: boolean;
}

export function authMagicLinkCreateInProgressSetAction(inProgress: boolean): IAuthMagicLinkCreateInProgressSetAction {
    return {
        inProgress,
        type: AuthActionsEnum.MagicLinkCreateInProgressSet,
    };
}

export interface IAuthMagicLinkRedeemAction extends Action {
    code: string;
}

export function authMagicLinkRedeemAction(code: string): IAuthMagicLinkRedeemAction {
    return {
        code,
        type: AuthActionsEnum.MagicLinkRedeem,
    };
}

export interface IAuthMagicLinkRedeemErrorSetAction extends Action {
    error: string;
}

export function authMagicLinkRedeemErrorSetAction(error: string): IAuthMagicLinkRedeemErrorSetAction {
    return {
        error,
        type: AuthActionsEnum.MagicLinkRedeemErrorSet,
    };
}

export interface IAuthRegisterInvestorAction extends Action {
    accountType: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    phone: string;
    successUrl: string;
}

export function authRegisterInvestorAction(accountType: string, email: string, firstName: string, lastName: string, password: string, phone: string, successUrl: string): IAuthRegisterInvestorAction {
    return {
        accountType,
        email,
        firstName,
        lastName,
        password,
        phone,
        successUrl,
        type: AuthActionsEnum.RegisterInvestor,
    };
}

export interface IAuthRegisterErrorSetAction extends Action {
    key: string;
    value: string;
}

export function authRegisterErrorSetAction(key: string, value: string): IAuthRegisterErrorSetAction {
    return {
        key,
        type: AuthActionsEnum.RegisterErrorSet,
        value,
    };
}

export interface IAuthRegisterErrorsSetAction extends Action {
    errors: any;
}

export function authRegisterErrorsSetAction(errors: any): IAuthRegisterErrorsSetAction {
    return {
        errors,
        type: AuthActionsEnum.RegisterErrorsSet,
    };
}

export interface IAuthRegisterInProgressSetAction extends Action {
    inProgress: boolean;
}

export function authRegisterInProgressSetAction(inProgress: boolean): IAuthRegisterInProgressSetAction {
    return {
        inProgress,
        type: AuthActionsEnum.RegisterInProgressSet,
    };
}

export interface IAuthRegisterMarketplaceQualifierAnswerSetAction extends Action {
    questionAnswerIndex: number;
    questionIndex: number;
}

export function authRegisterMarketplaceQualifierAnswerSetAction(questionIndex: number, questionAnswerIndex: number): IAuthRegisterMarketplaceQualifierAnswerSetAction {
    return {
        questionAnswerIndex,
        questionIndex,
        type: AuthActionsEnum.RegisterMarketplaceQualifierAnswerSet,
    };
}

export interface IAuthTokenSetAction extends Action {
    token: any;
}

export function authTokenSetAction(token: any): IAuthTokenSetAction {
    return {
        token,
        type: AuthActionsEnum.TokenSet,
    };
}
