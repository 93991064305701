import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router as ReactRouter, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import Application from '~Application';
import { authTokenSetAction } from '~Auth/actions';
import ForgotPassword from '~Auth/ForgotPassword';
import ForgotPasswordSent from '~Auth/ForgotPasswordSent';
import IAuthUser from '~Auth/IAuthUser';
import Login from '~Auth/Login';
import LoginLink from '~Auth/LoginLink';
import RoleEnum from '~Auth/RoleEnum';
import {
    authCurrentUserSelector,
    authTokenSelector,
} from '~Auth/selectors';
import Borrow from '~Borrow';
import BorrowerRouter from '~Borrower';
import BrokerRouter from '~Broker';
import InvestorRouter from '~Investor';
import Lead from '~Lead';
import PartnerReferral from '~PartnerReferral/router';
import Adviser from '~Public/Adviser';
import Broker from '~Public/Broker';
import Invest from '~Public/Invest';
import Partner from '~Public/ReferralPartnerRegister/router';
import { IGlobalState } from '~reducer';
import ScrollToTop from '~ScrollToTop';
import VerifyEmail from '~User/VerifyEmail';
import VerifyEmailVerified from '~User/VerifyEmailVerified';
import history from './history';

interface IPropsSelector {
    currentUser: IAuthUser;
    token: any;
}

interface IPropsDispatch {
    setToken: (token: any) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Router extends React.Component<Props> {
    public componentDidMount(): void {
        const { token } = this.props;

        this.props.setToken(token);
    }

    public render(): JSX.Element {
        const { currentUser } = this.props;

        if (currentUser.role === RoleEnum.Borrower) {
            return <BorrowerRouter/>;
        }

        if (currentUser.role === RoleEnum.Broker) {
            return <BrokerRouter/>;
        }

        if (currentUser.role === RoleEnum.Investor) {
            return <InvestorRouter/>;
        }

        return (
            <ReactRouter history={history}>
                <ScrollToTop/>
                <Switch>
                    <Route exact={true} path='/' component={Login} />
                    <Route exact={true} path='/forgot-password' component={ForgotPassword} />
                    <Route exact={true} path='/forgot-password/sent' component={ForgotPasswordSent} />
                    <Route exact={true} path='/investor/signup/:referrerCode' component={Invest} />
                    <Redirect exact={true} from='/investorapi/signup/:referrerCode' to='/investor/signup/:referrerCode' />
                    <Route exact={true} path='/borrowapi/signup/:referrerCode' component={Lead} />
                    <Route exact={true} path='/login-link/:code' component={LoginLink} />
                    <Route exact={true} path='/verify-email/verified' component={VerifyEmailVerified} />
                    <Route exact={true} path='/verify-email/:code' component={VerifyEmail} />
                    <Redirect exact={true} from='/investor_login' to='/' />

                    <Route path='/application' component={Application} />
                    <Route path='/borrow' component={Lead} />
                    <Route path='/broker' component={Broker} />
                    <Route path='/adviser' component={Adviser} />
                    <Route path='/get-your-quote' component={Borrow} />
                    <Route path='/invest' component={Invest} />
                    <Route path='/partner' component={Partner} />
                    <Route path='/partner-referral' component={PartnerReferral} />
                    <Route path='/quote' component={Application} />

                    <Redirect to='/' />
                </Switch>
            </ReactRouter>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentUser: authCurrentUserSelector(state),
        token: authTokenSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        setToken: (token: any) => dispatch(authTokenSetAction(token)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Router);
