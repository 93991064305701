import React from 'react';
import { Container } from 'react-bootstrap';
import PublicLayout from '~Public/Layout';

class QuoteConvertError extends React.Component {
    public render(): JSX.Element {
        return (
            <PublicLayout>
                <div className='application'>
                    <Container className='quote'>
                        <h3>Error</h3>
                        <p>The link has expired or is invalid.</p>
                    </Container>
                </div>
            </PublicLayout>
        );
    }
}

export default QuoteConvertError;
