import React from 'react';
import { Redirect, Route, Router as ReactRouter, Switch } from 'react-router-dom';
import history from '~history';
import IncomeTrust from './IncomeTrust';
import InvestorAccountType from './InvestorAccountType';
import Marketplace from './Marketplace';
import Referred from './Referred';

class Router extends React.Component {
    public render(): JSX.Element {
        return (
            <ReactRouter history={history}>
                <Switch>
                    <Route exact={true} path='/invest/account-type' component={InvestorAccountType} />
                    <Route exact={true} path='/investor/signup/:referrerCode' component={Referred} />
                    <Redirect exact={true} from='/investorapi/signup/:referrerCode' to='/investor/signup/:referrerCode' />

                    <Route path='/invest/marketplace' component={Marketplace} />
                    <Route path='/invest/income-trust' component={IncomeTrust} />

                    <Redirect exact={true} from='/invest' to='/invest/marketplace' />
                    <Redirect exact={true} from='/invest/details' to='/invest/marketplace/details' />
                    <Redirect exact={true} from='/invest/bank-account' to='/invest/marketplace/bank-account' />
                    <Redirect exact={true} from='/invest/pds' to='/invest/marketplace/pds' />

                    <Redirect to='/invest/marketplace' />
                </Switch>
            </ReactRouter>
        );
    }
}

export default Router;
