import React from 'react';
import { Redirect, Route, Router as ReactRouter, Switch } from 'react-router-dom';
import history from '~history';
import Register from '~Public/ReferralPartnerRegister/Register';
import Thanks from '~Public/ReferralPartnerRegister/Thanks';

class Router extends React.Component {
    public render(): JSX.Element {
        return (
            <ReactRouter history={history}>
                <Switch>
                    <Route exact={true} path='/partner/register' component={Register} />
                    <Route exact={true} path='/partner/register/thanks' component={Thanks} />
                    <Redirect to='/partner/register' />
                </Switch>
            </ReactRouter>
        );
    }
}

export default Router;
