import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IDeal, { IDealErrors } from '~Api/Deal/IDeal';
import history from '~history';
import {
    dealErrorSetAction,
    dealGetAction,
    dealValueSetAction,
} from '~Lead/actions';
import {
    dealSelector,
    errorsSelector,
    tokenSelector,
} from '~Lead/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import IToken from '~Auth/IToken';

interface IPropsSelector {
    deal: IDeal;
    errors: IDealErrors;
    borrowToken: IToken;
}

interface IPropsDispatch {
    dealGet: () => void;
    setError: (key: keyof IDealErrors, value: string) => void;
    setValue: (key: keyof IDeal, value: boolean|number|string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class LoanAmount extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickContinue = this.onClickContinue.bind(this);
        this.onChangeLoanAmount = this.onChangeLoanAmount.bind(this);
        this.validateLoanAmount = this.validateLoanAmount.bind(this);
    }

    public componentDidMount(): void {
        const { borrowToken, deal } = this.props;

        if (borrowToken && borrowToken.dealUuid && !deal.uuid) {
            this.props.dealGet();
        }
    }

    public render(): JSX.Element {
        const { borrowToken, deal, errors } = this.props;

        if (borrowToken && borrowToken.dealUuid && !deal.uuid) {
            return (
                <Layout currentStep={2}>
                    <h3>You&apos;re on your way.</h3>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            style: 'currency',
        });

        return (
            <Layout currentStep={2}>
                <h3>You&apos;re on your way.</h3>
                <p>See, that wasn&apos;t so hard. Now let&apos;s talk money. How much are you looking for?</p>
                <Form>
                    <Form.Group className='loan-amount'>
                        <Form.Label>Loan Amount ($AUD)</Form.Label>
                        <Form.Control isInvalid={!!errors.loanAmount} min={0} onBlur={this.validateLoanAmount} onChange={this.onChangeLoanAmount} type='number' value={deal.loanAmount || ''}/>
                        <Form.Text>{deal.loanAmount ? currencyFormatter.format(deal.loanAmount) : ''}</Form.Text>
                        {errors.loanAmount && <Form.Control.Feedback type='invalid'>{errors.loanAmount}</Form.Control.Feedback>}
                    </Form.Group>
                </Form>
                <div className='actions'>
                    <Link className='previous' to='/get-your-quote'><Button variant='primary'>Previous</Button></Link>
                    <Button className='continue' onClick={this.onClickContinue} variant='primary'>Continue</Button>
                </div>
            </Layout>
        );
    }

    private onClickContinue(): void {
        if (!this.validateLoanAmount()) {
            return;
        }

        history.push('/get-your-quote/loan-term');
    }

    private onChangeLoanAmount(event: React.ChangeEvent<HTMLInputElement>): void {
        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.props.setValue('loanAmount', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private validateLoanAmount(): boolean {
        const { deal } = this.props;

        let error: string;

        if (!deal.loanAmount || deal.loanAmount === 0) {
            error = 'Please enter your loan amount';
        } else if (deal.loanAmount < 20000) {
            error = 'The loan amount must be at least $20,000';
        }

        this.props.setError('loanAmount', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        borrowToken: tokenSelector(state),
        deal: dealSelector(state),
        errors: errorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        dealGet: () => dispatch(dealGetAction()),
        setError: (key: keyof IDealErrors, value: string) => dispatch(dealErrorSetAction(key, value)),
        setValue: (key: keyof IDeal, value: boolean|number|string) => dispatch(dealValueSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoanAmount);
