import IDeal from './IDeal';
import IDealProperty from './IDealProperty';

export function transformDeal(deal: IDeal): any {
    return {
        comments: deal.comments,
        email: deal.email,
        firstName: deal.firstName,
        isBroker: deal.isBroker,
        isReferralPartner: deal.isReferralPartner,
        landingPage: deal.landingPage,
        lastName: deal.lastName,
        loanAmount: deal.loanAmount,
        loanPurpose: deal.loanPurpose,
        phone: deal.phone,
        referralPartnerCompanyName: deal.referralPartnerCompanyName,
        referralPartnerEmail: deal.referralPartnerEmail,
        referralPartnerFirstName: deal.referralPartnerFirstName,
        referralPartnerLastName: deal.referralPartnerLastName,
        referralPartnerPhone: deal.referralPartnerPhone,
        termMonths: deal.termMonths,
        uiSource: deal.uiSource,
    };
}

export function transformProperty(property: IDealProperty): any {
    return {
        addressVersion: property.addressVersion,
        currentDebt: property.currentDebt,
        estimatedValue: property.estimatedValue,
        postcode: property.postcode,
        state: property.state,
        streetAddress: property.streetAddress,
        streetName: property.streetName,
        streetNumber: property.streetNumber,
        streetType: property.streetType,
        suburb: property.suburb,
        unitNumber: property.unitNumber,
        zoneType: property.zoneType,
    };
}
