import _ from 'lodash';
import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IDealProperty } from '~Api/Deal';
import AddressVersionEnum from '~Api/Deal/AddressVersionEnum';
import IDeal, { IDealErrors } from '~Api/Deal/IDeal';
import { IDealPropertyErrors } from '~Api/Deal/IDealProperty';
import LoanPurposeEnum from '~Api/Deal/LoanPurposeEnum';
import { tokenSelector } from '~Lead/selectors';
import { validateProperty } from '~Lead/validators';
import { IGlobalState } from '~reducer';
import { currentUserGetAction } from '~User/actions';
import IUser from '~User/IUser';
import { currentUserSelector } from '~User/selectors';
import { IDictionary } from '~utilities/IDictionary';
import { brokerDealAddAction } from './actions';
import './application-request.less';
import ApplicationProperty from './ApplicationProperty';
import Layout from './Layout';
import { brokerInProgressSelector } from './selectors';
import IToken from '~Auth/IToken';

const loanPurposeLabels: IDictionary<string> = {
    [LoanPurposeEnum.BridgingLoan]: 'Bridging loan',
    [LoanPurposeEnum.Refinance]: 'Refinance an existing property',
    [LoanPurposeEnum.BusinessLoan]: 'Business loan',
    [LoanPurposeEnum.PersonalLoan]: 'Personal loan',
    [LoanPurposeEnum.RenovateOrBuild]: 'Renovate or build',
    [LoanPurposeEnum.DevelopmentLoan]: 'Development loan',
};

interface IState {
    deal: IDeal;
    errors: IDealErrors;
    properties: IDealProperty[];
    supportingDocuments: File[];
}

interface IPropsSelector {
    borrowToken: IToken;
    currentUser: IUser;
    saving: boolean;
}

interface IPropsDispatch {
    currentBrokerDealAdd: (deal: IDeal, properties: IDealProperty[], supportingDocuments: File[]) => void;
    currentUserGet: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class ApplicationRequest extends React.Component<Props, IState> {
    public state: IState = {
        deal: {
            brokerStatus: null,
            comments: null,
            email: null,
            firstName: null,
            isBroker: null,
            lastName: null,
            loanAmount: null,
            loanPurpose: null,
            phone: null,
            termMonths: null,
        },
        errors: {},
        properties: [],
        supportingDocuments: [],
    };

    constructor(props: Props) {
        super(props);

        this.addProperty = this.addProperty.bind(this);
        this.clearError = this.clearError.bind(this);
        this.clearPropertyError = this.clearPropertyError.bind(this);
        this.removeProperty = this.removeProperty.bind(this);
        this.setError = this.setError.bind(this);
        this.setPropertyError = this.setPropertyError.bind(this);
        this.setPropertyValue = this.setPropertyValue.bind(this);

        this.onChangeComments = this.onChangeComments.bind(this);
        this.onChangeLoanAmount = this.onChangeLoanAmount.bind(this);
        this.onChangeLoanPurpose = this.onChangeLoanPurpose.bind(this);
        this.onChangeSupportingDocuments = this.onChangeSupportingDocuments.bind(this);
        this.onChangeTermMonths = this.onChangeTermMonths.bind(this);

        this.onClickSubmit = this.onClickSubmit.bind(this);

        this.validateLoanAmount = this.validateLoanAmount.bind(this);
        this.validateLoanPurpose = this.validateLoanPurpose.bind(this);
        this.validateTermMonths = this.validateTermMonths.bind(this);
    }

    public componentDidMount() {
        const { currentUser } = this.props;

        if (!currentUser) {
            this.props.currentUserGet();
        }

        this.addProperty();
    }

    public render(): JSX.Element {
        const { currentUser, saving } = this.props;
        const { deal, errors, properties, supportingDocuments } = this.state;

        if (!currentUser) {
            return (
                <Layout section='application-request'>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        const propertiesBlock: JSX.Element[] = properties.map((dealProperty: IDealProperty, index: number) => (
            <ApplicationProperty
                clearError={this.clearPropertyError}
                index={index}
                key={`application-property-${index}`}
                property={dealProperty}
                removeProperty={this.removeProperty}
                setError={this.setPropertyError}
                setValue={this.setPropertyValue}
            />
        ));

        const supportingDocumentList: JSX.Element[] = supportingDocuments && supportingDocuments.length > 0 && supportingDocuments.map((document: File, index: number) => {
            const onClickDelete: () => void = () => this.onClickSupportingDocumentDelete(index);

            return (
                <li className='upload-list-item' key={document.name}>{document.name} <MdCancel onClick={onClickDelete} /></li>
            );
        });

        const supportingDocumentsListBlock: JSX.Element = supportingDocuments && supportingDocuments.length > 0 && (
            <div className='file-upload-list'>
                <ul>
                    {supportingDocumentList}
                </ul>
            </div>
        );

        return (
            <Layout section='application-request'>
                <h1>Applications</h1>
                <div className='main-box application-form'>
                    <h2>Let&apos;s Submit your Application</h2>
                    <p className='intro'>
                        Submit all the required details and upload all required supporting documents and our team will be in contact you on pricing and decision FAST.
                    </p>
                    <Form>
                        <div className='loan-information'>
                            <Form.Group className='loan-purpose'>
                                <Form.Label>I&apos;m looking for a loan to...</Form.Label>
                                <Form.Control as='select' className='custom-select' isInvalid={!!errors.loanPurpose} onBlur={this.validateLoanPurpose} onChange={this.onChangeLoanPurpose} value={deal.loanPurpose || ''}>
                                    {deal.loanPurpose === null && <option />}
                                    {_.map(loanPurposeLabels, (loanPurposeLabel: string, key: LoanPurposeEnum) => <option value={key}>{loanPurposeLabel}</option>)}
                                </Form.Control>
                                {errors.loanPurpose && <Form.Control.Feedback type='invalid'>{errors.loanPurpose}</Form.Control.Feedback>}
                            </Form.Group>
                            <div className='loan-details'>
                                <Form.Group className='term-months'>
                                    <Form.Label>What is the term of your loan?</Form.Label>
                                    <Form.Control as='select' className='custom-select' isInvalid={!!errors.termMonths} onBlur={this.validateTermMonths} onChange={this.onChangeTermMonths} value={deal.termMonths || ''}>
                                        {deal.termMonths === null && <option />}
                                        {_.times(36, (months: number) => (<option key={months + 1} value={months + 1}>{months + 1} months</option>))}
                                    </Form.Control>
                                    {errors.termMonths && <Form.Control.Feedback type='invalid'>{errors.termMonths}</Form.Control.Feedback>}
                                </Form.Group>
                                <Form.Group className='loan-amount'>
                                    <Form.Label>How much do you want to borrow?</Form.Label>
                                    <Form.Control isInvalid={!!errors.loanAmount} min={0} onBlur={this.validateLoanAmount} onChange={this.onChangeLoanAmount} type='number' value={deal.loanAmount || ''} />
                                    {errors.loanAmount && <Form.Control.Feedback type='invalid'>{errors.loanAmount}</Form.Control.Feedback>}
                                </Form.Group>
                            </div>
                        </div>

                        <div className='property-information'>
                            {propertiesBlock}
                            <Button className='add-property' onClick={this.addProperty}>Add Another Property</Button>
                        </div>

                        <div className='contact-information'>
                            <Form.Group className='comments'>
                                <Form.Label>Comments</Form.Label>
                                <Form.Control as='textarea' rows={5} onChange={this.onChangeComments} />
                            </Form.Group>
                        </div>

                        <Form.Group className='supporting-documents'>
                            <Form.Label>Upload Supporting Documents</Form.Label>
                            <div className='file-upload-wrapper'>
                                <div className='label'>
                                    {(supportingDocuments && supportingDocuments.length > 0) && <React.Fragment>{`${supportingDocuments.length} ${supportingDocuments.length > 1 ? 'files' : 'file'}`} <AiOutlineFileAdd /></React.Fragment>}
                                    {(!supportingDocuments || supportingDocuments.length === 0) && <React.Fragment>Upload documents <AiOutlineFileAdd /></React.Fragment>}
                                </div>
                                <Form.Control disabled={saving} multiple={true} onChange={this.onChangeSupportingDocuments} type='file' />
                            </div>
                            {supportingDocumentsListBlock}
                        </Form.Group>

                        {!saving && <Button onClick={this.onClickSubmit} className='submit-application'>Submit Application</Button>}
                        {saving && <Spinner animation='border' as='span' role='status' size='sm' />}
                    </Form>
                </div>
            </Layout>
        );
    }

    private addProperty(): void {
        const { properties } = this.state;

        const property: IDealProperty = {
            addressVersion: AddressVersionEnum.V2,
            currentDebt: null,
            errors: {},
            estimatedValue: null,
            postcode: null,
            state: null,
            streetName: null,
            streetNumber: null,
            streetType: null,
            suburb: null,
            unitNumber: null,
            zoneType: null,
        };

        this.setState({
            properties: [
                ...properties,
                property,
            ],
        });
    }

    private removeProperty(index: number) {
        const { properties } = this.state;

        const newProperties: IDealProperty[] = [...properties];
        newProperties.splice(index);

        this.setState({
            properties: newProperties,
        });
    }

    private clearError(key: keyof IDealErrors) {
        const { errors } = this.state;

        this.setState({
            errors: _.omit(errors, key),
        });
    }

    private clearPropertyError(index: number, key: keyof IDealPropertyErrors) {
        const { properties } = this.state;

        properties[index] = {
            ...properties[index],
            errors: _.omit(properties[index].errors, key),
        };

        this.setState({
            properties,
        });
    }

    private setError(key: keyof IDealErrors, value: string) {
        this.setState((prevState: IState) => {
            return {
                errors: {
                    ...prevState.errors,
                    [key]: value,
                },
            };
        });
    }

    private setPropertyError(index: number, key: keyof IDealPropertyErrors, value: string) {
        this.setState((prevState: IState) => {
            const { properties } = prevState;

            const errors: IDealPropertyErrors = {
                ...prevState.properties[index].errors,
                [key]: value,
            };

            properties[index].errors = errors;

            return {
                properties,
            };
        });
    }

    private setPropertyValue(index: number, key: keyof IDealProperty, value: boolean|number|string) {
        const { properties } = this.state;

        this.setState({
            properties: [
                ...properties.slice(0, index),
                {
                    ...properties[index],
                    [key]: value,
                },
                ...properties.slice(index + 1),
            ],
        });
    }

    private onChangeComments(event: React.ChangeEvent<HTMLInputElement>) {
        const { deal } = this.state;

        this.setState({
            deal: {
                ...deal,
                comments: event.target.value,
            },
        });
    }

    private onChangeLoanAmount(event: React.ChangeEvent<HTMLInputElement>) {
        const { deal } = this.state;

        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.setState({
            deal: {
                ...deal,
                loanAmount: event.target.value === '' ? null : event.target.valueAsNumber,
            },
        });
    }

    private onChangeLoanPurpose(event: React.ChangeEvent<HTMLInputElement>) {
        const { deal } = this.state;

        this.setState({
            deal: {
                ...deal,
                loanPurpose: event.target.value as LoanPurposeEnum,
            },
        });
    }

    private onChangeSupportingDocuments(event: React.ChangeEvent<HTMLInputElement>) {
        const { supportingDocuments: supportingFiles } = this.state;

        this.setState({
            supportingDocuments: [
                ...supportingFiles,
                event.target.files[0],
            ],
        });
    }

    private onChangeTermMonths(event: React.ChangeEvent<HTMLInputElement>) {
        const { deal } = this.state;

        this.setState({
            deal: {
                ...deal,
                termMonths: event.target.value ? Number(event.target.value) : null,
            },
        });
    }

    private onClickSubmit() {
        const { deal, properties, supportingDocuments } = this.state;

        let valid: boolean = true;

        valid = this.validateLoanPurpose() && valid;
        valid = this.validateLoanAmount() && valid;
        valid = this.validateTermMonths() && valid;

        const invalidProperties: IDealProperty[] = _.filter(properties, (property: IDealProperty, index: number) => {
            const setError: (key: keyof IDealPropertyErrors, value: string) => void = (key: keyof IDealPropertyErrors, value: string) => this.setPropertyError(index, key, value);

            return !validateProperty(property, setError);
        });

        if (!valid || _.size(invalidProperties) > 0) {
            return;
        }

        this.props.currentBrokerDealAdd(deal, properties, supportingDocuments);
    }

    private onClickSupportingDocumentDelete(index: number) {
        const { supportingDocuments } = this.state;

        this.setState({
            supportingDocuments: [
                ...supportingDocuments.slice(0, index),
                ...supportingDocuments.slice(index + 1),
            ],
        });
    }

    private validateLoanAmount(): boolean {
        const { deal } = this.state;

        let error: string;

        if (!deal.loanAmount || deal.loanAmount === 0) {
            error = 'Please enter your loan amount';
        } else if (deal.loanAmount < 20000) {
            error = 'The loan amount must be at least $20,000';
        }

        if (error) {
            this.setError('loanAmount', error);
        } else {
            this.clearError('loanAmount');
        }

        return !error;
    }

    private validateLoanPurpose(): boolean {
        const { deal } = this.state;

        let error: string;

        if (!_.values(LoanPurposeEnum).includes(deal.loanPurpose)) {
            error = 'Please select your loan purpose';
        }

        if (error) {
            this.setError('loanPurpose', error);
        } else {
            this.clearError('loanPurpose');
        }

        return !error;
    }

    private validateTermMonths(): boolean {
        const { deal } = this.state;

        let error: string;

        if (!deal.termMonths) {
            error = 'Please select your loan term';
        }

        if (error) {
            this.setError('termMonths', error);
        } else {
            this.clearError('termMonths');
        }

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        borrowToken: tokenSelector(state),
        currentUser: currentUserSelector(state),
        saving: brokerInProgressSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentBrokerDealAdd: (deal: IDeal, properties: IDealProperty[], supportingDocuments: File[]) => dispatch(brokerDealAddAction(deal, properties, supportingDocuments)),
        currentUserGet: () => dispatch(currentUserGetAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ApplicationRequest);
