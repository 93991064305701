import React from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IGlobalState } from '~reducer';
import {
    currentUserSelector,
    passwordChangeErrorsSelector,
    passwordChangeInProgressSelector,
    passwordChangeModalShowSelector,
} from '~User/selectors';
import {
    currentUserGetAction,
    passwordChangeAction,
    passwordChangeErrorSetAction,
    passwordChangeModalHideAction,
} from './actions';
import IUser from './IUser';
import './user.less';

interface IState {
    confirmPassword: string;
    password: string;
}

interface IPropsSelector {
    currentUser: IUser;
    errors: any;
    inProgress: boolean;
    show: boolean;
}

interface IPropsDispatch {
    changePassword: (password: string) => void;
    currentUserGet: () => void;
    hide: () => void;
    setError: (key: string, value: any) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class PasswordChangeModal extends React.Component<Props, IState> {
    public state: IState = {
        confirmPassword: null,
        password: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickOk = this.onClickOk.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);

        this.validatePassword = this.validatePassword.bind(this);
        this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
    }

    public componentDidMount() {
        const { currentUser } = this.props;

        if (!currentUser) {
            this.props.currentUserGet();
        }
    }

    public render(): JSX.Element {
        const { currentUser, errors, inProgress, show } = this.props;
        const { confirmPassword, password } = this.state;

        if (!currentUser) {
            return null;
        }

        return (
            <Modal
                dialogClassName='user-password-change-modal'
                onHide={this.props.hide}
                show={show}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control defaultValue={currentUser.email} hidden={true} name='email' />
                    <Form.Group className='new-password'>
                        <Form.Label>New Password</Form.Label>
                        <Form.Control isInvalid={!!errors.password} name='new_password' onBlur={this.validatePassword} onChange={this.onChangePassword} placeholder='New Password' type='password' value={password || ''} />
                        {errors.password && <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group className='confirm-password'>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control isInvalid={!!errors.confirmPassword} name='confirm_password' onBlur={this.validateConfirmPassword} onChange={this.onChangeConfirmPassword} placeholder='Confirm Password' type='password' value={confirmPassword || ''} />
                        {errors.confirmPassword && <Form.Control.Feedback type='invalid'>{errors.confirmPassword}</Form.Control.Feedback>}
                    </Form.Group>
                    <Button disabled={inProgress} name='submit' onClick={this.onClickOk}>
                        {inProgress && <Spinner animation='border' size='sm' />} Confirm
                    </Button>
                </Modal.Body>
            </Modal>
        );
    }

    private onClickOk() {
        const { password } = this.state;

        let valid = true;
        valid = this.validatePassword() && valid;
        valid = this.validateConfirmPassword() && valid;

        if (!valid) {
            return;
        }

        this.props.changePassword(password);
    }

    private onChangePassword(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            password: event.target.value,
        });
    }

    private onChangeConfirmPassword(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            confirmPassword: event.target.value,
        });
    }

    private validatePassword() {
        const { password } = this.state;

        let error: string;

        if (!password || password.length === 0) {
            error = 'Please enter the new password';
        } else if (password.length < 5) {
            error = 'Password must be at least 5 characters';
        }

        this.props.setError('password', error);

        return !error;
    }

    private validateConfirmPassword() {
        const { confirmPassword, password } = this.state;

        let error: string;

        if (!confirmPassword || confirmPassword.length === 0) {
            error = 'Please enter the confirm password';
        } else if (confirmPassword !== password) {
            error = 'Confirm password does not match password';
        }

        this.props.setError('confirmPassword', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentUser: currentUserSelector(state),
        errors: passwordChangeErrorsSelector(state),
        inProgress: passwordChangeInProgressSelector(state),
        show: passwordChangeModalShowSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        changePassword: (password: string) => dispatch(passwordChangeAction(password)),
        currentUserGet: () => dispatch(currentUserGetAction()),
        hide: () => dispatch(passwordChangeModalHideAction()),
        setError: (key: string, value: any) => dispatch(passwordChangeErrorSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PasswordChangeModal);
