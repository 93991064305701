import moment from 'moment';
import IInvestment from './IInvestment';
import IInvestmentPhoto from './IInvestmentPhoto';
import StatusEnum from './StatusEnum';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInvestment(raw: any): IInvestment {
    const monthsRemaining: number = Math.floor(moment.duration(moment(raw.endDate).diff(moment())).as('months'));
    const daysRemaining: number = Math.floor(moment.duration(moment(raw.endDate).subtract(monthsRemaining, 'months').diff(moment())).as('days'));

    return {
        amountRemaining: raw.amountRemaining,
        amountTotal: raw.amountTotal,
        closedDate: raw.closedDate,
        code: raw.code,
        createdTime: raw.createdTime,
        description: raw.description,
        endDate: raw.endDate,
        interestRate: raw.interestRate,
        lvr: raw.lvr,
        name: raw.name,
        photos: raw.photos.map(parseInvestmentPhoto),
        propertySecurity: raw.propertySecurity,
        propertyType: raw.propertyType,
        propertyValue: raw.propertyValue,
        startDate: raw.startDate,
        status: raw.investorStatus as StatusEnum,
        termMonths: raw.termMonths,
        timeRemaining: {
            days: daysRemaining,
            months: monthsRemaining,
        },
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInvestmentPhoto(raw: any): IInvestmentPhoto {
    return {
        url: raw.url,
    };
}
