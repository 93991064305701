import { all, call, debounce, put, select, takeEvery } from '@redux-saga/core/effects';
import _ from 'lodash';
import IInvestment from '~Api/Investment/IInvestment';
import { parseInvestment } from '~Api/Investment/parsers';
import {
    investmentGetRequest,
    investmentInvestRequest,
    investmentsAvailableListRequest,
    investmentsRecentlyFundedListRequest,
} from '~Api/Investment/requests';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IAnnualStatement from '~Api/Investor/IAnnualStatement';
import IIncomeTrust from '~Api/Investor/IIncomeTrust';
import IInvestor from '~Api/Investor/IInvestor';
import IMonthlyStatement from '~Api/Investor/IMonthlyStatement';
import InterestPaymentStrategyEnum from '~Api/Investor/InterestPaymentStrategyEnum';
import IReferral from '~Api/Investor/IReferral';
import IShare from '~Api/Investor/IShare';
import ITransaction from '~Api/Investor/ITransaction';
import {
    parseAccount,
    parseAnnualStatement,
    parseIncomeTrust,
    parseInvestor,
    parseMonthlyStatement,
    parseReferral,
    parseShare,
    parseTransaction,
} from '~Api/Investor/parsers';
import {
    investorAccountAnnualStatementsListRequest,
    investorAccountCompleteRequest,
    investorAccountDepositRequest,
    investorAccountIncomeTrustCreateRequest,
    investorAccountIncomeTrustDepositRequest,
    investorAccountIncomeTrustInterestPaymentStrategyRequest,
    investorAccountIncomeTrustsListRequest,
    investorAccountMarketplaceCreateRequest,
    investorAccountMonthlyStatementsListRequest,
    investorAccountSharesListRequest,
    investorAccountTransactionsListRequest,
    investorAccountWithdrawRequest,
    investorGetRequest,
    investorReferralAddRequest,
    investorReferralsListRequest,
    investorUpdateRequest,
} from '~Api/Investor/requests';
import IAuthUser from '~Auth/IAuthUser';
import RoleEnum from '~Auth/RoleEnum';
import { authCurrentUserSelector } from '~Auth/selectors';
import { toastAddAction } from '~Global/actions';
import history from '~history';
import { IFetchResponse } from '~utilities/fetch';
import {
    IAnnualStatementsListAction,
    ICurrentInvestorAccountIncomeTrustInterestPaymentStrategySetAction,
    ICurrentInvestorBankAccountValueSetAction,
    ICurrentInvestorCompanyValueSetAction,
    ICurrentInvestorGetAction,
    ICurrentInvestorIndividualValueSetAction,
    ICurrentInvestorSendAction,
    ICurrentInvestorTrustValueSetAction,
    ICurrentInvestorValueSetAction,
    IIncomeTrustAccountCompleteAction,
    IIncomeTrustInvestAction,
    IIncomeTrustsListAction,
    IInvestmentGetAction,
    IInvestmentInvestAction,
    IInvestmentsAvailableListAction,
    IInvestmentsRecentlyFundedListAction,
    IMarketplaceAccountCompleteAction,
    IMonthlyStatementsListAction,
    IReferralAddAction,
    IReferralsListAction,
    ISharesListAction,
    ITopUpAction,
    ITransactionsListAction,
    IWithdrawAction,
    annualStatementsSetAction,
    currentInvestorAccountIncomeTrustSetAction,
    currentInvestorAccountMarketplaceSetAction,
    currentInvestorGetAction,
    currentInvestorSendAction,
    currentInvestorSetAction,
    incomeTrustInvestCompleteModalShowSetAction,
    incomeTrustInvestConfirmModalShowSetAction,
    incomeTrustInvestErrorsSetAction,
    incomeTrustInvestInProgressSetAction,
    incomeTrustsSetAction,
    investmentGetAction,
    investmentInvestAmountSetAction,
    investmentInvestCompleteModalShowSetAction,
    investmentInvestErrorsSetAction,
    investmentInvestInProgressSetAction,
    investmentInvestModalShowSetAction,
    investmentSetAction,
    investmentsAvailableSetAction,
    investmentsRecentlyFundedSetAction,
    monthlyStatementsSetAction,
    referralAddErrorsSetAction,
    referralAddInProgressSetAction,
    referralEmailSetAction,
    referralsSetAction,
    registerErrorsSetAction,
    registerInProgressSetAction,
    sharesListAction,
    sharesSetAction,
    topUpCompleteModalShowAction,
    topUpConfirmModalHideAction,
    topUpErrorsSetAction,
    topUpInProgressSetAction,
    topUpModalHideAction,
    transactionsSetAction,
    withdrawCompleteModalShowAction,
    withdrawConfirmModalHideAction,
    withdrawErrorsSetAction,
    withdrawInProgressSetAction,
    withdrawModalHideAction,
} from './actions';
import InvestorActionsEnum from './ActionsEnum';
import {
    currentInvestorAccountIncomeTrustInterestPaymentStrategySelector,
    currentInvestorAccountSelector,
    currentInvestorSelector,
    referralEmailSelector,
} from './selectors';

function* annualStatementsList(action: IAnnualStatementsListAction): Iterator<any> {
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Investor) {
        const currentInvestorAccount: IAccount = yield select(currentInvestorAccountSelector, action.accountType);
        const investorAnnualStatementsListResponse: IFetchResponse = yield call(investorAccountAnnualStatementsListRequest, currentInvestorAccount.uuid);
        const annualStatements: IAnnualStatement[] = yield Promise.all(investorAnnualStatementsListResponse.body.map(parseAnnualStatement));
        yield put(annualStatementsSetAction(currentInvestorAccount.uuid, annualStatements));
    }
}

function* currentInvestorGet(action: ICurrentInvestorGetAction): Iterator<any> {
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Investor) {
        const rawInvestor: IFetchResponse = yield call(investorGetRequest, currentUser.currentInvestorUuid);
        const investor: IInvestor = parseInvestor(rawInvestor.body);
        yield put(currentInvestorSetAction(investor));
    }
}

function* currentInvestorSend(action: ICurrentInvestorSendAction): Iterator<any> {
    const investor: IInvestor = yield select(currentInvestorSelector);
    yield call(investorUpdateRequest, investor);
}

function* currentInvestorValueSet(action: ICurrentInvestorValueSetAction): Iterator<any> {
    yield put(currentInvestorSendAction());
}

function* currentInvestorBankAccountValueSet(action: ICurrentInvestorBankAccountValueSetAction): Iterator<any> {
    yield put(currentInvestorSendAction());
}

function* currentInvestorCompanyValueSet(action: ICurrentInvestorCompanyValueSetAction): Iterator<any> {
    yield put(currentInvestorSendAction());
}

function* currentInvestorIndividualValueSet(action: ICurrentInvestorIndividualValueSetAction): Iterator<any> {
    yield put(currentInvestorSendAction());
}

function* currentInvestorTrustValueSet(action: ICurrentInvestorTrustValueSetAction): Iterator<any> {
    yield put(currentInvestorSendAction());
}

function* currentInvestorAccountIncomeTrustInterestPaymentStrategySet(action: ICurrentInvestorAccountIncomeTrustInterestPaymentStrategySetAction): Iterator<any> {
    sessionStorage.setItem('interestPaymentStrategy', action.interestPaymentStrategy);
}

function* incomeTrustInvest(action: IIncomeTrustInvestAction): Iterator<any> {
    yield put(incomeTrustInvestInProgressSetAction(true));
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Investor) {
        const investorAccountIncomeTrustDepositResponse: IFetchResponse = yield call(investorAccountIncomeTrustDepositRequest, action.incomeTrustUuid, action.amount);
        if (investorAccountIncomeTrustDepositResponse.status === 422) {
            yield put(incomeTrustInvestErrorsSetAction(investorAccountIncomeTrustDepositResponse.body));
            yield put(incomeTrustInvestConfirmModalShowSetAction(false));
        } else if (investorAccountIncomeTrustDepositResponse.status === 200) {
            yield put(currentInvestorAccountIncomeTrustSetAction(parseAccount(investorAccountIncomeTrustDepositResponse.body)));
            yield put(incomeTrustInvestErrorsSetAction({}));
            yield put(currentInvestorGetAction());
            yield put(incomeTrustInvestConfirmModalShowSetAction(false));
            yield put(incomeTrustInvestCompleteModalShowSetAction(true));
        }
    }
    yield put(incomeTrustInvestInProgressSetAction(false));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* incomeTrustsList(action: IIncomeTrustsListAction): Iterator<unknown> {
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Investor) {
        const incomeTrustAccount: IAccount = yield select(currentInvestorAccountSelector, AccountTypeEnum.IncomeTrust);
        const investorAccountIncomeTrustsListResponse: IFetchResponse = yield call(investorAccountIncomeTrustsListRequest, incomeTrustAccount.uuid);
        const incomeTrusts: IIncomeTrust[] = yield Promise.all(investorAccountIncomeTrustsListResponse.body.map(parseIncomeTrust));
        yield put(incomeTrustsSetAction(incomeTrusts));
    }
}

function* investmentGet(action: IInvestmentGetAction): Iterator<any> {
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Investor) {
        const rawInvestment: IFetchResponse = yield call(investmentGetRequest, action.uuid);
        const investment: IInvestment = parseInvestment(rawInvestment.body);
        yield put(investmentSetAction(investment));
    }
}

function* investmentInvest(action: IInvestmentInvestAction): Iterator<any> {
    yield put(investmentInvestInProgressSetAction(true));
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Investor) {
        const currentInvestor: IInvestor = yield select(currentInvestorSelector);
        const marketplaceAccount: IAccount = _.find(currentInvestor.accounts, { accountType: AccountTypeEnum.Marketplace });
        const rawInvest: IFetchResponse = yield call(investmentInvestRequest, action.uuid, marketplaceAccount.uuid, action.amount);
        if (rawInvest.status === 422) {
            yield put(investmentInvestErrorsSetAction(action.uuid, rawInvest.body));
        } else if (rawInvest.status === 200) {
            yield put(investmentGetAction(action.uuid));
            yield put(currentInvestorGetAction());
            yield put(sharesListAction());
            yield put(investmentInvestModalShowSetAction(false));
            yield put(investmentInvestCompleteModalShowSetAction(true));
            yield put(investmentInvestAmountSetAction(action.uuid, null));
        }
        yield put(investmentInvestModalShowSetAction(false));
    }
    yield put(investmentInvestInProgressSetAction(false));
}

function* investmentsAvailableList(action: IInvestmentsAvailableListAction): Iterator<any> {
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Investor) {
        const rawInvestments: IFetchResponse = yield call(investmentsAvailableListRequest);
        const investments: IInvestment[] = rawInvestments.body.map(parseInvestment);
        yield put(investmentsAvailableSetAction(investments));
    }
}

function* investmentsRecentlyFundedList(action: IInvestmentsRecentlyFundedListAction): Iterator<any> {
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Investor) {
        const rawInvestments: IFetchResponse = yield call(investmentsRecentlyFundedListRequest);
        const investments: IInvestment[] = rawInvestments.body.map(parseInvestment);
        yield put(investmentsRecentlyFundedSetAction(investments));
    }
}

function* incomeTrustAccountComplete(action: IIncomeTrustAccountCompleteAction): Iterator<any> {
    yield put(registerInProgressSetAction(true));

    let unlock: boolean = true;

    // Create income trust account
    const investor: IInvestor = yield select(currentInvestorSelector);
    const investorAccountIncomeTrustCreateResponse: IFetchResponse = yield call(investorAccountIncomeTrustCreateRequest, investor.uuid);
    const incomeTrustAccount: IAccount = parseInvestor(investorAccountIncomeTrustCreateResponse.body).accounts[AccountTypeEnum.IncomeTrust];

    // Set distribution preference
    const interestPaymentStrategy: InterestPaymentStrategyEnum = yield select(currentInvestorAccountIncomeTrustInterestPaymentStrategySelector);
    yield call(investorAccountIncomeTrustInterestPaymentStrategyRequest, incomeTrustAccount.uuid, interestPaymentStrategy);

    // Complete the income trust account
    const investorAccountCompleteResponse: IFetchResponse = yield call(investorAccountCompleteRequest, incomeTrustAccount.uuid, action.imContent);

    if (investorAccountCompleteResponse.status === 422) {
        yield put(registerErrorsSetAction(investorAccountCompleteResponse.body));
    } else if (investorAccountCompleteResponse.status === 200) {
        yield put(currentInvestorAccountIncomeTrustSetAction(parseAccount(investorAccountCompleteResponse.body)));

        sessionStorage.removeItem('interestPaymentStrategy');

        history.push('/income-trust');

        unlock = false;
    }

    if (unlock) {
        yield put(registerInProgressSetAction(false));
    }
}

function* marketplaceAccountComplete(action: IMarketplaceAccountCompleteAction): Iterator<unknown> {
    yield put(registerInProgressSetAction(true));

    let unlock: boolean = true;

    // Create marketplace account
    const investor: IInvestor = yield select(currentInvestorSelector);
    const investorAccountMarketplaceCreateResponse: IFetchResponse = yield call(investorAccountMarketplaceCreateRequest, investor.uuid);
    const marketplaceAccount: IAccount = parseAccount(investorAccountMarketplaceCreateResponse.body);

    // Complete the marketplace account
    const investorAccountCompleteResponse: IFetchResponse = yield call(investorAccountCompleteRequest, marketplaceAccount.uuid, null, action.pdsContent);

    if (investorAccountCompleteResponse.status === 422) {
        yield put(registerErrorsSetAction(investorAccountCompleteResponse.body));
    } else if (investorAccountCompleteResponse.status === 200) {
        yield put(currentInvestorAccountMarketplaceSetAction(parseAccount(investorAccountCompleteResponse.body)));

        history.push('/marketplace');

        unlock = false;
    }

    if (unlock) {
        yield put(registerInProgressSetAction(false));
    }
}

function* monthlyStatementsList(action: IMonthlyStatementsListAction): Iterator<any> {
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Investor) {
        const currentInvestorAccount: IAccount = yield select(currentInvestorAccountSelector, action.accountType);
        const investorMonthlyStatementsListResponse: IFetchResponse = yield call(investorAccountMonthlyStatementsListRequest, currentInvestorAccount.uuid);
        const monthlyStatements: IMonthlyStatement[] = yield Promise.all(investorMonthlyStatementsListResponse.body.map(parseMonthlyStatement));
        yield put(monthlyStatementsSetAction(currentInvestorAccount.uuid, monthlyStatements));
    }
}

function* referralAdd(action: IReferralAddAction): Iterator<any> {
    yield put(referralAddInProgressSetAction(true));
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Investor) {
        const email: string = yield select(referralEmailSelector);
        const rawReferral: IFetchResponse = yield call(investorReferralAddRequest, currentUser.currentInvestorUuid, email);
        if (rawReferral.status === 422) {
            yield put(referralAddErrorsSetAction(rawReferral.body));
        } else if (rawReferral.status === 200) {
            yield put(referralEmailSetAction(null));
            yield put(toastAddAction('Send Invite', 'Your invite has been sent.'));
        }
    }
    yield put(referralAddInProgressSetAction(false));
}

function* referralsList(action: IReferralsListAction): Iterator<any> {
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Investor) {
        const rawReferrals: IFetchResponse = yield call(investorReferralsListRequest, currentUser.currentInvestorUuid);
        const referrals: IReferral[] = rawReferrals.body.map(parseReferral);
        yield put(referralsSetAction(referrals));
    }
}

function* sharesList(action: ISharesListAction): Iterator<any> {
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Investor) {
        const investor: IInvestor = yield select(currentInvestorSelector);
        const account: IAccount = _.find(investor.accounts, { accountType: AccountTypeEnum.Marketplace });
        const rawShares: IFetchResponse = yield call(investorAccountSharesListRequest, account.uuid);
        const shares: IShare[] = rawShares.body.map(parseShare);
        yield put(sharesSetAction(shares));
    }
}

function* topUp(action: ITopUpAction): Iterator<any> {
    yield put(topUpInProgressSetAction(true));
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Investor) {
        const investor: IInvestor = yield select(currentInvestorSelector);
        const account: IAccount = _.find(investor.accounts, { accountType: action.accountType });
        const rawDeposit: IFetchResponse = yield call(investorAccountDepositRequest, account.uuid, action.amount);
        if (rawDeposit.status === 422) {
            yield put(topUpErrorsSetAction(rawDeposit.body));
            yield put(topUpConfirmModalHideAction());
        } else if (rawDeposit.status === 200) {
            yield put(topUpErrorsSetAction({}));
            yield put(currentInvestorGetAction());
            yield put(topUpModalHideAction());
            yield put(topUpConfirmModalHideAction());
            yield put(topUpCompleteModalShowAction());
        }
    }
    yield put(topUpInProgressSetAction(false));
}

function* transactionsList(action: ITransactionsListAction): Iterator<any> {
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Investor) {
        const investor: IInvestor = yield select(currentInvestorSelector);
        const account: IAccount = _.find(investor.accounts, { accountType: AccountTypeEnum.Marketplace });
        const rawTransactions: IFetchResponse = yield call(investorAccountTransactionsListRequest, account.uuid);
        const transactions: ITransaction[] = rawTransactions.body.map(parseTransaction);
        yield put(transactionsSetAction(transactions));
    }
}

function* withdraw(action: IWithdrawAction): Iterator<any> {
    yield put(withdrawInProgressSetAction(true));
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Investor) {
        const investor: IInvestor = yield select(currentInvestorSelector);
        const account: IAccount = _.find(investor.accounts, { accountType: AccountTypeEnum.Marketplace });
        const rawWithdraw: IFetchResponse = yield call(investorAccountWithdrawRequest, account.uuid, action.amount);
        if (rawWithdraw.status === 422) {
            yield put(withdrawErrorsSetAction(rawWithdraw.body));
            yield put(withdrawConfirmModalHideAction());
        } else if (rawWithdraw.status === 200) {
            yield put(withdrawErrorsSetAction({}));
            yield put(currentInvestorGetAction());
            yield put(withdrawModalHideAction());
            yield put(withdrawConfirmModalHideAction());
            yield put(withdrawCompleteModalShowAction());
        }
    }
    yield put(withdrawInProgressSetAction(false));
}

export function* InvestorSagas(): any {
    yield all([
        takeEvery(InvestorActionsEnum.AnnualStatementsList, annualStatementsList),
        debounce(10, InvestorActionsEnum.CurrentInvestorGet, currentInvestorGet),
        debounce(500, InvestorActionsEnum.CurrentInvestorSend, currentInvestorSend),
        takeEvery(InvestorActionsEnum.CurrentInvestorValueSet, currentInvestorValueSet),
        takeEvery(InvestorActionsEnum.CurrentInvestorBankAccountValueSet, currentInvestorBankAccountValueSet),
        takeEvery(InvestorActionsEnum.CurrentInvestorCompanyValueSet, currentInvestorCompanyValueSet),
        takeEvery(InvestorActionsEnum.CurrentInvestorIndividualValueSet, currentInvestorIndividualValueSet),
        takeEvery(InvestorActionsEnum.CurrentInvestorTrustValueSet, currentInvestorTrustValueSet),
        takeEvery(InvestorActionsEnum.CurrentInvestorAccountIncomeTrustInterestPaymentStrategySet, currentInvestorAccountIncomeTrustInterestPaymentStrategySet),
        takeEvery(InvestorActionsEnum.IncomeTrustInvest, incomeTrustInvest),
        takeEvery(InvestorActionsEnum.IncomeTrustsList, incomeTrustsList),
        takeEvery(InvestorActionsEnum.InvestmentGet, investmentGet),
        debounce(200, InvestorActionsEnum.InvestmentInvest, investmentInvest),
        takeEvery(InvestorActionsEnum.InvestmentsAvailableList, investmentsAvailableList),
        takeEvery(InvestorActionsEnum.InvestmentsRecentlyFundedList, investmentsRecentlyFundedList),
        takeEvery(InvestorActionsEnum.IncomeTrustAccountComplete, incomeTrustAccountComplete),
        takeEvery(InvestorActionsEnum.MarketplaceAccountComplete, marketplaceAccountComplete),
        takeEvery(InvestorActionsEnum.MonthlyStatementsList, monthlyStatementsList),
        debounce(200, InvestorActionsEnum.ReferralAdd, referralAdd),
        takeEvery(InvestorActionsEnum.ReferralsList, referralsList),
        takeEvery(InvestorActionsEnum.SharesList, sharesList),
        takeEvery(InvestorActionsEnum.TopUp, topUp),
        takeEvery(InvestorActionsEnum.TransactionsList, transactionsList),
        takeEvery(InvestorActionsEnum.Withdraw, withdraw),
    ]);
}
