enum BrokerActionsEnum {
    BrokerAddressUpdate = 'BROKER/ADDRESS_UPDATE',
    BrokerAddressUpdateModalShow = 'BROKER/ADDRESS_UPDATE/MODAL/SHOW',
    BrokerCheckEmail = 'BROKER/CHECK_EMAIL',
    BrokerDealAdd = 'BROKER/DEAL/ADD',
    BrokerInProgress = 'BROKER/IN_PROGRESS',
    BrokerPassword = 'BROKER/PASSWORD',
    BrokerVerifyEmail = 'BROKER/VERIFY_EMAIL',

    BrokerLoginErrorsSet = 'BROKER_LOGIN/ERRORS/SET',
    BrokerLoginErrorSet = 'BROKER_LOGIN/ERROR/SET',

    BrokerSet = 'BROKER/SET',

    CurrentBrokerGet = 'CURRENT_BROKER/GET',
}

export default BrokerActionsEnum;
