import React from 'react';
import { connect } from 'react-redux';
import { Router as ReactRouter, Redirect, Route, Switch } from 'react-router-dom';
import history from '~history';
import { tokenSelector } from '~Lead/selectors';
import { IGlobalState } from '~reducer';
import BasicInformation from './BasicInformation';
import Confirm from './Confirm';
import LoanAmount from './LoanAmount';
import LoanPurpose from './LoanPurpose';
import LoanTerm from './LoanTerm';
import Properties from './Properties';
import Quote from './Quote';
import Thanks from './Thanks';
import IToken from '~Auth/IToken';

interface IPropsSelector {
    token: IToken;
}

type Props = IPropsSelector;

class Router extends React.Component<Props> {
    public render(): JSX.Element {
        const { token } = this.props;

        if (!token) {
            return (
                <ReactRouter history={history}>
                    <Switch>
                        <Route exact={true} path='/get-your-quote' component={BasicInformation} />
                        <Route exact={true} path='/get-your-quote/thanks' component={Thanks} />
                        <Redirect to='/get-your-quote' />
                    </Switch>
                </ReactRouter>
            );
        }

        return (
            <ReactRouter history={history}>
                <Switch>
                    <Route exact={true} path='/get-your-quote' component={BasicInformation} />
                    <Route exact={true} path='/get-your-quote/loan-amount' component={LoanAmount} />
                    <Route exact={true} path='/get-your-quote/loan-term' component={LoanTerm} />
                    <Route exact={true} path='/get-your-quote/loan-purpose' component={LoanPurpose} />
                    <Route exact={true} path='/get-your-quote/properties' component={Properties} />
                    <Route exact={true} path='/get-your-quote/confirm' component={Confirm} />
                    <Route exact={true} path='/get-your-quote/thanks' component={Thanks} />
                    <Route exact={true} path='/get-your-quote/quote' component={Quote} />
                    <Redirect to='/get-your-quote' />
                </Switch>
            </ReactRouter>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        token: tokenSelector(state),
    };
}

export default connect(
    mapStateToProps,
    null,
)(Router);
