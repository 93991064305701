import _ from 'lodash';
import React from 'react';
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from 'react-icons/ti';

export enum SortOrderEnum {
    Ascending = 'asc',
    Descending = 'desc',
}

interface IProps {
    className: string;
    field: string;
    label: string;
    onClick: (field: string) => void;
    sortField: string;
    sortOrder: SortOrderEnum;
}

class SortableHeader extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    public render(): JSX.Element {
        const { className, field, label, sortField, sortOrder } = this.props;

        return (
            <th className={className} onClick={this.onClick}>
                {label}
                {sortField !== field && <TiArrowUnsorted/>}
                {sortField === field && sortOrder === SortOrderEnum.Descending && <TiArrowSortedDown/>}
                {sortField === field && sortOrder === SortOrderEnum.Ascending && <TiArrowSortedUp/>}
            </th>
        );
    }

    private onClick() {
        const { field } = this.props;

        this.props.onClick(field);
    }
}

export default SortableHeader;
