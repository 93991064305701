import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import {
    currentInvestorGetAction,
    investmentsAvailableListAction,
    investmentsRecentlyFundedListAction,
} from '~Investor/actions';
import {
    availableInvestmentsSelector,
    currentInvestorAccountSelector,
    currentInvestorSelector,
    recentlyFundedInvestmentsSelector,
} from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import { currentUserGetAction } from '~User/actions';
import IUser from '~User/IUser';
import { currentUserSelector } from '~User/selectors';
import '../dashboard.less';
import InvestmentBox from './InvestmentBox';
import Layout from './Layout';
import ReferFriendBox from './ReferFriendBox';

interface IPropsSelector {
    availableInvestments: IInvestment[];
    currentInvestor: IInvestor;
    currentUser: IUser;
    marketplaceAccount: IAccount;
    recentlyFundedInvestments: IInvestment[];
}

interface IPropsDispatch {
    availableInvestmentsList: () => void;
    currentInvestorGet: () => void;
    currentUserGet: () => void;
    recentlyFundedInvestmentsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Dashboard extends React.Component<Props> {
    private availableInterval: any;
    private recentlyFundedInterval: any;

    public componentDidMount(): void {
        const { availableInvestments, currentInvestor, currentUser, recentlyFundedInvestments } = this.props;

        if (!availableInvestments) {
            this.props.availableInvestmentsList();
        }

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }

        if (!currentUser) {
            this.props.currentUserGet();
        }

        if (!recentlyFundedInvestments) {
            this.props.recentlyFundedInvestmentsList();
        }

        this.availableInterval = setInterval(() => {
            this.props.availableInvestmentsList();
        }, 60 * 1000);

        this.recentlyFundedInterval = setInterval(() => {
            this.props.recentlyFundedInvestmentsList();
        }, 60 * 60 * 1000);
    }

    public componentWillUnmount(): void {
        clearInterval(this.availableInterval);
        clearInterval(this.recentlyFundedInterval);
    }

    public render(): JSX.Element {
        const { availableInvestments, currentInvestor, currentUser, marketplaceAccount, recentlyFundedInvestments } = this.props;

        if (!currentInvestor || !currentUser) {
            return (
                <Layout section='dashboard'>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        const displayName: string = currentUser.firstName || currentInvestor.name;

        if (!availableInvestments || !recentlyFundedInvestments) {
            return (
                <Layout section='dashboard'>
                    <h1>👋 Hi, {displayName}</h1>
                    <div className='content-box'>
                        <Spinner animation='border' />
                    </div>
                </Layout>
            );
        }

        const availableInvestmentsBlock: JSX.Element[] = _.reverse(_.sortBy(availableInvestments, ['createdTime'])).map((investment: IInvestment) => {
            return (
                <InvestmentBox investment={investment} key={investment.uuid} />
            );
        });

        const recentlyFundedInvestmentsBlock: JSX.Element[] = recentlyFundedInvestments.map((investment: IInvestment) => {
            return (
                <InvestmentBox investment={investment} key={investment.uuid} />
            );
        });

        return (
            <Layout section='dashboard'>
                <h1>👋 Hi, {displayName}</h1>
                <div className='content-box portfolio-snapshot'>
                    <h2>Entire Portfolio Snapshot</h2>
                    <div className='boxes'>
                        <div className='box cash-balance'>
                            <div className='primary'>
                                <div className='label'>Cash Balance</div>
                                <div className='value'>{numeral(marketplaceAccount.balance).format('$0,0[.]00')}</div>
                            </div>
                            <div className='secondary'>
                                <div className='label'>Total Portfolio</div>
                                <div className='value'>{numeral(marketplaceAccount.balance + marketplaceAccount.investedAmountCurrent).format('$0,0[.]00')}</div>
                            </div>
                        </div>
                        <div className='box principal-invested'>
                            <div className='primary'>
                                <div className='label'>Principal Invested</div>
                                <div className='value'>{numeral(marketplaceAccount.investedAmountCurrent).format('$0,0[.]00')}</div>
                            </div>
                            <div className='secondary'>
                                <div className='label'>Pending Top Up Cash</div>
                                <div className='value'>{numeral(marketplaceAccount.depositAmountPending).format('$0,0[.]00')}</div>
                            </div>
                        </div>
                        <div className='box total-income'>
                            <div className='primary'>
                                <div className='label'>Total Income</div>
                                <div className='value'>{numeral(marketplaceAccount.interestAmountEarned).format('$0,0[.]00')}</div>
                            </div>
                            <div className='secondary'>
                                <div className='label'>Pending Withdrawal</div>
                                <div className='value'>{numeral(marketplaceAccount.withdrawalAmountPending).format('$0,0[.]00')}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content-box available-investments'>
                    <h2>New Available Investments</h2>
                    <div>
                        {availableInvestmentsBlock}
                        {availableInvestments.length === 0 && `Looks like our investments were too popular! We'll have more soon.`}
                    </div>
                </div>
                <div className='content-box latest-funded'>
                    <h2>Latest Funded Loans</h2>
                    <div>{recentlyFundedInvestmentsBlock}</div>
                </div>
                <ReferFriendBox />
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        availableInvestments: availableInvestmentsSelector(state),
        currentInvestor: currentInvestorSelector(state),
        currentUser: currentUserSelector(state),
        marketplaceAccount: currentInvestorAccountSelector(state, AccountTypeEnum.Marketplace),
        recentlyFundedInvestments: recentlyFundedInvestmentsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        availableInvestmentsList: () => dispatch(investmentsAvailableListAction()),
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
        currentUserGet: () => dispatch(currentUserGetAction()),
        recentlyFundedInvestmentsList: () => dispatch(investmentsRecentlyFundedListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Dashboard);
