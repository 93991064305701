import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import roundTo from 'round-to';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import {
    currentInvestorGetAction,
    incomeTrustInvestAction,
    incomeTrustInvestCompleteModalShowSetAction,
    incomeTrustInvestConfirmModalShowSetAction,
    incomeTrustInvestErrorSetAction,
    incomeTrustsListAction,
} from '~Investor/actions';
import {
    currentInvestorAccountIncomeTrustByClassTypeSelector,
    currentInvestorAccountSelector,
    currentInvestorSelector,
    incomeTrustInvestCompleteModalShowSelector,
    incomeTrustInvestConfirmModalShowSelector,
    incomeTrustInvestErrorsSelector,
    incomeTrustInvestInProgressSelector,
} from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import BpayLogo from '~UI/BpayLogo';
import './invest-modal.less';
import IncomeTrustClassTypeEnum from '~Api/Investor/IncomeTrustClassTypeEnum';
import { IIncomeTrustInvestErrors } from '~Investor/reducer';
import IIncomeTrust from '~Api/Investor/IIncomeTrust';
import IncomeTrustClassTypeLabels from '~Api/Investor/IncomeTrustClassTypeLabels';

interface IState {
    amount: number;
}

interface IProps {
    classType: IncomeTrustClassTypeEnum;
}

interface IPropsSelector {
    currentInvestor: IInvestor;
    incomeTrust: IIncomeTrust;
    incomeTrustAccount: IAccount;
    isInvestCompleteModalOpen: boolean;
    isInvestConfirmModalOpen: boolean;
    investErrors: IIncomeTrustInvestErrors;
    investInProgress: boolean;
}

interface IPropsDispatch {
    completeModalShow: (show: boolean) => void;
    confirmModalShow: (show: boolean) => void;
    currentInvestorGet: () => void;
    incomeTrustsList: () => void;
    invest: (amount: number, incomeTrustUuid: string) => void;
    setError: (key: keyof IIncomeTrustInvestErrors, value: string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class InvestModal extends React.Component<Props, IState> {
    public state: IState = {
        amount: null,
    };

    constructor(props: Props) {
        super(props);

        this.completeModalHide = this.completeModalHide.bind(this);
        this.confirmModalHide = this.confirmModalHide.bind(this);

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
        this.validateAmount = this.validateAmount.bind(this);
    }

    public componentDidMount(): void {
        const { incomeTrust } = this.props;

        if (!incomeTrust) {
            this.props.incomeTrustsList();
        }
    }

    public render(): JSX.Element {
        const { classType, isInvestCompleteModalOpen, isInvestConfirmModalOpen, incomeTrust, incomeTrustAccount, investErrors, investInProgress } = this.props;
        const { amount } = this.state;

        if (!incomeTrust) {
            return null;
        }

        const minimumAmount: number = 50000;

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        if (isInvestCompleteModalOpen) {
            return (
                <Modal
                    backdropClassName='investor-income-trust-invest-modal-backdrop'
                    dialogClassName='investor-income-trust-invest-modal complete'
                    onHide={this.completeModalHide}
                    show={isInvestCompleteModalOpen}
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title>Thank you for submitting an investment request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='title'>Pay via BPAY</p>
                        <p className='intro'>
                            Use BPAY to transfer below amount from your account and send remittance advice to <a href='mailto:invest@funding.com.au'>invest@funding.com.au</a>.
                        </p>
                        <div className='bpay-box'>
                            <div className='logo'><BpayLogo /></div>
                            <div className='details'>
                                <div>
                                    <strong>Biller Code:</strong> 358317
                                </div>
                                <div>
                                    <strong>Ref:</strong> {incomeTrustAccount.code}
                                </div>
                            </div>
                            <div className='notes'>
                                <strong>Telephone &amp; Internet Banking – BPAY<sup>&reg;</sup></strong><br />
                                Contact your bank or financial institution to make this<br />
                                payment from your cheque, savings, debit, credit card<br />
                                or transaction account. More info:<a href='https://www.bpay.com.au' target='_blank' rel='noreferrer'>www.bpay.com.au</a><br />
                            </div>
                        </div>
                        <hr className='spacing' />
                        <p className='title'>Pay via Bank Transfer</p>
                        <p className='intro'>
                            Please transfer below amount to given bank details and send remittance advice to <a href='mailto:invest@funding.com.au'>invest@funding.com.au</a>.
                        </p>
                        <div>
                            <div className='label'>Account Name:</div>
                            <div className='value'>Sandhurst Trustees Limited ACF Funding Capital Pty Ltd Atf Funding Income Trust – Applications Account</div>
                        </div>
                        <div>
                            <div className='label'>BSB:</div>
                            <div className='value'>083 001</div>
                        </div>
                        <div>
                            <div className='label'>Account Number:</div>
                            <div className='value'>78 618 6600</div>
                        </div>
                        <div>
                            <div className='label'>Bank:</div>
                            <div className='value'>National Australia Bank</div>
                        </div>
                        <div>
                            <div className='label'>Amount:</div>
                            <div className='value'>{currencyFormatter.format(amount)}</div>
                        </div>
                        <div>
                            <div className='label'>Payment Reference:</div>
                            <div className='value'>{incomeTrustAccount.code}</div>
                        </div>
                        <br />
                        <Button onClick={this.completeModalHide}>OK</Button>
                    </Modal.Body>
                </Modal>
            );
        }

        const firstUnhandledError: string = _.head(_.values(_.omit(investErrors, ['amount'])));

        return (
            <Modal
                backdropClassName='investor-income-trust-invest-modal-backdrop'
                dialogClassName='investor-income-trust-invest-modal'
                onHide={this.confirmModalHide}
                show={isInvestConfirmModalOpen}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>{IncomeTrustClassTypeLabels[classType]} Investment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {firstUnhandledError && <Alert variant='danger'>{firstUnhandledError}</Alert>}
                    <p className='instructions-1'>Enter the dollar amount you wish to add to your investment.</p>
                    <Form.Group className='amount'>
                        <Form.Label>Amount in AUD $</Form.Label>
                        <Form.Control isInvalid={!!investErrors.amount} onBlur={this.validateAmount} onChange={this.onChangeAmount} placeholder={`e.g. ${minimumAmount}`} type='number' value={amount || ''} />
                        {!!investErrors.amount && <Form.Control.Feedback type='invalid'>{investErrors.amount}</Form.Control.Feedback>}
                    </Form.Group>
                    <p className='instructions-2'>Once you click submit you will be advised of the bank account details where you are to transfer the funds to.</p>
                    <Button className='invest' disabled={investInProgress} onClick={this.onClickOk} variant='primary'>
                        {investInProgress && <Spinner animation='border' size='sm' />} Submit
                    </Button>
                </Modal.Body>
            </Modal>
        );
    }

    private completeModalHide(): void {
        this.props.completeModalShow(false);
    }

    private confirmModalHide(): void {
        this.props.confirmModalShow(false);
    }

    private onChangeAmount(event: React.ChangeEvent<HTMLInputElement>): void {
        if (/[^0-9.]/.test(event.target.value)) {
            return;
        }

        this.setState({
            amount: roundTo(Number(event.target.value), 2),
        });
    }

    private onClickOk(): void {
        const { amount } = this.state;
        const { incomeTrust } = this.props;

        let valid: boolean = true;
        valid = this.validateAmount() && valid;

        if (!valid) {
            return;
        }

        this.props.invest(amount, incomeTrust.uuid);
    }

    private validateAmount(): boolean {
        const { amount } = this.state;

        let error: string;

        if (!amount) {
            error = 'Please enter the amount';
        } else {
            const minimumAmount: number = 50000;
            if (amount < minimumAmount) {
                error = `The minimum amount is ${numeral(minimumAmount).format('$0,0[.]00')}`;
            }
        }

        this.props.setError('amount', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        incomeTrust: currentInvestorAccountIncomeTrustByClassTypeSelector(state, ownProps.classType),
        incomeTrustAccount: currentInvestorAccountSelector(state, AccountTypeEnum.IncomeTrust),
        isInvestCompleteModalOpen: incomeTrustInvestCompleteModalShowSelector(state),
        isInvestConfirmModalOpen: incomeTrustInvestConfirmModalShowSelector(state),
        investErrors: incomeTrustInvestErrorsSelector(state),
        investInProgress: incomeTrustInvestInProgressSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        completeModalShow: (show: boolean) => dispatch(incomeTrustInvestCompleteModalShowSetAction(show)),
        confirmModalShow: (show: boolean) => dispatch(incomeTrustInvestConfirmModalShowSetAction(show)),
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
        incomeTrustsList: () => dispatch(incomeTrustsListAction()),
        invest: (amount: number, incomeTrustUuid: string) => dispatch(incomeTrustInvestAction(amount, incomeTrustUuid)),
        setError: (key: keyof IIncomeTrustInvestErrors, value: string) => dispatch(incomeTrustInvestErrorSetAction(key , value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InvestModal);
