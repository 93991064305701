import { Action } from 'redux';
import { IDeal } from '~Api/Deal';
import ActionsEnum from './ActionsEnum';

export interface IPartnerReferralDealAddAction extends Action {
    lead: IDeal;
}

export function PartnerReferralDealAddAction(lead: IDeal): IPartnerReferralDealAddAction {
    return {
        lead,
        type: ActionsEnum.PartnerReferralDealAdd,
    };
}
