import { all, call, ForkEffectDescriptor, put, SimpleEffect, spawn } from '@redux-saga/core/effects';
import Cookies from 'js-cookie';
import { Saga } from 'redux-saga';
import { ApplicationSagas } from '~Application/sagas';
import { authTokenSetAction } from '~Auth/actions';
import { AuthSagas } from '~Auth/sagas';
import { BrokerSagas } from '~Broker/sagas';
import { InvestorSagas } from '~Investor/sagas';
import { LeadSagas } from '~Lead/sagas';
import { PartnerReferralSagas } from '~PartnerReferral/sagas';
import { PublicSagas } from '~Public/sagas';
import { UserSagas } from '~User/sagas';

export default function* rootSaga(): any {
    const sagas: Saga[] = [
        ApplicationSagas,
        AuthSagas,
        BrokerSagas,
        InvestorSagas,
        LeadSagas,
        PublicSagas,
        PartnerReferralSagas,
        UserSagas,
    ];

    yield all(
        sagas.map((saga: Saga): SimpleEffect<'FORK', ForkEffectDescriptor<any>> => {
            return spawn(function*(): any {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (e: any) {
                        switch (e.name) {
                            case 'TokenExpiredError':
                                yield put(authTokenSetAction(null));
                                Cookies.remove('currentUser');
                                (document as any).location = '/';
                                break;
                            default:
                                throw e;
                        }
                    }
                }
            });
        }),
    );
}
