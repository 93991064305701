import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import './help-centre.less';
import Layout from './Layout';

class HelpCentre extends React.Component {
    public render(): JSX.Element {
        return (
            <Layout section='faq'>
                <h1>Help Centre</h1>
                <div className='content-box'>
                    <Accordion>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='0'>What is a Wholesale Investor?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='0'>
                                <Card.Body>
                                    If you invest at least AU$500,000 in the Funding Income Trust you are automatically classed as a wholesale investor.<br />
                                    <br />
                                    You also qualify as a wholesale investor if you provide a certificate by a qualified accountant stating that you have net assets of at least AU$2.5 million or gross income for each of the last two financial years of at least AU$250,000. A certificate issued by an accountant is valid for up to 2 years.<br />
                                    <br />
                                    Note – if you are contemplating an investment in the Funding Income Trust, you will find the accountants certificate in the Application Form.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='1'>Can I access my funds early?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='1'>
                                <Card.Body>
                                    You will need to give 90 days notice from the end of the current investment period to make any redemptions. You can not redeem an amount that will leave less than $50,000 remaining in your account unless you would like to redeem your full balance.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='2'>What is a first mortgage?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='2'>
                                <Card.Body>
                                    Our investments are backed by a registered first mortgage loan against Australian real estate. A first mortgage is a first charge over real estate owned by the borrower. If there is a Default in repayment of the Loan, the property can be sold in order to recover the Loan and repay Members.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='3'>What type of real estate security is provided?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='3'>
                                <Card.Body>
                                    The security property varies from loan to loan however each loan is secured over either Residential or Commercial real estate in locations across Australia.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='4'>What type of borrowers do we lend to?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='4'>
                                <Card.Body>
                                    Borrowers must have Australian real estate security, the ability to meet their repayments and a repayment strategy to exit the Loan at the end of the term. Our mortgages are catered to borrowers that need a fast and flexible solution. Short-term mortgages are more expensive than traditional finance, which is why we are able to provide higher returns to our investors.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='5'>What is the Funding Income Trust?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='5'>
                                <Card.Body>
                                    The Funding Income Trust is the legal structure for our Pooled Fund. The Funding Income Trust is an unregistered managed investment scheme where members of the Trust are provided with access to first mortgage investments.<br />
                                    <br />
                                    Manager: Funding.com.au Pty Ltd ACN 603 756 547, Trustee: Funding Capital Pty Ltd ACN 639 230 345, AFSL 523247 and Custodian: Sandhurst Trustees Limited ACN 004 030 737
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='6'>What is the LVR on the loans?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='6'>
                                <Card.Body>
                                    The loan to value ratio or LVR is the maximum lend secured over the property. The LVR of mortgages within the pool may vary, however, all LVR’s must be less than 70% of the value of the security property. (e.g. Loan of $480,000 divide by Property Value of $800,000 = 60% LVR)
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='7'>When do I get paid?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='7'>
                                <Card.Body>
                                    The interest from your investments is intended to be paid monthly, into your investment account, subject to the performance of the investments. You can elect to have these funds automatically reinvested, or withdraw to your nominated bank account. Please allow for 2-3 business days for withdrawals to be processed.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='8'>How is the interest rate determined?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='8'>
                                <Card.Body>
                                    Providing the loans in the Trust perform as per the mortgage contracts, the target rate is currently 7.00% pa (net of fees)*.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
            </Layout>
        );
    }
}

export default HelpCentre;
