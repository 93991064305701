import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { Spinner, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IApplication } from '~Api/Application';
import WorkflowStatusEnum from '~Api/Application/WorkflowStatusEnum';
import { IDeal } from '~Api/Deal';
import { applicationsListAction } from '~Application/actions';
import { applicationsSelector } from '~Application/selectors';
import { dealsListAction } from '~Lead/actions';
import { dealsSelector } from '~Lead/selectors';
import { IGlobalState } from '~reducer';
import Pagination from '~UI/Pagination';
import SortableHeader, { SortOrderEnum } from '~UI/SortableHeader';
import { IDictionary } from '~utilities/IDictionary';

export enum FilterEnum {
    Pending = 'PENDING',
    Settled = 'SETTLED',
}

enum SortFieldEnum {
    ClientName = 'formattedName',
    CreatedTime = 'createdTime',
    LoanAmount = 'loanAmount',
    BrokerageFee = 'brokerageFee',
    Status = 'workflowStatus',
}

const workflowStatusLabels: IDictionary<string> = {
    [WorkflowStatusEnum.ConditionalApproval]: 'Conditional Approval',
    [WorkflowStatusEnum.Draft]: 'Draft',
    [WorkflowStatusEnum.LegalDocuments]: 'Legal Documents',
    [WorkflowStatusEnum.New]: 'New',
    [WorkflowStatusEnum.Settlement]: 'Settlement',
    [WorkflowStatusEnum.Underwriting]: 'Underwriting',
    [WorkflowStatusEnum.Warehoused]: 'Settled',
};

interface IState {
    currentPage: number;
    perPage: number;
    sortField: SortFieldEnum;
    sortOrder: SortOrderEnum;
}

interface IProps {
    filter: FilterEnum;
}

interface IPropsSelector {
    applications: IDictionary<IApplication>;
    deals: IDictionary<IDeal>;
}

interface IPropsDispatch {
    applicationsList: () => void;
    dealsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class ApplicationsTable extends React.Component<Props, IState> {
    public state: IState = {
        currentPage: 1,
        perPage: 10,
        sortField: SortFieldEnum.CreatedTime,
        sortOrder: SortOrderEnum.Ascending,
    };

    constructor(props: Props) {
        super(props);

        this.onChangePerPage = this.onChangePerPage.bind(this);

        this.onClickPage = this.onClickPage.bind(this);
        this.onClickSort = this.onClickSort.bind(this);
        this.onClickSortByBrokerageFee = this.onClickSortByBrokerageFee.bind(this);
        this.onClickSortByClientName = this.onClickSortByClientName.bind(this);
        this.onClickSortByLoanAmount = this.onClickSortByLoanAmount.bind(this);
        this.onClickSortByApplicationStatus = this.onClickSortByApplicationStatus.bind(this);
        this.onClickSortByCreatedTime = this.onClickSortByCreatedTime.bind(this);
    }

    public componentDidMount() {
        const { applications, deals } = this.props;

        if (!applications) {
            this.props.applicationsList();
        }

        if (!deals) {
            this.props.dealsList();
        }
    }

    public render(): JSX.Element {
        const { applications, deals, filter } = this.props;
        const { currentPage, perPage, sortField, sortOrder } = this.state;

        if (!applications || !deals) {
            return (
                <div className='spinner'>
                    <Spinner animation='border' />
                </div>
            );
        }

        let filterPredicate: any = null;
        switch (filter) {
            case FilterEnum.Pending:
                filterPredicate = (application: IApplication) => application.workflowStatus !== WorkflowStatusEnum.Warehoused;
                break;
            case FilterEnum.Settled:
                filterPredicate = (application: IApplication) => application.workflowStatus === WorkflowStatusEnum.Warehoused;
                break;
        }

        const filteredApplications = _.orderBy(_.filter(applications, filterPredicate), [sortField], [sortOrder]);

        const offset: number = (currentPage - 1) * perPage;
        const pageApplications: IApplication[] = _.slice(filteredApplications, offset, offset + perPage);

        const applicationsBlock: JSX.Element[] = pageApplications.map((application: IApplication) => {
            return (
                <tr key={application.uuid}>
                    <td className='created-date'>{moment(application.createdTime).format('D MMM YYYY')}</td>
                    <td className='client-name'>{application.formattedName}</td>
                    <td className='loan-amount'>{numeral(application.loanAmount).format('$0,0[.]00')}</td>
                    <td className='status'>{filter !== FilterEnum.Settled && !!application.deal.closedTime ? 'Closed' : workflowStatusLabels[application.workflowStatus]}</td>
                    <td className='brokerage-fee'>{numeral(application.brokerageFee).format('$0,0[.]00')}</td>
                </tr>
            );
        });

        return (
            <React.Fragment>
                <Table
                    className='application-table list-table'
                    bordered={true}
                >
                    <thead>
                        <tr>
                            <SortableHeader
                                className={'created-date'}
                                field={SortFieldEnum.CreatedTime}
                                label='Submitted'
                                onClick={this.onClickSortByCreatedTime}
                                sortField={sortField}
                                sortOrder={sortOrder}
                            />
                            <SortableHeader
                                className={'client-name'}
                                field={SortFieldEnum.ClientName}
                                label='Client Name'
                                onClick={this.onClickSortByClientName}
                                sortField={sortField}
                                sortOrder={sortOrder}
                            />
                            <SortableHeader
                                className={'loan-amount'}
                                field={SortFieldEnum.LoanAmount}
                                label='Loan Amount'
                                onClick={this.onClickSortByLoanAmount}
                                sortField={sortField}
                                sortOrder={sortOrder}
                            />
                            <SortableHeader
                                className={'status'}
                                field={SortFieldEnum.Status}
                                label='Status'
                                onClick={this.onClickSortByApplicationStatus}
                                sortField={sortField}
                                sortOrder={sortOrder}
                            />
                            <SortableHeader
                                className={'brokerage-fee'}
                                field={SortFieldEnum.BrokerageFee}
                                label='Commission'
                                onClick={this.onClickSortByBrokerageFee}
                                sortField={sortField}
                                sortOrder={sortOrder}
                            />
                        </tr>
                    </thead>
                    <tbody>
                        {applicationsBlock}
                    </tbody>
                </Table>
                <div className='pagination-block'>
                    <Pagination
                        currentPage={currentPage}
                        itemCount={filteredApplications.length}
                        onChangePerPage={this.onChangePerPage}
                        onClickPage={this.onClickPage}
                        perPage={perPage}
                    />
                </div>
            </React.Fragment>
        );
    }

    private onClickPage(page: number) {
        this.setState({
            currentPage: page,
        });
    }

    private onChangePerPage(perPage: number) {
        this.setState({
            currentPage: 1,
            perPage,
        });
    }

    private onClickSort(field: SortFieldEnum) {
        const { sortField, sortOrder } = this.state;

        if (sortField === field) {
            this.setState({
                sortOrder: sortOrder === SortOrderEnum.Ascending ? SortOrderEnum.Descending : SortOrderEnum.Ascending,
            });
        } else {
            this.setState({
                sortField: field,
                sortOrder: SortOrderEnum.Ascending,
            });
        }
    }

    private onClickSortByApplicationStatus() {
        this.onClickSort(SortFieldEnum.Status);
    }

    private onClickSortByBrokerageFee() {
        this.onClickSort(SortFieldEnum.BrokerageFee);
    }

    private onClickSortByClientName() {
        this.onClickSort(SortFieldEnum.ClientName);
    }

    private onClickSortByCreatedTime() {
        this.onClickSort(SortFieldEnum.CreatedTime);
    }

    private onClickSortByLoanAmount() {
        this.onClickSort(SortFieldEnum.LoanAmount);
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        applications: applicationsSelector(state),
        deals: dealsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        applicationsList: () => dispatch(applicationsListAction()),
        dealsList: () => dispatch(dealsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ApplicationsTable);
