import React from 'react';
import PublicLayout from '../Layout';
import './broker.less';

class Layout extends React.Component {
    public render(): JSX.Element {
        return (
            <PublicLayout contactEmail='broker@funding.com.au' showLogin={false}>
                <div className='broker'>
                    {this.props.children}
                </div>
            </PublicLayout>
        );
    }
}

export default Layout;
