import dayjs from 'dayjs';
import _ from 'lodash';
import IncomeTrustClassTypeEnum from '~Api/Investor/IncomeTrustClassTypeEnum';
import constants from '~constants';
import { IDictionary } from '~utilities/IDictionary';

export function getIncomeTrustClassInterestRate(incomeTrustClassType: IncomeTrustClassTypeEnum): number {
    const currentBrisbaneDate: string = dayjs().tz('Australia/Brisbane').format('YYYY-MM-DD');
    const interestRates: IDictionary<number> = constants.INCOME_TRUST_INTEREST_RATES[incomeTrustClassType];

    return _.find(interestRates, (interestRate: number, date: string): boolean => currentBrisbaneDate >= date);
}
