import { all, call, debounce, put, select, takeEvery } from '@redux-saga/core/effects';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import { toastAddAction } from '~Global/actions';
import history from '~history';
import { IFetchResponse } from '~utilities/fetch';
import {
    currentUserSetAction,
    emailChangeErrorsSetAction,
    emailChangeInProgressSetAction,
    emailChangeModalHideAction,
    ICurrentUserGetAction,
    IEmailChangeAction,
    IEmailVerifyAction,
    IPasswordChangeAction,
    passwordChangeErrorsSetAction,
    passwordChangeInProgressSetAction,
    passwordChangeModalHideAction,
} from './actions';
import UserActionsEnum from './ActionsEnum';
import IUser from './IUser';
import { parseUser } from './parsers';
import {
    userEmailRequest,
    userEmailVerifyRequest,
    userGetRequest,
    userPasswordRequest,
} from './requests';

function* currentUserGet(action: ICurrentUserGetAction): Iterator<any> {
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    const rawUser: IFetchResponse = yield call(userGetRequest, currentUser.userUuid);
    const user: IUser = parseUser(rawUser.body);
    yield put(currentUserSetAction(user));
}

function* emailChange(action: IEmailChangeAction): Iterator<any> {
    yield put(emailChangeInProgressSetAction(true));
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    const rawEmail: IFetchResponse = yield call(userEmailRequest, currentUser.userUuid, action.email);
    if (rawEmail.status === 422) {
        yield put(emailChangeErrorsSetAction(rawEmail.body));
    } else if (rawEmail.status === 200) {
        yield put(emailChangeModalHideAction());
        yield put(toastAddAction('Change Email', 'A verification link has been sent to your email account.'));
    }

    yield put(emailChangeInProgressSetAction(false));
}

function* emailVerify(action: IEmailVerifyAction): Iterator<any> {
    const rawEmailVerify: IFetchResponse = yield call(userEmailVerifyRequest, action.code);
    if (rawEmailVerify.status === 200) {
        history.push('/verify-email/verified');
    }
}

function* passwordChange(action: IPasswordChangeAction): Iterator<any> {
    yield put(passwordChangeInProgressSetAction(true));
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    const rawPassword: IFetchResponse = yield call(userPasswordRequest, currentUser.userUuid, action.password);
    if (rawPassword.status === 422) {
        yield put(passwordChangeErrorsSetAction(rawPassword.body));
    } else if (rawPassword.status === 200) {
        yield put(passwordChangeModalHideAction());
        yield put(toastAddAction('Change Password', 'Your password has been changed.'));
    }

    yield put(passwordChangeInProgressSetAction(false));
}

export function* UserSagas(): any {
    yield all([
        debounce(20, UserActionsEnum.CurrentUserGet, currentUserGet),
        takeEvery(UserActionsEnum.EmailChange, emailChange),
        takeEvery(UserActionsEnum.EmailVerify, emailVerify),
        takeEvery(UserActionsEnum.PasswordChange, passwordChange),
    ]);
}
