import _ from 'lodash';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IDealProperty, { IDealPropertyErrors } from '~Api/Deal/IDealProperty';
import PropertyStateEnum from '~Api/Deal/PropertyStateEnum';
import PropertyStreetTypeEnum from '~Api/Deal/PropertyStreetTypeEnum';
import propertyStreetTypeLabels from '~Api/Deal/PropertyStreetTypeLabels';
import ZoneTypeEnum from '~Api/Deal/ZoneTypeEnum';
import { authRegisterErrorsSelector } from '~Auth/selectors';
import {
    propertyDeleteAction,
    propertyErrorSetAction,
    propertyValueSetAction,
} from '~Lead/actions';
import { propertySelector } from '~Lead/selectors';
import {
    validateCurrentDebt,
    validateEstimatedValue,
    validatePostcode,
    validateState,
    validateStreetAddress,
    validateStreetName,
    validateStreetNumber,
    validateStreetType,
    validateSuburb,
    validateUnitNumber,
    validateZoneType,
} from '~Lead/validators';
import { IGlobalState } from '~reducer';

interface IProps {
    uuid: string;
    index: number;
}

interface IPropsSelector {
    property: IDealProperty;
    borrowErrors: any;
}

interface IPropsDispatch {
    remove: () => void;
    setError: (key: keyof IDealPropertyErrors, value: string) => void;
    setValue: (key: keyof IDealProperty, value: any) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Property extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeStreetAddress = this.onChangeStreetAddress.bind(this);
        this.onChangeUnitNumber = this.onChangeUnitNumber.bind(this);
        this.onChangeStreetNumber = this.onChangeStreetNumber.bind(this);
        this.onChangeStreetName = this.onChangeStreetName.bind(this);
        this.onChangeStreetType = this.onChangeStreetType.bind(this);
        this.onChangeCurrentDebt = this.onChangeCurrentDebt.bind(this);
        this.onChangePostcode = this.onChangePostcode.bind(this);
        this.onChangeEstimatedValue = this.onChangeEstimatedValue.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeSuburb = this.onChangeSuburb.bind(this);
        this.onChangeZoneType = this.onChangeZoneType.bind(this);

        this.validateStreetAddress = this.validateStreetAddress.bind(this);
        this.validateUnitNumber = this.validateUnitNumber.bind(this);
        this.validateStreetNumber = this.validateStreetNumber.bind(this);
        this.validateStreetName = this.validateStreetName.bind(this);
        this.validateStreetType = this.validateStreetType.bind(this);
        this.validateCurrentDebt = this.validateCurrentDebt.bind(this);
        this.validatePostcode = this.validatePostcode.bind(this);
        this.validateEstimatedValue = this.validateEstimatedValue.bind(this);
        this.validateState = this.validateState.bind(this);
        this.validateSuburb = this.validateSuburb.bind(this);
        this.validateZoneType = this.validateZoneType.bind(this);
    }

    public render(): JSX.Element {
        const { index, property } = this.props;

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            style: 'currency',
        });

        return (
            <React.Fragment>
                {index !== 0 && <h4>Property {index + 1}</h4>}
                {index > 0 && <Button className='remove-property' onClick={this.props.remove} variant='light'>Remove Property</Button>}
                <Form.Group className='unit-number'>
                    <Form.Label>Unit Number (if any)</Form.Label>
                    <Form.Control isInvalid={!!property.errors.unitNumber} maxLength={10} onBlur={this.validateUnitNumber} onChange={this.onChangeUnitNumber} value={property.unitNumber || ''} />
                    {property.errors.unitNumber && <Form.Control.Feedback type='invalid'>{property.errors.unitNumber}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='street-number'>
                    <Form.Label>Street Number</Form.Label>
                    <Form.Control isInvalid={!!property.errors.streetNumber} maxLength={10} onBlur={this.validateStreetNumber} onChange={this.onChangeStreetNumber}  value={property.streetNumber || ''} />
                    {property.errors.streetNumber && <Form.Control.Feedback type='invalid'>{property.errors.streetNumber}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='street-name'>
                    <Form.Label>Street Name</Form.Label>
                    <Form.Control isInvalid={!!property.errors.streetName} maxLength={255} onBlur={this.validateStreetName} onChange={this.onChangeStreetName} value={property.streetName || ''} />
                    {property.errors.streetName && <Form.Control.Feedback type='invalid'>{property.errors.streetName}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='street-type'>
                    <Form.Label>Street Type</Form.Label>
                    <Form.Control as='select' className='custom-select' isInvalid={!!property.errors.streetType} onBlur={this.validateStreetType} onChange={this.onChangeStreetType} value={property.streetType || ''}>
                        {property.streetType === null && <option/>}
                        {_.keys(propertyStreetTypeLabels).map((code: string): JSX.Element => <option key={code} value={code}>{propertyStreetTypeLabels[code]}</option>)}
                    </Form.Control>
                    {property.errors.streetType && <Form.Control.Feedback type='invalid'>{property.errors.streetType}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='suburb'>
                    <Form.Label>Suburb</Form.Label>
                    <Form.Control isInvalid={!!property.errors.suburb} maxLength={50} onBlur={this.validateSuburb} onChange={this.onChangeSuburb} value={property.suburb || ''} />
                    {property.errors.suburb && <Form.Control.Feedback type='invalid'>{property.errors.suburb}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='state'>
                    <Form.Label>State</Form.Label>
                    <Form.Control as='select' className='custom-select' isInvalid={!!property.errors.state} onBlur={this.validateState} onChange={this.onChangeState} value={property.state || ''}>
                        {property.state === null && <option/>}
                        <option value={PropertyStateEnum.AustralianCapitalTerritory}>ACT</option>
                        <option value={PropertyStateEnum.NewSouthWales}>NSW</option>
                        <option value={PropertyStateEnum.NorthernTerritory}>NT</option>
                        <option value={PropertyStateEnum.Queensland}>QLD</option>
                        <option value={PropertyStateEnum.SouthAustralia}>SA</option>
                        <option value={PropertyStateEnum.Tasmania}>TAS</option>
                        <option value={PropertyStateEnum.Victoria}>VIC</option>
                        <option value={PropertyStateEnum.WesternAustralia}>WA</option>
                    </Form.Control>
                    {property.errors.state && <Form.Control.Feedback type='invalid'>{property.errors.state}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='postcode'>
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control isInvalid={!!property.errors.postcode} maxLength={4} onBlur={this.validatePostcode} onChange={this.onChangePostcode} value={property.postcode || ''} />
                    {property.errors.postcode && <Form.Control.Feedback type='invalid'>{property.errors.postcode}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='zone-type'>
                    <p>What type of property is it?</p>
                    <Form.Label>Select Type</Form.Label>
                    <Form.Control as='select' className='custom-select' isInvalid={!!property.errors.zoneType} onBlur={this.validateZoneType} onChange={this.onChangeZoneType} value={property.zoneType || ''}>
                        {property.zoneType === null && <option/>}
                        <option value={ZoneTypeEnum.ResidentialHouse}>Residential - House</option>
                        <option value={ZoneTypeEnum.ResidentialTownhouse}>Residential - Townhouse / Villa</option>
                        <option value={ZoneTypeEnum.ResidentialUnit}>Residential - Unit / Apartment</option>
                        <option value={ZoneTypeEnum.ResidentialLand}>Residential - Land</option>
                        <option value={ZoneTypeEnum.CommercialOffice}>Commercial - Office</option>
                        <option value={ZoneTypeEnum.CommercialRetail}>Commercial - Retail</option>
                        <option value={ZoneTypeEnum.CommercialIndustrial}>Commercial - Industrial</option>
                        <option value={ZoneTypeEnum.CommercialLand}>Commercial - Land</option>
                        <option value={ZoneTypeEnum.RuralResidential}>Rural - Residential</option>
                        <option value={ZoneTypeEnum.RuralLand}>Rural - Land</option>
                    </Form.Control>
                    {property.errors.zoneType && <Form.Control.Feedback type='invalid'>{property.errors.zoneType}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='estimated-value'>
                    <p>How much is it worth?</p>
                    <Form.Label>Enter Amount</Form.Label>
                    <Form.Control isInvalid={!!property.errors.estimatedValue} min={0} onBlur={this.validateEstimatedValue} onChange={this.onChangeEstimatedValue} type='number' value={property.estimatedValue || ''} />
                    <Form.Text>{property.estimatedValue ? currencyFormatter.format(property.estimatedValue) : ''}</Form.Text>
                    {property.errors.estimatedValue && <Form.Control.Feedback type='invalid'>{property.errors.estimatedValue}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='current-debt'>
                    <p>How much do you owe on it?</p>
                    <Form.Label>Enter Amount</Form.Label>
                    <Form.Control isInvalid={!!property.errors.currentDebt} min={0} onBlur={this.validateCurrentDebt} onChange={this.onChangeCurrentDebt} type='number' value={property.currentDebt ?? ''} />
                    <Form.Text>{property.currentDebt ? currencyFormatter.format(property.currentDebt) : ''}</Form.Text>
                    {property.errors.currentDebt && <Form.Control.Feedback type='invalid'>{property.errors.currentDebt}</Form.Control.Feedback>}
                </Form.Group>
            </React.Fragment>
        );
    }

    private onChangeStreetAddress(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue('streetAddress', event.target.value);
    }

    private onChangeUnitNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue('unitNumber', event.target.value);
    }

    private onChangeStreetNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue('streetNumber', event.target.value);
    }

    private onChangeStreetName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue('streetName', event.target.value);
    }

    private onChangeStreetType(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue('streetType', event.target.value as PropertyStreetTypeEnum);
    }

    private onChangePostcode(event: React.ChangeEvent<HTMLInputElement>): void {
        if (/[^0-9]/.test(event.target.value) || event.target.value.trim().length > 4) {
            return;
        }

        this.props.setValue('postcode', event.target.value);
    }

    private onChangeZoneType(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue('zoneType', event.target.value as ZoneTypeEnum);
    }

    private onChangeEstimatedValue(event: React.ChangeEvent<HTMLInputElement>): void {
        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.props.setValue('estimatedValue', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private onChangeCurrentDebt(event: React.ChangeEvent<HTMLInputElement>): void {
        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.props.setValue('currentDebt', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private onChangeState(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue('state', event.target.value as PropertyStateEnum || null);
    }

    private onChangeSuburb(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue('suburb', event.target.value.substr(0, 50));
    }

    private validateStreetAddress(): boolean {
        return !validateStreetAddress(this.props.property.streetAddress, this.props.setError);
    }

    private validateUnitNumber(): boolean {
        return !validateUnitNumber(this.props.property.unitNumber, this.props.setError);
    }

    private validateStreetNumber(): boolean {
        return !validateStreetNumber(this.props.property.streetNumber, this.props.setError);
    }

    private validateStreetName(): boolean {
        return !validateStreetName(this.props.property.streetName, this.props.setError);
    }

    private validateStreetType(): boolean {
        return !validateStreetType(this.props.property.streetType, this.props.setError);
    }

    private validatePostcode(): boolean {
        return !validatePostcode(this.props.property.postcode, this.props.property.state, this.props.setError);
    }

    private validateZoneType(): boolean {
        return !validateZoneType(this.props.property.zoneType, this.props.setError);
    }

    private validateEstimatedValue(): boolean {
        return !validateEstimatedValue(this.props.property.estimatedValue, this.props.setError);
    }

    private validateCurrentDebt(): boolean {
        return !validateCurrentDebt(this.props.property.currentDebt, this.props.setError);
    }

    private validateState(): boolean {
        if (this.props.property.postcode && this.props.property.postcode.length > 0) {
            validatePostcode(this.props.property.postcode, this.props.property.state, this.props.setError);
        }

        return !validateState(this.props.property.state, this.props.setError);
    }

    private validateSuburb(): boolean {
        return !validateSuburb(this.props.property.suburb, this.props.setError);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        borrowErrors: authRegisterErrorsSelector(state),
        property: propertySelector(state, ownProps.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        remove: () => dispatch(propertyDeleteAction(ownProps.uuid)),
        setError: (key: keyof IDealPropertyErrors, value: string) => dispatch(propertyErrorSetAction(ownProps.uuid, key, value)),
        setValue: (key: keyof IDealProperty, value: string) => dispatch(propertyValueSetAction(ownProps.uuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Property);
