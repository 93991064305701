import { fetchPostUtility, fetchUtility } from '~utilities/fetch';

export async function investmentGetRequest(uuid: string): Promise<Response> {
    return fetchUtility(`/investments/${uuid}`);
}

export async function investmentInvestRequest(uuid: string, investorAccountUuid: string, amount: number): Promise<Response> {
    return fetchPostUtility(`/investments/${uuid}/invest`, {
        amount,
        investorAccountUuid,
    });
}

export async function investmentsAvailableListRequest(): Promise<Response> {
    return fetchUtility('/investments/available');
}

export async function investmentsRecentlyFundedListRequest(): Promise<Response> {
    return fetchUtility('/investments/recently-funded');
}
