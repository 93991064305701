import React from 'react';
import { Button, Container, Form, InputGroup, Spinner } from 'react-bootstrap';
import { FaLock } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { authLoginAction, authLoginErrorSetAction } from '~Auth/actions';
import {
    authLoginErrorsSelector,
    authLoginInProgressSelector,
} from '~Auth/selectors';
import { brokerEmailSelector } from '~Broker/selectors';
import { IGlobalState } from '~reducer';
import './broker.less';
import Layout from './Layout';

interface IState {
    password: string;
}

interface IPropsSelector {
    brokerEmail: string;
    inProgress: boolean;
    loginErrors: any;
}

interface IPropsDispatch {
    login: (email: string, password: string) => void;
    setError: (key: string, value: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class LoginPassword extends React.Component<Props, IState> {
    public state: IState = {
        password: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangePassword = this.onChangePassword.bind(this);
        this.onClickLogin = this.onClickLogin.bind(this);

        this.validatePassword = this.validatePassword.bind(this);
    }

    public render(): JSX.Element {
        const { loginErrors, inProgress } = this.props;
        const { password } = this.state;

        return (
            <Layout>
                <div className='broker'>
                    <Container className='login'>
                        <h3>Broker Login</h3>
                        <Form>
                            <Form.Group>
                                <InputGroup>
                                    <InputGroup.Prepend><FaLock/></InputGroup.Prepend>
                                    <Form.Control
                                        isInvalid={!!loginErrors.password || !!loginErrors.email}
                                        name='password'
                                        onBlur={this.validatePassword}
                                        onChange={this.onChangePassword}
                                        placeholder='Password'
                                        type='password'
                                        value={password || ''}
                                    />
                                    {!!loginErrors.password && <Form.Control.Feedback type='invalid'>{loginErrors.password}</Form.Control.Feedback>}
                                    {!!loginErrors.email && <Form.Control.Feedback type='invalid'>{loginErrors.email}</Form.Control.Feedback>}
                                </InputGroup>
                            </Form.Group>
                            <Link className='forgot-password' to='/forgot-password'>I forgot my password</Link>
                            <Button className='continue' disabled={inProgress} onClick={this.onClickLogin} variant='primary'>
                                {inProgress && <Spinner animation='border' as='span' role='status' size='sm' />}
                                {' '}Login
                            </Button>
                        </Form>
                    </Container>
                </div>
            </Layout>
        );
    }

    private onChangePassword(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            password: event.target.value,
        });
    }

    private onClickLogin() {
        const { brokerEmail } = this.props;
        const { password } = this.state;

        let valid = true;
        valid = this.validatePassword() && valid;

        if (!valid) {
            return;
        }

        this.props.login(brokerEmail, password);
    }

    private validatePassword(): boolean {
        const { password } = this.state;

        let error: string;

        if (!password || password.length === 0) {
            error = 'Please enter a password';
        }

        this.props.setError('password', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        brokerEmail: brokerEmailSelector(state),
        inProgress: authLoginInProgressSelector(state),
        loginErrors: authLoginErrorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        login: (email: string, password: string) => dispatch(authLoginAction(email, password)),
        setError: (key: string, value: string) => dispatch(authLoginErrorSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginPassword);
