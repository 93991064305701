import _ from 'lodash';
import BorrowerTypeEnum from '~Api/Application/BorrowerTypeEnum';
import GenderEnum from '~Api/Application/GenderEnum';
import IApplicationBorrower from '~Api/Application/IApplicationBorrower';
import IApplicationBorrowerErrors from '~Api/Application/IApplicationBorrowerErrors';
import IdTypeEnum from '~Api/Application/IdTypeEnum';
import IncomeTypeEnum from '~Api/Application/IncomeTypeEnum';
import LicenceStateEnum from '~Api/Application/LicenceStateEnum';
import MaritalStatusEnum from '~Api/Application/MaritalStatusEnum';
import { validateEmail } from '~validators';

export function validateBorrower(borrower: IApplicationBorrower, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): boolean {
    let valid = true;

    switch (borrower.type) {
        case BorrowerTypeEnum.Company:
            valid = !validateBorrowerAbn(borrower.abn, setError) && valid;
            valid = !validateBorrowerBusinessName(borrower.businessName, setError) && valid;
            valid = !validateBorrowerMonthlyGrossExpenses(borrower.monthlyGrossExpenses, setError) && valid;
            valid = !validateBorrowerMonthlyGrossTurnover(borrower.monthlyGrossTurnover, setError) && valid;
            break;
        case BorrowerTypeEnum.Individual:
            valid = !validateBorrowerAddress(borrower.address, setError) && valid;
            valid = !validateBorrowerDependentNumber(borrower.dependentNumber, setError) && valid;
            valid = !validateBorrowerDob(borrower.dob, setError) && valid;
            valid = !validateBorrowerEmail(borrower.email, setError) && valid;
            valid = !validateBorrowerFirstName(borrower.firstName, setError) && valid;
            valid = !validateBorrowerGender(borrower.gender, setError) && valid;
            valid = !validateBorrowerIdType(borrower.idType, setError) && valid;
            valid = !validateBorrowerIncomeType(borrower.incomeType, setError) && valid;
            valid = !validateBorrowerLastName(borrower.lastName, setError) && valid;
            valid = !validateBorrowerMaritalStatus(borrower.maritalStatus, setError) && valid;
            valid = !validateBorrowerPhone(borrower.phone, setError) && valid;
            valid = !validateBorrowerYearlyIncome(borrower.yearlyIncome, setError) && valid;

            switch (borrower.idType) {
                case IdTypeEnum.DriverLicence:
                    valid = !validateBorrowerLicenceNumber(borrower.licenceNumber, setError) && valid;
                    valid = !validateBorrowerLicenceState(borrower.licenceState, setError) && valid;
                    break;
                case IdTypeEnum.Passport:
                    valid = !validateBorrowerPassportCountry(borrower.passportCountry, setError) && valid;
                    valid = !validateBorrowerPassportNumber(borrower.passportNumber, setError) && valid;
                    break;
            }

            switch (borrower.incomeType) {
                case IncomeTypeEnum.Other:
                    valid = !validateBorrowerOtherIncomeType(borrower.otherIncomeType, setError) && valid;
                    break;
                case IncomeTypeEnum.PaygFullTime:
                case IncomeTypeEnum.PaygPartTime:
                    valid = !validateBorrowerJobTitle(borrower.jobTitle, setError) && valid;
                    break;
                case IncomeTypeEnum.SelfEmployed:
                    valid = !validateBorrowerAbn(borrower.abn, setError) && valid;
                    valid = !validateBorrowerBusinessName(borrower.businessName, setError) && valid;
                    break;
            }

            break;
        case BorrowerTypeEnum.Trust:
            valid = !validateBorrowerAbn(borrower.abn, setError) && valid;
            valid = !validateBorrowerTrustName(borrower.trustName, setError) && valid;
            valid = !validateBorrowerTrusteeName(borrower.trusteeName, setError) && valid;
            break;
    }

    return valid;
}

export function validateBorrowerAbn(abn: string, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!abn || abn.length === 0) {
        error = 'Please enter the ABN';
    }

    setError('abn', error);

    return error;
}

export function validateBorrowerAddress(address: string, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!address || address.length === 0) {
        error = 'Please enter the address';
    }

    setError('address', error);

    return error;
}

export function validateBorrowerBusinessName(businessName: string, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!businessName || businessName.length === 0) {
        error = 'Please enter the business name';
    }

    setError('businessName', error);

    return error;
}

export function validateBorrowerDependentNumber(dependentNumber: number, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (dependentNumber === null) {
        error = 'Please enter the number of dependents';
    }

    setError('dependentNumber', error);

    return error;
}

export function validateBorrowerDob(dob: string, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!dob || dob.length === 0) {
        error = 'Please enter the date of birth';
    }

    setError('dob', error);

    return error;
}

export function validateBorrowerEmail(email: string, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (email && email.length > 0 && !validateEmail(email)) {
        error = 'Please enter a valid email address';
    }

    setError('email', error);

    return error;
}

export function validateBorrowerFirstName(firstName: string, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!firstName || firstName.length === 0) {
        error = 'Please enter the given name';
    }

    setError('firstName', error);

    return error;
}

export function validateBorrowerGender(gender: GenderEnum, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!_.values(GenderEnum).includes(gender)) {
        error = 'Please choose the gender';
    }

    setError('gender', error);

    return error;
}

export function validateBorrowerIdType(idType: IdTypeEnum, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!_.values(IdTypeEnum).includes(idType)) {
        error = 'Please choose the ID Type';
    }

    setError('idType', error);

    return error;
}

export function validateBorrowerIncomeType(incomeType: IncomeTypeEnum, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!incomeType) {
        error = 'Please choose the income type';
    }

    setError('incomeType', error);

    return error;
}

export function validateBorrowerJobTitle(jobTitle: string, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!jobTitle || jobTitle.length === 0) {
        error = 'Please enter the job title';
    }

    setError('jobTitle', error);

    return error;
}

export function validateBorrowerLastName(lastName: string, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!lastName || lastName.length === 0) {
        error = 'Please enter the surname';
    }

    setError('lastName', error);

    return error;
}

export function validateBorrowerLicenceNumber(licenceNumber: string, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!licenceNumber || licenceNumber.length === 0) {
        error = 'Please enter the licence number';
    }

    setError('licenceNumber', error);

    return error;
}

export function validateBorrowerLicenceState(licenceState: LicenceStateEnum, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!_.values(LicenceStateEnum).includes(licenceState)) {
        error = 'Please choose the Licence State';
    }

    setError('licenceState', error);

    return error;
}

export function validateBorrowerMaritalStatus(maritalStatus: MaritalStatusEnum, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!maritalStatus) {
        error = 'Please choose the marital status';
    }

    setError('maritalStatus', error);

    return error;
}

export function validateBorrowerMiddleName(middleName: string, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    // No validators

    return null;
}

export function validateBorrowerMonthlyGrossExpenses(monthlyGrossExpenses: number, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!monthlyGrossExpenses || monthlyGrossExpenses === 0) {
        error = 'Please enter the monthly gross expenses';
    }

    setError('monthlyGrossExpenses', error);

    return error;
}

export function validateBorrowerMonthlyGrossTurnover(monthlyGrossTurnover: number, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!monthlyGrossTurnover || monthlyGrossTurnover === 0) {
        error = 'Please enter the monthly gross turnover';
    }

    setError('monthlyGrossTurnover', error);

    return error;
}

export function validateBorrowerOtherIncomeType(otherIncomeType: string, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!otherIncomeType || otherIncomeType.length === 0) {
        error = 'Please enter the other income details';
    }

    setError('otherIncomeType', error);

    return error;
}

export function validateBorrowerPassportCountry(passportCountry: string, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!passportCountry || passportCountry.length === 0) {
        error = 'Please enter the passport country';
    }

    setError('passportCountry', error);

    return error;
}

export function validateBorrowerPassportNumber(passportNumber: string, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!passportNumber || passportNumber.length === 0) {
        error = 'Please enter the passport number';
    }

    setError('passportNumber', error);

    return error;
}

export function validateBorrowerPhone(phone: string, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    // No validators

    return null;
}

export function validateBorrowerTrustName(trustName: string, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!trustName || trustName.length === 0) {
        error = 'Please enter the trust name';
    }

    setError('trustName', error);

    return error;
}

export function validateBorrowerTrusteeName(trusteeName: string, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!trusteeName || trusteeName.length === 0) {
        error = 'Please enter the trustee name';
    }

    setError('trusteeName', error);

    return error;
}

export function validateBorrowerYearlyIncome(yearlyIncome: number, setError: (key: keyof IApplicationBorrowerErrors, value: string) => void): string {
    let error: string = null;

    if (!yearlyIncome || yearlyIncome === 0) {
        error = 'Please enter the yearly income';
    }

    setError('yearlyIncome', error);

    return error;
}
