import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { Button, Form, Spinner, Table } from 'react-bootstrap';
import { FiSearch, FiX } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import StatusEnum from '~Api/Investment/StatusEnum';
import IShare from '~Api/Investor/IShare';
import {
    currentInvestorGetAction,
    sharesListAction,
} from '~Investor/actions';
import {
    currentInvestorAccountSelector,
    currentInvestorSelector,
    sharesSelector,
} from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import Pagination from '~UI/Pagination';
import SortableHeader, { SortOrderEnum } from '~UI/SortableHeader';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import './portfolio.less';

const statusLabels: IDictionary<string> = {
    [StatusEnum.Active]: 'Active',
    [StatusEnum.GracePeriod]: 'Grace Period',
    [StatusEnum.Repaid]: 'Repaid',
};

enum StatusFilterEnum {
    All = 'All',
    Active = 'Active',
    GracePeriod = 'GracePeriod',
    Repaid = 'Repaid',
}

const statusFilterMap: IDictionary<StatusEnum> = {
    [StatusFilterEnum.Active]: StatusEnum.Active,
    [StatusFilterEnum.GracePeriod]: StatusEnum.GracePeriod,
    [StatusFilterEnum.Repaid]: StatusEnum.Repaid,
};

enum SortFieldEnum {
    AmountInvested = 'AmountInvested',
    AmountRemaining = 'AmountRemaining',
    EndDate = 'EndDate',
    InterestEarned = 'InterestEarned',
    InterestRate = 'InterestRate',
    InvestmentName = 'InvestmentName',
    LoanName = 'LoanName',
    StartDate = 'StartDate',
    Status = 'Status',
}

interface IState {
    currentPage: number;
    perPage: number;
    search: string;
    sortField: SortFieldEnum;
    sortOrder: SortOrderEnum;
    statusFilter: StatusFilterEnum;
}

interface IPropsSelector {
    currentInvestor: IInvestor;
    marketplaceAccount: IAccount;
    shares: IShare[];
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
    sharesList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Portfolio extends React.Component<Props, IState> {
    public state: IState = {
        currentPage: 1,
        perPage: 10,
        search: null,
        sortField: SortFieldEnum.StartDate,
        sortOrder: SortOrderEnum.Ascending,
        statusFilter: StatusFilterEnum.All,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onClickClearSearch = this.onClickClearSearch.bind(this);
        this.onClickPage = this.onClickPage.bind(this);
        this.onChangeStatusFilter = this.onChangeStatusFilter.bind(this);
        this.onChangePerPage = this.onChangePerPage.bind(this);
        this.onClickSort = this.onClickSort.bind(this);
        this.onClickSortByInvestmentName = this.onClickSortByInvestmentName.bind(this);
        this.onClickSortByLoanName = this.onClickSortByLoanName.bind(this);
        this.onClickSortByInterestRate = this.onClickSortByInterestRate.bind(this);
        this.onClickSortByAmountInvested = this.onClickSortByAmountInvested.bind(this);
        this.onClickSortByAmountRemaining = this.onClickSortByAmountRemaining.bind(this);
        this.onClickSortByInterestEarned = this.onClickSortByInterestEarned.bind(this);
        this.onClickSortByEndDate = this.onClickSortByEndDate.bind(this);
        this.onClickSortByStartDate = this.onClickSortByStartDate.bind(this);
        this.onClickSortByStatus = this.onClickSortByStatus.bind(this);
    }

    public componentDidMount(): void {
        const { currentInvestor, shares } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }

        if (!shares) {
            this.props.sharesList();
        }
    }

    public render(): JSX.Element {
        const { currentInvestor, marketplaceAccount, shares } = this.props;
        const { currentPage, perPage, search, sortField, sortOrder, statusFilter } = this.state;

        if (!currentInvestor || !shares) {
            return (
                <Layout section='investment-portfolio'>
                    <h1>My Portfolio</h1>
                    <div className='content-box'>
                        <Spinner animation='border' />
                    </div>
                </Layout>
            );
        }

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const possibleStatuses: StatusEnum[] = _.uniq(_.map(shares, 'investmentStatus'));

        let filteredShares: IShare[] = _.filter(shares, (share: IShare) => {
            if (search && search.trim().length > 0 && !`${share.loanName}`.toLowerCase().includes(search.toLowerCase())) {
                return false;
            }

            if (statusFilter === StatusFilterEnum.All) {
                return true;
            }

            if (statusFilterMap[statusFilter]) {
                return share.investmentStatus === statusFilterMap[statusFilter];
            }

            return false;
        });

        filteredShares = _.sortBy(filteredShares, [(share: IShare) => {
            switch (sortField) {
                case SortFieldEnum.AmountInvested:
                    return share.amountInvested;
                case SortFieldEnum.AmountRemaining:
                    return share.amountRemaining;
                case SortFieldEnum.EndDate:
                    return share.endDate;
                case SortFieldEnum.InterestEarned:
                    return share.interestEarned;
                case SortFieldEnum.InterestRate:
                    return share.interestRate;
                case SortFieldEnum.InvestmentName:
                    return share.investmentName;
                case SortFieldEnum.LoanName:
                    return share.loanName;
                case SortFieldEnum.StartDate:
                    return share.startDate;
                case SortFieldEnum.Status:
                    return share.investmentStatus;
            }
        }]);

        if (sortOrder === SortOrderEnum.Descending) {
            filteredShares = _.reverse(filteredShares);
        }

        const offset: number = (currentPage - 1) * perPage;
        const pageShares: IShare[] = _.slice(filteredShares, offset, offset + perPage);

        const sharesBlock: JSX.Element[] = pageShares.map((share: IShare) => {
            return (
                <tr key={share.uuid}>
                    <td className='date'>{moment(share.startDate).format('D MMM YYYY')}</td>
                    <td className='loan-number'><Link to={`/marketplace/investment/${share.investmentUuid}`}>{share.loanName}</Link></td>
                    <td className='status'>{statusLabels[share.investmentStatus]}</td>
                    <td className='invested'>{numeral(share.amountRemaining).format('$0,0[.]00')}</td>
                    <td className='rate'>{percentageFormatter.format(share.interestRate / 100)}</td>
                    <td className='interest-earned'>{numeral(share.interestEarned).format('$0,0[.]00')}</td>
                    <td className='expiration'>{moment(share.endDate).fromNow()}</td>
                </tr>
            );
        });

        const myLoansBlock: JSX.Element = shares.length > 0 && (
            <React.Fragment>
                <Form.Group className='search'>
                    <FiSearch className='magnify' />
                    <Button className='clear' onClick={this.onClickClearSearch}><FiX /></Button>
                    <Form.Control onChange={this.onChangeSearch} placeholder='Search' value={search || ''} />
                </Form.Group>
                <Form.Group className='status-filter'>
                    <Form.Control as='select' onChange={this.onChangeStatusFilter} value={statusFilter}>
                        <option value={StatusFilterEnum.All}>All</option>
                        {possibleStatuses.includes(StatusEnum.Active) && <option value={StatusFilterEnum.Active}>{statusLabels[StatusEnum.Active]}</option>}
                        {possibleStatuses.includes(StatusEnum.GracePeriod) && <option value={StatusFilterEnum.GracePeriod}>{statusLabels[StatusEnum.GracePeriod]}</option>}
                        {possibleStatuses.includes(StatusEnum.Repaid) && <option value={StatusFilterEnum.Repaid}>{statusLabels[StatusEnum.Repaid]}</option>}
                    </Form.Control>
                </Form.Group>
                <Table
                    className='my-loans'
                    bordered={true}
                >
                    <thead>
                        <tr>
                            <SortableHeader
                                className='date'
                                field={SortFieldEnum.StartDate}
                                label='Start Date'
                                onClick={this.onClickSortByStartDate}
                                sortField={sortField}
                                sortOrder={sortOrder}
                            />
                            <SortableHeader
                                className='loan-number'
                                field={SortFieldEnum.LoanName}
                                label='Loan ID'
                                onClick={this.onClickSortByLoanName}
                                sortField={sortField}
                                sortOrder={sortOrder}
                            />
                            <SortableHeader
                                className='status'
                                field={SortFieldEnum.Status}
                                label='Status'
                                onClick={this.onClickSortByStatus}
                                sortField={sortField}
                                sortOrder={sortOrder}
                            />
                            <SortableHeader
                                className='invested'
                                field={SortFieldEnum.AmountInvested}
                                label='Investment'
                                onClick={this.onClickSortByAmountInvested}
                                sortField={sortField}
                                sortOrder={sortOrder}
                            />
                            <SortableHeader
                                className='rate'
                                field={SortFieldEnum.InterestRate}
                                label='Target Return'
                                onClick={this.onClickSortByInterestRate}
                                sortField={sortField}
                                sortOrder={sortOrder}
                            />
                            <SortableHeader
                                className='interest-earned'
                                field={SortFieldEnum.InterestEarned}
                                label='Interest Earned'
                                onClick={this.onClickSortByInterestEarned}
                                sortField={sortField}
                                sortOrder={sortOrder}
                            />
                            <SortableHeader
                                className='expiration'
                                field={SortFieldEnum.EndDate}
                                label='Time Remaining'
                                onClick={this.onClickSortByEndDate}
                                sortField={sortField}
                                sortOrder={sortOrder}
                            />
                        </tr>
                    </thead>
                    <tbody>
                        {sharesBlock}
                    </tbody>
                </Table>
                <div className='pagination-block'>
                    <Pagination
                        currentPage={currentPage}
                        itemCount={filteredShares.length}
                        onChangePerPage={this.onChangePerPage}
                        onClickPage={this.onClickPage}
                        perPage={perPage}
                    />
                </div>
            </React.Fragment>
        );

        return (
            <Layout section='investment-portfolio'>
                <h1>My Portfolio</h1>
                <div className='content-box'>
                    <div className='box cash-balance'>
                        <div className='primary'>
                            <div className='label'>Cash Balance</div>
                            <div className='value'>{numeral(marketplaceAccount.balance).format('$0,0[.]00')}</div>
                        </div>
                        <div className='secondary'>
                            <div className='label'>Total Portfolio</div>
                            <div className='value'>{numeral(marketplaceAccount.balance + marketplaceAccount.investedAmountCurrent).format('$0,0[.]00')}</div>
                        </div>
                    </div>
                    <div className='box principal-invested'>
                        <div className='primary'>
                            <div className='label'>Principal Invested</div>
                            <div className='value'>{numeral(marketplaceAccount.investedAmountCurrent).format('$0,0[.]00')}</div>
                        </div>
                        <div className='secondary'>
                            <div className='label'>Pending Top Up Cash</div>
                            <div className='value'>{numeral(marketplaceAccount.depositAmountPending).format('$0,0[.]00')}</div>
                        </div>
                    </div>
                    <div className='box total-income'>
                        <div className='primary'>
                            <div className='label'>Total Income</div>
                            <div className='value'>{numeral(marketplaceAccount.interestAmountEarned).format('$0,0[.]00')}</div>
                        </div>
                        <div className='secondary'>
                            <div className='label'>Pending Withdrawal</div>
                            <div className='value'>{numeral(marketplaceAccount.withdrawalAmountPending).format('$0,0[.]00')}</div>
                        </div>
                    </div>
                    {myLoansBlock}
                </div>
            </Layout>
        );
    }

    private onChangeSearch(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            search: event.target.value,
        });
    }

    private onClickClearSearch(): void {
        this.setState({
            search: null,
        });
    }

    private onClickPage(page: number): void {
        this.setState({
            currentPage: page,
        });
    }

    private onChangeStatusFilter(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            statusFilter: event.target.value as StatusFilterEnum,
        });
    }

    private onChangePerPage(perPage: number): void {
        this.setState({
            currentPage: 1,
            perPage,
        });
    }

    private onClickSort(field: SortFieldEnum): void {
        const { sortField, sortOrder } = this.state;

        if (sortField === field) {
            this.setState({
                sortOrder: sortOrder === SortOrderEnum.Ascending ? SortOrderEnum.Descending : SortOrderEnum.Ascending,
            });
        } else {
            this.setState({
                sortField: field,
                sortOrder: SortOrderEnum.Ascending,
            });
        }
    }

    private onClickSortByInvestmentName(): void {
        this.onClickSort(SortFieldEnum.InvestmentName);
    }

    private onClickSortByLoanName(): void {
        this.onClickSort(SortFieldEnum.LoanName);
    }

    private onClickSortByStatus(): void {
        this.onClickSort(SortFieldEnum.Status);
    }

    private onClickSortByInterestRate(): void {
        this.onClickSort(SortFieldEnum.InterestRate);
    }

    private onClickSortByAmountInvested(): void {
        this.onClickSort(SortFieldEnum.AmountInvested);
    }

    private onClickSortByAmountRemaining(): void {
        this.onClickSort(SortFieldEnum.AmountRemaining);
    }

    private onClickSortByInterestEarned(): void {
        this.onClickSort(SortFieldEnum.InterestEarned);
    }

    private onClickSortByEndDate(): void {
        this.onClickSort(SortFieldEnum.EndDate);
    }

    private onClickSortByStartDate(): void {
        this.onClickSort(SortFieldEnum.StartDate);
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        marketplaceAccount: currentInvestorAccountSelector(state, AccountTypeEnum.Marketplace),
        shares: sharesSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
        sharesList: () => dispatch(sharesListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Portfolio);
