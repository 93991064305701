import { AnyAction } from 'redux';
import {
    IAuthLoginErrorSetAction,
    IAuthLoginErrorsSetAction,
    IAuthLoginInProgressSetAction,
    IAuthMagicLinkCreateErrorSetAction,
    IAuthMagicLinkCreateErrorsSetAction,
    IAuthMagicLinkCreateInProgressSetAction,
    IAuthMagicLinkRedeemErrorSetAction,
    IAuthRegisterErrorSetAction,
    IAuthRegisterErrorsSetAction,
    IAuthRegisterInProgressSetAction,
    IAuthRegisterMarketplaceQualifierAnswerSetAction,
    IAuthTokenSetAction,
} from './actions';
import AuthActionsEnum from './ActionsEnum';

export interface IAuthState {
    loginErrors: any;
    loginInProgress: boolean;
    magicLinkCreateErrors: any;
    magicLinkCreateInProgress: boolean;
    magicLinkRedeemError: string;
    registerErrors: any;
    registerInProgress: boolean;
    registerMarketplaceQualifierAnswers: { [key in number]: number };
    token: any;
}

const initialData: IAuthState = {
    loginErrors: {},
    loginInProgress: false,
    magicLinkCreateErrors: {},
    magicLinkCreateInProgress: false,
    magicLinkRedeemError: null,
    registerErrors: {},
    registerInProgress: false,
    registerMarketplaceQualifierAnswers: {},
    token: null,
};

export function reducer(state: IAuthState = initialData, action: AnyAction): IAuthState {
    switch (action.type) {
        case AuthActionsEnum.LoginErrorsSet: {
            const typedAction: IAuthLoginErrorsSetAction = action as IAuthLoginErrorsSetAction;

            return {
                ...state,
                loginErrors: typedAction.errors,
            };
        }

        case AuthActionsEnum.LoginErrorSet: {
            const typedAction: IAuthLoginErrorSetAction = action as IAuthLoginErrorSetAction;

            return {
                ...state,
                loginErrors: {
                    ...state.loginErrors,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        case AuthActionsEnum.LoginInProgressSet: {
            const typedAction: IAuthLoginInProgressSetAction = action as IAuthLoginInProgressSetAction;

            return {
                ...state,
                loginInProgress: typedAction.inProgress,
            };
        }

        case AuthActionsEnum.MagicLinkCreateErrorsSet: {
            const typedAction: IAuthMagicLinkCreateErrorsSetAction = action as IAuthMagicLinkCreateErrorsSetAction;

            return {
                ...state,
                magicLinkCreateErrors: typedAction.errors,
            };
        }

        case AuthActionsEnum.MagicLinkCreateErrorSet: {
            const typedAction: IAuthMagicLinkCreateErrorSetAction = action as IAuthMagicLinkCreateErrorSetAction;

            return {
                ...state,
                magicLinkCreateErrors: {
                    ...state.magicLinkCreateErrors,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        case AuthActionsEnum.MagicLinkCreateInProgressSet: {
            const typedAction: IAuthMagicLinkCreateInProgressSetAction = action as IAuthMagicLinkCreateInProgressSetAction;

            return {
                ...state,
                magicLinkCreateInProgress: typedAction.inProgress,
            };
        }

        case AuthActionsEnum.MagicLinkRedeemErrorSet: {
            const typedAction: IAuthMagicLinkRedeemErrorSetAction = action as IAuthMagicLinkRedeemErrorSetAction;

            return {
                ...state,
                magicLinkRedeemError: typedAction.error,
            };
        }

        case AuthActionsEnum.RegisterErrorsSet: {
            const typedAction: IAuthRegisterErrorsSetAction = action as IAuthRegisterErrorsSetAction;

            return {
                ...state,
                registerErrors: typedAction.errors,
            };
        }

        case AuthActionsEnum.RegisterErrorSet: {
            const typedAction: IAuthRegisterErrorSetAction = action as IAuthRegisterErrorSetAction;

            return {
                ...state,
                registerErrors: {
                    ...state.registerErrors,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        case AuthActionsEnum.RegisterInProgressSet: {
            const typedAction: IAuthRegisterInProgressSetAction = action as IAuthRegisterInProgressSetAction;

            return {
                ...state,
                registerInProgress: typedAction.inProgress,
            };
        }

        case AuthActionsEnum.RegisterMarketplaceQualifierAnswerSet: {
            const typedAction: IAuthRegisterMarketplaceQualifierAnswerSetAction = action as IAuthRegisterMarketplaceQualifierAnswerSetAction;

            return {
                ...state,
                registerMarketplaceQualifierAnswers: {
                    ...state.registerMarketplaceQualifierAnswers,
                    [typedAction.questionIndex]: typedAction.questionAnswerIndex,
                },
            };
        }

        case AuthActionsEnum.TokenSet: {
            const typedAction: IAuthTokenSetAction = action as IAuthTokenSetAction;

            return {
                ...state,
                token: typedAction.token,
            };
        }

        default:
            return state;
    }
}
