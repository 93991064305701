import IBroker from '~Api/Broker/IBroker';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

export function brokerAddressUpdateModalShowSelector(state: IGlobalState): boolean {
    return state.broker.addressUpdateModalShow;
}

export function brokerEmailSelector(state: IGlobalState): string {
    return state.broker.brokerEmail;
}

export function brokerInProgressSelector(state: IGlobalState): boolean {
    return state.broker.inProgress;
}

export function brokerLoginErrorsSelector(state: IGlobalState): IDictionary<string> {
    return state.broker.loginErrors;
}

export function currentBrokerSelector(state: IGlobalState): IBroker {
    return state.broker.currentBroker;
}
