import { fetchDeleteUtility, fetchGetUtility, fetchPostFileUtility, fetchPostUtility, fetchPutUtility } from '~utilities/fetch';
import IDeal from './IDeal';
import IDealProperty from './IDealProperty';
import {
    transformDeal,
    transformProperty,
} from './transformers';

export async function dealAddRequest(deal: IDeal): Promise<Response> {
    return fetchPostUtility('/deals', transformDeal(deal));
}

export async function dealCompleteRequest(deal: IDeal, autoQuote: boolean): Promise<Response> {
    return fetchPostUtility(`/deals/${deal.uuid}/complete`, {
        autoQuote,
    });
}

export async function dealFileAddRequest(deal: IDeal, file: File): Promise<Response> {
    return fetchPostFileUtility(`/deals/${deal.uuid}/files`, file);
}

export async function dealGetRequest(dealUuid: string, tokenName: string = null): Promise<Response> {
    return fetchGetUtility(`/deals/${dealUuid}`, tokenName);
}

export async function dealUpdateRequest(deal: IDeal): Promise<Response> {
    return fetchPutUtility(`/deals/${deal.uuid}`, transformDeal(deal));
}

export async function dealReferredByRequest(dealUuid: string, token: string): Promise<Response> {
    return fetchPostUtility(`/deals/${dealUuid}/referred-by`, {
        token,
    });
}

export async function dealReferralsTokenRequest(referrerCode: string): Promise<Response> {
    return fetchPostUtility(`/deal-referrals/token`, { referrerCode });
}

export async function dealPropertiesAddRequest(deal: IDeal, property: IDealProperty, tokenName: string = null): Promise<Response> {
    return fetchPostUtility(`/deals/${deal.uuid}/properties`, transformProperty(property), tokenName);
}

export async function dealPropertyDeleteRequest(propertyUuid: string, tokenName: string = null): Promise<Response> {
    return fetchDeleteUtility(`/deal-properties/${propertyUuid}`, tokenName);
}

export async function dealPropertyUpdateRequest(property: IDealProperty, tokenName: string = null): Promise<Response> {
    return fetchPutUtility(`/deal-properties/${property.uuid}`, transformProperty(property), tokenName);
}

export async function dealQuoteCreateApplicationRequest(code: string): Promise<Response> {
    return fetchPostUtility(`/deal-quotes/${code}/create-application`);
}

export async function dealQuotesListRequest(dealUuid: string): Promise<Response> {
    return fetchGetUtility(`/deals/${dealUuid}/quotes`);
}

export async function dealsListRequest(): Promise<Response> {
    return fetchGetUtility('/deals');
}
