import React from 'react';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from './Layout';

class ForgotPasswordSent extends React.Component {
    public render(): JSX.Element {
        return (
            <Layout section='forgot-password-sent'>
                <Alert variant='success'>Please check your email for a login link.</Alert>
                <Link className='login' to='/'>Log In</Link>
            </Layout>
        );
    }
}

export default ForgotPasswordSent;
