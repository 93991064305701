import _ from 'lodash';
import React from 'react';
import {
    Button,
    Container,
    Form,
    Spinner,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { IDeal } from '~Api/Deal';
import { IDealErrors } from '~Api/Deal/IDeal';
import { IDealPropertyErrors } from '~Api/Deal/IDealProperty';
import LoanPurposeEnum from '~Api/Deal/LoanPurposeEnum';
import { savingSelector } from '~Lead/selectors';
import { IGlobalState } from '~reducer';
import Checkbox from '~UI/Checkbox';
import { validateEmail } from '~validators';
import { PartnerReferralDealAddAction } from './actions';
import Layout from './Layout';

interface IState {
    comments?: string;
    customerConsent: boolean;
    email: string;
    errors: IDealErrors & IDealPropertyErrors;
    firstName: string;
    lastName: string;
    loanPurpose: LoanPurposeEnum;
    phone: string;
    referralPartnerCompanyName: string;
    referralPartnerEmail: string;
    referralPartnerFirstName: string;
    referralPartnerLastName: string;
    referralPartnerPhone: string;
}

interface IPropsSelector {
    saving: boolean;
}

interface IPropsDispatch {
    dealAdd: (lead: IDeal) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Referral extends React.Component<Props, IState> {
    public state: IState = {
        comments: null,
        customerConsent: false,
        email: null,
        errors: {},
        firstName: null,
        lastName: null,
        loanPurpose: null,
        phone: null,
        referralPartnerCompanyName: null,
        referralPartnerEmail: null,
        referralPartnerFirstName: null,
        referralPartnerLastName: null,
        referralPartnerPhone: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickSubmit = this.onClickSubmit.bind(this);

        this.onChangeReferralPartnerCompanyName = this.onChangeReferralPartnerCompanyName.bind(this);
        this.onChangeReferralPartnerEmail = this.onChangeReferralPartnerEmail.bind(this);
        this.onChangeReferralPartnerFirstName = this.onChangeReferralPartnerFirstName.bind(this);
        this.onChangeReferralPartnerLastName = this.onChangeReferralPartnerLastName.bind(this);
        this.onChangeReferralPartnerPhone = this.onChangeReferralPartnerPhone.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeLoanPurpose = this.onChangeLoanPurpose.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeCustomerConsent = this.onChangeCustomerConsent.bind(this);
        this.onChangeComments = this.onChangeComments.bind(this);

        this.validateReferralPartnerEmail = this.validateReferralPartnerEmail.bind(this);
        this.validateReferralPartnerFirstName = this.validateReferralPartnerFirstName.bind(this);
        this.validateReferralPartnerLastName = this.validateReferralPartnerLastName.bind(this);
        this.validateReferralPartnerPhone = this.validateReferralPartnerPhone.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validateFirstName = this.validateFirstName.bind(this);
        this.validateLastName = this.validateLastName.bind(this);
        this.validateLoanPurpose = this.validateLoanPurpose.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
        this.validateCustomerConsent = this.validateCustomerConsent.bind(this);

        this.setError = this.setError.bind(this);
    }

    public render(): JSX.Element {
        const { saving } = this.props;
        const {
            customerConsent,
            email,
            errors,
            firstName,
            lastName,
            loanPurpose,
            phone,
            referralPartnerCompanyName,
            referralPartnerEmail,
            referralPartnerFirstName,
            referralPartnerLastName,
            referralPartnerPhone,
        } = this.state;

        return (
            <Layout>
                <Container className='referral'>
                    <h3>Let’s get started and refer the Customer.</h3>
                    <p className='intro'>If you are an accredited referrer complete your details and the customers details and our team will contact the customer within 10 days (normally within 24 hours).</p>
                    <p className='intro'>If you are not an accredited Referrer. Complete your Accreditation <Link to='/partner/register'>here.</Link></p>
                    <Form>
                        <h4>Referral Partner Details</h4>
                        <Form.Group className='referral-partner-first-name'>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control isInvalid={!!errors.referralPartnerFirstName} onBlur={this.validateReferralPartnerFirstName} onChange={this.onChangeReferralPartnerFirstName} type='text' value={referralPartnerFirstName || ''} />
                            {errors.referralPartnerFirstName && <Form.Control.Feedback type='invalid'>{errors.referralPartnerFirstName}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='referral-partner-last-name'>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control isInvalid={!!errors.referralPartnerLastName} onBlur={this.validateReferralPartnerLastName} onChange={this.onChangeReferralPartnerLastName} type='text' value={referralPartnerLastName || ''} />
                            {errors.referralPartnerLastName && <Form.Control.Feedback type='invalid'>{errors.referralPartnerLastName}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='referral-partner-phone'>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control isInvalid={!!errors.referralPartnerPhone} onBlur={this.validateReferralPartnerPhone} onChange={this.onChangeReferralPartnerPhone} type='text' value={referralPartnerPhone || ''} />
                            {errors.referralPartnerPhone && <Form.Control.Feedback type='invalid'>{errors.referralPartnerPhone}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='referral-partner-email'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control isInvalid={!!errors.referralPartnerEmail} onBlur={this.validateReferralPartnerEmail} onChange={this.onChangeReferralPartnerEmail} type='email' value={referralPartnerEmail || ''} />
                            {errors.referralPartnerEmail && <Form.Control.Feedback type='invalid'>{errors.referralPartnerEmail}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='referral-partner-company-name'>
                            <Form.Label>Company</Form.Label>
                            <Form.Control isInvalid={!!errors.referralPartnerCompanyName} onChange={this.onChangeReferralPartnerCompanyName} type='text' value={referralPartnerCompanyName || ''} />
                            {errors.referralPartnerCompanyName && <Form.Control.Feedback type='invalid'>{errors.referralPartnerCompanyName}</Form.Control.Feedback>}
                        </Form.Group>
                        <h4>Customer Details</h4>
                        <Form.Group className='first-name'>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control isInvalid={!!errors.firstName} onBlur={this.validateFirstName} onChange={this.onChangeFirstName} type='text' value={firstName || ''} />
                            {errors.firstName && <Form.Control.Feedback type='invalid'>{errors.firstName}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='last-name'>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control isInvalid={!!errors.lastName} onBlur={this.validateLastName} onChange={this.onChangeLastName} type='text' value={lastName || ''} />
                            {errors.lastName && <Form.Control.Feedback type='invalid'>{errors.lastName}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='phone'>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control isInvalid={!!errors.phone} onBlur={this.validatePhone} onChange={this.onChangePhone} type='text' value={phone || ''} />
                            {errors.phone && <Form.Control.Feedback type='invalid'>{errors.phone}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='email'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control isInvalid={!!errors.email} onBlur={this.validateEmail} onChange={this.onChangeEmail} type='email' value={email || ''} />
                            {errors.email && <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='loan-purpose'>
                            <Form.Label>Loan Purpose</Form.Label>
                            <Form.Control as='select' className='custom-select' isInvalid={!!errors.loanPurpose} onBlur={this.validateLoanPurpose} onChange={this.onChangeLoanPurpose} value={loanPurpose || ''}>
                                {loanPurpose === null && <option/>}
                                <option value={LoanPurposeEnum.Refinance}>Refinance an existing property</option>
                                {/* <option value={LoanPurposeEnum.}>Debt consolidation</option> */}
                                <option value={LoanPurposeEnum.RenovateOrBuild}>Renovate or build</option>
                                <option value={LoanPurposeEnum.BusinessLoan}>Business loan</option>
                                <option value={LoanPurposeEnum.PersonalLoan}>Personal loan</option>
                                <option value={LoanPurposeEnum.BridgingLoan}>Bridging loan</option>
                            </Form.Control>
                            {errors.loanPurpose && <Form.Control.Feedback type='invalid'>{errors.loanPurpose}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='customer-consent' onBlur={this.validateCustomerConsent}>
                            <Form.Label>The client(s) give their consent to the Referrer to disclose their personal information to funding.com.au for the purpose of arranging an obligation free consultation regarding their finance needs as outlined below.</Form.Label>
                            <Checkbox
                                checked={customerConsent}
                                label={<React.Fragment>I/We agree</React.Fragment>}
                                onChange={this.onChangeCustomerConsent}
                                isInvalid={!!errors.customerConsent}
                            />
                            {!!errors.customerConsent && <Form.Control.Feedback type='invalid'>{errors.customerConsent}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='comments'>
                            <Form.Label>Comments</Form.Label>
                            <Form.Control as='textarea' rows={5} onChange={this.onChangeComments} />
                        </Form.Group>
                        <Button className='submit' disabled={saving} onClick={this.onClickSubmit} variant='primary'>
                            {saving && <Spinner animation='border' as='span' role='status' size='sm' />}
                            {!saving && 'Submit'}
                        </Button>
                    </Form>
                </Container>
            </Layout>
        );
    }

    private onClickSubmit(): void {
        let valid = true;

        valid = this.validateReferralPartnerEmail() && valid;
        valid = this.validateReferralPartnerFirstName() && valid;
        valid = this.validateReferralPartnerLastName() && valid;
        valid = this.validateReferralPartnerPhone() && valid;
        valid = this.validateEmail() && valid;
        valid = this.validateFirstName() && valid;
        valid = this.validateLastName() && valid;
        valid = this.validatePhone() && valid;
        valid = this.validateLoanPurpose() && valid;
        valid = this.validateCustomerConsent() && valid;

        if (!valid) {
            return;
        }

        const {
            referralPartnerCompanyName,
            referralPartnerEmail,
            referralPartnerFirstName,
            referralPartnerLastName,
            referralPartnerPhone,
            email,
            firstName,
            lastName,
            phone,
            loanPurpose,
            comments,
        } = this.state;

        const lead: IDeal = {
            comments,
            email,
            firstName,
            isBroker: false,
            isReferralPartner: true,
            lastName,
            loanAmount: null,
            loanPurpose,
            phone,
            referralPartnerCompanyName,
            referralPartnerEmail,
            referralPartnerFirstName,
            referralPartnerLastName,
            referralPartnerPhone,
            termMonths: null,
        };

        this.props.dealAdd(lead);
    }

    private onChangeReferralPartnerCompanyName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            referralPartnerCompanyName: event.target.value,
        });
    }

    private onChangeReferralPartnerEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            referralPartnerEmail: event.target.value,
        });
    }

    private onChangeReferralPartnerFirstName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            referralPartnerFirstName: event.target.value,
        });
    }

    private onChangeReferralPartnerLastName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            referralPartnerLastName: event.target.value,
        });
    }

    private onChangeReferralPartnerPhone(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            referralPartnerPhone: event.target.value,
        });
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            email: event.target.value,
        });
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            firstName: event.target.value,
        });
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            lastName: event.target.value,
        });
    }

    private onChangePhone(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            phone: event.target.value,
        });
    }

    private onChangeCustomerConsent(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            customerConsent: event.target.checked,
        });
    }

    private onChangeComments(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            comments: event.target.value,
        });
    }

    private onChangeLoanPurpose(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            loanPurpose: event.target.value as LoanPurposeEnum,
        });
    }

    private validateReferralPartnerEmail(): boolean {
        const { referralPartnerEmail } = this.state;

        let error: string;
        if (!referralPartnerEmail || referralPartnerEmail.length === 0) {
            error = 'Please enter an email address';
        } else if (!validateEmail(referralPartnerEmail)) {
            error = 'Please enter a valid email address';
        }

        this.setError('referralPartnerEmail', error);

        return !error;
    }

    private validateReferralPartnerFirstName(): boolean {
        const { referralPartnerFirstName } = this.state;

        let error: string;
        if (!referralPartnerFirstName || referralPartnerFirstName.length === 0) {
            error = 'Please enter a first name';
        } else if (referralPartnerFirstName.length < 2) {
            error = 'Please enter at least 2 characters';
        }

        this.setError('referralPartnerFirstName', error);

        return !error;
    }

    private validateReferralPartnerLastName(): boolean {
        const { referralPartnerLastName } = this.state;

        let error: string;
        if (!referralPartnerLastName || referralPartnerLastName.length === 0) {
            error = 'Please enter a last name';
        } else if (referralPartnerLastName.length < 2) {
            error = 'Please enter at least 2 characters';
        }

        this.setError('referralPartnerLastName', error);

        return !error;
    }

    private validateReferralPartnerPhone(): boolean {
        const { referralPartnerPhone } = this.state;

        let error: string;
        if (!referralPartnerPhone || referralPartnerPhone.length  === 0) {
            error = 'Please enter a phone number';
        }

        this.setError('referralPartnerPhone', error);

        return !error;
    }

    private validateEmail(): boolean {
        const { email } = this.state;

        let error: string;
        if (!email || email.length === 0) {
            error = 'Please enter an email address';
        } else if (!validateEmail(email)) {
            error = 'Please enter a valid email address';
        }

        this.setError('email', error);

        return !error;
    }

    private validateFirstName(): boolean {
        const { firstName } = this.state;

        let error: string;

        if (!firstName || firstName.length === 0) {
            error = 'Please enter a first name';
        } else if (firstName.length < 2) {
            error = 'Please enter at least 2 characters';
        }

        this.setError('firstName', error);

        return !error;
    }

    private validateLastName(): boolean {
        const { lastName } = this.state;

        let error: string;

        if (!lastName || lastName.length === 0) {
            error = 'Please enter a last name';
        } else if (lastName.length < 2) {
            error = 'Please enter at least 2 characters';
        }

        this.setError('lastName', error);

        return !error;
    }

    private validatePhone(): boolean {
        const { phone } = this.state;

        let error: string;

        if (!phone || phone.length  === 0) {
            error = 'Please enter a phone number';
        }

        this.setError('phone', error);

        return !error;
    }

    private validateLoanPurpose(): boolean {
        const { loanPurpose } = this.state;

        let error: string;

        if (!_.values(LoanPurposeEnum).includes(loanPurpose)) {
            error = 'Please select a loan purpose';
        }

        this.setError('loanPurpose', error);

        return !error;
    }

    private validateCustomerConsent(): boolean {
        const { customerConsent } = this.state;

        let error: string;

        if (!customerConsent) {
            error = 'Please confirm the client(s) have given their consent to the above';
        }

        this.setError('customerConsent', error);

        return !error;
    }

    private setError(key: string, value: string): void {
        this.setState((prevState: IState) => {
            return {
                errors: {
                    ...prevState.errors,
                    [key]: value,
                },
            };
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        saving: savingSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        dealAdd: (lead: IDeal) => dispatch(PartnerReferralDealAddAction(lead)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Referral);
