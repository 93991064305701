import { all, call, put, takeEvery } from '@redux-saga/core/effects';
import Cookies from 'js-cookie';
import { IDeal } from '~Api/Deal';
import { parseDeal } from '~Api/Deal/parsers';
import {
    dealAddRequest,
    dealCompleteRequest,
} from '~Api/Deal/requests';
import UiSourceEnum from '~Api/Deal/UiSourceEnum';
import history from '~history';
import { dealSetAction } from '~Lead/actions';
import { IFetchResponse } from '~utilities/fetch';
import { IPartnerReferralDealAddAction } from './actions';
import ActionsEnum from './ActionsEnum';
import { verifyToken } from '~utilities/jwt';

function* partnerReferralDealAdd(action: IPartnerReferralDealAddAction): Iterator<unknown> {
    const rawDeal: IFetchResponse = yield call(dealAddRequest, {
        ...action.lead,
        uiSource: UiSourceEnum.ReferralPartner,
    });

    const token: string = rawDeal.headers.get('Funding-Auth-Token');

    verifyToken(token);
    Cookies.set('jwt_token', token);

    const parsedDeal: IDeal = parseDeal(rawDeal.body);

    yield call(dealCompleteRequest, parsedDeal, false);

    yield put(dealSetAction(parsedDeal));

    history.push('/partner-referral/thanks');
}

export function* PartnerReferralSagas(): Iterator<unknown> {
    yield all([
        takeEvery(ActionsEnum.PartnerReferralDealAdd, partnerReferralDealAdd),
    ]);
}
