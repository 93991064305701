import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { Spinner, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IDeal } from '~Api/Deal';
import BrokerStatusEnum from '~Api/Deal/BrokerStatusEnum';
import { dealsListAction } from '~Lead/actions';
import { dealsSelector } from '~Lead/selectors';
import { IGlobalState } from '~reducer';
import Pagination from '~UI/Pagination';
import SortableHeader, { SortOrderEnum } from '~UI/SortableHeader';
import { IDictionary } from '~utilities/IDictionary';

enum SortFieldEnum {
    ClientName = 'clientName',
    CreatedTime = 'createdTime',
    LoanAmount = 'loanAmount',
    BrokerStatus = 'brokerStatus',
}

const brokerStatusLabels: IDictionary<string> = {
    [BrokerStatusEnum.Application]: 'Application',
    [BrokerStatusEnum.Rejected]: 'Closed',
    [BrokerStatusEnum.Quote]: 'Quote',
};

interface IState {
    currentPage: number;
    perPage: number;
    sortField: SortFieldEnum;
    sortOrder: SortOrderEnum;
}

interface IPropsSelector {
    deals: IDictionary<IDeal>;
}

interface IPropsDispatch {
    dealsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class DealsTable extends React.Component<Props, IState> {
    public state: IState = {
        currentPage: 1,
        perPage: 10,
        sortField: SortFieldEnum.CreatedTime,
        sortOrder: SortOrderEnum.Ascending,
    };

    constructor(props: Props) {
        super(props);

        this.onChangePerPage = this.onChangePerPage.bind(this);

        this.onClickPage = this.onClickPage.bind(this);
        this.onClickSort = this.onClickSort.bind(this);
        this.onClickSortByClientName = this.onClickSortByClientName.bind(this);
        this.onClickSortByCreatedTime = this.onClickSortByCreatedTime.bind(this);
        this.onClickSortByLoanAmount = this.onClickSortByLoanAmount.bind(this);
        this.onClickSortByStatus = this.onClickSortByStatus.bind(this);
    }

    public componentDidMount() {
        const { deals } = this.props;

        if (!deals) {
            this.props.dealsList();
        }
    }

    public render(): JSX.Element {
        const { deals } = this.props;
        const { currentPage, perPage, sortField, sortOrder } = this.state;

        if (!deals) {
            return (
                <div className='main-box deals'>
                    <h2>Deals</h2>
                    <div className='spinner'>
                        <Spinner animation='border' />
                    </div>
                </div>
            );
        }

        let sortedDeals: IDeal[] = _.sortBy(deals, [(deal: IDeal) => {
            switch (sortField) {
                case SortFieldEnum.ClientName:
                    return `${deal.firstName} ${deal.lastName}`;
                case SortFieldEnum.CreatedTime:
                    return deal.createdTime;
                case SortFieldEnum.LoanAmount:
                    return deal.loanAmount;
                case SortFieldEnum.BrokerStatus:
                    return deal.brokerStatus;
            }
        }]);

        if (sortOrder === SortOrderEnum.Descending) {
            sortedDeals = _.reverse(sortedDeals);
        }

        const offset: number = (currentPage - 1) * perPage;
        const pageDeals: IDeal[] = _.slice(sortedDeals, offset, offset + perPage);

        const dealsBlock: JSX.Element[] = pageDeals.map((deal: IDeal) => {
            const { firstName, lastName } = deal;

            const clientName = firstName && lastName ? `${firstName} ${lastName}` : '-';

            return (
                <tr key={deal.uuid}>
                    <td className='created-date'>{moment(deal.createdTime).format('D MMM YYYY')}</td>
                    <td className='client-name'>{clientName}</td>
                    <td className='loan-amount'>{numeral(deal.loanAmount).format('$0,0[.]00')}</td>
                    <td className='broker-status'>{brokerStatusLabels[deal.brokerStatus]}</td>
                </tr>
            );
        });

        return (
            <div className='main-box deals'>
                <h2>Deals</h2>
                <Table
                    className='deals-table list-table'
                    bordered={true}
                >
                    <thead>
                        <tr>
                            <SortableHeader
                                className={'created-date'}
                                field={SortFieldEnum.CreatedTime}
                                label='Submitted'
                                onClick={this.onClickSortByCreatedTime}
                                sortField={sortField}
                                sortOrder={sortOrder}
                            />
                            <SortableHeader
                                className={'client-name'}
                                field={SortFieldEnum.ClientName}
                                label='Client Name'
                                onClick={this.onClickSortByClientName}
                                sortField={sortField}
                                sortOrder={sortOrder}
                            />
                            <SortableHeader
                                className={'loan-amount'}
                                field={SortFieldEnum.LoanAmount}
                                label='Loan Amount'
                                onClick={this.onClickSortByLoanAmount}
                                sortField={sortField}
                                sortOrder={sortOrder}
                            />
                            <SortableHeader
                                className={'broker-status'}
                                field={SortFieldEnum.BrokerStatus}
                                label='Status'
                                onClick={this.onClickSortByStatus}
                                sortField={sortField}
                                sortOrder={sortOrder}
                            />
                        </tr>
                    </thead>
                    <tbody>
                        {dealsBlock}
                    </tbody>
                </Table>
                <div className='pagination-block'>
                    <Pagination
                        currentPage={currentPage}
                        itemCount={sortedDeals.length}
                        onChangePerPage={this.onChangePerPage}
                        onClickPage={this.onClickPage}
                        perPage={perPage}
                    />
                </div>
            </div>
        );
    }

    private onClickPage(page: number) {
        this.setState({
            currentPage: page,
        });
    }

    private onChangePerPage(perPage: number) {
        this.setState({
            currentPage: 1,
            perPage,
        });
    }

    private onClickSort(field: SortFieldEnum) {
        const { sortField, sortOrder } = this.state;

        if (sortField === field) {
            this.setState({
                sortOrder: sortOrder === SortOrderEnum.Ascending ? SortOrderEnum.Descending : SortOrderEnum.Ascending,
            });
        } else {
            this.setState({
                sortField: field,
                sortOrder: SortOrderEnum.Ascending,
            });
        }
    }

    private onClickSortByClientName() {
        this.onClickSort(SortFieldEnum.ClientName);
    }

    private onClickSortByCreatedTime() {
        this.onClickSort(SortFieldEnum.CreatedTime);
    }

    private onClickSortByLoanAmount() {
        this.onClickSort(SortFieldEnum.LoanAmount);
    }

    private onClickSortByStatus() {
        this.onClickSort(SortFieldEnum.BrokerStatus);
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        deals: dealsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        dealsList: () => dispatch(dealsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DealsTable);
