import { Action } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IAnnualStatement from '~Api/Investor/IAnnualStatement';
import IBankAccount from '~Api/Investor/IBankAccount';
import ICompany from '~Api/Investor/ICompany';
import IIncomeTrust from '~Api/Investor/IIncomeTrust';
import IIndividual from '~Api/Investor/IIndividual';
import IInvestor from '~Api/Investor/IInvestor';
import IMonthlyStatement from '~Api/Investor/IMonthlyStatement';
import InterestPaymentStrategyEnum from '~Api/Investor/InterestPaymentStrategyEnum';
import IReferral from '~Api/Investor/IReferral';
import IShare from '~Api/Investor/IShare';
import ITransaction from '~Api/Investor/ITransaction';
import ITrust from '~Api/Investor/ITrust';
import { IDictionary } from '~utilities/IDictionary';
import InvestorActionsEnum from './ActionsEnum';
import { IIncomeTrustInvestErrors, IInvestErrors, IReferralAddErrors } from './reducer';

export interface IAnnualStatementsListAction extends Action {
    accountType: AccountTypeEnum;
}

export function annualStatementsListAction(accountType: AccountTypeEnum): IAnnualStatementsListAction {
    return {
        accountType,
        type: InvestorActionsEnum.AnnualStatementsList,
    };
}

export interface IAnnualStatementsSetAction extends Action {
    annualStatements: IAnnualStatement[];
    investorAccountUuid: string;
}

export function annualStatementsSetAction(investorAccountUuid: string, annualStatements: IAnnualStatement[]): IAnnualStatementsSetAction {
    return {
        annualStatements,
        investorAccountUuid,
        type: InvestorActionsEnum.AnnualStatementsSet,
    };
}

export interface ICompleteSignupModalShowSetAction extends Action {
    show: boolean;
}

export function completeSignupModalShowSetAction(show: boolean): ICompleteSignupModalShowSetAction {
    return {
        show,
        type: InvestorActionsEnum.CompleteSignupModalShowSet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface ICurrentInvestorGetAction extends Action {}

export function currentInvestorGetAction(): ICurrentInvestorGetAction {
    return {
        type: InvestorActionsEnum.CurrentInvestorGet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface ICurrentInvestorSendAction extends Action {}

export function currentInvestorSendAction(): ICurrentInvestorSendAction {
    return {
        type: InvestorActionsEnum.CurrentInvestorSend,
    };
}


export interface ICurrentInvestorSetAction extends Action {
    investor: IInvestor;
}

export function currentInvestorSetAction(investor: IInvestor): ICurrentInvestorSetAction {
    return {
        investor,
        type: InvestorActionsEnum.CurrentInvestorSet,
    };
}

export interface ICurrentInvestorValueSetAction extends Action {
    key: keyof IInvestor;
    value: any;
}

export function currentInvestorValueSetAction(key: keyof IInvestor, value: any): ICurrentInvestorValueSetAction {
    return {
        key,
        type: InvestorActionsEnum.CurrentInvestorValueSet,
        value,
    };
}

export interface ICurrentInvestorBankAccountValueSetAction extends Action {
    key: keyof IBankAccount;
    value: any;
}

export function currentInvestorBankAccountValueSetAction(key: keyof IBankAccount, value: any): ICurrentInvestorBankAccountValueSetAction {
    return {
        key,
        type: InvestorActionsEnum.CurrentInvestorBankAccountValueSet,
        value,
    };
}

export interface ICurrentInvestorCompanyValueSetAction extends Action {
    key: keyof ICompany;
    value: any;
}

export function currentInvestorCompanyValueSetAction(key: keyof ICompany, value: any): ICurrentInvestorCompanyValueSetAction {
    return {
        key,
        type: InvestorActionsEnum.CurrentInvestorCompanyValueSet,
        value,
    };
}

export interface ICurrentInvestorIndividualValueSetAction extends Action {
    index: number;
    key: keyof IIndividual;
    value: any;
}

export function currentInvestorIndividualValueSetAction(index: number, key: keyof IIndividual, value: any): ICurrentInvestorIndividualValueSetAction {
    return {
        index,
        key,
        type: InvestorActionsEnum.CurrentInvestorIndividualValueSet,
        value,
    };
}

export interface ICurrentInvestorTrustValueSetAction extends Action {
    key: keyof ITrust;
    value: any;
}

export function currentInvestorTrustValueSetAction(key: keyof ITrust, value: any): ICurrentInvestorTrustValueSetAction {
    return {
        key,
        type: InvestorActionsEnum.CurrentInvestorTrustValueSet,
        value,
    };
}

export interface ICurrentInvestorAccountIncomeTrustInterestPaymentStrategySetAction extends Action {
    interestPaymentStrategy: InterestPaymentStrategyEnum;
}

export function currentInvestorAccountIncomeTrustInterestPaymentStrategySetAction(interestPaymentStrategy: InterestPaymentStrategyEnum): ICurrentInvestorAccountIncomeTrustInterestPaymentStrategySetAction {
    return {
        interestPaymentStrategy,
        type: InvestorActionsEnum.CurrentInvestorAccountIncomeTrustInterestPaymentStrategySet,
    };
}

export interface ICurrentInvestorAccountIncomeTrustSetAction extends Action {
    investorAccount: IAccount;
}

export function currentInvestorAccountIncomeTrustSetAction(investorAccount: IAccount): ICurrentInvestorAccountIncomeTrustSetAction {
    return {
        investorAccount,
        type: InvestorActionsEnum.CurrentInvestorAccountIncomeTrustSet,
    };
}

export interface ICurrentInvestorAccountMarketplaceSetAction extends Action {
    investorAccount: IAccount;
}

export function currentInvestorAccountMarketplaceSetAction(investorAccount: IAccount): ICurrentInvestorAccountMarketplaceSetAction {
    return {
        investorAccount,
        type: InvestorActionsEnum.CurrentInvestorAccountMarketplaceSet,
    };
}

export interface IIncomeTrustInvestAction extends Action {
    amount: number;
    incomeTrustUuid: string;
}

export function incomeTrustInvestAction(amount: number, incomeTrustUuid: string): IIncomeTrustInvestAction {
    return {
        amount,
        incomeTrustUuid,
        type: InvestorActionsEnum.IncomeTrustInvest,
    };
}

export interface IIncomeTrustInvestCompleteModalShowSetAction extends Action {
    show: boolean;
}

export function incomeTrustInvestCompleteModalShowSetAction(show: boolean): IIncomeTrustInvestCompleteModalShowSetAction {
    return {
        show,
        type: InvestorActionsEnum.IncomeTrustInvestCompleteModalShowSet,
    };
}

export interface IIncomeTrustInvestConfirmModalShowSetAction extends Action {
    show: boolean;
}

export function incomeTrustInvestConfirmModalShowSetAction(show: boolean): IIncomeTrustInvestConfirmModalShowSetAction {
    return {
        show,
        type: InvestorActionsEnum.IncomeTrustInvestConfirmModalShowSet,
    };
}

export interface IIncomeTrustInvestErrorSetAction extends Action {
    key: keyof IIncomeTrustInvestErrors;
    value: any;
}

export function incomeTrustInvestErrorSetAction(key: keyof IIncomeTrustInvestErrors, value: any): IIncomeTrustInvestErrorSetAction {
    return {
        key,
        type: InvestorActionsEnum.IncomeTrustInvestErrorSet,
        value,
    };
}

export interface IIncomeTrustInvestErrorsSetAction extends Action {
    errors: IIncomeTrustInvestErrors;
}

export function incomeTrustInvestErrorsSetAction(errors: IIncomeTrustInvestErrors): IIncomeTrustInvestErrorsSetAction {
    return {
        errors,
        type: InvestorActionsEnum.IncomeTrustInvestErrorsSet,
    };
}

export interface IIncomeTrustInvestInProgressSetAction extends Action {
    inProgress: boolean;
}

export function incomeTrustInvestInProgressSetAction(inProgress: boolean): IIncomeTrustInvestInProgressSetAction {
    return {
        inProgress,
        type: InvestorActionsEnum.IncomeTrustInvestInProgressSet,
    };
}

export interface IInvestmentGetAction extends Action {
    uuid: string;
}

export function investmentGetAction(uuid: string): IInvestmentGetAction {
    return {
        type: InvestorActionsEnum.InvestmentGet,
        uuid,
    };
}

export interface IInvestmentInvestAction extends Action {
    amount: number;
    uuid: string;
}

export function investmentInvestAction(uuid: string, amount: number): IInvestmentInvestAction {
    return {
        amount,
        type: InvestorActionsEnum.InvestmentInvest,
        uuid,
    };
}

export interface IInvestmentInvestAmountSetAction extends Action {
    amount: number;
    uuid: string;
}

export function investmentInvestAmountSetAction(uuid: string, amount: number): IInvestmentInvestAmountSetAction {
    return {
        amount,
        type: InvestorActionsEnum.InvestmentInvestAmountSet,
        uuid,
    };
}

export interface IInvestmentInvestCompleteModalShowSetAction extends Action {
    show: boolean;
}

export function investmentInvestCompleteModalShowSetAction(show: boolean): IInvestmentInvestCompleteModalShowSetAction {
    return {
        show,
        type: InvestorActionsEnum.InvestmentInvestCompleteModalShowSet,
    };
}

export interface IInvestmentInvestErrorSetAction extends Action {
    key: keyof IInvestErrors;
    value: any;
}

export function investmentInvestErrorSetAction(key: keyof IInvestErrors, value: any): IInvestmentInvestErrorSetAction {
    return {
        key,
        type: InvestorActionsEnum.InvestmentInvestErrorSet,
        value,
    };
}

export interface IInvestmentInvestErrorsSetAction extends Action {
    errors: IInvestErrors;
    uuid: string;
}

export function investmentInvestErrorsSetAction(uuid: string, errors: IInvestErrors): IInvestmentInvestErrorsSetAction {
    return {
        errors,
        type: InvestorActionsEnum.InvestmentInvestErrorsSet,
        uuid,
    };
}

export interface IInvestmentInvestInProgressSetAction extends Action {
    inProgress: boolean;
}

export function investmentInvestInProgressSetAction(inProgress: boolean): IInvestmentInvestInProgressSetAction {
    return {
        inProgress,
        type: InvestorActionsEnum.InvestmentInvestInProgressSet,
    };
}

export interface IInvestmentInvestModalShowSetAction extends Action {
    show: boolean;
}

export function investmentInvestModalShowSetAction(show: boolean): IInvestmentInvestModalShowSetAction {
    return {
        show,
        type: InvestorActionsEnum.InvestmentInvestModalShowSet,
    };
}

export interface IInvestmentSetAction extends Action {
    investment: IInvestment;
}

export function investmentSetAction(investment: IInvestment): IInvestmentSetAction {
    return {
        investment,
        type: InvestorActionsEnum.InvestmentSet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IInvestmentsAvailableListAction extends Action {}

export function investmentsAvailableListAction(): IInvestmentsAvailableListAction {
    return {
        type: InvestorActionsEnum.InvestmentsAvailableList,
    };
}

export interface IInvestmentsAvailableSetAction extends Action {
    investments: IInvestment[];
}

export function investmentsAvailableSetAction(investments: IInvestment[]): IInvestmentsAvailableSetAction {
    return {
        investments,
        type: InvestorActionsEnum.InvestmentsAvailableSet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IInvestmentsRecentlyFundedListAction extends Action {}

export function investmentsRecentlyFundedListAction(): IInvestmentsRecentlyFundedListAction {
    return {
        type: InvestorActionsEnum.InvestmentsRecentlyFundedList,
    };
}

export interface IInvestmentsRecentlyFundedSetAction extends Action {
    investments: IInvestment[];
}

export function investmentsRecentlyFundedSetAction(investments: IInvestment[]): IInvestmentsRecentlyFundedSetAction {
    return {
        investments,
        type: InvestorActionsEnum.InvestmentsRecentlyFundedSet,
    };
}

// tslint:disable-next-line:no-empty-interface
export interface IIncomeTrustAccountCompleteAction extends Action {
    imContent: string
}

export function incomeTrustAccountCompleteAction(imContent: string): IIncomeTrustAccountCompleteAction {
    return {
        imContent,
        type: InvestorActionsEnum.IncomeTrustAccountComplete,
    };
}

// tslint:disable-next-line:no-empty-interface
export interface IIncomeTrustsListAction extends Action {}

export function incomeTrustsListAction(): IIncomeTrustsListAction {
    return {
        type: InvestorActionsEnum.IncomeTrustsList,
    };
}

export interface IIncomeTrustsSetAction extends Action {
    incomeTrusts: IIncomeTrust[];
}

export function incomeTrustsSetAction(incomeTrusts: IIncomeTrust[]): IIncomeTrustsSetAction {
    return {
        incomeTrusts,
        type: InvestorActionsEnum.IncomeTrustsSet,
    };
}

// tslint:disable-next-line:no-empty-interface
export interface IMarketplaceAccountCompleteAction extends Action {
    pdsContent: string;
}

export function marketplaceAccountCompleteAction(pdsContent: string): IMarketplaceAccountCompleteAction {
    return {
        pdsContent,
        type: InvestorActionsEnum.MarketplaceAccountComplete,
    };
}

export interface IMonthlyStatementsListAction extends Action {
    accountType: AccountTypeEnum;
}

export function monthlyStatementsListAction(accountType: AccountTypeEnum): IMonthlyStatementsListAction {
    return {
        accountType,
        type: InvestorActionsEnum.MonthlyStatementsList,
    };
}

export interface IMonthlyStatementsSetAction extends Action {
    investorAccountUuid: string;
    monthlyStatements: IMonthlyStatement[];
}

export function monthlyStatementsSetAction(investorAccountUuid: string, monthlyStatements: IMonthlyStatement[]): IMonthlyStatementsSetAction {
    return {
        investorAccountUuid,
        monthlyStatements,
        type: InvestorActionsEnum.MonthlyStatementsSet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IReferralAddAction extends Action {}

export function referralAddAction(): IReferralAddAction {
    return {
        type: InvestorActionsEnum.ReferralAdd,
    };
}

export interface IReferralAddErrorSetAction extends Action {
    key: keyof IReferralAddErrors;
    value: any;
}

export function referralAddErrorSetAction(key: keyof IReferralAddErrors, value: any): IReferralAddErrorSetAction {
    return {
        key,
        type: InvestorActionsEnum.ReferralAddErrorSet,
        value,
    };
}

export interface IReferralAddErrorsSetAction extends Action {
    errors: IReferralAddErrors;
}

export function referralAddErrorsSetAction(errors: IReferralAddErrors): IReferralAddErrorsSetAction {
    return {
        errors,
        type: InvestorActionsEnum.ReferralAddErrorsSet,
    };
}

export interface IReferralAddInProgressSetAction extends Action {
    inProgress: boolean;
}

export function referralAddInProgressSetAction(inProgress: boolean): IReferralAddInProgressSetAction {
    return {
        inProgress,
        type: InvestorActionsEnum.ReferralAddInProgressSet,
    };
}

export interface IReferralEmailSetAction extends Action {
    email: string;
}

export function referralEmailSetAction(email: string): IReferralEmailSetAction {
    return {
        email,
        type: InvestorActionsEnum.ReferralEmailSet,
    };
}

export interface IReferralSetAction extends Action {
    referral: IReferral;
}

export function referralSetAction(referral: IReferral): IReferralSetAction {
    return {
        referral,
        type: InvestorActionsEnum.ReferralSet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IReferralsListAction extends Action {}

export function referralsListAction(): IReferralsListAction {
    return {
        type: InvestorActionsEnum.ReferralsList,
    };
}

export interface IReferralsSetAction extends Action {
    referrals: IReferral[];
}

export function referralsSetAction(referrals: IReferral[]): IReferralsSetAction {
    return {
        referrals,
        type: InvestorActionsEnum.ReferralsSet,
    };
}

export interface IRegisterErrorSetAction extends Action {
    key: string;
    value: string;
}

export function registerErrorSetAction(key: string, value: string): IRegisterErrorSetAction {
    return {
        key,
        type: InvestorActionsEnum.RegisterErrorSet,
        value,
    };
}

export interface IRegisterErrorsSetAction extends Action {
    errors: IDictionary<string>;
}

export function registerErrorsSetAction(errors: IDictionary<string>): IRegisterErrorsSetAction {
    return {
        errors,
        type: InvestorActionsEnum.RegisterErrorsSet,
    };
}

export interface IRegisterInProgressSetAction extends Action {
    inProgress: boolean;
}

export function registerInProgressSetAction(inProgress: boolean): IRegisterInProgressSetAction {
    return {
        inProgress,
        type: InvestorActionsEnum.RegisterInProgressSet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface ISharesListAction extends Action {}

export function sharesListAction(): ISharesListAction {
    return {
        type: InvestorActionsEnum.SharesList,
    };
}

export interface ISharesSetAction extends Action {
    shares: IShare[];
}

export function sharesSetAction(shares: IShare[]): ISharesSetAction {
    return {
        shares,
        type: InvestorActionsEnum.SharesSet,
    };
}

export interface ITopUpAction extends Action {
    accountType: AccountTypeEnum;
    amount: number;
}

export function topUpAction(amount: number, accountType: AccountTypeEnum): ITopUpAction {
    return {
        accountType,
        amount,
        type: InvestorActionsEnum.TopUp,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface ITopUpCompleteModalHideAction extends Action {}

export function topUpCompleteModalHideAction(): ITopUpCompleteModalHideAction {
    return {
        type: InvestorActionsEnum.TopUpCompleteModalHide,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface ITopUpCompleteModalShowAction extends Action {}

export function topUpCompleteModalShowAction(): ITopUpCompleteModalShowAction {
    return {
        type: InvestorActionsEnum.TopUpCompleteModalShow,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface ITopUpConfirmModalHideAction extends Action {}

export function topUpConfirmModalHideAction(): ITopUpConfirmModalHideAction {
    return {
        type: InvestorActionsEnum.TopUpConfirmModalHide,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface ITopUpConfirmModalShowAction extends Action {}

export function topUpConfirmModalShowAction(): ITopUpConfirmModalShowAction {
    return {
        type: InvestorActionsEnum.TopUpConfirmModalShow,
    };
}

export interface ITopUpErrorSetAction extends Action {
    key: string;
    value: any;
}

export function topUpErrorSetAction(key: string, value: any): ITopUpErrorSetAction {
    return {
        key,
        type: InvestorActionsEnum.TopUpErrorSet,
        value,
    };
}

export interface ITopUpErrorsSetAction extends Action {
    errors: any;
}

export function topUpErrorsSetAction(errors: any): ITopUpErrorsSetAction {
    return {
        errors,
        type: InvestorActionsEnum.TopUpErrorsSet,
    };
}

export interface ITopUpInProgressSetAction extends Action {
    inProgress: boolean;
}

export function topUpInProgressSetAction(inProgress: boolean): ITopUpInProgressSetAction {
    return {
        inProgress,
        type: InvestorActionsEnum.TopUpInProgressSet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface ITopUpModalHideAction extends Action {}

export function topUpModalHideAction(): ITopUpModalHideAction {
    return {
        type: InvestorActionsEnum.TopUpModalHide,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface ITopUpModalShowAction extends Action {}

export function topUpModalShowAction(): ITopUpModalShowAction {
    return {
        type: InvestorActionsEnum.TopUpModalShow,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface ITransactionsListAction extends Action {}

export function transactionsListAction(): ITransactionsListAction {
    return {
        type: InvestorActionsEnum.TransactionsList,
    };
}

export interface ITransactionsSetAction extends Action {
    transactions: ITransaction[];
}

export function transactionsSetAction(transactions: ITransaction[]): ITransactionsSetAction {
    return {
        transactions,
        type: InvestorActionsEnum.TransactionsSet,
    };
}

export interface IWithdrawAction extends Action {
    amount: number;
}

export function withdrawAction(amount: number): IWithdrawAction {
    return {
        amount,
        type: InvestorActionsEnum.Withdraw,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IWithdrawCompleteModalHideAction extends Action {}

export function withdrawCompleteModalHideAction(): IWithdrawCompleteModalHideAction {
    return {
        type: InvestorActionsEnum.WithdrawCompleteModalHide,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IWithdrawCompleteModalShowAction extends Action {}

export function withdrawCompleteModalShowAction(): IWithdrawCompleteModalShowAction {
    return {
        type: InvestorActionsEnum.WithdrawCompleteModalShow,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IWithdrawConfirmModalHideAction extends Action {}

export function withdrawConfirmModalHideAction(): IWithdrawConfirmModalHideAction {
    return {
        type: InvestorActionsEnum.WithdrawConfirmModalHide,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IWithdrawConfirmModalShowAction extends Action {}

export function withdrawConfirmModalShowAction(): IWithdrawConfirmModalShowAction {
    return {
        type: InvestorActionsEnum.WithdrawConfirmModalShow,
    };
}

export interface IWithdrawErrorSetAction extends Action {
    key: string;
    value: any;
}

export function withdrawErrorSetAction(key: string, value: any): IWithdrawErrorSetAction {
    return {
        key,
        type: InvestorActionsEnum.WithdrawErrorSet,
        value,
    };
}

export interface IWithdrawErrorsSetAction extends Action {
    errors: any;
}

export function withdrawErrorsSetAction(errors: any): IWithdrawErrorsSetAction {
    return {
        errors,
        type: InvestorActionsEnum.WithdrawErrorsSet,
    };
}

export interface IWithdrawInProgressSetAction extends Action {
    inProgress: boolean;
}

export function withdrawInProgressSetAction(inProgress: boolean): IWithdrawInProgressSetAction {
    return {
        inProgress,
        type: InvestorActionsEnum.WithdrawInProgressSet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IWithdrawModalHideAction extends Action {}

export function withdrawModalHideAction(): IWithdrawModalHideAction {
    return {
        type: InvestorActionsEnum.WithdrawModalHide,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IWithdrawModalShowAction extends Action {}

export function withdrawModalShowAction(): IWithdrawModalShowAction {
    return {
        type: InvestorActionsEnum.WithdrawModalShow,
    };
}
