import React from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import Layout from '~Auth/Layout';
import { emailVerifyAction } from './actions';

interface IMatch {
    code: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsDispatch {
    userEmailVerify: () => void;
}

type Props = IProps & IPropsDispatch;

class VerifyEmail extends React.Component<Props> {
    public componentDidMount() {
        this.props.userEmailVerify();
    }

    public render(): JSX.Element {
        return (
            <Layout section='verify-email'>
                <Spinner animation='border'/>
            </Layout>
        );
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        userEmailVerify: () => dispatch(emailVerifyAction(ownProps.match.params.code)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(VerifyEmail);
