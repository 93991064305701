import _ from 'lodash';
import { AnyAction } from 'redux';
import IDeal, { IDealErrors } from '~Api/Deal/IDeal';
import IDealProperty from '~Api/Deal/IDealProperty';
import PartnerReferralActionsEnum from '~PartnerReferral/ActionsEnum';
import IDealQuote from '~Api/Deal/IDealQuote';
import { IDictionary } from '~utilities/IDictionary';
import {
    IDealAddAction,
    IDealBorrowStepSetAction,
    IDealCompleteAction,
    IDealErrorSetAction,
    IDealQuotesSetAction,
    IDealSetAction,
    IDealsSetAction,
    IDealValueSetAction,
    IPropertyAddAction,
    IPropertyDeleteAction,
    IPropertyErrorSetAction,
    IPropertySetAction,
    IPropertyValueSetAction,
} from './actions';
import LeadActionsEnum from './ActionsEnum';

export interface ILeadState {
    borrowStep: number;
    deal: IDeal;
    dealQuoteUuids: IDictionary<string[]>;
    deals: IDictionary<IDeal>;
    dealsListed: boolean;
    errors: IDealErrors;
    properties: IDictionary<IDealProperty>;
    quotes: IDictionary<IDealQuote>;
    saving: boolean;
}

const initialData: ILeadState = {
    borrowStep: null,
    deal: {
        comments: null,
        email: null,
        firstName: null,
        isBroker: null,
        lastName: null,
        loanAmount: null,
        loanPurpose: null,
        phone: null,
        termMonths: null,
    },
    deals: {},
    dealsListed: false,
    errors: {},
    properties: {},
    quotes: {},
    saving: false,
};

function dehydrateDeal(deal: IDeal): IDeal {
    return _.omit(deal, ['properties']);
}

export function leadReducer(state: ILeadState = initialData, action: AnyAction): ILeadState {
    switch (action.type) {
        case LeadActionsEnum.DealAdd: {
            const typedAction: IDealAddAction = action as IDealAddAction;

            return {
                ...state,
                saving: true,
            };
        }

        case LeadActionsEnum.DealBorrowStepSet: {
            const typedAction: IDealBorrowStepSetAction = action as IDealBorrowStepSetAction;

            return {
                ...state,
                borrowStep: typedAction.borrowStep,
            };
        }

        case LeadActionsEnum.DealComplete: {
            const typedAction: IDealCompleteAction = action as IDealCompleteAction;

            return {
                ...state,
                saving: true,
            };
        }

        case LeadActionsEnum.DealErrorSet: {
            const typedAction: IDealErrorSetAction = action as IDealErrorSetAction;

            return {
                ...state,
                errors: {
                    ...state.errors,
                    [typedAction.field]: typedAction.value,
                },
            };
        }

        case LeadActionsEnum.DealSet: {
            const typedAction: IDealSetAction = action as IDealSetAction;

            const currentDealProperties: IDictionary<IDealProperty> = {};

            typedAction.deal.properties.forEach((property: IDealProperty) => {
                currentDealProperties[property.uuid] = property;
            });

            return {
                ...state,
                deal: dehydrateDeal(typedAction.deal),
                properties: currentDealProperties,
                saving: false,
            };
        }

        case LeadActionsEnum.DealValueSet: {
            const typedAction: IDealValueSetAction = action as IDealValueSetAction;

            return {
                ...state,
                deal: {
                    ...state.deal,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        case LeadActionsEnum.DealsSet: {
            const typedAction: IDealsSetAction = action as IDealsSetAction;

            const deals: IDictionary<IDeal> = {};
            typedAction.deals.forEach((deal: IDeal) => {
                deals[deal.uuid] = deal;
            });

            return {
                ...state,
                deals,
                dealsListed: true,
            };
        }

        case LeadActionsEnum.DealQuotesSet: {
            const typedAction: IDealQuotesSetAction = action as IDealQuotesSetAction;

            const quotes: IDictionary<IDealQuote> = { ...state.quotes };
            const dealQuoteUuids: IDictionary<string[]> = { ...state.dealQuoteUuids };

            typedAction.quotes.forEach((quote: IDealQuote) => {
                dealQuoteUuids[quote.dealUuid] = [
                    ...(dealQuoteUuids[quote.dealUuid] || []),
                    quote.uuid,
                ];
                quotes[quote.uuid] = quote;
            });

            return {
                ...state,
                dealQuoteUuids,
                quotes,
            };
        }

        case LeadActionsEnum.PropertiesAdd: {
            const typedAction: IPropertyAddAction = action as IPropertyAddAction;

            return {
                ...state,
                saving: true,
            };
        }

        case LeadActionsEnum.PropertyDelete: {
            const typedAction: IPropertyDeleteAction = action as IPropertyDeleteAction;

            return {
                ...state,
                properties: _.omit(state.properties, [typedAction.uuid]),
            };
        }

        case LeadActionsEnum.PropertyErrorSet: {
            const typedAction: IPropertyErrorSetAction = action as IPropertyErrorSetAction;

            return {
                ...state,
                properties: {
                    ...state.properties,
                    [typedAction.uuid]: {
                        ...state.properties[typedAction.uuid],
                        errors: {
                            ...state.properties[typedAction.uuid].errors,
                            [typedAction.field]: typedAction.value,
                        },
                    },
                },
            };
        }

        case LeadActionsEnum.PropertySet: {
            const typedAction: IPropertySetAction = action as IPropertySetAction;

            return {
                ...state,
                properties: {
                    ...state.properties,
                    [typedAction.property.uuid]: typedAction.property,
                },
                saving: false,
            };
        }

        case LeadActionsEnum.PropertyValueSet: {
            const typedAction: IPropertyValueSetAction = action as IPropertyValueSetAction;

            return {
                ...state,
                properties: {
                    ...state.properties,
                    [typedAction.uuid]: {
                        ...state.properties[typedAction.uuid],
                        [typedAction.field]: typedAction.value,
                    },
                },
            };
        }

        case PartnerReferralActionsEnum.PartnerReferralDealAdd: {
            return {
                ...state,
                saving: true,
            };
        }

        default:
            return state;
    }
}
