import React from 'react';

interface IProps {
    checked?: boolean;
    isInvalid?: boolean;
    label?: string | JSX.Element;
    onChange?: (event: any) => void;
}

class Checkbox extends React.Component<IProps> {
    public render(): JSX.Element {
        const { checked, isInvalid, label } = this.props;

        return (
            <div className={`form-check ${isInvalid && 'is-invalid'}`}>
                <label>
                    <input
                        type='checkbox'
                        className='form-check-input'
                        checked={checked}
                        onChange={this.props.onChange}
                    />
                    <span className='checkmark' />
                    {label}
                </label>
            </div>
        );
    }
}

export default Checkbox;
