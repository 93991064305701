import React from 'react';
import Lottie, { Options } from 'react-lottie';
import { Link } from 'react-router-dom';
import success from '~UI/success.json';
import './application-success.less';
import Layout from './Layout';

class ApplicationSuccess extends React.Component {
    public render(): JSX.Element {
        const options: Options = {
            animationData: success,
            autoplay: true,
            loop: false,
        };

        return (
            <Layout section='application-success'>
                <h1>Applications</h1>
                <div className='main-box'>
                    <h2>Application successfully requested</h2>
                    <p>We will be in touch shortly. Return to your <Link to='/'>dashboard</Link> or request another <Link to='/applications/submit'>quote</Link>.</p>
                    <Lottie height={180} options={options} width={180} />
                </div>
            </Layout>
        );
    }
}

export default ApplicationSuccess;
