import React, { ReactElement } from 'react';
import { Router as ReactRouter, Redirect, Route, Switch } from 'react-router-dom';
import history from '~history';
import AccountInformation from './AccountInformation';
import BankAccount from './BankAccount';
import Details from './Details';
import Qualifier from './Qualifier';
import ProductDisclosure from './ProductDisclosure';

export default function Router(): ReactElement {
    return (
        <ReactRouter history={history}>
            <Switch>
                <Route exact={true} path='/invest/marketplace' component={AccountInformation} />
                <Route exact={true} path='/invest/marketplace/details' component={Details} />
                <Route exact={true} path='/invest/marketplace/bank-account' component={BankAccount} />
                <Route exact={true} path='/invest/marketplace/qualifier' component={Qualifier} />
                <Route exact={true} path='/invest/marketplace/pds' component={ProductDisclosure} />

                <Redirect to='/invest/marketplace' />
            </Switch>
        </ReactRouter>
    );
}
