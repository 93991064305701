import React from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IGlobalState } from '~reducer';
import {
    currentUserSelector,
    emailChangeErrorsSelector,
    emailChangeInProgressSelector,
    emailChangeModalShowSelector,
} from '~User/selectors';
import { validateEmail } from '~validators';
import {
    currentUserGetAction,
    emailChangeAction,
    emailChangeErrorSetAction,
    emailChangeModalHideAction,
} from './actions';
import IUser from './IUser';
import './user.less';

interface IState {
    email: string;
}

interface IPropsSelector {
    currentUser: IUser;
    errors: any;
    inProgress: boolean;
    show: boolean;
}

interface IPropsDispatch {
    changeEmail: (email: string) => void;
    currentUserGet: () => void;
    hide: () => void;
    setError: (key: string, value: any) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class EmailChangeModal extends React.Component<Props, IState> {
    public state: IState = {
        email: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickOk = this.onClickOk.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);

        this.validateEmail = this.validateEmail.bind(this);
    }

    public componentDidMount() {
        const { currentUser } = this.props;

        if (!currentUser) {
            this.props.currentUserGet();
        }
    }

    public render(): JSX.Element {
        const { currentUser, errors, inProgress, show } = this.props;
        const { email } = this.state;

        if (!currentUser) {
            return null;
        }

        return (
            <Modal
                dialogClassName='user-email-change-modal'
                onHide={this.props.hide}
                show={show}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Change Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='new-email'>
                        <Form.Label>New Email</Form.Label>
                        <Form.Control isInvalid={!!errors.email} name='email' onBlur={this.validateEmail} onChange={this.onChangeEmail} placeholder='New Email' value={email || ''} />
                        {errors.email && <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>}
                    </Form.Group>
                    <Button disabled={inProgress} name='submit' onClick={this.onClickOk}>
                        {inProgress && <Spinner animation='border' size='sm' />} Confirm
                    </Button>
                </Modal.Body>
            </Modal>
        );
    }

    private onClickOk() {
        const { email } = this.state;

        let valid = true;
        valid = this.validateEmail() && valid;

        if (!valid) {
            return;
        }

        this.props.changeEmail(email);
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            email: event.target.value,
        });
    }

    private validateEmail() {
        const { email } = this.state;

        let error: string;
        if (!email || email.length === 0) {
            error = 'Please enter the new email';
        } else if (!validateEmail(email)) {
            error = 'Please enter a valid email address';
        }
        this.props.setError('email', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentUser: currentUserSelector(state),
        errors: emailChangeErrorsSelector(state),
        inProgress: emailChangeInProgressSelector(state),
        show: emailChangeModalShowSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        changeEmail: (email: string) => dispatch(emailChangeAction(email)),
        currentUserGet: () => dispatch(currentUserGetAction()),
        hide: () => dispatch(emailChangeModalHideAction()),
        setError: (key: string, value: any) => dispatch(emailChangeErrorSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EmailChangeModal);
