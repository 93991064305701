import _ from 'lodash';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IApplication } from '~Api/Application';
import WorkflowStatusEnum from '~Api/Application/WorkflowStatusEnum';
import { IDeal } from '~Api/Deal';
import BrokerStatusEnum from '~Api/Deal/BrokerStatusEnum';
import { applicationsListAction } from '~Application/actions';
import { applicationsSelector } from '~Application/selectors';
import { dealsListAction } from '~Lead/actions';
import { dealsSelector } from '~Lead/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import './performance-snapshot.less';

interface IPropsSelector {
    applications: IDictionary<IApplication>;
    deals: IDictionary<IDeal>;
}

interface IPropsDispatch {
    applicationsList: () => void;
    dealsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class PerformanceSnapshotBox extends React.Component<Props> {
    public componentDidMount() {
        const { applications, deals } = this.props;

        if (!applications) {
            this.props.applicationsList();
        }

        if (!deals) {
            this.props.dealsList();
        }
    }

    public render(): JSX.Element {
        const { applications, deals } = this.props;

        if (!applications || !deals) {
            return (
                <div className='main-box performance-snapshot'>
                    <h2>Your Funding Pipeline</h2>
                    <div className='boxes'>
                        <div className='box applications'>
                            <div className='primary'>
                                <div className='label'>Deals</div>
                                <div className='value'><Spinner animation='border' /></div>
                            </div>
                        </div>
                        <div className='box settled'>
                            <div className='primary'>
                                <div className='label'>Applications</div>
                                <div className='value'><Spinner animation='border' /></div>
                            </div>
                        </div>
                        <div className='box settled-total'>
                            <div className='primary'>
                                <div className='label'>Settled</div>
                                <div className='value'><Spinner animation='border' /></div>
                            </div>
                        </div>
                    </div>
                    <h2>Our turn around times</h2>
                    <div className='boxes'>
                        <div className='box applications'>
                            <div className='primary'>
                                <div className='label'>Quote</div>
                                <div className='value'>1 Hour</div>
                            </div>
                        </div>
                        <div className='box settled'>
                            <div className='primary'>
                                <div className='label'>Conditional Approval</div>
                                <div className='value'>2 Hours</div>
                            </div>
                        </div>
                        <div className='box settled-total'>
                            <div className='primary'>
                                <div className='label'>Settle</div>
                                <div className='value'>5 Days</div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        let activeDealsTotal: number = 0;
        let activeApplicationsTotal: number = 0;
        let settledApplicationsTotal: number = 0;

        _.forEach(deals, (deal: IDeal) => {
            if (deal.brokerStatus === BrokerStatusEnum.Quote) {
                activeDealsTotal += deal.loanAmount;
            }

            if (deal.applicationUuid) {
                const application: IApplication = applications[deal.applicationUuid];

                if (application.workflowStatus === WorkflowStatusEnum.Warehoused) {
                    settledApplicationsTotal += application.loanAmount;
                } else {
                    activeApplicationsTotal += application.loanAmount;
                }
            }
        });

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        return (
            <div className='main-box performance-snapshot'>
                <h2>Your Funding Pipeline</h2>
                <div className='boxes'>
                    <div className='box applications'>
                        <div className='primary'>
                            <div className='label'>Deals</div>
                            <div className='value'>{activeDealsTotal ? currencyFormatter.format(activeDealsTotal) : '-'}</div>
                        </div>
                    </div>
                    <div className='box settled'>
                        <div className='primary'>
                            <div className='label'>Applications</div>
                            <div className='value'>{activeApplicationsTotal ? currencyFormatter.format(activeApplicationsTotal) : '-'}</div>
                        </div>
                    </div>
                    <div className='box settled-total'>
                        <div className='primary'>
                            <div className='label'>Settled</div>
                            <div className='value'>{settledApplicationsTotal ? currencyFormatter.format(settledApplicationsTotal) : '-'}</div>
                        </div>
                    </div>
                </div>
                <h2>Our turn around times</h2>
                <div className='boxes'>
                    <div className='box applications'>
                        <div className='primary'>
                            <div className='label'>Quote</div>
                            <div className='value'>1 Hour</div>
                        </div>
                    </div>
                    <div className='box settled'>
                        <div className='primary'>
                            <div className='label'>Conditional Approval</div>
                            <div className='value'>2 Hours</div>
                        </div>
                    </div>
                    <div className='box settled-total'>
                        <div className='primary'>
                            <div className='label'>Settle</div>
                            <div className='value'>5 Days</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        applications: applicationsSelector(state),
        deals: dealsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        applicationsList: () => dispatch(applicationsListAction()),
        dealsList: () => dispatch(dealsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PerformanceSnapshotBox);
