import _ from 'lodash';
import AddressVersionEnum from '~Api/Deal/AddressVersionEnum';
import IDealProperty, { IDealPropertyErrors } from '~Api/Deal/IDealProperty';
import PropertyStateEnum from '~Api/Deal/PropertyStateEnum';
import PropertyStreetTypeEnum from '~Api/Deal/PropertyStreetTypeEnum';
import ZoneTypeEnum from '~Api/Deal/ZoneTypeEnum';

export function validateProperty(property: IDealProperty, setError: (key: keyof IDealPropertyErrors, value: string) => void): boolean {
    let valid = true;

    valid = !validateCurrentDebt(property.currentDebt, setError) && valid;
    valid = !validateEstimatedValue(property.estimatedValue, setError) && valid;
    valid = !validatePostcode(property.postcode, property.state, setError) && valid;
    valid = !validateState(property.state, setError) && valid;
    valid = !validateSuburb(property.suburb, setError) && valid;
    valid = !validateZoneType(property.zoneType, setError) && valid;

    switch (property.addressVersion) {
        case AddressVersionEnum.V2:
            valid = !validateUnitNumber(property.unitNumber, setError) && valid;
            valid = !validateStreetNumber(property.streetNumber, setError) && valid;
            valid = !validateStreetName(property.streetName, setError) && valid;
            valid = !validateStreetType(property.streetType, setError) && valid;

            break;
        default:
            valid = !validateStreetAddress(property.streetAddress, setError) && valid;
    }

    return valid;
}

export function validateCurrentDebt(currentDebt: number, setError: (key: keyof IDealPropertyErrors, value: string) => void): string {
    let error: string;

    if (currentDebt && currentDebt >= 100000000) {
        error = 'Please enter a valid debt value';
    }
    setError('currentDebt', error);

    return error;
}

export function validateEstimatedValue(estimatedValue: number, setError: (key: keyof IDealPropertyErrors, value: string) => void): string {
    let error: string;

    if (!estimatedValue || estimatedValue === 0) {
        error = 'Please enter your property value';
    } else if (estimatedValue < 1 || estimatedValue >= 100000000) {
        error = 'Please enter a valid property value';
    }

    setError('estimatedValue', error);

    return error;
}

export function validatePostcode(postcode: string, state: string, setError: (key: keyof IDealPropertyErrors, value: string) => void): string {
    let error: string;

    if (!postcode || postcode.length === 0) {
        error = 'Please enter the postcode';
    } else if (postcode.length < 3 || postcode.length > 4) {
        error = 'Postcode must be 3 or 4 numbers';
    }  else if (state && state !== PropertyStateEnum.NorthernTerritory && postcode.length === 3) {
        error = 'Postcode must be 4 numbers';
    }

    if (!error && state) {
        let valid = true;
        const firstChar = postcode.charAt(0);

        switch (state) {
            case PropertyStateEnum.AustralianCapitalTerritory:
            case PropertyStateEnum.NewSouthWales:
                if (firstChar !== '2') {
                    valid = false;
                }
                break;

            case PropertyStateEnum.NorthernTerritory:
                if ((postcode.length === 3 && firstChar !== '8') || (postcode.length === 4 && postcode.substring(0, 2) !== '08')) {
                    valid = false;
                }
                break;

            case PropertyStateEnum.Queensland:
                if (firstChar !== '4') {
                    valid = false;
                }
                break;

            case PropertyStateEnum.SouthAustralia:
                if (firstChar !== '5') {
                    valid = false;
                }
                break;

            case PropertyStateEnum.Tasmania:
                if (firstChar !== '7') {
                    valid = false;
                }
                break;

            case PropertyStateEnum.Victoria:
                if (firstChar !== '3') {
                    valid = false;
                }
                break;

            case PropertyStateEnum.WesternAustralia:
                if (firstChar !== '6') {
                    valid = false;
                }
                break;
        }

        if (!valid) {
            error = 'Please enter a valid postcode';
        }
    }

    setError('postcode', error);

    return error;
}

export function validateState(state: PropertyStateEnum, setError: (key: keyof IDealPropertyErrors, value: string) => void): string {
    let error: string;

    if (!_.values(PropertyStateEnum).includes(state)) {
        error = 'Please select your state';
    }

    setError('state', error);

    return error;
}

export function validateStreetAddress(streetAddress: string, setError: (key: keyof IDealPropertyErrors, value: string) => void): string {
    let error: string;

    if (!streetAddress || streetAddress.length === 0) {
        error = 'Please enter the address';
    } else if (streetAddress.length > 255) {
        error = 'Please limit the address to 255 characters';
    }

    setError('streetAddress', error);

    return error;
}

export function validateUnitNumber(unitNumber: string, setError: (key: keyof IDealPropertyErrors, value: string) => void): string {
    let error: string;

    if (!!unitNumber && unitNumber.length > 10) {
        error = 'Please limit the unit number to 10 characters';
    }

    setError('unitNumber', error);

    return error;
}

export function validateStreetNumber(streetNumber: string, setError: (key: keyof IDealPropertyErrors, value: string) => void): string {
    let error: string;

    if (!streetNumber || streetNumber.length === 0) {
        error = 'Please enter the street number';
    } else if (streetNumber.length > 10) {
        error = 'Please limit the street number to 10 characters';
    }

    setError('streetNumber', error);

    return error;
}

export function validateStreetName(streetName: string, setError: (key: keyof IDealPropertyErrors, value: string) => void): string {
    let error: string;

    if (!streetName || streetName.length === 0) {
        error = 'Please enter the street name';
    } else if (streetName.length > 255) {
        error = 'Please limit the street name to 255 characters';
    }

    setError('streetName', error);

    return error;
}

export function validateStreetType(streetType: PropertyStreetTypeEnum, setError: (key: keyof IDealPropertyErrors, value: string) => void): string {
    let error: string;

    if (!_.values(PropertyStreetTypeEnum).includes(streetType)) {
        error = 'Please select your street type';
    }

    setError('streetType', error);

    return error;
}

export function validateSuburb(suburb: string, setError: (key: keyof IDealPropertyErrors, value: string) => void): string {
    let error: string;

    if (!suburb || suburb.length === 0) {
        error = 'Please enter your suburb';
    } else if (suburb.length > 50) {
        error = 'Please enter a valid suburb';
    }

    setError('suburb', error);

    return error;
}

export function validateZoneType(zoneType: ZoneTypeEnum, setError: (key: keyof IDealPropertyErrors, value: string) => void): string {
    let error: string;

    if (!_.values(ZoneTypeEnum).includes(zoneType)) {
        error = 'Please select your property type';
    }

    setError('zoneType', error);

    return error;
}
