import _ from 'lodash';
import React from 'react';
import { Form, Pagination as BootstrapPagination } from 'react-bootstrap';

interface IProps {
    currentPage: number;
    itemCount: number;
    onChangePerPage?: (perPage: number) => void;
    onClickPage: (page: number) => void;
    perPage: number;
    showChangePerPage?: boolean;
}

class Pagination extends React.Component<IProps> {
    public static defaultProps = {
        showChangePerPage: true,
    };

    constructor(props: IProps) {
        super(props);

        this.onChangePerPage = this.onChangePerPage.bind(this);
    }

    public render(): JSX.Element {
        const { currentPage, itemCount, perPage, showChangePerPage } = this.props;

        const pageCount: number = _.ceil(itemCount / perPage);

        const pages: JSX.Element[] = _.times(pageCount, (index: number) => {
            const onClick = () => this.props.onClickPage(index + 1);

            return (
                <BootstrapPagination.Item active={currentPage === index + 1} key={index} onClick={onClick}>{index + 1}</BootstrapPagination.Item>
            );
        });

        const paginationBlock: JSX.Element = pageCount > 1 && (
            <BootstrapPagination>
                {pages}
            </BootstrapPagination>
        );

        const changePerPageBlock: JSX.Element = showChangePerPage && (
            <Form.Control as='select' className='per-page' onChange={this.onChangePerPage} value={perPage}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
            </Form.Control>
        );

        return (
            <React.Fragment>
                {paginationBlock}
                {changePerPageBlock}
            </React.Fragment>
        );
    }

    private onChangePerPage(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.onChangePerPage(Number(event.target.value));
    }
}

export default Pagination;
