import _ from 'lodash';
import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IDeal, { IDealErrors } from '~Api/Deal/IDeal';
import {
    dealCompleteAction,
    dealErrorSetAction,
    dealGetAction,
    dealValueSetAction,
} from '~Lead/actions';
import {
    dealSelector,
    errorsSelector,
    savingSelector,
} from '~Lead/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';

interface IState {
    comments: string;
}

interface IPropsSelector {
    deal: IDeal;
    errors: IDealErrors;
    saving: boolean;
}

interface IPropsDispatch {
    dealComplete: () => void;
    dealGet: () => void;
    setError: (key: keyof IDealErrors, value: string) => void;
    setValue: (key: keyof IDeal, value: any) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Confirm extends React.Component<Props, IState> {
    public state: IState = {
        comments: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickConfirm = this.onClickConfirm.bind(this);
        this.onChangeComments = this.onChangeComments.bind(this);
    }

    public componentDidMount(): void {
        const { deal } = this.props;

        if (!deal.uuid) {
            this.props.dealGet();
        }
    }

    public render(): JSX.Element {
        const { deal, errors, saving } = this.props;

        if (!deal.uuid) {
            return (
                <Layout currentStep={6}>
                    <h3>Have we missed anything?</h3>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        return (
            <Layout currentStep={6}>
                <h3>Have we missed anything?</h3>
                <p>Now&apos;s your chance to give us any extra info you want.</p>
                <Form>
                    <Form.Group className='comments'>
                        <Form.Label>Comments</Form.Label>
                        <Form.Control disabled={saving} isInvalid={!!errors.comments} onChange={this.onChangeComments} as='textarea' value={deal.comments || ''} />
                    </Form.Group>
                    <div className='actions'>
                        <Link className='previous' to='/get-your-quote/properties'><Button variant='primary'>Previous</Button></Link>
                        <Button className='continue' disabled={saving} onClick={this.onClickConfirm} variant='primary'>
                            {saving && <Spinner animation='border' as='span' role='status' size='sm' />}
                            {!saving && 'Get my Quote! 🎉'}
                        </Button>
                    </div>
                </Form>
            </Layout>
        );
    }

    private onClickConfirm(): void {
        this.props.dealComplete();
    }

    private onChangeComments(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue('comments', event.target.value);
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        deal: dealSelector(state),
        errors: errorsSelector(state),
        saving: savingSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        dealComplete: () => dispatch(dealCompleteAction(true, '/get-your-quote/thanks', '/get-your-quote/quote')),
        dealGet: () => dispatch(dealGetAction()),
        setError: (key: keyof IDealErrors, value: string) => dispatch(dealErrorSetAction(key, value)),
        setValue: (key: keyof IDeal, value: any) => dispatch(dealValueSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Confirm);
