import _ from 'lodash';
import AccountApprovalStatusEnum from './AccountApprovalStatusEnum';
import AccountTypeEnum from './AccountTypeEnum';
import AvailableFundsTypeEnum from './AvailableFundsTypeEnum';
import IAccount from './IAccount';
import IAnnualStatement from './IAnnualStatement';
import IBankAccount from './IBankAccount';
import ICompany from './ICompany';
import IdProofTypeEnum from './IdProofTypeEnum';
import IIncomeTrust from './IIncomeTrust';
import IIndividual from './IIndividual';
import IInvestor from './IInvestor';
import IMonthlyStatement from './IMonthlyStatement';
import IncomeTrustClassTypeEnum from './IncomeTrustClassTypeEnum';
import InterestPaymentStrategyEnum from './InterestPaymentStrategyEnum';
import InvestmentStatusEnum from '../Investment/StatusEnum';
import InvestorTypeEnum from './InvestorTypeEnum';
import IReferral from './IReferral';
import IShare from './IShare';
import ITransaction from './ITransaction';
import ITrust from './ITrust';
import LoanStatusEnum from './LoanStatusEnum';
import ResidentTypeEnum from './ResidentTypeEnum';
import StatusEnum from './StatusEnum';
import TransactionTypeEnum from './TransactionTypeEnum';

export function parseAccount(raw: any): IAccount {
    return {
        accountType: raw.accountType as AccountTypeEnum,
        approvalStatus: raw.approvalStatus as AccountApprovalStatusEnum,
        balance: raw.balance,
        code: raw.code,
        depositAmountPending: raw.depositAmountPending,
        incomeTrust: raw.incomeTrust && parseIncomeTrust(raw.incomeTrust),
        interestAmountEarned: raw.interestAmountEarned,
        investedAmountCurrent: raw.investedAmountCurrent,
        investmentCountTotal: raw.investmentCountTotal,
        isCompleted: raw.isCompleted,
        transferCountTotal: raw.transferCountTotal,
        uuid: raw.uuid,
        withdrawalAmountPending: raw.withdrawalAmountPending,
    };
}

export function parseAnnualStatement(raw: any): IAnnualStatement {
    return {
        investorAccountUuid: raw.investorAccountUuid,
        uuid: raw.uuid,
        year: raw.year,
    };
}

function parseBankAccount(raw: any): IBankAccount {
    return {
        accountName: raw.accountName,
        accountNumber: raw.accountNumber,
        bankName: raw.bankName,
        bsb: raw.bsb,
    };
}

function parseCompany(raw: any): ICompany {
    return {
        australianCompanyNumber: raw.australianCompanyNumber,
        name: raw.name,
        postcode: raw.postcode,
        state: raw.state,
        streetAddress: raw.streetAddress,
        suburb: raw.suburb,
        taxFileNumber: raw.taxFileNumber,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseIncomeTrust(raw: any): IIncomeTrust {
    return {
        classType: raw.class as IncomeTrustClassTypeEnum,
        interestBalance: raw.interestBalance,
        interestPaymentStrategy: raw.interestPaymentStrategy as InterestPaymentStrategyEnum,
        interestRate: raw.interestRate,
        principalBalance: raw.principalBalance,
        uuid: raw.uuid,
    };
}

function parseIndividual(raw: any): IIndividual {
    let idProofType: IdProofTypeEnum = null;
    switch (raw.idProofType) {
        case 'DRIVER_LICENCE':
        case 'DRIVER_LICENSE':
            idProofType = IdProofTypeEnum.DriverLicence;
            break;
        case 'PASSPORT':
            idProofType = IdProofTypeEnum.Passport;
            break;
    }

    return {
        country: raw.country,
        dateOfBirth: raw.dateOfBirth,
        driverLicenceCardNumber: raw.driverLicenceCardNumber,
        driverLicenceState: raw.driverLicenceState,
        email: raw.email,
        firstName: raw.firstName,
        idProofExpiryDate: raw.idProofExpiryDate,
        idProofNumber: raw.idProofNumber,
        idProofType,
        lastName: raw.lastName,
        postcode: raw.postcode,
        residentType: raw.residentType as ResidentTypeEnum,
        state: raw.state,
        streetAddress: raw.streetAddress,
        suburb: raw.suburb,
        taxFileNumber: raw.taxFileNumber,
    };
}

export function parseInvestor(raw: any): IInvestor {
    const accounts: { [key in AccountTypeEnum]?: IAccount } = {};
    _.each(raw.accounts, (rawAccount: any): void => {
        const account: IAccount = parseAccount(rawAccount);
        accounts[account.accountType] = account;
    });

    let bankAccount: IBankAccount = _.find(raw.bankAccounts, { uuid: raw.primaryBankAccountUuid });
    if (!bankAccount) {
        bankAccount = raw.bankAccounts[0];
    }

    return {
        accounts,
        availableFundsType: raw.availableFundsType as AvailableFundsTypeEnum,
        bankAccount: bankAccount && parseBankAccount(bankAccount),
        company: raw.company && parseCompany(raw.company),
        individuals: raw.individuals.map(parseIndividual),
        isCompleted: raw.isCompleted,
        landingPage: raw.landingPage,
        name: raw.name,
        referralCode: raw.referralCode,
        trust: raw.trust && parseTrust(raw.trust),
        type: raw.type as InvestorTypeEnum,
        uuid: raw.uuid,
    };
}

export function parseMonthlyStatement(raw: any): IMonthlyStatement {
    return {
        investorAccountUuid: raw.investorAccountUuid,
        month: raw.month,
        uuid: raw.uuid,
        year: raw.year,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseReferral(raw: any): IReferral {
    return {
        createdTime: raw.createdTime,
        investorRewardAmount: raw.investorRewardAmount,
        isInvestorProcessed: raw.isInvestorProcessed,
        referralEmailAddress: raw.referralEmailAddress,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseShare(raw: any): IShare {
    return {
        amountInvested: raw.amountInvested,
        amountRemaining: raw.amountRemaining,
        createdTime: raw.createdTime,
        endDate: raw.endDate,
        interestEarned: raw.interestEarned,
        interestRate: raw.interestRate,
        investmentName: raw.investmentName,
        investmentStatus: raw.investmentStatus as InvestmentStatusEnum,
        investmentUuid: raw.investmentUuid,
        loanId: raw.loanId,
        loanName: raw.loanName,
        loanStatus: raw.loanStatus as LoanStatusEnum,
        name: raw.name,
        startDate: raw.startDate,
        status: raw.status as StatusEnum,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseTransaction(raw: any): ITransaction {
    return {
        activityDate: raw.activityDate,
        amount: raw.amount,
        balance: raw.balance,
        createdTime: raw.createdTime,
        description: raw.description,
        type: raw.type as TransactionTypeEnum,
        uuid: raw.uuid,
    };
}

export function parseTrust(raw: any): ITrust {
    return {
        australianBusinessNumber: raw.australianBusinessNumber,
        name: raw.name,
        taxFileNumber: raw.taxFileNumber,
    };
}
