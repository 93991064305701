import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReferFriend from '~UI/ReferFriend';

class ReferFriendBox extends React.Component {
    public render(): JSX.Element {
        return (
            <div className='content-box refer-a-friend'>
                <ReferFriend />
                <h2>Refer a Friend</h2>
                <p>Earn $150 for every friend you invite to join Funding. You will receive $150 in your account once they sign up and invest and they will too. Terms and conditions apply.</p>
                <Link to='/marketplace/refer-a-friend'><Button className='refer'>Refer your friends</Button></Link>
            </div>
        );
    }
}

export default ReferFriendBox;
