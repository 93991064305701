import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ApplicationsTable, { FilterEnum } from './ApplicationsTable';
import DealsTable from './DealsTable';
import Layout from './Layout';

class Applications extends React.Component {
    public render(): JSX.Element {
        return (
            <Layout section='applications'>
                <h1>Applications</h1>
                <div className='main-box applications'>
                    <Link to='/applications/submit' className='submit-button'>
                        <Button>Submit a Deal</Button>
                    </Link>
                    <h2>My Applications</h2>
                </div>
                <DealsTable />
                <div className='main-box pending-applications'>
                    <h2>Applications</h2>
                    <ApplicationsTable filter={FilterEnum.Pending} />
                </div>
                <div className='main-box settled-applications'>
                    <h2>Settled</h2>
                    <ApplicationsTable filter={FilterEnum.Settled} />
                </div>
            </Layout>
        );
    }
}

export default Applications;
