import React from 'react';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import { authRegisterErrorSetAction, authRegisterInvestorAction } from '~Auth/actions';
import { authRegisterErrorsSelector, authRegisterInProgressSelector } from '~Auth/selectors';
import { IGlobalState } from '~reducer';
import Checkbox from '~UI/Checkbox';
import { validateEmail } from '~validators';
import Layout from './Layout';
import Steps from './Steps';

interface IState {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    phone: string;
    termsOfUse: boolean;
}

interface IPropsSelector {
    registerErrors: any;
    registerInProgress: boolean;
}

interface IPropsDispatch {
    register: (email: string, firstName: string, lastName: string, password: string, phone: string) => void;
    setError: (key: string, value: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class AccountInformation extends React.Component<Props, IState> {
    public state: IState = {
        email: null,
        firstName: null,
        lastName: null,
        password: null,
        phone: null,
        termsOfUse: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickRegister = this.onClickRegister.bind(this);

        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeTermsOfUse = this.onChangeTermsOfUse.bind(this);

        this.validateFirstName = this.validateFirstName.bind(this);
        this.validateLastName = this.validateLastName.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
    }

    public render(): JSX.Element {
        const { registerErrors, registerInProgress } = this.props;
        const {
            email,
            firstName,
            lastName,
            password,
            phone,
            termsOfUse,
        } = this.state;

        return (
            <Layout>
                <Steps currentStep={1} />

                <Container className='register'>
                    <h3>Account Information</h3>
                    <p className='intro'>Let's get started.</p>
                    <Form>
                        <Form.Group className='first-name'>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.firstName} onBlur={this.validateFirstName} onChange={this.onChangeFirstName} type='text' value={firstName || ''} />
                            {registerErrors.firstName && <Form.Control.Feedback type='invalid'>{registerErrors.firstName}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='last-name'>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.lastName} onBlur={this.validateLastName} onChange={this.onChangeLastName} type='text' value={lastName || ''} />
                            {registerErrors.lastName && <Form.Control.Feedback type='invalid'>{registerErrors.lastName}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.phone} name='phone' onBlur={this.validatePhone} onChange={this.onChangePhone} type='text' value={phone || ''} />
                            {registerErrors.phone && <Form.Control.Feedback type='invalid'>{registerErrors.phone}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.email} name='email' onBlur={this.validateEmail} onChange={this.onChangeEmail} type='email' value={email || ''} />
                            {registerErrors.email && <Form.Control.Feedback type='invalid'>{registerErrors.email}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.password} name='password' onBlur={this.validatePassword} onChange={this.onChangePassword} type='password' value={password || ''} />
                            {registerErrors.password && <Form.Control.Feedback type='invalid'>{registerErrors.password}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group>
                            <Checkbox
                                checked={termsOfUse}
                                label={<React.Fragment>I/We agree to funding's <a href='https://www.funding.com.au/terms-of-use/' target='_blank'>Terms of Use</a>.</React.Fragment>}
                                onChange={this.onChangeTermsOfUse}
                            />
                        </Form.Group>
                        <Button className='continue' disabled={!termsOfUse || registerInProgress} onClick={this.onClickRegister} variant='primary'>
                            {registerInProgress && <Spinner animation='border' as='span' role='status' size='sm' />}
                            {!registerInProgress && 'Continue'}
                        </Button>
                    </Form>
                </Container>
            </Layout>
        );
    }

    private onClickRegister() {
        const { email, firstName, lastName, password, phone } = this.state;

        let valid = true;
        valid = this.validateFirstName() && valid;
        valid = this.validateLastName() && valid;
        valid = this.validatePhone() && valid;
        valid = this.validateEmail() && valid;
        valid = this.validatePassword() && valid;

        if (!valid) {
            return;
        }

        this.props.register(email, firstName, lastName, password, phone);
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            firstName: event.target.value,
        });
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            lastName: event.target.value,
        });
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            email: event.target.value,
        });
    }

    private onChangePhone(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            phone: event.target.value,
        });
    }

    private onChangePassword(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            password: event.target.value,
        });
    }

    private onChangeTermsOfUse(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            termsOfUse: event.currentTarget.checked,
        });
    }

    private validateFirstName(): boolean {
        const { firstName } = this.state;

        let error: string;

        if (!firstName) {
            error = 'Please enter your first name';
        } else if (firstName.length < 2) {
            error = 'Please enter your first name';
        }

        this.props.setError('firstName', error);

        return !error;
    }

    private validateLastName(): boolean {
        const { lastName } = this.state;

        let error: string;

        if (!lastName) {
            error = 'Please enter your last name';
        } else if (lastName.length < 2) {
            error = 'Please enter your last name';
        }

        this.props.setError('lastName', error);

        return !error;
    }

    private validatePhone(): boolean {
        const { phone } = this.state;

        let error: string;

        if (!phone || phone.length  === 0) {
            error = 'Please enter your phone number';
        }

        this.props.setError('phone', error);

        return !error;
    }

    private validateEmail(): boolean {
        const { email } = this.state;

        let error: string;
        if (!email || email.length === 0) {
            error = 'Please enter your email address';
        } else if (!validateEmail(email)) {
            error = 'Please enter a valid email address';
        }
        this.props.setError('email', error);

        return !error;
    }

    private validatePassword(): boolean {
        const { password } = this.state;

        let error: string;

        if (!password || password.length === 0) {
            error = 'Please enter a password';
        } else if (password.length < 6) {
            error = 'Please enter at least 6 characters';
        }

        this.props.setError('password', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        registerErrors: authRegisterErrorsSelector(state),
        registerInProgress: authRegisterInProgressSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        register: (email: string, firstName: string, lastName: string, password: string, phone: string) => dispatch(authRegisterInvestorAction(AccountTypeEnum.Marketplace, email, firstName, lastName, password, phone, '/invest/marketplace/details')),
        setError: (key: string, value: string) => dispatch(authRegisterErrorSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountInformation);
