import React, { ReactElement } from 'react';
import Layout from './Layout';
import './team.less';

export default function Team(): ReactElement {
    return (
        <Layout section='team'>
            <h1>Team</h1>
            <div className='team-main'>
                <div className='team-box'>
                    <h2>About Us</h2>
                    <p>Funding offers fast, simple Bridging, Business and Building loans secured by property. We are based in Brisbane and on the Gold Coast with dedicated Business Development Managers and direct access to our Credit Team.</p>
                    <p>Funding can lend for business and personal use, including NCCP and non-NCCP applications with loan terms up to 24 months. We offer quick quotes, online application lodgement and up to 1% commission with no clawbacks.</p>
                </div>
                <div className='team-box'>
                    <h2>Broker Team</h2>
                    <div className='members'>
                        <div className='member'>
                            <img src='https://www.funding.com.au/headshots/chris-maamoun/' />
                            <p className='name'>Chris Maamoun</p>
                            <p className='title'>National Sales &amp; Partnership Lead</p>
                            <p className='phone'><a href='tel:0488931009'>0488 931 009</a></p>
                            <p className='email'><a href='mailto:chris@funding.com.au'>chris@funding.com.au</a></p>
                        </div>
                        <div className='member'>
                            <img src='https://www.funding.com.au/headshots/andrew-crossley/' />
                            <p className='name'>Andrew Crossley</p>
                            <p className='title'>National BDM - VIC, SA &amp; TAS</p>
                            <p className='phone'><a href='tel:0480319850'>0480 319 850</a></p>
                            <p className='email'><a href='mailto:andrew@funding.com.au'>andrew@funding.com.au</a></p>
                        </div>
                        <div className='member'>
                            <img src='https://www.funding.com.au/headshots/lauren-severino' />
                            <p className='name'>Lauren Severino</p>
                            <p className='title'>National BDM - NSW</p>
                            <p className='phone'><a href='tel:0405986264'>0405 986 264</a></p>
                            <p className='email'><a href='mailto:lauren.severino@funding.com.au'>lauren.severino@funding.com.au</a></p>
                        </div>
                        <div className='member'>
                            <img src='https://www.funding.com.au/headshots/sam-hermon' />
                            <p className='name'>Sam Hermon</p>
                            <p className='title'>National BDM - NSW</p>
                            <p className='phone'><a href='tel:0449620917'>0449 620 917</a></p>
                            <p className='email'><a href='mailto:sam.hermon@funding.com.au'>sam.hermon@funding.com.au</a></p>
                        </div>
                    </div>
                </div>
                <div className='team-box'>
                    <h2>Credit Team</h2>
                    <div className='members'>
                        <div className='member'>
                            <img src='https://www.funding.com.au/headshots/lauren-alexander/' />
                            <p className='name'>Lauren Alexander</p>
                            <p className='title'>Loans Officer</p>
                            <p className='phone'><a href='tel:(07)56466336'>(07) 5646 6336</a></p>
                            <p className='email'><a href='mailto:lauren@funding.com.au'>lauren@funding.com.au</a></p>
                        </div>
                        <div className='member'>
                            <img src='https://www.funding.com.au/headshots/john-bryant/' />
                            <p className='name'>John Bryant</p>
                            <p className='title'>Loans Officer</p>
                            <p className='phone'><a href='tel:(07)56463548'>(07) 5646 3548</a></p>
                            <p className='email'><a href='mailto:john@funding.com.au'>john@funding.com.au</a></p>
                        </div>
                    </div>
                </div>
                <div className='team-box'>
                    <h2>Broker Support Team</h2>
                    <div className='support-team'>
                        <p>Scenarios & Support <a href='tel:1300443319'>1300 44 33 19</a></p>
                        <p>Credit & Supporting Documents <a href='mailto:loanservices@funding.com.au'>loanservices@funding.com.au</a></p>
                        <p>Applications <a href='mailto:applications@funding.com.au'>applications@funding.com.au</a></p>
                        <p>Brokers & Accreditation <a href='mailto:broker@funding.com.au'>broker@funding.com.au</a></p>
                        <p>Weekdays 8.30am-5pm EST</p>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
