import _ from 'lodash';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IBroker from '~Api/Broker/IBroker';
import { IGlobalState } from '~reducer';
import CustomerForms from '~UI/CustomerForms';
import RegularIncome from '~UI/RegularIncome';
import { currentUserGetAction } from '~User/actions';
import IUser from '~User/IUser';
import { currentUserSelector } from '~User/selectors';
import { currentBrokerGetAction } from './actions';
import ApplicationsTable, { FilterEnum } from './ApplicationsTable';
import './dashboard.less';
import DealsTable from './DealsTable';
import Layout from './Layout';
import PerformanceSnapshotBox from './PerformanceSnapshotBox';
import { currentBrokerSelector } from './selectors';

interface IPropsSelector {
    currentBroker: IBroker;
    currentUser: IUser;
}

interface IPropsDispatch {
    currentBrokerGet: () => void;
    currentUserGet: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Dashboard extends React.Component<Props> {
    public componentDidMount() {
        const { currentBroker, currentUser } = this.props;

        if (!currentBroker) {
            this.props.currentBrokerGet();
        }

        if (!currentUser) {
            this.props.currentUserGet();
        }
    }

    public render(): JSX.Element {
        const { currentBroker, currentUser } = this.props;

        if (!currentBroker || !currentUser) {
            return (
                <Layout section='dashboard'>
                    <h1>Dashboard</h1>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        return (
            <Layout section='dashboard'>
                <h1>Welcome to Funding</h1>
                <PerformanceSnapshotBox />
                <div className='broker-actions'>
                    <div className='action-boxes'>
                        <div className='action-box product-guide'>
                            <CustomerForms />
                            <div className='primary'>
                                <div className='label'>Product & Tools</div>
                                <div className='detail'>Product Guides, Resources, Broker Tools & Customer documents for Funding Loan applications and Customer support.</div>
                            </div>
                            <Link to='/product-and-tools' className='action-button'>
                                <Button>Browse Tools</Button>
                            </Link>
                        </div>
                        <div className='action-box get-quote'>
                            <RegularIncome/>
                            <div className='primary'>
                                <div className='label'>Submit Application</div>
                                <div className='detail'>Submit your Broker Loan Application and track the progress of your pending Applications.</div>
                            </div>
                            <Link to='applications/submit' className='action-button'>
                                <Button>Submit a Deal</Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <DealsTable />
                <div className='main-box pending-applications'>
                    <h2>Applications</h2>
                    <ApplicationsTable filter={FilterEnum.Pending} />
                </div>
                <div className='main-box settled-applications'>
                    <h2>Settled</h2>
                    <ApplicationsTable filter={FilterEnum.Settled} />
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentBroker: currentBrokerSelector(state),
        currentUser: currentUserSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentBrokerGet: () => dispatch(currentBrokerGetAction()),
        currentUserGet: () => dispatch(currentUserGetAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Dashboard);
