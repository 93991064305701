enum InvestorActionsEnum {
    AnnualStatementsList = 'INVESTOR/ANNUAL_STATEMENTS/LIST',
    AnnualStatementsSet = 'INVESTOR/ANNUAL_STATEMENTS/SET',

    CompleteSignupModalShowSet = 'INVESTOR/COMPLETE_SIGNUP_MODAL/SHOW/SET',

    CurrentInvestorGet = 'INVESTOR/CURRENT_INVESTOR/GET',
    CurrentInvestorSend = 'INVESTOR/CURRENT_INVESTOR/SEND',
    CurrentInvestorSet = 'INVESTOR/CURRENT_INVESTOR/SET',

    CurrentInvestorValueSet = 'INVESTOR/CURRENT_INVESTOR/VALUE/SET',

    CurrentInvestorBankAccountValueSet = 'INVESTOR/CURRENT_INVESTOR/BANK_ACCOUNT/VALUE/SET',
    CurrentInvestorCompanyValueSet = 'INVESTOR/CURRENT_INVESTOR/COMPANY/VALUE/SET',
    CurrentInvestorIndividualValueSet = 'INVESTOR/CURRENT_INVESTOR/INDIVIDUAL/VALUE/SET',
    CurrentInvestorTrustValueSet = 'INVESTOR/CURRENT_INVESTOR/TRUST/VALUE/SET',

    CurrentInvestorAccountIncomeTrustSet = 'INVESTOR/CURRENT_INVESTOR/ACCOUNT/INCOME_TRUST/SET',
    CurrentInvestorAccountIncomeTrustInterestPaymentStrategySet = 'INVESTOR/CURRENT_INVESTOR/ACCOUNT/INCOME_TRUST/INTEREST_PAYMENT_STRATEGY/SET',

    CurrentInvestorAccountMarketplaceSet = 'INVESTOR/CURRENT_INVESTOR/ACCOUNT/MARKETPLACE/SET',

    IncomeTrustInvest = 'INVESTOR/INCOME_TRUST/INVEST',
    IncomeTrustInvestCompleteModalShowSet = 'INVESTOR/INCOME_TRUST/INVEST/COMPLETE_MODAL_SHOW/SET',
    IncomeTrustInvestConfirmModalShowSet = 'INVESTOR/INCOME_TRUST/INVEST/CONFIRM_MODAL_SHOW/SET',
    IncomeTrustInvestErrorSet = 'INVESTOR/INCOME_TRUST/INVEST/ERROR/SET',
    IncomeTrustInvestErrorsSet = 'INVESTOR/INCOME_TRUST/INVEST/ERRORS/SET',
    IncomeTrustInvestInProgressSet = 'INVESTOR/INCOME_TRUST/INVEST/IN_PROGRESS/SET',

    IncomeTrustsList = 'INVESTOR/INCOME_TRUSTS/LIST',
    IncomeTrustsSet = 'INVESTOR/INCOME_TRUSTS/SET',

    InvestmentGet = 'INVESTOR/INVESTMENT/GET',
    InvestmentInvest = 'INVESTOR/INVESTMENT/INVEST',
    InvestmentSet = 'INVESTOR/INVESTMENT/SET',

    InvestmentInvestAmountSet = 'INVESTOR/INVESTMENT/INVEST/AMOUNT/SET',

    InvestmentInvestCompleteModalShowSet = 'INVESTOR/INVESTMENT/INVEST/COMPLETE_MODAL_SHOW/SET',

    InvestmentInvestErrorSet = 'INVESTOR/INVESTMENT/INVEST/ERROR/SET',

    InvestmentInvestErrorsSet = 'INVESTOR/INVESTMENT/INVEST/ERRORS/SET',

    InvestmentInvestInProgressSet = 'INVESTOR/INVESTMENT/INVEST/IN_PROGRESS/SET',

    InvestmentInvestModalShowSet = 'INVESTOR/INVESTMENT/INVEST/MODAL_SHOW/SET',

    InvestmentsAvailableList = 'INVESTOR/INVESTMENTS/AVAILABLE/LIST',
    InvestmentsAvailableSet = 'INVESTOR/INVESTMENTS/AVAILABLE/SET',
    InvestmentsRecentlyFundedList = 'INVESTOR/INVESTMENTS/RECENTLY_FUNDED/LIST',
    InvestmentsRecentlyFundedSet = 'INVESTOR/INVESTMENTS/RECENTLY_FUNDED/SET',

    IncomeTrustAccountComplete = 'INVESTOR/INCOME_TRUST_ACCOUNT/COMPLETE',
    MarketplaceAccountComplete = 'INVESTOR/MARKETPLACE_ACCOUNT/COMPLETE',

    MonthlyStatementsList = 'INVESTOR/MONTHLY_STATEMENTS/LIST',
    MonthlyStatementsSet = 'INVESTOR/MONTHLY_STATEMENTS/SET',

    ReferralAdd = 'INVESTOR/REFERRAL/ADD',
    ReferralAddErrorSet = 'INVESTOR/REFERRAL/ADD/ERROR/SET',
    ReferralAddErrorsSet = 'INVESTOR/REFERRAL/ADD/ERRORS/SET',
    ReferralAddInProgressSet = 'INVESTOR/REFERRAL/ADD/IN_PROGRESS/SET',
    ReferralEmailSet = 'INVESTOR/REFERRAL/EMAIL/SET',
    ReferralSet = 'INVESTOR/REFERRAL/SET',

    ReferralsList = 'INVESTOR/REFERRALS/LIST',
    ReferralsSet = 'INVESTOR/REFERRALS/SET',

    RegisterErrorSet = 'INVESTOR/REGISTER/ERROR/SET',
    RegisterErrorsSet = 'INVESTOR/REGISTER/ERRORS/SET',
    RegisterInProgressSet = 'INVESTOR/REGISTER/IN_PROGRESS/SET',

    SharesList = 'INVESTOR/SHARES/LIST',
    SharesSet = 'INVESTOR/SHARES/SET',

    TopUp = 'INVESTOR/TOP_UP',
    TopUpCompleteModalHide = 'INVESTOR/TOP_UP/COMPLETE_MODAL/HIDE',
    TopUpCompleteModalShow = 'INVESTOR/TOP_UP/COMPLETE_MODAL/SHOW',
    TopUpConfirmModalHide = 'INVESTOR/TOP_UP/CONFIRM_MODAL/HIDE',
    TopUpConfirmModalShow = 'INVESTOR/TOP_UP/CONFIRM_MODAL/SHOW',
    TopUpErrorSet = 'INVESTOR/TOP_UP/ERROR/SET',
    TopUpErrorsSet = 'INVESTOR/TOP_UP/ERRORS/SET',
    TopUpInProgressSet = 'INVESTOR/TOP_UP/IN_PROGRESS/SET',
    TopUpModalHide = 'INVESTOR/TOP_UP/MODAL/HIDE',
    TopUpModalShow = 'INVESTOR/TOP_UP/MODAL/SHOW',

    TransactionsList = 'INVESTOR/TRANSACTIONS/LIST',
    TransactionsSet = 'INVESTOR/TRANSACTIONS/SET',

    Withdraw = 'INVESTOR/WITHDRAW',
    WithdrawCompleteModalHide = 'INVESTOR/WITHDRAW/COMPLETE_MODAL/HIDE',
    WithdrawCompleteModalShow = 'INVESTOR/WITHDRAW/COMPLETE_MODAL/SHOW',
    WithdrawConfirmModalHide = 'INVESTOR/WITHDRAW/CONFIRM_MODAL/HIDE',
    WithdrawConfirmModalShow = 'INVESTOR/WITHDRAW/CONFIRM_MODAL/SHOW',
    WithdrawErrorSet = 'INVESTOR/WITHDRAW/ERROR/SET',
    WithdrawErrorsSet = 'INVESTOR/WITHDRAW/ERRORS/SET',
    WithdrawInProgressSet = 'INVESTOR/WITHDRAW/IN_PROGRESS/SET',
    WithdrawModalHide = 'INVESTOR/WITHDRAW/MODAL/HIDE',
    WithdrawModalShow = 'INVESTOR/WITHDRAW/MODAL/SHOW',
}

export default InvestorActionsEnum;
