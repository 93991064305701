import numeral from 'numeral';
import React from 'react';
import { Nav, NavDropdown, Spinner } from 'react-bootstrap';
import { AiOutlinePercentage, AiOutlineQuestionCircle } from 'react-icons/ai';
import { BiBarChartSquare, BiBriefcase, BiCheckShield, BiDetail, BiNews, BiRightArrowAlt, BiSync } from 'react-icons/bi';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import { currentInvestorGetAction } from '~Investor/actions';
import '~Investor/investor.less';
import InvestorLayout from '~Investor/Layout';
import { currentInvestorAccountSelector, currentInvestorSelector } from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import Footer from './Footer';

interface IProps {
    children: JSX.Element | JSX.Element[];
    section: string;
}

interface IPropsSelector {
    currentInvestor: IInvestor;
    incomeTrustAccount: IAccount;
    marketplaceAccount: IAccount;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Layout extends React.Component<Props> {
    private currentInvestorInterval: any;

    public componentDidMount(): void {
        const { currentInvestor } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }

        this.currentInvestorInterval = setInterval(() => {
            this.props.currentInvestorGet();
        }, 5 * 60 * 1000);
    }

    public componentWillUnmount(): void {
        clearInterval(this.currentInvestorInterval);
    }

    public render(): JSX.Element {
        const { currentInvestor, incomeTrustAccount, marketplaceAccount, section } = this.props;

        if (!currentInvestor) {
            return (
                <div className='investor'>
                    <Spinner animation='border' />
                </div>
            );
        }

        const authNavMenuOptions: JSX.Element = (
            <React.Fragment>
                <NavDropdown.Item as={NavLink} className='help-centre' to='/marketplace/help-centre'>Help Centre <AiOutlineQuestionCircle /></NavDropdown.Item>
                <NavDropdown.Item as={NavLink} className='refer-friend' to='/marketplace/refer-a-friend'>Refer a Friend 💰</NavDropdown.Item>
            </React.Fragment>
        );

        const incomeTrustNavItem: JSX.Element = incomeTrustAccount && (
            <Nav.Item as={NavLink} className='income-trust-account' to='/income-trust'><AiOutlinePercentage />Income Trust<BiRightArrowAlt className='arrow-right' /></Nav.Item>
        );

        const leftNav: JSX.Element = (
            <React.Fragment>
                <div className='profile'>
                    <div className='name'>{currentInvestor.name}</div>
                    <div className='portfolio-value'>
                        <div className='label'>Portfolio Value</div>
                        <div className='value'>{numeral(marketplaceAccount.balance + marketplaceAccount.investedAmountCurrent).format('$0,0[.]00')}</div>
                    </div>
                    <div className='cash-balance'>
                        <div className='label'>Cash Balance</div>
                        <div className='value'>{numeral(marketplaceAccount.balance).format('$0,0[.]00')}</div>
                    </div>
                    <div className='account-id'>
                        <div className='label'>Account Number</div>
                        <div className='value'>{marketplaceAccount.code}</div>
                    </div>
                </div>
                <Nav activeKey={section} className='flex-column'>
                    <Nav.Item as={NavLink} exact={true} to='/marketplace'><BiBarChartSquare />Marketplace</Nav.Item>
                    <Nav.Item as={NavLink} to='/marketplace/top-up-withdraw'><BiSync />Top Up &amp; Withdraw</Nav.Item>
                    <Nav.Item as={NavLink} to='/marketplace/portfolio'><BiBriefcase />My Portfolio</Nav.Item>
                    <Nav.Item as={NavLink} to='/marketplace/transactions'><BiDetail />Transactions</Nav.Item>
                    <Nav.Item as={NavLink} to='/marketplace/statements'><BiNews />Statements</Nav.Item>
                    <Nav.Item as={NavLink} to='/marketplace/disclosure-documents'><BiCheckShield />Disclosure Documents</Nav.Item>
                    {incomeTrustNavItem}
                    <NavLink className='refer-friend' to='/marketplace/refer-a-friend'>Refer a Friend 💰</NavLink>
                </Nav>
            </React.Fragment>
        );

        return (
            <InvestorLayout
                section={section}
                footer={<Footer />}
                authNavMenuOptions={authNavMenuOptions}
                leftNav={leftNav}
            >
                {this.props.children}
            </InvestorLayout >
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        incomeTrustAccount: currentInvestorAccountSelector(state, AccountTypeEnum.IncomeTrust),
        marketplaceAccount: currentInvestorAccountSelector(state, AccountTypeEnum.Marketplace),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Layout);
