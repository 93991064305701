import React from 'react';

class Withdraw extends React.Component {
    public render(): JSX.Element {
        return (
            <svg width='90' height='90' viewBox='0 0 90 90' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M45 90C69.8528 90 90 69.8528 90 45C90 20.1472 69.8528 0 45 0C20.1472 0 0 20.1472 0 45C0 69.8528 20.1472 90 45 90Z' fill='#E9F6F6'/>
                <path d='M7 61L75 61' stroke='#16254C' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
                <rect x='17' y='21' width='42' height='41' fill='white'/>
                <path d='M62 64.5C62 65.8158 51.2966 67 37.9951 67C24.6937 67 14 65.8816 14 64.5C14 63.1184 24.7034 62 37.9951 62C51.2868 62 62 63.1842 62 64.5Z' fill='#91D1D2'/>
                <path d='M68 76.5C68 77.2895 63.5402 78 57.998 78C52.4557 78 48 77.3289 48 76.5C48 75.6711 52.4598 75 57.998 75C63.5362 75 68 75.7105 68 76.5Z' fill='#91D1D2'/>
                <circle cx='58.5' cy='61.5' r='13.5' fill='white'/>
                <path d='M44.0468 64.1473H19.4502C16.9089 64.1473 14.9121 62.1567 14.9121 59.6231V23.4299C14.9121 20.8964 16.9089 18.9058 19.4502 18.9058H55.7552C58.2965 18.9058 60.2933 20.8964 60.2933 23.4299V47.9508H57.7237V23.4299C57.7237 21.8917 57.2981 21.369 55.7552 21.369H19.2684C17.7254 21.369 17.3693 21.8917 17.3693 23.4299V59.6231C17.3693 61.1614 18.2002 61.7234 19.7431 61.7234H44.0468V64.1473Z' fill='#16254C'/>
                <path d='M59.3857 30.4877H15.8198V32.2973H59.3857V30.4877Z' fill='#16254C'/>
                <path d='M26.3482 28.5874C24.7145 28.5874 23.353 27.2301 23.353 25.6014C23.353 23.9727 24.7145 22.6155 26.3482 22.6155C27.9819 22.6155 29.3433 23.9727 29.3433 25.6014C29.3433 27.2301 27.9819 28.5874 26.3482 28.5874ZM26.3482 24.4251C25.7128 24.4251 25.1683 24.968 25.1683 25.6014C25.1683 26.2348 25.7128 26.7777 26.3482 26.7777C26.9835 26.7777 27.5281 26.2348 27.5281 25.6014C27.5281 24.968 26.9835 24.4251 26.3482 24.4251Z' fill='#91D1D2'/>
                <path d='M48.8572 28.5874C47.2235 28.5874 45.8621 27.2301 45.8621 25.6014C45.8621 23.9727 47.2235 22.6155 48.8572 22.6155C50.4909 22.6155 51.8524 23.9727 51.8524 25.6014C51.8524 27.2301 50.4909 28.5874 48.8572 28.5874ZM48.8572 24.4251C48.2219 24.4251 47.6773 24.968 47.6773 25.6014C47.6773 26.2348 48.2219 26.7777 48.8572 26.7777C49.4926 26.7777 50.0371 26.2348 50.0371 25.6014C50.0371 24.968 49.4926 24.4251 48.8572 24.4251Z' fill='#91D1D2'/>
                <path d='M58.0241 70.2096C55.4828 70.2096 53.3953 68.1284 53.3953 65.5949C53.3953 65.052 53.7583 64.6901 54.3029 64.6901C54.8475 64.6901 55.2105 65.052 55.2105 65.5949C55.2105 67.1331 56.4812 68.3999 58.0241 68.3999C59.5671 68.3999 60.8378 67.1331 60.8378 65.5949C60.8378 64.0567 59.5671 62.7899 58.0241 62.7899C55.4828 62.7899 53.3953 60.7088 53.3953 58.1753C53.3953 55.6418 55.4828 53.5607 58.0241 53.5607C60.5655 53.5607 62.653 55.6418 62.653 58.1753C62.653 58.7182 62.29 59.0801 61.7454 59.0801C61.2008 59.0801 60.8378 58.7182 60.8378 58.1753C60.8378 56.6371 59.5671 55.3703 58.0241 55.3703C56.4812 55.3703 55.2105 56.6371 55.2105 58.1753C55.2105 59.7135 56.4812 60.9803 58.0241 60.9803C60.5655 60.9803 62.653 63.0614 62.653 65.5949C62.653 68.1284 60.5655 70.2096 58.0241 70.2096Z' fill='#16254C'/>
                <path d='M58.0238 55.3704C57.4793 55.3704 57.1162 55.0085 57.1162 54.4656V53.0179C57.1162 52.475 57.4793 52.113 58.0238 52.113C58.5684 52.113 58.9315 52.475 58.9315 53.0179V54.4656C58.9315 55.0085 58.5684 55.3704 58.0238 55.3704Z' fill='#16254C'/>
                <path d='M58.0238 71.6575C57.4793 71.6575 57.1162 71.2956 57.1162 70.7527V69.305C57.1162 68.7621 57.4793 68.4001 58.0238 68.4001C58.5684 68.4001 58.9315 68.7621 58.9315 69.305V70.7527C58.9315 71.2051 58.5684 71.6575 58.0238 71.6575Z' fill='#16254C'/>
                <path d='M58.0246 76.815C49.7652 76.815 43.0488 70.1192 43.0488 61.8853C43.0488 53.6513 49.7652 46.9556 58.0246 46.9556C66.284 46.9556 73.0004 53.6513 73.0004 61.8853C73.0004 70.1192 66.284 76.815 58.0246 76.815ZM58.0246 49.3797C50.7636 49.3797 45.38 54.6466 45.38 61.8853C45.38 69.1239 50.7636 74.5418 58.0246 74.5418C65.2856 74.5418 70.5421 69.1239 70.5421 61.8853C70.5421 54.6466 65.2856 49.3797 58.0246 49.3797Z' fill='#16254C'/>
                <path d='M28.4337 41.979H22.9879C22.4434 41.979 22.0803 41.6171 22.0803 41.0742V35.6452C22.0803 35.1023 22.4434 34.7404 22.9879 34.7404H28.4337C28.9783 34.7404 29.3413 35.1023 29.3413 35.6452V41.0742C29.3413 41.6171 28.9783 41.979 28.4337 41.979ZM23.8956 40.1693H27.5261V36.55H23.8956V40.1693Z' fill='#16254C'/>
                <path d='M40.233 41.979H34.7873C34.2427 41.979 33.8796 41.6171 33.8796 41.0742V35.6452C33.8796 35.1023 34.2427 34.7404 34.7873 34.7404H40.233C40.7776 34.7404 41.1406 35.1023 41.1406 35.6452V41.0742C41.1406 41.6171 40.7776 41.979 40.233 41.979ZM35.6949 40.1693H39.3254V36.55H35.6949V40.1693Z' fill='#16254C'/>
                <path d='M52.0326 41.979H46.5868C46.0422 41.979 45.6792 41.6171 45.6792 41.0742V35.6452C45.6792 35.1023 46.0422 34.7404 46.5868 34.7404H52.0326C52.5771 34.7404 52.9402 35.1023 52.9402 35.6452V41.0742C52.9402 41.6171 52.5771 41.979 52.0326 41.979ZM47.4944 40.1693H51.1249V36.55H47.4944V40.1693Z' fill='#16254C'/>
                <path d='M28.4337 51.0272H22.9879C22.4434 51.0272 22.0803 50.6653 22.0803 50.1224V44.6934C22.0803 44.1505 22.4434 43.7886 22.9879 43.7886H28.4337C28.9783 43.7886 29.3413 44.1505 29.3413 44.6934V50.1224C29.3413 50.6653 28.9783 51.0272 28.4337 51.0272ZM23.8956 49.2176H27.5261V45.5982H23.8956V49.2176Z' fill='#16254C'/>
                <path d='M40.233 51.0272H34.7873C34.2427 51.0272 33.8796 50.6653 33.8796 50.1224V44.6934C33.8796 44.1505 34.2427 43.7886 34.7873 43.7886H40.233C40.7776 43.7886 41.1406 44.1505 41.1406 44.6934V50.1224C41.1406 50.6653 40.7776 51.0272 40.233 51.0272ZM35.6949 49.2176H39.3254V45.5982H35.6949V49.2176Z' fill='#16254C'/>
                <path d='M49.3097 51.0272H46.5868C46.0422 51.0272 45.6792 50.6653 45.6792 50.1224V44.6934C45.6792 44.1505 46.0422 43.7886 46.5868 43.7886H52.0326C52.5771 43.7886 52.9402 44.1505 52.9402 44.6934V48.7651H51.1249V45.5982H47.4944V49.2176H50.5005L49.3097 51.0272Z' fill='#16254C'/>
                <path d='M28.4337 60.0756H22.9879C22.4434 60.0756 22.0803 59.7136 22.0803 59.1707V53.7417C22.0803 53.1988 22.4434 52.8369 22.9879 52.8369H28.4337C28.9783 52.8369 29.3413 53.1988 29.3413 53.7417V59.1707C29.3413 59.7136 28.9783 60.0756 28.4337 60.0756ZM23.8956 58.2659H27.5261V54.6466H23.8956V58.2659Z' fill='#16254C'/>
                <path d='M40.233 60.0756H34.7873C34.2427 60.0756 33.8796 59.7136 33.8796 59.1707V53.7417C33.8796 53.1988 34.2427 52.8369 34.7873 52.8369H40.233C40.7776 52.8369 41.1406 53.1988 41.1406 53.7417V59.1707C41.1406 59.7136 40.7776 60.0756 40.233 60.0756ZM35.6949 58.2659H39.3254V54.6466H35.6949V58.2659Z' fill='#16254C'/>
                <path d='M79 61H81.75' stroke='#16254C' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
            </svg>
        );
    }
}

export default Withdraw;
