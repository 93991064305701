import { fetchPostUtility, fetchUtility } from '~utilities/fetch';

export async function userGetRequest(uuid: string): Promise<Response> {
    return fetchUtility(`/users/${uuid}`);
}

export async function userPasswordRequest(uuid: string, password: string): Promise<Response> {
    return fetchPostUtility(`/users/${uuid}/password`, {
        password,
    });
}

export async function userEmailRequest(uuid: string, email: string): Promise<Response> {
    return fetchPostUtility(`/users/${uuid}/email`, {
        email,
    });
}

export async function userEmailVerifyRequest(code: string): Promise<Response> {
    return fetchPostUtility(`/users/email-verify/${code}`);
}
