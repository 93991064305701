import _ from 'lodash';
import React from 'react';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import BrokerTypeEnum from '~Api/Broker/BrokerTypeEnum';
import IAggregator from '~Api/Broker/IAggregator';
import IBroker from '~Api/Broker/IBroker';
import RegistrationSourceEnum from '~Api/Broker/RegistrationSourceEnum';
import StateEnum from '~Api/Broker/StateEnum';
import { authRegisterErrorSetAction } from '~Auth/actions';
import {
    authRegisterErrorsSelector,
    authRegisterInProgressSelector,
} from '~Auth/selectors';
import { brokerEmailSelector } from '~Broker/selectors';
import {
    publicAggregatorsGetAction,
    publicCurrentBrokerRegisterAction,
} from '~Public/actions';
import { publicAggregatorsSelector } from '~Public/selectors';
import { IGlobalState } from '~reducer';
import Radio from '~UI/Radio';
import Upload from '~UI/Upload';
import { validateEmail } from '~validators';
import './broker.less';
import Layout from './Layout';

interface IState {
    aclNumber: string;
    aggregatorUuid: string;
    bankruptcy: boolean;
    companyName: string;
    convicted: boolean;
    email: string;
    firstName: string;
    indemnityInsurance: boolean;
    insuranceProof: File;
    lastName: string;
    linkedInUrl: string;
    membership: File;
    phone: string;
    photoId: File;
    postalAddress: string;
    postcode: string;
    registrationSource: RegistrationSourceEnum;
    state: StateEnum;
    suburb: string;
    type: BrokerTypeEnum;
}

interface IPropsSelector {
    aggregators: IAggregator[];
    brokerEmail: string;
    registerErrors: any;
    registerInProgress: boolean;
}

interface IPropsDispatch {
    aggregatorsList: () => void;
    register: (broker: IBroker, insuranceProof: File, membershipDocument: File, photoId: File) => void;
    setError: (key: string, value: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Register extends React.Component<Props, IState> {
    public state: IState = {
        aclNumber: null,
        aggregatorUuid: null,
        bankruptcy: null,
        companyName: null,
        convicted: null,
        email: null,
        firstName: null,
        indemnityInsurance: null,
        insuranceProof: null,
        lastName: null,
        linkedInUrl: null,
        membership: null,
        phone: null,
        photoId: null,
        postalAddress: null,
        postcode: null,
        registrationSource: null,
        state: null,
        suburb: null,
        type: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickContinue = this.onClickContinue.bind(this);

        this.onChangeAclNumber = this.onChangeAclNumber.bind(this);
        this.onChangeAggregator = this.onChangeAggregator.bind(this);
        this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeHasBeenBankrupted = this.onChangeHasBeenBankrupted.bind(this);
        this.onChangeHasBeenConvicted = this.onChangeHasBeenConvicted.bind(this);
        this.onChangeHasIndemnityInsurance = this.onChangeHasIndemnityInsurance.bind(this);
        this.onChangeHasNoIndemnityInsurance = this.onChangeHasNoIndemnityInsurance.bind(this);
        this.onChangeHasNotBeenBankrupted = this.onChangeHasNotBeenBankrupted.bind(this);
        this.onChangeHasNotBeenConvicted = this.onChangeHasNotBeenConvicted.bind(this);
        this.onChangeInsuranceProof = this.onChangeInsuranceProof.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeLinkedInUrl = this.onChangeLinkedInUrl.bind(this);
        this.onChangeMembership = this.onChangeMembership.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangePhotoId = this.onChangePhotoId.bind(this);
        this.onChangePostalAddress = this.onChangePostalAddress.bind(this);
        this.onChangePostcode = this.onChangePostcode.bind(this);
        this.onChangeRegistrationSource = this.onChangeRegistrationSource.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeSuburb = this.onChangeSuburb.bind(this);
        this.onChangeTypeAustralianCreditLicence = this.onChangeTypeAustralianCreditLicence.bind(this);
        this.onChangeTypeAuthorisedCreditRepresentative = this.onChangeTypeAuthorisedCreditRepresentative.bind(this);

        this.validateAclNumber = this.validateAclNumber.bind(this);
        this.validateBankruptcy = this.validateBankruptcy.bind(this);
        this.validateConvicted = this.validateConvicted.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validateFirstName = this.validateFirstName.bind(this);
        this.validateIndemnityInsurance = this.validateIndemnityInsurance.bind(this);
        this.validateLastName = this.validateLastName.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
        this.validatePostalAddress = this.validatePostalAddress.bind(this);
        this.validatePostcode = this.validatePostcode.bind(this);
        this.validateState = this.validateState.bind(this);
        this.validateSuburb = this.validateSuburb.bind(this);
        this.validateType = this.validateType.bind(this);
    }

    public componentDidMount() {
        const { aggregators, brokerEmail } = this.props;

        if (brokerEmail) {
            this.setState({
                email: brokerEmail,
            });
        }

        if (!aggregators) {
            this.props.aggregatorsList();
        }
    }

    public render(): JSX.Element {
        const { aggregators, registerErrors, registerInProgress } = this.props;
        const {
            aclNumber,
            aggregatorUuid,
            bankruptcy,
            companyName,
            convicted,
            email,
            firstName,
            indemnityInsurance,
            insuranceProof,
            lastName,
            linkedInUrl,
            membership,
            phone,
            photoId,
            postalAddress,
            postcode,
            registrationSource,
            state,
            suburb,
            type,
        } = this.state;

        if (!aggregators) {
            return (
                <Layout>
                    <div className='broker'>
                        <Container className='register'>
                            <Spinner animation='border' />
                        </Container>
                    </div>
                </Layout>
            );
        }

        return (
            <Layout>
                <div className='broker'>
                    <Container className='register'>
                        <h3>Become an Accredited Broker</h3>
                        <p className='intro'>We need some information to get started.</p>
                        <Form>
                            <Form.Group className='first-name'>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control isInvalid={!!registerErrors.firstName} onBlur={this.validateFirstName} onChange={this.onChangeFirstName} type='text' value={firstName || ''} />
                                {registerErrors.firstName && <Form.Control.Feedback type='invalid'>{registerErrors.firstName}</Form.Control.Feedback>}
                            </Form.Group>
                            <Form.Group className='last-name'>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control isInvalid={!!registerErrors.lastName} onBlur={this.validateLastName} onChange={this.onChangeLastName} type='text' value={lastName || ''} />
                                {registerErrors.lastName && <Form.Control.Feedback type='invalid'>{registerErrors.lastName}</Form.Control.Feedback>}
                            </Form.Group>
                            <Form.Group className='phone'>
                                <Form.Label>Phone</Form.Label>
                                <Form.Control isInvalid={!!registerErrors.phone} onBlur={this.validatePhone} onChange={this.onChangePhone} type='text' value={phone || ''} />
                                {registerErrors.phone && <Form.Control.Feedback type='invalid'>{registerErrors.phone}</Form.Control.Feedback>}
                            </Form.Group>
                            <Form.Group className='email'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control isInvalid={!!registerErrors.email} onBlur={this.validateEmail} onChange={this.onChangeEmail} type='email' value={email || ''} />
                                {registerErrors.email && <Form.Control.Feedback type='invalid'>{registerErrors.email}</Form.Control.Feedback>}
                            </Form.Group>
                            <Form.Group className='company-name'>
                                <Form.Label>Company Name (If Applicable)</Form.Label>
                                <Form.Control onChange={this.onChangeCompanyName} type='text' value={companyName || ''} />
                            </Form.Group>
                            <Form.Group className='postal-address'>
                                <Form.Label>Address</Form.Label>
                                <Form.Control isInvalid={!!registerErrors.postalAddress} onBlur={this.validatePostalAddress} onChange={this.onChangePostalAddress} value={postalAddress || ''} />
                                {registerErrors.postalAddress && <Form.Control.Feedback type='invalid'>{registerErrors.postalAddress}</Form.Control.Feedback>}
                            </Form.Group>
                            <Form.Group className='suburb'>
                                <Form.Label>Suburb</Form.Label>
                                <Form.Control isInvalid={!!registerErrors.suburb} onBlur={this.validateSuburb} onChange={this.onChangeSuburb} value={suburb || ''} />
                                {registerErrors.suburb && <Form.Control.Feedback type='invalid'>{registerErrors.suburb}</Form.Control.Feedback>}
                            </Form.Group>
                            <Form.Group className='state'>
                                <Form.Label>State</Form.Label>
                                <Form.Control as='select' className='custom-select' isInvalid={!!registerErrors.state} onBlur={this.validateState} onChange={this.onChangeState} value={state || ''}>
                                    {state === null && <option/>}
                                    <option value={StateEnum.AustralianCapitalTerritory}>ACT</option>
                                    <option value={StateEnum.NewSouthWales}>NSW</option>
                                    <option value={StateEnum.NorthernTerritory}>NT</option>
                                    <option value={StateEnum.Queensland}>QLD</option>
                                    <option value={StateEnum.SouthAustralia}>SA</option>
                                    <option value={StateEnum.Tasmania}>TAS</option>
                                    <option value={StateEnum.Victoria}>VIC</option>
                                    <option value={StateEnum.WesternAustralia}>WA</option>
                                </Form.Control>
                                {registerErrors.state && <Form.Control.Feedback type='invalid'>{registerErrors.state}</Form.Control.Feedback>}
                            </Form.Group>
                            <Form.Group className='postcode'>
                                <Form.Label>Postcode</Form.Label>
                                <Form.Control isInvalid={!!registerErrors.postcode} maxLength={4} onBlur={this.validatePostcode} onChange={this.onChangePostcode} value={postcode || ''} />
                                {registerErrors.postcode && <Form.Control.Feedback type='invalid'>{registerErrors.postcode}</Form.Control.Feedback>}
                            </Form.Group>
                            <Form.Group className='type'>
                                <Form.Label>Are You an ACL Holder or Credit Representative?</Form.Label>
                                <div className={`form-check-group ${registerErrors.type && 'is-invalid'}`}>
                                    <Radio checked={type === BrokerTypeEnum.AustralianCreditLicence} label='ACL Holder' onChange={this.onChangeTypeAustralianCreditLicence} />
                                    <Radio checked={type === BrokerTypeEnum.AuthorisedCreditRepresentative} label='Credit Representative' onChange={this.onChangeTypeAuthorisedCreditRepresentative} />
                                </div>
                                {registerErrors.type && <Form.Control.Feedback type='invalid'>{registerErrors.type}</Form.Control.Feedback>}
                            </Form.Group>
                            <Form.Group className='acl-number'>
                                <Form.Label>ACL/CR Number</Form.Label>
                                <Form.Control isInvalid={!!registerErrors.aclNumber} onBlur={this.validateAclNumber} onChange={this.onChangeAclNumber} type='text' maxLength={20} value={aclNumber || ''} />
                                {registerErrors.aclNumber && <Form.Control.Feedback type='invalid'>{registerErrors.aclNumber}</Form.Control.Feedback>}
                            </Form.Group>
                            <Form.Group className='aggregator'>
                                <Form.Label>Aggregator (If Applicable)</Form.Label>
                                <Form.Control as='select' className='custom-select' onChange={this.onChangeAggregator} value={aggregatorUuid || ''}>
                                    {aggregatorUuid === null && <option/>}
                                    {_.sortBy(aggregators, ['name']).map((aggregator) => <option key={aggregator.uuid} value={aggregator.uuid}>{aggregator.name}</option>)}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className='linkedin'>
                                <Form.Label>LinkedIn URL</Form.Label>
                                <Form.Control onChange={this.onChangeLinkedInUrl} type='text' value={linkedInUrl || ''} />
                            </Form.Group>
                            <Form.Group className='registration-source'>
                                <Form.Label>How did you find out about us?</Form.Label>
                                <Form.Control as='select' className='custom-select' onChange={this.onChangeRegistrationSource} value={registrationSource || ''}>
                                    <option/>
                                    <option value={RegistrationSourceEnum.Advisor}>Advisor</option>
                                    <option value={RegistrationSourceEnum.AggregatorBdm}>Aggregator BDM</option>
                                    <option value={RegistrationSourceEnum.AustralianBroker}>Australian Broker</option>
                                    <option value={RegistrationSourceEnum.FinanceAndCoffee}>Finance & Coffee</option>
                                    <option value={RegistrationSourceEnum.FundingBdm}>Funding BDM</option>
                                    <option value={RegistrationSourceEnum.Google}>Google</option>
                                    <option value={RegistrationSourceEnum.IndustryEvent}>Industry Event</option>
                                    <option value={RegistrationSourceEnum.LunchAndLearn}>Lunch & Learn</option>
                                    <option value={RegistrationSourceEnum.MortgageBusiness}>Mortgage Business</option>
                                    <option value={RegistrationSourceEnum.Mpa}>MPA</option>
                                    <option value={RegistrationSourceEnum.PdDay}>PD Day</option>
                                    <option value={RegistrationSourceEnum.Webinar}>Webinar</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className='indemnity-insurance'>
                                <Form.Label>Confirm you hold professional indemnity insurance (min requirement $2 million)</Form.Label>
                                <div className={`form-check-group ${registerErrors.indemnityInsurance && 'is-invalid'}`}>
                                    <Radio checked={indemnityInsurance === true} label='Yes' onChange={this.onChangeHasIndemnityInsurance} />
                                    <Radio checked={indemnityInsurance === false} label='No' onChange={this.onChangeHasNoIndemnityInsurance} />
                                </div>
                                {registerErrors.indemnityInsurance && <Form.Control.Feedback type='invalid'>{registerErrors.indemnityInsurance}</Form.Control.Feedback>}
                            </Form.Group>
                            <Form.Group className='convicted'>
                                <Form.Label>Have you been convicted of fraud and/or dishonesty in the past 10 years?</Form.Label>
                                <div className={`form-check-group ${registerErrors.convicted && 'is-invalid'}`}>
                                    <Radio checked={convicted === true} label='Yes' onChange={this.onChangeHasBeenConvicted} />
                                    <Radio checked={convicted === false} label='No' onChange={this.onChangeHasNotBeenConvicted} />
                                </div>
                                {registerErrors.convicted && <Form.Control.Feedback type='invalid'>{registerErrors.convicted}</Form.Control.Feedback>}
                            </Form.Group>
                            <Form.Group className='bankruptcy'>
                                <Form.Label>Have you or a company associated with you been bankrupted in the past 10 years?</Form.Label>
                                <div className={`form-check-group ${registerErrors.bankruptcy && 'is-invalid'}`}>
                                    <Radio checked={bankruptcy === true} label='Yes' onChange={this.onChangeHasBeenBankrupted} />
                                    <Radio checked={bankruptcy === false} label='No' onChange={this.onChangeHasNotBeenBankrupted} />
                                </div>
                                {registerErrors.bankruptcy && <Form.Control.Feedback type='invalid'>{registerErrors.bankruptcy}</Form.Control.Feedback>}
                            </Form.Group>
                            <Form.Group className='membership'>
                                <Form.Label>Upload Your MFAA/FBAA Membership</Form.Label>
                                <Upload
                                    file={membership}
                                    onChange={this.onChangeMembership}
                                />
                            </Form.Group>
                            <Form.Group className='photo-id'>
                                <Form.Label>Upload Your Photo ID or Drivers Licence</Form.Label>
                                <Upload
                                    file={photoId}
                                    onChange={this.onChangePhotoId}
                                />
                            </Form.Group>
                            <Form.Group className='insurance-proof'>
                                <Form.Label>Upload Your PI Insurance</Form.Label>
                                <Upload
                                    file={insuranceProof}
                                    onChange={this.onChangeInsuranceProof}
                                />
                            </Form.Group>
                            <Button className='continue' disabled={registerInProgress} onClick={this.onClickContinue} variant='primary'>
                                {registerInProgress && <Spinner animation='border' as='span' role='status' size='sm' />}
                                {!registerInProgress && 'Send'}
                            </Button>
                        </Form>
                    </Container>
                </div>
            </Layout>
        );
    }

    private onClickContinue() {
        let valid = true;

        valid = this.validateAclNumber() && valid;
        valid = this.validateBankruptcy() && valid;
        valid = this.validateConvicted() && valid;
        valid = this.validateEmail() && valid;
        valid = this.validateFirstName() && valid;
        valid = this.validateIndemnityInsurance() && valid;
        valid = this.validateLastName() && valid;
        valid = this.validatePhone() && valid;
        valid = this.validatePostalAddress() && valid;
        valid = this.validatePostcode() && valid;
        valid = this.validateState() && valid;
        valid = this.validateSuburb() && valid;
        valid = this.validateType() && valid;

        if (!valid) {
            return;
        }

        const {
            aclNumber,
            aggregatorUuid,
            bankruptcy,
            companyName,
            convicted,
            email,
            firstName,
            indemnityInsurance,
            insuranceProof,
            lastName,
            linkedInUrl,
            membership,
            phone,
            photoId,
            postalAddress,
            postcode,
            registrationSource,
            state,
            suburb,
            type,
        } = this.state;

        const broker: IBroker = {
            aclNumber,
            aggregatorUuid,
            companyName,
            email,
            firstName,
            hasBeenBankrupted: bankruptcy,
            hasBeenConvicted: convicted,
            hasIndemnityInsurance: indemnityInsurance,
            lastName,
            linkedInUrl,
            phone,
            postalAddress,
            postcode,
            registrationSource,
            state,
            suburb,
            type,
        };

        this.props.register(broker, insuranceProof, membership, photoId);
    }

    private onChangeAclNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            aclNumber: event.target.value.substring(0, 20),
        });
    }

    private onChangeAggregator(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            aggregatorUuid: event.target.value,
        });
    }

    private onChangeCompanyName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            companyName: event.target.value,
        });
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            email: event.target.value,
        });
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            firstName: event.target.value,
        });
    }

    private onChangeHasBeenBankrupted(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            bankruptcy: event.target.checked,
        });
    }

    private onChangeHasBeenConvicted(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            convicted: event.target.checked,
        });
    }

    private onChangeHasIndemnityInsurance(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            indemnityInsurance: event.target.checked,
        });
    }

    private onChangeHasNoIndemnityInsurance(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            indemnityInsurance: !event.target.checked,
        });
    }

    private onChangeHasNotBeenBankrupted(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            bankruptcy: !event.target.checked,
        });
    }

    private onChangeHasNotBeenConvicted(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            convicted: !event.target.checked,
        });
    }

    private onChangeInsuranceProof(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            insuranceProof: event.target.files[0],
        });
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            lastName: event.target.value,
        });
    }

    private onChangeLinkedInUrl(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            linkedInUrl: event.target.value,
        });
    }

    private onChangeMembership(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            membership: event.target.files[0],
        });
    }

    private onChangePhone(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            phone: event.target.value,
        });
    }

    private onChangePhotoId(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            photoId: event.target.files[0],
        });
    }

    private onChangePostalAddress(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            postalAddress: event.target.value,
        });
    }

    private onChangePostcode(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            postcode: event.target.value,
        });
    }

    private onChangeRegistrationSource(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            registrationSource: event.target.value as RegistrationSourceEnum || null,
        });
    }

    private onChangeState(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            state: event.target.value as StateEnum,
        });
    }

    private onChangeSuburb(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            suburb: event.target.value,
        });
    }

    private onChangeTypeAustralianCreditLicence(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            type: event.target.checked ? BrokerTypeEnum.AustralianCreditLicence : BrokerTypeEnum.AuthorisedCreditRepresentative,
        });
    }

    private onChangeTypeAuthorisedCreditRepresentative(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            type: !event.target.checked ? BrokerTypeEnum.AustralianCreditLicence : BrokerTypeEnum.AuthorisedCreditRepresentative,
        });
    }

    private validateAclNumber(): boolean {
        const { aclNumber } = this.state;

        let error: string;

        if (!aclNumber || aclNumber.length === 0) {
            error = 'Please enter the ACL/CR number';
        } else if (aclNumber.length > 20) {
            error = 'The ACL/CR number must be no more than 20 characters';
        }

        this.props.setError('aclNumber', error);

        return !error;
    }

    private validateBankruptcy(): boolean {
        const { bankruptcy } = this.state;

        let error: string;

        if (bankruptcy !== true && bankruptcy !== false) {
            error = 'Please choose one';
        }

        this.props.setError('bankruptcy', error);

        return !error;
    }

    private validateConvicted(): boolean {
        const { convicted } = this.state;

        let error: string;

        if (convicted !== true && convicted !== false) {
            error = 'Please choose one';
        }

        this.props.setError('convicted', error);

        return !error;
    }

    private validateEmail(): boolean {
        const { email } = this.state;

        let error: string;
        if (!email || email.length === 0) {
            error = 'Please enter your email address';
        } else if (!validateEmail(email)) {
            error = 'Please enter a valid email address';
        }

        this.props.setError('email', error);

        return !error;
    }

    private validateFirstName(): boolean {
        const { firstName } = this.state;

        let error: string;

        if (!firstName || firstName.length === 0) {
            error = 'Please enter the first name';
        } else if (firstName.length < 2) {
            error = 'Please enter at least 2 characters';
        }

        this.props.setError('firstName', error);

        return !error;
    }

    private validateIndemnityInsurance(): boolean {
        const { indemnityInsurance } = this.state;

        let error: string;

        if (indemnityInsurance !== true && indemnityInsurance !== false) {
            error = 'Please choose one';
        }

        this.props.setError('indemnityInsurance', error);

        return !error;
    }

    private validateLastName(): boolean {
        const { lastName } = this.state;

        let error: string;

        if (!lastName || lastName.length === 0) {
            error = 'Please enter the last name';
        } else if (lastName.length < 2) {
            error = 'Please enter at least 2 characters';
        }

        this.props.setError('lastName', error);

        return !error;
    }

    private validatePhone(): boolean {
        const { phone } = this.state;

        let error: string;

        if (!phone || phone.length  === 0) {
            error = 'Please enter your phone number';
        }

        this.props.setError('phone', error);

        return !error;
    }

    private validatePostalAddress(): boolean {
        const { postalAddress } = this.state;

        let error: string;

        if (!postalAddress || postalAddress.length  === 0) {
            error = 'Please enter your address';
        }

        this.props.setError('postalAddress', error);

        return !error;
    }

    private validatePostcode(): boolean {
        const { postcode, state } = this.state;

        let error: string;

        if (!postcode || postcode.length === 0) {
            error = 'Please enter the postcode';
        } else if (postcode.length < 3 || postcode.length > 4) {
            error = 'Postcode must be 3 or 4 numbers';
        }  else if (state && state !== StateEnum.NorthernTerritory && postcode.length === 3) {
            error = 'Postcode must be 4 numbers';
        }

        if (!error && state) {
            let valid = true;
            const firstChar = postcode.charAt(0);

            switch (state) {
                case StateEnum.AustralianCapitalTerritory:
                case StateEnum.NewSouthWales:
                    if (firstChar !== '2') {
                        valid = false;
                    }
                    break;

                case StateEnum.NorthernTerritory:
                    if ((postcode.length === 3 && firstChar !== '8') || (postcode.length === 4 && postcode.substring(0, 2) !== '08')) {
                        valid = false;
                    }
                    break;

                case StateEnum.Queensland:
                    if (firstChar !== '4') {
                        valid = false;
                    }
                    break;

                case StateEnum.SouthAustralia:
                    if (firstChar !== '5') {
                        valid = false;
                    }
                    break;

                case StateEnum.Tasmania:
                    if (firstChar !== '7') {
                        valid = false;
                    }
                    break;

                case StateEnum.Victoria:
                    if (firstChar !== '3') {
                        valid = false;
                    }
                    break;

                case StateEnum.WesternAustralia:
                    if (firstChar !== '6') {
                        valid = false;
                    }
                    break;
            }

            if (!valid) {
                error = 'Please enter a valid postcode';
            }
        }

        this.props.setError('postcode', error);

        return !error;
    }

    private validateState(): boolean {
        const { state } = this.state;

        let error: string;

        if (!_.values(StateEnum).includes(state)) {
            error = 'Please select your state';
        }

        this.props.setError('state', error);

        return !error;
    }

    private validateSuburb(): boolean {
        const { suburb } = this.state;

        let error: string;

        if (!suburb || suburb.length  === 0) {
            error = 'Please enter your suburb';
        }

        this.props.setError('suburb', error);

        return !error;
    }

    private validateType(): boolean {
        const { type } = this.state;

        let error: string;

        if (![BrokerTypeEnum.AustralianCreditLicence, BrokerTypeEnum.AuthorisedCreditRepresentative].includes(type)) {
            error = 'Please choose one';
        }

        this.props.setError('type', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        aggregators: publicAggregatorsSelector(state),
        brokerEmail: brokerEmailSelector(state),
        registerErrors: authRegisterErrorsSelector(state),
        registerInProgress: authRegisterInProgressSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        aggregatorsList: () => dispatch(publicAggregatorsGetAction()),
        register: (broker: IBroker, insuranceProof: File, membershipDocument: File, photoId: File) => dispatch(publicCurrentBrokerRegisterAction(broker, insuranceProof, membershipDocument, photoId)),
        setError: (key: string, value: string) => dispatch(authRegisterErrorSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Register);
