import IApplication from './IApplication';
import IApplicationBorrower from './IApplicationBorrower';

export function transformApplication(application: IApplication): any {
    return {
        loanAmount: application.loanAmount,
        loanPurpose: application.loanPurpose,
        repaymentStrategy: application.repaymentStrategy,
        termMonths: application.termMonths,
    };
}

export function transformBorrower(borrower: IApplicationBorrower) {
    return {
        abn: borrower.abn,
        address: borrower.address,
        businessName: borrower.businessName,
        category: borrower.category,
        dependentNumber: borrower.dependentNumber,
        dob: borrower.dob,
        email: borrower.email,
        firstName: borrower.firstName,
        gender: borrower.gender,
        idType: borrower.idType,
        incomeType: borrower.incomeType,
        jobTitle: borrower.jobTitle,
        lastName: borrower.lastName,
        licenceNumber: borrower.licenceNumber,
        licenceState: borrower.licenceState,
        maritalStatus: borrower.maritalStatus,
        middleName: borrower.middleName,
        monthlyGrossExpenses: borrower.monthlyGrossExpenses,
        monthlyGrossTurnover: borrower.monthlyGrossTurnover,
        monthlyHouseholdExpenses: borrower.monthlyHouseholdExpenses,
        otherIncomeType: borrower.otherIncomeType,
        passportCountry: borrower.passportCountry,
        passportNumber: borrower.passportNumber,
        phone: borrower.phone,
        trustName: borrower.trustName,
        trusteeName: borrower.trusteeName,
        type: borrower.type,
        yearlyIncome: borrower.yearlyIncome,
    };
}
