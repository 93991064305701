import React from 'react';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import InterestPaymentStrategyEnum from '~Api/Investor/InterestPaymentStrategyEnum';
import history from '~history';
import {
    publicCurrentInvestorAccountIncomeTrustInterestPaymentStrategySetAction,
    publicCurrentInvestorGetAction,
} from '~Public/actions';
import Steps from '~Public/Invest/IncomeTrust/Steps';
import {
    publicCurrentInvestorAccountSelector,
    publicCurrentInvestorSelector,
} from '~Public/selectors';
import { IGlobalState } from '~reducer';
import Radio from '~UI/Radio';
import Layout from './Layout';

interface IPropsSelector {
    currentInvestor: IInvestor;
    incomeTrustAccount: IAccount;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
    currentInvestorUpdateInterestPaymentStrategy: (interestPaymentStrategy: InterestPaymentStrategyEnum) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class DistributionPreference extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeInterestPaymentStrategy = this.onChangeInterestPaymentStrategy.bind(this);
    }

    public componentDidMount(): void {
        const { currentInvestor } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }
    }

    public render(): JSX.Element {
        const { currentInvestor, incomeTrustAccount } = this.props;

        if (!currentInvestor) {
            return (
                <Layout>
                    <Steps currentStep={4} />
                    <Container className='register-distribution-preference'>
                        <Spinner animation='border' />
                    </Container>
                </Layout>
            );
        }

        const interestPaymentStrategy: InterestPaymentStrategyEnum = incomeTrustAccount.incomeTrust.interestPaymentStrategy;

        return (
            <Layout>
                <Steps currentStep={4} />

                <Container className='register-distribution-preference'>
                    <h3>Distribution Preference</h3>
                    <p className='intro'>How would you like us to treat your monthly income?</p>
                    <Form>
                        <Form.Group className='distribution-preference'>
                            <div className='form-cards'>
                                <div className={`form-card ${interestPaymentStrategy === InterestPaymentStrategyEnum.Withdraw ? 'form-card-selected' : ''}`}>
                                    <Radio
                                        checked={interestPaymentStrategy === InterestPaymentStrategyEnum.Withdraw}
                                        label='Automatic Withdraw'
                                        value={InterestPaymentStrategyEnum.Withdraw}
                                        onChange={this.onChangeInterestPaymentStrategy}
                                    />
                                    <div className='card-description'>Automatically withdraw my interest to my nominated bank account</div>
                                </div>
                                <div className={`form-card ${interestPaymentStrategy === InterestPaymentStrategyEnum.Reinvest ? 'form-card-selected' : ''}`}>
                                    <Radio
                                        checked={interestPaymentStrategy === InterestPaymentStrategyEnum.Reinvest}
                                        label='Automatic Reinvest'
                                        value={InterestPaymentStrategyEnum.Reinvest}
                                        onChange={this.onChangeInterestPaymentStrategy}
                                    />
                                    <div className='card-description'>Automatically reinvest my interest back into the Income Trust</div>
                                </div>
                            </div>
                        </Form.Group>
                        <Button href='/invest/income-trust/bank-account' variant='primary' className='previous'>Previous</Button>
                        <Button href='/invest/income-trust/information-memorandum' variant='primary' className='continue' disabled={!interestPaymentStrategy}>Continue</Button>
                    </Form>
                </Container>
            </Layout>
        );
    }

    private onChangeInterestPaymentStrategy(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.currentInvestorUpdateInterestPaymentStrategy(event.target.value as InterestPaymentStrategyEnum);
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: publicCurrentInvestorSelector(state),
        incomeTrustAccount: publicCurrentInvestorAccountSelector(state, AccountTypeEnum.IncomeTrust),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(publicCurrentInvestorGetAction()),
        currentInvestorUpdateInterestPaymentStrategy: (interestPaymentStrategy: InterestPaymentStrategyEnum) => dispatch(publicCurrentInvestorAccountIncomeTrustInterestPaymentStrategySetAction(interestPaymentStrategy)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DistributionPreference);
