import _ from 'lodash';
import React from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IBroker from '~Api/Broker/IBroker';
import StateEnum from '~Api/Broker/StateEnum';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { currentBrokerAddressUpdateAction } from './actions';
import { brokerInProgressSelector } from './selectors';

interface IState {
    errors: IDictionary<string>;
    postalAddress: string;
    postcode: string;
    state: StateEnum;
    suburb: string;
}

interface IProps {
    broker: IBroker;
    cancel: () => void;
    isModalOpen: boolean;
}

interface IPropsSelector {
    inProgress: boolean;
}

interface IPropsDispatch {
    updateAddress: (uuid: string, postalAddress: string, postcode: string, state: StateEnum, suburb: string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class AddressModal extends React.Component<Props, IState> {
    public state: IState = {
        errors: {},
        postalAddress: this.props.broker.postalAddress,
        postcode: this.props.broker.postcode,
        state: this.props.broker.state,
        suburb: this.props.broker.suburb,
    };

    constructor(props: Props) {
        super(props);

        this.clearError = this.clearError.bind(this);
        this.setError = this.setError.bind(this);

        this.onChangePostalAddress = this.onChangePostalAddress.bind(this);
        this.onChangePostcode = this.onChangePostcode.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeSuburb = this.onChangeSuburb.bind(this);

        this.onClickSubmit = this.onClickSubmit.bind(this);

        this.validatePostalAddress = this.validatePostalAddress.bind(this);
        this.validatePostcode = this.validatePostcode.bind(this);
        this.validateState = this.validateState.bind(this);
        this.validateSuburb = this.validateSuburb.bind(this);
    }

    public render(): JSX.Element {
        const { cancel, inProgress, isModalOpen } = this.props;
        const { errors, postalAddress, postcode, state, suburb } = this.state;

        return (
            <Modal
                backdropClassName='broker-update-address-modal-backdrop'
                dialogClassName='broker-update-address-modal'
                onHide={cancel}
                show={isModalOpen}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Update Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='street-address'>
                        <Form.Label>Street Address</Form.Label>
                        <Form.Control onBlur={this.validatePostalAddress} isInvalid={!!errors.postalAddress} onChange={this.onChangePostalAddress} type='text' value={postalAddress || ''} />
                        {errors.postalAddress && <Form.Control.Feedback type='invalid'>{errors.postalAddress}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group className='suburb'>
                        <Form.Label>Suburb</Form.Label>
                        <Form.Control onBlur={this.validateSuburb} isInvalid={!!errors.suburb} onChange={this.onChangeSuburb} type='text' value={suburb || ''} />
                        {errors.suburb && <Form.Control.Feedback type='invalid'>{errors.suburb}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group className='state'>
                        <Form.Label>State</Form.Label>
                        <Form.Control as='select' className='custom-select' isInvalid={!!errors.state} onBlur={this.validateState} onChange={this.onChangeState} value={state || ''}>
                            {state === null && <option/>}
                            <option value={StateEnum.AustralianCapitalTerritory}>ACT</option>
                            <option value={StateEnum.NewSouthWales}>NSW</option>
                            <option value={StateEnum.NorthernTerritory}>NT</option>
                            <option value={StateEnum.Queensland}>QLD</option>
                            <option value={StateEnum.SouthAustralia}>SA</option>
                            <option value={StateEnum.Tasmania}>TAS</option>
                            <option value={StateEnum.Victoria}>VIC</option>
                            <option value={StateEnum.WesternAustralia}>WA</option>
                        </Form.Control>
                        {errors.state && <Form.Control.Feedback type='invalid'>{errors.state}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group className='postcode'>
                        <Form.Label>Postcode</Form.Label>
                        <Form.Control onBlur={this.validatePostcode} isInvalid={!!errors.postcode} onChange={this.onChangePostcode} type='text' value={postcode || ''} />
                        {errors.postcode && <Form.Control.Feedback type='invalid'>{errors.postcode}</Form.Control.Feedback>}
                    </Form.Group>
                    <Button disabled={inProgress} name='submit' onClick={this.onClickSubmit}>
                        {inProgress && <Spinner animation='border' size='sm' />} Confirm
                    </Button>
                </Modal.Body>
            </Modal>
        );
    }

    private onChangePostalAddress(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            postalAddress: event.target.value,
        });
    }

    private onChangePostcode(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            postcode: event.target.value,
        });
    }

    private onChangeState(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            state: event.target.value as StateEnum,
        });
    }

    private onChangeSuburb(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            suburb: event.target.value,
        });
    }

    private onClickSubmit() {
        const { broker } = this.props;
        const { postalAddress, postcode, state, suburb } = this.state;

        let valid = true;

        valid = this.validatePostalAddress() && valid;
        valid = this.validatePostcode() && valid;
        valid = this.validateState() && valid;
        valid = this.validateSuburb() && valid;

        if (!valid) {
            return;
        }

        this.props.updateAddress(broker.uuid, postalAddress, postcode, state, suburb);
    }

    private clearError(key: keyof IBroker) {
        const { errors } = this.state;

        this.setState({
            errors: _.omit(errors, [key]),
        });
    }

    private setError(key: keyof IBroker, error: string) {
        const { errors } = this.state;

        this.setState({
            errors: {
                ...errors,
                [key]: error,
            },
        });
    }

    private validatePostalAddress(): boolean {
        const { postalAddress } = this.state;

        let error: string;
        if (!postalAddress || postalAddress.length === 0) {
            error = 'Please enter the address';
        } else if (postalAddress.length > 255) {
            error = 'Please limit the address to 255 characters';
        }

        if (error) {
            this.setError('postalAddress', error);
        } else {
            this.clearError('postalAddress');
        }

        return !error;
    }

    private validatePostcode(): boolean {
        const { postcode, state } = this.state;

        let error: string;
        if (!postcode || postcode.length === 0) {
            error = 'Please enter the postcode';
        } else if (postcode.length < 3 || postcode.length > 4) {
            error = 'Postcode must be 3 or 4 numbers';
        }  else if (state && state !== StateEnum.NorthernTerritory && postcode.length === 3) {
            error = 'Postcode must be 4 numbers';
        }

        if (error) {
            this.setError('postcode', error);
        } else {
            this.clearError('postcode');
        }

        return !error;
    }

    private validateState(): boolean {
        const { postcode, state } = this.state;

        if (postcode && postcode.length > 0) {
            this.validatePostcode();
        }

        let error: string;
        if (!_.values(StateEnum).includes(state)) {
            error = 'Please select your state';
        }

        if (error) {
            this.setError('state', error);
        } else {
            this.clearError('state');
        }

        return !error;
    }

    private validateSuburb(): boolean {
        const { suburb } = this.state;

        let error: string;
        if (!suburb || suburb.length === 0) {
            error = 'Please enter your suburb';
        } else if (suburb.length > 50) {
            error = 'Please enter a valid suburb';
        }

        if (error) {
            this.setError('suburb', error);
        } else {
            this.clearError('suburb');
        }

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        inProgress: brokerInProgressSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        updateAddress: (uuid: string, postalAddress: string, postcode: string, state: StateEnum, suburb: string) => dispatch(currentBrokerAddressUpdateAction(uuid, postalAddress, postcode, state, suburb)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddressModal);
