import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IncomeTrustClassTypeEnum from '~Api/Investor/IncomeTrustClassTypeEnum';
import { getIncomeTrustClassInterestRate } from '~utilities/utils';
import Layout from './Layout';

class InvestorAccountType extends React.Component {
    public render(): JSX.Element {
        const incomeTrustClasses: IncomeTrustClassTypeEnum[] = [IncomeTrustClassTypeEnum.ClassC, IncomeTrustClassTypeEnum.ClassD];
        const interestRates: number[] = incomeTrustClasses.map(getIncomeTrustClassInterestRate);
        const minimumInterestRate: number = Math.min(...interestRates);
        const maximumInterestRate: number = Math.max(...interestRates);

        const decimalFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'decimal',
        });

        return (
            <Layout>
                <Container className='investor-account-type'>
                    <div className='type-boxes'>
                        <div className='type-box'>
                            <h3>Retail Fund</h3>
                            <div className='box-description'>
                                <p>Our retail fund open to all investors who select and manage investments online starting from $5,000.</p>
                                <p>Target returns from <strong>5 to 9%</strong> pa*.</p>
                                <p className='box-disclaimer'>Be sure to read the PDS and consider all risks while investing. Target returns and repayment of capital are not guaranteed.</p>
                            </div>
                            <footer className='box-footer'>
                                <Link className='box-button btn btn-primary' to='/invest/marketplace'>Get started</Link>
                                <a href='https://www.funding.com.au/investing/' target='_blank' className='box-link' rel='noreferrer'>Learn more</a>
                            </footer>
                        </div>
                        <div className='type-box'>
                            <h3>Wholesale Fund</h3>
                            <div className='box-description'>
                                <p>Our wholesale investment fund open to wholesale investors only who invest in a pool of mortgages starting from $50,000.</p>
                                <p>Target returns from <strong>{decimalFormatter.format(minimumInterestRate * 100)} to {decimalFormatter.format(maximumInterestRate * 100)}%</strong>* pa*.</p>
                                <p className='box-disclaimer'>Be sure to read the IM and consider all risks while investing. Target returns and repayment of capital are not guaranteed.</p>
                            </div>
                            <footer className='box-footer'>
                                <Link className='box-button btn btn-primary' to='/invest/income-trust'>Get started</Link>
                                <a href='https://www.funding.com.au/income-trust/' target='_blank' className='box-link' rel='noreferrer'>Learn more</a>
                            </footer>
                        </div>
                    </div>
                </Container>
            </Layout>
        );
    }
}

export default InvestorAccountType;
