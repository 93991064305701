import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import DateTime from 'react-datetime';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import BorrowerTypeEnum from '~Api/Application/BorrowerTypeEnum';
import GenderEnum from '~Api/Application/GenderEnum';
import IApplicationBorrower from '~Api/Application/IApplicationBorrower';
import IApplicationBorrowerErrors from '~Api/Application/IApplicationBorrowerErrors';
import IdTypeEnum from '~Api/Application/IdTypeEnum';
import IncomeTypeEnum from '~Api/Application/IncomeTypeEnum';
import LicenceStateEnum from '~Api/Application/LicenceStateEnum';
import MaritalStatusEnum from '~Api/Application/MaritalStatusEnum';
import { publicCountriesGetAction } from '~Public/actions';
import ICountry from '~Public/ICountry';
import { publicCountriesSelector } from '~Public/selectors';
import { IGlobalState } from '~reducer';
import {
    borrowerDeleteAction,
    borrowerErrorSetAction,
    borrowerValueSetAction,
} from './actions';
import {
    borrowerErrorsSelector,
    borrowerSelector,
    primaryBorrowerSelector,
} from './selectors';
import {
    validateBorrowerAbn,
    validateBorrowerAddress,
    validateBorrowerBusinessName,
    validateBorrowerDependentNumber,
    validateBorrowerDob,
    validateBorrowerEmail,
    validateBorrowerFirstName,
    validateBorrowerGender,
    validateBorrowerIdType,
    validateBorrowerIncomeType,
    validateBorrowerJobTitle,
    validateBorrowerLastName,
    validateBorrowerLicenceNumber,
    validateBorrowerLicenceState,
    validateBorrowerMaritalStatus,
    validateBorrowerMiddleName,
    validateBorrowerMonthlyGrossExpenses,
    validateBorrowerMonthlyGrossTurnover,
    validateBorrowerOtherIncomeType,
    validateBorrowerPassportCountry,
    validateBorrowerPassportNumber,
    validateBorrowerPhone,
    validateBorrowerTrusteeName,
    validateBorrowerTrustName,
    validateBorrowerYearlyIncome,
} from './validators';

const individualNames: { [borrowerType: string]: string } = {
    [BorrowerTypeEnum.Company]: 'Director',
    [BorrowerTypeEnum.Individual]: 'Individual',
    [BorrowerTypeEnum.Trust]: 'Trustee Individual/Director',
};

interface IProps {
    countries?: ICountry[];
    uuid: string;
    index?: number;
}

interface IPropsSelector {
    borrower: IApplicationBorrower;
    countries: ICountry[];
    errors: IApplicationBorrowerErrors;
    primaryBorrower: IApplicationBorrower;
}

interface IPropsDispatch {
    countriesGet: () => void;
    delete: () => void;
    errorSet: (field: keyof IApplicationBorrowerErrors, value: string) => void;
    valueSet: (field: keyof IApplicationBorrower, value: any) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Borrower extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeAbn = this.onChangeAbn.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeBusinessName = this.onChangeBusinessName.bind(this);
        this.onChangeDateOfBirth = this.onChangeDateOfBirth.bind(this);
        this.onChangeDependentNumber = this.onChangeDependentNumber.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeGender = this.onChangeGender.bind(this);
        this.onChangeIdType = this.onChangeIdType.bind(this);
        this.onChangeIncomeType = this.onChangeIncomeType.bind(this);
        this.onChangeJobTitle = this.onChangeJobTitle.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeLicenceNumber = this.onChangeLicenceNumber.bind(this);
        this.onChangeLicenceState = this.onChangeLicenceState.bind(this);
        this.onChangeMaritalStatus = this.onChangeMaritalStatus.bind(this);
        this.onChangeMiddleName = this.onChangeMiddleName.bind(this);
        this.onChangeMonthlyGrossExpenses = this.onChangeMonthlyGrossExpenses.bind(this);
        this.onChangeMonthlyGrossTurnover = this.onChangeMonthlyGrossTurnover.bind(this);
        this.onChangeOtherIncomeType = this.onChangeOtherIncomeType.bind(this);
        this.onChangePassportCountry = this.onChangePassportCountry.bind(this);
        this.onChangePassportNumber = this.onChangePassportNumber.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeTrustName = this.onChangeTrustName.bind(this);
        this.onChangeTrusteeName = this.onChangeTrusteeName.bind(this);
        this.onChangeYearlyIncome = this.onChangeYearlyIncome.bind(this);

        this.validateAbn = this.validateAbn.bind(this);
        this.validateAddress = this.validateAddress.bind(this);
        this.validateBusinessName = this.validateBusinessName.bind(this);
        this.validateDependentNumber = this.validateDependentNumber.bind(this);
        this.validateDob = this.validateDob.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validateFirstName = this.validateFirstName.bind(this);
        this.validateGender = this.validateGender.bind(this);
        this.validateIdType = this.validateIdType.bind(this);
        this.validateIncomeType = this.validateIncomeType.bind(this);
        this.validateJobTitle = this.validateJobTitle.bind(this);
        this.validateLastName = this.validateLastName.bind(this);
        this.validateLicenceNumber = this.validateLicenceNumber.bind(this);
        this.validateLicenceState = this.validateLicenceState.bind(this);
        this.validateMaritalStatus = this.validateMaritalStatus.bind(this);
        this.validateMiddleName = this.validateMiddleName.bind(this);
        this.validateMonthlyGrossExpenses = this.validateMonthlyGrossExpenses.bind(this);
        this.validateMonthlyGrossTurnover = this.validateMonthlyGrossTurnover.bind(this);
        this.validateOtherIncomeType = this.validateOtherIncomeType.bind(this);
        this.validatePassportCountry = this.validatePassportCountry.bind(this);
        this.validatePassportNumber = this.validatePassportNumber.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
        this.validateTrustName = this.validateTrustName.bind(this);
        this.validateTrusteeName = this.validateTrusteeName.bind(this);
        this.validateYearlyIncome = this.validateYearlyIncome.bind(this);
    }

    public componentDidMount() {
        const { countries } = this.props;

        if (!countries) {
            this.props.countriesGet();
        }
    }

    public render(): JSX.Element {
        const { borrower, countries, errors, index = 0, primaryBorrower } = this.props;

        if (!countries) {
            return (
                <React.Fragment>
                    <Spinner animation='border' />
                </React.Fragment>
            );
        }

        if (borrower.type === BorrowerTypeEnum.Company) {
            return (
                <React.Fragment>
                    <h4>Company</h4>
                    <Form.Group className='business-name'>
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control isInvalid={!!errors.businessName} maxLength={100} onBlur={this.validateBusinessName} onChange={this.onChangeBusinessName} value={borrower.businessName || ''} />
                        {errors.businessName && <Form.Control.Feedback type='invalid'>{errors.businessName}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group className='abn'>
                        <Form.Label>ACN or ABN</Form.Label>
                        <Form.Control isInvalid={!!errors.abn} maxLength={11} onBlur={this.validateAbn} onChange={this.onChangeAbn} value={borrower.abn || ''} />
                        {errors.abn && <Form.Control.Feedback type='invalid'>{errors.abn}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group className='monthly-gross-turnover'>
                        <Form.Label>Monthly Gross Turnover</Form.Label>
                        <Form.Control isInvalid={!!errors.monthlyGrossTurnover} min={0} max={99999999} onBlur={this.validateMonthlyGrossTurnover} onChange={this.onChangeMonthlyGrossTurnover} type='number' value={borrower.monthlyGrossTurnover || ''} />
                        {errors.monthlyGrossTurnover && <Form.Control.Feedback type='invalid'>{errors.monthlyGrossTurnover}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group className='monthly-gross-expenses'>
                        <Form.Label>Monthly Gross Expenses</Form.Label>
                        <Form.Control isInvalid={!!errors.monthlyGrossExpenses} min={0} max={99999999} onBlur={this.validateMonthlyGrossExpenses} onChange={this.onChangeMonthlyGrossExpenses} type='number' value={borrower.monthlyGrossExpenses || ''} />
                        {errors.monthlyGrossExpenses && <Form.Control.Feedback type='invalid'>{errors.monthlyGrossExpenses}</Form.Control.Feedback>}
                    </Form.Group>
                </React.Fragment>
            );
        }

        if (borrower.type === BorrowerTypeEnum.Trust) {
            return (
                <React.Fragment>
                    <h4>Trust</h4>
                    <Form.Group className='trust-name'>
                        <Form.Label>Trust Name</Form.Label>
                        <Form.Control isInvalid={!!errors.trustName} maxLength={100} onBlur={this.validateTrustName} onChange={this.onChangeTrustName} value={borrower.trustName || ''} />
                        {errors.trustName && <Form.Control.Feedback type='invalid'>{errors.trustName}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group className='abn'>
                        <Form.Label>ACN or ABN</Form.Label>
                        <Form.Control isInvalid={!!errors.abn} maxLength={11} onBlur={this.validateAbn} onChange={this.onChangeAbn} value={borrower.abn || ''} />
                        {errors.abn && <Form.Control.Feedback type='invalid'>{errors.abn}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group className='trustee-name'>
                        <Form.Label>Trustee Name</Form.Label>
                        <Form.Control isInvalid={!!errors.trusteeName} maxLength={100} onBlur={this.validateTrusteeName} onChange={this.onChangeTrusteeName} value={borrower.trusteeName || ''} />
                        {errors.trusteeName && <Form.Control.Feedback type='invalid'>{errors.trusteeName}</Form.Control.Feedback>}
                    </Form.Group>
                </React.Fragment>
            );
        }

        const jobTitleBlock: JSX.Element = [IncomeTypeEnum.PaygFullTime, IncomeTypeEnum.PaygPartTime].includes(borrower.incomeType) && (
            <Form.Group className='job-title'>
                <Form.Label>Job Title</Form.Label>
                <Form.Control isInvalid={!!errors.jobTitle} maxLength={100} onBlur={this.validateJobTitle} onChange={this.onChangeJobTitle} value={borrower.jobTitle || ''} />
                {errors.jobTitle && <Form.Control.Feedback type='invalid'>{errors.jobTitle}</Form.Control.Feedback>}
            </Form.Group>
        );

        const otherIncomeTypeBlock: JSX.Element = borrower.incomeType === IncomeTypeEnum.Other && (
            <Form.Group className='other-income-type'>
                <Form.Label>Other Income Details</Form.Label>
                <Form.Control isInvalid={!!errors.otherIncomeType} maxLength={100} onBlur={this.validateOtherIncomeType} onChange={this.onChangeOtherIncomeType} value={borrower.otherIncomeType || ''} />
                {errors.otherIncomeType && <Form.Control.Feedback type='invalid'>{errors.otherIncomeType}</Form.Control.Feedback>}
            </Form.Group>
        );

        const selfEmployedBlock: JSX.Element = borrower.incomeType === IncomeTypeEnum.SelfEmployed && (
            <React.Fragment>
                <Form.Group className='business-name'>
                    <Form.Label>Business Name</Form.Label>
                    <Form.Control isInvalid={!!errors.businessName} maxLength={100} onBlur={this.validateBusinessName} onChange={this.onChangeBusinessName} value={borrower.businessName || ''} />
                    {errors.businessName && <Form.Control.Feedback type='invalid'>{errors.businessName}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='abn'>
                    <Form.Label>ABN</Form.Label>
                    <Form.Control isInvalid={!!errors.abn} maxLength={11} onBlur={this.validateAbn} onChange={this.onChangeAbn} value={borrower.abn || ''} />
                    {errors.abn && <Form.Control.Feedback type='invalid'>{errors.abn}</Form.Control.Feedback>}
                </Form.Group>
            </React.Fragment>
        );

        const licenceNumberBlock: JSX.Element = borrower.idType === IdTypeEnum.DriverLicence && (
            <Form.Group className='licence-number'>
                <Form.Label>Licence Number</Form.Label>
                <Form.Control isInvalid={!!errors.licenceNumber} maxLength={20} onBlur={this.validateLicenceNumber} onChange={this.onChangeLicenceNumber} value={borrower.licenceNumber || ''} />
                {errors.licenceNumber && <Form.Control.Feedback type='invalid'>{errors.licenceNumber}</Form.Control.Feedback>}
            </Form.Group>
        );

        const licenceStateBlock: JSX.Element = borrower.idType === IdTypeEnum.DriverLicence && (
            <Form.Group className='licence-state'>
                <Form.Label>Licence State</Form.Label>
                <Form.Control as='select' className='custom-select' isInvalid={!!errors.licenceState} onBlur={this.validateLicenceState} onChange={this.onChangeLicenceState} value={borrower.licenceState || ''}>
                    <option/>
                    {_.values(LicenceStateEnum).map((stateCode: string) => <option key={stateCode} value={stateCode}>{stateCode}</option>)}
                </Form.Control>
                {errors.licenceState && <Form.Control.Feedback type='invalid'>{errors.licenceState}</Form.Control.Feedback>}
            </Form.Group>
        );

        const passportCountryBlock: JSX.Element = borrower.idType === IdTypeEnum.Passport && (
            <Form.Group className='passport-country'>
                <Form.Label>Passport Country</Form.Label>
                <Form.Control as='select' className='custom-select' isInvalid={!!errors.passportCountry} onBlur={this.validatePassportCountry} onChange={this.onChangePassportCountry} value={borrower.passportCountry || ''}>
                    <option/>
                    {countries.map((country: ICountry) => <option key={country.iso3} value={country.iso3}>{country.name}</option>)}
                </Form.Control>
                {errors.passportCountry && <Form.Control.Feedback type='invalid'>{errors.passportCountry}</Form.Control.Feedback>}
            </Form.Group>
        );

        const passportNumberBlock: JSX.Element = borrower.idType === IdTypeEnum.Passport && (
            <Form.Group className='passport-number'>
                <Form.Label>Passport Number</Form.Label>
                <Form.Control isInvalid={!!errors.passportNumber} maxLength={20} onBlur={this.validatePassportNumber} onChange={this.onChangePassportNumber} value={borrower.passportNumber || ''} />
                {errors.passportNumber && <Form.Control.Feedback type='invalid'>{errors.passportNumber}</Form.Control.Feedback>}
            </Form.Group>
        );

        return (
            <React.Fragment>
                <h4>{individualNames[primaryBorrower.type]} {primaryBorrower.type === BorrowerTypeEnum.Individual && primaryBorrower.uuid !== borrower.uuid ? index + 2 : index + 1}</h4>
                <Form.Group className='first-name'>
                    <Form.Label>Given Name</Form.Label>
                    <Form.Control isInvalid={!!errors.firstName} maxLength={50} onBlur={this.validateFirstName} onChange={this.onChangeFirstName} value={borrower.firstName || ''} />
                    {errors.firstName && <Form.Control.Feedback type='invalid'>{errors.firstName}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='middle-name'>
                    <Form.Label>Middle Name(s)</Form.Label>
                    <Form.Control isInvalid={!!errors.middleName} maxLength={255} onBlur={this.validateMiddleName} onChange={this.onChangeMiddleName} value={borrower.middleName || ''} />
                    {errors.middleName && <Form.Control.Feedback type='invalid'>{errors.middleName}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='last-name'>
                    <Form.Label>Surname</Form.Label>
                    <Form.Control isInvalid={!!errors.lastName} maxLength={50} onBlur={this.validateLastName} onChange={this.onChangeLastName} value={borrower.lastName || ''} />
                    {errors.lastName && <Form.Control.Feedback type='invalid'>{errors.lastName}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='phone'>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control isInvalid={!!errors.phone} maxLength={20} onBlur={this.validatePhone} onChange={this.onChangePhone} value={borrower.phone || ''} />
                    {errors.phone && <Form.Control.Feedback type='invalid'>{errors.phone}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='email'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control isInvalid={!!errors.email} maxLength={100} onBlur={this.validateEmail} onChange={this.onChangeEmail} value={borrower.email || ''} />
                    {errors.email && <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='dob'>
                    <Form.Label>Date of Birth</Form.Label>
                    <DateTime
                        className={!!errors.dob && 'is-invalid'}
                        dateFormat='DD/MM/YYYY'
                        inputProps={{ placeholder: 'dd/mm/yyyy' }}
                        onBlur={this.validateDob}
                        onChange={this.onChangeDateOfBirth}
                        timeFormat={false}
                        value={borrower.dob ? moment(borrower.dob) : ''}
                    />
                    {errors.dob && <Form.Control.Feedback type='invalid'>{errors.dob}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='gender'>
                    <Form.Label>Gender</Form.Label>
                    <Form.Control as='select' className='custom-select' isInvalid={!!errors.gender} onBlur={this.validateGender} onChange={this.onChangeGender} value={borrower.gender || ''}>
                        <option/>
                        <option value={GenderEnum.Female}>Female</option>
                        <option value={GenderEnum.Male}>Male</option>
                    </Form.Control>
                    {errors.gender && <Form.Control.Feedback type='invalid'>{errors.gender}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='id-type'>
                    <Form.Label>ID Type</Form.Label>
                    <Form.Control as='select' className='custom-select' isInvalid={!!errors.idType} onBlur={this.validateIdType} onChange={this.onChangeIdType} value={borrower.idType || ''}>
                        <option/>
                        <option value={IdTypeEnum.DriverLicence}>Driver License</option>
                        <option value={IdTypeEnum.Passport}>Passport</option>
                    </Form.Control>
                    {errors.idType && <Form.Control.Feedback type='invalid'>{errors.idType}</Form.Control.Feedback>}
                </Form.Group>
                {licenceStateBlock}
                {licenceNumberBlock}
                {passportCountryBlock}
                {passportNumberBlock}
                <Form.Group className='address'>
                    <Form.Label>Current Address</Form.Label>
                    <Form.Control isInvalid={!!errors.address} maxLength={255} onBlur={this.validateAddress} onChange={this.onChangeAddress} value={borrower.address || ''} />
                    {errors.address && <Form.Control.Feedback type='invalid'>{errors.address}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='income-type'>
                    <Form.Label>Income Type</Form.Label>
                    <Form.Control as='select' className='custom-select' isInvalid={!!errors.incomeType} onBlur={this.validateIncomeType} onChange={this.onChangeIncomeType} value={borrower.incomeType || ''}>
                        <option/>
                        <option value={IncomeTypeEnum.PaygFullTime}>PAYG - Full Time</option>
                        <option value={IncomeTypeEnum.PaygPartTime}>PAYG - Part Time</option>
                        <option value={IncomeTypeEnum.SelfEmployed}>Self Employed</option>
                        <option value={IncomeTypeEnum.Other}>Other</option>
                    </Form.Control>
                    {errors.incomeType && <Form.Control.Feedback type='invalid'>{errors.incomeType}</Form.Control.Feedback>}
                </Form.Group>
                {otherIncomeTypeBlock}
                {jobTitleBlock}
                {selfEmployedBlock}
                <Form.Group className='dependent-number'>
                    <Form.Label>Number of Dependants</Form.Label>
                    <Form.Control isInvalid={!!errors.dependentNumber} max={10} min={0} onBlur={this.validateDependentNumber} onChange={this.onChangeDependentNumber} type='number' value={null === borrower.dependentNumber ? '' : borrower.dependentNumber} />
                    {errors.dependentNumber && <Form.Control.Feedback type='invalid'>{errors.dependentNumber}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='marital-status'>
                    <Form.Label>Marital Status</Form.Label>
                    <Form.Control as='select' className='custom-select' isInvalid={!!errors.maritalStatus} onBlur={this.validateMaritalStatus} onChange={this.onChangeMaritalStatus} value={borrower.maritalStatus || ''}>
                        <option/>
                        <option value={MaritalStatusEnum.Single}>Single</option>
                        <option value={MaritalStatusEnum.Married}>Married</option>
                        <option value={MaritalStatusEnum.DeFacto}>De Facto</option>
                        <option value={MaritalStatusEnum.Separated}>Separated</option>
                        <option value={MaritalStatusEnum.Divorced}>Divorced</option>
                    </Form.Control>
                    {errors.maritalStatus && <Form.Control.Feedback type='invalid'>{errors.maritalStatus}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='yearly-income'>
                    <Form.Label>Approx Yearly Income (Pre Tax)</Form.Label>
                    <Form.Control isInvalid={!!errors.yearlyIncome} min={0} max={99999999} onBlur={this.validateYearlyIncome} onChange={this.onChangeYearlyIncome} type='number' value={borrower.yearlyIncome || ''} />
                    {errors.yearlyIncome && <Form.Control.Feedback type='invalid'>{errors.yearlyIncome}</Form.Control.Feedback>}
                </Form.Group>
                {(primaryBorrower.type === BorrowerTypeEnum.Individual && primaryBorrower.uuid !== borrower.uuid ? index + 1 : index) > 0 && <Button className='delete' onClick={this.props.delete} variant='light'>Remove {individualNames[primaryBorrower.type]}</Button>}
            </React.Fragment>
        );
    }

    private onChangeAbn(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.props.valueSet('abn', event.target.value.substr(0, 11));
    }

    private onChangeAddress(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('address', event.target.value.substr(0, 255));
    }

    private onChangeBusinessName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('businessName', event.target.value.substr(0, 100));
    }

    private onChangeDateOfBirth(value: string | moment.Moment) {
        this.props.valueSet('dob', typeof value === 'string' ? null : value && (value as moment.Moment).format('YYYY-MM-DD'));
    }

    private onChangeDependentNumber(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 10) {
            return;
        }

        this.props.valueSet('dependentNumber', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('email', event.target.value.substr(0, 100));
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('firstName', event.target.value.substr(0, 50));
    }

    private onChangeGender(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('gender', event.target.value as GenderEnum || null);
    }

    private onChangeIdType(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('idType', event.target.value as IdTypeEnum || null);
    }

    private onChangeIncomeType(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('incomeType', event.target.value as IncomeTypeEnum || null);
    }

    private onChangeJobTitle(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('jobTitle', event.target.value.substr(0, 100));
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('lastName', event.target.value.substr(0, 50));
    }

    private onChangeLicenceNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('licenceNumber', event.target.value.substr(0, 20));
    }

    private onChangeLicenceState(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('licenceState', event.target.value as LicenceStateEnum || null);
    }

    private onChangeMaritalStatus(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('maritalStatus', event.target.value as MaritalStatusEnum || null);
    }

    private onChangeMiddleName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('middleName', event.target.value.substr(0, 255));
    }

    private onChangeMonthlyGrossExpenses(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.props.valueSet('monthlyGrossExpenses', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private onChangeMonthlyGrossTurnover(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.props.valueSet('monthlyGrossTurnover', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private onChangeOtherIncomeType(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('otherIncomeType', event.target.value.substr(0, 100));
    }

    private onChangePassportCountry(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('passportCountry', event.target.value.substr(0, 3));
    }

    private onChangePassportNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('passportNumber', event.target.value.substr(0, 20));
    }

    private onChangePhone(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('phone', event.target.value.substr(0, 20));
    }

    private onChangeTrustName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('trustName', event.target.value.substr(0, 100));
    }

    private onChangeTrusteeName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('trusteeName', event.target.value.substr(0, 100));
    }

    private onChangeYearlyIncome(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.props.valueSet('yearlyIncome', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private validateAbn(): boolean {
        return !validateBorrowerAbn(this.props.borrower.abn, this.props.errorSet);
    }

    private validateAddress(): boolean {
        return !validateBorrowerAddress(this.props.borrower.address, this.props.errorSet);
    }

    private validateBusinessName(): boolean {
        return !validateBorrowerBusinessName(this.props.borrower.businessName, this.props.errorSet);
    }

    private validateDependentNumber(): boolean {
        return !validateBorrowerDependentNumber(this.props.borrower.dependentNumber, this.props.errorSet);
    }

    private validateDob(): boolean {
        return !validateBorrowerDob(this.props.borrower.dob, this.props.errorSet);
    }

    private validateEmail(): boolean {
        return !validateBorrowerEmail(this.props.borrower.email, this.props.errorSet);
    }

    private validateFirstName(): boolean {
        return !validateBorrowerFirstName(this.props.borrower.firstName, this.props.errorSet);
    }

    private validateGender(): boolean {
        return !validateBorrowerGender(this.props.borrower.gender, this.props.errorSet);
    }

    private validateIdType(): boolean {
        return !validateBorrowerIdType(this.props.borrower.idType, this.props.errorSet);
    }

    private validateIncomeType(): boolean {
        return !validateBorrowerIncomeType(this.props.borrower.incomeType, this.props.errorSet);
    }

    private validateJobTitle(): boolean {
        return !validateBorrowerJobTitle(this.props.borrower.jobTitle, this.props.errorSet);
    }

    private validateLastName(): boolean {
        return !validateBorrowerLastName(this.props.borrower.lastName, this.props.errorSet);
    }

    private validateLicenceNumber(): boolean {
        return !validateBorrowerLicenceNumber(this.props.borrower.licenceNumber, this.props.errorSet);
    }

    private validateLicenceState(): boolean {
        return !validateBorrowerLicenceState(this.props.borrower.licenceState, this.props.errorSet);
    }

    private validateMaritalStatus(): boolean {
        return !validateBorrowerMaritalStatus(this.props.borrower.maritalStatus, this.props.errorSet);
    }

    private validateMiddleName(): boolean {
        return !validateBorrowerMiddleName(this.props.borrower.middleName, this.props.errorSet);
    }

    private validateMonthlyGrossExpenses(): boolean {
        return !validateBorrowerMonthlyGrossExpenses(this.props.borrower.monthlyGrossExpenses, this.props.errorSet);
    }

    private validateMonthlyGrossTurnover(): boolean {
        return !validateBorrowerMonthlyGrossTurnover(this.props.borrower.monthlyGrossTurnover, this.props.errorSet);
    }

    private validateOtherIncomeType(): boolean {
        return !validateBorrowerOtherIncomeType(this.props.borrower.otherIncomeType, this.props.errorSet);
    }

    private validatePassportCountry(): boolean {
        return !validateBorrowerPassportCountry(this.props.borrower.passportCountry, this.props.errorSet);
    }

    private validatePassportNumber(): boolean {
        return !validateBorrowerPassportNumber(this.props.borrower.passportNumber, this.props.errorSet);
    }

    private validatePhone(): boolean {
        return !validateBorrowerPhone(this.props.borrower.phone, this.props.errorSet);
    }

    private validateTrustName(): boolean {
        return !validateBorrowerTrustName(this.props.borrower.trustName, this.props.errorSet);
    }

    private validateTrusteeName(): boolean {
        return !validateBorrowerTrusteeName(this.props.borrower.trusteeName, this.props.errorSet);
    }

    private validateYearlyIncome(): boolean {
        return !validateBorrowerYearlyIncome(this.props.borrower.yearlyIncome, this.props.errorSet);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        borrower: borrowerSelector(state, ownProps.uuid),
        countries: publicCountriesSelector(state),
        errors: borrowerErrorsSelector(state, ownProps.uuid),
        primaryBorrower: primaryBorrowerSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        countriesGet: () => dispatch(publicCountriesGetAction()),
        delete: () => dispatch(borrowerDeleteAction(ownProps.uuid)),
        errorSet: (field: keyof IApplicationBorrowerErrors, value: string) => dispatch(borrowerErrorSetAction(ownProps.uuid, field, value)),
        valueSet: (field: keyof IApplicationBorrower, value: any) => dispatch(borrowerValueSetAction(ownProps.uuid, field, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Borrower);
