import React from 'react';
import { Route, Router as ReactRouter } from 'react-router-dom';
import history from '~history';
import Register from './Register';
import Thanks from './Thanks';

class Router extends React.Component {
    public render(): JSX.Element {
        return (
            <ReactRouter history={history}>
                <Route exact={true} path='/adviser/register' component={Register} />
                <Route exact={true} path='/adviser/register/thanks' component={Thanks} />
            </ReactRouter>
        );
    }
}

export default Router;
