import { combineReducers } from 'redux';
import { applicationReducer, IApplicationState } from '~Application/reducer';
import { IAuthState, reducer as authReducer } from '~Auth/reducer';
import { brokerReducer, IBrokerState } from '~Broker/reducer';
import { globalReducer, IGlobalGlobalState } from '~Global/reducer';
import { IInvestorState, investorReducer } from '~Investor/reducer';
import { ILeadState, leadReducer } from '~Lead/reducer';
import { IPublicState, publicReducer } from '~Public/reducer';
import { IUserState, userReducer } from '~User/reducer';

export interface IGlobalState {
  application: IApplicationState;
  auth: IAuthState;
  broker: IBrokerState;
  global: IGlobalGlobalState;
  investor: IInvestorState;
  lead: ILeadState;
  public: IPublicState;
  user: IUserState;
}

export default combineReducers({
  application: applicationReducer,
  auth: authReducer,
  broker: brokerReducer,
  global: globalReducer,
  investor: investorReducer,
  lead: leadReducer,
  public: publicReducer,
  user: userReducer,
});
