import _ from 'lodash';
import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import BrokerTypeEnum from '~Api/Broker/BrokerTypeEnum';
import IAggregator from '~Api/Broker/IAggregator';
import IBroker from '~Api/Broker/IBroker';
import { publicAggregatorsGetAction } from '~Public/actions';
import { publicAggregatorsSelector } from '~Public/selectors';
import { IGlobalState } from '~reducer';
import {
    currentUserGetAction,
    emailChangeModalShowAction,
    passwordChangeModalShowAction,
} from '~User/actions';
import IUser from '~User/IUser';
import { currentUserSelector } from '~User/selectors';
import { IDictionary } from '~utilities/IDictionary';
import {
    currentBrokerAddressUpdateModalShowAction,
    currentBrokerGetAction,
} from './actions';
import AddressModal from './AddressModal';
import Layout from './Layout';
import './my-details.less';
import {
    brokerAddressUpdateModalShowSelector,
    currentBrokerSelector,
} from './selectors';

const typeLabels: IDictionary<string> = {
    [BrokerTypeEnum.AustralianCreditLicence]: 'Australian Credit Licence',
    [BrokerTypeEnum.AuthorisedCreditRepresentative]: 'Australian Credit Representative',
};

interface IPropsSelector {
    aggregators: IAggregator[];
    currentBroker: IBroker;
    currentUser: IUser;
    showAddressModal: boolean;
}

interface IPropsDispatch {
    aggregatorsList: () => void;
    addressChangeModalShow: (showAddressModal: boolean) => void;
    currentBrokerGet: () => void;
    currentUserGet: () => void;
    emailChangeModalShow: () => void;
    passwordChangeModalShow: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class MyDetails extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickAddressModal = this.onClickAddressModal.bind(this);
        this.onClickAddressModalCancel = this.onClickAddressModalCancel.bind(this);
    }

    public componentDidMount() {
        const { aggregators, currentBroker, currentUser } = this.props;

        if (!currentBroker) {
            this.props.currentBrokerGet();
        }

        if (!currentUser) {
            this.props.currentUserGet();
        }

        if (!aggregators) {
            this.props.aggregatorsList();
        }
    }

    public render(): JSX.Element {
        const { aggregators, currentBroker, currentUser, showAddressModal } = this.props;

        if (!aggregators || !currentBroker || !currentUser) {
            return (
                <Layout section='get-details'>
                    <h1>My Details</h1>
                    <div className='main-box basic-information'>
                        <h2>Your Info</h2>
                        <Spinner animation='border' />
                    </div>
                    <div className='main-box broker-information'>
                        <h2>Broker Info</h2>
                        <Spinner animation='border' />
                    </div>
                </Layout>
            );
        }

        return (
            <Layout section='get-details'>
                <h1>My Details</h1>
                <div className='main-box basic-information'>
                    <h2>Your Info</h2>
                    <Form.Group className='full-name'>
                        <Form.Label>Full name</Form.Label>
                        <Form.Control defaultValue={currentUser.name} disabled={true} plaintext={true} />
                    </Form.Group>
                    <Form.Group className='email'>
                        <Form.Label>Email</Form.Label>
                        <Button className='change' onClick={this.props.emailChangeModalShow} variant='link'>Change</Button>
                        <Form.Control defaultValue={currentUser.email} disabled={true} plaintext={true} />
                    </Form.Group>
                    <Form.Group className='phone'>
                        <Form.Label>Phone</Form.Label>
                        <Form.Control defaultValue={currentUser.phone} disabled={true} plaintext={true} />
                    </Form.Group>
                    <Form.Group className='password'>
                        <Form.Label>Password</Form.Label>
                        <Button className='change' onClick={this.props.passwordChangeModalShow} variant='link'>Change</Button>
                        <Form.Text>**********</Form.Text>
                    </Form.Group>
                    <Form.Group className='code'>
                        <Form.Label>Broker Ref</Form.Label>
                        <Form.Text>{currentBroker.code}</Form.Text>
                    </Form.Group>
                </div>
                <div className='main-box broker-information'>
                    <h2>Broker Info</h2>
                    <Form.Group className='company-name'>
                        <Form.Label className='full-name'>Company name</Form.Label>
                        <Form.Control defaultValue={currentBroker.companyName || ''} disabled={true} plaintext={true} />
                    </Form.Group>
                    <Form.Group className='postal-address'>
                        <Form.Label>Address</Form.Label>
                        <Button className='change' onClick={this.onClickAddressModal} variant='link'>Change</Button>
                        <Form.Control value={`${currentBroker.postalAddress || ''} ${currentBroker.suburb || ''} ${currentBroker.state || ''} ${currentBroker.postcode || ''}`} disabled={true} plaintext={true} />
                    </Form.Group>
                    <Form.Group className='broker-type'>
                        <Form.Label>Type</Form.Label>
                        <Form.Control defaultValue={currentBroker.type && typeLabels[currentBroker.type]} disabled={true} plaintext={true} />
                    </Form.Group>
                    <Form.Group className='credit-number'>
                        <Form.Label>{currentBroker.type === BrokerTypeEnum.AustralianCreditLicence ? 'Licence Number' : 'Representative Number'}</Form.Label>
                        <Form.Control defaultValue={currentBroker.aclNumber || '-'} disabled={true} plaintext={true} />
                    </Form.Group>
                    <Form.Group className='insurance'>
                        <Form.Label>Indemnity Insurance</Form.Label>
                        <Form.Control defaultValue={currentBroker.hasIndemnityInsurance ? 'Yes' : 'No'} disabled={true} plaintext={true} />
                    </Form.Group>
                    <Form.Group className='aggregrator'>
                        <Form.Label>Aggregrator</Form.Label>
                        <Form.Control defaultValue={currentBroker.aggregatorUuid ? _.find(aggregators, { uuid: currentBroker.aggregatorUuid }).name : '-'} disabled={true} plaintext={true} />
                    </Form.Group>
                </div>
                <AddressModal broker={currentBroker} cancel={this.onClickAddressModalCancel} isModalOpen={showAddressModal} />
            </Layout>
        );
    }

    private onClickAddressModal() {
        this.props.addressChangeModalShow(true);
    }

    private onClickAddressModalCancel() {
        this.props.addressChangeModalShow(false);
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        aggregators: publicAggregatorsSelector(state),
        currentBroker: currentBrokerSelector(state),
        currentUser: currentUserSelector(state),
        showAddressModal: brokerAddressUpdateModalShowSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        addressChangeModalShow: (showAddressModal: boolean) => dispatch(currentBrokerAddressUpdateModalShowAction(showAddressModal)),
        aggregatorsList: () => dispatch(publicAggregatorsGetAction()),
        currentBrokerGet: () => dispatch(currentBrokerGetAction()),
        currentUserGet: () => dispatch(currentUserGetAction()),
        emailChangeModalShow: () => dispatch(emailChangeModalShowAction()),
        passwordChangeModalShow: () => dispatch(passwordChangeModalShowAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MyDetails);
