import React from 'react';
import { Button, Container, Form, InputGroup, Spinner } from 'react-bootstrap';
import { FaUser } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { brokerCheckEmailAction, brokerLoginErrorSetAction } from '~Broker/actions';
import { brokerInProgressSelector, brokerLoginErrorsSelector } from '~Broker/selectors';
import { IGlobalState } from '~reducer';
import { validateEmail } from '~validators';
import './broker.less';
import Layout from './Layout';

interface IState {
    email: string;
}

interface IPropsSelector {
    inProgress: boolean;
    loginErrors: any;
}

interface IPropsDispatch {
    checkEmail: (email: string) => void;
    setError: (key: string, value: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Login extends React.Component<Props, IState> {
    public state: IState = {
        email: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onClickContinue = this.onClickContinue.bind(this);

        this.validateEmail = this.validateEmail.bind(this);
    }

    public render(): JSX.Element {
        const { inProgress, loginErrors } = this.props;
        const { email } = this.state;

        return (
            <Layout>
                <div className='broker'>
                    <Container className='login'>
                        <h3>Welcome to Funding Broker</h3>
                        <strong>Accredited Broker</strong>
                        <p>Log in to the Broker Portal with your email.</p>
                        <strong>Not accredited?</strong>
                        <p>Enter your email to get started and accredited.</p>
                        <Form>
                            <Form.Group>
                                <InputGroup>
                                    <InputGroup.Prepend><FaUser/></InputGroup.Prepend>
                                    <Form.Control
                                        isInvalid={!!loginErrors.email || !!loginErrors.code}
                                        name='email'
                                        onBlur={this.validateEmail}
                                        onChange={this.onChangeEmail}
                                        placeholder='Email'
                                        type='email'
                                        value={email || ''}
                                    />
                                    {!!loginErrors.email && <Form.Control.Feedback type='invalid'>{loginErrors.email}</Form.Control.Feedback>}
                                    {!!loginErrors.code && <Form.Control.Feedback type='invalid'>{loginErrors.code}</Form.Control.Feedback>}
                                </InputGroup>
                            </Form.Group>
                            <Button className='continue' disabled={inProgress} onClick={this.onClickContinue} variant='primary'>
                                {inProgress && <Spinner animation='border' as='span' role='status' size='sm' />}
                                {' '}Get Started
                            </Button>
                        </Form>
                    </Container>
                </div>
            </Layout>
        );
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            email: event.target.value,
        });
    }

    private onClickContinue() {
        const { email } = this.state;

        let valid = true;
        valid = this.validateEmail() && valid;

        if (!valid) {
            return;
        }

        this.props.checkEmail(email);
    }

    private validateEmail(): boolean {
        const { email } = this.state;

        let error: string;

        if (!email || email.length === 0) {
            error = 'Please enter your email address';
        } else if (!validateEmail(email)) {
            error = 'Please enter a valid email address';
        }

        this.props.setError('email', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        inProgress: brokerInProgressSelector(state),
        loginErrors: brokerLoginErrorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        checkEmail: (email: string) => dispatch(brokerCheckEmailAction(email)),
        setError: (key: string, value: string) => dispatch(brokerLoginErrorSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Login);
