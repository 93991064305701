import React from 'react';
import { Button } from 'react-bootstrap';
import { BiDownArrowAlt } from 'react-icons/bi';
import DisclosureDocumentsIcon from '~UI/DisclosureDocuments';
import './disclosure-documents.less';
import Layout from './Layout';

class DisclosureDocuments extends React.Component {
    public render(): JSX.Element {
        return (
            <Layout section='get-legal-document'>
                <h1>Disclosure Documents</h1>
                <div className='content-box'>
                    <DisclosureDocumentsIcon />
                    <h2>Downloadable Documents</h2>
                    <p>Please ensure you read and understand these documents before making an investment. Contact our investment team if you have any general information questions.</p>
                    <Button href='https://www.funding.com.au/pds/' target='_blank'>Product Disclosure Statement <BiDownArrowAlt /></Button>
                    <Button href='https://www.funding.com.au/fsg/' target='_blank'>Financial Services Guide <BiDownArrowAlt /></Button>
                </div>
            </Layout>
        );
    }
}

export default DisclosureDocuments;
