import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import IAuthUser from '~Auth/IAuthUser';
import RoleEnum from '~Auth/RoleEnum';
import { authCurrentUserSelector } from '~Auth/selectors';
import history from '~history';
import { IGlobalState } from '~reducer';
import ScrollToTop from '~ScrollToTop';
import Dashboard from './Dashboard';

interface IPropsSelector {
    user: IAuthUser;
}

type Props = IPropsSelector;

class BorrowerRouter extends React.Component<Props> {
    public render(): JSX.Element {
        const { user } = this.props;

        if (user.role !== RoleEnum.Borrower) {
            return null;
        }

        return (
            <Router history={history}>
                <ScrollToTop/>
                <Switch>
                    <Route exact={true} path='/' component={Dashboard} />

                    <Redirect to='/' />
                </Switch>
            </Router>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        user: authCurrentUserSelector(state),
    };
}

export default connect(
    mapStateToProps,
    null,
)(BorrowerRouter);
