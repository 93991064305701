enum AuthActionsEnum {
    Login = 'AUTH/LOGIN',
    LoginErrorSet = 'AUTH/LOGIN/ERROR/SET',
    LoginErrorsSet = 'AUTH/LOGIN/ERRORS/SET',
    LoginInProgressSet = 'AUTH/LOGIN/IN_PROGRESS/SET',

    Logout = 'AUTH/LOGOUT',

    MagicLinkCreate = 'AUTH/MAGIC_LINK/CREATE',
    MagicLinkCreateErrorSet = 'AUTH/MAGIC_LINK/CREATE/ERROR/SET',
    MagicLinkCreateErrorsSet = 'AUTH/MAGIC_LINK/CREATE/ERRORS/SET',
    MagicLinkCreateInProgressSet = 'AUTH/MAGIC_LINK/CREATE/IN_PROGRESS/SET',
    MagicLinkRedeem = 'AUTH/MAGIC_LINK/REDEEM',
    MagicLinkRedeemErrorSet = 'AUTH/MAGIC_LINK/REDEEM/ERROR/SET',

    RegisterErrorSet = 'REGISTER/ERROR/SET',
    RegisterErrorsSet = 'REGISTER/ERRORS/SET',
    RegisterInProgressSet = 'REGISTER/IN_PROGRESS/SET',
    RegisterInvestor = 'REGISTER/INVESTOR',
    RegisterMarketplaceQualifierAnswerSet = 'REGISTER/MARKETPLACE/QUALIFIER/ANSWER/SET',

    TokenSet = 'AUTH/TOKEN/SET',
}

export default AuthActionsEnum;
