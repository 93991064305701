import React from 'react';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestor from '~Api/Investor/IInvestor';
import { authRegisterInProgressSelector } from '~Auth/selectors';
import {
    publicCurrentInvestorAccountIncomeTrustCompleteAction,
    publicCurrentInvestorGetAction,
} from '~Public/actions';
import { publicCurrentInvestorSelector } from '~Public/selectors';
import { IGlobalState } from '~reducer';
import Checkbox from '~UI/Checkbox';
import Layout from './Layout';
import Steps from './Steps';
import { informationMemorandum } from '~utilities/InformationMemorandum';

interface IState {
    agreeInformationMemorandum: boolean;
}

interface IPropsSelector {
    currentInvestor: IInvestor;
    registerInProgress: boolean;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
    incomeTrustAccountComplete: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class InformationMemorandum extends React.Component<Props, IState> {
    public state: IState = {
        agreeInformationMemorandum: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAgreeInformationMemorandum = this.onChangeAgreeInformationMemorandum.bind(this);

        this.onClickComplete = this.onClickComplete.bind(this);
    }

    public componentDidMount(): void {
        const { currentInvestor } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }
    }

    public render(): JSX.Element {
        const { registerInProgress } = this.props;
        const { agreeInformationMemorandum } = this.state;

        return (
            <Layout>
                <Steps currentStep={5} />

                <Container className='register-information-memorandum'>
                    <h3>Information Memorandum</h3>
                    <p dangerouslySetInnerHTML={{ __html: informationMemorandum }}></p>
                    <Form>
                        <Form.Group className='agree-information-memorandum'>
                            <Checkbox
                                checked={agreeInformationMemorandum}
                                label='I/We agree to all of the above.'
                                onChange={this.onChangeAgreeInformationMemorandum}
                            />
                        </Form.Group>
                        <Link to='/invest/income-trust/distribution-preference' className='previous'>
                            <Button variant='primary'>Previous</Button>
                        </Link>
                        <Button className='continue' disabled={!agreeInformationMemorandum || registerInProgress} onClick={this.onClickComplete} variant='primary'>
                            {registerInProgress && <Spinner animation='border' as='span' role='status' size='sm' />}
                            {!registerInProgress && 'Done'}
                        </Button>
                    </Form>
                </Container>
            </Layout>
        );
    }

    private onClickComplete(): void {
        this.props.incomeTrustAccountComplete();
    }

    private onChangeAgreeInformationMemorandum(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ agreeInformationMemorandum: event.currentTarget.checked });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: publicCurrentInvestorSelector(state),
        registerInProgress: authRegisterInProgressSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(publicCurrentInvestorGetAction()),
        incomeTrustAccountComplete: () => dispatch(publicCurrentInvestorAccountIncomeTrustCompleteAction(informationMemorandum)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InformationMemorandum);
