import { all, call, debounce, put, select, takeEvery } from '@redux-saga/core/effects';
import Cookies from 'js-cookie';
import _ from 'lodash';
import BorrowerCategoryEnum from '~Api/Application/BorrowerCategoryEnum';
import BorrowerTypeEnum from '~Api/Application/BorrowerTypeEnum';
import IApplication from '~Api/Application/IApplication';
import IApplicationBorrower from '~Api/Application/IApplicationBorrower';
import {
    parseApplication,
    parseApplicationBorrower,
} from '~Api/Application/parsers';
import {
    applicationCompleteRequest,
    applicationGetRequest,
    applicationUpdateRequest,
    applicationsListRequest,
    borrowerDeleteRequest,
    borrowerUpdateRequest,
    borrowersAddRequest,
} from '~Api/Application/requests';
import AddressVersionEnum from '~Api/Deal/AddressVersionEnum';
import IDeal from '~Api/Deal/IDeal';
import IDealProperty from '~Api/Deal/IDealProperty';
import {
    parseDeal,
    parseProperty,
} from '~Api/Deal/parsers';
import {
    dealGetRequest,
    dealPropertiesAddRequest,
    dealPropertyDeleteRequest,
    dealPropertyUpdateRequest,
    dealQuoteCreateApplicationRequest,
} from '~Api/Deal/requests';
import history from '~history';
import { IFetchResponse } from '~utilities/fetch';
import {
    IApplicationBorrowerTypeAction,
    IApplicationCompleteAction,
    IApplicationSendAction,
    IApplicationValueSetAction,
    IApplicationsListAction,
    IBorrowerDeleteAction,
    IBorrowerSendAction,
    IBorrowerValueSetAction,
    IDealGetAction,
    IDealQuoteConvertAction,
    IPropertiesAddAction,
    IPropertyDeleteAction,
    IPropertySendAction,
    IPropertyValueSetAction,
    applicationSavingSetAction,
    applicationSendAction,
    applicationSetAction,
    applicationsSetAction,
    borrowerSendAction,
    borrowerSetAction,
    dealSetAction,
    propertySendAction,
    propertySetAction,
    tokenSetAction,
} from './actions';
import ApplicationActionsEnum from './ActionsEnum';
import {
    allBorrowersSelector,
    applicationSelector,
    borrowerSelector,
    currentDealSelector,
    dealUuidSelector,
    primaryBorrowerSelector,
    propertySelector,
} from './selectors';
import { verifyToken } from '~utilities/jwt';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* applicationBorrowerType(action: IApplicationBorrowerTypeAction): Iterator<unknown> {
    yield put(applicationSavingSetAction(true));

    const application: IApplication = yield select(applicationSelector);
    const borrowers: IApplicationBorrower[] = yield select(allBorrowersSelector);

    const primaryBorrower: IApplicationBorrower = _.find(borrowers, { uuid: application.primaryBorrowerUuid });

    if ([BorrowerTypeEnum.Company, BorrowerTypeEnum.Trust].includes(primaryBorrower.type)) {
        if (!_.some(borrowers, { category: BorrowerCategoryEnum.Guarantor })) {
            const rawBorrower: IFetchResponse = yield call(borrowersAddRequest, application, {
                abn: null,
                address: null,
                businessName: null,
                category: BorrowerCategoryEnum.Guarantor,
                dependentNumber: null,
                dob: null,
                email: null,
                firstName: null,
                gender: null,
                idType: null,
                incomeType: null,
                jobTitle: null,
                lastName: null,
                licenceNumber: null,
                licenceState: null,
                maritalStatus: null,
                middleName: null,
                monthlyGrossExpenses: null,
                monthlyGrossTurnover: null,
                monthlyHouseholdExpenses: null,
                onTitle: null,
                otherIncomeType: null,
                passportCountry: null,
                passportNumber: null,
                phone: null,
                trustName: null,
                trusteeName: null,
                type: BorrowerTypeEnum.Individual,
                yearlyIncome: null,
            }, 'application');
            const borrower: IApplicationBorrower = parseApplicationBorrower(rawBorrower.body);
            yield put(borrowerSetAction(borrower));
        }
    }

    history.push('/application/borrowers');

    yield put(applicationSavingSetAction(false));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* applicationComplete(action: IApplicationCompleteAction): Iterator<unknown> {
    yield put(applicationSavingSetAction(true));

    const application: IApplication = yield select(applicationSelector);

    yield call(applicationCompleteRequest, application.uuid, 'application');

    yield put(tokenSetAction(null));
    Cookies.remove('application');

    history.push('/application/thanks');

    yield put(applicationSavingSetAction(false));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* applicationSend(action: IApplicationSendAction): Iterator<unknown> {
    const application: IApplication = yield select(applicationSelector);
    yield call(applicationUpdateRequest, application, 'application');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* applicationValueSet(action: IApplicationValueSetAction): Iterator<unknown> {
    yield put(applicationSendAction());
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* applicationsList(action: IApplicationsListAction): Iterator<unknown> {
    const applicationsListResponse: IFetchResponse = yield call(applicationsListRequest);
    const applications: IApplication[] = yield Promise.all(applicationsListResponse.body.map(parseApplication));
    yield put(applicationsSetAction(applications));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* dealGet(action: IDealGetAction): Iterator<unknown> {
    const dealUuid: string = yield select(dealUuidSelector);
    const rawDeal: IFetchResponse = yield call(dealGetRequest, dealUuid, 'application');
    const deal: IDeal = parseDeal(rawDeal.body);
    yield put(dealSetAction(deal));

    const rawApplication: IFetchResponse = yield call(applicationGetRequest, deal.applicationUuid, 'application');
    const application: IApplication = parseApplication(rawApplication.body);
    yield put(applicationSetAction(application));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* borrowersAdd(action: IPropertiesAddAction): Iterator<unknown> {
    yield put(applicationSavingSetAction(true));

    const application: IApplication = yield select(applicationSelector);
    const primaryBorrower: IApplicationBorrower = yield select(primaryBorrowerSelector);
    const rawBorrower: IFetchResponse = yield call(borrowersAddRequest, application, {
        abn: null,
        address: null,
        businessName: null,
        category: primaryBorrower.type === BorrowerTypeEnum.Individual ? BorrowerCategoryEnum.Borrower : BorrowerCategoryEnum.Guarantor,
        dependentNumber: null,
        dob: null,
        email: null,
        firstName: null,
        gender: null,
        idType: null,
        incomeType: null,
        jobTitle: null,
        lastName: null,
        licenceNumber: null,
        licenceState: null,
        maritalStatus: null,
        middleName: null,
        monthlyGrossExpenses: null,
        monthlyGrossTurnover: null,
        monthlyHouseholdExpenses: null,
        onTitle: null,
        otherIncomeType: null,
        passportCountry: null,
        passportNumber: null,
        phone: null,
        trustName: null,
        trusteeName: null,
        type: BorrowerTypeEnum.Individual,
        yearlyIncome: null,
    }, 'application');
    const borrower: IApplicationBorrower = parseApplicationBorrower(rawBorrower.body);
    yield put(borrowerSetAction(borrower));

    yield put(applicationSavingSetAction(false));
}

function* borrowerDelete(action: IBorrowerDeleteAction): Iterator<unknown> {
    yield call(borrowerDeleteRequest, action.uuid, 'application');
}

function* borrowerSend(action: IBorrowerSendAction): Iterator<unknown> {
    const borrower: IApplicationBorrower = yield select(borrowerSelector, action.uuid);
    if (borrower && borrower.uuid) {
        yield call(borrowerUpdateRequest, borrower, 'application');
    }
}

function* borrowerValueSet(action: IBorrowerValueSetAction): Iterator<unknown> {
    yield put(borrowerSendAction(action.uuid));
}

function* dealQuoteConvert(action: IDealQuoteConvertAction): Iterator<unknown> {
    yield put(applicationSavingSetAction(true));

    const rawDeal: IFetchResponse = yield call(dealQuoteCreateApplicationRequest, action.code);
    if (rawDeal.status === 404) {
        history.push('/quote/error');
    } else if (rawDeal.status === 200) {
        const token: string = rawDeal.headers.get('Funding-Auth-Token');

        verifyToken(token);

        yield put(tokenSetAction(token));
        Cookies.set('application', {
            token,
        });

        const deal: IDeal = parseDeal(rawDeal.body);
        yield put(dealSetAction(deal));

        const rawApplication: IFetchResponse = yield call(applicationGetRequest, deal.applicationUuid, 'application');
        const application: IApplication = parseApplication(rawApplication.body);

        if (deal.properties.length === 0) {
            const rawProperty: IFetchResponse = yield call(dealPropertiesAddRequest, deal, {
                addressVersion: AddressVersionEnum.V2,
                currentDebt: null,
                errors: {},
                estimatedValue: null,
                postcode: null,
                state: null,
                streetAddress: null,
                suburb: null,
                zoneType: null,
            }, 'application');
            const property: IDealProperty = parseProperty(rawProperty.body);
            yield put(propertySetAction(property));
        }

        if (application.borrowers.length === 0) {
            const rawBorrower: IFetchResponse = yield call(borrowersAddRequest, application, {
                abn: null,
                address: null,
                businessName: null,
                category: BorrowerCategoryEnum.Borrower,
                dependentNumber: null,
                dob: null,
                email: null,
                firstName: null,
                gender: null,
                idType: null,
                incomeType: null,
                jobTitle: null,
                lastName: null,
                licenceNumber: null,
                licenceState: null,
                maritalStatus: null,
                middleName: null,
                monthlyGrossExpenses: null,
                monthlyGrossTurnover: null,
                monthlyHouseholdExpenses: null,
                onTitle: null,
                otherIncomeType: null,
                passportCountry: null,
                passportNumber: null,
                phone: null,
                trustName: null,
                trusteeName: null,
                type: null,
                yearlyIncome: null,
            }, 'application');
            const borrower: IApplicationBorrower = parseApplicationBorrower(rawBorrower.body);
            yield put(borrowerSetAction(borrower));

            application.primaryBorrowerUuid = borrower.uuid;
        }

        yield put(applicationSetAction(application));

        history.push('/application');
    }

    yield put(applicationSavingSetAction(false));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* propertiesAdd(action: IPropertiesAddAction): Iterator<unknown> {
    yield put(applicationSavingSetAction(true));

    const deal: IDeal = yield select(currentDealSelector);
    const property: IDealProperty = {
        addressVersion: AddressVersionEnum.V2,
        currentDebt: null,
        errors: {},
        estimatedValue: null,
        postcode: null,
        state: null,
        streetAddress: null,
        streetName: null,
        streetNumber: null,
        streetType: null,
        suburb: null,
        unitNumber: null,
        zoneType: null,
    };
    const rawProperty: IFetchResponse = yield call(dealPropertiesAddRequest, deal, property, 'application');
    const parsedProperty: IDealProperty = parseProperty(rawProperty.body);
    yield put(propertySetAction(parsedProperty));

    yield put(applicationSavingSetAction(false));
}

function* propertyDelete(action: IPropertyDeleteAction): Iterator<unknown> {
    yield call(dealPropertyDeleteRequest, action.uuid, 'application');
}

function* propertySend(action: IPropertySendAction): Iterator<unknown> {
    const property: IDealProperty = yield select(propertySelector, action.uuid);
    if (property && property.uuid) {
        yield call(dealPropertyUpdateRequest, property, 'application');
    }
}

function* propertyValueSet(action: IPropertyValueSetAction): Iterator<unknown> {
    yield put(propertySendAction(action.uuid));
}

export function* ApplicationSagas(): Iterator<unknown> {
    yield all([
        takeEvery(ApplicationActionsEnum.ApplicationComplete, applicationComplete),
        takeEvery(ApplicationActionsEnum.ApplicationBorrowerType, applicationBorrowerType),
        debounce(500, ApplicationActionsEnum.ApplicationSend, applicationSend),
        takeEvery(ApplicationActionsEnum.ApplicationValueSet, applicationValueSet),

        takeEvery(ApplicationActionsEnum.ApplicationsList, applicationsList),

        takeEvery(ApplicationActionsEnum.BorrowersAdd, borrowersAdd),

        takeEvery(ApplicationActionsEnum.BorrowerDelete, borrowerDelete),
        debounce(500, ApplicationActionsEnum.BorrowerSend, borrowerSend),
        takeEvery(ApplicationActionsEnum.BorrowerValueSet, borrowerValueSet),

        takeEvery(ApplicationActionsEnum.DealGet, dealGet),
        takeEvery(ApplicationActionsEnum.DealQuoteConvert, dealQuoteConvert),

        takeEvery(ApplicationActionsEnum.PropertiesAdd, propertiesAdd),

        takeEvery(ApplicationActionsEnum.PropertyDelete, propertyDelete),
        debounce(500, ApplicationActionsEnum.PropertySend, propertySend),
        takeEvery(ApplicationActionsEnum.PropertyValueSet, propertyValueSet),
    ]);
}
