enum LeadActionsEnum {
    DealAdd = 'LEAD/DEAL/ADD',
    DealBorrowStepSet = 'LEAD/DEAL/BORROW_STEP/SET',
    DealComplete = 'LEAD/DEAL/COMPLETE',
    DealErrorSet = 'LEAD/DEAL/ERROR/SET',
    DealGet = 'LEAD/DEAL/GET',
    DealSend = 'LEAD/DEAL/SEND',
    DealSet = 'LEAD/DEAL/SET',
    DealValueSet = 'LEAD/DEAL/VALUE/SET',

    DealsList = 'DEALS/LIST',
    DealsSet = 'DEALS/SET',

    DealQuotesList = 'LEAD/QUOTES/LIST',
    DealQuotesSet = 'LEAD/QUOTES/SET',

    ReferrerTokenGet = 'LEAD/REFERRER/TOKEN/GET',

    PropertiesAdd = 'LEAD/PROPERTIES/ADD',

    PropertyDelete = 'LEAD/PROPERTY/DELETE',
    PropertyErrorSet = 'LEAD/PROPERTY/ERROR/SET',
    PropertySend = 'LEAD/PROPERTY/SEND',
    PropertySet = 'LEAD/PROPERTY/SET',
    PropertyValueSet = 'LEAD/PROPERTY/VALUE/SET',
}

export default LeadActionsEnum;
