import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { Alert, Button, Form, ProgressBar, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import {
    currentInvestorGetAction,
    investmentGetAction,
    investmentInvestAction,
    investmentInvestAmountSetAction,
    investmentInvestCompleteModalShowSetAction,
    investmentInvestErrorSetAction,
    investmentInvestModalShowSetAction,
} from '~Investor/actions';
import { IInvestErrors } from '~Investor/reducer';
import {
    currentInvestorAccountSelector,
    currentInvestorSelector,
    investAmountSelector,
    investCompleteModalShowSelector,
    investErrorsSelector,
    investModalShowSelector,
    investmentSelector,
} from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import './investment.less';
import InvestModal from './InvestModal';
import Layout from './Layout';

interface IMatch {
    funded?: string;
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    amount: number;
    completeModalShow: boolean;
    currentInvestor: IInvestor;
    errors: IInvestErrors;
    investment: IInvestment;
    marketplaceAccount: IAccount;
    modalShow: boolean;
}

interface IPropsDispatch {
    amountSet: (amount: number) => void;
    currentInvestorGet: () => void;
    hideCompleteModal: () => void;
    hideModal: () => void;
    invest: (amount: number) => void;
    investmentGet: () => void;
    setError: (key: keyof IInvestErrors, value: string) => void;
    showModal: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Investment extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.onClickInvest = this.onClickInvest.bind(this);
        this.validateAmount = this.validateAmount.bind(this);
    }

    public componentDidMount(): void {
        const { currentInvestor, investment } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }

        if (!investment) {
            this.props.investmentGet();
        }
    }

    public render(): JSX.Element {
        const {
            amount,
            currentInvestor,
            errors,
            investment,
        } = this.props;

        if (!currentInvestor || !investment) {
            return (
                <Layout section='investment-details'>
                    <h1>Investment Details</h1>
                    <div className='content-box'>
                        <Spinner animation='border' />
                    </div>
                </Layout>
            );
        }

        const termBlock: JSX.Element = investment.amountRemaining === 0 ? (
            <div className='term'>
                <div className='label'>Term</div>
                <div className='value'>
                    <div className='number'>{investment.termMonths}</div>
                    <div className='suffix'>Month{investment.termMonths > 1 && 's'}</div>
                </div>
            </div>
        ) : (
            <div className='term-remaining'>
                <div className='label'>Term remaining</div>
                <div className='value'>
                    {investment.timeRemaining.months > 0 && <React.Fragment><div className='number'>{investment.timeRemaining.months}</div><div className='suffix'>Month{investment.timeRemaining.months > 1 && 's'}</div></React.Fragment>}
                    {investment.timeRemaining.days > 0 && <React.Fragment><div className='number'>{investment.timeRemaining.days}</div><div className='suffix'>Day{investment.timeRemaining.days > 1 && 's'}</div></React.Fragment>}
                </div>
            </div>
        );

        const photoBlock: JSX.Element = investment.photos.length > 0 && (
            <div className='photo'><img src={investment.photos[0].url} /></div>
        );

        const descriptionBlock: JSX.Element = investment.description && (
            <div className='description' dangerouslySetInnerHTML={{ __html: investment.description }} />
        );

        const firstUnhandledError: string = _.head(_.values(_.omit(errors, ['amount'])));

        const investBlock: JSX.Element = investment.amountRemaining === 0 ? (
            <p>Sorry, this investment is now closed.</p>
        ) : (
            <React.Fragment>
                {firstUnhandledError && <Alert variant='danger'>{firstUnhandledError}</Alert>}
                <Form.Group className='amount'>
                    <Form.Control isInvalid={!!errors.amount} onBlur={this.validateAmount} onChange={this.onChangeAmount} placeholder='Enter amount $' type='number' value={amount || ''} />
                    {!!errors.amount && <Form.Control.Feedback type='invalid'>{errors.amount}</Form.Control.Feedback>}
                </Form.Group>
                <Button onClick={this.onClickInvest}>Invest Now</Button>
            </React.Fragment>
        );

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const progressFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        let remainingPercentage: number = (investment.amountTotal - investment.amountRemaining) / investment.amountTotal * 100;
        if (investment.amountRemaining > 0 && remainingPercentage > 99) {
            remainingPercentage = 99;
        }

        return (
            <Layout section='investment-details'>
                <h1>Investment Details</h1>
                <div className='content-box invest-box'>
                    <h3>Investment Actions</h3>
                    {investBlock}
                </div>
                <div className='content-box'>
                    {photoBlock}
                    <div className='investment-progress'>
                        <div className='progress-graph'>
                            <ProgressBar now={remainingPercentage} />
                        </div>
                        <div className='funded'>{progressFormatter.format(remainingPercentage / 100)} Funded</div>
                        <div className='remaining'>{numeral(investment.amountRemaining > 0 ? investment.amountRemaining : 0).format('$0,0[.]00')} Remaining</div>
                    </div>
                    <h2 className='name'>{investment.name}</h2>
                    <div className='code'>{investment.code}</div>
                    <h3 className='loan-overview'>Loan Overview</h3>
                    <div className='target-return'>
                        <div className='label'>Target Return</div>
                        <div className='value'>{percentageFormatter.format(investment.interestRate / 100)} pa net*</div>
                    </div>
                    <div className='lvr'>
                        <div className='label'>LVR</div>
                        <div className='value'>{percentageFormatter.format(investment.lvr / 100)}</div>
                    </div>
                    {termBlock}
                    <div className='available'>
                        <div className='label'>Available</div>
                        <div className='value'>{numeral(investment.amountRemaining > 0 ? investment.amountRemaining : 0).format('$0,0[.]00')}</div>
                    </div>
                    <h3 className='loan-details'>Loan Details</h3>
                    <div className='total-loan-amount'>
                        <div className='label'>Total loan amount</div>
                        <div className='value'>{numeral(investment.amountTotal).format('$0,0[.]00')}</div>
                    </div>
                    <div className='start-date'>
                        <div className='label'>Start date</div>
                        <div className='value'>{investment.startDate ? moment(investment.startDate).format('Do MMMM YYYY') : '-'}</div>
                    </div>
                    <div className='scheduled-end-date'>
                        <div className='label'>Scheduled end date</div>
                        <div className='value'>{investment.endDate ? moment(investment.endDate).format('Do MMMM YYYY') : '-'}</div>
                    </div>
                    <h3 className='property-and-security'>Property &amp; Security</h3>
                    <div className='property-value'>
                        <div className='label'>Property value</div>
                        <div className='value'>{investment.propertyValue ? numeral(investment.propertyValue).format('$0,0[.]00') : '-'}</div>
                    </div>
                    <div className='property-type'>
                        <div className='label'>Property type</div>
                        <div className='value'>{investment.propertyType || '-'}</div>
                    </div>
                    <div className='property-security'>
                        <div className='label'>Property security</div>
                        <div className='value'>{investment.propertySecurity || 'First Mortgage'}</div>
                    </div>
                    {descriptionBlock}
                </div>
                <InvestModal
                    uuid={investment.uuid}
                />
            </Layout>
        );
    }

    private onChangeAmount(event: React.ChangeEvent<HTMLInputElement>): void {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.props.amountSet(Number(event.target.value));
    }

    private onClickInvest(): void {
        let valid: boolean = true;
        valid = this.validateAmount() && valid;

        if (!valid) {
            return;
        }

        this.props.showModal();
    }

    private validateAmount(): boolean {
        const { amount, investment, marketplaceAccount } = this.props;

        let error: string;

        const minimumInvestmentAmount: number = investment.amountRemaining < 1000 ? investment.amountRemaining : 1000;

        if (!amount) {
            error = 'Please enter the amount';
        } else if (amount < minimumInvestmentAmount) {
            error = `The minimum investment amount is $${minimumInvestmentAmount}`;
        } else if (amount > investment.amountRemaining) {
            error = 'The amount is more than is available for this investment';
        } else if (amount > marketplaceAccount.balance) {
            error = 'The amount is more than your cash balance';
        }

        this.props.setError('amount', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        amount: investAmountSelector(state),
        completeModalShow: investCompleteModalShowSelector(state),
        currentInvestor: currentInvestorSelector(state),
        errors: investErrorsSelector(state),
        investment: investmentSelector(state, ownProps.match.params.uuid),
        marketplaceAccount: currentInvestorAccountSelector(state, AccountTypeEnum.Marketplace),
        modalShow: investModalShowSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        amountSet: (amount: number) => dispatch(investmentInvestAmountSetAction(ownProps.match.params.uuid, amount)),
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
        hideCompleteModal: () => dispatch(investmentInvestCompleteModalShowSetAction(false)),
        hideModal: () => dispatch(investmentInvestModalShowSetAction(false)),
        invest: (amount: number) => dispatch(investmentInvestAction(ownProps.match.params.uuid, amount)),
        investmentGet: () => dispatch(investmentGetAction(ownProps.match.params.uuid)),
        setError: (key: keyof IInvestErrors, value: string) => dispatch(investmentInvestErrorSetAction(key, value)),
        showModal: () => dispatch(investmentInvestModalShowSetAction(true)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Investment);
