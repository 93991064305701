import React from 'react';
import { Nav, Spinner } from 'react-bootstrap';
import { BiBarChartSquare, BiRightArrowAlt } from 'react-icons/bi';
import { HiOutlineNewspaper } from 'react-icons/hi';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import { currentInvestorGetAction } from '~Investor/actions';
import InvestorLayout from '~Investor/Layout';
import {
    currentInvestorAccountSelector,
    currentInvestorSelector,
} from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import Footer from '../Footer';
import './register.less';

interface IProps {
    children: JSX.Element | JSX.Element[];
    section: string;
}

interface IPropsSelector {
    currentInvestor: IInvestor;
    incomeTrustAccount: IAccount;
    marketplaceAccount: IAccount;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Layout extends React.Component<Props> {
    public componentDidMount(): void {
        const { currentInvestor } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }
    }

    public render(): JSX.Element {
        const { currentInvestor, incomeTrustAccount, marketplaceAccount, section } = this.props;

        if (!currentInvestor) {
            return (
                <div className='investor'>
                    <Spinner animation='border' />
                </div>
            );
        }

        const leftNav: JSX.Element = (
            <React.Fragment>
                <Nav activeKey={section} className='flex-column'>
                    <Nav.Item as={NavLink} to='/income-trust/register'><BiBarChartSquare />Income Trust</Nav.Item>
                    {marketplaceAccount && <Nav.Item as={NavLink} exact={true} className='marketplace-account' to='/marketplace'><HiOutlineNewspaper />Marketplace<BiRightArrowAlt className='arrow-right' /></Nav.Item>}
                </Nav>
            </React.Fragment>
        );

        return (
            <InvestorLayout
                section={`income-trust-register ${section}`}
                footer={<Footer />}
                leftNav={leftNav}
            >
                {this.props.children}
            </InvestorLayout >
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        incomeTrustAccount: currentInvestorAccountSelector(state, AccountTypeEnum.IncomeTrust),
        marketplaceAccount: currentInvestorAccountSelector(state, AccountTypeEnum.Marketplace),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Layout);
