import { fetchDeleteUtility, fetchGetUtility, fetchPostUtility, fetchPutUtility } from '~utilities/fetch';
import IApplication from './IApplication';
import IApplicationBorrower from './IApplicationBorrower';
import {
    transformApplication,
    transformBorrower,
} from './transformers';

export async function applicationCompleteRequest(applicationUuid: string, tokenName: string): Promise<Response> {
    return fetchPostUtility(`/applications/${applicationUuid}/complete`, null, tokenName);
}

export async function applicationGetRequest(applicationUuid: string, tokenName: string): Promise<Response> {
    return fetchGetUtility(`/applications/${applicationUuid}`, tokenName);
}

export async function applicationUpdateRequest(application: IApplication, tokenName: string): Promise<Response> {
    return fetchPutUtility(`/applications/${application.uuid}`, transformApplication(application), tokenName);
}

export async function applicationsListRequest(): Promise<Response> {
    return fetchGetUtility(`/applications`);
}

export async function borrowersAddRequest(application: IApplication, borrower: IApplicationBorrower, tokenName: string): Promise<Response> {
    return fetchPostUtility(`/applications/${application.uuid}/borrowers`, transformBorrower(borrower), tokenName);
}

export async function borrowerDeleteRequest(borrowerUuid: string, tokenName: string): Promise<Response> {
    return fetchDeleteUtility(`/application-borrowers/${borrowerUuid}`, tokenName);
}

export async function borrowerUpdateRequest(borrower: IApplicationBorrower, tokenName: string): Promise<Response> {
    return fetchPutUtility(`/application-borrowers/${borrower.uuid}`, transformBorrower(borrower), tokenName);
}
