import Cookies from 'js-cookie';
import _ from 'lodash';
import BorrowerCategoryEnum from '~Api/Application/BorrowerCategoryEnum';
import IApplication from '~Api/Application/IApplication';
import IApplicationBorrower from '~Api/Application/IApplicationBorrower';
import IApplicationBorrowerErrors from '~Api/Application/IApplicationBorrowerErrors';
import IApplicationErrors from '~Api/Application/IApplicationErrors';
import { IDeal, IDealProperty } from '~Api/Deal';
import { IDealPropertyErrors } from '~Api/Deal/IDealProperty';
import IToken from '~Auth/IToken';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { verifyToken } from '~utilities/jwt';

function hydrateApplication(state: IGlobalState, application: IApplication): IApplication {
    const cloneApplication: IApplication = { ...application };
    cloneApplication.deal = dealSelector(state, application.dealUuid);
    return cloneApplication;
}

export function applicationSelector(state: IGlobalState): IApplication {
    return state.application.application;
}

export function allBorrowersSelector(state: IGlobalState): IApplicationBorrower[] {
    return _.values(state.application.borrowers);
}

export function borrowerErrorsSelector(state: IGlobalState, uuid: string): IApplicationBorrowerErrors {
    return state.application.borrowerErrors[uuid] || {};
}

export function borrowerSelector(state: IGlobalState, uuid: string): IApplicationBorrower {
    return state.application.borrowers[uuid];
}

export function applicationsSelector(state: IGlobalState): IDictionary<IApplication> {
    if (!state.application.applications || !state.application.applicationsListed) {
        return null;
    }

    const applications: IDictionary<IApplication> = {};
    _.forEach(state.application.applications, (application: IApplication) => {
        applications[application.uuid] = hydrateApplication(state, application);
    });

    return applications;
}

export function currentDealSelector(state: IGlobalState): IDeal {
    return state.application.deal;
}

export function dealSelector(state: IGlobalState, dealUuid: string): IDeal {
    if (!state.lead.deals || !state.lead.deals[dealUuid]) {
        return null;
    }

    return state.lead.deals[dealUuid];
}

export function dealUuidSelector(state: IGlobalState): string {
    const decodedToken: IToken = tokenSelector(state);

    return decodedToken.dealUuid;
}

export function errorsSelector(state: IGlobalState): IApplicationErrors {
    return state.application.errors;
}

export function guarantorsSelector(state: IGlobalState): IApplicationBorrower[] {
    const allBorrowers: IApplicationBorrower[] = allBorrowersSelector(state);

    return _.filter(allBorrowers, { category: BorrowerCategoryEnum.Guarantor });
}

export function nonPrimaryBorrowersSelector(state: IGlobalState): IApplicationBorrower[] {
    const application: IApplication = applicationSelector(state);
    const allBorrowers: IApplicationBorrower[] = allBorrowersSelector(state);

    return application && _.filter(allBorrowers, (loopBorrower: IApplicationBorrower) => loopBorrower.category === BorrowerCategoryEnum.Borrower && loopBorrower.uuid !== application.primaryBorrowerUuid);
}

export function primaryBorrowerSelector(state: IGlobalState): IApplicationBorrower {
    const application: IApplication = applicationSelector(state);
    const allBorrowers: IApplicationBorrower[] = allBorrowersSelector(state);

    return application && _.find(allBorrowers, { uuid: application.primaryBorrowerUuid });
}

export function propertiesSelector(state: IGlobalState): IDealProperty[] {
    return _.values(state.application.properties);
}

export function propertyErrorsSelector(state: IGlobalState, uuid: string): IDealPropertyErrors {
    return state.application.propertyErrors[uuid] || {};
}

export function propertySelector(state: IGlobalState, uuid: string): IDealProperty {
    return state.application.properties[uuid];
}

export function savingSelector(state: IGlobalState): boolean {
    return state.application.saving;
}

export function tokenSelector(state: IGlobalState): IToken {
    if (state.application.token) {
        return state.application.token;
    }

    const cookie: any = Cookies.getJSON('application');

    if (!cookie) {
        return null;
    }

    try {
        const decodedToken: IToken = verifyToken(cookie.token);

        return decodedToken;
    } catch (e: any) {
        switch (e.name) {
            case 'TokenExpiredError':
                return null;
            default:
                throw e;
        }
    }

    return null;
}
