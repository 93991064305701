import _ from 'lodash';
import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { IApplication } from '~Api/Application';
import BorrowerCategoryEnum from '~Api/Application/BorrowerCategoryEnum';
import BorrowerTypeEnum from '~Api/Application/BorrowerTypeEnum';
import IApplicationBorrower from '~Api/Application/IApplicationBorrower';
import IApplicationBorrowerErrors from '~Api/Application/IApplicationBorrowerErrors';
import { IDeal, IDealProperty } from '~Api/Deal';
import history from '~history';
import { IGlobalState } from '~reducer';
import {
    borrowerErrorSetAction,
    borrowersAddAction,
    borrowerValueSetAction,
    dealGetAction,
} from './actions';
import Borrower from './Borrower';
import Layout from './Layout';
import {
    allBorrowersSelector,
    applicationSelector,
    currentDealSelector,
    guarantorsSelector,
    nonPrimaryBorrowersSelector,
    primaryBorrowerSelector,
    propertiesSelector,
    savingSelector,
} from './selectors';
import { validateBorrower } from './validators';

const individualNames: { [borrowerType: string]: string } = {
    [BorrowerTypeEnum.Company]: 'Director',
    [BorrowerTypeEnum.Individual]: 'Individual',
    [BorrowerTypeEnum.Trust]: 'Trustee Individual/Director',
};

interface IPropsSelector {
    allBorrowers: IApplicationBorrower[];
    application: IApplication;
    deal: IDeal;
    guarantors: IApplicationBorrower[];
    nonPrimaryBorrowers: IApplicationBorrower[];
    primaryBorrower: IApplicationBorrower;
    properties: IDealProperty[];
    saving: boolean;
}

interface IPropsDispatch {
    borrowerErrorSet: (uuid: string, field: keyof IApplicationBorrowerErrors, value: string) => void;
    borrowersAdd: () => void;
    dealGet: () => void;
    valueSet: (uuid: string, field: keyof IApplicationBorrower, value: any) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Borrowers extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickContinue = this.onClickContinue.bind(this);
    }

    public componentDidMount() {
        const { application, deal } = this.props;

        if (!application || !deal) {
            this.props.dealGet();
        }
    }

    public render(): JSX.Element {
        const {
            application,
            deal,
            guarantors,
            nonPrimaryBorrowers,
            primaryBorrower,
            saving,
        } = this.props;

        if (!application || !deal) {
            return (
                <Layout containerClass='borrowers' stepNumber={3}>
                    <h3>Borrower Details</h3>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        if (!primaryBorrower) {
            return (
                <Layout containerClass='borrowers' stepNumber={3}>
                    <h3>Borrower Details</h3>
                    <p>Oops there's a problem with your application. Please contact us.</p>
                </Layout>
            );
        }

        return (
            <Layout containerClass='borrowers' stepNumber={3}>
                <h3>Borrower Details</h3>
                <p className='intro'>Enter your details below.</p>
                <Form>
                    <Borrower uuid={primaryBorrower.uuid} />
                    {primaryBorrower.type === BorrowerTypeEnum.Individual && nonPrimaryBorrowers.map((nonPrimaryBorrower: IApplicationBorrower, index: number) => <Borrower uuid={nonPrimaryBorrower.uuid} index={index} key={nonPrimaryBorrower.uuid} />)}
                    {[BorrowerTypeEnum.Company, BorrowerTypeEnum.Trust].includes(primaryBorrower.type) && guarantors.map((guarantor: IApplicationBorrower, index: number) => <Borrower uuid={guarantor.uuid} index={index} key={guarantor.uuid} />)}
                    <Button className='add-borrower' disabled={saving} onClick={this.props.borrowersAdd} variant='light'>
                        {!saving && `Add Another ${individualNames[primaryBorrower.type]}`}
                        {saving && <Spinner animation='border' as='span' role='status' size='sm' />}
                    </Button>
                    <div className='actions'>
                        <Link className='previous' to='/application/borrower-type'><Button disabled={saving} variant='primary'>Previous</Button></Link>
                        <Button className='continue' disabled={saving} onClick={this.onClickContinue} variant='primary'>Continue</Button>
                    </div>
                </Form>
            </Layout>
        );
    }

    private onClickContinue() {
        let valid = true;

        valid = this.validateBorrowers() && valid;

        if (!valid) {
            return;
        }

        history.push(`/application/submit`);
    }

    private validateBorrowers(): boolean {
        const { allBorrowers, primaryBorrower } = this.props;

        let valid: boolean = true;

        allBorrowers.forEach((loopBorrower: IApplicationBorrower) => {
            if (loopBorrower.type === BorrowerTypeEnum.Individual) {
                if (![BorrowerTypeEnum.Company, BorrowerTypeEnum.Trust].includes(primaryBorrower.type) && loopBorrower.category === BorrowerCategoryEnum.Guarantor) {
                    return;
                }

                if ([BorrowerTypeEnum.Company, BorrowerTypeEnum.Trust].includes(primaryBorrower.type) && loopBorrower.category === BorrowerCategoryEnum.Borrower) {
                    return;
                }
            } else if (loopBorrower.category === BorrowerCategoryEnum.Borrower) {
                return;
            }

            const setError = (key: keyof IApplicationBorrowerErrors, value: string) => this.props.borrowerErrorSet(loopBorrower.uuid, key, value);

            valid = validateBorrower(loopBorrower, setError) && valid;
        });

        return valid;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        allBorrowers: allBorrowersSelector(state),
        application: applicationSelector(state),
        deal: currentDealSelector(state),
        guarantors: guarantorsSelector(state),
        nonPrimaryBorrowers: nonPrimaryBorrowersSelector(state),
        primaryBorrower: primaryBorrowerSelector(state),
        properties: propertiesSelector(state),
        saving: savingSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        borrowerErrorSet: (uuid: string, field: keyof IApplicationBorrowerErrors, value: string) => dispatch(borrowerErrorSetAction(uuid, field, value)),
        borrowersAdd: () => dispatch(borrowersAddAction()),
        dealGet: () => dispatch(dealGetAction()),
        valueSet: (uuid: string, field: keyof IApplicationBorrower, value: any) => dispatch(borrowerValueSetAction(uuid, field, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Borrowers);
