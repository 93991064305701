import React from 'react';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IBankAccount from '~Api/Investor/IBankAccount';
import IInvestor from '~Api/Investor/IInvestor';
import { authRegisterErrorSetAction } from '~Auth/actions';
import { authRegisterErrorsSelector } from '~Auth/selectors';
import history from '~history';
import {
    publicCurrentInvestorBankAccountValueSetAction,
    publicCurrentInvestorGetAction,
} from '~Public/actions';
import { publicCurrentInvestorSelector } from '~Public/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import Steps from './Steps';

interface IPropsSelector {
    currentInvestor: IInvestor;
    registerErrors: any;
}

interface IPropsDispatch {
    currentInvestorBankAccountValueSet: (key: keyof IBankAccount, value: any) => void;
    currentInvestorGet: () => void;
    setError: (key: string, value: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class BankAccount extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.getBankAccount = this.getBankAccount.bind(this);

        this.onClickContinue = this.onClickContinue.bind(this);

        this.onChangeAccountName = this.onChangeAccountName.bind(this);
        this.onChangeAccountNumber = this.onChangeAccountNumber.bind(this);
        this.onChangeBankName = this.onChangeBankName.bind(this);
        this.onChangeBsb = this.onChangeBsb.bind(this);

        this.validateAccountName = this.validateAccountName.bind(this);
        this.validateAccountNumber = this.validateAccountNumber.bind(this);
        this.validateBankName = this.validateBankName.bind(this);
        this.validateBsb = this.validateBsb.bind(this);
    }

    public componentDidMount() {
        const { currentInvestor } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }
    }

    public render(): JSX.Element {
        const { currentInvestor, registerErrors } = this.props;

        if (!currentInvestor) {
            return (
                <Layout>
                    <Steps currentStep={3} />
                    <Container className='register-bank-account'>
                        <Spinner animation='border' />
                    </Container>
                </Layout>
            );
        }

        const bankAccount: IBankAccount = this.getBankAccount();

        return (
            <Layout>
                <Steps currentStep={3} />

                <Container className='register-bank-account'>
                    <Link to='/invest/income-trust/distribution-preference'><Button className='skip'>Skip</Button></Link>
                    <h3>Bank Account</h3>
                    <p className='intro'>To receive your interest payments.</p>
                    <Form>
                        <Form.Group className='bank-name'>
                            <Form.Label>Bank Name</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.bankName} onBlur={this.validateBankName} onChange={this.onChangeBankName} type='text' value={bankAccount.bankName || ''} />
                            {registerErrors.bankName && <Form.Control.Feedback type='invalid'>{registerErrors.bankName}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='account-name'>
                            <Form.Label>Account Name</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.bankAccountName} onBlur={this.validateAccountName} onChange={this.onChangeAccountName} type='text' value={bankAccount.accountName || ''} />
                            {registerErrors.bankAccountName && <Form.Control.Feedback type='invalid'>{registerErrors.bankAccountName}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='bsb'>
                            <Form.Label>BSB</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.bankBsb} onBlur={this.validateBsb} onChange={this.onChangeBsb} type='text' value={bankAccount.bsb || ''} maxLength={6} />
                            {registerErrors.bankBsb && <Form.Control.Feedback type='invalid'>{registerErrors.bankBsb}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='account-number'>
                            <Form.Label>Account Number</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.bankAccountNumber} onBlur={this.validateAccountNumber} onChange={this.onChangeAccountNumber} type='text' value={bankAccount.accountNumber || ''} maxLength={9} />
                            {registerErrors.bankAccountNumber && <Form.Control.Feedback type='invalid'>{registerErrors.bankAccountNumber}</Form.Control.Feedback>}
                        </Form.Group>
                        <Link className='previous' to='/invest/income-trust/details'>
                            <Button variant='primary'>
                                Previous
                            </Button>
                        </Link>
                        <Button className='continue' onClick={this.onClickContinue} variant='primary'>
                            Continue
                        </Button>
                    </Form>
                </Container>
            </Layout>
        );
    }

    private getBankAccount(): IBankAccount {
        const { currentInvestor } = this.props;

        return currentInvestor.bankAccount || {
            accountName: null,
            accountNumber: null,
            bankName: null,
            bsb: null,
        };
    }

    private onClickContinue() {
        let valid = true;
        valid = this.validateAccountName() && valid;
        valid = this.validateAccountNumber() && valid;
        valid = this.validateBankName() && valid;
        valid = this.validateBsb() && valid;

        if (!valid) {
            return;
        }

        history.push('/invest/income-trust/distribution-preference');
    }

    private onChangeAccountName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorBankAccountValueSet('accountName', event.target.value);
    }

    private onChangeAccountNumber(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.props.currentInvestorBankAccountValueSet('accountNumber', event.target.value);
    }

    private onChangeBankName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorBankAccountValueSet('bankName', event.target.value);
    }

    private onChangeBsb(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.props.currentInvestorBankAccountValueSet('bsb', event.target.value);
    }

    private validateAccountName() {
        let error: string;

        const bankAccount: IBankAccount = this.getBankAccount();

        if (!bankAccount.accountName || bankAccount.accountName.length === 0) {
            error = 'Please enter the account name';
        }

        this.props.setError('bankAccountName', error);

        return !error;
    }

    private validateAccountNumber() {
        let error: string;

        const bankAccount: IBankAccount = this.getBankAccount();

        if (!bankAccount.accountNumber || bankAccount.accountNumber.length === 0) {
            error = 'Please enter the account number';
        } else if (bankAccount.accountNumber.length > 9) {
            error = 'Account number must not be more than 9 digits';
        }

        this.props.setError('bankAccountNumber', error);

        return !error;
    }

    private validateBankName() {
        let error: string;

        const bankAccount: IBankAccount = this.getBankAccount();

        if (!bankAccount.bankName || bankAccount.bankName.length === 0) {
            error = 'Please enter the bank name';
        }

        this.props.setError('bankName', error);

        return !error;
    }

    private validateBsb() {
        let error: string;

        const bankAccount: IBankAccount = this.getBankAccount();

        if (!bankAccount.bsb || bankAccount.bsb.length === 0) {
            error = 'Please enter the BSB';
        } else if (bankAccount.bsb.length !== 6) {
            error = 'BSB must be exactly 6 figures';
        }

        this.props.setError('bankBsb', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: publicCurrentInvestorSelector(state),
        registerErrors: authRegisterErrorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorBankAccountValueSet: (key: keyof IBankAccount, value: any) => dispatch(publicCurrentInvestorBankAccountValueSetAction(key, value)),
        currentInvestorGet: () => dispatch(publicCurrentInvestorGetAction()),
        setError: (key: string, value: string) => dispatch(authRegisterErrorSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BankAccount);
