import React from 'react';

class Statements extends React.Component {
    public render(): JSX.Element {
        return (
            <svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'>
                <circle cx='50' cy='51.835' r='45' fill='#E9F6F6' />
                <path d='M16 66.835L84 66.835' stroke='#16254C' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round' />
                <path d='M88 66.835H90.75' stroke='#16254C' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round' />
                <path d='M77.7983 76.6604C77.7983 77.7227 63.8462 78.6788 46.5076 78.6788C29.1689 78.6788 15.2295 77.7758 15.2295 76.6604C15.2295 75.545 29.1816 74.6421 46.5076 74.6421C63.8335 74.6421 77.7983 75.5982 77.7983 76.6604Z' fill='#91D1D2' />
                <path d='M75.2047 74.2517C75.2061 74.4347 75.1713 74.6161 75.1023 74.7855C75.0333 74.9549 74.9315 75.109 74.8026 75.2389C74.6738 75.3688 74.5205 75.4719 74.3516 75.5422C74.1827 75.6125 74.0016 75.6487 73.8186 75.6487H38.4536C38.0831 75.6487 37.7278 75.5016 37.4658 75.2396C37.2038 74.9776 37.0566 74.6222 37.0566 74.2517V25.5547C37.0566 25.1842 37.2038 24.8289 37.4658 24.5669C37.7278 24.3049 38.0831 24.1577 38.4536 24.1577H67.6696C67.8534 24.1566 68.0356 24.1919 68.2055 24.2618C68.3755 24.3317 68.5299 24.4347 68.6597 24.5647L74.7976 30.7027C74.9277 30.8325 75.0307 30.9869 75.1006 31.1569C75.1704 31.3268 75.2058 31.509 75.2047 31.6927V74.2517Z' fill='white' />
                <path d='M75.2047 73.2517C75.2061 73.4347 75.1713 73.6161 75.1023 73.7855C75.0333 73.9549 74.9315 74.109 74.8026 74.2389C74.6738 74.3688 74.5205 74.4719 74.3516 74.5422C74.1827 74.6125 74.0016 74.6487 73.8186 74.6487H38.4536C38.0831 74.6487 37.7278 74.5016 37.4658 74.2396C37.2038 73.9776 37.0566 73.6222 37.0566 73.2517V24.5547C37.0566 24.1842 37.2038 23.8289 37.4658 23.5669C37.7278 23.3049 38.0831 23.1577 38.4536 23.1577H67.6696C67.8534 23.1566 68.0356 23.1919 68.2055 23.2618C68.3755 23.3317 68.5299 23.4347 68.6597 23.5647L74.7976 29.7027C74.9277 29.8325 75.0307 29.9869 75.1006 30.1569C75.1704 30.3268 75.2058 30.509 75.2047 30.6927V73.2517Z' stroke='#16254C' stroke-width='3' stroke-linejoin='round' />
                <path d='M63.5685 69.403C67.414 69.403 70.5315 66.2856 70.5315 62.44C70.5315 58.5945 67.414 55.4771 63.5685 55.4771C59.7229 55.4771 56.6055 58.5945 56.6055 62.44C56.6055 66.2856 59.7229 69.403 63.5685 69.403Z' fill='#D2EDED' />
                <path d='M46.1113 40.856H67.6713' stroke='#59C5C7' stroke-width='2.4403' stroke-linecap='round' stroke-linejoin='round' />
                <path d='M48.0684 36.6868H57.3194' stroke='#59C5C7' stroke-width='2.4403' stroke-linecap='round' stroke-linejoin='round' />
                <path d='M46.1113 45.0359H67.6713' stroke='#59C5C7' stroke-width='2.4403' stroke-linecap='round' stroke-linejoin='round' />
                <path d='M46.1113 49.2048H67.6713' stroke='#59C5C7' stroke-width='2.4403' stroke-linecap='round' stroke-linejoin='round' />
                <path d='M68.2539 28.8558C68.2539 29.0219 68.2866 29.1864 68.3502 29.3399C68.4138 29.4934 68.507 29.6328 68.6244 29.7503C68.7419 29.8678 68.8813 29.9609 69.0348 30.0245C69.1883 30.0881 69.3528 30.1208 69.5189 30.1208H75.0189C74.955 29.9651 74.8616 29.8232 74.7439 29.7028L68.6609 23.5648C68.5396 23.4535 68.4025 23.3608 68.2539 23.2898V28.8558Z' fill='#D6DAFF' stroke='#16254C' stroke-width='2.4403' stroke-linejoin='round' />
                <path d='M18.8328 61.5137H46.9473C47.903 61.5137 48.8195 61.9942 49.4952 62.8496C50.171 63.705 50.5506 64.8652 50.5506 66.0749V70.4651C50.5506 70.7675 50.4557 71.0576 50.2868 71.2714C50.1178 71.4853 49.8887 71.6054 49.6498 71.6054H16.1303C15.8914 71.6054 15.6623 71.4853 15.4933 71.2714C15.3244 71.0576 15.2295 70.7675 15.2295 70.4651V66.0749C15.2295 64.8652 15.6091 63.705 16.2849 62.8496C16.9606 61.9942 17.8771 61.5137 18.8328 61.5137Z' fill='white' />
                <path d='M42.6965 43.6389H29.6615C29.3574 43.6341 29.0562 43.5784 28.7705 43.4739C29.4525 44.8929 31.3115 49.8429 31.3115 61.7339H41.0465C41.0465 49.8429 42.9055 44.8929 43.5875 43.4739C43.3018 43.5784 43.0007 43.6341 42.6965 43.6389Z' fill='white' />
                <path d='M46.66 74.8007C46.5965 75.0461 46.4719 75.2598 46.3044 75.4111C46.137 75.5624 45.9352 75.6433 45.7283 75.6421H19.0345C18.8276 75.6433 18.6258 75.5624 18.4583 75.4111C18.2908 75.2598 18.1663 75.0461 18.1028 74.8007L17.248 71.6055H47.5233L46.66 74.8007Z' fill='#D2EDED' />
                <path d='M18.7298 61.5137H46.0411C46.9694 61.5137 47.8598 61.9942 48.5162 62.8496C49.1726 63.705 49.5414 64.8652 49.5414 66.0749V70.4651C49.5414 70.7675 49.4492 71.0576 49.2851 71.2714C49.121 71.4853 48.8984 71.6054 48.6663 71.6054H16.1046C15.8725 71.6054 15.6499 71.4853 15.4858 71.2714C15.3217 71.0576 15.2295 70.7675 15.2295 70.4651V66.0749C15.2295 64.8652 15.5983 63.705 16.2547 62.8496C16.9112 61.9942 17.8015 61.5137 18.7298 61.5137Z' stroke='#16254C' stroke-width='2.4403' stroke-linejoin='round' />
                <path d='M37.7119 43.5128H27.0583C26.8097 43.5081 26.5636 43.4526 26.3301 43.3486C26.8875 44.7603 28.4069 49.6845 28.4069 61.5138H36.3634C36.3634 49.6845 37.8828 44.7603 38.4402 43.3486C38.2067 43.4526 37.9605 43.5081 37.7119 43.5128Z' stroke='#16254C' stroke-width='2.4403' stroke-linejoin='round' />
                <path d='M46.66 74.8007C46.5965 75.0461 46.4719 75.2598 46.3044 75.4111C46.137 75.5624 45.9352 75.6433 45.7283 75.6421H19.0345C18.8276 75.6433 18.6258 75.5624 18.4583 75.4111C18.2908 75.2598 18.1663 75.0461 18.1028 74.8007L17.248 71.6055H47.5233L46.66 74.8007Z' stroke='#16254C' stroke-width='2.4403' stroke-linejoin='round' />
                <path d='M28.3486 57.4771H36.422' stroke='#16254C' stroke-width='2.4403' stroke-linejoin='round' />
                <path d='M37.732 32.2478H27.039C26.0903 32.2478 25.3213 33.23 25.3213 34.4417V41.1549C25.3213 42.3665 26.0903 43.3487 27.039 43.3487H37.732C38.6807 43.3487 39.4497 42.3665 39.4497 41.1549V34.4417C39.4497 33.23 38.6807 32.2478 37.732 32.2478Z' fill='white' />
                <path d='M37.732 32.2478H27.039C26.0903 32.2478 25.3213 33.23 25.3213 34.4417V41.1549C25.3213 42.3665 26.0903 43.3487 27.039 43.3487H37.732C38.6807 43.3487 39.4497 42.3665 39.4497 41.1549V34.4417C39.4497 33.23 38.6807 32.2478 37.732 32.2478Z' stroke='#16254C' stroke-width='3' stroke-linejoin='round' />
            </svg>
        );
    }
}

export default Statements;
