import { Action } from 'redux';
import { IApplication } from '~Api/Application';
import IApplicationBorrower from '~Api/Application/IApplicationBorrower';
import IApplicationBorrowerErrors from '~Api/Application/IApplicationBorrowerErrors';
import IApplicationErrors from '~Api/Application/IApplicationErrors';
import { IDeal, IDealProperty } from '~Api/Deal';
import { IDealPropertyErrors } from '~Api/Deal/IDealProperty';
import ApplicationActionsEnum from './ActionsEnum';

// tslint:disable-next-line: no-empty-interface
export interface IApplicationBorrowerTypeAction extends Action {}

export function applicationBorrowerTypeAction(): IApplicationBorrowerTypeAction {
    return {
        type: ApplicationActionsEnum.ApplicationBorrowerType,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IApplicationCompleteAction extends Action {}

export function applicationCompleteAction(): IApplicationCompleteAction {
    return {
        type: ApplicationActionsEnum.ApplicationComplete,
    };
}

export interface IApplicationErrorSetAction extends Action {
    field: keyof IApplicationErrors;
    value: any;
}

export function applicationErrorSetAction(field: keyof IApplicationErrors, value: any): IApplicationErrorSetAction {
    return {
        field,
        type: ApplicationActionsEnum.ApplicationErrorSet,
        value,
    };
}

export interface IApplicationSavingSetAction extends Action {
    saving: boolean;
}

export function applicationSavingSetAction(saving: boolean): IApplicationSavingSetAction {
    return {
        saving,
        type: ApplicationActionsEnum.ApplicationSavingSet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IApplicationSendAction extends Action {}

export function applicationSendAction(): IApplicationSendAction {
    return {
        type: ApplicationActionsEnum.ApplicationSend,
    };
}

export interface IApplicationSetAction extends Action {
    application: IApplication;
}

export function applicationSetAction(application: IApplication): IApplicationSetAction {
    return {
        application,
        type: ApplicationActionsEnum.ApplicationSet,
    };
}

export interface IApplicationValueSetAction extends Action {
    field: keyof IApplication;
    value: any;
}

export function applicationValueSetAction(field: keyof IApplication, value: any): IApplicationValueSetAction {
    return {
        field,
        type: ApplicationActionsEnum.ApplicationValueSet,
        value,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IApplicationsListAction extends Action {}

export function applicationsListAction(): IApplicationsListAction {
    return {
        type: ApplicationActionsEnum.ApplicationsList,
    };
}

export interface IApplicationsSetAction extends Action {
    applications: IApplication[];
}

export function applicationsSetAction(applications: IApplication[]) {
    return {
        applications,
        type: ApplicationActionsEnum.ApplicationsSet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IBorrowersAddAction extends Action {}

export function borrowersAddAction(): IBorrowersAddAction {
    return {
        type: ApplicationActionsEnum.BorrowersAdd,
    };
}

export interface IBorrowerDeleteAction extends Action {
    uuid: string;
}

export function borrowerDeleteAction(uuid: string): IBorrowerDeleteAction {
    return {
        type: ApplicationActionsEnum.BorrowerDelete,
        uuid,
    };
}

export interface IBorrowerErrorSetAction extends Action {
    field: keyof IApplicationBorrowerErrors;
    uuid: string;
    value: string;
}

export function borrowerErrorSetAction(uuid: string, field: keyof IApplicationBorrowerErrors, value: string): IBorrowerErrorSetAction {
    return {
        field,
        type: ApplicationActionsEnum.BorrowerErrorSet,
        uuid,
        value,
    };
}

export interface IBorrowerSendAction extends Action {
    uuid: string;
}

export function borrowerSendAction(uuid: string): IBorrowerSendAction {
    return {
        type: ApplicationActionsEnum.BorrowerSend,
        uuid,
    };
}

export interface IBorrowerSetAction extends Action {
    borrower: IApplicationBorrower;
}

export function borrowerSetAction(borrower: IApplicationBorrower): IBorrowerSetAction {
    return {
        borrower,
        type: ApplicationActionsEnum.BorrowerSet,
    };
}

export interface IBorrowerValueSetAction extends Action {
    field: keyof IApplicationBorrower;
    uuid: string;
    value: any;
}

export function borrowerValueSetAction(uuid: string, field: keyof IApplicationBorrower, value: any): IBorrowerValueSetAction {
    return {
        field,
        type: ApplicationActionsEnum.BorrowerValueSet,
        uuid,
        value,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IDealGetAction extends Action {}

export function dealGetAction(): IDealGetAction {
    return {
        type: ApplicationActionsEnum.DealGet,
    };
}

export interface IDealQuoteConvertAction extends Action {
    code: string;
}

export function dealQuoteConvertAction(code: string): IDealQuoteConvertAction {
    return {
        code,
        type: ApplicationActionsEnum.DealQuoteConvert,
    };
}

export interface IDealSetAction extends Action {
    deal: IDeal;
}

export function dealSetAction(deal: IDeal): IDealSetAction {
    return {
        deal,
        type: ApplicationActionsEnum.DealSet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IPropertiesAddAction extends Action {}

export function propertiesAddAction(): IPropertiesAddAction {
    return {
        type: ApplicationActionsEnum.PropertiesAdd,
    };
}

export interface IPropertyDeleteAction extends Action {
    uuid: string;
}

export function propertyDeleteAction(uuid: string): IPropertyDeleteAction {
    return {
        type: ApplicationActionsEnum.PropertyDelete,
        uuid,
    };
}

export interface IPropertyErrorSetAction extends Action {
    field: keyof IDealPropertyErrors;
    uuid: string;
    value: string;
}

export function propertyErrorSetAction(uuid: string, field: keyof IDealPropertyErrors, value: string): IPropertyErrorSetAction {
    return {
        field,
        type: ApplicationActionsEnum.PropertyErrorSet,
        uuid,
        value,
    };
}

export interface IPropertySendAction extends Action {
    uuid: string;
}

export function propertySendAction(uuid: string): IPropertySendAction {
    return {
        type: ApplicationActionsEnum.PropertySend,
        uuid,
    };
}

export interface IPropertySetAction extends Action {
    property: IDealProperty;
}

export function propertySetAction(property: IDealProperty): IPropertySetAction {
    return {
        property,
        type: ApplicationActionsEnum.PropertySet,
    };
}

export interface IPropertyValueSetAction extends Action {
    field: keyof IDealProperty;
    uuid: string;
    value: any;
}

export function propertyValueSetAction(uuid: string, field: keyof IDealProperty, value: any): IPropertyValueSetAction {
    return {
        field,
        type: ApplicationActionsEnum.PropertyValueSet,
        uuid,
        value,
    };
}

export interface ITokenSetAction extends Action {
    token: any;
}

export function tokenSetAction(token: any): ITokenSetAction {
    return {
        token,
        type: ApplicationActionsEnum.TokenSet,
    };
}
