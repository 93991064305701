import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Dispatch } from 'redux';
import IDeal, { IDealErrors } from '~Api/Deal/IDeal';
import history from '~history';
import {
    dealErrorSetAction,
    dealGetAction,
    dealValueSetAction,
} from '~Lead/actions';
import {
    dealSelector,
    errorsSelector,
    tokenSelector,
} from '~Lead/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import IToken from '~Auth/IToken';

interface IPropsSelector {
    deal: IDeal;
    errors: IDealErrors;
    borrowToken: IToken;
}

interface IPropsDispatch {
    dealGet: () => void;
    setError: (key: keyof IDealErrors, value: string) => void;
    setValue: (key: keyof IDeal, value: boolean|number|string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class LoanTerm extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickContinue = this.onClickContinue.bind(this);
        this.onChangeTermMonths = this.onChangeTermMonths.bind(this);
        this.validateTermMonths = this.validateTermMonths.bind(this);
    }

    public componentDidMount(): void {
        const { borrowToken, deal } = this.props;

        if (borrowToken && borrowToken.dealUuid && !deal.uuid) {
            this.props.dealGet();
        }
    }

    public render(): JSX.Element {
        const { borrowToken, deal, errors } = this.props;

        if (borrowToken && borrowToken.dealUuid && !deal.uuid) {
            return (
                <Layout currentStep={3}>
                    <h3>That&apos;s the &apos;how much&apos;. Now for the &apos;how long&apos;.</h3>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        return (
            <Layout currentStep={3}>
                <Helmet>
                    <title>Funding.com.au</title>
                    <meta content={'Enter in your term amount before proceeding to the next step. You\'re almost there!'} name='description' />
                </Helmet>
                <h3>That&apos;s the &apos;how much&apos;. Now for the &apos;how long&apos;.</h3>
                <p>What kind of loan period are you thinking?</p>
                <Form>
                    <Form.Group className='term-months'>
                        <Form.Label>
                            <Form.Control isInvalid={!!errors.termMonths} min={0} onBlur={this.validateTermMonths} onChange={this.onChangeTermMonths} type='number' value={deal.termMonths || ''} />
                            <div className='months'>{deal.termMonths !== 1 ? ' months' : ' month'}</div>
                        </Form.Label>
                        <Form.Control type='range' isInvalid={!!errors.termMonths} onBlur={this.validateTermMonths} onChange={this.onChangeTermMonths} value={deal.termMonths || 0} min={0} max={24} custom={true} />
                        {errors.termMonths && <Form.Control.Feedback type='invalid'>{errors.termMonths}</Form.Control.Feedback>}
                    </Form.Group>
                </Form>
                <div className='actions'>
                    <Link className='previous' to='/get-your-quote/loan-amount'><Button variant='primary'>Previous</Button></Link>
                    <Button className='continue' onClick={this.onClickContinue} variant='primary'>Continue</Button>
                </div>
            </Layout>
        );
    }

    private onClickContinue(): void {
        if (!this.validateTermMonths()) {
            return;
        }

        history.push('/get-your-quote/loan-purpose');
    }

    private onChangeTermMonths(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue('termMonths', event.target.value ? event.target.valueAsNumber : null);
    }

    private validateTermMonths(): boolean {
        const { deal } = this.props;

        let error: string;

        if (!deal.termMonths) {
            error = 'Please select a loan term of at least 1 month';
        } else if (deal.termMonths > 24) {
            error = 'Please select a loan term of at most 24 months';
        }

        this.props.setError('termMonths', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        borrowToken: tokenSelector(state),
        deal: dealSelector(state),
        errors: errorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        dealGet: () => dispatch(dealGetAction()),
        setError: (key: keyof IDealErrors, value: string) => dispatch(dealErrorSetAction(key, value)),
        setValue: (key: keyof IDeal, value: boolean|number|string) => dispatch(dealValueSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoanTerm);
