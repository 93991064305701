import _ from 'lodash';
import { AnyAction } from 'redux';
import IAggregator from '~Api/Broker/IAggregator';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IBankAccount from '~Api/Investor/IBankAccount';
import ICompany from '~Api/Investor/ICompany';
import IIndividual from '~Api/Investor/IIndividual';
import IInvestor from '~Api/Investor/IInvestor';
import ITrust from '~Api/Investor/ITrust';
import ICountry from '~Public/ICountry';
import {
    IPublicAdviserRegisterErrorSetAction,
    IPublicAdviserRegisterErrorsSetAction,
    IPublicAdviserRegisterInProgressSetAction,
    IPublicAggregatorsSetAction,
    IPublicCountriesSetAction,
    IPublicCurrentInvestorAccountIncomeTrustInterestPaymentStrategySetAction,
    IPublicCurrentInvestorBankAccountValueSetAction,
    IPublicCurrentInvestorCompanyValueSetAction,
    IPublicCurrentInvestorIndividualValueSetAction,
    IPublicCurrentInvestorSetAction,
    IPublicCurrentInvestorTrustValueSetAction,
    IPublicCurrentInvestorValueSetAction,
} from './actions';
import ActionsEnum from './ActionsEnum';
import { IDictionary } from '~utilities/IDictionary';

export interface IPublicState {
    adviserRegisterErrors: IDictionary<string>,
    adviserRegisterInProgress: boolean,
    aggregators: IAggregator[];
    countries: ICountry[];
    currentInvestor: IInvestor;
}

const initialData: IPublicState = {
    adviserRegisterErrors: {},
    adviserRegisterInProgress: false,
    aggregators: null,
    countries: null,
    currentInvestor: null,
};

export function publicReducer(state: IPublicState = initialData, action: AnyAction): IPublicState {
    switch (action.type) {
        case ActionsEnum.AdviserRegisterErrorsSet: {
            const typedAction: IPublicAdviserRegisterErrorsSetAction = action as IPublicAdviserRegisterErrorsSetAction;

            return {
                ...state,
                adviserRegisterErrors: typedAction.errors,
            };
        }

        case ActionsEnum.AdviserRegisterErrorSet: {
            const typedAction: IPublicAdviserRegisterErrorSetAction = action as IPublicAdviserRegisterErrorSetAction;

            return {
                ...state,
                adviserRegisterErrors: {
                    ...state.adviserRegisterErrors,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        case ActionsEnum.AdviserRegisterInProgressSet: {
            const typedAction: IPublicAdviserRegisterInProgressSetAction = action as IPublicAdviserRegisterInProgressSetAction;

            return {
                ...state,
                adviserRegisterInProgress: typedAction.inProgress,
            };
        }

        case ActionsEnum.AggregatorsSet: {
            const typedAction: IPublicAggregatorsSetAction = action as IPublicAggregatorsSetAction;

            return {
                ...state,
                aggregators: typedAction.aggregators,
            };
        }

        case ActionsEnum.CountriesSet: {
            const typedAction: IPublicCountriesSetAction = action as IPublicCountriesSetAction;

            return {
                ...state,
                countries: typedAction.countries,
            };
        }

        case ActionsEnum.CurrentInvestorSet: {
            const typedAction: IPublicCurrentInvestorSetAction = action as IPublicCurrentInvestorSetAction;

            return {
                ...state,
                currentInvestor: typedAction.investor,
            };
        }

        case ActionsEnum.CurrentInvestorAccountIncomeTrustInterestPaymentStrategySet: {
            const typedAction: IPublicCurrentInvestorAccountIncomeTrustInterestPaymentStrategySetAction = action as IPublicCurrentInvestorAccountIncomeTrustInterestPaymentStrategySetAction;

            return {
                ...state,
                currentInvestor: {
                    ...state.currentInvestor,
                    accounts: {
                        ...state.currentInvestor.accounts,
                        [AccountTypeEnum.IncomeTrust]: {
                            ...state.currentInvestor.accounts[AccountTypeEnum.IncomeTrust],
                            incomeTrust: {
                                ...state.currentInvestor.accounts[AccountTypeEnum.IncomeTrust].incomeTrust,
                                interestPaymentStrategy: typedAction.interestPaymentStrategy,
                            },
                        },
                    },
                },
            };
        }

        case ActionsEnum.CurrentInvestorBankAccountValueSet: {
            const typedAction: IPublicCurrentInvestorBankAccountValueSetAction = action as IPublicCurrentInvestorBankAccountValueSetAction;

            const bankAccount: IBankAccount = state.currentInvestor.bankAccount ? { ...state.currentInvestor.bankAccount } : {
                accountName: null,
                accountNumber: null,
                bankName: null,
                bsb: null,
            };

            bankAccount[typedAction.key] = typedAction.value;

            return {
                ...state,
                currentInvestor: {
                    ...state.currentInvestor,
                    bankAccount,
                },
            };
        }

        case ActionsEnum.CurrentInvestorCompanyValueSet: {
            const typedAction: IPublicCurrentInvestorCompanyValueSetAction = action as IPublicCurrentInvestorCompanyValueSetAction;

            const company: ICompany = state.currentInvestor.company ? { ...state.currentInvestor.company } : {
                australianCompanyNumber: null,
                name: null,
                postcode: null,
                state: null,
                streetAddress: null,
                suburb: null,
                taxFileNumber: null,
            };

            company[typedAction.key] = typedAction.value;

            return {
                ...state,
                currentInvestor: {
                    ...state.currentInvestor,
                    company,
                },
            };
        }

        case ActionsEnum.CurrentInvestorIndividualValueSet: {
            const typedAction: IPublicCurrentInvestorIndividualValueSetAction = action as IPublicCurrentInvestorIndividualValueSetAction;

            const individuals: IIndividual[] = [ ...state.currentInvestor.individuals ];

            if (!individuals[typedAction.index]) {
                individuals[typedAction.index] = {
                    country: null,
                    dateOfBirth: null,
                    driverLicenceCardNumber: null,
                    driverLicenceState: null,
                    email: null,
                    firstName: null,
                    idProofExpiryDate: null,
                    idProofNumber: null,
                    idProofType: null,
                    lastName: null,
                    postcode: null,
                    residentType: null,
                    state: null,
                    streetAddress: null,
                    suburb: null,
                    taxFileNumber: null,
                };
            }

            (individuals[typedAction.index][typedAction.key] as any) = typedAction.value;

            return {
                ...state,
                currentInvestor: {
                    ...state.currentInvestor,
                    individuals,
                },
            };
        }

        case ActionsEnum.CurrentInvestorTrustValueSet: {
            const typedAction: IPublicCurrentInvestorTrustValueSetAction = action as IPublicCurrentInvestorTrustValueSetAction;

            const trust: ITrust = state.currentInvestor.trust ? { ...state.currentInvestor.trust } : {
                australianBusinessNumber: null,
                name: null,
                taxFileNumber: null,
            };

            trust[typedAction.key] = typedAction.value;

            return {
                ...state,
                currentInvestor: {
                    ...state.currentInvestor,
                    trust,
                },
            };
        }

        case ActionsEnum.CurrentInvestorValueSet: {
            const typedAction: IPublicCurrentInvestorValueSetAction = action as IPublicCurrentInvestorValueSetAction;

            return {
                ...state,
                currentInvestor: {
                    ...state.currentInvestor,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        default:
            return state;
    }
}
