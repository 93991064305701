import _ from 'lodash';
import { AnyAction } from 'redux';
import IInvestment from '~Api/Investment/IInvestment';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IAnnualStatement from '~Api/Investor/IAnnualStatement';
import IBankAccount from '~Api/Investor/IBankAccount';
import ICompany from '~Api/Investor/ICompany';
import IIncomeTrust from '~Api/Investor/IIncomeTrust';
import IIndividual from '~Api/Investor/IIndividual';
import IInvestor from '~Api/Investor/IInvestor';
import IMonthlyStatement from '~Api/Investor/IMonthlyStatement';
import IReferral from '~Api/Investor/IReferral';
import IShare from '~Api/Investor/IShare';
import ITransaction from '~Api/Investor/ITransaction';
import ITrust from '~Api/Investor/ITrust';
import AuthActionsEnum from '~Auth/ActionsEnum';
import { IDictionary } from '~utilities/IDictionary';
import {
    IAnnualStatementsSetAction,
    ICompleteSignupModalShowSetAction,
    ICurrentInvestorAccountIncomeTrustInterestPaymentStrategySetAction,
    ICurrentInvestorAccountIncomeTrustSetAction,
    ICurrentInvestorAccountMarketplaceSetAction,
    ICurrentInvestorBankAccountValueSetAction,
    ICurrentInvestorCompanyValueSetAction,
    ICurrentInvestorIndividualValueSetAction,
    ICurrentInvestorSetAction,
    ICurrentInvestorTrustValueSetAction,
    ICurrentInvestorValueSetAction,
    IIncomeTrustInvestCompleteModalShowSetAction,
    IIncomeTrustInvestConfirmModalShowSetAction,
    IIncomeTrustInvestErrorSetAction,
    IIncomeTrustInvestErrorsSetAction,
    IIncomeTrustInvestInProgressSetAction,
    IIncomeTrustsSetAction,
    IInvestmentInvestAmountSetAction,
    IInvestmentInvestCompleteModalShowSetAction,
    IInvestmentInvestErrorSetAction,
    IInvestmentInvestErrorsSetAction,
    IInvestmentInvestInProgressSetAction,
    IInvestmentInvestModalShowSetAction,
    IInvestmentSetAction,
    IInvestmentsAvailableSetAction,
    IInvestmentsRecentlyFundedSetAction,
    IMonthlyStatementsSetAction,
    IReferralAddErrorSetAction,
    IReferralAddErrorsSetAction,
    IReferralAddInProgressSetAction,
    IReferralEmailSetAction,
    IReferralSetAction,
    IReferralsSetAction,
    IRegisterErrorSetAction,
    IRegisterErrorsSetAction,
    IRegisterInProgressSetAction,
    ISharesSetAction,
    ITopUpCompleteModalHideAction,
    ITopUpCompleteModalShowAction,
    ITopUpConfirmModalHideAction,
    ITopUpConfirmModalShowAction,
    ITopUpErrorSetAction,
    ITopUpErrorsSetAction,
    ITopUpInProgressSetAction,
    ITopUpModalHideAction,
    ITopUpModalShowAction,
    ITransactionsSetAction,
    IWithdrawCompleteModalHideAction,
    IWithdrawCompleteModalShowAction,
    IWithdrawConfirmModalHideAction,
    IWithdrawConfirmModalShowAction,
    IWithdrawErrorSetAction,
    IWithdrawErrorsSetAction,
    IWithdrawInProgressSetAction,
    IWithdrawModalHideAction,
    IWithdrawModalShowAction,
} from './actions';
import InvestorActionsEnum from './ActionsEnum';

export interface IIncomeTrustInvestErrors {
    investorAccountUuid?: string;
    amount?: string;
}

export interface IInvestErrors {
    id?: string;
    amount?: string;
}

export interface IReferralAddErrors {
    email?: string;
}

export interface IInvestorState {
    accountAnnualStatementUuids: IDictionary<string[]>;
    accountMonthlyStatementUuids: IDictionary<string[]>;
    annualStatements: IDictionary<IAnnualStatement>;
    availableInvestments: string[];
    completeSignupModalShow: boolean;
    currentInvestor: IInvestor;
    currentInvestorAccount: IAccount;
    incomeTrusts: IDictionary<IIncomeTrust>;
    incomeTrustClassTypeUuids: IDictionary<string>;
    incomeTrustInvestCompleteModalShow: boolean;
    incomeTrustInvestConfirmModalShow: boolean;
    incomeTrustInvestErrors: IInvestErrors;
    incomeTrustInvestInProgress: boolean;
    investAmount: number;
    investCompleteModalShow: boolean;
    investErrors: IInvestErrors;
    investInProgress: boolean;
    investModalShow: boolean;
    investments: IDictionary<IInvestment>;
    monthlyStatements: IDictionary<IMonthlyStatement>;
    recentlyFundedInvestments: string[];
    referralAddErrors: IReferralAddErrors;
    referralAddInProgress: boolean;
    referralEmail: string;
    referrals: IDictionary<IReferral>;
    registerErrors: IDictionary<string>;
    registerInProgress: boolean;
    shares: IDictionary<IShare>;
    transactions: IDictionary<ITransaction>;
    topUpCompleteModalShow: boolean;
    topUpConfirmModalShow: boolean;
    topUpErrors: IDictionary<string>;
    topUpInProgress: boolean;
    topUpModalShow: boolean;
    withdrawCompleteModalShow: boolean;
    withdrawConfirmModalShow: boolean;
    withdrawErrors: IDictionary<string>;
    withdrawInProgress: boolean;
    withdrawModalShow: boolean;
}

const initialData: IInvestorState = {
    accountAnnualStatementUuids: null,
    accountMonthlyStatementUuids: null,
    annualStatements: null,
    availableInvestments: null,
    completeSignupModalShow: false,
    currentInvestor: null,
    currentInvestorAccount: null,
    incomeTrusts: null,
    incomeTrustClassTypeUuids: {},
    incomeTrustInvestCompleteModalShow: false,
    incomeTrustInvestConfirmModalShow: false,
    incomeTrustInvestErrors: {},
    incomeTrustInvestInProgress: false,
    investAmount: null,
    investCompleteModalShow: false,
    investErrors: {},
    investInProgress: false,
    investModalShow: false,
    investments: {},
    monthlyStatements: null,
    recentlyFundedInvestments: null,
    referralAddErrors: {},
    referralAddInProgress: false,
    referralEmail: null,
    referrals: null,
    registerErrors: {},
    registerInProgress: false,
    shares: null,
    topUpCompleteModalShow: false,
    topUpConfirmModalShow: false,
    topUpErrors: {},
    topUpInProgress: false,
    topUpModalShow: false,
    transactions: null,
    withdrawCompleteModalShow: false,
    withdrawConfirmModalShow: false,
    withdrawErrors: {},
    withdrawInProgress: false,
    withdrawModalShow: false,
};

export function investorReducer(state: IInvestorState = initialData, action: AnyAction): IInvestorState {
    switch (action.type) {
        case InvestorActionsEnum.AnnualStatementsSet: {
            const typedAction: IAnnualStatementsSetAction = action as IAnnualStatementsSetAction;

            const annualStatements: IDictionary<IAnnualStatement> = {};
            const accountAnnualStatementUuids: string[] = [];

            typedAction.annualStatements.forEach((statement: IAnnualStatement) => {
                annualStatements[statement.uuid] = statement;
                accountAnnualStatementUuids.push(statement.uuid);
            });

            return {
                ...state,
                accountAnnualStatementUuids: {
                    ...state.accountAnnualStatementUuids,
                    [typedAction.investorAccountUuid]: accountAnnualStatementUuids,
                },
                annualStatements,
            };
        }

        case InvestorActionsEnum.CompleteSignupModalShowSet: {
            const typedAction: ICompleteSignupModalShowSetAction = action as ICompleteSignupModalShowSetAction;

            return {
                ...state,
                completeSignupModalShow: typedAction.show,
            };
        }

        case InvestorActionsEnum.CurrentInvestorSet: {
            const typedAction: ICurrentInvestorSetAction = action as ICurrentInvestorSetAction;

            return {
                ...state,
                currentInvestor: typedAction.investor,
            };
        }

        case InvestorActionsEnum.CurrentInvestorValueSet: {
            const typedAction: ICurrentInvestorValueSetAction = action as ICurrentInvestorValueSetAction;

            return {
                ...state,
                currentInvestor: {
                    ...state.currentInvestor,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        case InvestorActionsEnum.CurrentInvestorBankAccountValueSet: {
            const typedAction: ICurrentInvestorBankAccountValueSetAction = action as ICurrentInvestorBankAccountValueSetAction;

            const bankAccount: IBankAccount = state.currentInvestor.bankAccount ? { ...state.currentInvestor.bankAccount } : {
                accountName: null,
                accountNumber: null,
                bankName: null,
                bsb: null,
            };

            bankAccount[typedAction.key] = typedAction.value;

            return {
                ...state,
                currentInvestor: {
                    ...state.currentInvestor,
                    bankAccount,
                },
            };
        }

        case InvestorActionsEnum.CurrentInvestorCompanyValueSet: {
            const typedAction: ICurrentInvestorCompanyValueSetAction = action as ICurrentInvestorCompanyValueSetAction;

            const company: ICompany = state.currentInvestor.company ? { ...state.currentInvestor.company } : {
                australianCompanyNumber: null,
                name: null,
                postcode: null,
                state: null,
                streetAddress: null,
                suburb: null,
                taxFileNumber: null,
            };

            company[typedAction.key] = typedAction.value;

            return {
                ...state,
                currentInvestor: {
                    ...state.currentInvestor,
                    company,
                },
            };
        }

        case InvestorActionsEnum.CurrentInvestorIndividualValueSet: {
            const typedAction: ICurrentInvestorIndividualValueSetAction = action as ICurrentInvestorIndividualValueSetAction;

            const individuals: IIndividual[] = [ ...state.currentInvestor.individuals ];

            if (!individuals[typedAction.index]) {
                individuals[typedAction.index] = {
                    country: null,
                    dateOfBirth: null,
                    driverLicenceCardNumber: null,
                    driverLicenceState: null,
                    email: null,
                    firstName: null,
                    idProofExpiryDate: null,
                    idProofNumber: null,
                    idProofType: null,
                    lastName: null,
                    postcode: null,
                    residentType: null,
                    state: null,
                    streetAddress: null,
                    suburb: null,
                    taxFileNumber: null,
                };
            }

            (individuals[typedAction.index][typedAction.key] as any) = typedAction.value;

            return {
                ...state,
                currentInvestor: {
                    ...state.currentInvestor,
                    individuals,
                },
            };
        }

        case InvestorActionsEnum.CurrentInvestorTrustValueSet: {
            const typedAction: ICurrentInvestorTrustValueSetAction = action as ICurrentInvestorTrustValueSetAction;

            const trust: ITrust = state.currentInvestor.trust ? { ...state.currentInvestor.trust } : {
                australianBusinessNumber: null,
                name: null,
                taxFileNumber: null,
            };

            trust[typedAction.key] = typedAction.value;

            return {
                ...state,
                currentInvestor: {
                    ...state.currentInvestor,
                    trust,
                },
            };
        }

        case InvestorActionsEnum.CurrentInvestorAccountIncomeTrustInterestPaymentStrategySet: {
            const typedAction: ICurrentInvestorAccountIncomeTrustInterestPaymentStrategySetAction = action as ICurrentInvestorAccountIncomeTrustInterestPaymentStrategySetAction;

            const incomeTrust: IIncomeTrust = { ...state.currentInvestor.accounts[AccountTypeEnum.IncomeTrust]?.incomeTrust };
            incomeTrust.interestPaymentStrategy = typedAction.interestPaymentStrategy;

            return {
                ...state,
                currentInvestor: {
                    ...state.currentInvestor,
                    accounts: {
                        ...state.currentInvestor.accounts,
                        [AccountTypeEnum.IncomeTrust]: {
                            ...state.currentInvestor.accounts[AccountTypeEnum.IncomeTrust],
                            incomeTrust,
                        },
                    },
                },
            };
        }

        case InvestorActionsEnum.CurrentInvestorAccountIncomeTrustSet: {
            const typedAction: ICurrentInvestorAccountIncomeTrustSetAction = action as ICurrentInvestorAccountIncomeTrustSetAction;

            return {
                ...state,
                currentInvestor: {
                    ...state.currentInvestor,
                    accounts: {
                        ...state.currentInvestor.accounts,
                        [AccountTypeEnum.IncomeTrust]: typedAction.investorAccount,
                    },
                },
            };
        }

        case InvestorActionsEnum.CurrentInvestorAccountMarketplaceSet: {
            const typedAction: ICurrentInvestorAccountMarketplaceSetAction = action as ICurrentInvestorAccountMarketplaceSetAction;

            return {
                ...state,
                currentInvestor: {
                    ...state.currentInvestor,
                    accounts: {
                        ...state.currentInvestor.accounts,
                        [AccountTypeEnum.Marketplace]: typedAction.investorAccount,
                    },
                },
            };
        }

        case InvestorActionsEnum.IncomeTrustInvestCompleteModalShowSet: {
            const typedAction: IIncomeTrustInvestCompleteModalShowSetAction = action as IIncomeTrustInvestCompleteModalShowSetAction;

            return {
                ...state,
                incomeTrustInvestCompleteModalShow: typedAction.show,
            };
        }

        case InvestorActionsEnum.IncomeTrustInvestConfirmModalShowSet: {
            const typedAction: IIncomeTrustInvestConfirmModalShowSetAction = action as IIncomeTrustInvestConfirmModalShowSetAction;

            return {
                ...state,
                incomeTrustInvestConfirmModalShow: typedAction.show,
            };
        }

        case InvestorActionsEnum.IncomeTrustInvestErrorSet: {
            const typedAction: IIncomeTrustInvestErrorSetAction = action as IIncomeTrustInvestErrorSetAction;

            return {
                ...state,
                incomeTrustInvestErrors: {
                    ...state.incomeTrustInvestErrors,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        case InvestorActionsEnum.IncomeTrustInvestErrorsSet: {
            const typedAction: IIncomeTrustInvestErrorsSetAction = action as IIncomeTrustInvestErrorsSetAction;

            return {
                ...state,
                incomeTrustInvestErrors: typedAction.errors,
            };
        }

        case InvestorActionsEnum.IncomeTrustInvestInProgressSet: {
            const typedAction: IIncomeTrustInvestInProgressSetAction = action as IIncomeTrustInvestInProgressSetAction;

            return {
                ...state,
                incomeTrustInvestInProgress: typedAction.inProgress,
            };
        }

        case InvestorActionsEnum.IncomeTrustsSet: {
            const typedAction: IIncomeTrustsSetAction = action as IIncomeTrustsSetAction;

            const incomeTrustClassTypeUuids: IDictionary<string> = {};
            typedAction.incomeTrusts.forEach((incomeTrust: IIncomeTrust) => {
                incomeTrustClassTypeUuids[incomeTrust.classType] = incomeTrust.uuid;
            });

            return {
                ...state,
                incomeTrusts: _.keyBy(_.sortBy(typedAction.incomeTrusts, ['classType']), 'uuid'),
                incomeTrustClassTypeUuids,
            };
        }

        case InvestorActionsEnum.InvestmentInvestAmountSet: {
            const typedAction: IInvestmentInvestAmountSetAction = action as IInvestmentInvestAmountSetAction;

            return {
                ...state,
                investAmount: typedAction.amount,
            };
        }

        case InvestorActionsEnum.InvestmentInvestCompleteModalShowSet: {
            const typedAction: IInvestmentInvestCompleteModalShowSetAction = action as IInvestmentInvestCompleteModalShowSetAction;

            return {
                ...state,
                investCompleteModalShow: typedAction.show,
            };
        }

        case InvestorActionsEnum.InvestmentInvestErrorSet: {
            const typedAction: IInvestmentInvestErrorSetAction = action as IInvestmentInvestErrorSetAction;

            return {
                ...state,
                investErrors: {
                    ...state.investErrors,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        case InvestorActionsEnum.InvestmentInvestErrorsSet: {
            const typedAction: IInvestmentInvestErrorsSetAction = action as IInvestmentInvestErrorsSetAction;

            return {
                ...state,
                investErrors: typedAction.errors,
            };
        }

        case InvestorActionsEnum.InvestmentInvestInProgressSet: {
            const typedAction: IInvestmentInvestInProgressSetAction = action as IInvestmentInvestInProgressSetAction;

            return {
                ...state,
                investInProgress: typedAction.inProgress,
            };
        }

        case InvestorActionsEnum.InvestmentInvestModalShowSet: {
            const typedAction: IInvestmentInvestModalShowSetAction = action as IInvestmentInvestModalShowSetAction;

            return {
                ...state,
                investModalShow: typedAction.show,
            };
        }

        case InvestorActionsEnum.InvestmentSet: {
            const typedAction: IInvestmentSetAction = action as IInvestmentSetAction;

            return {
                ...state,
                investments: {
                    ...state.investments,
                    [typedAction.investment.uuid]: typedAction.investment,
                },
            };
        }

        case InvestorActionsEnum.InvestmentsAvailableSet: {
            const typedAction: IInvestmentsAvailableSetAction = action as IInvestmentsAvailableSetAction;

            const investments: { [investmentId: string]: IInvestment } = { ...state.investments };

            typedAction.investments.forEach((investment: IInvestment) => {
                investments[investment.uuid] = investment;
            });

            return {
                ...state,
                availableInvestments: typedAction.investments.map((investment: IInvestment) => investment.uuid),
                investments,
            };
        }

        case InvestorActionsEnum.InvestmentsRecentlyFundedSet: {
            const typedAction: IInvestmentsRecentlyFundedSetAction = action as IInvestmentsRecentlyFundedSetAction;

            const investments: { [investmentId: string]: IInvestment } = { ...state.investments };

            typedAction.investments.forEach((investment: IInvestment) => {
                investments[investment.uuid] = investment;
            });

            return {
                ...state,
                investments,
                recentlyFundedInvestments: typedAction.investments.map((investment: IInvestment) => investment.uuid),
            };
        }

        case InvestorActionsEnum.MonthlyStatementsSet: {
            const typedAction: IMonthlyStatementsSetAction = action as IMonthlyStatementsSetAction;

            const monthlyStatements: IDictionary<IMonthlyStatement> = { ...state.monthlyStatements };
            const accountMonthlyStatementUuids: string[] = [];

            typedAction.monthlyStatements.forEach((statement: IMonthlyStatement) => {
                monthlyStatements[statement.uuid] = statement;
                accountMonthlyStatementUuids.push(statement.uuid);
            });

            return {
                ...state,
                accountMonthlyStatementUuids: {
                    ...state.accountMonthlyStatementUuids,
                    [typedAction.investorAccountUuid]: accountMonthlyStatementUuids,
                },
                monthlyStatements,
            };
        }

        case InvestorActionsEnum.ReferralAddErrorSet: {
            const typedAction: IReferralAddErrorSetAction = action as IReferralAddErrorSetAction;

            return {
                ...state,
                referralAddErrors: {
                    ...state.referralAddErrors,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        case InvestorActionsEnum.ReferralAddErrorsSet: {
            const typedAction: IReferralAddErrorsSetAction = action as IReferralAddErrorsSetAction;

            return {
                ...state,
                referralAddErrors: typedAction.errors,
            };
        }

        case InvestorActionsEnum.ReferralAddInProgressSet: {
            const typedAction: IReferralAddInProgressSetAction = action as IReferralAddInProgressSetAction;

            return {
                ...state,
                referralAddInProgress: typedAction.inProgress,
            };
        }

        case InvestorActionsEnum.ReferralEmailSet: {
            const typedAction: IReferralEmailSetAction = action as IReferralEmailSetAction;

            return {
                ...state,
                referralEmail: typedAction.email,
            };
        }

        case InvestorActionsEnum.ReferralSet: {
            const typedAction: IReferralSetAction = action as IReferralSetAction;

            return {
                ...state,
                referrals: {
                    ...state.referrals,
                    [typedAction.referral.uuid]: typedAction.referral,
                },
            };
        }

        case InvestorActionsEnum.ReferralsSet: {
            const typedAction: IReferralsSetAction = action as IReferralsSetAction;

            return {
                ...state,
                referrals: _.keyBy(typedAction.referrals, 'uuid'),
            };
        }

        case InvestorActionsEnum.RegisterErrorsSet: {
            const typedAction: IRegisterErrorsSetAction = action as IRegisterErrorsSetAction;

            return {
                ...state,
                registerErrors: typedAction.errors,
            };
        }

        case InvestorActionsEnum.RegisterErrorSet: {
            const typedAction: IRegisterErrorSetAction = action as IRegisterErrorSetAction;

            return {
                ...state,
                registerErrors: {
                    ...state.registerErrors,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        case InvestorActionsEnum.RegisterInProgressSet: {
            const typedAction: IRegisterInProgressSetAction = action as IRegisterInProgressSetAction;

            return {
                ...state,
                registerInProgress: typedAction.inProgress,
            };
        }

        case InvestorActionsEnum.SharesSet: {
            const typedAction: ISharesSetAction = action as ISharesSetAction;

            return {
                ...state,
                shares: _.keyBy(typedAction.shares, 'uuid'),
            };
        }

        case InvestorActionsEnum.TopUpCompleteModalHide: {
            const typedAction: ITopUpCompleteModalHideAction = action as ITopUpCompleteModalHideAction;

            return {
                ...state,
                topUpCompleteModalShow: false,
            };
        }

        case InvestorActionsEnum.TopUpCompleteModalShow: {
            const typedAction: ITopUpCompleteModalShowAction = action as ITopUpCompleteModalShowAction;

            return {
                ...state,
                topUpCompleteModalShow: true,
            };
        }

        case InvestorActionsEnum.TopUpConfirmModalHide: {
            const typedAction: ITopUpConfirmModalHideAction = action as ITopUpConfirmModalHideAction;

            return {
                ...state,
                topUpConfirmModalShow: false,
            };
        }

        case InvestorActionsEnum.TopUpConfirmModalShow: {
            const typedAction: ITopUpConfirmModalShowAction = action as ITopUpConfirmModalShowAction;

            return {
                ...state,
                topUpConfirmModalShow: true,
            };
        }

        case InvestorActionsEnum.TopUpErrorSet: {
            const typedAction: ITopUpErrorSetAction = action as ITopUpErrorSetAction;

            return {
                ...state,
                topUpErrors: {
                    ...state.topUpErrors,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        case InvestorActionsEnum.TopUpErrorsSet: {
            const typedAction: ITopUpErrorsSetAction = action as ITopUpErrorsSetAction;

            return {
                ...state,
                topUpErrors: typedAction.errors,
            };
        }

        case InvestorActionsEnum.TopUpInProgressSet: {
            const typedAction: ITopUpInProgressSetAction = action as ITopUpInProgressSetAction;

            return {
                ...state,
                topUpInProgress: typedAction.inProgress,
            };
        }

        case InvestorActionsEnum.TopUpModalHide: {
            const typedAction: ITopUpModalHideAction = action as ITopUpModalHideAction;

            return {
                ...state,
                topUpModalShow: false,
            };
        }

        case InvestorActionsEnum.TopUpModalShow: {
            const typedAction: ITopUpModalShowAction = action as ITopUpModalShowAction;

            return {
                ...state,
                topUpModalShow: true,
            };
        }

        case InvestorActionsEnum.TransactionsSet: {
            const typedAction: ITransactionsSetAction = action as ITransactionsSetAction;

            return {
                ...state,
                transactions: _.keyBy(typedAction.transactions, 'uuid'),
            };
        }

        case InvestorActionsEnum.WithdrawCompleteModalHide: {
            const typedAction: IWithdrawCompleteModalHideAction = action as IWithdrawCompleteModalHideAction;

            return {
                ...state,
                withdrawCompleteModalShow: false,
            };
        }

        case InvestorActionsEnum.WithdrawCompleteModalShow: {
            const typedAction: IWithdrawCompleteModalShowAction = action as IWithdrawCompleteModalShowAction;

            return {
                ...state,
                withdrawCompleteModalShow: true,
            };
        }

        case InvestorActionsEnum.WithdrawConfirmModalHide: {
            const typedAction: IWithdrawConfirmModalHideAction = action as IWithdrawConfirmModalHideAction;

            return {
                ...state,
                withdrawConfirmModalShow: false,
            };
        }

        case InvestorActionsEnum.WithdrawConfirmModalShow: {
            const typedAction: IWithdrawConfirmModalShowAction = action as IWithdrawConfirmModalShowAction;

            return {
                ...state,
                withdrawConfirmModalShow: true,
            };
        }

        case InvestorActionsEnum.WithdrawErrorSet: {
            const typedAction: IWithdrawErrorSetAction = action as IWithdrawErrorSetAction;

            return {
                ...state,
                withdrawErrors: {
                    ...state.withdrawErrors,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        case InvestorActionsEnum.WithdrawInProgressSet: {
            const typedAction: IWithdrawInProgressSetAction = action as IWithdrawInProgressSetAction;

            return {
                ...state,
                withdrawInProgress: typedAction.inProgress,
            };
        }

        case InvestorActionsEnum.WithdrawModalHide: {
            const typedAction: IWithdrawModalHideAction = action as IWithdrawModalHideAction;

            return {
                ...state,
                withdrawModalShow: false,
            };
        }

        case InvestorActionsEnum.WithdrawModalShow: {
            const typedAction: IWithdrawModalShowAction = action as IWithdrawModalShowAction;

            return {
                ...state,
                withdrawModalShow: true,
            };
        }

        case InvestorActionsEnum.WithdrawErrorsSet: {
            const typedAction: IWithdrawErrorsSetAction = action as IWithdrawErrorsSetAction;

            return {
                ...state,
                withdrawErrors: typedAction.errors,
            };
        }

        case AuthActionsEnum.Login: {
            return initialData;
        }

        case AuthActionsEnum.Logout: {
            return initialData;
        }

        default:
            return state;
    }
}
