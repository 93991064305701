import React from 'react';
import { Route, Router as ReactRouter } from 'react-router-dom';
import history from '~history';
import Register from './Register';
import EmailVerification from './EmailVerification';
import Login from './Login';
import LoginPassword from './LoginPassword';
import SetPassword from './SetPassword';
import Thanks from './Thanks';
import VerifyEmail from './VerifyEmail';

class Router extends React.Component {
    public render(): JSX.Element {
        return (
            <ReactRouter history={history}>
                <Route exact={true} path='/broker' component={Login} />
                <Route exact={true} path='/broker/login-password' component={LoginPassword} />
                <Route exact={true} path='/broker/login-require-email-verification' component={EmailVerification} />
                <Route exact={true} path='/broker/login-set-password' component={SetPassword} />
                <Route exact={true} path='/broker/login-verify-email/:code' component={VerifyEmail} />
                <Route exact={true} path='/broker/register' component={Register} />
                <Route exact={true} path='/broker/register/thanks' component={Thanks} />
            </ReactRouter>
        );
    }
}

export default Router;
