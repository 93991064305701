import IncomeTrustClassTypeEnum from '~Api/Investor/IncomeTrustClassTypeEnum';

export default {
    /* eslint-disable sort-keys */
    // Order by latest date first
    INCOME_TRUST_INTEREST_RATES: {
        [IncomeTrustClassTypeEnum.ClassC]: {
            '2023-12-01': 0.08,
            '2023-07-01': 0.0725,
            '2023-06-01': 0.07,
            '2023-03-01': 0.0675,
            '2022-10-01': 0.065,
            '2021-04-01': 0.06,
        },
        [IncomeTrustClassTypeEnum.ClassD]: {
            '2023-12-01': 0.085,
            '2023-07-01': 0.0775,
        },
    },
    /* eslint-enable sort-keys */
};
