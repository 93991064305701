import React from 'react';
import PublicLayout from '~Public/Layout';
import './adviser.less';

class Layout extends React.Component {
    public render(): JSX.Element {
        return (
            <PublicLayout>
                <div className='adviser'>
                    {this.props.children}
                </div>
            </PublicLayout>
        );
    }
}

export default Layout;
