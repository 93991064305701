import React from 'react';
import { Button, Container, Form, InputGroup, Spinner } from 'react-bootstrap';
import { FaLock } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { brokerLoginErrorSetAction, brokerPasswordAction } from '~Broker/actions';
import {
    brokerInProgressSelector,
    brokerLoginErrorsSelector,
} from '~Broker/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';

interface IState {
    password: string;
}

interface IPropsSelector {
    inProgress: boolean;
    portalErrors: any;
}

interface IPropsDispatch {
    setError: (key: string, value: string) => void;
    setPassword: (password: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class SetPassword extends React.Component<Props, IState> {
    public state: IState = {
        password: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangePassword = this.onChangePassword.bind(this);
        this.onClickSetPassword = this.onClickSetPassword.bind(this);

        this.validatePassword = this.validatePassword.bind(this);
    }

    public render(): JSX.Element {
        const { portalErrors, inProgress } = this.props;
        const { password } = this.state;

        return (
            <Layout>
                <div className='broker'>
                    <Container className='login'>
                        <h3>Broker Login</h3>
                        <p>Please set your password for your portal login.</p>
                        <Form>
                            <Form.Group>
                                <InputGroup>
                                    <InputGroup.Prepend><FaLock/></InputGroup.Prepend>
                                    <Form.Control
                                        isInvalid={!!portalErrors.password}
                                        name='password'
                                        onBlur={this.validatePassword}
                                        onChange={this.onChangePassword}
                                        placeholder='Password'
                                        type='password'
                                        value={password || ''}
                                    />
                                    {!!portalErrors.password && <Form.Control.Feedback type='invalid'>{portalErrors.password}</Form.Control.Feedback>}
                                </InputGroup>
                            </Form.Group>
                            <Button className='continue' disabled={inProgress} onClick={this.onClickSetPassword} type='submit' variant='primary'>
                                {inProgress && <Spinner animation='border' as='span' role='status' size='sm' />}
                                {' '}Set Password
                            </Button>
                        </Form>
                    </Container>
                </div>
            </Layout>
        );
    }

    private onChangePassword(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            password: event.target.value,
        });
    }

    private onClickSetPassword() {
        const { password } = this.state;

        let valid = true;
        valid = this.validatePassword() && valid;

        if (!valid) {
            return;
        }

        this.props.setPassword(password);
    }

    private validatePassword(): boolean {
        const { password } = this.state;

        let error: string;

        if (!password || password.length === 0) {
            error = 'Please enter a password';
        } else if (password.length < 6) {
            error = 'Password must be at least 6 characters';
        }

        this.props.setError('password', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        inProgress: brokerInProgressSelector(state),
        portalErrors: brokerLoginErrorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        setError: (key: string, value: string) => dispatch(brokerLoginErrorSetAction(key, value)),
        setPassword: (password: string) => dispatch(brokerPasswordAction(password)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SetPassword);
