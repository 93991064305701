import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Button, Container, Spinner, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import EstablishmentFeeFormatEnum from '~Api/Deal/EstablishmentFeeFormatEnum';
import IDeal from '~Api/Deal/IDeal';
import IDealQuote from '~Api/Deal/IDealQuote';
import IDealQuoteProperty from '~Api/Deal/IDealQuoteProperty';
import MortgageTypeEnum from '~Api/Deal/MortgageTypeEnum';
import {
    dealGetAction,
    dealQuotesListAction,
} from '~Lead/actions';
import {
    currentDealAutoQuoteSelector,
    currentDealUuidSelector,
    dealSelector,
} from '~Lead/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';

const mortgageTypeLabels: IDictionary<string> = {
    [MortgageTypeEnum.FirstMortgage]: 'First Mortgage',
    [MortgageTypeEnum.SecondMortgage]: 'Second Mortgage',
};

interface IPropsSelector {
    deal: IDeal;
    dealUuid: string;
    quote: IDealQuote;
}

interface IPropsDispatch {
    dealGet: () => void;
    dealQuotesList: (dealUuid: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Quote extends React.Component<Props> {
    public componentDidMount(): void {
        const { deal, dealUuid, quote } = this.props;

        // Using uuid instead because the deal is defined but with null fields in the reducer
        if (!deal || !deal.uuid) {
            this.props.dealGet();
        }

        if (!quote) {
            this.props.dealQuotesList(dealUuid);
        }
    }

    public render(): JSX.Element {
        const { deal, quote } = this.props;

        if (!deal || !quote) {
            return (
                <Layout showProgressBar={false} currentStep={7}>
                    <Container className='quote'>
                        <h3>Your Loan Quote</h3>
                        <Spinner animation='border' />
                    </Container>
                </Layout>
            );
        }

        const currencyFormatter = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const percentageFormatter = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const propertiesBlock: JSX.Element[] = _.map(quote.properties, (property: IDealQuoteProperty) => <div>{property.name}</div>);

        return (
            <Layout showProgressBar={false} currentStep={7}>
                <h3>Your Loan Quote</h3>
                <p className='intro'>Thanks for choosing Funding for your fast and simple bridging solution.</p>
                <h4 className='quote-subheading'>Loan Details</h4>
                <Table
                    className='quote-details'
                    bordered={true}
                >
                    <tbody>
                        <tr>
                            <th>Quote Number</th>
                            <td>{quote.code}</td>
                        </tr>
                        <tr>
                            <th>Application Number</th>
                            <td>{deal.code}</td>
                        </tr>
                        <tr>
                            <th>Date</th>
                            <td>{moment(quote.createdTime).format('D MMMM YYYY')}</td>
                        </tr>
                        <tr>
                            <th>Loan Amount</th>
                            <td>{currencyFormatter.format(quote.loanAmount)}</td>
                        </tr>
                        <tr>
                            <th>Loan To Value Ratio</th>
                            <td>{percentageFormatter.format(quote.lvr / 100)} of the property value (or less)</td>
                        </tr>
                        <tr>
                            <th>Security</th>
                            <td>{mortgageTypeLabels[quote.mortgageType]}</td>
                        </tr>
                        <tr>
                            <th>Term</th>
                            <td>{quote.termMonths} month term</td>
                        </tr>
                        <tr>
                            <th>Interest Rate</th>
                            <td>{percentageFormatter.format(quote.interestRate / 100)} per annum</td>
                        </tr>
                        <tr>
                            <th>Establishment Fee</th>
                            <td>{quote.establishmentFeeFormat === EstablishmentFeeFormatEnum.Percentage ? percentageFormatter.format(quote.establishmentFeePercentage / 100) : currencyFormatter.format(quote.establishmentFee)}</td>
                        </tr>
                        <tr>
                            <th>Legal Fee</th>
                            <td>{currencyFormatter.format(quote.legalFees)} Legal fee + GST/Outlays</td>
                        </tr>
                        <tr>
                            <th>Property</th>
                            <td>{propertiesBlock}</td>
                        </tr>
                    </tbody>
                </Table>
                <h4 className='quote-subheading'>Disbursed as follows</h4>
                <Table
                    className='quote-details'
                    bordered={true}
                >
                    <tbody>
                        <tr>
                            <th>Total Loan Amount</th>
                            <td>{currencyFormatter.format(quote.loanAmount)}</td>
                        </tr>
                        <tr className='less'>
                            <th colSpan={2}>Less</th>
                        </tr>
                        <tr>
                            <th>{quote.termMonths} Months Interest at {percentageFormatter.format(quote.interestRate / 100)}</th>
                            <td>{currencyFormatter.format(quote.loanAmount * (quote.interestRate / 100) / 12 * quote.termMonths)}</td>
                        </tr>
                        <tr>
                            <th>Establishment Fee</th>
                            <td>{currencyFormatter.format(quote.establishmentFee)}</td>
                        </tr>
                        <tr>
                            <th>Legal Fee Incl GST</th>
                            <td>{currencyFormatter.format(quote.legalFees)}</td>
                        </tr>
                        <tr>
                            <th>Estimated Outlays and Registration Fees</th>
                            <td>{currencyFormatter.format(quote.estimatedOutlays)}</td>
                        </tr>
                        <tr>
                            <th>Current Property Debt</th>
                            <td>{currencyFormatter.format(quote.currentDebt)}</td>
                        </tr>
                        <tr>
                            <th>Balance (approx.)</th>
                            <td>{currencyFormatter.format(quote.netBalance)}</td>
                        </tr>
                    </tbody>
                </Table>
                <Link to={`/quote/${quote.linkCode}`}><Button className='apply-now' variant='primary'>Apply Now 🎉</Button></Link>
                <p>All loan fees are paid at loan settlement and included in the loan amount.</p>
                <p>The only upfront cost to kick start your loan is {currencyFormatter.format(quote.commitmentFee)} to cover the valuation of the security property / properties.</p>
                <p>If you want to get started apply now, to complete our online application in 5 minutes.</p>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        deal: dealSelector(state),
        dealUuid: currentDealUuidSelector(state),
        quote: currentDealAutoQuoteSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        dealGet: () => dispatch(dealGetAction()),
        dealQuotesList: (dealUuid: string) => dispatch(dealQuotesListAction(dealUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Quote);
