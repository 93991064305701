import _ from 'lodash';
import BorrowerCategoryEnum from './BorrowerCategoryEnum';
import BorrowerTypeEnum from './BorrowerTypeEnum';
import IApplication from './IApplication';
import IApplicationBorrower from './IApplicationBorrower';
import IncomeTypeEnum from './IncomeTypeEnum';
import LicenceStateEnum from './LicenceStateEnum';
import LoanPurposeEnum from './LoanPurposeEnum';
import MaritalStatusEnum from './MaritalStatusEnum';
import RepaymentStrategyEnum from './RepaymentStrategyEnum';
import WorkflowStatusEnum from './WorkflowStatusEnum';

export function parseApplication(raw: any): IApplication {
    return {
        borrowers: raw.borrowers.map(parseApplicationBorrower),
        brokerageFee: raw.brokerageFee,
        createdTime: raw.createdTime,
        dealUuid: raw.dealUuid,
        formattedName: raw.formattedName,
        loanAmount: raw.loanAmount ? Number(raw.loanAmount) : null,
        loanPurpose: raw.loanPurpose as LoanPurposeEnum,
        primaryBorrowerUuid: raw.primaryBorrowerUuid,
        repaymentStrategy: raw.repaymentStrategy as RepaymentStrategyEnum,
        termMonths: raw.termMonths ? Number(raw.termMonths) : null,
        uuid: raw.uuid,
        workflowStatus: raw.workflowStatus as WorkflowStatusEnum,
    };
}

export function parseApplicationBorrower(raw: any): IApplicationBorrower {
    return {
        abn: raw.abn,
        address: raw.address,
        businessName: raw.businessName,
        category: raw.category as BorrowerCategoryEnum,
        dependentNumber: raw.dependentNumber,
        dob: raw.dob,
        email: raw.email,
        firstName: raw.firstName,
        gender: raw.gender,
        idType: raw.idType,
        incomeType: raw.incomeType as IncomeTypeEnum,
        jobTitle: raw.jobTitle,
        lastName: raw.lastName,
        licenceNumber: raw.licenceNumber,
        licenceState: raw.licenceState as LicenceStateEnum,
        maritalStatus: raw.maritalStatus as MaritalStatusEnum,
        middleName: raw.middleName,
        monthlyGrossExpenses: raw.monthlyGrossExpenses ? Number(raw.monthlyGrossExpenses) : null,
        monthlyGrossTurnover: raw.monthlyGrossTurnover ? Number(raw.monthlyGrossTurnover) : null,
        monthlyHouseholdExpenses: raw.monthlyHouseholdExpenses ? Number(raw.monthlyHouseholdExpenses) : null,
        onTitle: raw.onTitle,
        otherIncomeType: raw.otherIncomeType,
        passportCountry: raw.passportCountry,
        passportNumber: raw.passportNumber,
        phone: raw.phone,
        trustName: raw.trustName,
        trusteeName: raw.trusteeName,
        type: raw.type as BorrowerTypeEnum,
        uuid: raw.uuid,
        yearlyIncome: raw.yearlyIncome ? Number(raw.yearlyIncome) : null,
    };
}
