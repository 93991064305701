import _ from 'lodash';
import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IApplicationErrors from '~Api/Application/IApplicationErrors';
import LoanPurposeEnum from '~Api/Application/LoanPurposeEnum';
import IDeal from '~Api/Deal/IDeal';
import history from '~history';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import {
    applicationErrorSetAction,
    applicationValueSetAction,
    dealGetAction,
} from './actions';
import Layout from './Layout';
import {
    applicationSelector,
    currentDealSelector,
    errorsSelector,
} from './selectors';

const loanPurposeLabels: IDictionary<string> = {
    [LoanPurposeEnum.BridgingLoan]: 'Bridging loan',
    [LoanPurposeEnum.Refinance]: 'Refinance an existing property',
    [LoanPurposeEnum.BusinessLoan]: 'Business loan',
    [LoanPurposeEnum.PersonalLoan]: 'Personal loan',
    [LoanPurposeEnum.RenovateOrBuild]: 'Renovate or build',
    [LoanPurposeEnum.DevelopmentLoan]: 'Development loan',
};

interface IPropsSelector {
    application: IApplication;
    deal: IDeal;
    errors: IApplicationErrors;
}

interface IPropsDispatch {
    applicationValueSet: (field: keyof IApplication, value: any) => void;
    dealGet: () => void;
    errorSet: (field: keyof IApplicationErrors, value: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Purpose extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeLoanPurpose = this.onChangeLoanPurpose.bind(this);

        this.onClickContinue = this.onClickContinue.bind(this);

        this.validateLoanPurpose = this.validateLoanPurpose.bind(this);
    }

    public componentDidMount() {
        const { application, deal } = this.props;

        if (!application || !deal) {
            this.props.dealGet();
        }
    }

    public render(): JSX.Element {
        const { application, deal, errors } = this.props;

        if (!application || !deal) {
            return (
                <Layout containerClass='purpose' stepNumber={1}>
                    <h3>Loan Details</h3>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        return (
            <Layout containerClass='purpose' stepNumber={1}>
                <h3>Loan Details</h3>
                <p className='intro'>Why do you need to borrow funds?</p>
                <Form.Group className='loan-purpose'>
                    <Form.Label>Loan Purpose</Form.Label>
                    <Form.Control as='select' className='custom-select' isInvalid={!!errors.loanPurpose} onBlur={this.validateLoanPurpose} onChange={this.onChangeLoanPurpose} value={application.loanPurpose || ''}>
                        <option/>
                        {_.map(loanPurposeLabels, (loanPurposeLabel: string, key: LoanPurposeEnum) => <option value={key}>{loanPurposeLabel}</option>)}
                    </Form.Control>
                    {errors.loanPurpose && <Form.Control.Feedback type='invalid'>{errors.loanPurpose}</Form.Control.Feedback>}
                </Form.Group>
                <div className='actions'>
                    <Button className='continue' onClick={this.onClickContinue} variant='primary'>Continue</Button>
                </div>
            </Layout>
        );
    }

    private onChangeLoanPurpose(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.applicationValueSet('loanPurpose', event.target.value as LoanPurposeEnum || null);
    }

    private onClickContinue() {
        let valid = true;

        valid = this.validateLoanPurpose() && valid;

        if (!valid) {
            return;
        }

        history.push(`/application/amount`);
    }

    private validateLoanPurpose(): boolean {
        const { application } = this.props;

        let error: string = null;

        if (!application.loanPurpose) {
            error = 'Please choose the loan purpose';
        }

        this.props.errorSet('loanPurpose', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        application: applicationSelector(state),
        deal: currentDealSelector(state),
        errors: errorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        applicationValueSet: (field: keyof IApplication, value: any) => dispatch(applicationValueSetAction(field, value)),
        dealGet: () => dispatch(dealGetAction()),
        errorSet: (field: keyof IApplicationErrors, value: string) => dispatch(applicationErrorSetAction(field, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Purpose);
