import Cookies from 'js-cookie';
import _ from 'lodash';
import CreationMethodEnum from '~Api/Deal/CreationMethodEnum';
import IDeal, { IDealErrors } from '~Api/Deal/IDeal';
import IDealProperty from '~Api/Deal/IDealProperty';
import IDealQuote from '~Api/Deal/IDealQuote';
import IToken from '~Auth/IToken';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { verifyToken } from '~utilities/jwt';

function hydrateDeal(state: IGlobalState, deal: IDeal): IDeal {
    const cloneDeal: IDeal = { ...deal };
    cloneDeal.properties = propertiesSelector(state);
    return cloneDeal;
}

export function borrowStepSelector(state: IGlobalState): number {
    return state.lead.borrowStep;
}

export function currentDealUuidSelector(state: IGlobalState): string {
    const token: any = tokenSelector(state);

    return token.dealUuid;
}

export function currentDealAutoQuoteSelector(state: IGlobalState): IDealQuote {
    const dealUuid: string = currentDealUuidSelector(state);

    if (!dealUuid || !state.lead.dealQuoteUuids || !state.lead.dealQuoteUuids[dealUuid] || state.lead.dealQuoteUuids[dealUuid].length !== 1) {
        return null;
    }

    const quote: IDealQuote = state.lead.quotes[state.lead.dealQuoteUuids[dealUuid][0]];

    return quote.creationMethod === CreationMethodEnum.Auto ? quote : null;
}

export function dealSelector(state: IGlobalState): IDeal {
    return state.lead.deal && hydrateDeal(state, state.lead.deal);
}

export function dealsSelector(state: IGlobalState): IDictionary<IDeal> {
    if (!state.lead.dealsListed || !state.lead.deals) {
        return null;
    }

    const deals: IDictionary<IDeal> = {};

    _.forEach(state.lead.deals, (deal: IDeal) => {
        deals[deal.uuid] = hydrateDeal(state, deal);
    });

    return deals;
}

export function errorsSelector(state: IGlobalState): IDealErrors {
    return state.lead.errors;
}

export function propertiesSelector(state: IGlobalState): IDealProperty[] {
    return _.values(state.lead.properties);
}

export function propertySelector(state: IGlobalState, uuid: string): IDealProperty {
    return state.lead.properties[uuid];
}

export function savingSelector(state: IGlobalState): boolean {
    return state.lead.saving;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function tokenSelector(state: IGlobalState): IToken {
    const borrowCookie: any = Cookies.getJSON('jwt_token');

    let token: IToken = null;

    if (borrowCookie) {
        try {
            token = verifyToken(borrowCookie);
        } catch (e: any) {
            switch (e.name) {
                case 'TokenExpiredError':
                    Cookies.remove('jwt_token');
                    break;
                default:
                    throw e;
            }
        }
    }

    return token;
}
