import React, { ReactElement } from 'react';
import './statements.less';
import Layout from './Layout';
import AnnualStatements from './AnnualStatements';
import MonthlyStatements from './MonthlyStatements';
import { Alert } from 'react-bootstrap';

export default function Statements(): ReactElement {
    return (
        <Layout section='statements'>
            <h1>Statements</h1>
            <Alert>
                <Alert.Heading>Annual Income Statements now available</Alert.Heading>
                Your Annual Income Statement for the year ended 30 June 2024 is now available for you to download.
            </Alert>
            <div className='statements'>
                <AnnualStatements/>
                <MonthlyStatements/>
            </div>
        </Layout>
    );
}
