import React from 'react';
import { Container } from 'react-bootstrap';
import PublicLayout from '~Public/Layout';
import './application.less';
import Steps from './Steps';

interface IProps {
    containerClass: string;
    stepNumber: number;
}

type Props = IProps;

class Layout extends React.Component<Props> {
    public render(): JSX.Element {
        const { containerClass, stepNumber } = this.props;

        return (
            <PublicLayout>
                <div className='application'>
                    <Steps currentStep={stepNumber} />

                    <Container className={containerClass}>
                        {this.props.children}
                    </Container>
                </div>
            </PublicLayout>
        );
    }
}

export default Layout;
