import { AnyAction } from 'redux';
import IBroker from '~Api/Broker/IBroker';
import { IDictionary } from '~utilities/IDictionary';
import {
    IBrokerCheckEmailAction,
    IBrokerInProgressAction,
    IBrokerLoginErrorSetAction,
    IBrokerLoginErrorsSetAction,
    IBrokerSetAction,
    ICurrentBrokerAddressUpdateModalShowAction,
} from './actions';
import BrokerActionsEnum from './ActionsEnum';

export interface IBrokerState {
    addressUpdateModalShow: boolean;
    brokerEmail: string;
    currentBroker: IBroker;
    inProgress: boolean;
    loginErrors: IDictionary<string>;
}

const initialData: IBrokerState = {
    addressUpdateModalShow: false,
    brokerEmail: null,
    currentBroker: null,
    inProgress: false,
    loginErrors: {},
};

export function brokerReducer(state: IBrokerState = initialData, action: AnyAction): IBrokerState {
    switch (action.type) {
        case BrokerActionsEnum.BrokerAddressUpdateModalShow: {
            const typedAction: ICurrentBrokerAddressUpdateModalShowAction = action as ICurrentBrokerAddressUpdateModalShowAction;

            return {
                ...state,
                addressUpdateModalShow: typedAction.showModal,
            };
        }

        case BrokerActionsEnum.BrokerCheckEmail: {
            const typedAction: IBrokerCheckEmailAction = action as IBrokerCheckEmailAction;

            return {
                ...state,
                brokerEmail: typedAction.email,
            };
        }

        case BrokerActionsEnum.BrokerInProgress: {
            const typedAction: IBrokerInProgressAction = action as IBrokerInProgressAction;

            return {
                ...state,
                inProgress: typedAction.inProgress,
            };
        }

        case BrokerActionsEnum.BrokerSet: {
            const typedAction: IBrokerSetAction = action as IBrokerSetAction;

            return {
                ...state,
                currentBroker: typedAction.broker,
            };
        }

        case BrokerActionsEnum.BrokerLoginErrorsSet: {
            const typedAction: IBrokerLoginErrorsSetAction = action as IBrokerLoginErrorsSetAction;

            return {
                ...state,
                loginErrors: typedAction.errors,
            };
        }

        case BrokerActionsEnum.BrokerLoginErrorSet: {
            const typedAction: IBrokerLoginErrorSetAction = action as IBrokerLoginErrorSetAction;

            return {
                ...state,
                loginErrors: {
                    ...state.loginErrors,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        default:
            return state;
    }
}
