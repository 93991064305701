import _ from 'lodash';
import { AnyAction } from 'redux';
import { IToastAddAction, IToastHideAction } from './actions';
import GlobalActionsEnum from './ActionsEnum';
import IToast from './IToast';

export interface IGlobalGlobalState {
    toasts: IToast[];
}

const initialData: IGlobalGlobalState = {
    toasts: [],
};

export function globalReducer(state: IGlobalGlobalState = initialData, action: AnyAction): IGlobalGlobalState {
    switch (action.type) {
        case GlobalActionsEnum.ToastAdd: {
            const typedAction: IToastAddAction = action as IToastAddAction;

            return {
                ...state,
                toasts: [
                    ...state.toasts,
                    {
                        body: typedAction.body,
                        show: true,
                        title: typedAction.title,
                    },
                ],
            };
        }

        case GlobalActionsEnum.ToastHide: {
            const typedAction: IToastHideAction = action as IToastHideAction;

            const toasts: IToast[] = [ ...state.toasts ];

            toasts[typedAction.index].show = false;

            return {
                ...state,
                toasts,
            };
        }

        default:
            return state;
    }
}
