import React from 'react';

class TopUp extends React.Component {
    public render(): JSX.Element {
        return (
            <svg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='50' cy='53.208' r='45' fill='#E9F6F6'/>
                <path d='M50.2682 88.6189C62.2925 88.6189 72.0401 87.6553 72.0401 86.4666C72.0401 85.2778 62.2925 84.3142 50.2682 84.3142C38.2439 84.3142 28.4963 85.2778 28.4963 86.4666C28.4963 87.6553 38.2439 88.6189 50.2682 88.6189Z' fill='#81D2D4'/>
                <path d='M77.2524 67.4653V39.8957C77.2524 38.9795 76.8854 38.1007 76.2321 37.4528C75.5789 36.8049 74.6928 36.4409 73.7689 36.4409H26.7693C26.3107 36.4391 25.8562 36.5272 25.432 36.7C25.0077 36.8728 24.6221 37.127 24.2971 37.448C23.9722 37.7689 23.7144 38.1504 23.5385 38.5705C23.3625 38.9905 23.272 39.4409 23.272 39.8957V67.4653H77.2524Z' fill='#D2EDED'/>
                <path d='M30.2392 67.4653L61.5907 36.3726H26.7557C26.2923 36.3725 25.8336 36.464 25.4064 36.6419C24.9792 36.8198 24.5921 37.0804 24.2677 37.4085C23.9433 37.7366 23.6882 38.1256 23.5172 38.5527C23.3463 38.9798 23.263 39.4364 23.2722 39.8959V67.4653H30.2392Z' fill='white'/>
                <path d='M77.2525 67.4651V74.32C77.2525 75.2363 76.8855 76.1151 76.2322 76.763C75.579 77.4109 74.6929 77.7749 73.769 77.7749H26.7694C25.8455 77.7749 24.9595 77.4109 24.3062 76.763C23.6529 76.1151 23.2859 75.2363 23.2859 74.32V67.4651H77.2525Z' fill='white' stroke='#16254C' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
                <path d='M52.0108 72.6475C52.0108 72.9891 51.9087 73.323 51.7173 73.6071C51.5259 73.8912 51.2539 74.1125 50.9356 74.2433C50.6174 74.374 50.2672 74.4082 49.9293 74.3416C49.5914 74.2749 49.2811 74.1104 49.0375 73.8688C48.7939 73.6273 48.628 73.3195 48.5608 72.9844C48.4936 72.6494 48.5281 72.3021 48.6599 71.9865C48.7918 71.6708 49.015 71.4011 49.3014 71.2113C49.5879 71.0215 49.9246 70.9202 50.2691 70.9202C50.731 70.9202 51.174 71.1022 51.5007 71.4261C51.8273 71.75 52.0108 72.1894 52.0108 72.6475Z' fill='#81D2D4' stroke='#59C5C7' strokeLinecap='round' strokeLinejoin='round'/>
                <path d='M58.9782 84.739H41.5469L42.4316 77.8296H58.1073L58.9782 84.739Z' fill='white' stroke='#16254C' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
                <path d='M36.335 84.739H64.1892' stroke='#16254C' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
                <path d='M77.2524 67.4653V39.8957C77.2524 38.9795 76.8854 38.1007 76.2321 37.4528C75.5789 36.8049 74.6928 36.4409 73.7689 36.4409H26.7693C26.3107 36.4391 25.8562 36.5272 25.432 36.7C25.0077 36.8728 24.6221 37.127 24.2971 37.448C23.9722 37.7689 23.7144 38.1504 23.5385 38.5705C23.3625 38.9905 23.272 39.4409 23.272 39.8957V67.4653H77.2524Z' stroke='#16254C' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
                <path d='M47.513 58.9118C47.1376 58.9141 46.7654 58.8418 46.4181 58.6993C46.0708 58.5567 45.7552 58.3467 45.4896 58.0813L41.6995 54.2761C41.205 53.7336 40.9386 53.0215 40.9556 52.2876C40.9726 51.5538 41.2717 50.8547 41.7907 50.3357C42.3098 49.8166 43.0088 49.5175 43.7427 49.5005C44.4765 49.4835 45.1887 49.75 45.7312 50.2444L47.5281 52.0262L52.1638 47.3905C52.7063 46.8961 53.4185 46.6297 54.1523 46.6466C54.8861 46.6636 55.5852 46.9627 56.1043 47.4818C56.6233 48.0008 56.9224 48.6999 56.9394 49.4337C56.9564 50.1676 56.69 50.8797 56.1955 51.4222L49.5364 58.0813C49.2713 58.3474 48.9558 58.5578 48.6084 58.7004C48.2609 58.8431 47.8885 58.9149 47.513 58.9118Z' fill='#59C5C7' stroke='#16254C' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
                <path d='M23.0009 7C20.6274 6.99983 18.3071 7.70352 16.3336 9.02209C14.36 10.3407 12.8218 12.2149 11.9135 14.4077C11.0052 16.6005 10.7675 19.0134 11.2306 21.3413C11.6937 23.6692 12.8368 25.8075 14.5152 27.4857L31.4866 10.5714C30.3764 9.44746 29.0555 8.55347 27.5994 7.94063C26.1433 7.3278 24.5807 7.00816 23.0009 7Z' fill='#81D2D4'/>
                <path d='M23.0017 31C29.6291 31 35.0017 25.6274 35.0017 19C35.0017 12.3726 29.6291 7 23.0017 7C16.3743 7 11.0017 12.3726 11.0017 19C11.0017 25.6274 16.3743 31 23.0017 31Z' fill='white' stroke='#16254C' strokeWidth='2.5' strokeMiterlimit='10'/>
                <path d='M23.0007 25V26.5' stroke='#16254C' strokeWidth='1.95695' strokeLinecap='round' strokeLinejoin='round'/>
                <path d='M23.0007 11.5001V13.0001' stroke='#16254C' strokeWidth='1.95695' strokeLinecap='round' strokeLinejoin='round'/>
                <path d='M19.9866 22C19.9866 22.5933 20.1625 23.1734 20.4922 23.6667C20.8218 24.1601 21.2903 24.5446 21.8385 24.7716C22.3867 24.9987 22.9899 25.0581 23.5718 24.9424C24.1538 24.8266 24.6883 24.5409 25.1079 24.1213C25.5274 23.7018 25.8132 23.1672 25.9289 22.5853C26.0447 22.0033 25.9853 21.4001 25.7582 20.852C25.5311 20.3038 25.1466 19.8352 24.6533 19.5056C24.1599 19.1759 23.5799 19 22.9866 19C22.3932 19 21.8132 18.8241 21.3199 18.4944C20.8265 18.1648 20.442 17.6962 20.2149 17.1481C19.9879 16.5999 19.9285 15.9967 20.0442 15.4147C20.16 14.8328 20.4457 14.2982 20.8652 13.8787C21.2848 13.4591 21.8194 13.1734 22.4013 13.0576C22.9832 12.9419 23.5864 13.0013 24.1346 13.2284C24.6828 13.4554 25.1513 13.8399 25.481 14.3333C25.8106 14.8266 25.9866 15.4067 25.9866 16' fill='white'/>
                <path d='M19.9866 22C19.9866 22.5933 20.1625 23.1734 20.4922 23.6667C20.8218 24.1601 21.2903 24.5446 21.8385 24.7716C22.3867 24.9987 22.9899 25.0581 23.5718 24.9424C24.1538 24.8266 24.6883 24.5409 25.1079 24.1213C25.5274 23.7018 25.8132 23.1672 25.9289 22.5853C26.0447 22.0033 25.9853 21.4001 25.7582 20.852C25.5311 20.3038 25.1466 19.8352 24.6533 19.5056C24.1599 19.1759 23.5799 19 22.9866 19C22.3932 19 21.8132 18.8241 21.3199 18.4944C20.8265 18.1648 20.442 17.6962 20.2149 17.1481C19.9879 16.5999 19.9285 15.9967 20.0442 15.4147C20.16 14.8328 20.4457 14.2982 20.8652 13.8787C21.2848 13.4591 21.8194 13.1734 22.4013 13.0576C22.9832 12.9419 23.5864 13.0013 24.1346 13.2284C24.6828 13.4554 25.1513 13.8399 25.481 14.3333C25.8106 14.8266 25.9866 15.4067 25.9866 16' stroke='#16254C' strokeWidth='1.95695' strokeLinecap='round' strokeLinejoin='round'/>
                <path d='M90.5543 16.6946L63.7307 6.74662C63.0574 6.49688 62.309 6.84031 62.0593 7.51369L56.4522 22.6324C56.2025 23.3058 56.5459 24.0542 57.2193 24.3039L84.0429 34.2519C84.7163 34.5016 85.4646 34.1582 85.7144 33.4848L91.3214 18.3661C91.5711 17.6927 91.2277 16.9444 90.5543 16.6946Z' fill='white' stroke='#16254C' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
                <path d='M76.8081 21.5832C76.595 22.1629 76.2147 22.6664 75.7153 23.0298C75.2159 23.3933 74.6199 23.6004 74.0027 23.6249C73.3856 23.6495 72.775 23.4903 72.2484 23.1677C71.7217 22.845 71.3026 22.3733 71.0442 21.8123C70.7857 21.2514 70.6995 20.6263 70.7965 20.0163C70.8935 19.4064 71.1693 18.8389 71.589 18.3857C72.0087 17.9326 72.5534 17.6141 73.1542 17.4707C73.7549 17.3273 74.3847 17.3654 74.9638 17.5801C75.7365 17.8701 76.3635 18.4531 76.7088 19.2026C77.0541 19.9521 77.0898 20.8076 76.8081 21.5832Z' fill='#59C5C7'/>
                <path d='M84.4952 27.7723L86.6713 21.9223C85.8941 21.633 85.2633 21.0473 84.9171 20.2937C84.5708 19.5401 84.5375 18.68 84.8244 17.9019L67.277 11.3908C66.9877 12.168 66.4021 12.7988 65.6485 13.1451C64.8949 13.4913 64.0347 13.5246 63.2566 13.2377L61.0805 19.0877C61.4658 19.2289 61.8195 19.4448 62.1213 19.7229C62.4231 20.0009 62.6672 20.3358 62.8394 20.7083C63.0116 21.0808 63.1087 21.4837 63.1251 21.8937C63.1414 22.3038 63.0767 22.7131 62.9347 23.0981L80.4821 29.6092C80.7707 28.8335 81.3557 28.2042 82.1082 27.8597C82.8608 27.5152 83.7194 27.4838 84.4952 27.7723Z' stroke='#16254C' strokeWidth='1.8' strokeLinecap='round' strokeLinejoin='round'/>
            </svg>
        );
    }
}

export default TopUp;
