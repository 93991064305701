enum UserActionsEnum {
    CurrentUserGet = 'USER/CURRENT_USER/GET',
    CurrentUserSet = 'USER/CURRENT_USER/SET',

    EmailChange = 'USER/EMAIL_CHANGE',
    EmailChangeErrorSet = 'USER/EMAIL_CHANGE/ERROR/SET',
    EmailChangeErrorsSet = 'USER/EMAIL_CHANGE/ERRORS/SET',
    EmailChangeInProgressSet = 'USER/EMAIL_CHANGE/IN_PROGRESS/SET',
    EmailChangeModalHide = 'USER/EMAIL_CHANGE/MODAL/HIDE',
    EmailChangeModalShow = 'USER/EMAIL_CHANGE/MODAL/SHOW',
    EmailVerify = 'USER/EMAIL_VERIFY',

    PasswordChange = 'USER/PASSWORD_CHANGE',
    PasswordChangeErrorSet = 'USER/PASSWORD_CHANGE/ERROR/SET',
    PasswordChangeErrorsSet = 'USER/PASSWORD_CHANGE/ERRORS/SET',
    PasswordChangeInProgressSet = 'USER/PASSWORD_CHANGE/IN_PROGRESS/SET',
    PasswordChangeModalHide = 'USER/PASSWORD_CHANGE/MODAL/HIDE',
    PasswordChangeModalShow = 'USER/PASSWORD_CHANGE/MODAL/SHOW',
}

export default UserActionsEnum;
