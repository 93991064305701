import numeral from 'numeral';
import React from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IInvestment from '~Api/Investment/IInvestment';

interface IProps {
    investment: IInvestment;
}

type Props = IProps;

class InvestmentBox extends React.Component<Props> {
    public render(): JSX.Element {
        const { investment } = this.props;

        const termBlock: JSX.Element = investment.amountRemaining === 0 ? (
            <div className='term'>
                <div className='label'>Term</div>
                <div className='value'>
                    <div className='number'>{investment.termMonths}</div>
                    <div className='suffix'>Month{investment.termMonths > 1 && 's'}</div>
                </div>
            </div>
        ) : (
            <div className='term-remaining'>
                <div className='label'>Term remaining</div>
                <div className='value'>
                    {investment.timeRemaining.months > 0 && <React.Fragment><div className='number'>{investment.timeRemaining.months}</div><div className='suffix'>Month{investment.timeRemaining.months > 1 && 's'}</div></React.Fragment>}
                    {investment.timeRemaining.days > 0 && <React.Fragment><div className='number'>{investment.timeRemaining.days}</div><div className='suffix'>Day{investment.timeRemaining.days > 1 && 's'}</div></React.Fragment>}
                </div>
            </div>
        );

        const percentageFormatter = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const progressFormatter = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const photoBlock: JSX.Element = investment.photos.length > 0 && (
            <img src={investment.photos[0].url} />
        );

        let remainingPercentage: number = (investment.amountTotal - investment.amountRemaining) / investment.amountTotal * 100;
        if (investment.amountRemaining > 0 && remainingPercentage > 99) {
            remainingPercentage = 99;
        }

        return (
            <Link className='investment' to={`/marketplace/investment/${investment.uuid}`}>
                <div className='photo'>
                    {photoBlock}
                </div>
                <div className='investment-progress'>
                    <div className='progress-graph'>
                        <ProgressBar now={remainingPercentage} />
                    </div>
                    <div className='funded'>{progressFormatter.format(remainingPercentage / 100)} Funded</div>
                    <div className='remaining'>{numeral(investment.amountRemaining > 0 ? investment.amountRemaining : 0).format('$0,0[.]00')} Left</div>
                </div>
                <div className='name'>{investment.name}</div>
                <div className='code'>{investment.code}</div>
                <div className='target-return'>
                    <div className='label'>Target Return</div>
                    <div className='value'>{percentageFormatter.format(investment.interestRate / 100)} pa*</div>
                </div>
                <div className='lvr'>
                    <div className='label'>LVR</div>
                    <div className='value'>{percentageFormatter.format(investment.lvr / 100)}</div>
                </div>
                {termBlock}
                <Button>View Investment</Button>
            </Link>
        );
    }
}

export default InvestmentBox;
