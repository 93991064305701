import _ from 'lodash';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AccountApprovalStatusEnum from '~Api/Investor/AccountApprovalStatusEnum';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import {
    completeSignupModalShowSetAction,
    currentInvestorGetAction,
    incomeTrustInvestConfirmModalShowSetAction,
    incomeTrustsListAction,
} from '~Investor/actions';
import {
    currentInvestorAccountSelector,
    currentInvestorSelector,
    incomeTrustsSelector,
} from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import './invest.less';
import InvestModal from './InvestModal';
import RegularIncome from '~UI/RegularIncome';
import IncomeTrustClassTypeEnum from '~Api/Investor/IncomeTrustClassTypeEnum';
import TopUp from '~UI/TopUp';
import IncomeTrustClassTypeLabels from '~Api/Investor/IncomeTrustClassTypeLabels';
import IIncomeTrust from '~Api/Investor/IIncomeTrust';
import { IDictionary } from '~utilities/IDictionary';

const incomeTrustClassTypeIcons: IDictionary<JSX.Element> = {
    [IncomeTrustClassTypeEnum.ClassC]: <TopUp />,
    [IncomeTrustClassTypeEnum.ClassD]: <RegularIncome />,
};

interface IState {
    investModalClassType: IncomeTrustClassTypeEnum;
}

interface IPropsSelector {
    currentInvestor: IInvestor;
    incomeTrustAccount: IAccount;
    incomeTrusts: IDictionary<IIncomeTrust>;
}

interface IPropsDispatch {
    completeSignupModalShow: () => void;
    confirmModalShow: () => void;
    currentInvestorGet: () => void;
    incomeTrustsList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Invest extends React.Component<Props> {
    public state: IState = {
        investModalClassType: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickInvest = this.onClickInvest.bind(this);
    }

    public componentDidMount(): void {
        const { incomeTrusts } = this.props;

        if (!incomeTrusts) {
            this.props.incomeTrustsList();
        }
    }

    public render(): JSX.Element {
        const { currentInvestor, incomeTrustAccount, incomeTrusts } = this.props;
        const { investModalClassType } = this.state;

        if (!currentInvestor) {
            return (
                <Layout section='income-trust-invest'>
                    <h1>Invest</h1>
                    <div className='content-box'>
                        <Spinner animation='border' />
                    </div>
                </Layout>
            );
        }

        if (incomeTrustAccount.approvalStatus !== AccountApprovalStatusEnum.Approved) {
            return (
                <Layout section='income-trust-invest'>
                    <h1>Invest</h1>
                    <div className='content-box pending-approval'>
                        <h3>Account Approval In Progress</h3>
                        <p>Sorry, we're still working on your account application. You cannot invest until your account has been approved.</p>
                        <p>We'll be in touch as soon as we can but please contact us at <a href='mailto:invest@funding.com.au'>invest@funding.com.au</a> if you need any assistance.</p>
                    </div>
                </Layout>
            );
        }

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const incomeTrustInvestmentBoxes: JSX.Element[] = _.map(incomeTrusts, (incomeTrust: IIncomeTrust) => {
            if (![IncomeTrustClassTypeEnum.ClassC, IncomeTrustClassTypeEnum.ClassD].includes(incomeTrust.classType)) {
                return;
            }

            const onClickInvest: () => void = () => this.onClickInvest(incomeTrust.classType);

            return (
                <div className='content-box invest'>
                    {incomeTrustClassTypeIcons[incomeTrust.classType]}
                    <div className='title'>{IncomeTrustClassTypeLabels[incomeTrust.classType]} Minimum Term</div>
                    <div className='target-return-label'>Target Return</div>
                    <div className='target-return-rate'>{percentageFormatter.format(incomeTrust.interestRate)}*</div>
                    <Button disabled={incomeTrustAccount.depositAmountPending > 0} onClick={onClickInvest}>{incomeTrustAccount.depositAmountPending > 0 ? 'Pending Investment' : 'Invest'}</Button>
                </div>
            );
        });

        return (
            <Layout section='income-trust-invest'>
                <h1>Invest</h1>
                <div className='content-boxes'>
                    {incomeTrustInvestmentBoxes}
                </div>
                <InvestModal
                    classType={investModalClassType}
                />
            </Layout>
        );
    }

    private onClickInvest(classType: IncomeTrustClassTypeEnum): void {
        const { incomeTrustAccount } = this.props;

        if (!incomeTrustAccount.isCompleted) {
            this.props.completeSignupModalShow();
        } else {
            this.setState({
                investModalClassType: classType,
            });
            this.props.confirmModalShow();
        }
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        incomeTrustAccount: currentInvestorAccountSelector(state, AccountTypeEnum.IncomeTrust),
        incomeTrusts: incomeTrustsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        completeSignupModalShow: () => dispatch(completeSignupModalShowSetAction(true)),
        confirmModalShow: () => dispatch(incomeTrustInvestConfirmModalShowSetAction(true)),
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
        incomeTrustsList: () => dispatch(incomeTrustsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Invest);
