import _ from 'lodash';
import { AnyAction } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IApplicationBorrower from '~Api/Application/IApplicationBorrower';
import IApplicationBorrowerErrors from '~Api/Application/IApplicationBorrowerErrors';
import IApplicationErrors from '~Api/Application/IApplicationErrors';
import IDeal from '~Api/Deal/IDeal';
import IDealProperty, { IDealPropertyErrors } from '~Api/Deal/IDealProperty';
import { IDictionary } from '~utilities/IDictionary';
import {
    IApplicationCompleteAction,
    IApplicationErrorSetAction,
    IApplicationSavingSetAction,
    IApplicationSetAction,
    IApplicationsSetAction,
    IApplicationValueSetAction,
    IBorrowerDeleteAction,
    IBorrowerErrorSetAction,
    IBorrowersAddAction,
    IBorrowerSetAction,
    IBorrowerValueSetAction,
    IDealSetAction,
    IPropertiesAddAction,
    IPropertyDeleteAction,
    IPropertyErrorSetAction,
    IPropertySetAction,
    IPropertyValueSetAction,
    ITokenSetAction,
} from './actions';
import ApplicationActionsEnum from './ActionsEnum';

export interface IApplicationState {
    application: IApplication;
    applicationBorrowerUuids: IDictionary<string[]>;
    applicationBorrowers: IDictionary<IApplicationBorrower>;
    applications: IDictionary<IApplication>;
    applicationsListed: boolean;
    borrowerErrors: IDictionary<IApplicationBorrowerErrors>;
    borrowers: IDictionary<IApplicationBorrower>;
    deal: IDeal;
    dealApplicationUuids: IDictionary<string[]>;
    errors: IApplicationErrors;
    properties: IDictionary<IDealProperty>;
    propertyErrors: IDictionary<IDealPropertyErrors>;
    saving: boolean;
    token: any;
}

const initialData: IApplicationState = {
    application: null, // Legacy application
    applicationBorrowerUuids: {},
    applicationBorrowers: {},
    applications: {},
    applicationsListed: false,
    borrowerErrors: {},
    borrowers: {}, // Legacy borrowers
    deal: null,
    dealApplicationUuids: {},
    errors: {},
    properties: {}, // Legacy properties
    propertyErrors: {},
    saving: false,
    token: null,
};

function dehydrateApplication(application: IApplication): IApplication {
    return _.omit(application, ['deal', 'borrowers']);
}

export function applicationReducer(state: IApplicationState = initialData, action: AnyAction): IApplicationState {
    switch (action.type) {
        case ApplicationActionsEnum.ApplicationComplete: {
            const typedAction: IApplicationCompleteAction = action as IApplicationCompleteAction;

            return {
                ...state,
            };
        }

        case ApplicationActionsEnum.ApplicationErrorSet: {
            const typedAction: IApplicationErrorSetAction = action as IApplicationErrorSetAction;

            return {
                ...state,
                errors: {
                    ...state.errors,
                    [typedAction.field]: typedAction.value,
                },
            };
        }

        case ApplicationActionsEnum.ApplicationSavingSet: {
            const typedAction: IApplicationSavingSetAction = action as IApplicationSavingSetAction;

            return {
                ...state,
                saving: typedAction.saving,
            };
        }

        case ApplicationActionsEnum.ApplicationSet: {
            const typedAction: IApplicationSetAction = action as IApplicationSetAction;

            const borrowers: { [borrowerId: string]: IApplicationBorrower } = {};
            typedAction.application.borrowers.forEach((borrower: IApplicationBorrower) => {
                borrowers[borrower.uuid] = borrower;
            });

            return {
                ...state,
                application: dehydrateApplication(typedAction.application),
                borrowers,
            };
        }

        case ApplicationActionsEnum.ApplicationValueSet: {
            const typedAction: IApplicationValueSetAction = action as IApplicationValueSetAction;

            return {
                ...state,
                application: {
                    ...state.application,
                    [typedAction.field]: typedAction.value,
                },
            };
        }

        case ApplicationActionsEnum.ApplicationsSet: {
            const typedAction: IApplicationsSetAction = action as IApplicationsSetAction;

            const applications: IDictionary<IApplication> = {};
            const dealApplicationUuids: IDictionary<string[]> = {};

            typedAction.applications.forEach((application: IApplication) => {
                if (!dealApplicationUuids[application.dealUuid]) {
                    dealApplicationUuids[application.dealUuid] = [];
                }

                dealApplicationUuids[application.dealUuid].push(application.uuid);
                applications[application.uuid] = dehydrateApplication(application);
            });

            return {
                ...state,
                applications,
                applicationsListed: true,
            };
        }

        case ApplicationActionsEnum.BorrowersAdd: {
            const typedAction: IBorrowersAddAction = action as IBorrowersAddAction;

            return {
                ...state,
            };
        }

        case ApplicationActionsEnum.BorrowerDelete: {
            const typedAction: IBorrowerDeleteAction = action as IBorrowerDeleteAction;

            return {
                ...state,
                borrowers: _.omit(state.borrowers, [typedAction.uuid]),
            };
        }

        case ApplicationActionsEnum.BorrowerErrorSet: {
            const typedAction: IBorrowerErrorSetAction = action as IBorrowerErrorSetAction;

            return {
                ...state,
                borrowerErrors: {
                    ...state.borrowerErrors,
                    [typedAction.uuid]: {
                        ...state.borrowerErrors[typedAction.uuid],
                        [typedAction.field]: typedAction.value,
                    },
                },
            };
        }

        case ApplicationActionsEnum.BorrowerSet: {
            const typedAction: IBorrowerSetAction = action as IBorrowerSetAction;

            return {
                ...state,
                borrowers: {
                    ...state.borrowers,
                    [typedAction.borrower.uuid]: typedAction.borrower,
                },
            };
        }

        case ApplicationActionsEnum.BorrowerValueSet: {
            const typedAction: IBorrowerValueSetAction = action as IBorrowerValueSetAction;

            return {
                ...state,
                borrowers: {
                    ...state.borrowers,
                    [typedAction.uuid]: {
                        ...state.borrowers[typedAction.uuid],
                        [typedAction.field]: typedAction.value,
                    },
                },
            };
        }

        case ApplicationActionsEnum.DealSet: {
            const typedAction: IDealSetAction = action as IDealSetAction;

            const properties: IDictionary<IDealProperty> = {};
            typedAction.deal.properties.forEach((property: IDealProperty) => {
                properties[property.uuid] = property;
            });

            return {
                ...state,
                deal: _.omit(typedAction.deal, ['properties']),
                properties,
            };
        }

        case ApplicationActionsEnum.PropertiesAdd: {
            const typedAction: IPropertiesAddAction = action as IPropertiesAddAction;

            return {
                ...state,
            };
        }

        case ApplicationActionsEnum.PropertyDelete: {
            const typedAction: IPropertyDeleteAction = action as IPropertyDeleteAction;

            return {
                ...state,
                properties: _.omit(state.properties, [typedAction.uuid]),
            };
        }

        case ApplicationActionsEnum.PropertyErrorSet: {
            const typedAction: IPropertyErrorSetAction = action as IPropertyErrorSetAction;

            return {
                ...state,
                propertyErrors: {
                    ...state.propertyErrors,
                    [typedAction.uuid]: {
                        ...state.propertyErrors[typedAction.uuid],
                        [typedAction.field]: typedAction.value,
                    },
                },
            };
        }

        case ApplicationActionsEnum.PropertySet: {
            const typedAction: IPropertySetAction = action as IPropertySetAction;

            return {
                ...state,
                properties: {
                    ...state.properties,
                    [typedAction.property.uuid]: typedAction.property,
                },
            };
        }

        case ApplicationActionsEnum.PropertyValueSet: {
            const typedAction: IPropertyValueSetAction = action as IPropertyValueSetAction;

            return {
                ...state,
                properties: {
                    ...state.properties,
                    [typedAction.uuid]: {
                        ...state.properties[typedAction.uuid],
                        [typedAction.field]: typedAction.value,
                    },
                },
            };
        }

        case ApplicationActionsEnum.TokenSet: {
            const typedAction: ITokenSetAction = action as ITokenSetAction;

            return {
                ...state,
                token: typedAction.token,
            };
        }

        default:
            return state;
    }
}
