import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import StateEnum from '~Api/Broker/StateEnum';
import AvailableFundsTypeEnum from '~Api/Investor/AvailableFundsTypeEnum';
import ICompany from '~Api/Investor/ICompany';
import IdProofTypeEnum from '~Api/Investor/IdProofTypeEnum';
import IIndividual from '~Api/Investor/IIndividual';
import IInvestor from '~Api/Investor/IInvestor';
import InvestorTypeEnum from '~Api/Investor/InvestorTypeEnum';
import ITrust from '~Api/Investor/ITrust';
import ResidentTypeEnum from '~Api/Investor/ResidentTypeEnum';
import { authRegisterErrorSetAction } from '~Auth/actions';
import { authRegisterErrorsSelector } from '~Auth/selectors';
import history from '~history';
import ICountry from '~Public/ICountry';
import { IGlobalState } from '~reducer';
import EmojiHand from '~UI/EmojiHand';
import Radio from '~UI/Radio';
import { validateTaxFileNumber } from '~utilities/validators';
import {
    publicCountriesGetAction,
    publicCurrentInvestorCompanyValueSetAction,
    publicCurrentInvestorGetAction,
    publicCurrentInvestorIndividualValueSetAction,
    publicCurrentInvestorTrustValueSetAction,
    publicCurrentInvestorValueSetAction,
} from '~Public/actions';
import {
    publicCountriesSelector,
    publicCurrentInvestorSelector,
} from '~Public/selectors';
import Layout from './Layout';
import Steps from './Steps';

interface IPropsSelector {
    countries: ICountry[];
    currentInvestor: IInvestor;
    registerErrors: any;
}

interface IPropsDispatch {
    countriesGet: () => void;
    currentInvestorCompanyValueSet: (key: keyof ICompany, value: any) => void;
    currentInvestorGet: () => void;
    currentInvestorIndividualValueSet: (index: number, key: keyof IIndividual, value: any) => void;
    currentInvestorTrustValueSet: (key: keyof ITrust, value: any) => void;
    currentInvestorValueSet: (key: keyof IInvestor, value: any) => void;
    setError: (key: string, value: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Details extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.getCompany = this.getCompany.bind(this);
        this.getIndividualOne = this.getIndividualOne.bind(this);
        this.getIndividualTwo = this.getIndividualTwo.bind(this);
        this.getTrust = this.getTrust.bind(this);

        this.onClickContinue = this.onClickContinue.bind(this);

        this.onChangeAvailableFundsType = this.onChangeAvailableFundsType.bind(this);

        this.onChangeCompanyAustralianCompanyNumber = this.onChangeCompanyAustralianCompanyNumber.bind(this);
        this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
        this.onChangeCompanyPostcode = this.onChangeCompanyPostcode.bind(this);
        this.onChangeCompanyState = this.onChangeCompanyState.bind(this);
        this.onChangeCompanyStreetAddress = this.onChangeCompanyStreetAddress.bind(this);
        this.onChangeCompanySuburb = this.onChangeCompanySuburb.bind(this);
        this.onChangeCompanyTaxFileNumber = this.onChangeCompanyTaxFileNumber.bind(this);

        this.onChangeIndividualOneCountry = this.onChangeIndividualOneCountry.bind(this);
        this.onChangeIndividualOneDateOfBirth = this.onChangeIndividualOneDateOfBirth.bind(this);
        this.onChangeIndividualOneFirstName = this.onChangeIndividualOneFirstName.bind(this);
        this.onChangeIndividualOneIdProofExpiryDate = this.onChangeIndividualOneIdProofExpiryDate.bind(this);
        this.onChangeIndividualOneIdProofNumber = this.onChangeIndividualOneIdProofNumber.bind(this);
        this.onChangeIndividualOneDriverLicenceCardNumber = this.onChangeIndividualOneDriverLicenceCardNumber.bind(this);
        this.onChangeIndividualOneDriverLicenceState = this.onChangeIndividualOneDriverLicenceState.bind(this);
        this.onChangeIndividualOneIdProofType = this.onChangeIndividualOneIdProofType.bind(this);
        this.onChangeIndividualOneLastName = this.onChangeIndividualOneLastName.bind(this);
        this.onChangeIndividualOneNonResident = this.onChangeIndividualOneNonResident.bind(this);
        this.onChangeIndividualOnePostcode = this.onChangeIndividualOnePostcode.bind(this);
        this.onChangeIndividualOneResident = this.onChangeIndividualOneResident.bind(this);
        this.onChangeIndividualOneState = this.onChangeIndividualOneState.bind(this);
        this.onChangeIndividualOneStreetAddress = this.onChangeIndividualOneStreetAddress.bind(this);
        this.onChangeIndividualOneSuburb = this.onChangeIndividualOneSuburb.bind(this);
        this.onChangeIndividualOneTaxFileNumber = this.onChangeIndividualOneTaxFileNumber.bind(this);

        this.onChangeIndividualTwoCountry = this.onChangeIndividualTwoCountry.bind(this);
        this.onChangeIndividualTwoDateOfBirth = this.onChangeIndividualTwoDateOfBirth.bind(this);
        this.onChangeIndividualTwoFirstName = this.onChangeIndividualTwoFirstName.bind(this);
        this.onChangeIndividualTwoIdProofExpiryDate = this.onChangeIndividualTwoIdProofExpiryDate.bind(this);
        this.onChangeIndividualTwoIdProofNumber = this.onChangeIndividualTwoIdProofNumber.bind(this);
        this.onChangeIndividualTwoDriverLicenceCardNumber = this.onChangeIndividualTwoDriverLicenceCardNumber.bind(this);
        this.onChangeIndividualTwoDriverLicenceState = this.onChangeIndividualTwoDriverLicenceState.bind(this);
        this.onChangeIndividualTwoIdProofType = this.onChangeIndividualTwoIdProofType.bind(this);
        this.onChangeIndividualTwoLastName = this.onChangeIndividualTwoLastName.bind(this);
        this.onChangeIndividualTwoNonResident = this.onChangeIndividualTwoNonResident.bind(this);
        this.onChangeIndividualTwoPostcode = this.onChangeIndividualTwoPostcode.bind(this);
        this.onChangeIndividualTwoResident = this.onChangeIndividualTwoResident.bind(this);
        this.onChangeIndividualTwoState = this.onChangeIndividualTwoState.bind(this);
        this.onChangeIndividualTwoStreetAddress = this.onChangeIndividualTwoStreetAddress.bind(this);
        this.onChangeIndividualTwoSuburb = this.onChangeIndividualTwoSuburb.bind(this);
        this.onChangeIndividualTwoTaxFileNumber = this.onChangeIndividualTwoTaxFileNumber.bind(this);

        this.onChangeInvestorType = this.onChangeInvestorType.bind(this);

        this.onChangeTrustAustralianBusinessNumber = this.onChangeTrustAustralianBusinessNumber.bind(this);
        this.onChangeTrustName = this.onChangeTrustName.bind(this);
        this.onChangeTrustTaxFileNumber = this.onChangeTrustTaxFileNumber.bind(this);

        this.validateCompanyAustralianCompanyNumber = this.validateCompanyAustralianCompanyNumber.bind(this);
        this.validateCompanyName = this.validateCompanyName.bind(this);
        this.validateCompanyPostcode = this.validateCompanyPostcode.bind(this);
        this.validateCompanyState = this.validateCompanyState.bind(this);
        this.validateCompanyStreetAddress = this.validateCompanyStreetAddress.bind(this);
        this.validateCompanySuburb = this.validateCompanySuburb.bind(this);
        this.validateCompanyTaxFileNumber = this.validateCompanyTaxFileNumber.bind(this);
        this.validateIndividualOneDateOfBirth = this.validateIndividualOneDateOfBirth.bind(this);
        this.validateIndividualOneFirstName = this.validateIndividualOneFirstName.bind(this);
        this.validateIndividualOneIdProofExpiryDate = this.validateIndividualOneIdProofExpiryDate.bind(this);
        this.validateIndividualOneIdProofNumber = this.validateIndividualOneIdProofNumber.bind(this);
        this.validateIndividualOneDriverLicenceCardNumber = this.validateIndividualOneDriverLicenceCardNumber.bind(this);
        this.validateIndividualOneDriverLicenceState = this.validateIndividualOneDriverLicenceState.bind(this);
        this.validateIndividualOneIdProofType = this.validateIndividualOneIdProofType.bind(this);
        this.validateIndividualOneLastName = this.validateIndividualOneLastName.bind(this);
        this.validateIndividualOnePostcode = this.validateIndividualOnePostcode.bind(this);
        this.validateIndividualOneState = this.validateIndividualOneState.bind(this);
        this.validateIndividualOneStreetAddress = this.validateIndividualOneStreetAddress.bind(this);
        this.validateIndividualOneSuburb = this.validateIndividualOneSuburb.bind(this);
        this.validateIndividualOneTaxFileNumber = this.validateIndividualOneTaxFileNumber.bind(this);
        this.validateIndividualTwoDateOfBirth = this.validateIndividualTwoDateOfBirth.bind(this);
        this.validateIndividualTwoFirstName = this.validateIndividualTwoFirstName.bind(this);
        this.validateIndividualTwoIdProofExpiryDate = this.validateIndividualTwoIdProofExpiryDate.bind(this);
        this.validateIndividualTwoIdProofNumber = this.validateIndividualTwoIdProofNumber.bind(this);
        this.validateIndividualTwoDriverLicenceCardNumber = this.validateIndividualTwoDriverLicenceCardNumber.bind(this);
        this.validateIndividualTwoDriverLicenceState = this.validateIndividualTwoDriverLicenceState.bind(this);
        this.validateIndividualTwoIdProofType = this.validateIndividualTwoIdProofType.bind(this);
        this.validateIndividualTwoLastName = this.validateIndividualTwoLastName.bind(this);
        this.validateIndividualTwoPostcode = this.validateIndividualTwoPostcode.bind(this);
        this.validateIndividualTwoState = this.validateIndividualTwoState.bind(this);
        this.validateIndividualTwoStreetAddress = this.validateIndividualTwoStreetAddress.bind(this);
        this.validateIndividualTwoSuburb = this.validateIndividualTwoSuburb.bind(this);
        this.validateIndividualTwoTaxFileNumber = this.validateIndividualTwoTaxFileNumber.bind(this);
        this.validateTrustAustralianBusinessNumber = this.validateTrustAustralianBusinessNumber.bind(this);
        this.validateTrustName = this.validateTrustName.bind(this);
        this.validateTrustTaxFileNumber = this.validateTrustTaxFileNumber.bind(this);
    }

    public componentDidMount() {
        const { countries, currentInvestor } = this.props;

        if (!countries) {
            this.props.countriesGet();
        }

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }
    }

    public render(): JSX.Element {
        const { countries, currentInvestor, registerErrors } = this.props;

        if (!countries || !currentInvestor) {
            return (
                <Layout>
                    <Steps currentStep={2} />
                    <Container className='register-details'>
                        <Spinner animation='border' />
                    </Container>
                </Layout>
            );
        }

        const company: ICompany = this.getCompany();
        const individualOne: IIndividual = this.getIndividualOne();
        const individualTwo: IIndividual = this.getIndividualTwo();
        const trust: ITrust = this.getTrust();

        const individualName = [InvestorTypeEnum.Company, InvestorTypeEnum.TrustCompany].includes(currentInvestor.type) ? 'Director' : 'Individual';

        let individualOneIdProofBlock: JSX.Element = null;

        switch (individualOne.idProofType) {
            case IdProofTypeEnum.DriverLicence:
                individualOneIdProofBlock = (
                    <React.Fragment>
                        <Form.Group className='id-proof-number'>
                            <Form.Label>Licence Number</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.individualOneIdProofNumber} onBlur={this.validateIndividualOneIdProofNumber} onChange={this.onChangeIndividualOneIdProofNumber} type='text' value={individualOne.idProofNumber || ''} />
                            {registerErrors.individualOneIdProofNumber && <Form.Control.Feedback type='invalid'>{registerErrors.individualOneIdProofNumber}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='driver-licence-card-number'>
                            <Form.Label>License Card Number</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.individualOneDriverLicenceCardNumber} onBlur={this.validateIndividualOneDriverLicenceCardNumber} onChange={this.onChangeIndividualOneDriverLicenceCardNumber} type='text' value={individualOne.driverLicenceCardNumber || ''} />
                            {registerErrors.individualOneDriverLicenceCardNumber && <Form.Control.Feedback type='invalid'>{registerErrors.individualOneDriverLicenceCardNumber}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='id-proof-expiry-date'>
                            <Form.Label>Expiry Date</Form.Label>
                            <DateTime
                                className={!!registerErrors.individualOneIdProofExpiryDate && 'is-invalid'}
                                dateFormat='DD/MM/YYYY'
                                inputProps={{ placeholder: 'dd/mm/yyyy' }}
                                onBlur={this.validateIndividualOneIdProofExpiryDate}
                                onChange={this.onChangeIndividualOneIdProofExpiryDate}
                                timeFormat={false}
                                value={individualOne.idProofExpiryDate ? moment(individualOne.idProofExpiryDate) : ''}
                            />
                            {registerErrors.individualOneIdProofExpiryDate && <Form.Control.Feedback type='invalid'>{registerErrors.individualOneIdProofExpiryDate}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='driver-licence-state'>
                            <Form.Label>State</Form.Label>
                            <Form.Control as='select' className='custom-select' isInvalid={!!registerErrors.individualOneDriverLicenceState} onChange={this.onChangeIndividualOneDriverLicenceState} value={individualOne.driverLicenceState || ''}>
                                {individualOne.driverLicenceState === null && <option/>}
                                <option value={StateEnum.AustralianCapitalTerritory}>ACT</option>
                                <option value={StateEnum.NewSouthWales}>NSW</option>
                                <option value={StateEnum.NorthernTerritory}>NT</option>
                                <option value={StateEnum.Queensland}>QLD</option>
                                <option value={StateEnum.SouthAustralia}>SA</option>
                                <option value={StateEnum.Tasmania}>TAS</option>
                                <option value={StateEnum.Victoria}>VIC</option>
                                <option value={StateEnum.WesternAustralia}>WA</option>
                            </Form.Control>
                            {registerErrors.individualOneDriverLicenceState && <Form.Control.Feedback type='invalid'>{registerErrors.individualOneDriverLicenceState}</Form.Control.Feedback>}
                        </Form.Group>
                    </React.Fragment>
                );

                break;
            case IdProofTypeEnum.Passport:
                individualOneIdProofBlock = (
                    <React.Fragment>
                        <Form.Group className='id-proof-number'>
                            <Form.Label>Passport Number</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.individualOneIdProofNumber} onBlur={this.validateIndividualOneIdProofNumber} onChange={this.onChangeIndividualOneIdProofNumber} type='text' value={individualOne.idProofNumber || ''} />
                            {registerErrors.individualOneIdProofNumber && <Form.Control.Feedback type='invalid'>{registerErrors.individualOneIdProofNumber}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='id-proof-expiry-date'>
                            <Form.Label>Expiry Date</Form.Label>
                            <DateTime
                                className={!!registerErrors.individualOneIdProofExpiryDate && 'is-invalid'}
                                dateFormat='DD/MM/YYYY'
                                inputProps={{ placeholder: 'dd/mm/yyyy' }}
                                onBlur={this.validateIndividualOneIdProofExpiryDate}
                                onChange={this.onChangeIndividualOneIdProofExpiryDate}
                                timeFormat={false}
                                value={individualOne.idProofExpiryDate ? moment(individualOne.idProofExpiryDate) : ''}
                            />
                            {registerErrors.individualOneIdProofExpiryDate && <Form.Control.Feedback type='invalid'>{registerErrors.individualOneIdProofExpiryDate}</Form.Control.Feedback>}
                        </Form.Group>
                    </React.Fragment>
                );

                break;
        }

        const trustBlock = [InvestorTypeEnum.TrustIndividual, InvestorTypeEnum.TrustCompany].includes(currentInvestor.type) && (
            <React.Fragment>
                <h4 className='trust'>Trust</h4>
                <Form.Group className='trust-name'>
                    <Form.Label>Name of Trust</Form.Label>
                    <Form.Control onBlur={this.validateTrustName} isInvalid={!!registerErrors.trustName} onChange={this.onChangeTrustName} type='text' value={trust.name || ''} />
                    {registerErrors.trustName && <Form.Control.Feedback type='invalid'>{registerErrors.trustName}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='trust-tax-file-number'>
                    <Form.Label>Tax File Number</Form.Label>
                    <Form.Control onBlur={this.validateTrustTaxFileNumber} isInvalid={!!registerErrors.trustTaxFileNumber} onChange={this.onChangeTrustTaxFileNumber} type='text' value={trust.taxFileNumber || ''} />
                    {registerErrors.trustTaxFileNumber && <Form.Control.Feedback type='invalid'>{registerErrors.trustTaxFileNumber}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='australian-business-number'>
                    <Form.Label>Australian Business Number</Form.Label>
                    <Form.Control onBlur={this.validateTrustAustralianBusinessNumber} isInvalid={!!registerErrors.trustAustralianBusinessNumber} onChange={this.onChangeTrustAustralianBusinessNumber} type='text' value={trust.australianBusinessNumber || ''} />
                    {registerErrors.trustAustralianBusinessNumber && <Form.Control.Feedback type='invalid'>{registerErrors.trustAustralianBusinessNumber}</Form.Control.Feedback>}
                </Form.Group>
            </React.Fragment>
        );

        const companyBlock = [InvestorTypeEnum.Company, InvestorTypeEnum.TrustCompany].includes(currentInvestor.type) && (
            <React.Fragment>
                <h4 className='company'>Company</h4>
                <Form.Group className='company-name'>
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control onBlur={this.validateCompanyName} isInvalid={!!registerErrors.companyName} onChange={this.onChangeCompanyName} type='text' value={company.name || ''} />
                    {registerErrors.companyName && <Form.Control.Feedback type='invalid'>{registerErrors.companyName}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='company-tax-file-number'>
                    <Form.Label>Tax File Number</Form.Label>
                    <Form.Control onBlur={this.validateCompanyTaxFileNumber} isInvalid={!!registerErrors.companyTaxFileNumber} onChange={this.onChangeCompanyTaxFileNumber} type='text' value={company.taxFileNumber || ''} />
                    {registerErrors.companyTaxFileNumber && <Form.Control.Feedback type='invalid'>{registerErrors.companyTaxFileNumber}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='australian-company-number'>
                    <Form.Label>Australian Company Number</Form.Label>
                    <Form.Control onBlur={this.validateCompanyAustralianCompanyNumber} isInvalid={!!registerErrors.companyAustralianCompanyNumber} onChange={this.onChangeCompanyAustralianCompanyNumber} type='text' value={company.australianCompanyNumber || ''} />
                    {registerErrors.companyAustralianCompanyNumber && <Form.Control.Feedback type='invalid'>{registerErrors.companyAustralianCompanyNumber}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='street-address'>
                    <Form.Label>Street Address</Form.Label>
                    <Form.Control onBlur={this.validateCompanyStreetAddress} isInvalid={!!registerErrors.companyStreetAddress} onChange={this.onChangeCompanyStreetAddress} type='text' value={company.streetAddress || ''} />
                    {registerErrors.companyStreetAddress && <Form.Control.Feedback type='invalid'>{registerErrors.companyStreetAddress}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='suburb'>
                    <Form.Label>Suburb</Form.Label>
                    <Form.Control onBlur={this.validateCompanySuburb} isInvalid={!!registerErrors.companySuburb} onChange={this.onChangeCompanySuburb} type='text' value={company.suburb || ''} />
                    {registerErrors.companySuburb && <Form.Control.Feedback type='invalid'>{registerErrors.companySuburb}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='postcode'>
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control onBlur={this.validateCompanyPostcode} isInvalid={!!registerErrors.companyPostcode} onChange={this.onChangeCompanyPostcode} type='text' value={company.postcode || ''} />
                    {registerErrors.companyPostcode && <Form.Control.Feedback type='invalid'>{registerErrors.companyPostcode}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='state'>
                    <Form.Label>State</Form.Label>
                    <Form.Control onBlur={this.validateCompanyState} isInvalid={!!registerErrors.companyState} onChange={this.onChangeCompanyState} type='text' value={company.state || ''} />
                    {registerErrors.companyState && <Form.Control.Feedback type='invalid'>{registerErrors.companyState}</Form.Control.Feedback>}
                </Form.Group>
            </React.Fragment>
        );

        const individualOneBlock: JSX.Element = currentInvestor.type && (
            <React.Fragment>
                <h4 className='individual-1'>{individualName} 1</h4>
                <Form.Group className='first-name'>
                    <Form.Label>Given Name</Form.Label>
                    <Form.Control isInvalid={!!registerErrors.individualOneFirstName} onBlur={this.validateIndividualOneFirstName} onChange={this.onChangeIndividualOneFirstName} type='text' value={individualOne.firstName || ''} />
                    {registerErrors.individualOneFirstName && <Form.Control.Feedback type='invalid'>{registerErrors.individualOneFirstName}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='last-name'>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control isInvalid={!!registerErrors.individualOneLastName} onBlur={this.validateIndividualOneLastName} onChange={this.onChangeIndividualOneLastName} type='text' value={individualOne.lastName || ''} />
                    {registerErrors.individualOneLastName && <Form.Control.Feedback type='invalid'>{registerErrors.individualOneLastName}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='date-of-birth'>
                    <Form.Label>Date of Birth</Form.Label>
                    <DateTime
                        className={!!registerErrors.individualOneDateOfBirth && 'is-invalid'}
                        dateFormat='DD/MM/YYYY'
                        inputProps={{ placeholder: 'dd/mm/yyyy' }}
                        onBlur={this.validateIndividualOneDateOfBirth}
                        onChange={this.onChangeIndividualOneDateOfBirth}
                        timeFormat={false}
                        value={individualOne.dateOfBirth ? moment(individualOne.dateOfBirth) : ''}
                    />
                    {registerErrors.individualOneDateOfBirth && <Form.Control.Feedback type='invalid'>{registerErrors.individualOneDateOfBirth}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='tax-file-number'>
                    <Form.Label>Tax File Number</Form.Label>
                    <Form.Control isInvalid={!!registerErrors.individualOneTaxFileNumber} onBlur={this.validateIndividualOneTaxFileNumber} onChange={this.onChangeIndividualOneTaxFileNumber} type='text' value={individualOne.taxFileNumber || ''} />
                    {registerErrors.individualOneTaxFileNumber && <Form.Control.Feedback type='invalid'>{registerErrors.individualOneTaxFileNumber}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='street-address'>
                    <Form.Label>Street Address</Form.Label>
                    <Form.Control isInvalid={!!registerErrors.individualOneStreetAddress} onBlur={this.validateIndividualOneStreetAddress} onChange={this.onChangeIndividualOneStreetAddress} type='text' value={individualOne.streetAddress || ''} />
                    {registerErrors.individualOneStreetAddress && <Form.Control.Feedback type='invalid'>{registerErrors.individualOneStreetAddress}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='suburb'>
                    <Form.Label>Suburb</Form.Label>
                    <Form.Control isInvalid={!!registerErrors.individualOneSuburb} onBlur={this.validateIndividualOneSuburb} onChange={this.onChangeIndividualOneSuburb} type='text' value={individualOne.suburb || ''} />
                    {registerErrors.individualOneSuburb && <Form.Control.Feedback type='invalid'>{registerErrors.individualOneSuburb}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='postcode'>
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control isInvalid={!!registerErrors.individualOnePostcode} onBlur={this.validateIndividualOnePostcode} onChange={this.onChangeIndividualOnePostcode} type='text' value={individualOne.postcode || ''} />
                    {registerErrors.individualOnePostcode && <Form.Control.Feedback type='invalid'>{registerErrors.individualOnePostcode}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='state'>
                    <Form.Label>State</Form.Label>
                    <Form.Control isInvalid={!!registerErrors.individualOneState} onBlur={this.validateIndividualOneState} onChange={this.onChangeIndividualOneState} type='text' value={individualOne.state || ''} />
                    {registerErrors.individualOneState && <Form.Control.Feedback type='invalid'>{registerErrors.individualOneState}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='country'>
                    <Form.Label>Country</Form.Label>
                    <Form.Control as='select' className='custom-select' onChange={this.onChangeIndividualOneCountry} value={individualOne.country || 'AUS'}>
                        {_.sortBy(countries, ['name']).map((country: ICountry) => <option key={country.iso3} value={country.iso3}>{country.name}</option>)}
                    </Form.Control>
                </Form.Group>
                <Form.Group className='resident-type'>
                    <Form.Label>Are You a Foreign or Non-Resident Australian?</Form.Label>
                    <div className='form-check-group'>
                        <Radio checked={individualOne.residentType === ResidentTypeEnum.NonResident} label='Yes' onChange={this.onChangeIndividualOneNonResident} />
                        <Radio checked={individualOne.residentType === ResidentTypeEnum.Resident} label='No' onChange={this.onChangeIndividualOneResident} />
                    </div>
                </Form.Group>
                <Form.Group className='id-proof-type'>
                    <Form.Label>ID Proof</Form.Label>
                    <Form.Control as='select' className='custom-select' isInvalid={!!registerErrors.individualOneIdProofType} onBlur={this.validateIndividualOneIdProofType} onChange={this.onChangeIndividualOneIdProofType} value={individualOne.idProofType || ''}>
                        <option/>
                        <option value={IdProofTypeEnum.DriverLicence}>Australian Driver Licence</option>
                        <option value={IdProofTypeEnum.Passport}>Passport</option>
                    </Form.Control>
                    {registerErrors.individualOneIdProofType && <Form.Control.Feedback type='invalid'>{registerErrors.individualOneIdProofType}</Form.Control.Feedback>}
                </Form.Group>
                {individualOneIdProofBlock}
            </React.Fragment>
        );

        let individualTwoIdProofBlock: JSX.Element = null;

        switch (individualTwo.idProofType) {
            case IdProofTypeEnum.DriverLicence:
                individualTwoIdProofBlock = (
                    <React.Fragment>
                        <Form.Group className='id-proof-number'>
                            <Form.Label>Licence Number</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.individualTwoIdProofNumber} onBlur={this.validateIndividualTwoIdProofNumber} onChange={this.onChangeIndividualTwoIdProofNumber} type='text' value={individualTwo.idProofNumber || ''} />
                            {registerErrors.individualTwoIdProofNumber && <Form.Control.Feedback type='invalid'>{registerErrors.individualTwoIdProofNumber}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='driver-licence-card-number'>
                            <Form.Label>License Card Number</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.individualTwoDriverLicenceCardNumber} onBlur={this.validateIndividualTwoDriverLicenceCardNumber} onChange={this.onChangeIndividualTwoDriverLicenceCardNumber} type='text' value={individualTwo.driverLicenceCardNumber || ''} />
                            {registerErrors.individualTwoDriverLicenceCardNumber && <Form.Control.Feedback type='invalid'>{registerErrors.individualTwoDriverLicenceCardNumber}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='id-proof-expiry-date'>
                            <Form.Label>Expiry Date</Form.Label>
                            <DateTime
                                className={!!registerErrors.individualTwoIdProofExpiryDate && 'is-invalid'}
                                dateFormat='DD/MM/YYYY'
                                inputProps={{ placeholder: 'dd/mm/yyyy' }}
                                onBlur={this.validateIndividualTwoIdProofExpiryDate}
                                onChange={this.onChangeIndividualTwoIdProofExpiryDate}
                                timeFormat={false}
                                value={individualTwo.idProofExpiryDate ? moment(individualTwo.idProofExpiryDate) : ''}
                            />
                            {registerErrors.individualTwoIdProofExpiryDate && <Form.Control.Feedback type='invalid'>{registerErrors.individualTwoIdProofExpiryDate}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='driver-licence-state'>
                            <Form.Label>State</Form.Label>
                            <Form.Control as='select' className='custom-select' isInvalid={!!registerErrors.individualTwoDriverLicenceState} onChange={this.onChangeIndividualTwoDriverLicenceState} value={individualTwo.driverLicenceState || ''}>
                                {individualTwo.driverLicenceState === null && <option/>}
                                <option value={StateEnum.AustralianCapitalTerritory}>ACT</option>
                                <option value={StateEnum.NewSouthWales}>NSW</option>
                                <option value={StateEnum.NorthernTerritory}>NT</option>
                                <option value={StateEnum.Queensland}>QLD</option>
                                <option value={StateEnum.SouthAustralia}>SA</option>
                                <option value={StateEnum.Tasmania}>TAS</option>
                                <option value={StateEnum.Victoria}>VIC</option>
                                <option value={StateEnum.WesternAustralia}>WA</option>
                            </Form.Control>
                            {registerErrors.individualTwoDriverLicenceState && <Form.Control.Feedback type='invalid'>{registerErrors.individualTwoDriverLicenceState}</Form.Control.Feedback>}
                        </Form.Group>
                    </React.Fragment>
                );

                break;
            case IdProofTypeEnum.Passport:
                individualTwoIdProofBlock = (
                    <React.Fragment>
                        <Form.Group className='id-proof-number'>
                            <Form.Label>Passport Number</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.individualTwoIdProofNumber} onBlur={this.validateIndividualTwoIdProofNumber} onChange={this.onChangeIndividualTwoIdProofNumber} type='text' value={individualTwo.idProofNumber || ''} />
                            {registerErrors.individualTwoIdProofNumber && <Form.Control.Feedback type='invalid'>{registerErrors.individualTwoIdProofNumber}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='id-proof-expiry-date'>
                            <Form.Label>Expiry Date</Form.Label>
                            <DateTime
                                className={!!registerErrors.individualTwoIdProofExpiryDate && 'is-invalid'}
                                dateFormat='DD/MM/YYYY'
                                inputProps={{ placeholder: 'dd/mm/yyyy' }}
                                onBlur={this.validateIndividualTwoIdProofExpiryDate}
                                onChange={this.onChangeIndividualTwoIdProofExpiryDate}
                                timeFormat={false}
                                value={individualTwo.idProofExpiryDate ? moment(individualTwo.idProofExpiryDate) : ''}
                            />
                            {registerErrors.individualTwoIdProofExpiryDate && <Form.Control.Feedback type='invalid'>{registerErrors.individualTwoIdProofExpiryDate}</Form.Control.Feedback>}
                        </Form.Group>
                    </React.Fragment>
                );

                break;
        }

        const individualTwoBlock: JSX.Element = currentInvestor.type && currentInvestor.type !== InvestorTypeEnum.Individual && (
            <React.Fragment>
                <h4 className='individual-2'>{individualName} 2</h4>
                <Form.Group className='first-name'>
                    <Form.Label>Given Name</Form.Label>
                    <Form.Control isInvalid={!!registerErrors.individualTwoFirstName} onChange={this.onChangeIndividualTwoFirstName} type='text' value={individualTwo.firstName || ''} />
                    {registerErrors.individualTwoFirstName && <Form.Control.Feedback type='invalid'>{registerErrors.individualTwoFirstName}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='last-name'>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control isInvalid={!!registerErrors.individualTwoLastName} onChange={this.onChangeIndividualTwoLastName} type='text' value={individualTwo.lastName || ''} />
                    {registerErrors.individualTwoLastName && <Form.Control.Feedback type='invalid'>{registerErrors.individualTwoLastName}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='date-of-birth'>
                    <Form.Label>Date of Birth</Form.Label>
                    <DateTime
                        className={!!registerErrors.individualTwoDateOfBirth && 'is-invalid'}
                        dateFormat='DD/MM/YYYY'
                        inputProps={{ placeholder: 'dd/mm/yyyy' }}
                        onBlur={this.validateIndividualTwoDateOfBirth}
                        onChange={this.onChangeIndividualTwoDateOfBirth}
                        timeFormat={false}
                        value={individualTwo.dateOfBirth ? moment(individualTwo.dateOfBirth) : ''}
                    />
                    {registerErrors.individualTwoDateOfBirth && <Form.Control.Feedback type='invalid'>{registerErrors.individualTwoDateOfBirth}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='tax-file-number'>
                    <Form.Label>Tax File Number</Form.Label>
                    <Form.Control onBlur={this.validateIndividualTwoTaxFileNumber} isInvalid={!!registerErrors.individualTwoTaxFileNumber} onChange={this.onChangeIndividualTwoTaxFileNumber} type='text' value={individualTwo.taxFileNumber || ''} />
                    {registerErrors.individualTwoTaxFileNumber && <Form.Control.Feedback type='invalid'>{registerErrors.individualTwoTaxFileNumber}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='street-address'>
                    <Form.Label>Street Address</Form.Label>
                    <Form.Control isInvalid={!!registerErrors.individualTwoStreetAddress} onChange={this.onChangeIndividualTwoStreetAddress} type='text' value={individualTwo.streetAddress || ''} />
                    {registerErrors.individualTwoStreetAddress && <Form.Control.Feedback type='invalid'>{registerErrors.individualTwoStreetAddress}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='suburb'>
                    <Form.Label>Suburb</Form.Label>
                    <Form.Control isInvalid={!!registerErrors.individualTwoSuburb} onChange={this.onChangeIndividualTwoSuburb} type='text' value={individualTwo.suburb || ''} />
                    {registerErrors.individualTwoSuburb && <Form.Control.Feedback type='invalid'>{registerErrors.individualTwoSuburb}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='postcode'>
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control isInvalid={!!registerErrors.individualTwoPostcode} onChange={this.onChangeIndividualTwoPostcode} type='text' value={individualTwo.postcode || ''} />
                    {registerErrors.individualTwoPostcode && <Form.Control.Feedback type='invalid'>{registerErrors.individualTwoPostcode}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='state'>
                    <Form.Label>State</Form.Label>
                    <Form.Control isInvalid={!!registerErrors.individualTwoState} onChange={this.onChangeIndividualTwoState} type='text' value={individualTwo.state || ''} />
                    {registerErrors.individualTwoState && <Form.Control.Feedback type='invalid'>{registerErrors.individualTwoState}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='country'>
                    <Form.Label>Country</Form.Label>
                    <Form.Control as='select' className='custom-select' onChange={this.onChangeIndividualTwoCountry} value={individualTwo.country || 'AUS'}>
                        {_.sortBy(countries, ['name']).map((country: ICountry) => <option key={country.iso3} value={country.iso3}>{country.name}</option>)}
                    </Form.Control>
                </Form.Group>
                <Form.Group className='resident-type'>
                    <Form.Label>Are You a Foreign or Non-Resident Australian?</Form.Label>
                    <div className='form-check-group'>
                        <Radio checked={individualTwo.residentType === ResidentTypeEnum.NonResident} label='Yes' onChange={this.onChangeIndividualTwoNonResident} />
                        <Radio checked={individualTwo.residentType === ResidentTypeEnum.Resident} label='No' onChange={this.onChangeIndividualTwoResident} />
                    </div>
                </Form.Group>
                <Form.Group className='id-proof-type'>
                    <Form.Label>ID Proof</Form.Label>
                    <Form.Control as='select' className='custom-select' isInvalid={!!registerErrors.individualTwoIdProofType} onChange={this.onChangeIndividualTwoIdProofType} value={individualTwo.idProofType || ''}>
                        <option/>
                        <option value={IdProofTypeEnum.DriverLicence}>Australian Driver Licence</option>
                        <option value={IdProofTypeEnum.Passport}>Passport</option>
                    </Form.Control>
                    {registerErrors.individualTwoIdProofType && <Form.Control.Feedback type='invalid'>{registerErrors.individualTwoIdProofType}</Form.Control.Feedback>}
                </Form.Group>
                {individualTwoIdProofBlock}
            </React.Fragment>
        );

        return (
            <Layout>
                <Steps currentStep={2} />

                <Container className='register-details'>
                    <Link to='/'><button className='try'>Try it first <EmojiHand className='hand' /></button></Link>
                    <h3>Investor Details</h3>
                    <p className='intro'>Tell us a little bit more information.</p>
                    <Form>
                        <Form.Group className='available-funds'>
                            <Form.Label>Available Funds</Form.Label>
                            <Form.Control as='select' className='custom-select' onChange={this.onChangeAvailableFundsType} value={currentInvestor.availableFundsType || ''}>
                                <option value={AvailableFundsTypeEnum.UnderTenThousand}>Under $10,000</option>
                                <option value={AvailableFundsTypeEnum.TenToOneHundredThousand}>Between $10,000 and $100,000</option>
                                <option value={AvailableFundsTypeEnum.OneHundredToTwoHundredFiftyThousand}>Between $100,000 and $250,000</option>
                                <option value={AvailableFundsTypeEnum.TwoHundredFiftyToFiveHundredThousand}>Between $250,000 and $500,000</option>
                                <option value={AvailableFundsTypeEnum.FiveHundredThousandToOneMillion}>Between $500,000 and $1m</option>
                                <option value={AvailableFundsTypeEnum.OverOneMillion}>$1m +</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className='investor-type'>
                            <Form.Label>Type of Investor</Form.Label>
                            <Form.Control as='select' className='custom-select' onChange={this.onChangeInvestorType} value={currentInvestor.type || ''}>
                                <option value={InvestorTypeEnum.Individual}>Individual</option>
                                <option value={InvestorTypeEnum.JointIndividuals}>Joint – individuals</option>
                                <option value={InvestorTypeEnum.Company}>Company</option>
                                <option value={InvestorTypeEnum.TrustIndividual}>Trust – individual trustee</option>
                                <option value={InvestorTypeEnum.TrustCompany}>Trust – company trustee</option>
                            </Form.Control>
                        </Form.Group>
                        {trustBlock}
                        {companyBlock}
                        {individualOneBlock}
                        {individualTwoBlock}
                        <Button className='continue' onClick={this.onClickContinue} variant='primary'>
                            Continue
                        </Button>
                    </Form>
                </Container>
            </Layout>
        );
    }

    private getCompany(): ICompany {
        const { currentInvestor } = this.props;

        return currentInvestor.company || {
            australianCompanyNumber: null,
            name: null,
            postcode: null,
            state: null,
            streetAddress: null,
            suburb: null,
            taxFileNumber: null,
        };
    }

    private getIndividualOne(): IIndividual {
        const { currentInvestor } = this.props;

        return currentInvestor.individuals && currentInvestor.individuals[0] ? currentInvestor.individuals[0] : {
            country: 'AUS',
            dateOfBirth: null,
            driverLicenceCardNumber: null,
            driverLicenceState: null,
            email: null,
            firstName: null,
            idProofExpiryDate: null,
            idProofNumber: null,
            idProofType: null,
            lastName: null,
            postcode: null,
            residentType: null,
            state: null,
            streetAddress: null,
            suburb: null,
            taxFileNumber: null,
        };
    }

    private getIndividualTwo(): IIndividual {
        const { currentInvestor } = this.props;

        return currentInvestor.individuals && currentInvestor.individuals[1] ? currentInvestor.individuals[1] : {
            country: 'AUS',
            dateOfBirth: null,
            driverLicenceCardNumber: null,
            driverLicenceState: null,
            email: null,
            firstName: null,
            idProofExpiryDate: null,
            idProofNumber: null,
            idProofType: null,
            lastName: null,
            postcode: null,
            residentType: null,
            state: null,
            streetAddress: null,
            suburb: null,
            taxFileNumber: null,
        };
    }

    private getTrust(): ITrust {
        const { currentInvestor } = this.props;

        return currentInvestor.trust || {
            australianBusinessNumber: null,
            name: null,
            taxFileNumber: null,
        };
    }

    private onClickContinue(): void {
        let valid = true;
        valid = this.validateCompanyAustralianCompanyNumber() && valid;
        valid = this.validateCompanyName() && valid;
        valid = this.validateCompanyPostcode() && valid;
        valid = this.validateCompanyState() && valid;
        valid = this.validateCompanyStreetAddress() && valid;
        valid = this.validateCompanySuburb() && valid;
        valid = this.validateCompanyTaxFileNumber() && valid;
        valid = this.validateIndividualOneDateOfBirth() && valid;
        valid = this.validateIndividualOneFirstName() && valid;
        valid = this.validateIndividualOneIdProofExpiryDate() && valid;
        valid = this.validateIndividualOneIdProofNumber() && valid;
        valid = this.validateIndividualOneIdProofType() && valid;
        valid = this.validateIndividualOneLastName() && valid;
        valid = this.validateIndividualOnePostcode() && valid;
        valid = this.validateIndividualOneState() && valid;
        valid = this.validateIndividualOneStreetAddress() && valid;
        valid = this.validateIndividualOneSuburb() && valid;
        valid = this.validateIndividualOneTaxFileNumber() && valid;
        valid = this.validateIndividualTwoDateOfBirth() && valid;
        valid = this.validateIndividualTwoFirstName() && valid;
        valid = this.validateIndividualTwoIdProofExpiryDate() && valid;
        valid = this.validateIndividualTwoIdProofNumber() && valid;
        valid = this.validateIndividualTwoIdProofType() && valid;
        valid = this.validateIndividualTwoLastName() && valid;
        valid = this.validateIndividualTwoPostcode() && valid;
        valid = this.validateIndividualTwoState() && valid;
        valid = this.validateIndividualTwoStreetAddress() && valid;
        valid = this.validateIndividualTwoSuburb() && valid;
        valid = this.validateIndividualTwoTaxFileNumber() && valid;
        valid = this.validateTrustAustralianBusinessNumber() && valid;
        valid = this.validateTrustName() && valid;
        valid = this.validateTrustTaxFileNumber() && valid;

        const individual1: IIndividual = this.getIndividualOne();

        if (individual1.idProofType === IdProofTypeEnum.DriverLicence) {
            valid = this.validateIndividualOneDriverLicenceState() && valid;
            valid = this.validateIndividualOneDriverLicenceCardNumber() && valid;
        }

        const individual2: IIndividual = this.getIndividualTwo();

        if (individual2.idProofType === IdProofTypeEnum.DriverLicence) {
            valid = this.validateIndividualTwoDriverLicenceState() && valid;
            valid = this.validateIndividualTwoDriverLicenceCardNumber() && valid;
        }

        if (!valid) {
            return;
        }

        history.push('/invest/marketplace/bank-account');
    }

    private onChangeAvailableFundsType(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorValueSet('availableFundsType', event.target.value);
    }

    private onChangeCompanyAustralianCompanyNumber(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.props.currentInvestorCompanyValueSet('australianCompanyNumber', event.target.value);
    }

    private onChangeCompanyName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorCompanyValueSet('name', event.target.value);
    }

    private onChangeCompanyPostcode(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.props.currentInvestorCompanyValueSet('postcode', event.target.value);
    }

    private onChangeCompanyState(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorCompanyValueSet('state', event.target.value);
    }

    private onChangeCompanyStreetAddress(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorCompanyValueSet('streetAddress', event.target.value);
    }

    private onChangeCompanySuburb(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorCompanyValueSet('suburb', event.target.value);
    }

    private onChangeCompanyTaxFileNumber(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.props.currentInvestorCompanyValueSet('taxFileNumber', event.target.value);
    }

    private onChangeIndividualOneCountry(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(0, 'country', event.target.value);
    }

    private onChangeIndividualOneDateOfBirth(value: string | moment.Moment) {
        if (typeof value === 'string') {
            this.props.currentInvestorIndividualValueSet(0, 'dateOfBirth', '');

            return;
        }

        this.props.currentInvestorIndividualValueSet(0, 'dateOfBirth', value && (value as moment.Moment).format('YYYY-MM-DD'));
    }

    private onChangeIndividualOneFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(0, 'firstName', event.target.value);
    }

    private onChangeIndividualOneIdProofExpiryDate(value: string | moment.Moment) {
        if (typeof value === 'string') {
            this.props.currentInvestorIndividualValueSet(0, 'idProofExpiryDate', '');

            return;
        }

        this.props.currentInvestorIndividualValueSet(0, 'idProofExpiryDate', value && (value as moment.Moment).format('YYYY-MM-DD'));
    }

    private onChangeIndividualOneIdProofNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(0, 'idProofNumber', event.target.value);
    }

    private onChangeIndividualOneDriverLicenceCardNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.currentInvestorIndividualValueSet(0, 'driverLicenceCardNumber', event.target.value);
    }

    private onChangeIndividualOneDriverLicenceState(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.currentInvestorIndividualValueSet(0, 'driverLicenceState', event.target.value);
    }

    private onChangeIndividualOneIdProofType(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(0, 'idProofType', event.target.value as IdProofTypeEnum);
    }

    private onChangeIndividualOneLastName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(0, 'lastName', event.target.value);
    }

    private onChangeIndividualOneNonResident(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(0, 'residentType', event.target.checked ? ResidentTypeEnum.NonResident : ResidentTypeEnum.Resident);
    }

    private onChangeIndividualOnePostcode(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(0, 'postcode', event.target.value);
    }

    private onChangeIndividualOneResident(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(0, 'residentType', event.target.checked ? ResidentTypeEnum.Resident : ResidentTypeEnum.NonResident);
    }

    private onChangeIndividualOneState(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(0, 'state', event.target.value);
    }

    private onChangeIndividualOneStreetAddress(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(0, 'streetAddress', event.target.value);
    }

    private onChangeIndividualOneSuburb(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(0, 'suburb', event.target.value);
    }

    private onChangeIndividualOneTaxFileNumber(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.props.currentInvestorIndividualValueSet(0, 'taxFileNumber', event.target.value);
    }

    private onChangeIndividualTwoCountry(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(1, 'country', event.target.value);
    }

    private onChangeIndividualTwoDateOfBirth(value: string | moment.Moment) {
        if (typeof value === 'string') {
            this.props.currentInvestorIndividualValueSet(1, 'dateOfBirth', '');

            return;
        }

        this.props.currentInvestorIndividualValueSet(1, 'dateOfBirth', value && (value as moment.Moment).format('YYYY-MM-DD'));
    }

    private onChangeIndividualTwoFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(1, 'firstName', event.target.value);
    }

    private onChangeIndividualTwoIdProofExpiryDate(value: string | moment.Moment) {
        if (typeof value === 'string') {
            this.props.currentInvestorIndividualValueSet(1, 'idProofExpiryDate', '');

            return;
        }

        this.props.currentInvestorIndividualValueSet(1, 'idProofExpiryDate', value && (value as moment.Moment).format('YYYY-MM-DD'));
    }

    private onChangeIndividualTwoIdProofNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(1, 'idProofNumber', event.target.value);
    }

    private onChangeIndividualTwoDriverLicenceCardNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.currentInvestorIndividualValueSet(1, 'driverLicenceCardNumber', event.target.value);
    }

    private onChangeIndividualTwoDriverLicenceState(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.currentInvestorIndividualValueSet(1, 'driverLicenceState', event.target.value);
    }

    private onChangeIndividualTwoIdProofType(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(1, 'idProofType', event.target.value as IdProofTypeEnum);
    }

    private onChangeIndividualTwoLastName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(1, 'lastName', event.target.value);
    }

    private onChangeIndividualTwoNonResident(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(1, 'residentType', event.target.checked ? ResidentTypeEnum.NonResident : ResidentTypeEnum.Resident);
    }

    private onChangeIndividualTwoPostcode(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(1, 'postcode', event.target.value);
    }

    private onChangeIndividualTwoResident(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(1, 'residentType', event.target.checked ? ResidentTypeEnum.Resident : ResidentTypeEnum.NonResident);
    }

    private onChangeIndividualTwoState(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(1, 'state', event.target.value);
    }

    private onChangeIndividualTwoStreetAddress(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(1, 'streetAddress', event.target.value);
    }

    private onChangeIndividualTwoSuburb(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorIndividualValueSet(1, 'suburb', event.target.value);
    }

    private onChangeIndividualTwoTaxFileNumber(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.props.currentInvestorIndividualValueSet(1, 'taxFileNumber', event.target.value);
    }

    private onChangeInvestorType(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorValueSet('type', event.target.value);
    }

    private onChangeTrustAustralianBusinessNumber(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.props.currentInvestorTrustValueSet('australianBusinessNumber', event.target.value);
    }

    private onChangeTrustName(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.currentInvestorTrustValueSet('name', event.target.value);
    }

    private onChangeTrustTaxFileNumber(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.props.currentInvestorTrustValueSet('taxFileNumber', event.target.value);
    }

    private validateCompanyAustralianCompanyNumber(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if ([InvestorTypeEnum.Company, InvestorTypeEnum.TrustCompany].includes(currentInvestor.type)) {
            const company: ICompany = this.getCompany();

            if (!company.australianCompanyNumber || company.australianCompanyNumber.length === 0) {
                error = 'Please enter the Australian company number';
            } else if (company.australianCompanyNumber.length !== 9) {
                error = 'Please enter exactly 9 numbers';
            } else {
                const sum1 = parseInt(company.australianCompanyNumber[0], 10) * 8;
                const sum2 = parseInt(company.australianCompanyNumber[1], 10) * 7;
                const sum3 = parseInt(company.australianCompanyNumber[2], 10) * 6;
                const sum4 = parseInt(company.australianCompanyNumber[3], 10) * 5;
                const sum5 = parseInt(company.australianCompanyNumber[4], 10) * 4;
                const sum6 = parseInt(company.australianCompanyNumber[5], 10) * 3;
                const sum7 = parseInt(company.australianCompanyNumber[6], 10) * 2;
                const sum8 = parseInt(company.australianCompanyNumber[7], 10) * 1;

                const sum = sum1 + sum2 + sum3 + sum4 + sum5 + sum6 + sum7 + sum8;
                const remainder = sum % 10;
                const checkDigit = 10 - remainder === 10 ? 0 : 10 - remainder;

                if (checkDigit !== parseInt(company.australianCompanyNumber[8], 10)) {
                    error = 'Please enter a valid Australian company number';
                }
            }
        }

        this.props.setError('companyAustralianCompanyNumber', error);

        return !error;
    }

    private validateCompanyName(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if ([InvestorTypeEnum.Company, InvestorTypeEnum.TrustCompany].includes(currentInvestor.type)) {
            const company: ICompany = this.getCompany();

            if (!company.name || company.name.length === 0) {
                error = 'Please enter the name';
            }
        }

        this.props.setError('companyName', error);

        return !error;
    }

    private validateCompanyPostcode(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if ([InvestorTypeEnum.Company, InvestorTypeEnum.TrustCompany].includes(currentInvestor.type)) {
            const company: ICompany = this.getCompany();

            if (!company.postcode || company.postcode.length === 0) {
                error = 'Please enter the postcode';
            }
        }

        this.props.setError('companyPostcode', error);

        return !error;
    }

    private validateCompanyState(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if ([InvestorTypeEnum.Company, InvestorTypeEnum.TrustCompany].includes(currentInvestor.type)) {
            const company: ICompany = this.getCompany();

            if (!company.state || company.state.length === 0) {
                error = 'Please enter the state';
            }
        }

        this.props.setError('companyState', error);

        return !error;
    }

    private validateCompanyStreetAddress(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if ([InvestorTypeEnum.Company, InvestorTypeEnum.TrustCompany].includes(currentInvestor.type)) {
            const company: ICompany = this.getCompany();

            if (!company.streetAddress || company.streetAddress.length === 0) {
                error = 'Please enter the street address';
            }
        }

        this.props.setError('companyStreetAddress', error);

        return !error;
    }

    private validateCompanySuburb(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if ([InvestorTypeEnum.Company, InvestorTypeEnum.TrustCompany].includes(currentInvestor.type)) {
            const company: ICompany = this.getCompany();

            if (!company.suburb || company.suburb.length === 0) {
                error = 'Please enter the suburb';
            }
        }

        this.props.setError('companySuburb', error);

        return !error;
    }

    private validateCompanyTaxFileNumber(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if ([InvestorTypeEnum.Company, InvestorTypeEnum.TrustCompany].includes(currentInvestor.type)) {
            const company: ICompany = this.getCompany();

            if (company.taxFileNumber && company.taxFileNumber.length > 0) {
                if (company.taxFileNumber.length < 8) {
                    error = 'Please enter at least 8 numbers';
                } else if (company.taxFileNumber.length > 9) {
                    error = 'Please enter at most 9 numbers';
                } else if (!validateTaxFileNumber(company.taxFileNumber)) {
                    error = 'Please enter a valid tax file number';
                }
            }
        }

        this.props.setError('companyTaxFileNumber', error);

        return !error;
    }

    private validateIndividualOneDateOfBirth(): boolean {
        let error: string;

        const individual1 = this.getIndividualOne();

        if (!individual1.dateOfBirth || individual1.dateOfBirth.length === 0) {
            error = 'Please enter the date of birth';
        } else {
            const date = moment(individual1.dateOfBirth);

            if (!date.isValid()) {
                error = 'Please enter a valid date';
            } else {
                const before = moment().subtract(18, 'years');

                if (date.isAfter(before)) {
                    error = 'Sorry you must be at least 18 years old';
                }
            }
        }

        this.props.setError('individualOneDateOfBirth', error);

        return !error;
    }

    private validateIndividualOneFirstName(): boolean {
        let error: string;

        const individual1 = this.getIndividualOne();

        if (!individual1.firstName || individual1.firstName.length === 0) {
            error = 'Please enter the given name';
        } else if (individual1.firstName.length < 2) {
            error = 'Please enter at least 2 characters';
        }

        this.props.setError('individualOneFirstName', error);

        return !error;
    }

    private validateIndividualOneIdProofExpiryDate(): boolean {
        let error: string;

        const individual1 = this.getIndividualOne();

        if (!individual1.idProofExpiryDate || individual1.idProofExpiryDate.length === 0) {
            error = 'Please enter the ID proof expiry date';
        } else {
            const date = moment(individual1.idProofExpiryDate);

            if (!date.isValid()) {
                error = 'Please enter a valid date';
            } else if (date.isSameOrBefore(moment())) {
                error = 'Please enter an ID proof that has not expired';
            }
        }

        this.props.setError('individualOneIdProofExpiryDate', error);

        return !error;
    }

    private validateIndividualOneIdProofNumber(): boolean {
        let error: string;

        const individual1 = this.getIndividualOne();

        if (!individual1.idProofNumber || individual1.idProofNumber.length === 0) {
            error = 'Please enter the ID proof number';
        }

        this.props.setError('individualOneIdProofNumber', error);

        return !error;
    }

    private validateIndividualOneIdProofType(): boolean {
        let error: string;

        const individual1 = this.getIndividualOne();

        if (!individual1.idProofType || ![IdProofTypeEnum.DriverLicence, IdProofTypeEnum.Passport].includes(individual1.idProofType)) {
            error = 'Please select the ID proof';
        }

        this.props.setError('individualOneIdProofType', error);

        return !error;
    }

    private validateIndividualOneDriverLicenceCardNumber(): boolean {
        let error: string;

        const individual1 = this.getIndividualOne();

        if ((individual1.driverLicenceCardNumber || '').length === 0) {
            error = 'Please enter the the driver licence card number';
        } else if (individual1.driverLicenceCardNumber && individual1.driverLicenceCardNumber.length > 20) {
            error = 'Please do not enter more than 10 characters';
        }

        this.props.setError('individualOneDriverLicenceCardNumber', error);

        return !error;
    }

    private validateIndividualOneDriverLicenceState(): boolean {
        let error: string;

        const individual1 = this.getIndividualOne();

        if (!individual1.driverLicenceState || individual1.driverLicenceState.length === 0) {
            error = 'Please select the driver licence state';
        }

        this.props.setError('individualOneDriverLicenceState', error);

        return !error;
    }

    private validateIndividualOneLastName(): boolean {
        let error: string;

        const individual1 = this.getIndividualOne();

        if (!individual1.lastName || individual1.lastName.length === 0) {
            error = 'Please enter the last name';
        } else if (individual1.lastName.length < 2) {
            error = 'Please enter at least 2 characters';
        }

        this.props.setError('individualOneLastName', error);

        return !error;
    }

    private validateIndividualOnePostcode(): boolean {
        let error: string;

        const individual1 = this.getIndividualOne();

        if (!individual1.postcode || individual1.postcode.length === 0) {
            error = 'Please enter the postcode';
        }

        this.props.setError('individualOnePostcode', error);

        return !error;
    }

    private validateIndividualOneState(): boolean {
        let error: string;

        const individual1 = this.getIndividualOne();

        if (!individual1.state || individual1.state.length === 0) {
            error = 'Please enter the state';
        }

        this.props.setError('individualOneState', error);

        return !error;
    }

    private validateIndividualOneStreetAddress(): boolean {
        let error: string;

        const individual1 = this.getIndividualOne();

        if (!individual1.streetAddress || individual1.streetAddress.length === 0) {
            error = 'Please enter the street address';
        }

        this.props.setError('individualOneStreetAddress', error);

        return !error;
    }

    private validateIndividualOneSuburb(): boolean {
        let error: string;

        const individual1 = this.getIndividualOne();

        if (!individual1.suburb || individual1.suburb.length === 0) {
            error = 'Please enter the suburb';
        }

        this.props.setError('individualOneSuburb', error);

        return !error;
    }

    private validateIndividualOneTaxFileNumber(): boolean {
        let error: string;

        const individual1 = this.getIndividualOne();

        if (individual1.taxFileNumber && individual1.taxFileNumber.length > 0) {
            if (individual1.taxFileNumber.length < 8) {
                error = 'Please enter at least 8 numbers';
            } else if (individual1.taxFileNumber.length > 9) {
                error = 'Please enter at most 9 numbers';
            } else if (!validateTaxFileNumber(individual1.taxFileNumber)) {
                error = 'Please enter a valid tax file number';
            }
        }

        this.props.setError('individualOneTaxFileNumber', error);

        return !error;
    }

    private validateIndividualTwoDateOfBirth(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if (currentInvestor.type === InvestorTypeEnum.JointIndividuals) {
            const individual2 = this.getIndividualTwo();

            if (!individual2.dateOfBirth || individual2.dateOfBirth.length === 0) {
                error = 'Please enter the date of birth';
            } else {
                const date = moment(individual2.dateOfBirth);

                if (!date.isValid()) {
                    error = 'Please enter a valid date';
                } else {
                    const before = moment().subtract(18, 'years');

                    if (date.isAfter(before)) {
                        error = 'Sorry you must be at least 18 years old';
                    }
                }
            }
        }

        this.props.setError('individualTwoDateOfBirth', error);

        return !error;
    }

    private validateIndividualTwoFirstName(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if (currentInvestor.type === InvestorTypeEnum.JointIndividuals) {
            const individual2 = this.getIndividualTwo();

            if (!individual2.firstName || individual2.firstName.length === 0) {
                error = 'Please enter the given name';
            } else if (individual2.firstName.length < 2) {
                error = 'Please enter at least 2 characters';
            }
        }

        this.props.setError('individualTwoFirstName', error);

        return !error;
    }

    private validateIndividualTwoIdProofExpiryDate(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if (currentInvestor.type === InvestorTypeEnum.JointIndividuals) {
            const individual2 = this.getIndividualTwo();

            if (!individual2.idProofExpiryDate || individual2.idProofExpiryDate.length === 0) {
                error = 'Please enter the ID proof expiry date';
            } else {
                const date = moment(individual2.idProofExpiryDate);

                if (!date.isValid()) {
                    error = 'Please enter a valid date';
                } else if (date.isSameOrBefore(moment())) {
                    error = 'Please enter an ID proof that has not expired';
                }
            }
        }

        this.props.setError('individualTwoIdProofExpiryDate', error);

        return !error;
    }

    private validateIndividualTwoIdProofNumber(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if (currentInvestor.type === InvestorTypeEnum.JointIndividuals) {
            const individual2 = this.getIndividualTwo();

            if (!individual2.idProofNumber || individual2.idProofNumber.length === 0) {
                error = 'Please enter the ID proof number';
            }
        }

        this.props.setError('individualTwoIdProofNumber', error);

        return !error;
    }

    private validateIndividualTwoIdProofType(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if (currentInvestor.type === InvestorTypeEnum.JointIndividuals) {
            const individual2 = this.getIndividualTwo();

            if (!individual2.idProofType || ![IdProofTypeEnum.DriverLicence, IdProofTypeEnum.Passport].includes(individual2.idProofType)) {
                error = 'Please select the ID proof';
            }
        }

        this.props.setError('individualTwoIdProofType', error);

        return !error;
    }

    private validateIndividualTwoDriverLicenceCardNumber(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if (currentInvestor.type === InvestorTypeEnum.JointIndividuals) {
            const individual2: IIndividual = this.getIndividualTwo();

            if ((individual2.driverLicenceCardNumber || '').length === 0) {
                error = 'Please enter the the driver licence card number';
            } else if (individual2.driverLicenceCardNumber && individual2.driverLicenceCardNumber.length > 20) {
                error = 'Please do not enter more than 20 characters';
            }
        }

        this.props.setError('individualTwoDriverLicenceCardNumber', error);

        return !error;
    }

    private validateIndividualTwoDriverLicenceState(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if (currentInvestor.type === InvestorTypeEnum.JointIndividuals) {
            const individual2: IIndividual = this.getIndividualTwo();

            if (!individual2.driverLicenceState || individual2.driverLicenceState.length === 0) {
                error = 'Please select the driver licence state';
            }
        }

        this.props.setError('individualTwoDriverLicenceState', error);

        return !error;
    }

    private validateIndividualTwoLastName(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if (currentInvestor.type === InvestorTypeEnum.JointIndividuals) {
            const individual2 = this.getIndividualTwo();

            if (!individual2.lastName || individual2.lastName.length === 0) {
                error = 'Please enter the last name';
            } else if (individual2.lastName.length < 2) {
                error = 'Please enter at least 2 characters';
            }
        }

        this.props.setError('individualTwoLastName', error);

        return !error;
    }

    private validateIndividualTwoPostcode(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if (currentInvestor.type === InvestorTypeEnum.JointIndividuals) {
            const individual2 = this.getIndividualTwo();

            if (!individual2.postcode || individual2.postcode.length === 0) {
                error = 'Please enter the postcode';
            }
        }

        this.props.setError('individualTwoPostcode', error);

        return !error;
    }

    private validateIndividualTwoState(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if (currentInvestor.type === InvestorTypeEnum.JointIndividuals) {
            const individual2 = this.getIndividualTwo();

            if (!individual2.state || individual2.state.length === 0) {
                error = 'Please enter the state';
            }
        }

        this.props.setError('individualTwoState', error);

        return !error;
    }

    private validateIndividualTwoStreetAddress(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if (currentInvestor.type === InvestorTypeEnum.JointIndividuals) {
            const individual2 = this.getIndividualTwo();

            if (!individual2.streetAddress || individual2.streetAddress.length === 0) {
                error = 'Please enter the street address';
            }
        }

        this.props.setError('individualTwoStreetAddress', error);

        return !error;
    }

    private validateIndividualTwoSuburb(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if (currentInvestor.type === InvestorTypeEnum.JointIndividuals) {
            const individual2 = this.getIndividualTwo();

            if (!individual2.suburb || individual2.suburb.length === 0) {
                error = 'Please enter the suburb';
            }
        }

        this.props.setError('individualTwoSuburb', error);

        return !error;
    }

    private validateIndividualTwoTaxFileNumber(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if (currentInvestor.type === InvestorTypeEnum.JointIndividuals) {
            const individual2 = this.getIndividualTwo();

            if (individual2.taxFileNumber && individual2.taxFileNumber.length > 0) {
                if (individual2.taxFileNumber.length < 8) {
                    error = 'Please enter at least 8 numbers';
                } else if (individual2.taxFileNumber.length > 9) {
                    error = 'Please enter at most 9 numbers';
                } else if (!validateTaxFileNumber(individual2.taxFileNumber)) {
                    error = 'Please enter a valid tax file number';
                }
            }
        }

        this.props.setError('individualTwoTaxFileNumber', error);

        return !error;
    }

    private validateTrustAustralianBusinessNumber(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if ([InvestorTypeEnum.TrustCompany, InvestorTypeEnum.TrustIndividual].includes(currentInvestor.type)) {
            const trust = this.getTrust();

            if (trust.australianBusinessNumber && trust.australianBusinessNumber.length > 0) {
                if (trust.australianBusinessNumber.length !== 11) {
                    error = 'Please enter exactly 11 numbers';
                } else {
                    const sum1 = (parseInt(trust.australianBusinessNumber[0], 10) - 1) * 10;
                    const sum2 = parseInt(trust.australianBusinessNumber[1], 10) * 1;
                    const sum3 = parseInt(trust.australianBusinessNumber[2], 10) * 3;
                    const sum4 = parseInt(trust.australianBusinessNumber[3], 10) * 5;
                    const sum5 = parseInt(trust.australianBusinessNumber[4], 10) * 7;
                    const sum6 = parseInt(trust.australianBusinessNumber[5], 10) * 9;
                    const sum7 = parseInt(trust.australianBusinessNumber[6], 10) * 11;
                    const sum8 = parseInt(trust.australianBusinessNumber[7], 10) * 13;
                    const sum9 = parseInt(trust.australianBusinessNumber[8], 10) * 15;
                    const sum10 = parseInt(trust.australianBusinessNumber[9], 10) * 17;
                    const sum11 = parseInt(trust.australianBusinessNumber[10], 10) * 19;

                    const sum = sum1 + sum2 + sum3 + sum4 + sum5 + sum6 + sum7 + sum8 + sum9 + sum10 + sum11;
                    const remainder = sum % 89;

                    if (remainder !== 0) {
                        error = 'Please enter a valid Australian business number';
                    }
                }
            }
        }

        this.props.setError('trustAustralianBusinessNumber', error);

        return !error;
    }

    private validateTrustName(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if ([InvestorTypeEnum.TrustCompany, InvestorTypeEnum.TrustIndividual].includes(currentInvestor.type)) {
            const trust = this.getTrust();

            if (!trust.name || trust.name.length === 0) {
                error = 'Please enter the name';
            }
        }

        this.props.setError('trustName', error);

        return !error;
    }

    private validateTrustTaxFileNumber(): boolean {
        const { currentInvestor } = this.props;

        let error: string;

        if ([InvestorTypeEnum.TrustCompany, InvestorTypeEnum.TrustIndividual].includes(currentInvestor.type)) {
            const trust = this.getTrust();

            if (!trust.taxFileNumber || trust.taxFileNumber.length === 0) {
                error = 'Please enter a tax file number';
            } else if (trust.taxFileNumber.length < 8) {
                error = 'Please enter at least 8 numbers';
            } else if (trust.taxFileNumber.length > 9) {
                error = 'Please enter at most 9 numbers';
            } else if (!validateTaxFileNumber(trust.taxFileNumber)) {
                error = 'Please enter a valid tax file number';
            }
        }

        this.props.setError('trustTaxFileNumber', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        countries: publicCountriesSelector(state),
        currentInvestor: publicCurrentInvestorSelector(state),
        registerErrors: authRegisterErrorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        countriesGet: () => dispatch(publicCountriesGetAction()),
        currentInvestorCompanyValueSet: (key: keyof ICompany, value: any) => dispatch(publicCurrentInvestorCompanyValueSetAction(key, value)),
        currentInvestorGet: () => dispatch(publicCurrentInvestorGetAction()),
        currentInvestorIndividualValueSet: (index: number, key: keyof IIndividual, value: any) => dispatch(publicCurrentInvestorIndividualValueSetAction(index, key, value)),
        currentInvestorTrustValueSet: (key: keyof ITrust, value: any) => dispatch(publicCurrentInvestorTrustValueSetAction(key, value)),
        currentInvestorValueSet: (key: keyof IInvestor, value: any) => dispatch(publicCurrentInvestorValueSetAction(key, value)),
        setError: (key: string, value: string) => dispatch(authRegisterErrorSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Details);
