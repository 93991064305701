import React from 'react';
import { Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IGlobalState } from '~reducer';
import { toastHideAction } from './actions';
import IToast from './IToast';
import { toastsSelector } from './selectors';

interface IPropsSelector {
    toasts: IToast[];
}

interface IPropsDispatch {
    hide: (index: number) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Toasts extends React.Component<Props> {
    public render(): JSX.Element {
        const { toasts } = this.props;

        const toastsBlock: JSX.Element[] = toasts.map((toast: IToast, index: number) => {
            const onHide = () => {
                this.props.hide(index);
            };

            return (
                <Toast autohide={true} delay={3000} key={index} onClose={onHide} show={toast.show}>
                    <Toast.Header>{toast.title}</Toast.Header>
                    <Toast.Body>{toast.body}</Toast.Body>
                </Toast>
            );
        });

        return (
            <div className='toasts'>
                {toastsBlock}
            </div>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        toasts: toastsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        hide: (index: number) => dispatch(toastHideAction(index)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Toasts);
