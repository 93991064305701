import { Action } from 'redux';
import GlobalActionsEnum from './ActionsEnum';

export interface IToastAddAction extends Action {
    title: string;
    body: string;
}

export function toastAddAction(title: string, body: string): IToastAddAction {
    return {
        body,
        title,
        type: GlobalActionsEnum.ToastAdd,
    };
}

export interface IToastHideAction extends Action {
    index: number;
}

export function toastHideAction(index: number): IToastHideAction {
    return {
        index,
        type: GlobalActionsEnum.ToastHide,
    };
}
