import { Action } from 'redux';
import IDeal, { IDealErrors } from '~Api/Deal/IDeal';
import IDealProperty, { IDealPropertyErrors } from '~Api/Deal/IDealProperty';
import IDealQuote from '~Api/Deal/IDealQuote';
import LeadActionsEnum from './ActionsEnum';

export interface IDealAddAction extends Action {
    successUrl: string;
}

export function dealAddAction(successUrl: string): IDealAddAction {
    return {
        successUrl,
        type: LeadActionsEnum.DealAdd,
    };
}

export interface IDealBorrowStepSetAction extends Action {
    borrowStep: number;
}

export function dealBorrowStepSetAction(borrowStep: number): IDealBorrowStepSetAction {
    return {
        borrowStep,
        type: LeadActionsEnum.DealBorrowStepSet,
    };
}

export interface IDealCompleteAction extends Action {
    autoQuote: boolean;
    quoteUrl: string;
    supportingDocuments: FileList;
    thanksUrl: string;
}

export function dealCompleteAction(autoQuote: boolean, thanksUrl: string, quoteUrl?: string, supportingDocuments?: FileList): IDealCompleteAction {
    return {
        autoQuote,
        quoteUrl,
        supportingDocuments,
        thanksUrl,
        type: LeadActionsEnum.DealComplete,
    };
}

export interface IDealErrorSetAction extends Action {
    field: keyof IDealErrors;
    value: string;
}

export function dealErrorSetAction(field: keyof IDealErrors, value: string): IDealErrorSetAction {
    return {
        field,
        type: LeadActionsEnum.DealErrorSet,
        value,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IDealGetAction extends Action {}

export function dealGetAction(): IDealGetAction {
    return {
        type: LeadActionsEnum.DealGet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IDealSendAction extends Action {}

export function dealSendAction(): IDealSendAction {
    return {
        type: LeadActionsEnum.DealSend,
    };
}

export interface IDealSetAction extends Action {
    deal: IDeal;
}

export function dealSetAction(deal: IDeal): IDealSetAction {
    return {
        deal,
        type: LeadActionsEnum.DealSet,
    };
}

export interface IDealValueSetAction extends Action {
    key: keyof IDeal;
    value: any;
}

export function dealValueSetAction(key: keyof IDeal, value: any): IDealValueSetAction {
    return {
        key,
        type: LeadActionsEnum.DealValueSet,
        value,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IDealsListAction extends Action {}

export function dealsListAction(): IDealsListAction {
    return {
        type: LeadActionsEnum.DealsList,
    };
}

export interface IDealsSetAction extends Action {
    deals: IDeal[];
}

export function dealsSetAction(deals: IDeal[]): IDealsSetAction {
    return {
        deals,
        type: LeadActionsEnum.DealsSet,
    };
}

export interface IDealQuotesListAction extends Action {
    dealUuid: string;
}

export function dealQuotesListAction(dealUuid: string): IDealQuotesListAction {
    return {
        dealUuid,
        type: LeadActionsEnum.DealQuotesList,
    };
}

export interface IDealQuotesSetAction extends Action {
    quotes: IDealQuote[];
}

export function dealQuotesSetAction(quotes: IDealQuote[]): IDealQuotesSetAction {
    return {
        quotes,
        type: LeadActionsEnum.DealQuotesSet,
    };
}

export interface IReferrerTokenGetAction extends Action {
    referralCode: string;
}

export function referrerTokenGetAction(referralCode: string): IReferrerTokenGetAction {
    return {
        referralCode,
        type: LeadActionsEnum.ReferrerTokenGet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IPropertyAddAction extends Action {}

export function propertyAddAction(): IPropertyAddAction {
    return {
        type: LeadActionsEnum.PropertiesAdd,
    };
}

export interface IPropertyDeleteAction extends Action {
    uuid: string;
}

export function propertyDeleteAction(uuid: string): IPropertyDeleteAction {
    return {
        type: LeadActionsEnum.PropertyDelete,
        uuid,
    };
}

export interface IPropertyErrorSetAction extends Action {
    field: keyof IDealPropertyErrors;
    uuid: string;
    value: string;
}

export function propertyErrorSetAction(uuid: string, field: keyof IDealPropertyErrors, value: string): IPropertyErrorSetAction {
    return {
        field,
        type: LeadActionsEnum.PropertyErrorSet,
        uuid,
        value,
    };
}

export interface IPropertySendAction extends Action {
    uuid: string;
}

export function propertySendAction(uuid: string): IPropertySendAction {
    return {
        type: LeadActionsEnum.PropertySend,
        uuid,
    };
}

export interface IPropertySetAction extends Action {
    property: IDealProperty;
}

export function propertySetAction(property: IDealProperty): IPropertySetAction {
    return {
        property,
        type: LeadActionsEnum.PropertySet,
    };
}

export interface IPropertyValueSetAction extends Action {
    field: keyof IDealProperty;
    uuid: string;
    value: string;
}

export function propertyValueSetAction(uuid: string, field: keyof IDealProperty, value: string): IPropertyValueSetAction {
    return {
        field,
        type: LeadActionsEnum.PropertyValueSet,
        uuid,
        value,
    };
}
