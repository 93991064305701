import React from 'react';
import PublicLayout from '../Layout';
import './invest.less';

class Layout extends React.Component {
    public render(): JSX.Element {
        return (
            <PublicLayout>
                <div className='invest'>
                    {this.props.children}
                </div>
            </PublicLayout>
        );
    }
}

export default Layout;
