import _ from 'lodash';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AddressVersionEnum from '~Api/Deal/AddressVersionEnum';
import IDealProperty, { IDealPropertyErrors } from '~Api/Deal/IDealProperty';
import PropertyStateEnum from '~Api/Deal/PropertyStateEnum';
import propertyStreetTypeLabels from '~Api/Deal/PropertyStreetTypeLabels';
import ZoneTypeEnum from '~Api/Deal/ZoneTypeEnum';
import {
    validateCurrentDebt,
    validateEstimatedValue,
    validatePostcode,
    validateState,
    validateStreetAddress,
    validateStreetName,
    validateStreetNumber,
    validateStreetType,
    validateSuburb,
    validateUnitNumber,
    validateZoneType,
} from '~Lead/validators';
import { IGlobalState } from '~reducer';
import {
    propertyDeleteAction,
    propertyErrorSetAction,
    propertyValueSetAction,
} from './actions';
import {
    propertyErrorsSelector,
    propertySelector,
} from './selectors';

interface IProps {
    uuid: string;
    index: number;
}

interface IPropsSelector {
    errors: IDealPropertyErrors;
    property: IDealProperty;
}

interface IPropsDispatch {
    delete: () => void;
    errorSet: (key: keyof IDealPropertyErrors, value: any) => void;
    valueSet: (key: keyof IDealProperty, value: any) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Property extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeCurrentDebt = this.onChangeCurrentDebt.bind(this);
        this.onChangeEstimatedValue = this.onChangeEstimatedValue.bind(this);
        this.onChangePostcode = this.onChangePostcode.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeStreetAddress = this.onChangeStreetAddress.bind(this);
        this.onChangeUnitNumber = this.onChangeUnitNumber.bind(this);
        this.onChangeStreetNumber = this.onChangeStreetNumber.bind(this);
        this.onChangeStreetName = this.onChangeStreetName.bind(this);
        this.onChangeStreetType = this.onChangeStreetType.bind(this);
        this.onChangeSuburb = this.onChangeSuburb.bind(this);
        this.onChangeZoneType = this.onChangeZoneType.bind(this);

        this.validateCurrentDebt = this.validateCurrentDebt.bind(this);
        this.validateEstimatedValue = this.validateEstimatedValue.bind(this);
        this.validatePostcode = this.validatePostcode.bind(this);
        this.validateState = this.validateState.bind(this);
        this.validateStreetAddress = this.validateStreetAddress.bind(this);
        this.validateUnitNumber = this.validateUnitNumber.bind(this);
        this.validateStreetNumber = this.validateStreetNumber.bind(this);
        this.validateStreetName = this.validateStreetName.bind(this);
        this.validateStreetType = this.validateStreetType.bind(this);
        this.validateSuburb = this.validateSuburb.bind(this);
        this.validateZoneType = this.validateZoneType.bind(this);
    }

    public render(): JSX.Element {
        const { errors, index, property } = this.props;

        const streetAddressFormFields = property.addressVersion === AddressVersionEnum.V2 ? (
            <React.Fragment>
                <Form.Group className='unit-number'>
                    <Form.Label>Unit Number</Form.Label>
                    <Form.Control
                        isInvalid={!!errors.unitNumber}
                        maxLength={10}
                        onBlur={this.validateUnitNumber}
                        onChange={this.onChangeUnitNumber}
                        value={property.unitNumber || ''}
                    />
                    {errors.unitNumber && <Form.Control.Feedback type='invalid'>{errors.unitNumber}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='street-number'>
                    <Form.Label>Street Number</Form.Label>
                    <Form.Control
                        isInvalid={!!errors.streetNumber}
                        maxLength={10}
                        onBlur={this.validateStreetNumber}
                        onChange={this.onChangeStreetNumber}
                        value={property.streetNumber || ''}
                    />
                    {errors.streetNumber && <Form.Control.Feedback type='invalid'>{errors.streetNumber}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='street-name'>
                    <Form.Label>Street Name</Form.Label>
                    <Form.Control
                        isInvalid={!!errors.streetName}
                        maxLength={255}
                        onBlur={this.validateStreetName}
                        onChange={this.onChangeStreetName}
                        value={property.streetName || ''}
                    />
                    {errors.streetName && <Form.Control.Feedback type='invalid'>{errors.streetName}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='street-type'>
                    <Form.Label>Street Type</Form.Label>
                    <Form.Control as='select' className='custom-select' isInvalid={!!errors.streetType} onBlur={this.validateStreetType} onChange={this.onChangeStreetType} value={property.streetType || ''}>
                        {property.state === null && <option/>}
                        {_.keys(propertyStreetTypeLabels).map((code: string): JSX.Element => <option key={code} value={code}>{propertyStreetTypeLabels[code]}</option>)}
                    </Form.Control>
                    {errors.streetType && <Form.Control.Feedback type='invalid'>{errors.streetType}</Form.Control.Feedback>}
                </Form.Group>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Form.Group className='street-address'>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                        isInvalid={!!errors.streetAddress}
                        maxLength={255}
                        onBlur={this.validateStreetAddress}
                        onChange={this.onChangeStreetAddress}
                        value={property.streetAddress || ''}
                    />
                    {errors.streetAddress && <Form.Control.Feedback type='invalid'>{errors.streetAddress}</Form.Control.Feedback>}
                </Form.Group>
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <h4>Security Property {index + 1}</h4>
                {streetAddressFormFields}
                <Form.Group className='suburb'>
                    <Form.Label>Suburb</Form.Label>
                    <Form.Control isInvalid={!!errors.suburb} maxLength={50} onBlur={this.validateSuburb} onChange={this.onChangeSuburb} value={property.suburb || ''} />
                    {errors.suburb && <Form.Control.Feedback type='invalid'>{errors.suburb}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='state'>
                    <Form.Label>State</Form.Label>
                    <Form.Control as='select' className='custom-select' isInvalid={!!errors.state} onBlur={this.validateState} onChange={this.onChangeState} value={property.state || ''}>
                        {property.state === null && <option/>}
                        <option value={PropertyStateEnum.AustralianCapitalTerritory}>ACT</option>
                        <option value={PropertyStateEnum.NewSouthWales}>NSW</option>
                        <option value={PropertyStateEnum.NorthernTerritory}>NT</option>
                        <option value={PropertyStateEnum.Queensland}>QLD</option>
                        <option value={PropertyStateEnum.SouthAustralia}>SA</option>
                        <option value={PropertyStateEnum.Tasmania}>TAS</option>
                        <option value={PropertyStateEnum.Victoria}>VIC</option>
                        <option value={PropertyStateEnum.WesternAustralia}>WA</option>
                    </Form.Control>
                    {errors.state && <Form.Control.Feedback type='invalid'>{errors.state}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='postcode'>
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control isInvalid={!!errors.postcode} maxLength={4} onBlur={this.validatePostcode} onChange={this.onChangePostcode} value={property.postcode || ''} />
                    {errors.postcode && <Form.Control.Feedback type='invalid'>{errors.postcode}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='estimated-value'>
                    <Form.Label>Estimated Value of Property</Form.Label>
                    <Form.Control isInvalid={!!errors.estimatedValue} min={0} max={99999999} onBlur={this.validateEstimatedValue} onChange={this.onChangeEstimatedValue} type='number' value={property.estimatedValue || ''} />
                    {errors.estimatedValue && <Form.Control.Feedback type='invalid'>{errors.estimatedValue}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='current-debt'>
                    <Form.Label>Current Debt on Property (if any)</Form.Label>
                    <Form.Control isInvalid={!!errors.currentDebt}  min={0} max={99999999} onBlur={this.validateCurrentDebt} onChange={this.onChangeCurrentDebt} type='number' value={property.currentDebt || ''} />
                    {errors.currentDebt && <Form.Control.Feedback type='invalid'>{errors.currentDebt}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='zone-type'>
                    <Form.Label>Property Type</Form.Label>
                    <Form.Control as='select' className='custom-select' isInvalid={!!errors.zoneType} onBlur={this.validateZoneType} onChange={this.onChangeZoneType} value={property.zoneType || ''}>
                        {property.zoneType === null && <option/>}
                        <option value={ZoneTypeEnum.ResidentialHouse}>Residential - House</option>
                        <option value={ZoneTypeEnum.ResidentialTownhouse}>Residential - Townhouse / Villa</option>
                        <option value={ZoneTypeEnum.ResidentialUnit}>Residential - Unit / Apartment</option>
                        <option value={ZoneTypeEnum.ResidentialLand}>Residential - Land</option>
                        <option value={ZoneTypeEnum.CommercialOffice}>Commercial - Office</option>
                        <option value={ZoneTypeEnum.CommercialRetail}>Commercial - Retail</option>
                        <option value={ZoneTypeEnum.CommercialIndustrial}>Commercial - Industrial</option>
                        <option value={ZoneTypeEnum.CommercialLand}>Commercial - Land</option>
                        <option value={ZoneTypeEnum.RuralResidential}>Rural - Residential</option>
                        <option value={ZoneTypeEnum.RuralLand}>Rural - Land</option>
                    </Form.Control>
                    {errors.zoneType && <Form.Control.Feedback type='invalid'>{errors.zoneType}</Form.Control.Feedback>}
                </Form.Group>
                {index > 0 && <Button className='delete' onClick={this.props.delete} variant='light'>Remove Property</Button>}
            </React.Fragment>
        );
    }

    private onChangeCurrentDebt(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.props.valueSet('currentDebt', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private onChangeEstimatedValue(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.props.valueSet('estimatedValue', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private onChangePostcode(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.props.valueSet('postcode', event.target.value.substr(0, 4));
    }

    private onChangeState(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('state', event.target.value as PropertyStateEnum);
    }

    private onChangeStreetAddress(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('streetAddress', event.target.value.substr(0, 255));
    }

    private onChangeUnitNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.valueSet('unitNumber', event.target.value.substr(0, 10));
    }

    private onChangeStreetNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.valueSet('streetNumber', event.target.value.substr(0, 10));
    }

    private onChangeStreetName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.valueSet('streetName', event.target.value.substr(0, 255));
    }

    private onChangeStreetType(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.valueSet('streetType', event.target.value.substr(0, 10));
    }

    private onChangeSuburb(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('suburb', event.target.value.substr(0, 50));
    }

    private onChangeZoneType(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.valueSet('zoneType', event.target.value as ZoneTypeEnum);
    }

    private validateCurrentDebt(): boolean {
        return !validateCurrentDebt(this.props.property.currentDebt, this.props.errorSet);
    }

    private validateEstimatedValue(): boolean {
        return !validateEstimatedValue(this.props.property.estimatedValue, this.props.errorSet);
    }

    private validatePostcode(): boolean {
        return !validatePostcode(this.props.property.postcode, this.props.property.state, this.props.errorSet);
    }

    private validateState(): boolean {
        if (this.props.property.postcode && this.props.property.postcode.length > 0) {
            validatePostcode(this.props.property.postcode, this.props.property.state, this.props.errorSet);
        }

        return !validateState(this.props.property.state, this.props.errorSet);
    }

    private validateStreetAddress(): boolean {
        return !validateStreetAddress(this.props.property.streetAddress, this.props.errorSet);
    }

    private validateUnitNumber(): boolean {
        return !validateUnitNumber(this.props.property.unitNumber, this.props.errorSet);
    }

    private validateStreetNumber(): boolean {
        return !validateStreetNumber(this.props.property.streetNumber, this.props.errorSet);
    }

    private validateStreetName(): boolean {
        return !validateStreetName(this.props.property.streetName, this.props.errorSet);
    }

    private validateStreetType(): boolean {
        return !validateStreetType(this.props.property.streetType, this.props.errorSet);
    }

    private validateSuburb(): boolean {
        return !validateSuburb(this.props.property.suburb, this.props.errorSet);
    }

    private validateZoneType(): boolean {
        return !validateZoneType(this.props.property.zoneType, this.props.errorSet);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        errors: propertyErrorsSelector(state, ownProps.uuid),
        property: propertySelector(state, ownProps.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        delete: () => dispatch(propertyDeleteAction(ownProps.uuid)),
        errorSet: (key: keyof IDealPropertyErrors, value: string) => dispatch(propertyErrorSetAction(ownProps.uuid, key, value)),
        valueSet: (key: keyof IDealProperty, value: string) => dispatch(propertyValueSetAction(ownProps.uuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Property);
