import { fetchGetUtility, fetchPostUtility, fetchPutUtility, fetchUtility } from '~utilities/fetch';
import IInvestor from './IInvestor';
import InterestPaymentStrategyEnum from './InterestPaymentStrategyEnum';
import { transformInvestor, transformMarketplaceQualifier } from './transformers';
import IMarketplaceQualifier from './IMarketplaceQualifier';

export async function investorAccountCompleteRequest(investorAccountUuid: string, imContent?: string, pdsContent?: string): Promise<Response> {
    return fetchPostUtility(`/investor-accounts/${investorAccountUuid}/complete`, {
        imContent,
        pdsContent,
    });
}

export async function investorAccountDepositRequest(uuid: string, amount: number): Promise<Response> {
    return fetchPostUtility(`/investor-accounts/${uuid}/deposit`, {
        amount,
    });
}

export async function investorAccountMarketplaceQualifierRequest(investorAccountUuid: string, qualifierResponses: IMarketplaceQualifier[]): Promise<Response> {
    return fetchPostUtility(`/investor-accounts/${investorAccountUuid}/marketplace-qualifier`, {
        qualifierResponses: qualifierResponses.map(transformMarketplaceQualifier),
    });
}

export async function investorAccountSharesListRequest(uuid: string): Promise<Response> {
    return fetchUtility(`/investor-accounts/${uuid}/shares`);
}

export async function investorAccountTransactionsListRequest(uuid: string): Promise<Response> {
    return fetchUtility(`/investor-accounts/${uuid}/transactions`);
}

export async function investorAccountWithdrawRequest(uuid: string, amount: number): Promise<Response> {
    return fetchPostUtility(`/investor-accounts/${uuid}/withdraw`, {
        amount,
    });
}

export async function investorAccountAnnualStatementsListRequest(investorAccountUuid: string): Promise<Response> {
    return fetchGetUtility(`/investor-accounts/${investorAccountUuid}/annual-statements`);
}

export async function investorAccountMonthlyStatementsListRequest(investorAccountUuid: string): Promise<Response> {
    return fetchGetUtility(`/investor-accounts/${investorAccountUuid}/monthly-statements`);
}

export async function investorGetRequest(uuid: string): Promise<Response> {
    return fetchUtility(`/investors/${uuid}`);
}

export async function investorReferralsTokenRequest(referralCode: string): Promise<Response> {
    return fetchPostUtility('/investor-referrals/token', { referralCode });
}

export async function investorReferralAddRequest(uuid: string, email: string): Promise<Response> {
    return fetchPostUtility(`/investors/${uuid}/referrals`, {
        email,
    });
}

export async function investorReferralsListRequest(uuid: string): Promise<Response> {
    return fetchUtility(`/investors/${uuid}/referrals`);
}

export async function investorReferredByRequest(investor: IInvestor, token: string): Promise<Response> {
    return fetchPostUtility(`/investors/${investor.uuid}/referred-by`, {
        token,
    });
}

export async function investorUpdateRequest(investor: IInvestor): Promise<Response> {
    return fetchPutUtility(`/investors/${investor.uuid}`, transformInvestor(investor));
}

export async function investorAccountIncomeTrustCreateRequest(investorUuid: string): Promise<Response> {
    return fetchPostUtility(`/investors/${investorUuid}/create-income-trust-account`);
}

export async function investorAccountIncomeTrustInterestPaymentStrategyRequest(investorAccountUuid: string, interestPaymentStrategy: InterestPaymentStrategyEnum): Promise<Response> {
    return fetchPostUtility(`/income-trust/${investorAccountUuid}/interest-payment-strategy`, {
        interestPaymentStrategy,
    });
}

export async function investorAccountIncomeTrustDepositRequest(investorIncomeTrustUuid: string, amount: number): Promise<Response> {
    return fetchPostUtility(`/investor-income-trust/${investorIncomeTrustUuid}/deposit`, {
        amount,
    });
}

export async function investorAccountIncomeTrustsListRequest(investorAccountUuid: string): Promise<Response> {
    return fetchGetUtility(`/investor-accounts/${investorAccountUuid}/income-trusts`);
}

export async function investorAccountMarketplaceCreateRequest(investorUuid: string): Promise<Response> {
    return fetchPostUtility(`/investors/${investorUuid}/create-marketplace-account`);
}
