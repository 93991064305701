import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router as ReactRouter, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import IAuthUser from '~Auth/IAuthUser';
import RoleEnum from '~Auth/RoleEnum';
import { authCurrentUserSelector } from '~Auth/selectors';
import history from '~history';
import { currentInvestorGetAction } from '~Investor/actions';
import {
    currentInvestorAccountSelector,
    currentInvestorSelector,
} from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import BankAccount from './BankAccount';
import Details from './Details';
import DistributionPreference from './DistributionPreference';
import InformationMemorandum from './InformationMemorandum';
import Qualifier from './Qualifier';
import RetailInvestor from './RetailInvestor';

interface IPropsSelector {
    currentInvestor: IInvestor;
    currentUser: IAuthUser;
    marketplaceAccount: IAccount;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Router extends React.Component<Props> {
    public componentDidMount(): void {
        const { currentInvestor } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }
    }

    public render(): JSX.Element {
        const { currentInvestor, currentUser, marketplaceAccount } = this.props;

        if (currentUser.role !== RoleEnum.Investor || !currentInvestor || !marketplaceAccount) {
            return null;
        }

        const incompleteInvestorAccountRoutes: JSX.Element[] = !marketplaceAccount.isCompleted && [
            <Route key='register-details' exact={true} path='/income-trust/register/details' component={Details} />,
            <Route key='register-bank-account' exact={true} path='/income-trust/register/bank-account' component={BankAccount} />,
        ];

        return (
            <ReactRouter history={history}>
                <Switch>
                    <Route exact={true} path='/income-trust/register/qualifier' component={Qualifier} />
                    <Route exact={true} path='/income-trust/register/retail-investor' component={RetailInvestor} />
                    <Route exact={true} path='/income-trust/register/distribution-preference' component={DistributionPreference} />
                    <Route exact={true} path='/income-trust/register/information-memorandum' component={InformationMemorandum} />

                    {incompleteInvestorAccountRoutes}

                    <Redirect to='/income-trust/register/qualifier' />
                </Switch>
            </ReactRouter>
        );
    }
}


function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        currentUser: authCurrentUserSelector(state),
        marketplaceAccount: currentInvestorAccountSelector(state, AccountTypeEnum.Marketplace),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Router);
