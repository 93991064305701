import React from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineArrowDown } from 'react-icons/ai';
import CustomerForms from '~UI/CustomerForms';
import DirectLender from '~UI/DirectLender';
import ProductGuides from '~UI/ProductGuides';
import Layout from './Layout';
import './product-and-tools.less';

class ProductAndTools extends React.Component {
    public render(): JSX.Element {
        return (
            <Layout section='product-and-tools'>
                <h1>Product & Tools</h1>
                <div className='main'>
                    <div className='content-box'>
                        <div className='image'>
                            <ProductGuides />
                        </div>
                        <div className='content'>
                            <div className='label'>Product Guides</div>
                            <div className='detail'> Product information available for download.</div>
                        </div>
                        <div className='document-link'>
                            <a href='https://www.funding.com.au/product-guide/' target='_blank'><Button>Product Guide <AiOutlineArrowDown /></Button></a>
                        </div>
                        <div className='document-link'>
                            <a href='https://www.funding.com.au/short-product-guide/' target='_blank'><Button>Short Product Guide <AiOutlineArrowDown /></Button></a>
                        </div>
                    </div>
                    <div className='content-box'>
                        <div className='image'>
                            <DirectLender />
                        </div>
                        <div className='content'>
                            <div className='label'>Broker Tools</div>
                            <div className='detail'>Broker related tools and information available for download.</div>
                        </div>
                        <div className='document-link'>
                            <a href='https://www.funding.com.au/broker-contacts/' target='_blank'><Button>BDM Contact List <AiOutlineArrowDown /></Button></a>
                        </div>
                        <div className='document-link'>
                            <a href='https://www.funding.com.au/supporting-documents-checklist/' target='_blank'><Button>Supporting Documents Checklist <AiOutlineArrowDown /></Button></a>
                        </div>
                    </div>
                    <div className='content-box'>
                        <div className='image'>
                            <CustomerForms />
                        </div>
                        <div className='content'>
                            <div className='label'>Customer Forms</div>
                            <div className='detail'>Documents and information for your customers / clients available for download.</div>
                        </div>
                        <div className='document-link'>
                            <a href='https://www.funding.com.au/loan-application/' target='_blank'><Button>Loan Application  <AiOutlineArrowDown /></Button></a>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default ProductAndTools;
