import IAdviser from './IAdviser';

export function transformAdviser(adviser: IAdviser): any {
    return {
        afslNumber: adviser.afslNumber,
        authorisedRepresentativeNumber: adviser.authorisedRepresentativeNumber,
        companyName: adviser.companyName,
        dealerGroup: adviser.dealerGroup,
        email: adviser.email,
        firstName: adviser.firstName,
        lastName: adviser.lastName,
        phone: adviser.phone,
    };
}
