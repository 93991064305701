import Cookies from 'js-cookie';
import iconv from 'iconv-lite';

export interface IFetchResponse {
    body: any;
    headers: Headers;
    status: number;
}

export async function fetchUtility(url: string, init?: RequestInit, tokenName: string = 'currentUser'): Promise<Response> {
    let token;
    const currentUser = Cookies.getJSON(tokenName);
    if (currentUser) {
        token = currentUser.token;
    }
    if (!token) {
        token = Cookies.getJSON('jwt_token');
    }

    const headers: any = init && init.headers ? { ...init.headers } : {};

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    headers['Accept'] = 'application/json';
    if (!headers['Content-Type']) {
        headers['Content-Type'] = 'application/json';
    }

    return fetch(`${process.env.API_HOST}${url}`, {
        ...init,
        headers,
    }).then((response: Response): Promise<any> => {
        return response.json().then((jsonResponse: any) => {
            return {
                body: jsonResponse,
                headers: response.headers,
                status: response.status,
            };
        });
    });
}

export async function fetchDeleteUtility(url: string, tokenName: string = 'currentUser'): Promise<Response> {
    return fetchUtility(url, {
        method: 'DELETE',
    }, tokenName);
}

export async function fetchGetUtility(url: string, tokenName: string = 'currentUser'): Promise<Response> {
    return fetchUtility(url, {
        method: 'GET',
    }, tokenName);
}

export async function fetchPostUtility(url: string, body?: any, tokenName: string = 'currentUser'): Promise<Response> {
    return fetchUtility(url, {
        body: JSON.stringify(body),
        method: 'POST',
    }, tokenName);
}

export async function fetchPostFileUtility(url: string, body: File): Promise<Response> {
    return fetchUtility(url, {
        body,
        headers: {
            'Content-Type': body.type,
            // Convert to ISO 8859-1 to remove unusual characters
            'Filename': iconv.encode(body.name, 'iso-8859-1').toString(),
        },
        method: 'POST',
    });
}

export async function fetchPutUtility(url: string, body?: any, tokenName: string = 'currentUser'): Promise<Response> {
    return fetchUtility(url, {
        body: JSON.stringify(body),
        method: 'PUT',
    }, tokenName);
}
