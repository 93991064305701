import _ from 'lodash';
import { AnyAction } from 'redux';
import AuthActionsEnum from '~Auth/ActionsEnum';
import {
    ICurrentUserSetAction,
    IEmailChangeErrorSetAction,
    IEmailChangeErrorsSetAction,
    IEmailChangeInProgressSetAction,
    IEmailChangeModalHideAction,
    IEmailChangeModalShowAction,
    IPasswordChangeErrorSetAction,
    IPasswordChangeErrorsSetAction,
    IPasswordChangeInProgressSetAction,
    IPasswordChangeModalHideAction,
    IPasswordChangeModalShowAction,
} from './actions';
import UserActionsEnum from './ActionsEnum';
import IUser from './IUser';

export interface IUserState {
    currentUser: IUser;
    emailChangeErrors: any;
    emailChangeInProgress: boolean;
    emailChangeModalShow: boolean;
    passwordChangeErrors: any;
    passwordChangeInProgress: boolean;
    passwordChangeModalShow: boolean;
}

const initialData: IUserState = {
    currentUser: null,
    emailChangeErrors: {},
    emailChangeInProgress: false,
    emailChangeModalShow: false,
    passwordChangeErrors: {},
    passwordChangeInProgress: false,
    passwordChangeModalShow: false,
};

export function userReducer(state: IUserState = initialData, action: AnyAction): IUserState {
    switch (action.type) {
        case UserActionsEnum.CurrentUserSet: {
            const typedAction: ICurrentUserSetAction = action as ICurrentUserSetAction;

            return {
                ...state,
                currentUser: typedAction.user,
            };
        }

        case UserActionsEnum.EmailChangeErrorSet: {
            const typedAction: IEmailChangeErrorSetAction = action as IEmailChangeErrorSetAction;

            return {
                ...state,
                emailChangeErrors: {
                    ...state.emailChangeErrors,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        case UserActionsEnum.EmailChangeErrorsSet: {
            const typedAction: IEmailChangeErrorsSetAction = action as IEmailChangeErrorsSetAction;

            return {
                ...state,
                emailChangeErrors: typedAction.errors,
            };
        }

        case UserActionsEnum.EmailChangeInProgressSet: {
            const typedAction: IEmailChangeInProgressSetAction = action as IEmailChangeInProgressSetAction;

            return {
                ...state,
                emailChangeInProgress: typedAction.inProgress,
            };
        }

        case UserActionsEnum.EmailChangeModalHide: {
            const typedAction: IEmailChangeModalHideAction = action as IEmailChangeModalHideAction;

            return {
                ...state,
                emailChangeModalShow: false,
            };
        }

        case UserActionsEnum.EmailChangeModalShow: {
            const typedAction: IEmailChangeModalShowAction = action as IEmailChangeModalShowAction;

            return {
                ...state,
                emailChangeModalShow: true,
            };
        }

        case UserActionsEnum.PasswordChangeErrorSet: {
            const typedAction: IPasswordChangeErrorSetAction = action as IPasswordChangeErrorSetAction;

            return {
                ...state,
                passwordChangeErrors: {
                    ...state.passwordChangeErrors,
                    [typedAction.key]: typedAction.value,
                },
            };
        }

        case UserActionsEnum.PasswordChangeErrorsSet: {
            const typedAction: IPasswordChangeErrorsSetAction = action as IPasswordChangeErrorsSetAction;

            return {
                ...state,
                passwordChangeErrors: typedAction.errors,
            };
        }

        case UserActionsEnum.PasswordChangeInProgressSet: {
            const typedAction: IPasswordChangeInProgressSetAction = action as IPasswordChangeInProgressSetAction;

            return {
                ...state,
                passwordChangeInProgress: typedAction.inProgress,
            };
        }

        case UserActionsEnum.PasswordChangeModalHide: {
            const typedAction: IPasswordChangeModalHideAction = action as IPasswordChangeModalHideAction;

            return {
                ...state,
                passwordChangeModalShow: false,
            };
        }

        case UserActionsEnum.PasswordChangeModalShow: {
            const typedAction: IPasswordChangeModalShowAction = action as IPasswordChangeModalShowAction;

            return {
                ...state,
                passwordChangeModalShow: true,
            };
        }

        case AuthActionsEnum.Login: {
            return initialData;
        }

        case AuthActionsEnum.Logout: {
            return initialData;
        }

        default:
            return state;
    }
}
