import React from 'react';

class ReferFriend extends React.Component {
    public render(): JSX.Element {
        return (
            <svg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='50' cy='50' r='45' fill='#E9F6F6'/>
                <path d='M71.0029 82.2862C71.0029 83.6062 61.9477 84.6754 50.7805 84.6754C39.6133 84.6754 30.5977 83.6062 30.5977 82.2862C30.5977 80.9662 39.6529 79.897 50.8201 79.897C61.9873 79.897 71.0029 80.9662 71.0029 82.2862Z' fill='#81D2D4'/>
                <path d='M53.8155 19.105L32.1023 58.6993C31.0367 60.6425 31.7481 63.0816 33.6913 64.1473L51.2605 73.7821C53.2037 74.8477 55.6428 74.1363 56.7085 72.1931L78.4217 32.5988C79.4873 30.6556 78.7759 28.2165 76.8327 27.1508L59.2635 17.516C57.3203 16.4504 54.8812 17.1618 53.8155 19.105Z' fill='white' stroke='#16254C' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
                <path d='M51.897 22.6401L34.0371 55.1943L58.6409 68.6924L76.5008 36.1382L51.897 22.6401Z' fill='#D2EDED'/>
                <path d='M35.9971 51.5961L75.5443 35.5977L51.8899 22.6353L35.9971 51.5961Z' fill='white'/>
                <path d='M51.897 22.6401L34.0371 55.1943L58.6409 68.6924L76.5008 36.1382L51.897 22.6401Z' stroke='#16254C' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
                <path d='M69.6572 27.7964L70.5416 28.2848' stroke='#59C5C7' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
                <path d='M61.3936 23.3086L67.0564 26.503' stroke='#59C5C7' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
                <path d='M8 7.73253C8.01044 6.99964 8.31072 6.30071 8.83515 5.78865C9.35958 5.27659 10.0655 4.99307 10.7984 5.00013H41.1584C41.8901 5.00013 42.5918 5.29079 43.1092 5.80816C43.6265 6.32554 43.9172 7.02725 43.9172 7.75893V29.1429C43.9155 29.5035 43.8427 29.8602 43.7032 30.1926C43.5636 30.5251 43.3599 30.8268 43.1037 31.0805C42.8475 31.3342 42.5439 31.535 42.2101 31.6714C41.8763 31.8078 41.519 31.8771 41.1584 31.8753H31.4564V34.9245C31.4549 35.241 31.3602 35.5499 31.1841 35.8129C31.008 36.0758 30.7584 36.281 30.4664 36.4029C30.1753 36.5218 29.8555 36.5515 29.5475 36.488C29.2396 36.4245 28.9575 36.2708 28.7372 36.0465L24.5528 31.8753H10.7984C10.0667 31.8753 9.36501 31.5847 8.84763 31.0673C8.33026 30.5499 8.0396 29.8482 8.0396 29.1165L8 7.73253Z' fill='white' stroke='#16254C' strokeWidth='2.5' strokeLinejoin='round'/>
                <path d='M39.0855 9.09222C39.1403 9.09222 39.1939 9.10847 39.2395 9.13893C39.2851 9.16939 39.3206 9.21268 39.3416 9.26334C39.3626 9.31399 39.3681 9.36972 39.3574 9.42349C39.3467 9.47727 39.3203 9.52666 39.2815 9.56542C39.2427 9.60419 39.1933 9.63059 39.1396 9.64129C39.0858 9.65198 39.0301 9.64649 38.9794 9.62551C38.9288 9.60453 38.8855 9.569 38.855 9.52342C38.8245 9.47783 38.8083 9.42424 38.8083 9.36941C38.8083 9.2959 38.8375 9.22539 38.8895 9.1734C38.9415 9.12142 39.012 9.09222 39.0855 9.09222ZM39.0855 7.77222C38.7696 7.77222 38.4608 7.86589 38.1981 8.04139C37.9355 8.2169 37.7308 8.46634 37.6099 8.75819C37.489 9.05004 37.4573 9.37119 37.519 9.68101C37.5806 9.99084 37.7327 10.2754 37.9561 10.4988C38.1795 10.7222 38.4641 10.8743 38.7739 10.9359C39.0837 10.9976 39.4049 10.9659 39.6967 10.845C39.9886 10.7241 40.238 10.5194 40.4135 10.2568C40.589 9.99411 40.6827 9.68531 40.6827 9.36941C40.6827 8.94581 40.5144 8.53956 40.2149 8.24003C39.9153 7.94049 39.5091 7.77222 39.0855 7.77222Z' fill='#59C5C7'/>
                <path d='M14.376 14.5437H29.5164' stroke='#59C5C7' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
                <path d='M14.376 19.3223H35.1' stroke='#59C5C7' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
                <path d='M14.376 24.1138H35.1' stroke='#59C5C7' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
                <path d='M45.2236 65.4561C45.2262 65.6159 45.1812 65.7728 45.0943 65.9069C45.0075 66.0411 44.8826 66.1463 44.7358 66.2093C44.5889 66.2723 44.4266 66.2901 44.2695 66.2606C44.1125 66.231 43.9678 66.1554 43.8538 66.0433C43.7399 65.9313 43.6619 65.7878 43.6297 65.6313C43.5976 65.4747 43.6128 65.3122 43.6733 65.1643C43.7338 65.0164 43.837 64.8898 43.9697 64.8007C44.1024 64.7117 44.2586 64.6641 44.4184 64.6641C44.6297 64.664 44.8325 64.7471 44.9831 64.8952C45.1338 65.0434 45.2201 65.2448 45.2236 65.4561Z' fill='#59C5C7' stroke='#59C5C7' strokeWidth='2' strokeLinejoin='round'/>
            </svg>
        );
    }
}

export default ReferFriend;
