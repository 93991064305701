import _ from 'lodash';
import React from 'react';
import { Button, Form, Nav, Spinner } from 'react-bootstrap';
import { AiOutlinePercentage } from 'react-icons/ai';
import { HiOutlineNewspaper } from 'react-icons/hi';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IdProofTypeEnum from '~Api/Investor/IdProofTypeEnum';
import IIndividual from '~Api/Investor/IIndividual';
import IInvestor from '~Api/Investor/IInvestor';
import InvestorTypeEnum from '~Api/Investor/InvestorTypeEnum';
import { currentInvestorGetAction } from '~Investor/actions';
import { currentInvestorAccountSelector, currentInvestorSelector } from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import {
    currentUserGetAction,
    emailChangeModalShowAction,
    passwordChangeModalShowAction,
} from '~User/actions';
import IUser from '~User/IUser';
import { currentUserSelector } from '~User/selectors';
import Layout from './Layout';
import './my-details.less';

const investorTypeLabels: any = {
    [InvestorTypeEnum.Company]: 'Company',
    [InvestorTypeEnum.Individual]: 'Individual',
    [InvestorTypeEnum.JointIndividuals]: 'Joint Individuals',
    [InvestorTypeEnum.TrustCompany]: 'Trust - Company',
    [InvestorTypeEnum.TrustIndividual]: 'Trust - Individual',
};

const idProofTypeLabels: any = {
    [IdProofTypeEnum.DriverLicence]: 'Driver Licence',
    [IdProofTypeEnum.Passport]: 'Passport',
};

interface IPropsSelector {
    currentInvestor: IInvestor;
    currentUser: IUser;
    incomeTrustAccount: IAccount;
    marketplaceAccount: IAccount;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
    currentUserGet: () => void;
    emailChangeModalShow: () => void;
    passwordChangeModalShow: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class MyDetails extends React.Component<Props> {
    public componentDidMount(): void {
        const { currentInvestor, currentUser } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }

        if (!currentUser) {
            this.props.currentUserGet();
        }
    }

    public render(): JSX.Element {
        const { currentInvestor, currentUser, incomeTrustAccount, marketplaceAccount } = this.props;

        if (!currentInvestor || !currentUser) {
            return (
                <Layout section='get-details'>
                    <h1>My Details</h1>
                    <div className='content-box'>
                        <Spinner animation='border' />
                    </div>
                </Layout>
            );
        }

        const isCompleted: boolean = (marketplaceAccount && marketplaceAccount.isCompleted) || (incomeTrustAccount && incomeTrustAccount.isCompleted);

        const leftNav: JSX.Element = (
            <Nav className='flex-column'>
                {marketplaceAccount && <Nav.Item as={NavLink} exact={true} to='/marketplace'><HiOutlineNewspaper />Marketplace</Nav.Item>}
                {/* {incomeTrustAccount && <Nav.Item as={NavLink} exact={true} to='/income-trust'><AiOutlinePercentage />Income Trust</Nav.Item>} */}
            </Nav>
        );

        const trustBlock: JSX.Element = [InvestorTypeEnum.TrustCompany, InvestorTypeEnum.TrustIndividual].includes(currentInvestor.type) && (
            <div className='trust'>
                <h3>Trust</h3>
                <Form.Group className='name'>
                    <Form.Label>Name of trust</Form.Label>
                    <Form.Control defaultValue={currentInvestor.trust.name} disabled={true} plaintext={true} />
                </Form.Group>
                <Form.Group className='tax-file-number'>
                    <Form.Label>TFN</Form.Label>
                    <Form.Control defaultValue={currentInvestor.trust.taxFileNumber} disabled={true} plaintext={true} />
                </Form.Group>
                <Form.Group className='australian-business-number'>
                    <Form.Label>ABN</Form.Label>
                    <Form.Control defaultValue={currentInvestor.trust.australianBusinessNumber} disabled={true} plaintext={true} />
                </Form.Group>
            </div>
        );

        const companyBlock: JSX.Element = [InvestorTypeEnum.TrustCompany, InvestorTypeEnum.Company].includes(currentInvestor.type) && (
            <div className='company'>
                <h3>Company</h3>
                <Form.Group className='name'>
                    <Form.Label>Company name</Form.Label>
                    <Form.Control defaultValue={currentInvestor.company.name} disabled={true} plaintext={true} />
                </Form.Group>
                <Form.Group className='tax-file-number'>
                    <Form.Label>TFN</Form.Label>
                    <Form.Control defaultValue={currentInvestor.company.taxFileNumber} disabled={true} plaintext={true} />
                </Form.Group>
                <Form.Group className='australian-company-number'>
                    <Form.Label>A.C.N.</Form.Label>
                    <Form.Control defaultValue={currentInvestor.company.australianCompanyNumber} disabled={true} plaintext={true} />
                </Form.Group>
                <Form.Group className='street-address'>
                    <Form.Label>Street address</Form.Label>
                    <Form.Control defaultValue={currentInvestor.company.streetAddress} disabled={true} plaintext={true} />
                </Form.Group>
                <Form.Group className='suburb'>
                    <Form.Label>Suburb</Form.Label>
                    <Form.Control defaultValue={currentInvestor.company.suburb} disabled={true} plaintext={true} />
                </Form.Group>
                <Form.Group className='postcode'>
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control defaultValue={currentInvestor.company.postcode} disabled={true} plaintext={true} />
                </Form.Group>
                <Form.Group className='state'>
                    <Form.Label>State</Form.Label>
                    <Form.Control defaultValue={currentInvestor.company.state} disabled={true} plaintext={true} />
                </Form.Group>
            </div>
        );

        const individualsBlock: JSX.Element[] = currentInvestor.individuals.map((individual: IIndividual, index: number) => {
            const emailBlock: JSX.Element = [InvestorTypeEnum.Company, InvestorTypeEnum.TrustCompany].includes(currentInvestor.type) && (
                <Form.Group className='email'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control defaultValue={individual.email} disabled={true} plaintext={true} />
                </Form.Group>
            );

            let idProofDetailsBlock: JSX.Element = null;

            switch (individual.idProofType) {
                case IdProofTypeEnum.DriverLicence:
                    idProofDetailsBlock = (
                        <React.Fragment>
                            <Form.Group className='id-proof-type'>
                                <Form.Label>Proof ID</Form.Label>
                                <Form.Control defaultValue='Australian Driver Licence' disabled={true} plaintext={true} />
                            </Form.Group>
                            <Form.Group className='id-proof-number'>
                                <Form.Label>Licence Number</Form.Label>
                                <Form.Control defaultValue={individual.idProofNumber || '-'} disabled={true} plaintext={true} />
                            </Form.Group>
                            <Form.Group className='driver-licence-card-number'>
                                <Form.Label>Licence Card Number</Form.Label>
                                <Form.Control defaultValue={individual.driverLicenceCardNumber || '-'} disabled={true} plaintext={true} />
                            </Form.Group>
                            <Form.Group className='id-proof-expiry-date'>
                                <Form.Label>Licence Expiry Date</Form.Label>
                                <Form.Control defaultValue={individual.idProofExpiryDate || '-'} disabled={true} plaintext={true} />
                            </Form.Group>
                            <Form.Group className='driver-licence-state'>
                                <Form.Label>Licence State</Form.Label>
                                <Form.Control defaultValue={individual.driverLicenceState || '-'} disabled={true} plaintext={true} />
                            </Form.Group>
                        </React.Fragment>
                    );

                    break;
                case IdProofTypeEnum.Passport:
                    idProofDetailsBlock = (
                        <React.Fragment>
                            <Form.Group className='id-proof-type'>
                                <Form.Label>Proof ID</Form.Label>
                                <Form.Control defaultValue='Passport' disabled={true} plaintext={true} />
                            </Form.Group>
                            <Form.Group className='id-proof-number'>
                                <Form.Label>Passport Number</Form.Label>
                                <Form.Control defaultValue={individual.idProofNumber || '-'} disabled={true} plaintext={true} />
                            </Form.Group>
                            <Form.Group className='id-proof-expiry-date'>
                                <Form.Label>Passport Expiry Date</Form.Label>
                                <Form.Control defaultValue={individual.idProofExpiryDate || '-'} disabled={true} plaintext={true} />
                            </Form.Group>
                        </React.Fragment>
                    );

                    break;
            }

            return (
                <div className='individual' key={index}>
                    <h3>{[InvestorTypeEnum.Company, InvestorTypeEnum.TrustCompany].includes(currentInvestor.type) ? 'Director' : 'Individual'} {index + 1}</h3>
                    <Form.Group className='full-name'>
                        <Form.Label className='full-name'>Full name</Form.Label>
                        <Form.Control defaultValue={`${individual.firstName || ''} ${individual.lastName || ''}`} disabled={true} plaintext={true} />
                    </Form.Group>
                    {emailBlock}
                    <Form.Group className='date-of-birth'>
                        <Form.Label>DOB</Form.Label>
                        <Form.Control defaultValue={individual.dateOfBirth || '-'} disabled={true} plaintext={true} />
                    </Form.Group>
                    <Form.Group className='tax-file-number'>
                        <Form.Label>TFN</Form.Label>
                        <Form.Control defaultValue={individual.taxFileNumber || '-'} disabled={true} plaintext={true} />
                    </Form.Group>
                    <Form.Group className='street-address'>
                        <Form.Label>Street address</Form.Label>
                        <Form.Control defaultValue={individual.streetAddress || '-'} disabled={true} plaintext={true} />
                    </Form.Group>
                    <Form.Group className='suburb'>
                        <Form.Label>Suburb</Form.Label>
                        <Form.Control defaultValue={individual.suburb || '-'} disabled={true} plaintext={true} />
                    </Form.Group>
                    <Form.Group className='postcode'>
                        <Form.Label>Postcode</Form.Label>
                        <Form.Control defaultValue={individual.postcode || '-'} disabled={true} plaintext={true} />
                    </Form.Group>
                    <Form.Group className='state'>
                        <Form.Label>State</Form.Label>
                        <Form.Control defaultValue={individual.state || '-'} disabled={true} plaintext={true} />
                    </Form.Group>
                    <Form.Group className='id-proof-type'>
                        <Form.Label>Proof ID</Form.Label>
                        <Form.Control defaultValue={individual.idProofType ? idProofTypeLabels[individual.idProofType] : '-'} disabled={true} plaintext={true} />
                    </Form.Group>
                    {idProofDetailsBlock}
                </div>
            );
        });

        const investorDetailsBlock: JSX.Element = isCompleted && (
            <div className='content-box investor-details'>
                <h2>Investor Details</h2>
                <Form.Group className='type-of-investor'>
                    <Form.Label>Type of investor</Form.Label>
                    <Form.Control defaultValue={investorTypeLabels[currentInvestor.type]} disabled={true} plaintext={true} />
                </Form.Group>
                {trustBlock}
                {companyBlock}
                {individualsBlock}
            </div>
        );

        const bankAccountDetailsBlock: JSX.Element = isCompleted && (
            <div className='content-box bank-account'>
                <h2>Bank Account</h2>
                <Form.Group className='bank-name'>
                    <Form.Label>Bank name</Form.Label>
                    <Form.Control defaultValue={currentInvestor.bankAccount?.bankName || '-'} disabled={true} plaintext={true} />
                </Form.Group>
                <Form.Group className='account-name'>
                    <Form.Label>Account name</Form.Label>
                    <Form.Control defaultValue={currentInvestor.bankAccount?.accountName || '-'} disabled={true} plaintext={true} />
                </Form.Group>
                <Form.Group className='bsb'>
                    <Form.Label>BSB</Form.Label>
                    <Form.Control defaultValue={currentInvestor.bankAccount?.bsb || '-'} disabled={true} plaintext={true} />
                </Form.Group>
                <Form.Group className='account-number'>
                    <Form.Label>Account number</Form.Label>
                    <Form.Control defaultValue={currentInvestor.bankAccount?.accountNumber || '-'} disabled={true} plaintext={true} />
                </Form.Group>
            </div>
        );

        const completeRegistrationBlock: JSX.Element = !isCompleted && (
            <Link className='complete-registration' to='/invest/marketplace/details'>
                <Button>Complete Registration</Button>
            </Link>
        );

        return (
            <Layout
                section='get-details'
                leftNav={leftNav}
            >
                <h1>My Details</h1>
                <div className='content-box basic-information'>
                    <h2>Basic Information</h2>
                    <Form.Group className='full-name'>
                        <Form.Label>Full name</Form.Label>
                        <Form.Control defaultValue={currentUser.name} disabled={true} plaintext={true} />
                    </Form.Group>
                    <Form.Group className='email'>
                        <Button className='change' onClick={this.props.emailChangeModalShow} variant='link'>Change</Button>
                        <Form.Label>Email</Form.Label>
                        <Form.Control defaultValue={currentUser.email} disabled={true} plaintext={true} />
                    </Form.Group>
                    <Form.Group className='phone'>
                        <Form.Label>Phone</Form.Label>
                        <Form.Control defaultValue={currentUser.phone} disabled={true} plaintext={true} />
                    </Form.Group>
                    <Form.Group className='password'>
                        <Button className='change' onClick={this.props.passwordChangeModalShow} variant='link'>Change</Button>
                        <Form.Label>Password</Form.Label>
                        <Form.Text>**********</Form.Text>
                    </Form.Group>
                </div>
                {completeRegistrationBlock}
                {investorDetailsBlock}
                {bankAccountDetailsBlock}
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        currentUser: currentUserSelector(state),
        incomeTrustAccount: currentInvestorAccountSelector(state, AccountTypeEnum.IncomeTrust),
        marketplaceAccount: currentInvestorAccountSelector(state, AccountTypeEnum.Marketplace),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
        currentUserGet: () => dispatch(currentUserGetAction()),
        emailChangeModalShow: () => dispatch(emailChangeModalShowAction()),
        passwordChangeModalShow: () => dispatch(passwordChangeModalShowAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MyDetails);
