import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IDeal, { IDealErrors } from '~Api/Deal/IDeal';
import history from '~history';
import {
    dealAddAction,
    dealErrorSetAction,
    dealGetAction,
    dealValueSetAction,
} from '~Lead/actions';
import {
    dealSelector,
    errorsSelector,
    savingSelector,
    tokenSelector,
} from '~Lead/selectors';
import { IGlobalState } from '~reducer';
import { validateEmail } from '~validators';
import Layout from './Layout';
import IToken from '~Auth/IToken';

interface IPropsSelector {
    saving: boolean;
    deal: IDeal;
    errors: IDealErrors;
    borrowToken: IToken;
}

interface IPropsDispatch {
    dealAdd: () => void;
    dealGet: () => void;
    setError: (key: keyof IDealErrors, value: string) => void;
    setValue: (key: keyof IDeal, value: boolean|number|string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class BasicInformation extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickContinue = this.onClickContinue.bind(this);

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);

        this.validateEmail = this.validateEmail.bind(this);
        this.validateFirstName = this.validateFirstName.bind(this);
        this.validateLastName = this.validateLastName.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
    }

    public componentDidMount(): void {
        const { borrowToken, deal } = this.props;

        if (borrowToken && borrowToken.dealUuid && !deal.uuid) {
            this.props.dealGet();
        }
    }

    public render(): JSX.Element {
        const { saving, borrowToken, deal, errors } = this.props;

        if (borrowToken && borrowToken.dealUuid && !deal.uuid) {
            return (
                <Layout currentStep={1}>
                    <h3>Let&apos;s sort your quote out.</h3>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        return (
            <Layout currentStep={1}>
                <h3>Let&apos;s sort your quote out.</h3>
                <p className='intro'>
                    We promise this isn&apos;t going to hurt a bit. To prove it, let&apos;s start with just a few simple questions about yourself.
                </p>
                <Form>
                    <Form.Group className='first-name'>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control disabled={saving} isInvalid={!!errors.firstName} onBlur={this.validateFirstName} onChange={this.onChangeFirstName} type='text' value={deal.firstName || ''} />
                        {errors.firstName && <Form.Control.Feedback type='invalid'>{errors.firstName}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group className='last-name'>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control disabled={saving} isInvalid={!!errors.lastName} onBlur={this.validateLastName} onChange={this.onChangeLastName} type='text' value={deal.lastName || ''} />
                        {errors.lastName && <Form.Control.Feedback type='invalid'>{errors.lastName}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group className='email'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control disabled={saving} isInvalid={!!errors.email} onBlur={this.validateEmail} onChange={this.onChangeEmail} type='email' value={deal.email || ''} />
                        {errors.email && <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group className='phone'>
                        <Form.Label>Phone</Form.Label>
                        <Form.Control disabled={saving} isInvalid={!!errors.phone} onBlur={this.validatePhone} onChange={this.onChangePhone} type='text' value={deal.phone || ''} />
                        {errors.phone && <Form.Control.Feedback type='invalid'>{errors.phone}</Form.Control.Feedback>}
                    </Form.Group>
                </Form>
                <div className='actions'>
                    <Button className='continue' disabled={saving} onClick={this.onClickContinue} variant='primary'>
                        {saving && <Spinner animation='border' as='span' role='status' size='sm' />}
                        {!saving && 'Continue'}
                    </Button>
                </div>
            </Layout>
        );
    }

    private onClickContinue(): void {
        const { deal } = this.props;

        let valid: boolean = true;

        valid = this.validateEmail() && valid;
        valid = this.validateFirstName() && valid;
        valid = this.validateLastName() && valid;
        valid = this.validatePhone() && valid;

        if (!valid) {
            return;
        }

        if (!deal.uuid) {
            this.props.dealAdd();
        } else {
            history.push('/get-your-quote/loan-amount');
        }
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue('email', event.target.value.substr(0, 100));
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue('firstName', event.target.value.substr(0, 50));
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue('lastName', event.target.value.substr(0, 50));
    }

    private onChangePhone(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue('phone', event.target.value.substr(0, 20));
    }

    private validateEmail(): boolean {
        const { deal } = this.props;

        let error: string;
        if (!deal.email || deal.email.length === 0) {
            error = 'Please enter your email address';
        } else if (!validateEmail(deal.email)) {
            error = 'Please enter a valid email address';
        }

        this.props.setError('email', error);

        return !error;
    }

    private validateFirstName(): boolean {
        const { deal } = this.props;

        let error: string;

        if (!deal.firstName || deal.firstName.length === 0) {
            error = 'Please enter the first name';
        } else if (deal.firstName.length < 2) {
            error = 'Please enter at least 2 characters';
        }

        this.props.setError('firstName', error);

        return !error;
    }

    private validateLastName(): boolean {
        const { deal } = this.props;

        let error: string;

        if (!deal.lastName || deal.lastName.length === 0) {
            error = 'Please enter the last name';
        } else if (deal.lastName.length < 2) {
            error = 'Please enter at least 2 characters';
        }

        this.props.setError('lastName', error);

        return !error;
    }

    private validatePhone(): boolean {
        const { deal } = this.props;

        let error: string;

        if (!deal.phone || deal.phone.length === 0) {
            error = 'Please enter your phone number';
        }

        this.props.setError('phone', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        borrowToken: tokenSelector(state),
        deal: dealSelector(state),
        errors: errorsSelector(state),
        saving: savingSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        dealAdd: () => dispatch(dealAddAction('/get-your-quote/loan-amount')),
        dealGet: () => dispatch(dealGetAction()),
        setError: (key: keyof IDealErrors, value: string) => dispatch(dealErrorSetAction(key, value)),
        setValue: (key: keyof IDeal, value: boolean|number|string) => dispatch(dealValueSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BasicInformation);
