import { all, call, debounce, put, select, takeEvery } from '@redux-saga/core/effects';
import Cookies from 'js-cookie';
import {
    IDeal,
    IDealProperty,
} from '~Api/Deal';
import AddressVersionEnum from '~Api/Deal/AddressVersionEnum';
import IDealQuote from '~Api/Deal/IDealQuote';
import {
    parseDeal,
    parseDealQuote,
    parseProperty,
} from '~Api/Deal/parsers';
import {
    dealAddRequest,
    dealCompleteRequest,
    dealFileAddRequest,
    dealGetRequest,
    dealPropertiesAddRequest,
    dealPropertyDeleteRequest,
    dealPropertyUpdateRequest,
    dealQuotesListRequest,
    dealReferralsTokenRequest,
    dealReferredByRequest,
    dealUpdateRequest,
    dealsListRequest,
} from '~Api/Deal/requests';
import UiSourceEnum from '~Api/Deal/UiSourceEnum';
import history from '~history';
import { IFetchResponse } from '~utilities/fetch';
import {
    IDealAddAction,
    IDealCompleteAction,
    IDealGetAction,
    IDealQuotesListAction,
    IDealSendAction,
    IDealValueSetAction,
    IDealsListAction,
    IPropertyAddAction,
    IPropertyDeleteAction,
    IPropertySendAction,
    IPropertyValueSetAction,
    IReferrerTokenGetAction,
    dealQuotesSetAction,
    dealSendAction,
    dealSetAction,
    dealsSetAction,
    propertySendAction,
    propertySetAction,
} from './actions';
import LeadActionsEnum from './ActionsEnum';
import {
    dealSelector,
    propertySelector,
    tokenSelector,
} from './selectors';
import { verifyToken } from '~utilities/jwt';
import IToken from '~Auth/IToken';

interface IReferrer {
    token: string;
}

function* dealAdd(action: IDealAddAction): Iterator<unknown> {
    const landingPage: string = Cookies.getJSON('landing');

    const deal: IDeal = yield select(dealSelector);

    const rawDeal: IFetchResponse = yield call(dealAddRequest, {
        ...deal,
        landingPage,
        uiSource: UiSourceEnum.ExpressQuote,
    });

    const token: string = rawDeal.headers.get('Funding-Auth-Token');
    verifyToken(token);
    Cookies.set('jwt_token', token);

    const parsedDeal: IDeal = parseDeal(rawDeal.body);
    yield put(dealSetAction(parsedDeal));

    const referrer: IReferrer = Cookies.getJSON('dealReferrer');

    if (referrer && referrer.token) {
        yield call(dealReferredByRequest, parsedDeal.uuid, referrer.token);
    }

    history.push(action.successUrl);

    const property: IDealProperty = {
        addressVersion: AddressVersionEnum.V2,
        currentDebt: null,
        errors: {},
        estimatedValue: null,
        postcode: null,
        state: null,
        streetAddress: null,
        streetName: null,
        streetNumber: null,
        streetType: null,
        suburb: null,
        unitNumber: null,
        zoneType: null,
    };

    const rawProperty: IFetchResponse = yield call(dealPropertiesAddRequest, parsedDeal, property);
    const parsedProperty: IDealProperty = parseProperty(rawProperty.body);
    yield put(propertySetAction(parsedProperty));
}

function* dealComplete(action: IDealCompleteAction): Iterator<unknown> {
    const deal: IDeal = yield select(dealSelector);

    if (action.supportingDocuments) {
        for (const supportingDocument of Array.from(action.supportingDocuments)) {
            try {
                yield call(dealFileAddRequest, deal, supportingDocument);
            } catch {
                // We don't want a file add request failure to prevent us from completing the deal
            }
        }
    }

    yield call(dealCompleteRequest, deal, action.autoQuote);
    let dealQuotes: IDealQuote[] = [];

    if (action.autoQuote) {
        const dealQuotesListResponse: IFetchResponse = yield call(dealQuotesListRequest, deal.uuid);
        dealQuotes = yield Promise.all(dealQuotesListResponse.body.map(parseDealQuote));
    }

    if (action.autoQuote && dealQuotes.length === 1) {
        yield put(dealQuotesSetAction(dealQuotes));

        history.push(action.quoteUrl);
    } else {
        Cookies.remove('jwt_token');

        history.push(action.thanksUrl);
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* dealGet(action: IDealGetAction): Iterator<unknown> {
    const borrowToken: IToken = yield select(tokenSelector);
    if (borrowToken.dealUuid) {
        const rawDeal: IFetchResponse = yield call(dealGetRequest, borrowToken.dealUuid);
        const deal: IDeal = parseDeal(rawDeal.body);
        yield put(dealSetAction(deal));
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* dealSend(action: IDealSendAction): Iterator<unknown> {
    const deal: IDeal = yield select(dealSelector);
    if (deal && deal.uuid) {
        yield call(dealUpdateRequest, deal);
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* dealValueSet(action: IDealValueSetAction): Iterator<unknown> {
    yield put(dealSendAction());
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* dealsList(action: IDealsListAction): Iterator<unknown> {
    const dealsListResponse: IFetchResponse = yield call(dealsListRequest);
    const deals: IDeal[] = yield Promise.all(dealsListResponse.body.map(parseDeal));
    yield put(dealsSetAction(deals));
}

function* dealQuotesList(action: IDealQuotesListAction): Iterator<unknown> {
    const dealQuotesListResponse: IFetchResponse = yield call(dealQuotesListRequest, action.dealUuid);
    const dealQuotes: IDealQuote[] = yield Promise.all(dealQuotesListResponse.body.map(parseDealQuote));
    yield put(dealQuotesSetAction(dealQuotes));
}

function *referrerTokenGet(action: IReferrerTokenGetAction): Iterator<unknown> {
    const dealReferralsTokenResponse: IFetchResponse = yield call(dealReferralsTokenRequest, action.referralCode);

    Cookies.set('dealReferrer', dealReferralsTokenResponse.body, {
        expires: 30,
    });

    history.push('/borrow');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* propertyAdd(action: IPropertyAddAction): Iterator<unknown> {
    const deal: IDeal = yield select(dealSelector);
    const property: IDealProperty = {
        addressVersion: AddressVersionEnum.V2,
        currentDebt: null,
        errors: {},
        estimatedValue: null,
        postcode: null,
        state: null,
        streetAddress: null,
        streetName: null,
        streetNumber: null,
        streetType: null,
        suburb: null,
        unitNumber: null,
        zoneType: null,
    };
    const rawProperty: IFetchResponse = yield call(dealPropertiesAddRequest, deal, property);
    const parsedProperty: IDealProperty = parseProperty(rawProperty.body);
    yield put(propertySetAction(parsedProperty));
}

function* propertyDelete(action: IPropertyDeleteAction): Iterator<unknown> {
    yield call(dealPropertyDeleteRequest, action.uuid);
}

function* propertySend(action: IPropertySendAction): Iterator<unknown> {
    const property: IDealProperty = yield select(propertySelector, action.uuid);

    if (property && property.uuid) {
        yield call(dealPropertyUpdateRequest, property);
    }
}

function* propertyValueSet(action: IPropertyValueSetAction): Iterator<unknown> {
    yield put(propertySendAction(action.uuid));
}

export function* LeadSagas(): Iterator<unknown> {
    yield all([
        takeEvery(LeadActionsEnum.DealAdd, dealAdd),
        takeEvery(LeadActionsEnum.DealComplete, dealComplete),
        takeEvery(LeadActionsEnum.DealGet, dealGet),
        debounce(500, LeadActionsEnum.DealSend, dealSend),
        takeEvery(LeadActionsEnum.DealValueSet, dealValueSet),

        takeEvery(LeadActionsEnum.DealsList, dealsList),

        takeEvery(LeadActionsEnum.DealQuotesList, dealQuotesList),

        debounce(200, LeadActionsEnum.ReferrerTokenGet, referrerTokenGet),

        takeEvery(LeadActionsEnum.PropertiesAdd, propertyAdd),

        takeEvery(LeadActionsEnum.PropertyDelete, propertyDelete),
        debounce(500, LeadActionsEnum.PropertySend, propertySend),
        takeEvery(LeadActionsEnum.PropertyValueSet, propertyValueSet),
    ]);
}
