import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import IInvestor from '~Api/Investor/IInvestor';
import { authRegisterMarketplaceQualifierAnswerSetAction } from '~Auth/actions';
import { authRegisterMarketplaceQualifierAnswersSelector } from '~Auth/selectors';
import history from '~history';
import Radio from '~UI/Radio';
import {
    publicCurrentInvestorAccountMarketplaceQualifierSendAction,
    publicCurrentInvestorGetAction,
} from '~Public/actions';
import { publicCurrentInvestorSelector } from '~Public/selectors';
import Layout from './Layout';
import Steps from './Steps';
import IMarketplaceQualifier from '~Api/Investor/IMarketplaceQualifier';

enum AnswerRatingEnum {
    Amber = 'AMBER',
    Green = 'GREEN',
    Red = 'RED',
}

interface IAnswer {
    label: string;
    rating: AnswerRatingEnum;
}

interface IQuestion {
    answers: IAnswer[];
    description: string;
    heading: string;
    question: string;
}

const qualifierQuestions: IQuestion[] = [
    {
        answers: [
            {
                label: 'Capital Preservation',
                rating: AnswerRatingEnum.Green,
            },
            {
                label: 'Income Distribution',
                rating: AnswerRatingEnum.Green,
            },
            {
                label: 'Capital Growth',
                rating: AnswerRatingEnum.Red,
            },
            {
                label: 'Capital Guaranteed',
                rating: AnswerRatingEnum.Red,
            },
        ],
        description: 'The Trust provides investors with access to investments in loans, secured by a first mortgage, facilitated by Funding.com.au. Investors select which loans they want to invest in based on information provided and receive monthly interest distributions when the Borrowers of those Loans make repayments.',
        heading: 'Investment Objective',
        question: 'My primary investment objective is:',
    },
    {
        answers: [
            {
                label: 'Small Allocation (<25%)',
                rating: AnswerRatingEnum.Green,
            },
            {
                label: 'Core Component (25-75%)',
                rating: AnswerRatingEnum.Green,
            },
            {
                label: 'Standalone Solution (75-100%)',
                rating: AnswerRatingEnum.Red,
            },
        ],
        description: 'The Trust\'s investment strategy is to provide investors access to a variety of real estate loans secured by a registered first mortgage as a core or small component of a portfolio.',
        heading: 'Product Use',
        question: 'Based on my overall ivnestment portfolio, this investment would represent a:',
    },
    {
        answers: [
            {
                label: 'Short (≤ 2 years)',
                rating: AnswerRatingEnum.Green,
            },
            {
                label: 'Medium (> 2 years)',
                rating: AnswerRatingEnum.Amber,
            },
            {
                label: 'Long (> 8 years)',
                rating: AnswerRatingEnum.Red,
            },
        ],
        description: 'Investment timeframes vary from 1 to 36 months on a loan by loan basis, with investors selecting which loans (and therefore investment timeframe) they invest in.',
        heading: 'Investment Timeframe',
        question: 'My investment timeframe is:',
    },
    {
        answers: [
            {
                label: 'Low',
                rating: AnswerRatingEnum.Green,
            },
            {
                label: 'Medium',
                rating: AnswerRatingEnum.Green,
            },
            {
                label: 'High',
                rating: AnswerRatingEnum.Amber,
            },
            {
                label: 'Very High',
                rating: AnswerRatingEnum.Red,
            },
        ],
        description: 'The Trust\'s investment strategy is to provide investors access to a variety of loans with maximum loan-to-valuation ratios (LVR) of 70%. The current average LVR across the portfolio of loans is approximately 50%.',
        heading: 'Risk and Return Profile',
        question: 'My risk (ability to bear loss) and return profile is:',
    },
    {
        answers: [
            {
                label: 'Annually or Longer',
                rating: AnswerRatingEnum.Green,
            },
            {
                label: 'Quarterly',
                rating: AnswerRatingEnum.Green,
            },
            {
                label: 'Monthly',
                rating: AnswerRatingEnum.Amber,
            },
            {
                label: 'Weekly',
                rating: AnswerRatingEnum.Red,
            },
            {
                label: 'Daily',
                rating: AnswerRatingEnum.Red,
            },
        ],
        description: 'Investors select their preferred investment timeframe by selecting which loans they invest in. Investors can only withdraw their capital (other than monthly distributions) at the conclusion of the loan term once the loan has been repaid by the borrower. The current average loan term across the portfolio is approximately 10 months.',
        heading: 'Need to Withdraw Money',
        question: 'My need to access capital is:',
    },
];

export default function Qualifier(): ReactElement {
    const currentInvestor: IInvestor = useSelector(publicCurrentInvestorSelector);
    const qualifierAnswers: { [key in number]: number } = useSelector(authRegisterMarketplaceQualifierAnswersSelector);

    const [ showComplianceMessage, setShowComplianceMessage ] = useState<boolean>(false);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!currentInvestor) {
            dispatch(publicCurrentInvestorGetAction());
        }
    }, []);

    const onChangeQuestionAnswer: (questionIndex: number, questionAnswerIndex: number) => void = useCallback((questionIndex: number, questionAnswerIndex: number) => {
        dispatch(authRegisterMarketplaceQualifierAnswerSetAction(questionIndex, questionAnswerIndex));
    }, []);

    const onClickPrevious: () => void = useCallback(() => {
        if (showComplianceMessage) {
            setShowComplianceMessage(false);
            return;
        }

        history.push('/invest/bank-account');
    }, [showComplianceMessage]);

    const onClickContinue: () => void = useCallback(() => {
        let amberResponseCount: number = 0;
        let redResponseCount: number = 0;

        const qualifierResponses: IMarketplaceQualifier[] = [];

        _.each(qualifierAnswers, (qualifierAnswerIndex: number, qualifierQuestionIndex: string): void => {
            const qualifierQuestion: IQuestion = qualifierQuestions[Number(qualifierQuestionIndex)];
            const answer: IAnswer = qualifierQuestion.answers[qualifierAnswerIndex];

            if (answer.rating === AnswerRatingEnum.Amber) {
                amberResponseCount++;
            } else if (answer.rating === AnswerRatingEnum.Red) {
                redResponseCount++;
            }

            qualifierResponses.push({
                answerLabel: answer.label,
                questionDescription: qualifierQuestion.description,
                questionHeading: qualifierQuestion.heading,
                questionQuestion: qualifierQuestion.question,
            });
        });

        if (amberResponseCount >= 3 || redResponseCount >= 1) {
            window.scrollTo(0, 0);
            setShowComplianceMessage(true);
            return;
        }

        dispatch(publicCurrentInvestorAccountMarketplaceQualifierSendAction(qualifierResponses));

        history.push('/invest/pds');
    }, [qualifierAnswers]);

    if (!currentInvestor) {
        return (
            <Layout>
                <Steps currentStep={4} />
                <Container className='register-marketplace-qualifier'>
                    <Spinner animation='border' />
                </Container>
            </Layout>
        );
    }

    if (showComplianceMessage) {
        return (
            <Layout>
                <Steps currentStep={4} />

                <Container className='register-marketplace-qualifier unsuitable'>
                    <h3>Product Match</h3>
                    <p>Based on your provided answers this investment may not be suitable for you.</p>
                    <p>Please contact us on <a href='tel:1300443319'>1300 44 33 19</a> or at <a href='mailto:info@funding.com.au'>info@funding.com.au</a> to discuss.</p>
                    <p>You can refer to our <a href='https://www.funding.com.au/tmd-invest/' rel='noreferrer' target='_blank'>Target Market Determination</a> document for further information.</p>
                    <Button className='previous' onClick={onClickPrevious} variant='primary'>Previous</Button>
                </Container>
            </Layout>
        );
    }

    let hasRemainingQuestion: boolean = false;

    const questionsBlock: JSX.Element[] = [];

    _.each(qualifierQuestions, (qualifierQuestion: IQuestion, questionIndex: number): void => {
        const { [questionIndex]: currentAnswerIndex = null } = qualifierAnswers;

        const selectedAnswer: IAnswer = qualifierQuestion.answers[currentAnswerIndex];

        if (!selectedAnswer) {
            hasRemainingQuestion = true;
        }

        const answersBlock: JSX.Element[] = qualifierQuestion.answers.map((answer: IAnswer, answerIndex: number) => {
            const onChangeLoopQuestionAnswer: () => void = () => onChangeQuestionAnswer(questionIndex, answerIndex);

            let answerBlockClassName: string = '';

            if (currentAnswerIndex === answerIndex) {
                answerBlockClassName += ' selected';
            }

            switch (answer.rating) {
                case AnswerRatingEnum.Amber:
                    answerBlockClassName += ' amber';
                    break;
                case AnswerRatingEnum.Green:
                    answerBlockClassName += ' green';
                    break;
                case AnswerRatingEnum.Red:
                    answerBlockClassName += ' red';
                    break;
            }

            return (
                <Radio
                    checked={currentAnswerIndex === answerIndex}
                    className={answerBlockClassName}
                    key={answerIndex}
                    label={answer.label}
                    onChange={onChangeLoopQuestionAnswer}
                />
            );
        });

        const questionBlock: JSX.Element = (
            <Form.Group>
                <Form.Label>{qualifierQuestion.heading}</Form.Label>
                <p className='group-description'>{qualifierQuestion.description}</p>
                <p className='group-question'>{qualifierQuestion.question}</p>
                {answersBlock}
            </Form.Group>
        );

        questionsBlock.push(questionBlock);
    });

    return (
        <Layout>
            <Steps currentStep={4} />

            <Container className='register-marketplace-qualifier'>
                <h3>Product Match</h3>
                <p className='intro'>What are your objectives for our investment platform?</p>
                {questionsBlock}
                <Button className='previous' onClick={onClickPrevious} variant='primary'>Previous</Button>
                <Button className='continue' onClick={onClickContinue} disabled={hasRemainingQuestion} variant='primary'>Continue</Button>
            </Container>
        </Layout>
    );
}
