import React from 'react';
import { Container } from 'react-bootstrap';
import { FiPhone } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import Badges from '~UI/Badges';
import Logo from '~UI/Logo';
import './public.less';
import { Helmet } from 'react-helmet';

interface IProps {
    children: JSX.Element | JSX.Element[];
    contactEmail?: string;
    footer?: JSX.Element;
    showBadges?: boolean;
    showLogin?: boolean;
    showQuestions?: boolean;
}

type Props = IProps;

class Layout extends React.Component<Props> {
    public static defaultProps = {
        contactEmail: 'info@funding.com.au',
        showBadges: true,
        showLogin: true,
        showQuestions: true,
    };

    public render(): JSX.Element {
        const { children, contactEmail, footer, showBadges, showLogin, showQuestions } = this.props;

        const questionsBlock: JSX.Element = showQuestions && (
            <div className='questions-wrapper'>
                <Container className='questions'>
                    <h4>Got any questions?</h4>
                    <p>
                        We are here to help you, get in touch<br />
                        for more info on <a href='tel:1300443319' className='phone'>1300 44 33 19</a> or at <a href={`mailto:${contactEmail}`} className='email'>{contactEmail}</a>
                    </p>
                </Container>
            </div>
        );

        const footerBlock: JSX.Element = footer ? footer : (
            <p>
                <strong>DISCLAIMER</strong>
                {' '}* Any investment rate of return and terms displayed are specific to the individual mortgages and therefore subject to the performance of that individual mortgage investment.
                {' '}Any rate of return and income is targeted, are not guaranteed and are shown net of any fees.
                {' '}Borrowers: Lending criteria, fees and conditions apply. For consumer or personal loans please refer to our Credit Guide for more information.
                {' '}Australian Credit Licence ACL 483665.
                {' '}Platform Investors:
                {' '}The following disclaimers apply to investors in the Funding Investment Trust, ARSN 616 185 276 (“the Trust”).
                {' '}Funding.com.au Pty Ltd ACN 603 756 547 is the manager of the Trust and authorised representative (no. 1239776) of Funding Capital Pty Ltd ACN 639 230 345 (AFSL 523 247).
                {' '}Melbourne Securities Corporation Ltd ACN 160 326 545 (AFSL 428 289) is the trustee and responsible entity of the Trust.
                {' '}It is important for you to read the Product Disclosure Statement (PDS) for the Trust before you make any investment decision.
                {' '}The PDS is available on our website or by calling 1300 44 33 19.
                {' '}You should consider carefully whether or not investing in the Trust is appropriate for you.
                {' '}The rates of return from the Trust are targeted and not guaranteed and are determined by the future revenue of the Trust and may achieve lower than expected returns.
                {' '}Past performance is not a reliable indicator of future performance.
                {' '}Investors risk losing some or all of their principal investment.
                {' '}The investment is not a bank deposit.
                {' '}IMPORTANT: Information provided is general information only and should not be taken as legal or financial advice.
                {' '}It does not consider the specific needs, investment objectives or financial situation of any particular investor.
                {' '}Please refer to the <a href='https://www.funding.com.au/fsg/' target='_blank' rel='noreferrer'>Financial Services Guide</a> for more information.
                {' '}Direct Investors:
                {' '}The direct mortgage investments do not form part of the Funding Investment Trust, ARSN 616 185 276 and the PDS does not apply to such investments.
                {' '}See <a href='https://www.funding.com.au/privacy-policy/' target='_blank' rel='noreferrer'>Privacy</a>, <a href='https://www.funding.com.au/terms-of-use/' target='_blank' rel='noreferrer'>Terms</a> and <a href='https://www.funding.com.au/credit-guide/' target='_blank' rel='noreferrer'>Credit Guide</a> for more details.
            </p>
        );

        return (
            <div className='public'>
                <Helmet>
                    <title>Funding.com.au</title>
                </Helmet>
                <div className='d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 top-bar'>
                    <Container>
                        <a href='https://www.funding.com.au/'><Logo className='logo' /></a>
                        {showLogin && <Link to='/' className='btn btn-primary login'>Login</Link>}
                        <a href='tel:1300443319' className='phone'><FiPhone /> 1300 44 33 19</a>
                    </Container>
                </div>
                {children}
                {showBadges && <Badges/>}
                {questionsBlock}
                <div className='footer-wrapper'>
                    <Container className='footer'>
                        {footerBlock}
                    </Container>
                </div>
            </div>
        );
    }
}

export default Layout;
