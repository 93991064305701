enum ActionsEnum {
    AdviserRegister = 'ADVISER/REGISTER',
    AdviserRegisterErrorSet = 'ADVISER/REGISTER/ERROR/SET',
    AdviserRegisterErrorsSet = 'ADVISER/REGISTER/ERRORS/SET',
    AdviserRegisterInProgressSet = 'ADVISER/REGISTER/IN_PROGRESS/SET',

    AggregatorsGet = 'AGGREGATORS/GET',
    AggregatorsSet = 'AGGREGATORS/SET',

    CountriesGet = 'COUNTRIES/GET',
    CountriesSet = 'COUNTRIES/SET',

    CurrentBrokerRegister = 'CURRENT_BROKER/REGISTER',

    CurrentInvestorGet = 'CURRENT_INVESTOR/GET',
    CurrentInvestorSet = 'CURRENT_INVESTOR/SET',
    CurrentInvestorSend = 'CURRENT_INVESTOR/SEND',

    CurrentInvestorValueSet = 'CURRENT_INVESTOR/VALUE/SET',

    CurrentInvestorAccountMarketplaceQualifierSend = 'CURRENT_INVESTOR/ACCOUNT/MARKETPLACE/SEND',
    CurrentInvestorBankAccountValueSet = 'CURRENT_INVESTOR/BANK_ACCOUNT/VALUE/SET',
    CurrentInvestorCompanyValueSet = 'CURRENT_INVESTOR/COMPANY/VALUE/SET',
    CurrentInvestorIndividualValueSet = 'CURRENT_INVESTOR/INDIVIDUAL/VALUE/SET',
    CurrentInvestorTrustValueSet = 'CURRENT_INVESTOR/TRUST/VALUE/SET',

    CurrentInvestorAccountIncomeTrustComplete = 'CURRENT_INVESTOR/ACCOUNT/INCOME_TRUST/COMPLETE',
    CurrentInvestorAccountIncomeTrustInterestPaymentStrategySet = 'CURRENT_INVESTOR/ACCOUNT/INCOME_TRUST/INTEREST_PAYMENT_STRATEGY/SET',

    CurrentInvestorAccountMarketplaceComplete = 'CURRENT_INVESTOR/ACCOUNT/MARKETPLACE/COMPLETE',

    InvestReferred = 'PUBLIC/INVEST/REFERRED',

    ReferralPartnerRegister = 'REFERRAL_PARTNER/REGISTER',
}

export default ActionsEnum;
