import { Action } from 'redux';
import IBroker from '~Api/Broker/IBroker';
import StateEnum from '~Api/Broker/StateEnum';
import {
    IDeal,
    IDealProperty,
} from '~Api/Deal';
import { IDictionary } from '~utilities/IDictionary';
import BrokerActionsEnum from './ActionsEnum';

export interface IBrokerCheckEmailAction extends Action {
    email: string;
}

export function brokerCheckEmailAction(email: string): IBrokerCheckEmailAction {
    return {
        email,
        type: BrokerActionsEnum.BrokerCheckEmail,
    };
}

export interface IBrokerDealAddAction extends Action {
    deal: IDeal;
    properties: IDealProperty[];
    supportingDocuments: File[];
}

export function brokerDealAddAction(deal: IDeal, properties: IDealProperty[], supportingDocuments: File[]): IBrokerDealAddAction {
    return {
        deal,
        properties,
        supportingDocuments,
        type: BrokerActionsEnum.BrokerDealAdd,
    };
}

export interface IBrokerVerifyEmailAction extends Action {
    code: string;
}

export function brokerVerifyEmailAction(code: string): IBrokerVerifyEmailAction {
    return {
        code,
        type: BrokerActionsEnum.BrokerVerifyEmail,
    };
}

export interface IBrokerInProgressAction extends Action {
    inProgress: boolean;
}

export function brokerInProgressAction(inProgress: boolean): IBrokerInProgressAction {
    return {
        inProgress,
        type: BrokerActionsEnum.BrokerInProgress,
    };
}

export interface IBrokerLoginErrorsSetAction extends Action {
    errors: IDictionary<string>;
}

export function brokerLoginErrorsSetAction(errors: IDictionary<string>): IBrokerLoginErrorsSetAction {
    return {
        errors,
        type: BrokerActionsEnum.BrokerLoginErrorsSet,
    };
}

export interface IBrokerLoginErrorSetAction extends Action {
    key: string;
    value: string;
}

export function brokerLoginErrorSetAction(key: string, value: string): IBrokerLoginErrorSetAction {
    return {
        key,
        type: BrokerActionsEnum.BrokerLoginErrorSet,
        value,
    };
}

export interface IBrokerSetAction extends Action {
    broker: IBroker;
}

export function brokerSetAction(broker: IBroker): IBrokerSetAction {
    return {
        broker,
        type: BrokerActionsEnum.BrokerSet,
    };
}

export interface IBrokerPasswordAction extends Action {
    password: string;
}

export function brokerPasswordAction(password: string): IBrokerPasswordAction {
    return {
        password,
        type: BrokerActionsEnum.BrokerPassword,
    };
}

export interface ICurrentBrokerAddressUpdateAction extends Action {
    postalAddress: string;
    postcode: string;
    state: StateEnum;
    suburb: string;
    uuid: string;
}

export function currentBrokerAddressUpdateAction(uuid: string, postalAddress: string, postcode: string, state: StateEnum, suburb: string): ICurrentBrokerAddressUpdateAction {
    return {
        postalAddress,
        postcode,
        state,
        suburb,
        type: BrokerActionsEnum.BrokerAddressUpdate,
        uuid,
    };
}

export interface ICurrentBrokerAddressUpdateModalShowAction extends Action {
    showModal: boolean;
}

export function currentBrokerAddressUpdateModalShowAction(showModal: boolean): ICurrentBrokerAddressUpdateModalShowAction {
    return {
        showModal,
        type: BrokerActionsEnum.BrokerAddressUpdateModalShow,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface ICurrentBrokerGetAction extends Action {}

export function currentBrokerGetAction(): ICurrentBrokerGetAction {
    return {
        type: BrokerActionsEnum.CurrentBrokerGet,
    };
}
