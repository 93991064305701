import ApprovalStatusEnum from './ApprovalStatusEnum';
import BrokerTypeEnum from './BrokerTypeEnum';
import IAggregator from './IAggregator';
import IBroker from './IBroker';
import RegistrationSourceEnum from './RegistrationSourceEnum';
import StateEnum from './StateEnum';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseAggregator(raw: any): IAggregator {
    return {
        name: raw.name,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseBroker(raw: any): IBroker {
    return {
        aclNumber: raw.aclNumber,
        aggregatorUuid: raw.aggregatorUuid,
        approvalStatus: raw.approvalStatus as ApprovalStatusEnum,
        code: raw.code,
        companyName: raw.companyName,
        email: raw.email,
        firstName: raw.firstName,
        hasBeenBankrupted: raw.hasBeenBankrupted,
        hasBeenConvicted: raw.hasBeenConvicted,
        hasIndemnityInsurance: raw.hasIndemnityInsurance,
        lastName: raw.lastName,
        linkedInUrl: raw.linkedInUrl,
        phone: raw.phone,
        postalAddress: raw.postalAddress,
        postcode: raw.postcode,
        registrationSource: raw.registrationSource as RegistrationSourceEnum,
        state: raw.state as StateEnum,
        suburb: raw.suburb,
        type: raw.type as BrokerTypeEnum,
        uuid: raw.uuid,
    };
}
