import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import IAuthUser from '~Auth/IAuthUser';
import RoleEnum from '~Auth/RoleEnum';
import { authCurrentUserSelector } from '~Auth/selectors';
import history from '~history';
import Invest from '~Public/Invest';
import { IGlobalState } from '~reducer';
import ScrollToTop from '~ScrollToTop';
import VerifyEmail from '~User/VerifyEmail';
import VerifyEmailVerified from '~User/VerifyEmailVerified';
import { currentInvestorGetAction } from './actions';
import IncomeTrust from './IncomeTrust';
import Marketplace from './Marketplace';
import MyDetails from './MyDetails';
import { currentInvestorAccountSelector, currentInvestorSelector } from './selectors';

interface IPropsSelector {
    currentInvestor: IInvestor;
    currentUser: IAuthUser;
    incomeTrustAccount: IAccount;
    marketplaceAccount: IAccount;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class InvestorRouter extends React.Component<Props> {
    public componentDidMount(): void {
        const { currentInvestor } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }
    }

    public render(): JSX.Element {
        const { currentInvestor, currentUser, incomeTrustAccount, marketplaceAccount } = this.props;

        if (currentUser.role !== RoleEnum.Investor || !currentInvestor) {
            return null;
        }

        let defaultRedirect: string = '/';

        if (marketplaceAccount) {
            defaultRedirect = '/marketplace';
        } else if (incomeTrustAccount) {
            defaultRedirect = '/income-trust';
        }

        const marketplaceAccountRedirects: JSX.Element[] = marketplaceAccount && [
            <Redirect key='annual-statements' exact={true} from='/annual-statements' to='/marketplace/annual-statements' />,
            <Redirect key='disclosure-documents' exact={true} from='/disclosure-documents' to='/marketplace/disclosure-documents' />,
            <Redirect key='help-centre' exact={true} from='/help-centre' to='/marketplace/help-centre' />,
            <Redirect key='investment' exact={true} from='/investment/:uuid' to='/marketplace/investment/:uuid' />,
            <Redirect key='my-details' exact={true} from='/my-details' to='/marketplace/my-details' />,
            <Redirect key='portfolio' exact={true} from='/portfolio' to='/marketplace/portfolio' />,
            <Redirect key='refer-a-friend' exact={true} from='/refer-a-friend' to='/marketplace/refer-a-friend' />,
            <Redirect key='super-portfolio' exact={true} from='/super-portfolio' to='/marketplace/super-portfolio' />,
            <Redirect key='top-up-withdraw' exact={true} from='/top-up-withdraw' to='/marketplace/top-up-withdraw' />,
            <Redirect key='transactions' exact={true} from='/transactions' to='/marketplace/transactions' />,
        ];

        return (
            <Router history={history}>
                <ScrollToTop />
                <Switch>
                    <Route exact={true} path='/my-details' component={MyDetails} />

                    <Route exact={true} path='/verify-email/verified' component={VerifyEmailVerified} />
                    <Route exact={true} path='/verify-email/:code' component={VerifyEmail} />

                    <Route path='/invest' component={Invest} />

                    <Route path='/income-trust' component={IncomeTrust} />

                    <Route path='/marketplace' component={Marketplace} />
                    {marketplaceAccountRedirects}

                    <Redirect to={defaultRedirect} />
                </Switch>
            </Router>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        currentUser: authCurrentUserSelector(state),
        incomeTrustAccount: currentInvestorAccountSelector(state, AccountTypeEnum.IncomeTrust),
        marketplaceAccount: currentInvestorAccountSelector(state, AccountTypeEnum.Marketplace),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InvestorRouter);
