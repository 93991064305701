import React from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { dealQuoteConvertAction } from './actions';
import Layout from './Layout';

interface IMatch {
    code: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsDispatch {
    convert: () => void;
}

type Props = IProps & IPropsDispatch;

class QuoteConvert extends React.Component<Props> {
    public componentDidMount() {
        this.props.convert();
    }

    public render(): JSX.Element {
        return (
            <Layout containerClass='quote' stepNumber={0}>
                <Spinner animation='border'/>
            </Layout>
        );
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        convert: () => dispatch(dealQuoteConvertAction(ownProps.match.params.code)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(QuoteConvert);
