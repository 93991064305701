import IAccount from './IAccount';
import IBankAccount from './IBankAccount';
import ICompany from './ICompany';
import IdProofTypeEnum from './IdProofTypeEnum';
import IIncomeTrust from './IIncomeTrust';
import IIndividual from './IIndividual';
import IInvestor from './IInvestor';
import IMarketplaceQualifier from './IMarketplaceQualifier';
import ITrust from './ITrust';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformAccount(account: IAccount): any {
    return {
        incomeTrust: account.incomeTrust && transformIncomeTrust(account.incomeTrust),
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformBankAccount(bankAccount: IBankAccount): any {
    return {
        accountName: bankAccount.accountName,
        accountNumber: bankAccount.accountNumber,
        bankName: bankAccount.bankName,
        bsb: bankAccount.bsb,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformCompany(company: ICompany): any {
    return {
        australianCompanyNumber: company.australianCompanyNumber,
        name: company.name,
        postcode: company.postcode,
        state: company.state,
        streetAddress: company.streetAddress,
        suburb: company.suburb,
        taxFileNumber: company.taxFileNumber,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformIncomeTrust(incomeTrust: IIncomeTrust): any {
    return {
        interestPaymentStrategy: incomeTrust.interestPaymentStrategy,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformIndividual(individual: IIndividual): any {
    let idProofType: string = null;
    switch (individual.idProofType) {
        case IdProofTypeEnum.DriverLicence:
            idProofType = 'DRIVER_LICENSE';
            break;
        case IdProofTypeEnum.Passport:
            idProofType = 'PASSPORT';
            break;
    }

    return {
        country: individual.country,
        dateOfBirth: individual.dateOfBirth,
        driverLicenceCardNumber: individual.driverLicenceCardNumber,
        driverLicenceState: individual.driverLicenceState,
        email: individual.email,
        firstName: individual.firstName,
        idProofExpiryDate: individual.idProofExpiryDate,
        idProofNumber: individual.idProofNumber,
        idProofType,
        lastName: individual.lastName,
        postcode: individual.postcode,
        residentType: individual.residentType,
        state: individual.state,
        streetAddress: individual.streetAddress,
        suburb: individual.suburb,
        taxFileNumber: individual.taxFileNumber,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformInvestor(investor: IInvestor): any {
    return {
        availableFundsType: investor.availableFundsType,
        bankAccount: investor.bankAccount && transformBankAccount(investor.bankAccount),
        company: investor.company && transformCompany(investor.company),
        individuals: investor.individuals.map(transformIndividual),
        landingPage: investor.landingPage,
        name: investor.name,
        trust: investor.trust && transformTrust(investor.trust),
        type: investor.type,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformMarketplaceQualifier(marketplaceQualifier: IMarketplaceQualifier): any {
    return {
        answerLabel: marketplaceQualifier.answerLabel,
        questionDescription: marketplaceQualifier.questionDescription,
        questionHeading: marketplaceQualifier.questionHeading,
        questionQuestion: marketplaceQualifier.questionQuestion,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformTrust(trust: ITrust): any {
    return {
        australianBusinessNumber: trust.australianBusinessNumber,
        name: trust.name,
        taxFileNumber: trust.taxFileNumber,
    };
}
