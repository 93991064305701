import React from 'react';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { FaLock, FaUser } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { IGlobalState } from '~reducer';
import { validateEmail } from '~validators';
import {
    authLoginAction,
    authLoginErrorSetAction,
} from './actions';
import Layout from './Layout';
import {
    authLoginErrorsSelector,
    authLoginInProgressSelector,
} from './selectors';

interface IState {
    email: string;
    password: string;
}

interface IPropsSelector {
    loginErrors: any;
    loginInProgress: boolean;
}

interface IPropsDispatch {
    login: (email: string, password: string) => void;
    setError: (key: string, value: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Login extends React.Component<Props, IState> {
    public state: IState = {
        email: null,
        password: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onClickLogin = this.onClickLogin.bind(this);

        this.validateEmail = this.validateEmail.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
    }

    public render(): JSX.Element {
        const { loginErrors, loginInProgress } = this.props;
        const { email, password } = this.state;

        return (
            <Layout section='login'>
                <Form>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend><FaUser/></InputGroup.Prepend>
                            <Form.Control
                                isInvalid={!!loginErrors.email}
                                name='email'
                                onBlur={this.validateEmail}
                                onChange={this.onChangeEmail}
                                placeholder='Email'
                                type='email'
                                value={email || ''}
                            />
                            {!!loginErrors.email && <Form.Control.Feedback type='invalid'>{loginErrors.email}</Form.Control.Feedback>}
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend><FaLock/></InputGroup.Prepend>
                            <Form.Control
                                isInvalid={!!loginErrors.password}
                                name='password'
                                onBlur={this.validatePassword}
                                onChange={this.onChangePassword}
                                placeholder='Password'
                                type='password'
                                value={password || ''}
                            />
                            {!!loginErrors.password && <Form.Control.Feedback type='invalid'>{loginErrors.password}</Form.Control.Feedback>}
                        </InputGroup>
                    </Form.Group>
                    <Link className='forgot-password' to='/forgot-password'>I forgot my password</Link>
                    <Button className='continue' disabled={loginInProgress} onClick={this.onClickLogin} variant='primary'>
                        {loginInProgress && <Spinner animation='border' as='span' role='status' size='sm' />}
                        {' '}Login
                    </Button>
                    <p className='sign-up'>Don&apos;t have an account? <Link to='/invest'>Sign up</Link></p>
                </Form>
            </Layout>
        );
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            email: event.target.value,
        });
    }

    private onChangePassword(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            password: event.target.value,
        });
    }

    private onClickLogin() {
        const { email, password } = this.state;

        let valid = true;
        valid = this.validateEmail() && valid;
        valid = this.validatePassword() && valid;

        if (!valid) {
            return;
        }

        this.props.login(email, password);
    }

    private validateEmail(): boolean {
        const { email } = this.state;

        let error: string;

        if (!email || email.length === 0) {
            error = 'Please enter your email address';
        } else if (!validateEmail(email)) {
            error = 'Please enter a valid email address';
        }

        this.props.setError('email', error);

        return !error;
    }

    private validatePassword(): boolean {
        const { password } = this.state;

        let error: string;

        if (!password || password.length === 0) {
            error = 'Please enter a password';
        }

        this.props.setError('password', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        loginErrors: authLoginErrorsSelector(state),
        loginInProgress: authLoginInProgressSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        login: (email: string, password: string) => dispatch(authLoginAction(email, password)),
        setError: (key: string, value: string) => dispatch(authLoginErrorSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Login);
