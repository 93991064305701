import React from 'react';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { FaUser } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { IGlobalState } from '~reducer';
import { validateEmail } from '~validators';
import {
    authMagicLinkCreateAction,
    authMagicLinkCreateErrorSetAction,
} from './actions';
import Layout from './Layout';
import {
    authMagicLinkCreateErrorsSelector,
    authMagicLinkCreateInProgressSelector,
} from './selectors';

interface IState {
    email: string;
}

interface IPropsSelector {
    magicLinkCreateErrors: any;
    magicLinkCreateInProgress: boolean;
}

interface IPropsDispatch {
    magicLinkCreate: (email: string) => void;
    setError: (key: string, value: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Login extends React.Component<Props, IState> {
    public state: IState = {
        email: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onClickSubmit = this.onClickSubmit.bind(this);

        this.validateEmail = this.validateEmail.bind(this);
    }

    public render(): JSX.Element {
        const { magicLinkCreateErrors, magicLinkCreateInProgress } = this.props;
        const { email } = this.state;

        return (
            <Layout section='forgot-password'>
                <Form>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend><FaUser/></InputGroup.Prepend>
                            <Form.Control
                                isInvalid={!!magicLinkCreateErrors.email}
                                name='email'
                                onBlur={this.validateEmail}
                                onChange={this.onChangeEmail}
                                placeholder='Email'
                                type='email'
                                value={email || ''}
                            />
                            {!!magicLinkCreateErrors.email && <Form.Control.Feedback type='invalid'>{magicLinkCreateErrors.email}</Form.Control.Feedback>}
                        </InputGroup>
                    </Form.Group>
                    <Button className='continue' disabled={magicLinkCreateInProgress} onClick={this.onClickSubmit} variant='primary'>
                        {magicLinkCreateInProgress && <Spinner animation='border' as='span' role='status' size='sm' />}
                        {' '}Submit
                    </Button>
                    <Link className='login' to='/'>Log In</Link>
                </Form>
            </Layout>
        );
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            email: event.target.value,
        });
    }

    private onClickSubmit() {
        const { email } = this.state;

        let valid = true;
        valid = this.validateEmail() && valid;

        if (!valid) {
            return;
        }

        this.props.magicLinkCreate(email);
    }

    private validateEmail(): boolean {
        const { email } = this.state;

        let error: string;

        if (!email || email.length === 0) {
            error = 'Please enter your email address';
        } else if (!validateEmail(email)) {
            error = 'Please enter a valid email address';
        }

        this.props.setError('email', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        magicLinkCreateErrors: authMagicLinkCreateErrorsSelector(state),
        magicLinkCreateInProgress: authMagicLinkCreateInProgressSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        magicLinkCreate: (email: string) => dispatch(authMagicLinkCreateAction(email)),
        setError: (key: string, value: string) => dispatch(authMagicLinkCreateErrorSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Login);
