import React from 'react';
import Lottie, { Options } from 'react-lottie';
import success from '~UI/success.json';
import Layout from './Layout';

class Thanks extends React.Component {
    public render(): JSX.Element {
        const options: Options = {
            animationData: success,
            autoplay: true,
            loop: false,
        };

        return (
            <Layout containerClass='thanks' stepNumber={4}>
                <h3>Congratulations!</h3>
                <p className='intro'>Thankyou for submitting your loan application.</p>
                <Lottie height={180} options={options} width={180} />
            </Layout>
        );
    }
}

export default Thanks;
