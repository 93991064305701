import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import history from '~history';
import { currentInvestorGetAction } from '~Investor/actions';
import {
    currentInvestorAccountSelector,
    currentInvestorSelector,
} from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import Radio from '~UI/Radio';
import Layout from './Layout';

enum TypeOfInvestorEnum {
    Retail = 'RETAIL',
    WholesaleOrSophisticated = 'WHOLESALE_OR_SOPHISTICATED',
}

interface IState {
    typeOfInvestor: TypeOfInvestorEnum;
}

interface IPropsSelector {
    currentInvestor: IInvestor;
    marketplaceAccount: IAccount;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Qualifier extends React.Component<Props, IState> {
    public state: IState = {
        typeOfInvestor: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeTypeOfInvestor = this.onChangeTypeOfInvestor.bind(this);

        this.onClickContinue = this.onClickContinue.bind(this);
    }

    public componentDidMount(): void {
        const { currentInvestor } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }
    }

    public render(): JSX.Element {
        const { typeOfInvestor } = this.state;

        const { currentInvestor } = this.props;

        if (!currentInvestor) {
            return (
                <Layout section='qualifier'>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        return (
            <Layout section='qualifier'>
                <h1>Registration</h1>
                <div className='content-box'>
                    <h3>Type of Investor</h3>
                    <p className='intro'>Which type of investor are you?</p>
                    <Form>
                        <Form.Group>
                            <div className='form-cards form-cards-vertical'>
                                <div className={`form-card ${typeOfInvestor === TypeOfInvestorEnum.WholesaleOrSophisticated ? 'form-card-selected' : ''}`}>
                                    <Radio
                                        checked={typeOfInvestor === TypeOfInvestorEnum.WholesaleOrSophisticated}
                                        label='Wholesale or Sophisticated Investor'
                                        value={TypeOfInvestorEnum.WholesaleOrSophisticated}
                                        onChange={this.onChangeTypeOfInvestor}
                                    />
                                    <div className='card-description'>
                                        <p>A person or entity:</p>
                                        <ul>
                                            <li>who invests at least $500,000;</li>
                                            <li>or, has net assets of at least $2.5 million;</li>
                                            <li>or, had a gross income for each of the last two financial years of at least $250,000.</li>
                                        </ul>
                                        <p>A supporting accountant’s certificate is required.</p>
                                    </div>
                                </div>
                                <div className={`form-card ${typeOfInvestor === TypeOfInvestorEnum.Retail ? 'form-card-selected' : ''}`}>
                                    <Radio
                                        checked={typeOfInvestor === TypeOfInvestorEnum.Retail}
                                        label='Retail Investor'
                                        value={TypeOfInvestorEnum.Retail}
                                        onChange={this.onChangeTypeOfInvestor}
                                    />
                                    <div className='card-description'>Not a Wholesale or Sophisticated investor.</div>
                                </div>
                            </div>
                        </Form.Group>
                        <Button className='continue' onClick={this.onClickContinue} variant='primary' disabled={typeOfInvestor === null}>Continue</Button>
                    </Form>
                </div>
            </Layout>
        );
    }

    private onClickContinue(): void {
        const { marketplaceAccount } = this.props;
        const { typeOfInvestor } = this.state;

        if (typeOfInvestor === TypeOfInvestorEnum.Retail) {
            history.push('/income-trust/register/retail-investor');
            return;
        }

        if (!marketplaceAccount.isCompleted) {
            history.push('/income-trust/register/details');
            return;
        }

        history.push('/income-trust/register/distribution-preference');
    }

    private onChangeTypeOfInvestor(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ typeOfInvestor: event.target.value as TypeOfInvestorEnum });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        marketplaceAccount: currentInvestorAccountSelector(state, AccountTypeEnum.Marketplace),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Qualifier);
