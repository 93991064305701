import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IInvestor from '~Api/Investor/IInvestor';
import IAuthUser from '~Auth/IAuthUser';
import RoleEnum from '~Auth/RoleEnum';
import { authCurrentUserSelector } from '~Auth/selectors';
import history from '~history';
import { currentInvestorGetAction } from '~Investor/actions';
import MarketplaceRegister from '~Investor/Marketplace/Register';
import {
    currentInvestorAccountSelector,
    currentInvestorSelector,
} from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import ScrollToTop from '~ScrollToTop';
import Dashboard from './Dashboard';
import DisclosureDocuments from './DisclosureDocuments';
import HelpCentre from './HelpCentre';
import Investment from './Investment';
import Portfolio from './Portfolio';
import ReferFriend from './ReferFriend';
import TopUpWithdraw from './TopUpWithdraw';
import Transactions from './Transactions';
import Statements from './Statements';

interface IPropsSelector {
    currentInvestor: IInvestor;
    currentUser: IAuthUser;
    marketplaceAccount: IAccount;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class MarketplaceRouter extends React.Component<Props> {
    public componentDidMount(): void {
        const { currentInvestor } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }
    }

    public render(): JSX.Element {
        const { currentInvestor, currentUser, marketplaceAccount } = this.props;

        if (currentUser.role !== RoleEnum.Investor || !currentInvestor) {
            return null;
        }

        if (!marketplaceAccount) {
            return (
                <Router history={history}>
                    <ScrollToTop />
                    <Switch>
                        <Route path='/marketplace/register' component={MarketplaceRegister} />
                        <Redirect to='/marketplace/register'/>
                    </Switch>
                </Router>
            );
        }

        return (
            <Router history={history}>
                <ScrollToTop />
                <Switch>
                    <Route exact={true} path='/marketplace' component={Dashboard} />
                    <Route exact={true} path='/marketplace/help-centre' component={HelpCentre} />
                    <Route exact={true} path='/marketplace/investment/:uuid' component={Investment} />
                    <Route exact={true} path='/marketplace/portfolio' component={Portfolio} />
                    <Route exact={true} path='/marketplace/statements' component={Statements} />
                    <Route exact={true} path='/marketplace/disclosure-documents' component={DisclosureDocuments} />
                    <Route exact={true} path='/marketplace/portfolio' component={Portfolio} />
                    <Route exact={true} path='/marketplace/top-up-withdraw' component={TopUpWithdraw} />
                    <Route exact={true} path='/marketplace/transactions' component={Transactions} />
                    <Route exact={true} path='/marketplace/refer-a-friend' component={ReferFriend} />

                    <Redirect exact={true} from='/marketplace/annual-statements' to='/marketplace/statements' />

                    <Redirect to='/' />
                </Switch>
            </Router>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        currentUser: authCurrentUserSelector(state),
        marketplaceAccount: currentInvestorAccountSelector(state, AccountTypeEnum.Marketplace),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MarketplaceRouter);
