import _ from 'lodash';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { IconType } from 'react-icons';
import { GrDocumentVerified, GrUser, GrUserManager } from 'react-icons/gr';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { IApplication } from '~Api/Application';
import BorrowerTypeEnum from '~Api/Application/BorrowerTypeEnum';
import IApplicationBorrower from '~Api/Application/IApplicationBorrower';
import { IDeal, IDealProperty } from '~Api/Deal';
import { IGlobalState } from '~reducer';
import {
    applicationBorrowerTypeAction,
    borrowerValueSetAction,
    dealGetAction,
} from './actions';
import Layout from './Layout';
import {
    applicationSelector,
    currentDealSelector,
    primaryBorrowerSelector,
    propertiesSelector,
    savingSelector,
} from './selectors';

interface IType {
    icon: IconType;
    label: string;
    value: BorrowerTypeEnum;
}

const types: IType[] = [
    {
        icon: GrUserManager,
        label: 'Company',
        value: BorrowerTypeEnum.Company,
    },
    {
        icon: GrUser,
        label: 'Individual',
        value: BorrowerTypeEnum.Individual,
    },
    {
        icon: GrDocumentVerified,
        label: 'Trust',
        value: BorrowerTypeEnum.Trust,
    },
];

interface IState {
    type: BorrowerTypeEnum;
}

interface IPropsSelector {
    application: IApplication;
    deal: IDeal;
    primaryBorrower: IApplicationBorrower;
    properties: IDealProperty[];
    saving: boolean;
}

interface IPropsDispatch {
    continue: () => void;
    dealGet: () => void;
    valueSet: (uuid: string, field: keyof IApplicationBorrower, value: any) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Borrowers extends React.Component<Props, IState> {
    public state: IState = {
        type: null,
    };

    constructor(props: any) {
        super(props);

        this.onClickBorrowerType = this.onClickBorrowerType.bind(this);
    }

    public componentDidMount() {
        const { application, deal } = this.props;

        if (!application || !deal) {
            this.props.dealGet();
        }
    }

    public render(): JSX.Element {
        const { application, deal, primaryBorrower, saving } = this.props;

        if (!application || !deal) {
            return (
                <Layout containerClass='borrower-type' stepNumber={3}>
                    <h3>Borrower Details</h3>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        if (!primaryBorrower) {
            return (
                <Layout containerClass='borrower-type' stepNumber={3}>
                    <h3>Borrower Details</h3>
                    <p>Oops there's a problem with your application. Please contact us.</p>
                </Layout>
            );
        }

        const typeBlock: JSX.Element[] = _.values(types).map((loopType: IType) => {
            const onClick = () => this.onClickBorrowerType(loopType.value);

            return (
                <Button
                    className={`borrower-type-button ${primaryBorrower.type === loopType.value ? 'active' : ''}`}
                    disabled={saving}
                    key={loopType.value}
                    onClick={onClick}
                    variant='default'
                >
                    <loopType.icon/>
                    {loopType.label}
                </Button>
            );
        });

        return (
            <Layout containerClass='borrower-type' stepNumber={3}>
                <h3>Borrower Details</h3>
                <p className='intro'>Who is borrowing?</p>
                {typeBlock}
                <div className='actions'>
                    <Link className='previous' to='/application/properties'><Button disabled={saving} variant='primary'>Previous</Button></Link>
                    <Button className='continue' disabled={saving} onClick={this.props.continue} variant='primary'>Continue</Button>
                </div>
            </Layout>
        );
    }

    private onClickBorrowerType(type: BorrowerTypeEnum) {
        const { primaryBorrower } = this.props;

        this.props.valueSet(primaryBorrower.uuid, 'type', type);
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        application: applicationSelector(state),
        deal: currentDealSelector(state),
        primaryBorrower: primaryBorrowerSelector(state),
        properties: propertiesSelector(state),
        saving: savingSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        continue: () => dispatch(applicationBorrowerTypeAction()),
        dealGet: () => dispatch(dealGetAction()),
        valueSet: (uuid: string, field: keyof IApplicationBorrower, value: any) => dispatch(borrowerValueSetAction(uuid, field, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Borrowers);
