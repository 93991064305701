import { all, call, debounce, put, takeEvery } from '@redux-saga/core/effects';
import Cookies from 'js-cookie';
import IInvestor from '~Api/Investor/IInvestor';
import { parseInvestor } from '~Api/Investor/parsers';
import {
    investorGetRequest,
    investorReferredByRequest,
    investorUpdateRequest,
} from '~Api/Investor/requests';
import history from '~history';
import { publicCurrentInvestorSetAction } from '~Public/actions';
import { IFetchResponse } from '~utilities/fetch';
import {
    IAuthLoginAction,
    IAuthLogoutAction,
    IAuthMagicLinkCreateAction,
    IAuthMagicLinkRedeemAction,
    IAuthRegisterInvestorAction,
    authLoginErrorsSetAction,
    authLoginInProgressSetAction,
    authMagicLinkCreateErrorsSetAction,
    authMagicLinkCreateInProgressSetAction,
    authMagicLinkRedeemErrorSetAction,
    authRegisterErrorsSetAction,
    authRegisterInProgressSetAction,
    authTokenSetAction,
} from './actions';
import AuthActionsEnum from './ActionsEnum';
import {
    authLoginRequest,
    authMagicLinkCreateRequest,
    authMagicLinkRedeemRequest,
    authRegisterRequest,
} from './requests';
import { verifyToken } from '~utilities/jwt';

function* login(action: IAuthLoginAction): Iterator<unknown> {
    yield put(authLoginInProgressSetAction(true));

    const rawUser: IFetchResponse = yield call(authLoginRequest, action.email, action.password);

    if (rawUser.status === 422) {
        yield put(authLoginErrorsSetAction(rawUser.body));
    } else if (rawUser.status === 200) {
        const token: string = rawUser.headers.get('Funding-Auth-Token');
        verifyToken(token);

        yield put(authTokenSetAction(token));

        Cookies.set('currentUser', {
            token,
        });

        history.push('/');
    }

    yield put(authLoginInProgressSetAction(false));
}

function* logout(action: IAuthLogoutAction): Iterator<unknown> {
    yield put(authTokenSetAction(null));

    Cookies.remove('currentUser');

    (document as any).location = action.redirect || '/';
}

function* magicLinkCreate(action: IAuthMagicLinkCreateAction): Iterator<unknown> {
    yield put(authMagicLinkCreateInProgressSetAction(true));

    const rawMagicLinkCreateResult: IFetchResponse = yield call(authMagicLinkCreateRequest, action.email);

    if (rawMagicLinkCreateResult.status === 422) {
        yield put(authMagicLinkCreateErrorsSetAction(rawMagicLinkCreateResult.body));
    } else if (rawMagicLinkCreateResult.status === 200) {
        history.push('/forgot-password/sent');
    }

    yield put(authMagicLinkCreateInProgressSetAction(false));
}

function* magicLinkRedeem(action: IAuthMagicLinkRedeemAction): Iterator<unknown> {
    const rawMagicLinkRedeemResult: IFetchResponse = yield call(authMagicLinkRedeemRequest, action.code);

    if (rawMagicLinkRedeemResult.status !== 200) {
        yield put(authMagicLinkRedeemErrorSetAction('Sorry, that link has expired or is invalid'));
    } else if (rawMagicLinkRedeemResult.status === 200) {
        const token: string = rawMagicLinkRedeemResult.headers.get('Funding-Auth-Token');
        verifyToken(token);

        yield put(authTokenSetAction(token));

        Cookies.set('currentUser', {
            token,
        });

        history.push('/');
    }
}

function* registerInvestor(action: IAuthRegisterInvestorAction): Iterator<unknown> {
    yield put(authRegisterInProgressSetAction(true));

    const landingPage: string = Cookies.getJSON('landing');
    const rawRegisterResult: IFetchResponse = yield call(authRegisterRequest, {
        accountType: action.accountType,
        email: action.email,
        firstName: action.firstName,
        landingPage,
        lastName: action.lastName,
        password: action.password,
        phone: action.phone,
        role: 'INVESTOR',
    });

    if (rawRegisterResult.status === 422) {
        yield put(authRegisterErrorsSetAction(rawRegisterResult.body));
    } else if (rawRegisterResult.status === 200) {
        const token: string = rawRegisterResult.headers.get('Funding-Auth-Token');
        const decodedToken: any = verifyToken(token);

        yield put(authTokenSetAction(token));

        const expires: Date = new Date(decodedToken.exp * 1000);
        Cookies.set('currentUser', { token }, { expires });

        const rawInvestor: IFetchResponse = yield call(investorGetRequest, decodedToken.investorUuids[0]);
        const investor: IInvestor = parseInvestor(rawInvestor.body);

        investor.name = `${action.firstName} ${action.lastName}`;

        yield put(publicCurrentInvestorSetAction(investor));

        yield call(investorUpdateRequest, investor);

        const refer: any = Cookies.getJSON('refer');
        if (refer && refer.token) {
            yield call(investorReferredByRequest, investor, refer.token);
        }

        history.push(action.successUrl);
    }

    yield put(authRegisterInProgressSetAction(false));
}

export function* AuthSagas(): Iterator<unknown> {
    yield all([
        takeEvery(AuthActionsEnum.Login, login),
        takeEvery(AuthActionsEnum.Logout, logout),
        debounce(200, AuthActionsEnum.MagicLinkCreate, magicLinkCreate),
        debounce(200, AuthActionsEnum.MagicLinkRedeem, magicLinkRedeem),
        takeEvery(AuthActionsEnum.RegisterInvestor, registerInvestor),
    ]);
}
