import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import history from '~history';
import { completeSignupModalShowSetAction } from '~Investor/actions';

interface IProps {
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsDispatch {
    hideSignupModal: () => void;
}

type Props = IProps & IPropsDispatch;

class CompleteSignupModal extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickComplete = this.onClickComplete.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;

        return (
            <Modal
                dialogClassName='investor-complete-signup-modal'
                onHide={this.props.onCancel}
                show={isOpen}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Complete Signup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Please complete the signup process to Top Up and Invest. Click "Complete" to go to the signup process or "Cancel" to continue exploring.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='outline-secondary' onClick={this.onClickComplete}>Complete</Button>
                    <Button variant='outline-secondary' onClick={this.props.onCancel}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    private onClickComplete(): void {
        history.push('/invest/marketplace/details');
        this.props.hideSignupModal();
    }
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        hideSignupModal: () => dispatch(completeSignupModalShowSetAction(false)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(CompleteSignupModal);
