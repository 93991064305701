import React from 'react';
import { Router as ReactRouter, Redirect, Switch } from 'react-router-dom';
import history from '~history';

class Router extends React.Component {
    public render(): JSX.Element {
        return (
            <ReactRouter history={history}>
                <Switch>
                    <Redirect to='/get-your-quote' />
                </Switch>
            </ReactRouter>
        );
    }
}

export default Router;
