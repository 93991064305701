import { Action } from 'redux';
import IAggregator from '~Api/Broker/IAggregator';
import IBroker from '~Api/Broker/IBroker';
import IBankAccount from '~Api/Investor/IBankAccount';
import ICompany from '~Api/Investor/ICompany';
import IIndividual from '~Api/Investor/IIndividual';
import IInvestor from '~Api/Investor/IInvestor';
import InterestPaymentStrategyEnum from '~Api/Investor/InterestPaymentStrategyEnum';
import ITrust from '~Api/Investor/ITrust';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import ActionsEnum from './ActionsEnum';
import ICountry from './ICountry';
import IAdviser from '~Api/Adviser/IAdviser';
import { IDictionary } from '~utilities/IDictionary';
import IMarketplaceQualifier from '~Api/Investor/IMarketplaceQualifier';

// tslint:disable-next-line: no-empty-interface
export interface IPublicAggregatorsGetAction extends Action {}

export function publicAggregatorsGetAction(): IPublicAggregatorsGetAction {
    return {
        type: ActionsEnum.AggregatorsGet,
    };
}

export interface IPublicAggregatorsSetAction extends Action {
    aggregators: IAggregator[];
}

export function publicAggregatorsSetAction(aggregators: IAggregator[]): IPublicAggregatorsSetAction {
    return {
        aggregators,
        type: ActionsEnum.AggregatorsSet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IPublicCountriesGetAction extends Action {}

export function publicCountriesGetAction(): IPublicCountriesGetAction {
    return {
        type: ActionsEnum.CountriesGet,
    };
}

export interface IPublicCountriesSetAction extends Action {
    countries: ICountry[];
}

export function publicCountriesSetAction(countries: ICountry[]): IPublicCountriesSetAction {
    return {
        countries,
        type: ActionsEnum.CountriesSet,
    };
}

export interface IPublicAdviserRegisterAction extends Action {
    adviser: IAdviser;
}

export function publicAdviserRegisterAction(adviser: IAdviser): IPublicAdviserRegisterAction {
    return {
        adviser,
        type: ActionsEnum.AdviserRegister,
    };
}

export interface IPublicAdviserRegisterErrorSetAction extends Action {
    key: string;
    value: string;
}

export function publicAdviserRegisterErrorSetAction(key: string, value: string): IPublicAdviserRegisterErrorSetAction {
    return {
        key,
        type: ActionsEnum.AdviserRegisterErrorSet,
        value,
    };
}

export interface IPublicAdviserRegisterErrorsSetAction extends Action {
    errors: IDictionary<string>;
}

export function publicAdviserRegisterErrorsSetAction(errors: IDictionary<string>): IPublicAdviserRegisterErrorsSetAction {
    return {
        errors,
        type: ActionsEnum.AdviserRegisterErrorsSet,
    };
}

export interface IPublicAdviserRegisterInProgressSetAction extends Action {
    inProgress: boolean;
}

export function publicAdviserRegisterInProgressSetAction(inProgress: boolean): IPublicAdviserRegisterInProgressSetAction {
    return {
        inProgress,
        type: ActionsEnum.AdviserRegisterInProgressSet,
    };
}

export interface IPublicCurrentBrokerRegisterAction extends Action {
    broker: IBroker;
    insuranceProof: File;
    membershipDocument: File;
    photoId: File;
}

export function publicCurrentBrokerRegisterAction(broker: IBroker, insuranceProof: File, membershipDocument: File, photoId: File): IPublicCurrentBrokerRegisterAction {
    return {
        broker,
        insuranceProof,
        membershipDocument,
        photoId,
        type: ActionsEnum.CurrentBrokerRegister,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IPublicCurrentInvestorGetAction extends Action {}

export function publicCurrentInvestorGetAction(): IPublicCurrentInvestorGetAction {
    return {
        type: ActionsEnum.CurrentInvestorGet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IPublicCurrentInvestorSendAction extends Action {}

export function publicCurrentInvestorSendAction(): IPublicCurrentInvestorSendAction {
    return {
        type: ActionsEnum.CurrentInvestorSend,
    };
}

export interface IPublicCurrentInvestorSetAction extends Action {
    investor: IInvestor;
}

export function publicCurrentInvestorSetAction(investor: IInvestor): IPublicCurrentInvestorSetAction {
    return {
        investor,
        type: ActionsEnum.CurrentInvestorSet,
    };
}

export interface IPublicCurrentInvestorValueSetAction extends Action {
    key: keyof IInvestor;
    value: any;
}

export function publicCurrentInvestorValueSetAction(key: keyof IInvestor, value: any): IPublicCurrentInvestorValueSetAction {
    return {
        key,
        type: ActionsEnum.CurrentInvestorValueSet,
        value,
    };
}

// tslint:disable-next-line:no-empty-interface
export interface IPublicCurrentInvestorAccountIncomeTrustCompleteAction extends Action {
    imContent: string,
}

export function publicCurrentInvestorAccountIncomeTrustCompleteAction(imContent: string): IPublicCurrentInvestorAccountIncomeTrustCompleteAction {
    return {
        imContent,
        type: ActionsEnum.CurrentInvestorAccountIncomeTrustComplete,
    };
}

export interface IPublicCurrentInvestorAccountIncomeTrustInterestPaymentStrategySetAction extends Action {
    interestPaymentStrategy: InterestPaymentStrategyEnum;
}

export function publicCurrentInvestorAccountIncomeTrustInterestPaymentStrategySetAction(interestPaymentStrategy: InterestPaymentStrategyEnum): IPublicCurrentInvestorAccountIncomeTrustInterestPaymentStrategySetAction {
    return {
        interestPaymentStrategy,
        type: ActionsEnum.CurrentInvestorAccountIncomeTrustInterestPaymentStrategySet,
    };
}

// tslint:disable-next-line:no-empty-interface
export interface IPublicCurrentInvestorAccountMarketplaceCompleteAction extends Action {
    pdsContent: string,
}

export function publicCurrentInvestorAccountMarketplaceCompleteAction(pdsContent: string): IPublicCurrentInvestorAccountMarketplaceCompleteAction {
    return {
        pdsContent,
        type: ActionsEnum.CurrentInvestorAccountMarketplaceComplete,
    };
}

export interface IPublicCurrentInvestorBankAccountValueSetAction extends Action {
    key: keyof IBankAccount;
    value: any;
}

export function publicCurrentInvestorBankAccountValueSetAction(key: keyof IBankAccount, value: any): IPublicCurrentInvestorBankAccountValueSetAction {
    return {
        key,
        type: ActionsEnum.CurrentInvestorBankAccountValueSet,
        value,
    };
}

export interface IPublicCurrentInvestorAccountMarketplaceQualifierSendAction extends Action {
    qualifierResponses: IMarketplaceQualifier[];
}

export function publicCurrentInvestorAccountMarketplaceQualifierSendAction(qualifierResponses: IMarketplaceQualifier[]): IPublicCurrentInvestorAccountMarketplaceQualifierSendAction {
    return {
        qualifierResponses,
        type: ActionsEnum.CurrentInvestorAccountMarketplaceQualifierSend,
    };
}

export interface IPublicCurrentInvestorCompanyValueSetAction extends Action {
    key: keyof ICompany;
    value: any;
}

export function publicCurrentInvestorCompanyValueSetAction(key: keyof ICompany, value: any): IPublicCurrentInvestorCompanyValueSetAction {
    return {
        key,
        type: ActionsEnum.CurrentInvestorCompanyValueSet,
        value,
    };
}

export interface IPublicCurrentInvestorIndividualValueSetAction extends Action {
    index: number;
    key: keyof IIndividual;
    value: any;
}

export function publicCurrentInvestorIndividualValueSetAction(index: number, key: keyof IIndividual, value: any): IPublicCurrentInvestorIndividualValueSetAction {
    return {
        index,
        key,
        type: ActionsEnum.CurrentInvestorIndividualValueSet,
        value,
    };
}

export interface IPublicCurrentInvestorTrustValueSetAction extends Action {
    key: keyof ITrust;
    value: any;
}

export function publicCurrentInvestorTrustValueSetAction(key: keyof ITrust, value: any): IPublicCurrentInvestorTrustValueSetAction {
    return {
        key,
        type: ActionsEnum.CurrentInvestorTrustValueSet,
        value,
    };
}

export interface IPublicInvestReferredAction extends Action {
    referrerCode: string;
}

export function publicInvestReferredAction(referrerCode: string): IPublicInvestReferredAction {
    return {
        referrerCode,
        type: ActionsEnum.InvestReferred,
    };
}

export interface IPublicReferralPartnerRegisterAction extends Action {
    photoId: File;
    referralPartner: IReferralPartner;
}

export function publicReferralPartnerRegisterAction(referralPartner: IReferralPartner, photoId: File): IPublicReferralPartnerRegisterAction {
    return {
        photoId,
        referralPartner,
        type: ActionsEnum.ReferralPartnerRegister,
    };
}
