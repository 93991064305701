import _ from 'lodash';
import React from 'react';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IAggregator from '~Api/Broker/IAggregator';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import TypeEnum from '~Api/ReferralPartner/TypeEnum';
import { authRegisterErrorSetAction } from '~Auth/actions';
import {
    authRegisterErrorsSelector,
    authRegisterInProgressSelector,
} from '~Auth/selectors';
import {
    publicAggregatorsGetAction,
    publicReferralPartnerRegisterAction,
} from '~Public/actions';
import { publicAggregatorsSelector } from '~Public/selectors';
import { IGlobalState } from '~reducer';
import Radio from '~UI/Radio';
import Upload from '~UI/Upload';
import StateEnum from '~utilities/StateEnum';
import { validatePostcodeState } from '~utilities/validators';
import { validateEmail } from '~validators';
import Layout from './Layout';
import './referral-partner-register.less';

interface IState {
    abn: string;
    acl: boolean;
    aggregatorUuid: string;
    banned: boolean;
    chargesFee: boolean;
    companyName: string;
    conductsBusinessInPerson: boolean;
    disclosesBenefits: boolean;
    email: string;
    firstName: string;
    groupName: string;
    includesCustomerInfo: boolean;
    lastName: string;
    linkedInUrl: string;
    nccp: boolean;
    phone: string;
    photoId: File;
    postcode: string;
    refersClient: boolean;
    state: StateEnum;
    streetAddress: string;
    suburb: string;
    type: TypeEnum;
}

interface IPropsSelector {
    aggregators: IAggregator[];
    registerErrors: any;
    registerInProgress: boolean;
}

interface IPropsDispatch {
    aggregatorsList: () => void;
    register: (referralPartner: IReferralPartner, photoId: File) => void;
    setError: (key: string, value: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Register extends React.Component<Props, IState> {
    public state: IState = {
        abn: null,
        acl: null,
        aggregatorUuid: null,
        banned: null,
        chargesFee: null,
        companyName: null,
        conductsBusinessInPerson: null,
        disclosesBenefits: null,
        email: null,
        firstName: null,
        groupName: null,
        includesCustomerInfo: null,
        lastName: null,
        linkedInUrl: null,
        nccp: null,
        phone: null,
        photoId: null,
        postcode: null,
        refersClient: null,
        state: null,
        streetAddress: null,
        suburb: null,
        type: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickSubmit = this.onClickSubmit.bind(this);

        this.onChangeAbn = this.onChangeAbn.bind(this);
        this.onChangeAggregatorUuid = this.onChangeAggregatorUuid.bind(this);
        this.onChangeAgreesToDiscloseBenefits = this.onChangeAgreesToDiscloseBenefits.bind(this);
        this.onChangeAgreesToIncludeCustomerInfo = this.onChangeAgreesToIncludeCustomerInfo.bind(this);
        this.onChangeAgreesToNccpAct = this.onChangeAgreesToNccpAct.bind(this);
        this.onChangeAgreesToReferClient = this.onChangeAgreesToReferClient.bind(this);
        this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
        this.onChangeDoesChargeFee = this.onChangeDoesChargeFee.bind(this);
        this.onChangeDoesConductsBusinessInPerson = this.onChangeDoesConductsBusinessInPerson.bind(this);
        this.onChangeDoesNotChargeFee = this.onChangeDoesNotChargeFee.bind(this);
        this.onChangeDoesNotConductsBusinessInPerson = this.onChangeDoesNotConductsBusinessInPerson.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeGroupName = this.onChangeGroupName.bind(this);
        this.onChangeHasAcl = this.onChangeHasAcl.bind(this);
        this.onChangeHasBeenBanned = this.onChangeHasBeenBanned.bind(this);
        this.onChangeHasNoAcl = this.onChangeHasNoAcl.bind(this);
        this.onChangeHasNotBeenBanned = this.onChangeHasNotBeenBanned.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeLinkedInUrl = this.onChangeLinkedInUrl.bind(this);
        this.onChangeNotAgreesToDiscloseBenefits = this.onChangeNotAgreesToDiscloseBenefits.bind(this);
        this.onChangeNotAgreesToIncludeCustomerInfo = this.onChangeNotAgreesToIncludeCustomerInfo.bind(this);
        this.onChangeNotAgreesToNccpAct = this.onChangeNotAgreesToNccpAct.bind(this);
        this.onChangeNotAgreesToReferClient = this.onChangeNotAgreesToReferClient.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangePostcode = this.onChangePostcode.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeStreetAddress = this.onChangeStreetAddress.bind(this);
        this.onChangeSuburb = this.onChangeSuburb.bind(this);
        this.onChangeType = this.onChangeType.bind(this);

        this.onChangePhotoId = this.onChangePhotoId.bind(this);

        this.validateAgreesToDiscloseBenefits = this.validateAgreesToDiscloseBenefits.bind(this);
        this.validateAgreesToIncludeCustomerInfo = this.validateAgreesToIncludeCustomerInfo.bind(this);
        this.validateAgreesToNccpAct = this.validateAgreesToNccpAct.bind(this);
        this.validateAgreesToReferClient = this.validateAgreesToReferClient.bind(this);
        this.validateDoesNotChargeFee = this.validateDoesNotChargeFee.bind(this);
        this.validateDoesNotConductsBusinessInPerson = this.validateDoesNotConductsBusinessInPerson.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validateFirstName = this.validateFirstName.bind(this);
        this.validateHasAcl = this.validateHasAcl.bind(this);
        this.validateHasNotBeenBanned = this.validateHasNotBeenBanned.bind(this);
        this.validateLastName = this.validateLastName.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
        this.validatePostcode = this.validatePostcode.bind(this);
        this.validateState = this.validateState.bind(this);
        this.validateStreetAddress = this.validateStreetAddress.bind(this);
        this.validateSuburb = this.validateSuburb.bind(this);
        this.validateType = this.validateType.bind(this);
    }

    public componentDidMount(): void {
        const { aggregators } = this.props;

        if (!aggregators) {
            this.props.aggregatorsList();
        }
    }

    public render(): JSX.Element {
        const { aggregators, registerErrors, registerInProgress } = this.props;
        const {
            abn,
            acl,
            aggregatorUuid,
            banned,
            chargesFee,
            companyName,
            conductsBusinessInPerson,
            disclosesBenefits,
            email,
            firstName,
            groupName,
            includesCustomerInfo,
            lastName,
            linkedInUrl,
            nccp,
            phone,
            photoId,
            postcode,
            refersClient,
            state,
            streetAddress,
            suburb,
            type,
        } = this.state;

        if (!aggregators) {
            return (
                <Layout>
                    <Container className='register'>
                        <Spinner animation='border' />
                    </Container>
                </Layout>
            );
        }

        return (
            <Layout>
                <Container className='register'>
                    <h3>Become an Accredited Partner</h3>
                    <p className='intro'>We need some information to get started.</p>
                    <Form>
                        <Form.Group className='first-name'>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.firstName} onBlur={this.validateFirstName} onChange={this.onChangeFirstName} type='text' value={firstName || ''} />
                            {registerErrors.firstName && <Form.Control.Feedback type='invalid'>{registerErrors.firstName}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='last-name'>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.lastName} onBlur={this.validateLastName} onChange={this.onChangeLastName} type='text' value={lastName || ''} />
                            {registerErrors.lastName && <Form.Control.Feedback type='invalid'>{registerErrors.lastName}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='phone'>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.phone} onBlur={this.validatePhone} onChange={this.onChangePhone} type='text' value={phone || ''} />
                            {registerErrors.phone && <Form.Control.Feedback type='invalid'>{registerErrors.phone}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='email'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.email} onBlur={this.validateEmail} onChange={this.onChangeEmail} type='email' value={email || ''} />
                            {registerErrors.email && <Form.Control.Feedback type='invalid'>{registerErrors.email}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='company-name'>
                            <Form.Label>Company Name (If Applicable)</Form.Label>
                            <Form.Control onChange={this.onChangeCompanyName} type='text' value={companyName || ''} />
                        </Form.Group>
                        <Form.Group className='abn'>
                            <Form.Label>ABN/ACN</Form.Label>
                            <Form.Control onChange={this.onChangeAbn} maxLength={11} type='text' value={abn || ''} />
                        </Form.Group>
                        <Form.Group className='postal-address'>
                            <Form.Label>Address</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.streetAddress} onBlur={this.validateStreetAddress} onChange={this.onChangeStreetAddress} value={streetAddress || ''} />
                            {registerErrors.streetAddress && <Form.Control.Feedback type='invalid'>{registerErrors.streetAddress}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='suburb'>
                            <Form.Label>Suburb</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.suburb} onBlur={this.validateSuburb} onChange={this.onChangeSuburb} value={suburb || ''} />
                            {registerErrors.suburb && <Form.Control.Feedback type='invalid'>{registerErrors.suburb}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='state'>
                            <Form.Label>State</Form.Label>
                            <Form.Control as='select' className='custom-select' isInvalid={!!registerErrors.state} onBlur={this.validateState} onChange={this.onChangeState} value={state || ''}>
                                {state === null && <option/>}
                                <option value={StateEnum.AustralianCapitalTerritory}>ACT</option>
                                <option value={StateEnum.NewSouthWales}>NSW</option>
                                <option value={StateEnum.NorthernTerritory}>NT</option>
                                <option value={StateEnum.Queensland}>QLD</option>
                                <option value={StateEnum.SouthAustralia}>SA</option>
                                <option value={StateEnum.Tasmania}>TAS</option>
                                <option value={StateEnum.Victoria}>VIC</option>
                                <option value={StateEnum.WesternAustralia}>WA</option>
                            </Form.Control>
                            {registerErrors.state && <Form.Control.Feedback type='invalid'>{registerErrors.state}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='postcode'>
                            <Form.Label>Postcode</Form.Label>
                            <Form.Control isInvalid={!!registerErrors.postcode} maxLength={4} onBlur={this.validatePostcode} onChange={this.onChangePostcode} value={postcode || ''} />
                            {registerErrors.postcode && <Form.Control.Feedback type='invalid'>{registerErrors.postcode}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='type'>
                            <Form.Label>Which best describes you?</Form.Label>
                            <Form.Control as='select' className='custom-select' isInvalid={!!registerErrors.type} onBlur={this.validateType} onChange={this.onChangeType} value={type || ''}>
                                <option/>
                                <option value={TypeEnum.RealEstate}>Real Estate</option>
                                <option value={TypeEnum.Accountant}>Accountant</option>
                                <option value={TypeEnum.Leads}>Leads</option>
                                <option value={TypeEnum.Lender}>Lender</option>
                                <option value={TypeEnum.Other}>Other</option>
                            </Form.Control>
                            {registerErrors.type && <Form.Control.Feedback type='invalid'>{registerErrors.type}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='Group'>
                            <Form.Label>Group (If Applicable)</Form.Label>
                            <Form.Control onChange={this.onChangeGroupName} type='text' value={groupName || ''} />
                        </Form.Group>
                        <Form.Group className='aggregator'>
                            <Form.Label>Aggregator (If Applicable)</Form.Label>
                            <Form.Control as='select' className='custom-select' onChange={this.onChangeAggregatorUuid} value={aggregatorUuid || ''}>
                                <option value={null}/>
                                {_.sortBy(aggregators, ['name']).map((aggregator: IAggregator) => <option key={aggregator.uuid} value={aggregator.uuid}>{aggregator.name}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className='linkedin'>
                            <Form.Label>LinkedIn URL (If Applicable)</Form.Label>
                            <Form.Control onChange={this.onChangeLinkedInUrl} type='text' value={linkedInUrl || ''} />
                        </Form.Group>
                        <Form.Group className='acl'>
                            <Form.Label>Do you hold an Australian Credit License?</Form.Label>
                            <div className={`form-check-group ${registerErrors.acl && 'is-invalid'}`}>
                                <Radio checked={acl === true} label='Yes' onChange={this.onChangeHasAcl} />
                                <Radio checked={acl === false} label='No' onChange={this.onChangeHasNoAcl} />
                            </div>
                            {registerErrors.acl && <Form.Control.Feedback type='invalid'>{registerErrors.acl}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='nccp'>
                            <Form.Label>Do you agree to comply with the National Consumer Credit Protection Act 2009 (NCCP Act)?</Form.Label>
                            <div className={`form-check-group ${registerErrors.nccp && 'is-invalid'}`}>
                                <Radio checked={nccp === true} label='Yes' onChange={this.onChangeAgreesToNccpAct} />
                                <Radio checked={nccp === false} label='No' onChange={this.onChangeNotAgreesToNccpAct} />
                            </div>
                            {registerErrors.nccp && <Form.Control.Feedback type='invalid'>{registerErrors.nccp}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='banned'>
                            <Form.Label>Are you or have you ever been banned from engaging in credit activities?</Form.Label>
                            <div className={`form-check-group ${registerErrors.banned && 'is-invalid'}`}>
                                <Radio checked={banned === true} label='Yes' onChange={this.onChangeHasBeenBanned} />
                                <Radio checked={banned === false} label='No' onChange={this.onChangeHasNotBeenBanned} />
                            </div>
                            {registerErrors.banned && <Form.Control.Feedback type='invalid'>{registerErrors.banned}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='charges-fee'>
                            <Form.Label>Do you charge the consumer a fee to refer?</Form.Label>
                            <div className={`form-check-group ${registerErrors.chargesFee && 'is-invalid'}`}>
                                <Radio checked={chargesFee === true} label='Yes' onChange={this.onChangeDoesChargeFee} />
                                <Radio checked={chargesFee === false} label='No' onChange={this.onChangeDoesNotChargeFee} />
                            </div>
                            {registerErrors.chargesFee && <Form.Control.Feedback type='invalid'>{registerErrors.chargesFee}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='conducts-business-in-person'>
                            <Form.Label>Do you conduct conduct business by contacting people face-to-face from non-standard business premises (e.g. a stall in a shopping mall or airport such as credit card providers sometimes do)?</Form.Label>
                            <div className={`form-check-group ${registerErrors.conductsBusinessInPerson && 'is-invalid'}`}>
                                <Radio checked={conductsBusinessInPerson === true} label='Yes' onChange={this.onChangeDoesConductsBusinessInPerson} />
                                <Radio checked={conductsBusinessInPerson === false} label='No' onChange={this.onChangeDoesNotConductsBusinessInPerson} />
                            </div>
                            {registerErrors.conductsBusinessInPerson && <Form.Control.Feedback type='invalid'>{registerErrors.conductsBusinessInPerson}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='discloses-benefits'>
                            <Form.Label>Do you agree to disclose to the consumer any benefits that may be received by the referrer, including commission?</Form.Label>
                            <div className={`form-check-group ${registerErrors.disclosesBenefits && 'is-invalid'}`}>
                                <Radio checked={disclosesBenefits === true} label='Yes' onChange={this.onChangeAgreesToDiscloseBenefits} />
                                <Radio checked={disclosesBenefits === false} label='No' onChange={this.onChangeNotAgreesToDiscloseBenefits} />
                            </div>
                            {registerErrors.disclosesBenefits && <Form.Control.Feedback type='invalid'>{registerErrors.disclosesBenefits}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='refers-client'>
                            <Form.Label>Do you agree to refer the client for Funding to deal with the client, including the application and will not assist the application (no credit activity)?</Form.Label>
                            <div className={`form-check-group ${registerErrors.refersClient && 'is-invalid'}`}>
                                <Radio checked={refersClient === true} label='Yes' onChange={this.onChangeAgreesToReferClient} />
                                <Radio checked={refersClient === false} label='No' onChange={this.onChangeNotAgreesToReferClient} />
                            </div>
                            {registerErrors.refersClient && <Form.Control.Feedback type='invalid'>{registerErrors.refersClient}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='includes-customer-info'>
                            <Form.Label>Do you agree to pass customers contact details including consumer&apos;s name, contact details and a short description of the purpose for which the consumer may want the credit (if known) to Funding with 5 days?</Form.Label>
                            <div className={`form-check-group ${registerErrors.includesCustomerInfo && 'is-invalid'}`}>
                                <Radio checked={includesCustomerInfo === true} label='Yes' onChange={this.onChangeAgreesToIncludeCustomerInfo} />
                                <Radio checked={includesCustomerInfo === false} label='No' onChange={this.onChangeNotAgreesToIncludeCustomerInfo} />
                            </div>
                            {registerErrors.includesCustomerInfo && <Form.Control.Feedback type='invalid'>{registerErrors.includesCustomerInfo}</Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group className='photo-id'>
                            <Form.Label>Upload Your Photo ID or Drivers Licence</Form.Label>
                            <Upload
                                file={photoId}
                                onChange={this.onChangePhotoId}
                                error={registerErrors.photoId}
                            />
                        </Form.Group>
                        <Button className='submit' disabled={registerInProgress} onClick={this.onClickSubmit} variant='primary'>
                            {registerInProgress && <Spinner animation='border' as='span' role='status' size='sm' />}
                            {!registerInProgress && 'Submit'}
                        </Button>
                    </Form>
                </Container>
            </Layout>
        );
    }

    private onClickSubmit(): void {
        let valid: boolean = true;

        valid = this.validateAgreesToDiscloseBenefits() && valid;
        valid = this.validateAgreesToIncludeCustomerInfo() && valid;
        valid = this.validateAgreesToNccpAct() && valid;
        valid = this.validateAgreesToReferClient() && valid;
        valid = this.validateDoesNotChargeFee() && valid;
        valid = this.validateDoesNotConductsBusinessInPerson() && valid;
        valid = this.validateEmail() && valid;
        valid = this.validateFirstName() && valid;
        valid = this.validateHasAcl() && valid;
        valid = this.validateHasNotBeenBanned() && valid;
        valid = this.validateLastName() && valid;
        valid = this.validatePhone() && valid;
        valid = this.validatePhotoId() && valid;
        valid = this.validatePostcode() && valid;
        valid = this.validateState() && valid;
        valid = this.validateStreetAddress() && valid;
        valid = this.validateSuburb() && valid;
        valid = this.validateType() && valid;

        if (!valid) {
            return;
        }

        const {
            abn,
            acl,
            aggregatorUuid,
            banned,
            chargesFee,
            companyName,
            conductsBusinessInPerson,
            disclosesBenefits,
            email,
            firstName,
            groupName,
            includesCustomerInfo,
            lastName,
            linkedInUrl,
            nccp,
            phone,
            photoId,
            postcode,
            refersClient,
            state,
            streetAddress,
            suburb,
            type,
        } = this.state;

        const referralPartner: IReferralPartner = {
            abn,
            acl,
            aggregatorUuid,
            banned,
            chargesFee,
            companyName,
            conductsBusinessInPerson,
            disclosesBenefits,
            email,
            firstName,
            groupName,
            includesCustomerInfo,
            lastName,
            linkedInUrl,
            nccp,
            phone,
            postcode,
            refersClient,
            state,
            streetAddress,
            suburb,
            type,
        };

        this.props.register(referralPartner, photoId);
    }

    private onChangeAbn(event: React.ChangeEvent<HTMLInputElement>): void {
        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.setState({
            abn: event.target.value,
        });
    }

    private onChangeAggregatorUuid(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            aggregatorUuid: event.target.value,
        });
    }

    private onChangeAgreesToDiscloseBenefits(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            disclosesBenefits: event.target.checked,
        });
    }

    private onChangeAgreesToIncludeCustomerInfo(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            includesCustomerInfo: event.target.checked,
        });
    }

    private onChangeAgreesToNccpAct(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            nccp: event.target.checked,
        });
    }

    private onChangeAgreesToReferClient(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            refersClient: event.target.checked,
        });
    }

    private onChangeCompanyName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            companyName: event.target.value,
        });
    }

    private onChangeDoesChargeFee(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            chargesFee: event.target.checked,
        });
    }

    private onChangeDoesConductsBusinessInPerson(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            conductsBusinessInPerson: event.target.checked,
        });
    }

    private onChangeDoesNotChargeFee(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            chargesFee: !event.target.checked,
        });
    }

    private onChangeDoesNotConductsBusinessInPerson(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            conductsBusinessInPerson: !event.target.checked,
        });
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            email: event.target.value,
        });
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            firstName: event.target.value,
        });
    }

    private onChangeGroupName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            groupName: event.target.value,
        });
    }

    private onChangeHasAcl(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            acl: event.target.checked,
        });
    }

    private onChangeHasBeenBanned(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            banned: event.target.checked,
        });
    }

    private onChangeHasNoAcl(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            acl: !event.target.checked,
        });
    }

    private onChangeHasNotBeenBanned(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            banned: !event.target.checked,
        });
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            lastName: event.target.value,
        });
    }

    private onChangeLinkedInUrl(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            linkedInUrl: event.target.value,
        });
    }

    private onChangeNotAgreesToDiscloseBenefits(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            disclosesBenefits: !event.target.checked,
        });
    }

    private onChangeNotAgreesToIncludeCustomerInfo(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            includesCustomerInfo: !event.target.checked,
        });
    }

    private onChangeNotAgreesToNccpAct(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            nccp: !event.target.checked,
        });
    }

    private onChangeNotAgreesToReferClient(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            refersClient: !event.target.checked,
        });
    }

    private onChangePhone(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            phone: event.target.value,
        });
    }

    private onChangePostcode(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            postcode: event.target.value,
        });
    }

    private onChangeState(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            state: event.target.value as StateEnum,
        });
    }

    private onChangeStreetAddress(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            streetAddress: event.target.value,
        });
    }

    private onChangeSuburb(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            suburb: event.target.value,
        });
    }

    private onChangeType(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            type: event.target.value as TypeEnum,
        });
    }

    private onChangePhotoId(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            photoId: event.target.files[0],
        });
    }

    private validateAgreesToDiscloseBenefits(): boolean {
        const { disclosesBenefits } = this.state;

        let error: string;

        if (disclosesBenefits !== true && disclosesBenefits !== false) {
            error = 'Please choose one';
        }

        this.props.setError('disclosesBenefits', error);

        return !error;
    }

    private validateAgreesToIncludeCustomerInfo(): boolean {
        const { includesCustomerInfo } = this.state;

        let error: string;

        if (includesCustomerInfo !== true && includesCustomerInfo !== false) {
            error = 'Please choose one';
        }

        this.props.setError('includesCustomerInfo', error);

        return !error;
    }

    private validateAgreesToNccpAct(): boolean {
        const { nccp } = this.state;

        let error: string;

        if (nccp !== true && nccp !== false) {
            error = 'Please choose one';
        }

        this.props.setError('nccp', error);

        return !error;
    }

    private validateAgreesToReferClient(): boolean {
        const { refersClient } = this.state;

        let error: string;

        if (refersClient !== true && refersClient !== false) {
            error = 'Please choose one';
        }

        this.props.setError('refersClient', error);

        return !error;
    }

    private validateDoesNotChargeFee(): boolean {
        const { chargesFee } = this.state;

        let error: string;

        if (chargesFee !== true && chargesFee !== false) {
            error = 'Please choose one';
        }

        this.props.setError('chargesFee', error);

        return !error;
    }

    private validateDoesNotConductsBusinessInPerson(): boolean {
        const { conductsBusinessInPerson } = this.state;

        let error: string;

        if (conductsBusinessInPerson !== true && conductsBusinessInPerson !== false) {
            error = 'Please choose one';
        }

        this.props.setError('conductsBusinessInPerson', error);

        return !error;
    }

    private validateEmail(): boolean {
        const { email } = this.state;

        let error: string;
        if (!email || email.length === 0) {
            error = 'Please enter your email address';
        } else if (!validateEmail(email)) {
            error = 'Please enter a valid email address';
        }

        this.props.setError('email', error);

        return !error;
    }

    private validateFirstName(): boolean {
        const { firstName } = this.state;

        let error: string;

        if (!firstName || firstName.length === 0) {
            error = 'Please enter the first name';
        } else if (firstName.length < 2) {
            error = 'Please enter at least 2 characters';
        }

        this.props.setError('firstName', error);

        return !error;
    }

    private validateHasAcl(): boolean {
        const { acl } = this.state;

        let error: string;

        if (acl !== true && acl !== false) {
            error = 'Please choose one';
        }

        this.props.setError('acl', error);

        return !error;
    }

    private validateHasNotBeenBanned(): boolean {
        const { banned } = this.state;

        let error: string;

        if (banned !== true && banned !== false) {
            error = 'Please choose one';
        }

        this.props.setError('banned', error);

        return !error;
    }

    private validateLastName(): boolean {
        const { lastName } = this.state;

        let error: string;

        if (!lastName || lastName.length === 0) {
            error = 'Please enter the last name';
        } else if (lastName.length < 2) {
            error = 'Please enter at least 2 characters';
        }

        this.props.setError('lastName', error);

        return !error;
    }

    private validatePhone(): boolean {
        const { phone } = this.state;

        let error: string;

        if (!phone || phone.length  === 0) {
            error = 'Please enter your phone number';
        }

        this.props.setError('phone', error);

        return !error;
    }

    private validatePhotoId(): boolean {
        const { photoId } = this.state;

        let error: string;

        if (!photoId) {
            error = 'Please attach a form of photo ID';
        }

        this.props.setError('photoId', error);

        return !error;
    }

    private validatePostcode(): boolean {
        const { postcode, state } = this.state;

        let error: string;

        if (!postcode || postcode.length === 0) {
            error = 'Please enter the postcode';
        } else if (postcode.length < 3 || postcode.length > 4) {
            error = 'Postcode must be 3 or 4 numbers';
        }  else if (state && state !== StateEnum.NorthernTerritory && postcode.length === 3) {
            error = 'Postcode must be 4 numbers';
        }

        if (!error && state) {
            let valid: boolean = true;

            valid = validatePostcodeState(postcode, state);

            if (!valid) {
                error = 'Please enter a valid postcode';
            }
        }

        this.props.setError('postcode', error);

        return !error;
    }

    private validateState(): boolean {
        const { state } = this.state;

        let error: string;

        if (!_.values(StateEnum).includes(state)) {
            error = 'Please select your state';
        }

        this.props.setError('state', error);

        return !error;
    }

    private validateStreetAddress(): boolean {
        const { streetAddress } = this.state;

        let error: string;

        if (!streetAddress || streetAddress.length  === 0) {
            error = 'Please enter your address';
        }

        this.props.setError('streetAddress', error);

        return !error;
    }

    private validateSuburb(): boolean {
        const { suburb } = this.state;

        let error: string;

        if (!suburb || suburb.length  === 0) {
            error = 'Please enter your suburb';
        }

        this.props.setError('suburb', error);

        return !error;
    }

    private validateType(): boolean {
        const { type } = this.state;

        let error: string;

        if (type === null) {
            error = 'Please choose one';
        }

        this.props.setError('type', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        aggregators: publicAggregatorsSelector(state),
        registerErrors: authRegisterErrorsSelector(state),
        registerInProgress: authRegisterInProgressSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        aggregatorsList: () => dispatch(publicAggregatorsGetAction()),
        register: (referralPartner: IReferralPartner, photoId: File) => dispatch(publicReferralPartnerRegisterAction(referralPartner, photoId)),
        setError: (key: string, value: string) => dispatch(authRegisterErrorSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Register);
