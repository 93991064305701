import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router as ReactRouter, Switch } from 'react-router-dom';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IAuthUser from '~Auth/IAuthUser';
import RoleEnum from '~Auth/RoleEnum';
import { authCurrentUserSelector } from '~Auth/selectors';
import history from '~history';
import { currentInvestorAccountSelector } from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import AccountInformation from './AccountInformation';
import BankAccount from './BankAccount';
import Details from './Details';
import DistributionPreference from './DistributionPreference';
import InformationMemorandum from './InformationMemorandum';
import Qualifier from './Qualifier';
import RetailInvestor from './RetailInvestor';

interface IPropsSelector {
    currentUser: IAuthUser;
    incomeTrustAccount: IAccount;
}

type Props = IPropsSelector;

class Router extends React.Component<Props> {
    public render(): JSX.Element {
        const { currentUser, incomeTrustAccount } = this.props;

        if (currentUser.role === RoleEnum.Investor) {
            // Marketplace users complete income trust registration from the portal
            if (!incomeTrustAccount) {
                return (
                    <ReactRouter history={history}>
                        <Redirect to='/income-trust' />
                    </ReactRouter>
                );
            }

            // In-progress income trust registration skips account creation
            if (!incomeTrustAccount.isCompleted) {
                return (
                    <ReactRouter history={history}>
                        <Switch>
                            <Route exact={true} path='/invest/income-trust/details' component={Details} />
                            <Route exact={true} path='/invest/income-trust/bank-account' component={BankAccount} />
                            <Route exact={true} path='/invest/income-trust/distribution-preference' component={DistributionPreference} />
                            <Route exact={true} path='/invest/income-trust/information-memorandum' component={InformationMemorandum} />
                            <Redirect to='/invest/income-trust/details' />
                        </Switch>
                    </ReactRouter>
                );
            }

            // Completed accounts redirect to the portal
            return (
                <ReactRouter history={history}>
                    <Redirect to='/income-trust' />
                </ReactRouter>
            );
        }

        // Public registration
        return (
            <ReactRouter history={history}>
                <Switch>
                    <Route exact={true} path='/invest/income-trust/qualifier' component={Qualifier} />
                    <Route exact={true} path='/invest/income-trust/retail-investor' component={RetailInvestor} />
                    <Route exact={true} path='/invest/income-trust/account-information' component={AccountInformation} />
                    <Route exact={true} path='/invest/income-trust/details' component={Details} />
                    <Route exact={true} path='/invest/income-trust/bank-account' component={BankAccount} />
                    <Route exact={true} path='/invest/income-trust/distribution-preference' component={DistributionPreference} />
                    <Route exact={true} path='/invest/income-trust/information-memorandum' component={InformationMemorandum} />
                    <Redirect to='/invest/income-trust/qualifier' />
                </Switch>
            </ReactRouter>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentUser: authCurrentUserSelector(state),
        incomeTrustAccount: currentInvestorAccountSelector(state, AccountTypeEnum.IncomeTrust),
    };
}

export default connect(
    mapStateToProps,
    null,
)(Router);
