enum ApplicationActionsEnum {
    ApplicationBorrowerType = 'APPLICATION/BORROWER_TYPE',
    ApplicationComplete = 'APPLICATION/COMPLETE',
    ApplicationErrorSet = 'APPLICATION/ERROR_SET',
    ApplicationGet = 'APPLICATION/GET',
    ApplicationSavingSet = 'APPLICATION/SAVING/SET',
    ApplicationSend = 'APPLICATION/SEND',
    ApplicationSet = 'APPLICATION/SET',
    ApplicationValueSet = 'APPLICATION/VALUE_SET',

    ApplicationsList = 'APPLICATIONS/LIST',
    ApplicationsSet = 'APPLICATIONS/SET',

    BorrowersAdd = 'APPLICATION/BORROWERS/ADD',

    BorrowerDelete = 'APPLICATION/BORROWER/DELETE',
    BorrowerErrorSet = 'APPLICATION/BORROWER/ERROR_SET',
    BorrowerSend = 'APPLICATION/BORROWER/SEND',
    BorrowerSet = 'APPLICATION/BORROWER/SET',
    BorrowerValueSet = 'APPLICATION/BORROWER/VALUE_SET',

    DealGet = 'APPLICATION/DEAL/GET',
    DealSet = 'APPLICATION/DEAL/SET',

    DealQuoteConvert = 'APPLICATION/DEAL_QUOTE_CONVERT',

    PropertiesAdd = 'APPLICATION/PROPERTIES/ADD',

    PropertyDelete = 'APPLICATION/PROPERTY/DELETE',
    PropertyErrorSet = 'APPLICATION/PROPERTY/ERROR_SET',
    PropertySend = 'APPLICATION/PROPERTY/SEND',
    PropertySet = 'APPLICATION/PROPERTY/SET',
    PropertyValueSet = 'APPLICATION/PROPERTY/VALUE_SET',

    TokenSet = 'APPLICATION/TOKEN/SET',
}

export default ApplicationActionsEnum;
