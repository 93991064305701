import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IDeal from '~Api/Deal/IDeal';
import IDealProperty, { IDealPropertyErrors } from '~Api/Deal/IDealProperty';
import history from '~history';
import { validateProperty } from '~Lead/validators';
import { IGlobalState } from '~reducer';
import {
    dealGetAction,
    propertiesAddAction,
    propertyErrorSetAction,
} from './actions';
import Layout from './Layout';
import Property from './Property';
import {
    applicationSelector,
    currentDealSelector,
    propertiesSelector,
    savingSelector,
} from './selectors';

interface IPropsSelector {
    application: IApplication;
    deal: IDeal;
    properties: IDealProperty[];
    saving: boolean;
}

interface IPropsDispatch {
    dealGet: () => void;
    propertiesAdd: () => void;
    propertyErrorSet: (uuid: string, field: keyof IDealPropertyErrors, value: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Properties extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickContinue = this.onClickContinue.bind(this);
        this.validateProperties = this.validateProperties.bind(this);
    }

    public componentDidMount() {
        const { application, deal } = this.props;

        if (!application || !deal) {
            this.props.dealGet();
        }
    }

    public render(): JSX.Element {
        const { application, deal, properties, saving } = this.props;

        if (!application || !deal) {
            return (
                <Layout containerClass='properties' stepNumber={2}>
                    <h3>Property Details</h3>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        return (
            <Layout containerClass='properties' stepNumber={2}>
                <h3>Property Details</h3>
                <p className='intro'>Enter your property details below.</p>
                <Form>
                    {properties.map((property: IDealProperty, index: number) => <Property uuid={property.uuid} index={index} key={property.uuid} />)}
                    <Button className='add-property' disabled={saving} onClick={this.props.propertiesAdd} variant='light'>
                        {!saving && 'Add Another Property'}
                        {saving && <Spinner animation='border' as='span' role='status' size='sm' />}
                    </Button>
                    <div className='actions'>
                        <Link className='previous' to='/application/repayment'><Button disabled={saving} variant='primary'>Previous</Button></Link>
                        <Button className='continue' disabled={saving} onClick={this.onClickContinue} variant='primary'>Continue</Button>
                    </div>
                </Form>
            </Layout>
        );
    }

    private onClickContinue() {
        let valid = true;

        valid = this.validateProperties() && valid;

        if (!valid) {
            return;
        }

        history.push(`/application/borrower-type`);
    }

    private validateProperties(): boolean {
        const { properties } = this.props;

        let valid: boolean = true;

        properties.forEach((property: IDealProperty) => {
            const setError = (key: keyof IDealPropertyErrors, value: string) => this.props.propertyErrorSet(property.uuid, key, value);

            valid = validateProperty(property, setError) && valid;
        });

        return valid;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        application: applicationSelector(state),
        deal: currentDealSelector(state),
        properties: propertiesSelector(state),
        saving: savingSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        dealGet: () => dispatch(dealGetAction()),
        propertiesAdd: () => dispatch(propertiesAddAction()),
        propertyErrorSet: (uuid: string, field: keyof IDealPropertyErrors, value: string) => dispatch(propertyErrorSetAction(uuid, field, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Properties);
