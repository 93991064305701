import _ from 'lodash';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { IDealProperty } from '~Api/Deal';
import { IDealPropertyErrors } from '~Api/Deal/IDealProperty';
import PropertyStateEnum from '~Api/Deal/PropertyStateEnum';
import PropertyStreetTypeEnum from '~Api/Deal/PropertyStreetTypeEnum';
import propertyStreetTypeLabels from '~Api/Deal/PropertyStreetTypeLabels';
import ZoneTypeEnum from '~Api/Deal/ZoneTypeEnum';
import {
    validateCurrentDebt,
    validateEstimatedValue,
    validatePostcode,
    validateState,
    validateStreetName,
    validateStreetNumber,
    validateStreetType,
    validateSuburb,
    validateUnitNumber,
    validateZoneType,
} from '~Lead/validators';
import './application-request.less';

interface IProps {
    clearError: (index: number, key: keyof IDealPropertyErrors) => void;
    index: number;
    property: IDealProperty;
    removeProperty: (index: number) => void;
    setError: (index: number, key: keyof IDealPropertyErrors, value: string) => void;
    setValue: (index: number, key: keyof IDealProperty, value: any) => void;
}

class ApplicationProperty extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);

        this.onChangeCurrentDebt = this.onChangeCurrentDebt.bind(this);
        this.onChangeEstimatedValue = this.onChangeEstimatedValue.bind(this);
        this.onChangePostcode = this.onChangePostcode.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeUnitNumber = this.onChangeUnitNumber.bind(this);
        this.onChangeStreetNumber = this.onChangeStreetNumber.bind(this);
        this.onChangeStreetName = this.onChangeStreetName.bind(this);
        this.onChangeStreetType = this.onChangeStreetType.bind(this);
        this.onChangeSuburb = this.onChangeSuburb.bind(this);
        this.onChangeZoneType = this.onChangeZoneType.bind(this);

        this.removeProperty = this.removeProperty.bind(this);

        this.setError = this.setError.bind(this);

        this.validateCurrentDebt = this.validateCurrentDebt.bind(this);
        this.validateEstimatedValue = this.validateEstimatedValue.bind(this);
        this.validatePostcode = this.validatePostcode.bind(this);
        this.validateState = this.validateState.bind(this);
        this.validateUnitNumber = this.validateUnitNumber.bind(this);
        this.validateStreetNumber = this.validateStreetNumber.bind(this);
        this.validateStreetName = this.validateStreetName.bind(this);
        this.validateStreetType = this.validateStreetType.bind(this);
        this.validateSuburb = this.validateSuburb.bind(this);
        this.validateZoneType = this.validateZoneType.bind(this);
    }

    public render(): JSX.Element {
        const { index, property } = this.props;

        return (
            <React.Fragment key={`property-${index}`}>
                <h2 className='property'> Security Property {index + 1}</h2>
                <Form.Group className='unit-number'>
                    <Form.Label>Unit Number (if any)</Form.Label>
                    <Form.Control isInvalid={!!property.errors.unitNumber} maxLength={10} onBlur={this.validateUnitNumber} onChange={this.onChangeUnitNumber} value={property.unitNumber || ''} />
                    {property.errors.unitNumber && <Form.Control.Feedback type='invalid'>{property.errors.unitNumber}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='street-number'>
                    <Form.Label>Street Number</Form.Label>
                    <Form.Control isInvalid={!!property.errors.streetNumber} maxLength={10} onBlur={this.validateStreetNumber} onChange={this.onChangeStreetNumber} value={property.streetNumber || ''} />
                    {property.errors.streetNumber && <Form.Control.Feedback type='invalid'>{property.errors.streetNumber}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='street-name'>
                    <Form.Label>Street Name</Form.Label>
                    <Form.Control isInvalid={!!property.errors.streetName} maxLength={255} onBlur={this.validateStreetName} onChange={this.onChangeStreetName} value={property.streetName || ''} />
                    {property.errors.streetName && <Form.Control.Feedback type='invalid'>{property.errors.streetName}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='street-type'>
                    <Form.Label>Street Type</Form.Label>
                    <Form.Control as='select' className='custom-select' isInvalid={!!property.errors.streetType} onBlur={this.validateStreetType} onChange={this.onChangeStreetType} value={property.streetType || ''}>
                        {property.streetType === null && <option />}
                        {_.keys(propertyStreetTypeLabels).map((code: string): JSX.Element => <option key={code} value={code}>{propertyStreetTypeLabels[code]}</option>)}
                    </Form.Control>
                    {property.errors.streetType && <Form.Control.Feedback type='invalid'>{property.errors.streetType}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='suburb'>
                    <Form.Label>Suburb</Form.Label>
                    <Form.Control isInvalid={!!property.errors.suburb} maxLength={50} onBlur={this.validateSuburb} onChange={this.onChangeSuburb} value={property.suburb || ''} />
                    {property.errors.suburb && <Form.Control.Feedback type='invalid'>{property.errors.suburb}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='state'>
                    <Form.Label>State</Form.Label>
                    <Form.Control as='select' className='custom-select' isInvalid={!!property.errors.state} onBlur={this.validateState} onChange={this.onChangeState} value={property.state || ''}>
                        {property.state === null && <option />}
                        <option value={PropertyStateEnum.AustralianCapitalTerritory}>ACT</option>
                        <option value={PropertyStateEnum.NewSouthWales}>NSW</option>
                        <option value={PropertyStateEnum.NorthernTerritory}>NT</option>
                        <option value={PropertyStateEnum.Queensland}>QLD</option>
                        <option value={PropertyStateEnum.SouthAustralia}>SA</option>
                        <option value={PropertyStateEnum.Tasmania}>TAS</option>
                        <option value={PropertyStateEnum.Victoria}>VIC</option>
                        <option value={PropertyStateEnum.WesternAustralia}>WA</option>
                    </Form.Control>
                    {property.errors.state && <Form.Control.Feedback type='invalid'>{property.errors.state}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='postcode'>
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control isInvalid={!!property.errors.postcode} maxLength={4} onBlur={this.validatePostcode} onChange={this.onChangePostcode} value={property.postcode || ''} />
                    {property.errors.postcode && <Form.Control.Feedback type='invalid'>{property.errors.postcode}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='estimated-value'>
                    <Form.Label>Estimated Value of Property</Form.Label>
                    <Form.Control isInvalid={!!property.errors.estimatedValue} min={0} max={99999999} onBlur={this.validateEstimatedValue} onChange={this.onChangeEstimatedValue} type='number' value={property.estimatedValue || ''} />
                    {property.errors.estimatedValue && <Form.Control.Feedback type='invalid'>{property.errors.estimatedValue}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='current-debt'>
                    <Form.Label>Current Debt on Property (if any)</Form.Label>
                    <Form.Control isInvalid={!!property.errors.currentDebt} min={0} max={99999999} onBlur={this.validateCurrentDebt} onChange={this.onChangeCurrentDebt} type='number' value={property.currentDebt || ''} />
                    {property.errors.currentDebt && <Form.Control.Feedback type='invalid'>{property.errors.currentDebt}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className='zone-type'>
                    <Form.Label>Property Type</Form.Label>
                    <Form.Control as='select' className='custom-select' isInvalid={!!property.errors.zoneType} onBlur={this.validateZoneType} onChange={this.onChangeZoneType} value={property.zoneType || ''}>
                        {property.zoneType === null && <option />}
                        <option value={ZoneTypeEnum.ResidentialHouse}>Residential - House</option>
                        <option value={ZoneTypeEnum.ResidentialTownhouse}>Residential - Townhouse / Villa</option>
                        <option value={ZoneTypeEnum.ResidentialUnit}>Residential - Unit / Apartment</option>
                        <option value={ZoneTypeEnum.ResidentialLand}>Residential - Land</option>
                        <option value={ZoneTypeEnum.CommercialOffice}>Commercial - Office</option>
                        <option value={ZoneTypeEnum.CommercialRetail}>Commercial - Retail</option>
                        <option value={ZoneTypeEnum.CommercialIndustrial}>Commercial - Industrial</option>
                        <option value={ZoneTypeEnum.CommercialLand}>Commercial - Land</option>
                        <option value={ZoneTypeEnum.RuralResidential}>Rural - Residential</option>
                        <option value={ZoneTypeEnum.RuralLand}>Rural - Land</option>
                    </Form.Control>
                    {property.errors.zoneType && <Form.Control.Feedback type='invalid'>{property.errors.zoneType}</Form.Control.Feedback>}
                </Form.Group>
                {index > 0 && <Button className='remove-property' onClick={this.removeProperty} variant='light'>Remove Property</Button>}
            </React.Fragment>
        );
    }

    private onChangeUnitNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue(this.props.index, 'unitNumber', event.target.value);
    }

    private onChangeStreetNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue(this.props.index, 'streetNumber', event.target.value);
    }

    private onChangeStreetName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue(this.props.index, 'streetName', event.target.value);
    }

    private onChangeStreetType(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.setValue(this.props.index, 'streetType', event.target.value as PropertyStreetTypeEnum);
    }

    private onChangePostcode(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value) || event.target.value.trim().length > 4) {
            return;
        }

        this.props.setValue(this.props.index, 'postcode', event.target.value);
    }

    private onChangeEstimatedValue(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.props.setValue(this.props.index, 'estimatedValue', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private onChangeCurrentDebt(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.props.setValue(this.props.index, 'currentDebt', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private onChangeState(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.setValue(this.props.index, 'state', event.target.value as PropertyStateEnum || null);
    }

    private onChangeSuburb(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.setValue(this.props.index, 'suburb', event.target.value.substr(0, 50));
    }

    private onChangeZoneType(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.setValue(this.props.index, 'zoneType', event.target.value as ZoneTypeEnum);
    }

    private removeProperty() {
        this.props.removeProperty(this.props.index);
    }

    private setError(index: number): (key: keyof IDealPropertyErrors, value: string) => void {
        return (key: keyof IDealPropertyErrors, value: string): void => {
            if (value) {
                this.props.setError(index, key, value);
            } else {
                this.props.clearError(index, key);
            }
        };
    }

    private validateCurrentDebt(): boolean {
        const { index, property } = this.props;

        const error: string = validateCurrentDebt(property.currentDebt, this.setError(index));

        return !error;
    }

    private validateEstimatedValue(): boolean {
        const { index, property } = this.props;

        const error: string = validateEstimatedValue(property.estimatedValue, this.setError(index));

        return !error;
    }

    private validatePostcode(): boolean {
        const { index, property } = this.props;

        const error: string = validatePostcode(property.postcode, property.state, this.setError(index));

        return !error;
    }

    private validateState(): boolean {
        const { index, property } = this.props;

        const error: string = validateState(property.state, this.setError(index));

        return !error;
    }

    private validateUnitNumber(): boolean {
        const { index, property } = this.props;

        const error: string = validateUnitNumber(property.unitNumber, this.setError(index));

        return !error;
    }

    private validateStreetNumber(): boolean {
        const { index, property } = this.props;

        const error: string = validateStreetNumber(property.streetNumber, this.setError(index));

        return !error;
    }


    private validateStreetName(): boolean {
        const { index, property } = this.props;

        const error: string = validateStreetName(property.streetName, this.setError(index));

        return !error;
    }

    private validateStreetType(): boolean {
        const { index, property } = this.props;

        const error: string = validateStreetType(property.streetType, this.setError(index));

        return !error;
    }

    private validateSuburb(): boolean {
        const { index, property } = this.props;

        const error: string = validateSuburb(property.suburb, this.setError(index));

        return !error;
    }

    private validateZoneType(): boolean {
        const { index, property } = this.props;

        const error: string = validateZoneType(property.zoneType, this.setError(index));

        return !error;
    }
}

export default ApplicationProperty;
