import React from 'react';
import { Card, CardDeck, Container } from 'react-bootstrap';
import PublicLayout from '~Public/Layout';
import '../invest.less';

class Layout extends React.Component {
    public render(): JSX.Element {
        return (
            <PublicLayout>
                <div className='invest marketplace'>
                    {this.props.children}

                    <Container className='faq'>
                        <h4>FAQ&apos;s</h4>
                        <CardDeck>
                            <Card>
                                <p className='header'>Is it free to open an account?</p>
                                <p>Yes. It is free to sign-up and open your account. You will then be able to view and have access to our available mortgage investments offering returns of 5 to 9% PA*.</p>
                            </Card>
                            <Card>
                                <p className='header'>Do I need to provide my TFN?</p>
                                <p>Your tax file number can be provided later. If one is not provided tax withholding obligations apply.</p>
                            </Card>
                            <Card>
                                <p className='header'>How is my information kept private?</p>
                                <p>We use the strongest browser encryption and we protect the privacy of your information and will never share your data with any third party without your permission.</p>
                            </Card>
                        </CardDeck>
                    </Container>
                </div>
            </PublicLayout>
        );
    }
}

export default Layout;
