import React from 'react';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '~Auth/Layout';

class VerifyEmailVerified extends React.Component {
    public render(): JSX.Element {
        return (
            <Layout section='verify-email-verified'>
                <Alert variant='success'>Your email address has been verified.</Alert>
                <Link className='login' to='/'>Log In</Link>
            </Layout>
        );
    }
}

export default VerifyEmailVerified;
