import React from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IInvestor from '~Api/Investor/IInvestor';
import history from '~history';
import { publicCurrentInvestorGetAction } from '~Public/actions';
import { publicCurrentInvestorSelector } from '~Public/selectors';
import { IGlobalState } from '~reducer';
import Radio from '~UI/Radio';
import Layout from './Layout';

enum TypeOfAccountEnum {
    Retail = 'RETAIL',
    WholesaleOrSophisticated = 'WHOLESALE_OR_SOPHISTICATED',
}

interface IState {
    typeOfAccount: string;
}

interface IPropsSelector {
    currentInvestor: IInvestor;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Qualifier extends React.Component<Props, IState> {
    public state: IState = {
        typeOfAccount: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeTypeOfAccount = this.onChangeTypeOfAccount.bind(this);

        this.onClickContinue = this.onClickContinue.bind(this);
    }

    public componentDidMount(): void {
        const { currentInvestor } = this.props;

        if (!currentInvestor) {
            this.props.currentInvestorGet();
        }
    }

    public render(): JSX.Element {
        const { typeOfAccount } = this.state;

        return (
            <Layout>
                <Container className='register-qualifier'>
                    <h3>Type of Investor</h3>
                    <p className='intro'>Which type of investor are you?</p>
                    <Form>
                        <Form.Group className='income-trust-qualifier'>
                            <div className='form-cards form-cards-vertical'>
                                <div className={`form-card ${typeOfAccount === TypeOfAccountEnum.WholesaleOrSophisticated ? 'form-card-selected' : ''}`}>
                                    <Radio
                                        checked={typeOfAccount === TypeOfAccountEnum.WholesaleOrSophisticated}
                                        label='Wholesale or Sophisticated Investor'
                                        value={TypeOfAccountEnum.WholesaleOrSophisticated}
                                        onChange={this.onChangeTypeOfAccount}
                                    />
                                    <div className='card-description'>
                                        <p>A person or entity:</p>
                                        <ul>
                                            <li>who invests at least $500,000;</li>
                                            <li>or, has net assets of at least $2.5 million;</li>
                                            <li>or, had a gross income for each of the last two financial years of at least $250,000.</li>
                                        </ul>
                                        <p>A supporting accountant’s certificate is required.</p>
                                    </div>
                                </div>
                                <div className={`form-card ${typeOfAccount === TypeOfAccountEnum.Retail ? 'form-card-selected' : ''}`}>
                                    <Radio
                                        checked={typeOfAccount === TypeOfAccountEnum.Retail}
                                        label='Retail Investor'
                                        value={TypeOfAccountEnum.Retail}
                                        onChange={this.onChangeTypeOfAccount}
                                    />
                                    <div className='card-description'>Not a Wholesale or Sophisticated investor.</div>
                                </div>
                            </div>
                        </Form.Group>
                        <Button className='continue' onClick={this.onClickContinue} variant='primary' disabled={typeOfAccount === null}>Continue</Button>
                    </Form>
                </Container>
            </Layout>
        );
    }

    private onClickContinue(): void {
        const { typeOfAccount } = this.state;
        history.push(typeOfAccount === TypeOfAccountEnum.WholesaleOrSophisticated ? '/invest/income-trust/account-information' : '/invest/income-trust/retail-investor');
    }

    private onChangeTypeOfAccount(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ typeOfAccount: event.target.value });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: publicCurrentInvestorSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(publicCurrentInvestorGetAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Qualifier);
