import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IApplicationErrors from '~Api/Application/IApplicationErrors';
import RepaymentStrategyEnum from '~Api/Application/RepaymentStrategyEnum';
import IDeal from '~Api/Deal/IDeal';
import history from '~history';
import { IGlobalState } from '~reducer';
import {
    applicationErrorSetAction,
    applicationValueSetAction,
    dealGetAction,
} from './actions';
import Layout from './Layout';
import {
    applicationSelector,
    currentDealSelector,
    errorsSelector,
} from './selectors';

interface IPropsSelector {
    application: IApplication;
    deal: IDeal;
    errors: IApplicationErrors;
}

interface IPropsDispatch {
    applicationValueSet: (field: keyof IApplication, value: any) => void;
    dealGet: () => void;
    errorSet: (field: keyof IApplicationErrors, value: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Repayment extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeRepaymentStrategy = this.onChangeRepaymentStrategy.bind(this);

        this.onClickContinue = this.onClickContinue.bind(this);

        this.validateRepaymentStrategy = this.validateRepaymentStrategy.bind(this);
    }

    public componentDidMount() {
        const { application, deal } = this.props;

        if (!application || !deal) {
            this.props.dealGet();
        }
    }

    public render(): JSX.Element {
        const { application, deal, errors } = this.props;

        if (!application || !deal) {
            return (
                <Layout containerClass='repayment' stepNumber={1}>
                    <h3>Loan Details</h3>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        return (
            <Layout containerClass='repayment' stepNumber={1}>
                <h3>Loan Details</h3>
                <p className='intro'>How will you repay the loan?</p>
                <Form>
                    <Form.Group className='repayment-strategy'>
                        <Form.Label>Repayment Strategy</Form.Label>
                        <Form.Control as='select' className='custom-select' isInvalid={!!errors.repaymentStrategy} onBlur={this.validateRepaymentStrategy} onChange={this.onChangeRepaymentStrategy} value={application.repaymentStrategy || ''}>
                            <option/>
                            <option value={RepaymentStrategyEnum.PropertySaleSold}>Sale of Property - Already Sold</option>
                            <option value={RepaymentStrategyEnum.PropertySaleNotSold}>Sale of Property - Not Yet Sold</option>
                            <option value={RepaymentStrategyEnum.RefinanceApproved}>Refinance - Already Approved</option>
                            <option value={RepaymentStrategyEnum.RefinanceNotApproved}>Refinance - Not Yet Approved</option>
                            <option value={RepaymentStrategyEnum.BusinessSaleSold}>Sale of Business - Already Sold</option>
                            <option value={RepaymentStrategyEnum.BusinessSaleNotSold}>Sale of Business - Not Yet Sold</option>
                            <option value={RepaymentStrategyEnum.BusinessCashFlow}>Business Cash Flow</option>
                            <option value={RepaymentStrategyEnum.Inheritance}>Inheritance</option>
                            <option value={RepaymentStrategyEnum.CourtSettlement}>Court Settlement / Compensation</option>
                            <option value={RepaymentStrategyEnum.Other}>Other</option>
                        </Form.Control>
                        {errors.repaymentStrategy && <Form.Control.Feedback type='invalid'>{errors.repaymentStrategy}</Form.Control.Feedback>}
                    </Form.Group>
                    <div className='actions'>
                        <Link className='previous' to='/application/term'><Button variant='primary'>Previous</Button></Link>
                        <Button className='continue' onClick={this.onClickContinue} variant='primary'>Continue</Button>
                    </div>
                </Form>
            </Layout>
        );
    }

    private onChangeRepaymentStrategy(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.applicationValueSet('repaymentStrategy', event.target.value as RepaymentStrategyEnum || null);
    }

    private onClickContinue() {
        let valid = true;

        valid = this.validateRepaymentStrategy() && valid;

        if (!valid) {
            return;
        }

        history.push(`/application/properties`);
    }

    private validateRepaymentStrategy(): boolean {
        const { application } = this.props;

        let error: string = null;

        if (!application.repaymentStrategy) {
            error = 'Please choose the repayment strategy';
        }

        this.props.errorSet('repaymentStrategy', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        application: applicationSelector(state),
        deal: currentDealSelector(state),
        errors: errorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        applicationValueSet: (field: keyof IApplication, value: any) => dispatch(applicationValueSetAction(field, value)),
        dealGet: () => dispatch(dealGetAction()),
        errorSet: (field: keyof IApplicationErrors, value: string) => dispatch(applicationErrorSetAction(field, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Repayment);
