import React from 'react';

interface IProps {
    currentStep: number;
}

class Steps extends React.Component<IProps> {
    public render(): JSX.Element {
        const { currentStep } = this.props;

        return (
            <div className={`steps steps-5 active-${currentStep}`}>
                <div className='line' />
                <div className='active-line' />
                <div className={`step step-1`}>
                    <div className='number'>1</div>
                    <div className='label'>Basic Information</div>
                </div>
                <div className={`step step-2`}>
                    <div className='number'>2</div>
                    <div className='label'>Investor Details</div>
                </div>
                <div className={`step step-3`}>
                    <div className='number'>3</div>
                    <div className='label'>Bank Account</div>
                </div>
                <div className={`step step-4`}>
                    <div className='number'>4</div>
                    <div className='label'>Distribution Preference</div>
                </div>
                <div className={`step step-5`}>
                    <div className='number'>5</div>
                    <div className='label'>Information Memorandum</div>
                </div>
            </div>
        );
    }
}

export default Steps;
