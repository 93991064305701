import _ from 'lodash';
import IInvestment from '~Api/Investment/IInvestment';
import AccountTypeEnum from '~Api/Investor/AccountTypeEnum';
import IAccount from '~Api/Investor/IAccount';
import IAnnualStatement from '~Api/Investor/IAnnualStatement';
import IIncomeTrust from '~Api/Investor/IIncomeTrust';
import IInvestor from '~Api/Investor/IInvestor';
import IMonthlyStatement from '~Api/Investor/IMonthlyStatement';
import InterestPaymentStrategyEnum from '~Api/Investor/InterestPaymentStrategyEnum';
import IReferral from '~Api/Investor/IReferral';
import IShare from '~Api/Investor/IShare';
import ITransaction from '~Api/Investor/ITransaction';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { IIncomeTrustInvestErrors, IInvestErrors, IReferralAddErrors } from './reducer';
import IncomeTrustClassTypeEnum from '~Api/Investor/IncomeTrustClassTypeEnum';

export function currentInvestorAccountAnnualStatementsSelector(state: IGlobalState, accountType: AccountTypeEnum): IDictionary<IAnnualStatement> {
    const investorAccount: IAccount = currentInvestorAccountSelector(state, accountType);

    if (!investorAccount || !state.investor.accountAnnualStatementUuids || !state.investor.accountAnnualStatementUuids[investorAccount.uuid]) {
        return null;
    }

    const annualStatements: IDictionary<IAnnualStatement> = {};
    _.forEach(state.investor.accountAnnualStatementUuids[investorAccount.uuid], (annualStatementUuid: string) => {
        annualStatements[annualStatementUuid] = state.investor.annualStatements[annualStatementUuid];
    });

    return annualStatements;
}

export function availableInvestmentsSelector(state: IGlobalState): IInvestment[] {
    return state.investor.availableInvestments && state.investor.availableInvestments.map((uuid: string) => {
        return investmentSelector(state, uuid);
    });
}

export function completeSignupModalShowSelector(state: IGlobalState): boolean {
    return state.investor.completeSignupModalShow;
}

export function currentInvestorSelector(state: IGlobalState): IInvestor {
    return state.investor.currentInvestor;
}

export function currentInvestorAccountSelector(state: IGlobalState, accountType: AccountTypeEnum): IAccount {
    const currentInvestor: IInvestor = currentInvestorSelector(state);
    if (!currentInvestor) {
        return null;
    }

    const investorAccount: IAccount = currentInvestor.accounts[accountType];
    if (!investorAccount) {
        return null;
    }

    return investorAccount;
}

export function currentInvestorAccountIncomeTrustByClassTypeSelector(state: IGlobalState, classType: IncomeTrustClassTypeEnum): IIncomeTrust {
    if (!state.investor.incomeTrusts || !state.investor.incomeTrustClassTypeUuids[classType]) {
        return null;
    }

    return state.investor.incomeTrusts[state.investor.incomeTrustClassTypeUuids[classType]];
}

export function currentInvestorAccountIncomeTrustInterestPaymentStrategySelector(state: IGlobalState): InterestPaymentStrategyEnum {
    const currentInvestorIncomeTrustAccount: IAccount = currentInvestorAccountSelector(state, AccountTypeEnum.IncomeTrust);

    if (currentInvestorIncomeTrustAccount) {
        return currentInvestorIncomeTrustAccount.incomeTrust.interestPaymentStrategy;
    }

    return sessionStorage.getItem('interestPaymentStrategy') as InterestPaymentStrategyEnum;
}

export function incomeTrustsSelector(state: IGlobalState): IDictionary<IIncomeTrust> {
    return state.investor.incomeTrusts;
}

export function investAmountSelector(state: IGlobalState): number {
    return state.investor.investAmount;
}

export function incomeTrustInvestCompleteModalShowSelector(state: IGlobalState): boolean {
    return state.investor.incomeTrustInvestCompleteModalShow;
}

export function incomeTrustInvestConfirmModalShowSelector(state: IGlobalState): boolean {
    return state.investor.incomeTrustInvestConfirmModalShow;
}

export function incomeTrustInvestErrorsSelector(state: IGlobalState): IIncomeTrustInvestErrors {
    return state.investor.incomeTrustInvestErrors;
}

export function incomeTrustInvestInProgressSelector(state: IGlobalState): boolean {
    return state.investor.incomeTrustInvestInProgress;
}

export function investCompleteModalShowSelector(state: IGlobalState): boolean {
    return state.investor.investCompleteModalShow;
}

export function investErrorsSelector(state: IGlobalState): IInvestErrors {
    return state.investor.investErrors;
}

export function investInProgressSelector(state: IGlobalState): boolean {
    return state.investor.investInProgress;
}

export function investModalShowSelector(state: IGlobalState): boolean {
    return state.investor.investModalShow;
}

export function investmentSelector(state: IGlobalState, uuid: string): IInvestment {
    return state.investor.investments[uuid];
}

export function monthlyStatementsSelector(state: IGlobalState, accountType: AccountTypeEnum): IDictionary<IMonthlyStatement> {
    const investorAccount: IAccount = currentInvestorAccountSelector(state, accountType);

    if (!investorAccount || !state.investor.accountMonthlyStatementUuids || !state.investor.accountMonthlyStatementUuids[investorAccount.uuid]) {
        return null;
    }

    const monthlyStatements: IDictionary<IMonthlyStatement> = {};
    _.forEach(state.investor.accountMonthlyStatementUuids[investorAccount.uuid], (monthlyStatementUuid: string) => {
        monthlyStatements[monthlyStatementUuid] = state.investor.monthlyStatements[monthlyStatementUuid];
    });

    return monthlyStatements;
}

export function recentlyFundedInvestmentsSelector(state: IGlobalState): IInvestment[] {
    return state.investor.recentlyFundedInvestments && state.investor.recentlyFundedInvestments.map((uuid: string) => {
        return investmentSelector(state, uuid);
    });
}

export function referralAddErrorsSelector(state: IGlobalState): IReferralAddErrors {
    return state.investor.referralAddErrors;
}

export function referralAddInProgressSelector(state: IGlobalState): boolean {
    return state.investor.referralAddInProgress;
}

export function referralEmailSelector(state: IGlobalState): string {
    return state.investor.referralEmail;
}

export function referralsSelector(state: IGlobalState): IReferral[] {
    return state.investor.referrals && _.values(state.investor.referrals);
}

export function registerErrorsSelector(state: IGlobalState): IDictionary<string> {
    return state.investor.registerErrors;
}

export function registerInProgressSelector(state: IGlobalState): boolean {
    return state.investor.registerInProgress;
}

export function sharesSelector(state: IGlobalState): IShare[] {
    return state.investor.shares && _.values(state.investor.shares);
}

export function topUpCompleteModalShowSelector(state: IGlobalState): boolean {
    return state.investor.topUpCompleteModalShow;
}

export function topUpConfirmModalShowSelector(state: IGlobalState): boolean {
    return state.investor.topUpConfirmModalShow;
}

export function topUpErrorsSelector(state: IGlobalState): IDictionary<string> {
    return state.investor.topUpErrors;
}

export function topUpInProgressSelector(state: IGlobalState): boolean {
    return state.investor.topUpInProgress;
}

export function topUpModalShowSelector(state: IGlobalState): boolean {
    return state.investor.topUpModalShow;
}

export function transactionsSelector(state: IGlobalState): ITransaction[] {
    return state.investor.transactions && _.values(state.investor.transactions);
}

export function withdrawCompleteModalShowSelector(state: IGlobalState): boolean {
    return state.investor.withdrawCompleteModalShow;
}

export function withdrawConfirmModalShowSelector(state: IGlobalState): boolean {
    return state.investor.withdrawConfirmModalShow;
}

export function withdrawErrorsSelector(state: IGlobalState): IDictionary<string> {
    return state.investor.withdrawErrors;
}

export function withdrawModalShowSelector(state: IGlobalState): boolean {
    return state.investor.withdrawModalShow;
}

export function withdrawInProgressSelector(state: IGlobalState): boolean {
    return state.investor.withdrawInProgress;
}
