import { fetchPostUtility } from '~utilities/fetch';

export async function authLoginRequest(email: string, password: string): Promise<Response> {
    return fetchPostUtility('/auth/login', {
        email,
        password,
        type: 'INVESTOR',
    });
}

export async function authMagicLinkCreateRequest(email: string): Promise<Response> {
    return fetchPostUtility('/auth/magic-link', {
        email,
        type: 'INVESTOR',
    });
}

export async function authMagicLinkRedeemRequest(code: string): Promise<Response> {
    return fetchPostUtility(`/auth/magic-link/${code}/redeem`, {
        type: 'INVESTOR',
    });
}

interface IAuthRegisterRequest {
    accountType: string;
    email: string;
    firstName: string;
    landingPage: string;
    lastName: string;
    password: string;
    phone: string;
    role: string;
}

export async function authRegisterRequest(body: IAuthRegisterRequest): Promise<Response> {
    return fetchPostUtility('/auth/register', body);
}
