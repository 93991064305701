import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import history from '~history';
import { IGlobalState } from '~reducer';
import ScrollToTop from '~ScrollToTop';
import Amount from './Amount';
import Borrowers from './Borrowers';
import BorrowerType from './BorrowerType';
import Properties from './Properties';
import Purpose from './Purpose';
import QuoteConvert from './QuoteConvert';
import QuoteConvertError from './QuoteConvertError';
import Repayment from './Repayment';
import { tokenSelector } from './selectors';
import Submit from './Submit';
import Term from './Term';
import Thanks from './Thanks';
import Welcome from './Welcome';
import IToken from '~Auth/IToken';

interface IPropsSelector {
    token: IToken;
}

type Props = IPropsSelector;

class ApplicationRouter extends React.Component<Props> {
    public render(): JSX.Element {
        const { token } = this.props;

        if (!token) {
            return (
                <Router history={history}>
                    <ScrollToTop/>
                    <Switch>
                        <Route exact={true} path='/application' component={Welcome} />
                        <Route exact={true} path='/application/thanks' component={Thanks} />
                        <Route exact={true} path='/quote/error' component={QuoteConvertError} />
                        <Route exact={true} path='/quote/:code' component={QuoteConvert} />
                        <Redirect to='/' />
                    </Switch>
                </Router>
            );
        }

        return (
            <Router history={history}>
                <ScrollToTop/>
                <Switch>
                    <Route exact={true} path='/application' component={Welcome} />
                    <Route exact={true} path='/application/purpose' component={Purpose} />
                    <Route exact={true} path='/application/amount' component={Amount} />
                    <Route exact={true} path='/application/term' component={Term} />
                    <Route exact={true} path='/application/repayment' component={Repayment} />
                    <Route exact={true} path='/application/properties' component={Properties} />
                    <Route exact={true} path='/application/borrower-type' component={BorrowerType} />
                    <Route exact={true} path='/application/borrowers' component={Borrowers} />
                    <Route exact={true} path='/application/submit' component={Submit} />
                    <Route exact={true} path='/application/thanks' component={Thanks} />
                    <Route exact={true} path='/quote/error' component={QuoteConvertError} />
                    <Route exact={true} path='/quote/:code' component={QuoteConvert} />
                    <Redirect to='/' />
                </Switch>
            </Router>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        token: tokenSelector(state),
    };
}

export default connect(
    mapStateToProps,
    null,
)(ApplicationRouter);
