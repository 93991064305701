import React from 'react';
import { Container } from 'react-bootstrap';
import Layout from './Layout';

class Thanks extends React.Component {
    public render(): JSX.Element {
        return (
            <Layout>
                <div className='broker'>
                    <Container className='register'>
                        <h3>Become an Accredited Broker</h3>
                        <p className='intro'>Thanks for your application, we'll be in touch soon.</p>
                    </Container>
                </div>
            </Layout>
        );
    }
}

export default Thanks;
