import _ from 'lodash';
import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IDeal from '~Api/Deal/IDeal';
import { IGlobalState } from '~reducer';
import { dealGetAction } from './actions';
import Layout from './Layout';
import {
    applicationSelector,
    currentDealSelector,
} from './selectors';

interface IPropsSelector {
    application: IApplication;
    deal: IDeal;
}

interface IPropsDispatch {
    dealGet: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Term extends React.Component<Props> {
    public componentDidMount() {
        const { application, deal } = this.props;

        if (!application || !deal) {
            this.props.dealGet();
        }
    }

    public render(): JSX.Element {
        const { application, deal } = this.props;

        if (!application || !deal) {
            return (
                <Layout containerClass='loan-term' stepNumber={1}>
                    <h3>Loan Details</h3>
                    <Spinner animation='border' />
                </Layout>
            );
        }

        return (
            <Layout containerClass='loan-term' stepNumber={1}>
                <h3>Loan Details</h3>
                <p className='intro'>Please confirm the following loan term.</p>
                <Form>
                    <Form.Group className='term-months'>
                        <Form.Label>Loan Term Required</Form.Label>
                        <p>{application.termMonths} month{application.termMonths !== 1 && 's'}</p>
                    </Form.Group>
                    <div className='actions'>
                        <Link className='previous' to='/application/amount'><Button variant='primary'>Previous</Button></Link>
                        <Link className='continue' to='/application/repayment'><Button variant='primary'>Continue</Button></Link>
                    </div>
                </Form>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        application: applicationSelector(state),
        deal: currentDealSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        dealGet: () => dispatch(dealGetAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Term);
