import React from 'react';
import { Container } from 'react-bootstrap';
import Lottie, { Options } from 'react-lottie';
import success from '~UI/success.json';
import Layout from './Layout';

class Thanks extends React.Component {
    public render(): JSX.Element {
        const options: Options = {
            animationData: success,
            autoplay: true,
            loop: false,
        };

        return (
            <Layout>
                <div className='partner'>
                    <Container className='referral'>
                        <h3>Success</h3>
                        <p className='intro'>Thanks for the Referral. We will contact the client within 10 days (normally within 24 hours).</p>
                        <Lottie height={180} options={options} width={180} />
                    </Container>
                </div>
            </Layout>
        );
    }
}

export default Thanks;
