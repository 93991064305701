import { fetchGetUtility, fetchPostFileUtility, fetchPostUtility, fetchPutUtility, fetchUtility } from '~utilities/fetch';
import IBroker from './IBroker';
import { transformBroker } from './transformers';

export async function aggregatorListRequest(): Promise<Response> {
    return fetchUtility('/aggregators');
}

export async function brokerAddRequest(broker: IBroker): Promise<Response> {
    return fetchPostUtility('/brokers', transformBroker(broker));
}

export async function brokerCheckEmailRequest(email: string): Promise<Response> {
    return fetchPostUtility(`/brokers/check-email`, {
        email,
    });
}

export async function brokerCompleteRequest(uuid: string): Promise<Response> {
    return fetchPostUtility(`/brokers/${uuid}/complete`);
}

export async function brokerGetRequest(uuid: string): Promise<Response> {
    return fetchGetUtility(`/brokers/${uuid}`);
}

export async function brokerInsuranceProofRequest(uuid: string, insuranceProof: File): Promise<Response> {
    return fetchPostFileUtility(`/brokers/${uuid}/insurance-proof`, insuranceProof);
}

export async function brokerMembershipDocumentRequest(uuid: string, membershipDocument: File): Promise<Response> {
    return fetchPostFileUtility(`/brokers/${uuid}/membership-document`, membershipDocument);
}

export async function brokerPhotoIdRequest(uuid: string, photoId: File): Promise<Response> {
    return fetchPostFileUtility(`/brokers/${uuid}/photo-id`, photoId);
}

export async function brokerPasswordRequest(uuid: string, password: string): Promise<Response> {
    return fetchPostUtility(`/brokers/${uuid}/password`, {
        password,
    });
}

export async function brokerUpdateRequest(broker: IBroker): Promise<Response> {
    return fetchPutUtility(`/brokers/${broker.uuid}`, transformBroker(broker));
}

export async function brokerVerifyEmailRequest(code: string): Promise<Response> {
    return fetchPostUtility(`/brokers/verify-email/${code}`);
}
