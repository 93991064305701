import { all, call, put, select, takeEvery, takeLeading } from '@redux-saga/core/effects';
import Cookies from 'js-cookie';
import IBroker from '~Api/Broker/IBroker';
import { parseBroker } from '~Api/Broker/parsers';
import {
    brokerCheckEmailRequest,
    brokerGetRequest,
    brokerPasswordRequest,
    brokerUpdateRequest,
    brokerVerifyEmailRequest,
} from '~Api/Broker/requests';
import { IDeal, IDealProperty } from '~Api/Deal';
import { parseDeal, parseProperty } from '~Api/Deal/parsers';
import {
    dealAddRequest,
    dealCompleteRequest,
    dealFileAddRequest,
    dealPropertiesAddRequest,
} from '~Api/Deal/requests';
import UiSourceEnum from '~Api/Deal/UiSourceEnum';
import { authTokenSetAction } from '~Auth/actions';
import IAuthUser from '~Auth/IAuthUser';
import RoleEnum from '~Auth/RoleEnum';
import { authCurrentUserSelector } from '~Auth/selectors';
import history from '~history';
import { dealSetAction, propertySetAction } from '~Lead/actions';
import { IFetchResponse } from '~utilities/fetch';
import {
    IBrokerCheckEmailAction,
    IBrokerDealAddAction,
    IBrokerPasswordAction,
    IBrokerVerifyEmailAction,
    ICurrentBrokerAddressUpdateAction,
    ICurrentBrokerGetAction,
    brokerInProgressAction,
    brokerLoginErrorSetAction,
    brokerLoginErrorsSetAction,
    brokerSetAction,
    currentBrokerAddressUpdateModalShowAction,
} from './actions';
import BrokerActionsEnum from './ActionsEnum';
import { currentBrokerSelector } from './selectors';
import { verifyToken } from '~utilities/jwt';

enum CheckEmailResultEnum {
    ContactSupport = 'CONTACT_SUPPORT',
    NotFound = 'NOT_FOUND',
    NotVerified = 'NOT_VERIFIED',
    Verified = 'VERIFIED',
}

function* brokerAddressUpdate(action: ICurrentBrokerAddressUpdateAction): Iterator<unknown> {
    yield put(brokerInProgressAction(true));

    const currentBroker: IBroker = yield select(currentBrokerSelector);
    const brokerUpdateResponse: IFetchResponse = yield call(brokerUpdateRequest, {
        ...currentBroker,
        postalAddress: action.postalAddress,
        postcode: action.postcode,
        state: action.state,
        suburb: action.suburb,
    });
    const broker: IBroker = yield parseBroker(brokerUpdateResponse.body);
    yield put(brokerSetAction(broker));

    yield put(currentBrokerAddressUpdateModalShowAction(false));
    yield put(brokerInProgressAction(false));
}

function* brokerCheckEmail(action: IBrokerCheckEmailAction): Iterator<unknown> {
    yield put(brokerInProgressAction(true));

    const brokerCheckEmailResponse: IFetchResponse = yield call(brokerCheckEmailRequest, action.email);
    switch (brokerCheckEmailResponse.body) {
        case CheckEmailResultEnum.ContactSupport:
            history.push('/broker');
            yield put(brokerLoginErrorSetAction('email', 'Please contact us regarding your account.'));
            break;
        case CheckEmailResultEnum.NotFound:
            history.push('/broker/register');
            break;
        case CheckEmailResultEnum.NotVerified:
            history.push('/broker/login-require-email-verification');
            break;
        case CheckEmailResultEnum.Verified:
            history.push('/broker/login-password');
            break;
    }

    yield put(brokerInProgressAction(false));
}

function* brokerDealAdd(action: IBrokerDealAddAction): Iterator<unknown> {
    yield put(brokerInProgressAction(true));

    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Broker) {
        const dealAddResponse: IFetchResponse = yield call(dealAddRequest, { ...action.deal, uiSource: UiSourceEnum.BrokerPortal });
        const parsedDeal: IDeal = parseDeal(dealAddResponse.body);
        yield put(dealSetAction(parsedDeal));

        for (const property of action.properties) {
            const dealPropertiesAddResponse: IFetchResponse = yield call(dealPropertiesAddRequest, parsedDeal, property, 'currentUser');
            const parsedProperty: IDealProperty = parseProperty(dealPropertiesAddResponse.body);
            yield put(propertySetAction(parsedProperty));
        }

        for (const document of action.supportingDocuments) {
            yield call(dealFileAddRequest, parsedDeal, document);
        }

        yield call(dealCompleteRequest, parsedDeal, false);
    }

    yield put(brokerInProgressAction(false));

    history.push('/applications/success');
}

function* brokerVerifyEmail(action: IBrokerVerifyEmailAction): Iterator<unknown> {
    yield put(brokerInProgressAction(true));

    const brokerVerifyEmailResponse: IFetchResponse = yield call(brokerVerifyEmailRequest, action.code);
    if (brokerVerifyEmailResponse.status === 422) {
        yield put(brokerLoginErrorsSetAction(brokerVerifyEmailResponse.body));
        history.push('/broker');
    } else {
        const token: string = brokerVerifyEmailResponse.headers.get('Funding-Auth-Token');
        verifyToken(token);
        Cookies.set('jwt_token', token);

        const broker: IBroker = parseBroker(brokerVerifyEmailResponse.body);
        yield put(brokerSetAction(broker));

        history.push(`/broker/login-set-password`);
    }

    yield put(brokerInProgressAction(false));
}

function* brokerPassword(action: IBrokerPasswordAction): Iterator<unknown> {
    yield put(brokerInProgressAction(true));

    const currentBroker: IBroker = yield select(currentBrokerSelector);
    const brokerPasswordResponse: IFetchResponse = yield call(brokerPasswordRequest, currentBroker.uuid, action.password);
    if (brokerPasswordResponse.status === 422) {
        yield put(brokerLoginErrorsSetAction(brokerPasswordResponse.body));
    } else if (brokerPasswordResponse.status === 200) {
        const token: string = brokerPasswordResponse.headers.get('Funding-Auth-Token');
        verifyToken(token);

        yield put(authTokenSetAction(token));

        Cookies.set('currentUser', {
            token,
        });

        history.push('/');
    }

    yield put(brokerInProgressAction(false));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* currentBrokerGet(action: ICurrentBrokerGetAction): Iterator<unknown> {
    const currentUser: IAuthUser = yield select(authCurrentUserSelector);
    if (currentUser.role === RoleEnum.Broker) {
        const brokerGetResponse: IFetchResponse = yield call(brokerGetRequest, currentUser.currentBrokerUuid);
        const broker: IBroker = parseBroker(brokerGetResponse.body);
        yield put(brokerSetAction(broker));
    }
}

export function* BrokerSagas(): Iterator<unknown> {
    yield all([
        takeEvery(BrokerActionsEnum.BrokerAddressUpdate, brokerAddressUpdate),
        takeEvery(BrokerActionsEnum.BrokerCheckEmail, brokerCheckEmail),
        takeEvery(BrokerActionsEnum.BrokerDealAdd, brokerDealAdd),
        takeEvery(BrokerActionsEnum.BrokerPassword, brokerPassword),
        takeEvery(BrokerActionsEnum.BrokerVerifyEmail, brokerVerifyEmail),
        takeLeading(BrokerActionsEnum.CurrentBrokerGet, currentBrokerGet),
    ]);
}
