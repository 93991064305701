import React from 'react';
import { Alert, Button, Form, Modal, Spinner, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import roundTo from 'round-to';
import IInvestor from '~Api/Investor/IInvestor';
import {
    currentInvestorGetAction,
    withdrawAction,
    withdrawCompleteModalHideAction,
    withdrawConfirmModalShowAction,
    withdrawErrorSetAction,
    withdrawModalHideAction,
} from '~Investor/actions';
import {
    currentInvestorSelector,
    withdrawCompleteModalShowSelector,
    withdrawConfirmModalShowSelector,
    withdrawErrorsSelector,
    withdrawInProgressSelector,
    withdrawModalShowSelector,
} from '~Investor/selectors';
import { IGlobalState } from '~reducer';
import './withdraw-modal.less';
import _ from 'lodash';
import { IDictionary } from '~utilities/IDictionary';

interface IState {
    amount: number;
}

interface IPropsSelector {
    currentInvestor: IInvestor;
    isWithdrawCompleteModalOpen: boolean;
    isWithdrawConfirmModalOpen: boolean;
    show: boolean;
    withdrawErrors: IDictionary<string>;
    withdrawInProgress: boolean;
}

interface IPropsDispatch {
    currentInvestorGet: () => void;
    hide: () => void;
    setError: (key: string, value: string) => void;
    withdraw: (amount: number) => void;
    withdrawCompleteModalHide: () => void;
    withdrawConfirmModalShow: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class WithdrawModal extends React.Component<Props, IState> {
    public state: IState = {
        amount: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
        this.validateAmount = this.validateAmount.bind(this);
    }

    public render(): JSX.Element {
        const { currentInvestor, isWithdrawCompleteModalOpen, show, withdrawErrors, withdrawInProgress } = this.props;
        const { amount } = this.state;

        if (!currentInvestor.bankAccount) {
            return null;
        }

        if (isWithdrawCompleteModalOpen) {
            return (
                <Modal
                    backdropClassName='investor-marketplace-withdraw-modal-backdrop'
                    dialogClassName='investor-marketplace-withdraw-modal complete'
                    onHide={this.props.withdrawCompleteModalHide}
                    show={isWithdrawCompleteModalOpen}
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title>Thank you for submitting a withdrawal request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>We will now process your withdrawal request. Please allow 3-5 business days for funds to be cleared in your nominated account.</p>
                        <Button onClick={this.props.withdrawCompleteModalHide}>OK</Button>
                    </Modal.Body>
                </Modal>
            );
        }

        const firstUnhandledError: string = _.head(_.values(_.omit(withdrawErrors, ['amount'])));

        return (
            <Modal
                backdropClassName='investor-marketplace-withdraw-modal-backdrop'
                dialogClassName='investor-marketplace-withdraw-modal'
                onHide={this.props.hide}
                show={show}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Withdraw Funds</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {firstUnhandledError && <Alert variant='danger'>{firstUnhandledError}</Alert>}
                    <p className='instructions'>Enter the dollar amount you wish to take out of your account.</p>
                    <Form.Group className='amount'>
                        <Form.Label>Amount in AUD $</Form.Label>
                        <Form.Control isInvalid={!!withdrawErrors.amount} onBlur={this.validateAmount} onChange={this.onChangeAmount} placeholder='1000' type='number' value={amount || ''} />
                        {!!withdrawErrors.amount && <Form.Control.Feedback type='invalid'>{withdrawErrors.amount}</Form.Control.Feedback>}
                    </Form.Group>
                    <Table className='bank-details' >
                        <tbody>
                            <tr>
                                <th>Bank name</th>
                                <td>{currentInvestor.bankAccount.bankName}</td>
                            </tr>
                            <tr>
                                <th>Account name</th>
                                <td>{currentInvestor.bankAccount.accountName}</td>
                            </tr>
                            <tr>
                                <th>BSB</th>
                                <td>{currentInvestor.bankAccount.bsb}</td>
                            </tr>
                            <tr>
                                <th>Account number</th>
                                <td>{currentInvestor.bankAccount.accountNumber}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <p>Please contact us before submitting this request at <a href='mailto:invest@funding.com.au'>invest@funding.com.au</a> if your account details have changed.</p>
                    <Button className='withdraw' disabled={withdrawInProgress} onClick={this.onClickOk} variant='primary'>
                        {withdrawInProgress && <Spinner animation='border' size='sm' />} Submit
                    </Button>
                </Modal.Body>
            </Modal>
        );
    }

    private onChangeAmount(event: React.ChangeEvent<HTMLInputElement>): void {
        if (/[^0-9.]/.test(event.target.value)) {
            return;
        }

        this.setState({
            amount: roundTo(Number(event.target.value), 2),
        });
    }

    private onClickOk(): void {
        const { amount } = this.state;

        let valid: boolean = true;
        valid = this.validateAmount() && valid;

        if (!valid) {
            return;
        }

        this.props.withdraw(amount);
    }

    private validateAmount(): boolean {
        const { amount } = this.state;

        let error: string;

        if (!amount || amount <= 0) {
            error = 'Please enter the amount';
        } else {
            // @TODO: Check their balance
        }

        this.props.setError('amount', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentInvestor: currentInvestorSelector(state),
        isWithdrawCompleteModalOpen: withdrawCompleteModalShowSelector(state),
        isWithdrawConfirmModalOpen: withdrawConfirmModalShowSelector(state),
        show: withdrawModalShowSelector(state),
        withdrawErrors: withdrawErrorsSelector(state),
        withdrawInProgress: withdrawInProgressSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentInvestorGet: () => dispatch(currentInvestorGetAction()),
        hide: () => dispatch(withdrawModalHideAction()),
        setError: (key: string, value: string) => dispatch(withdrawErrorSetAction(key, value)),
        withdraw: (amount: number) => dispatch(withdrawAction(amount)),
        withdrawCompleteModalHide: () => dispatch(withdrawCompleteModalHideAction()),
        withdrawConfirmModalShow: () => dispatch(withdrawConfirmModalShowAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WithdrawModal);
