import { fetchPostFileUtility, fetchPostUtility } from '~utilities/fetch';
import IReferralPartner from './IReferralPartner';
import { transformReferralPartner } from './transformers';

export async function referralPartnerAddRequest(referralPartner: IReferralPartner): Promise<Response> {
    return fetchPostUtility(`/referral-partners`, transformReferralPartner(referralPartner));
}

export async function referralPartnerCompleteRequest(referralPartner: IReferralPartner): Promise<Response> {
    return fetchPostUtility(`/referral-partners/${referralPartner.uuid}/complete`);
}

export async function referralPartnerFileAddRequest(referralPartner: IReferralPartner, file: File): Promise<Response> {
    return fetchPostFileUtility(`/referral-partners/${referralPartner.uuid}/files`, file);
}
