import IUser from './IUser';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseUser(raw: any): IUser {
    return {
        email: raw.email,
        firstName: raw.firstName,
        lastName: raw.lastName,
        name: raw.name,
        phone: raw.phone,
        referralCode: raw.referralCode,
        uuid: raw.uuid,
    };
}
