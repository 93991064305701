import React from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { IGlobalState } from '~reducer';
import { authMagicLinkRedeemAction } from './actions';
import Layout from './Layout';
import { authMagicLinkRedeemErrorSelector } from './selectors';

interface IMatch {
    code: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    magicLinkRedeemError: string;
}

interface IPropsDispatch {
    magicLinkRedeem: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class LoginLink extends React.Component<Props> {
    public componentDidMount() {
        this.props.magicLinkRedeem();
    }

    public render(): JSX.Element {
        const { magicLinkRedeemError } = this.props;

        return (
            <Layout section='login-link'>
                {magicLinkRedeemError && <Alert variant='danger'>{magicLinkRedeemError}</Alert>}
                {magicLinkRedeemError && <Link className='login' to='/'>Log In</Link>}
                {!magicLinkRedeemError && <Spinner animation='border'/>}
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        magicLinkRedeemError: authMagicLinkRedeemErrorSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        magicLinkRedeem: () => dispatch(authMagicLinkRedeemAction(ownProps.match.params.code)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginLink);
