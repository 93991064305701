import React from 'react';
import { Container } from 'react-bootstrap';
import Layout from './Layout';

class RequireApproval extends React.Component {
    public render(): JSX.Element {
        return (
            <Layout>
                <div className='broker'>
                    <Container className='require-approval'>
                        <h3>Approval Required</h3>
                        <p className='intro'>You have registered to be a Broker but have not been approved yet. Please contact us for more information.
                        </p>
                    </Container>
                </div>
            </Layout>
        );
    }
}

export default RequireApproval;
