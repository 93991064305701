import StateEnum from './StateEnum';

export function validatePostcodeState(postcode: string, state: StateEnum): boolean {
    let valid = true;
    const firstChar = postcode.charAt(0);

    switch (state) {
        case StateEnum.AustralianCapitalTerritory:
        case StateEnum.NewSouthWales:
            if (firstChar !== '2') {
                valid = false;
            }
            break;

        case StateEnum.NorthernTerritory:
            if ((postcode.length === 3 && firstChar !== '8') || (postcode.length === 4 && postcode.substring(0, 2) !== '08')) {
                valid = false;
            }
            break;

        case StateEnum.Queensland:
            if (firstChar !== '4') {
                valid = false;
            }
            break;

        case StateEnum.SouthAustralia:
            if (firstChar !== '5') {
                valid = false;
            }
            break;

        case StateEnum.Tasmania:
            if (firstChar !== '7') {
                valid = false;
            }
            break;

        case StateEnum.Victoria:
            if (firstChar !== '3') {
                valid = false;
            }
            break;

        case StateEnum.WesternAustralia:
            if (firstChar !== '6') {
                valid = false;
            }
            break;
    }

    return valid;
}

export function validateTaxFileNumber(tfn?: string): boolean {
    const cleanTfn: string = tfn ? tfn.replace(/[^\d]/g, '') : '';

    if (cleanTfn.length < 8 || cleanTfn.length > 9) {
        return false;
    }

    let sum: number = 0;
    const weights: number[] = cleanTfn.length === 9 ? [10, 7, 8, 4, 6, 3, 5, 2, 1] : [10, 7, 8, 4, 6, 3, 5, 1];

    weights.forEach((weight: number, position: number) => {
        sum += weight * Number(cleanTfn[position]);
    });

    return (sum % 11) === 0;
}
