import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import './help-centre.less';
import Layout from './Layout';

class HelpCentre extends React.Component {
    public render(): JSX.Element {
        return (
            <Layout section='faq'>
                <h1>Help Centre</h1>
                <div className='content-box'>
                    <Accordion>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='0'>Who can invest?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='0'>
                                <Card.Body>
                                    All types of investors can apply to invest including individuals, companies, trusts and Self Managed Super Funds (SMSFs).
                                    Individuals must be over the age of 18 years old.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='1'>What is a first mortgage?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='1'>
                                <Card.Body>
                                    Our Loans are backed by a registered first mortgage loan against Australian real estate.
                                    A first mortgage is a first charge over real estate owned by the borrower.
                                    If there is a Default in repayment of the Loan, the property can be sold in order to recover the Loan and repay Members.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='2'>What type of real estate security is provided?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='2'>
                                <Card.Body>
                                    The security property varies from loan to loan however each loan is secured over either Residential or Commercial real estate in locations across Australia.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='3'>What type of borrowers do we lend to?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='3'>
                                <Card.Body>
                                    Borrowers must have Australian real estate security, the ability to meet their repayments and a repayment strategy to exit the Loan at the end of the term.
                                    Borrowers can be individuals or companies or trusts borrowing for a wide range of purposes.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='4'>How does the Platform work?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='4'>
                                <Card.Body>
                                    Once an investor has signed up and is approved, they can select, invest and manage their investments via our platform and professional management staff.<br />
                                    <br />
                                    An investor can choose to fully fund a Loan or invest alongside other Members in a particular Loan.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='5'>Are my funds pooled?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='5'>
                                <Card.Body>
                                    No. Each investment is in a single stand-alone mortgage and the investor can diversify their portfolio by selecting and investing in multiple investments on offer.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='6'>What is the Funding Investment Trust?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='6'>
                                <Card.Body>
                                    The Funding Investment Trust (“the Trust”) is an ASIC registered (ARSN 616 185 276) managed investment scheme where members of the Trust are provided with access to first mortgage investments.<br />
                                    <br />
                                    Manager: Funding.com.au Pty Ltd ACN 603 756 547, AR No. 1239776<br />
                                    <br />
                                    Trustee: Melbourne Securities Corporation Ltd ACN 160 326 545, AFSL 428289<br />
                                    <br />
                                    Custodian: Sandhurst Trustees Limited ACN 004 030 737
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='7'>How do I get a copy of the PDS for the Funding Investment Trust?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='7'>
                                <Card.Body>
                                    You can download a copy from our website (<a href='https://www.funding.com.au/pds/' target='_blank'>click here</a>) or alternatively please contact our staff to obtain a copy.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='8'>What is the LVR on loans?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='8'>
                                <Card.Body>
                                    The loan to value ratio or LVR is the maximum lend secured over the property.
                                    The LVR is specific to the individual mortgage selected by the investor however the typical LVR is less than 70% of the value of the security property.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='9'>What happens if the borrower defaults?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='9'>
                                <Card.Body>
                                    The borrower grants a mortgage over their real estate.
                                    In the event of default, the lending entity will seek to recover and sell the security property if necessary.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey='10'>What is the difference between LAI &amp; APP loan numbers?</Accordion.Toggle>
                            <Accordion.Collapse eventKey='10'>
                                <Card.Body>
                                    Loan numbers beginging with LAI are performing loans.
                                    Your investment in these loans will therefore start the first full working day after that date that you selected to invest online.<br />
                                    <br />
                                    Loan numbers begining with APP are loans still in the application stage and have therefore not been advanced to the borrower yet.
                                    Once full funding has been raised, the loan will be advanced to the borrower and your investment will commence.
                                    The funding period is not, typically, more than 2 weeks but may vary on some occasions.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
            </Layout>
        );
    }
}

export default HelpCentre;
