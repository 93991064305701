import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import RequireApproval from '~/Public/Broker/RequireApproval';
import ApprovalStatusEnum from '~Api/Broker/ApprovalStatusEnum';
import IBroker from '~Api/Broker/IBroker';
import IAuthUser from '~Auth/IAuthUser';
import RoleEnum from '~Auth/RoleEnum';
import { authCurrentUserSelector } from '~Auth/selectors';
import history from '~history';
import { IGlobalState } from '~reducer';
import ScrollToTop from '~ScrollToTop';
import VerifyEmail from '~User/VerifyEmail';
import VerifyEmailVerified from '~User/VerifyEmailVerified';
import { currentBrokerGetAction } from './actions';
import ApplicationRequest from './ApplicationRequest';
import Applications from './Applications';
import ApplicationSuccess from './ApplicationSuccess';
import Dashboard from './Dashboard';
import MyDetails from './MyDetails';
import ProductAndTools from './ProductAndTools';
import { currentBrokerSelector } from './selectors';
import Team from './Team';

interface IPropsSelector {
    currentBroker: IBroker;
    user: IAuthUser;
}

interface IPropsDispatch {
    currentBrokerGet: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class BrokerRouter extends React.Component<Props> {
    public componentDidMount() {
        const { currentBroker } = this.props;

        if (!currentBroker) {
            this.props.currentBrokerGet();
        }
    }

    public render(): JSX.Element {
        const { currentBroker, user } = this.props;

        if (user.role !== RoleEnum.Broker || !currentBroker) {
            return null;
        }

        if (currentBroker.approvalStatus !== ApprovalStatusEnum.Approved) {
            return (
                <Router history={history}>
                    <ScrollToTop/>
                    <Switch>
                        <Route exact={true} path='/broker/require-approval' component={RequireApproval} />
                        <Redirect to='/broker/require-approval' />
                    </Switch>
                </Router>
            );
        }

        return (
            <Router history={history}>
                <ScrollToTop/>
                <Switch>
                    <Route exact={true} path='/' component={Dashboard} />
                    <Route exact={true} path='/team' component={Team} />
                    <Route exact={true} path='/applications/submit' component={ApplicationRequest} />
                    <Route exact={true} path='/my-details' component={MyDetails} />
                    <Route exact={true} path='/product-and-tools' component={ProductAndTools} />
                    <Route exact={true} path='/applications/success' component={ApplicationSuccess} />
                    <Route exact={true} path='/applications' component={Applications} />
                    <Route exact={true} path='/verify-email/verified' component={VerifyEmailVerified} />
                    <Route exact={true} path='/verify-email/:code' component={VerifyEmail} />
                    <Redirect to='/' />
                </Switch>
            </Router>
        );
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentBroker: currentBrokerSelector(state),
        user: authCurrentUserSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        currentBrokerGet: () => dispatch(currentBrokerGetAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BrokerRouter);
