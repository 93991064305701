import React from 'react';

class RegularIncome extends React.Component {
    public render(): JSX.Element {
        return (
            <svg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M45 90C69.8528 90 90 69.8528 90 45C90 20.1472 69.8528 0 45 0C20.1472 0 0 20.1472 0 45C0 69.8528 20.1472 90 45 90Z' fill='#E9F6F6'/>
                <path d='M7 61L75 61' stroke='#16254C' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
                <rect x='17' y='21' width='42' height='41' fill='white'/>
                <path d='M62 64.5C62 65.8158 51.2966 67 37.9951 67C24.6937 67 14 65.8816 14 64.5C14 63.1184 24.7034 62 37.9951 62C51.2868 62 62 63.1842 62 64.5Z' fill='#91D1D2'/>
                <path d='M68 76.5C68 77.2895 63.5402 78 57.998 78C52.4557 78 48 77.3289 48 76.5C48 75.6711 52.4598 75 57.998 75C63.5362 75 68 75.7105 68 76.5Z' fill='#91D1D2'/>
                <circle cx='58.5' cy='61.5' r='13.5' fill='white'/>
                <path d='M44.0468 64.1473H19.4502C16.9089 64.1473 14.9121 62.1567 14.9121 59.6231V23.4299C14.9121 20.8964 16.9089 18.9058 19.4502 18.9058H55.7552C58.2965 18.9058 60.2933 20.8964 60.2933 23.4299V47.9508H57.7237V23.4299C57.7237 21.8917 57.2981 21.369 55.7552 21.369H19.2684C17.7254 21.369 17.3693 21.8917 17.3693 23.4299V59.6231C17.3693 61.1614 18.2002 61.7234 19.7431 61.7234H44.0468V64.1473Z' fill='#16254C'/>
                <path d='M59.3862 30.4878H15.8203V32.2975H59.3862V30.4878Z' fill='#16254C'/>
                <path d='M26.3477 28.5874C24.714 28.5874 23.3525 27.2301 23.3525 25.6014C23.3525 23.9727 24.714 22.6155 26.3477 22.6155C27.9814 22.6155 29.3429 23.9727 29.3429 25.6014C29.3429 27.2301 27.9814 28.5874 26.3477 28.5874ZM26.3477 24.4251C25.7124 24.4251 25.1678 24.968 25.1678 25.6014C25.1678 26.2348 25.7124 26.7777 26.3477 26.7777C26.983 26.7777 27.5276 26.2348 27.5276 25.6014C27.5276 24.968 26.983 24.4251 26.3477 24.4251Z' fill='#91D1D2'/>
                <path d='M48.8575 28.5874C47.2237 28.5874 45.8623 27.2301 45.8623 25.6014C45.8623 23.9727 47.2237 22.6155 48.8575 22.6155C50.4912 22.6155 51.8526 23.9727 51.8526 25.6014C51.8526 27.2301 50.4912 28.5874 48.8575 28.5874ZM48.8575 24.4251C48.2221 24.4251 47.6776 24.968 47.6776 25.6014C47.6776 26.2348 48.2221 26.7777 48.8575 26.7777C49.4928 26.7777 50.0374 26.2348 50.0374 25.6014C50.0374 24.968 49.4928 24.4251 48.8575 24.4251Z' fill='#91D1D2'/>
                <path d='M58.0244 70.2097C55.483 70.2097 53.3955 68.1286 53.3955 65.595C53.3955 65.0521 53.7586 64.6902 54.3031 64.6902C54.8477 64.6902 55.2108 65.0521 55.2108 65.595C55.2108 67.1333 56.4814 68.4 58.0244 68.4C59.5673 68.4 60.838 67.1333 60.838 65.595C60.838 64.0568 59.5673 62.7901 58.0244 62.7901C55.483 62.7901 53.3955 60.709 53.3955 58.1754C53.3955 55.6419 55.483 53.5608 58.0244 53.5608C60.5657 53.5608 62.6533 55.6419 62.6533 58.1754C62.6533 58.7183 62.2902 59.0803 61.7456 59.0803C61.2011 59.0803 60.838 58.7183 60.838 58.1754C60.838 56.6372 59.5673 55.3705 58.0244 55.3705C56.4814 55.3705 55.2108 56.6372 55.2108 58.1754C55.2108 59.7136 56.4814 60.9804 58.0244 60.9804C60.5657 60.9804 62.6533 63.0615 62.6533 65.595C62.6533 68.1286 60.5657 70.2097 58.0244 70.2097Z' fill='#16254C'/>
                <path d='M58.0238 55.3704C57.4793 55.3704 57.1162 55.0085 57.1162 54.4656V53.0179C57.1162 52.475 57.4793 52.113 58.0238 52.113C58.5684 52.113 58.9315 52.475 58.9315 53.0179V54.4656C58.9315 55.0085 58.5684 55.3704 58.0238 55.3704Z' fill='#16254C'/>
                <path d='M58.0238 71.6575C57.4793 71.6575 57.1162 71.2956 57.1162 70.7527V69.305C57.1162 68.7621 57.4793 68.4001 58.0238 68.4001C58.5684 68.4001 58.9315 68.7621 58.9315 69.305V70.7527C58.9315 71.2051 58.5684 71.6575 58.0238 71.6575Z' fill='#16254C'/>
                <path d='M58.0246 76.815C49.7652 76.815 43.0488 70.1192 43.0488 61.8853C43.0488 53.6513 49.7652 46.9556 58.0246 46.9556C66.284 46.9556 73.0004 53.6513 73.0004 61.8853C73.0004 70.1192 66.284 76.815 58.0246 76.815ZM58.0246 49.3797C50.7636 49.3797 45.38 54.6466 45.38 61.8853C45.38 69.1239 50.7636 74.5418 58.0246 74.5418C65.2856 74.5418 70.5421 69.1239 70.5421 61.8853C70.5421 54.6466 65.2856 49.3797 58.0246 49.3797Z' fill='#16254C'/>
                <path d='M28.4334 41.9789H22.9877C22.4431 41.9789 22.0801 41.6169 22.0801 41.074V35.6451C22.0801 35.1022 22.4431 34.7402 22.9877 34.7402H28.4334C28.978 34.7402 29.3411 35.1022 29.3411 35.6451V41.074C29.3411 41.6169 28.978 41.9789 28.4334 41.9789ZM23.8953 40.1692H27.5258V36.5499H23.8953V40.1692Z' fill='#16254C'/>
                <path d='M40.2332 41.9789H34.7875C34.2429 41.9789 33.8799 41.6169 33.8799 41.074V35.6451C33.8799 35.1022 34.2429 34.7402 34.7875 34.7402H40.2332C40.7778 34.7402 41.1409 35.1022 41.1409 35.6451V41.074C41.1409 41.6169 40.7778 41.9789 40.2332 41.9789ZM35.6951 40.1692H39.3256V36.5499H35.6951V40.1692Z' fill='#16254C'/>
                <path d='M52.0321 41.9789H46.5863C46.0418 41.9789 45.6787 41.6169 45.6787 41.074V35.6451C45.6787 35.1022 46.0418 34.7402 46.5863 34.7402H52.0321C52.5766 34.7402 52.9397 35.1022 52.9397 35.6451V41.074C52.9397 41.6169 52.5766 41.9789 52.0321 41.9789ZM47.494 40.1692H51.1245V36.5499H47.494V40.1692Z' fill='#16254C'/>
                <path d='M28.4334 51.0272H22.9877C22.4431 51.0272 22.0801 50.6653 22.0801 50.1224V44.6934C22.0801 44.1505 22.4431 43.7886 22.9877 43.7886H28.4334C28.978 43.7886 29.3411 44.1505 29.3411 44.6934V50.1224C29.3411 50.6653 28.978 51.0272 28.4334 51.0272ZM23.8953 49.2176H27.5258V45.5982H23.8953V49.2176Z' fill='#16254C'/>
                <path d='M40.2332 51.0272H34.7875C34.2429 51.0272 33.8799 50.6653 33.8799 50.1224V44.6934C33.8799 44.1505 34.2429 43.7886 34.7875 43.7886H40.2332C40.7778 43.7886 41.1409 44.1505 41.1409 44.6934V50.1224C41.1409 50.6653 40.7778 51.0272 40.2332 51.0272ZM35.6951 49.2176H39.3256V45.5982H35.6951V49.2176Z' fill='#16254C'/>
                <path d='M49.3092 51.0272H46.5863C46.0418 51.0272 45.6787 50.6653 45.6787 50.1224V44.6934C45.6787 44.1505 46.0418 43.7886 46.5863 43.7886H52.0321C52.5767 43.7886 52.9397 44.1505 52.9397 44.6934V48.7651H51.1245V45.5982H47.494V49.2176H50.5L49.3092 51.0272Z' fill='#16254C'/>
                <path d='M28.4334 60.0756H22.9877C22.4431 60.0756 22.0801 59.7136 22.0801 59.1707V53.7417C22.0801 53.1988 22.4431 52.8369 22.9877 52.8369H28.4334C28.978 52.8369 29.3411 53.1988 29.3411 53.7417V59.1707C29.3411 59.7136 28.978 60.0756 28.4334 60.0756ZM23.8953 58.2659H27.5258V54.6466H23.8953V58.2659Z' fill='#16254C'/>
                <path d='M40.2332 60.0756H34.7875C34.2429 60.0756 33.8799 59.7136 33.8799 59.1707V53.7417C33.8799 53.1988 34.2429 52.8369 34.7875 52.8369H40.2332C40.7778 52.8369 41.1409 53.1988 41.1409 53.7417V59.1707C41.1409 59.7136 40.7778 60.0756 40.2332 60.0756ZM35.6951 58.2659H39.3256V54.6466H35.6951V58.2659Z' fill='#16254C'/>
                <path d='M79 61H81.75' stroke='#16254C' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
            </svg>
        );
    }
}

export default RegularIncome;
