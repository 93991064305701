import { Action } from 'redux';
import UserActionsEnum from './ActionsEnum';
import IUser from './IUser';

// tslint:disable-next-line: no-empty-interface
export interface ICurrentUserGetAction extends Action {}

export function currentUserGetAction(): ICurrentUserGetAction {
    return {
        type: UserActionsEnum.CurrentUserGet,
    };
}

export interface ICurrentUserSetAction extends Action {
    user: IUser;
}

export function currentUserSetAction(user: IUser): ICurrentUserSetAction {
    return {
        type: UserActionsEnum.CurrentUserSet,
        user,
    };
}

export interface IEmailChangeAction extends Action {
    email: string;
}

export function emailChangeAction(email: string): IEmailChangeAction {
    return {
        email,
        type: UserActionsEnum.EmailChange,
    };
}

export interface IEmailChangeErrorSetAction extends Action {
    key: string;
    value: any;
}

export function emailChangeErrorSetAction(key: string, value: any): IEmailChangeErrorSetAction {
    return {
        key,
        type: UserActionsEnum.EmailChangeErrorSet,
        value,
    };
}

export interface IEmailChangeErrorsSetAction extends Action {
    errors: any;
}

export function emailChangeErrorsSetAction(errors: any): IEmailChangeErrorsSetAction {
    return {
        errors,
        type: UserActionsEnum.EmailChangeErrorsSet,
    };
}

export interface IEmailChangeInProgressSetAction extends Action {
    inProgress: boolean;
}

export function emailChangeInProgressSetAction(inProgress: boolean): IEmailChangeInProgressSetAction {
    return {
        inProgress,
        type: UserActionsEnum.EmailChangeInProgressSet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IEmailChangeModalHideAction extends Action {}

export function emailChangeModalHideAction(): IEmailChangeModalHideAction {
    return {
        type: UserActionsEnum.EmailChangeModalHide,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IEmailChangeModalShowAction extends Action {}

export function emailChangeModalShowAction(): IEmailChangeModalShowAction {
    return {
        type: UserActionsEnum.EmailChangeModalShow,
    };
}

export interface IEmailVerifyAction extends Action {
    code: string;
}

export function emailVerifyAction(code: string): IEmailVerifyAction {
    return {
        code,
        type: UserActionsEnum.EmailVerify,
    };
}

export interface IPasswordChangeAction extends Action {
    password: string;
}

export function passwordChangeAction(password: string): IPasswordChangeAction {
    return {
        password,
        type: UserActionsEnum.PasswordChange,
    };
}

export interface IPasswordChangeErrorSetAction extends Action {
    key: string;
    value: any;
}

export function passwordChangeErrorSetAction(key: string, value: any): IPasswordChangeErrorSetAction {
    return {
        key,
        type: UserActionsEnum.PasswordChangeErrorSet,
        value,
    };
}

export interface IPasswordChangeErrorsSetAction extends Action {
    errors: any;
}

export function passwordChangeErrorsSetAction(errors: any): IPasswordChangeErrorsSetAction {
    return {
        errors,
        type: UserActionsEnum.PasswordChangeErrorsSet,
    };
}

export interface IPasswordChangeInProgressSetAction extends Action {
    inProgress: boolean;
}

export function passwordChangeInProgressSetAction(inProgress: boolean): IPasswordChangeInProgressSetAction {
    return {
        inProgress,
        type: UserActionsEnum.PasswordChangeInProgressSet,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IPasswordChangeModalHideAction extends Action {}

export function passwordChangeModalHideAction(): IPasswordChangeModalHideAction {
    return {
        type: UserActionsEnum.PasswordChangeModalHide,
    };
}

// tslint:disable-next-line: no-empty-interface
export interface IPasswordChangeModalShowAction extends Action {}

export function passwordChangeModalShowAction(): IPasswordChangeModalShowAction {
    return {
        type: UserActionsEnum.PasswordChangeModalShow,
    };
}
