import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IAdviser from '~Api/Adviser/IAdviser';
import Layout from './Layout';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import { validateEmail } from '~validators';
import { IGlobalState } from '~reducer';
import {
    publicAdviserRegisterAction,
    publicAdviserRegisterErrorSetAction,
} from '~Public/actions';
import {
    publicAdviserRegisterErrorsSelector,
    publicAdviserRegisterInProgressSelector,
} from '~Public/selectors';
import { IDictionary } from '~utilities/IDictionary';

interface IState {
    afslNumber: string;
    authorisedRepresentativeNumber: string;
    companyName: string;
    dealerGroup: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
}

interface IPropsSelector {
    adviserRegisterErrors: IDictionary<string>;
    adviserRegisterInProgress: boolean;
}

interface IPropsDispatch {
    register: (adviser: IAdviser) => void;
    setError: (key: string, value: string) => void;
}

type Props = IPropsSelector & IPropsDispatch;

class Register extends React.Component<Props, IState> {
    public state: IState = {
        afslNumber: null,
        authorisedRepresentativeNumber: null,
        companyName: null,
        dealerGroup: null,
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickContinue = this.onClickContinue.bind(this);

        this.onChangeAfslNumber = this.onChangeAfslNumber.bind(this);
        this.onChangeAuthorisedRepresentativeNumber = this.onChangeAuthorisedRepresentativeNumber.bind(this);
        this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
        this.onChangeDealerGroup = this.onChangeDealerGroup.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);

        this.validateEmail = this.validateEmail.bind(this);
        this.validateFirstName = this.validateFirstName.bind(this);
        this.validateLastName = this.validateLastName.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
    }

    public render(): JSX.Element {
        const { adviserRegisterErrors, adviserRegisterInProgress } = this.props;
        const {
            afslNumber,
            authorisedRepresentativeNumber,
            companyName,
            dealerGroup,
            email,
            firstName,
            lastName,
            phone,
        } = this.state;

        return (
            <Layout>
                <div className='adviser'>
                    <Container className='register'>
                        <h3>Become an Accredited Adviser</h3>
                        <p className='intro'>We need some information to get started.</p>
                        <Form>
                            <Form.Group className='first-name'>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control isInvalid={!!adviserRegisterErrors.firstName} onBlur={this.validateFirstName} onChange={this.onChangeFirstName} type='text' value={firstName || ''} maxLength={50} />
                                {adviserRegisterErrors.firstName && <Form.Control.Feedback type='invalid'>{adviserRegisterErrors.firstName}</Form.Control.Feedback>}
                            </Form.Group>
                            <Form.Group className='last-name'>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control isInvalid={!!adviserRegisterErrors.lastName} onBlur={this.validateLastName} onChange={this.onChangeLastName} type='text' value={lastName || ''} maxLength={50} />
                                {adviserRegisterErrors.lastName && <Form.Control.Feedback type='invalid'>{adviserRegisterErrors.lastName}</Form.Control.Feedback>}
                            </Form.Group>
                            <Form.Group className='phone'>
                                <Form.Label>Phone</Form.Label>
                                <Form.Control isInvalid={!!adviserRegisterErrors.phone} onBlur={this.validatePhone} onChange={this.onChangePhone} type='text' value={phone || ''} maxLength={20} />
                                {adviserRegisterErrors.phone && <Form.Control.Feedback type='invalid'>{adviserRegisterErrors.phone}</Form.Control.Feedback>}
                            </Form.Group>
                            <Form.Group className='email'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control isInvalid={!!adviserRegisterErrors.email} onBlur={this.validateEmail} onChange={this.onChangeEmail} type='email' value={email || ''} maxLength={100} />
                                {adviserRegisterErrors.email && <Form.Control.Feedback type='invalid'>{adviserRegisterErrors.email}</Form.Control.Feedback>}
                            </Form.Group>
                            <Form.Group className='company-name'>
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control onChange={this.onChangeCompanyName} type='text' value={companyName || ''} maxLength={255} />
                            </Form.Group>
                            <Form.Group className='dealer-group'>
                                <Form.Label>Dealer Group</Form.Label>
                                <Form.Control onChange={this.onChangeDealerGroup} type='text' value={dealerGroup || ''} maxLength={255} />
                            </Form.Group>
                            <Form.Group className='afsl-number'>
                                <Form.Label>AFSL Number</Form.Label>
                                <Form.Control onChange={this.onChangeAfslNumber} type='text' value={afslNumber || ''} maxLength={20} />
                            </Form.Group>
                            <Form.Group className='dealer-group'>
                                <Form.Label>Authorised Representative Number</Form.Label>
                                <Form.Control onChange={this.onChangeAuthorisedRepresentativeNumber} type='text' value={authorisedRepresentativeNumber || ''} maxLength={20} />
                            </Form.Group>
                            <Button className='continue' disabled={adviserRegisterInProgress} onClick={this.onClickContinue} variant='primary'>
                                {adviserRegisterInProgress && <Spinner animation='border' as='span' role='status' size='sm' />}
                                {!adviserRegisterInProgress && 'Send'}
                            </Button>
                        </Form>
                    </Container>
                </div>
            </Layout>
        );
    }

    private onClickContinue(): void {
        let valid: boolean = true;

        valid = this.validateEmail() && valid;
        valid = this.validateFirstName() && valid;
        valid = this.validateLastName() && valid;
        valid = this.validatePhone() && valid;

        if (!valid) {
            return;
        }

        const {
            afslNumber,
            authorisedRepresentativeNumber,
            companyName,
            dealerGroup,
            email,
            firstName,
            lastName,
            phone,
        } = this.state;

        const adviser: IAdviser = {
            afslNumber,
            authorisedRepresentativeNumber,
            companyName,
            dealerGroup,
            email,
            firstName,
            lastName,
            phone,
        };

        this.props.register(adviser);
    }

    private onChangeAfslNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            afslNumber: event.target.value,
        });
    }

    private onChangeAuthorisedRepresentativeNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            authorisedRepresentativeNumber: event.target.value,
        });
    }

    private onChangeCompanyName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            companyName: event.target.value,
        });
    }

    private onChangeDealerGroup(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            dealerGroup: event.target.value,
        });
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            email: event.target.value,
        });
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            firstName: event.target.value,
        });
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            lastName: event.target.value,
        });
    }

    private onChangePhone(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            phone: event.target.value,
        });
    }

    private validateEmail(): boolean {
        const { email } = this.state;

        let error: string;
        if (!email || email.length === 0) {
            error = 'Please enter your email address';
        } else if (!validateEmail(email)) {
            error = 'Please enter a valid email address';
        }

        this.props.setError('email', error);

        return !error;
    }

    private validateFirstName(): boolean {
        const { firstName } = this.state;

        let error: string;

        if (!firstName || firstName.length === 0) {
            error = 'Please enter your first name';
        } else if (firstName.length < 2) {
            error = 'Please enter at least 2 characters';
        }

        this.props.setError('firstName', error);

        return !error;
    }

    private validateLastName(): boolean {
        const { lastName } = this.state;

        let error: string;

        if (!lastName || lastName.length === 0) {
            error = 'Please enter your last name';
        } else if (lastName.length < 2) {
            error = 'Please enter at least 2 characters';
        }

        this.props.setError('lastName', error);

        return !error;
    }

    private validatePhone(): boolean {
        const { phone } = this.state;

        let error: string;

        if (!phone || phone.length === 0) {
            error = 'Please enter your phone number';
        }

        this.props.setError('phone', error);

        return !error;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        adviserRegisterErrors: publicAdviserRegisterErrorsSelector(state),
        adviserRegisterInProgress: publicAdviserRegisterInProgressSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        register: (adviser: IAdviser) => dispatch(publicAdviserRegisterAction(adviser)),
        setError: (key: string, value: string) => dispatch(publicAdviserRegisterErrorSetAction(key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Register);
