import Cookies from 'js-cookie';
import { IGlobalState } from '~reducer';
import IAuthUser from './IAuthUser';
import RoleEnum from './RoleEnum';
import { verifyToken } from '~utilities/jwt';
import IToken from './IToken';

export function authLoginErrorsSelector(state: IGlobalState): any {
    return state.auth.loginErrors;
}

export function authLoginInProgressSelector(state: IGlobalState): boolean {
    return state.auth.loginInProgress;
}

export function authMagicLinkCreateErrorsSelector(state: IGlobalState): any {
    return state.auth.magicLinkCreateErrors;
}

export function authMagicLinkCreateInProgressSelector(state: IGlobalState): any {
    return state.auth.magicLinkCreateInProgress;
}

export function authMagicLinkRedeemErrorSelector(state: IGlobalState): any {
    return state.auth.magicLinkRedeemError;
}

export function authRegisterErrorsSelector(state: IGlobalState): any {
    return state.auth.registerErrors;
}

export function authRegisterInProgressSelector(state: IGlobalState): boolean {
    return state.auth.registerInProgress;
}

export function authRegisterMarketplaceQualifierAnswersSelector(state: IGlobalState): any {
    return state.auth.registerMarketplaceQualifierAnswers;
}

export function authCurrentUserSelector(state: IGlobalState): IAuthUser {
    const user: IAuthUser = {
        role: RoleEnum.Unauthenticated,
    };

    const token: string = authTokenSelector(state);

    if (token) {
        try {
            const decodedToken: IToken = verifyToken(token);

            user.role = decodedToken.roles[0];
            user.userUuid = decodedToken.userUuid;

            if (user.role === RoleEnum.Investor) {
                user.currentInvestorUuid = decodedToken.investorUuids && decodedToken.investorUuids[0];
                user.investorUuids = decodedToken.investorUuids;
            } else if (user.role === RoleEnum.Broker) {
                user.currentBrokerUuid = decodedToken.brokerUuids && decodedToken.brokerUuids[0];
                user.brokerUuids = decodedToken.brokerUuids;
            }
        } catch (e: any) {
            switch (e.name) {
                case 'TokenExpiredError':
                    Cookies.remove('currentUser');
                    (document as any).location = '/';
                    break;
                default:
                    throw e;
            }
        }
    }

    return user;
}

export function authTokenSelector(state: IGlobalState): string {
    let token: string = state.auth.token;

    if (!token) {
        const currentUserCookie: any = Cookies.getJSON('currentUser');

        if (currentUserCookie) {
            token = currentUserCookie.token;
        }
    }

    return token;
}
